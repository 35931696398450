import * as _ from 'lodash'

import { Action } from '../actions/list'
import {
  UPDATE_SPRINTS_LIST,
  UPDATE_SPRINTS_TAB_LIST,
  UPDATE_SPRINTS_ITEM,
  DELETE_ITEM,
  EDIT_ITEM,
  ADD_ITEM
} from '../constants/types'

export interface Item {
  sprintRank: number,
  email: string,
  _id: string,
  enabled: boolean,
  sprintPoints: number,
  race: string,
  username: string,
  teams: Array<string>,
  _acl: {
    creator: string
  }
  _bmd: {
    createdAt: number,
    _rev: number,
    updatedAt: number,
    _gen: string
  }
  previousRank: number,
  gender: string,
  name: string,
  allowNotifications: boolean,
  defaultAvatar: string,
  previousSprintRank: number,
  code: string,
  shareActivity: boolean,
  community: {
    includeCommonActions: boolean,
    status: string,
    points: number,
    eventsEnabled: boolean,
    volunteerOpportunitiesEnabled: boolean,
    _id: string,
    location: string,
    actionsTitle: string,
    enabled: boolean,
    sprintPoints: number,
    collections: Array<string>,
    servicesDescription: string,
    placesTitle: string,
    welcomeEmailAction: string,
    welcomeEmailActionURL: string,
    servicesEnabled: boolean,
    hours: number,
    placesDescription: string,
    categories: Array<string>,
    servicesTitle: string,
    shareUrl: string,
    volunteerOpportunitiesDescription: string,
    _acl: {
      creator: string
    }
    sprintHours: number,
    includeCommonPlaces: boolean,
    eventsDescription: string,
    showCategoriesInSearch: boolean,
    _bmd: {
      createdAt: number,
      _rev: number,
      updatedAt: number,
      _gen: string
    },
    eventsTitle: string,
    includeCommonServices: boolean,
    name: string,
    teamsPromptEnabled: boolean,
    volunteerOpportunitiesTitle: string,
    promptForRace: boolean,
    userCount: number,
    welcomeEmailSubject: string,
    code: string,
    placesEnabled: boolean,
    whitelistEnabled: boolean,
    actionsEnabled: boolean,
    teamsEnabled: boolean,
    logo: {
      _type: string,
      _id: string
    },
    actionsDescription: string,
    welcomeEmailEnabled: boolean,
    includeCommonEvents: boolean,
    includeCommonVolunteerOpportunities: boolean,
    shareMessage: string,
    description: string,
    promptForZipCode: boolean,
    profileChartsEnabled: boolean
  }
  rank: number,
  birthdate: string,
  engagements: number,
  $$hashKey: string
}

export type Users = Array<Item>
export type Sprints = Array<any>

const initialState: Sprints = []

export default (state = initialState, action: Action): Sprints => {
  switch (action.type) {
    case UPDATE_SPRINTS_LIST:
      return action.sprints
    case UPDATE_SPRINTS_TAB_LIST:
      const fixCreatedAt = _.without(action.sprints).map((i) => {
        const bmd = i._bmd || {}
        const date = bmd.createdAt || ''
        const createdAt = (window as any).moment.unix(date / 1000000000).format('YYYY-MM-DD h:mm a')
        return {
          ...i,
          createdAt
        }
      })
      const list = _.sortBy(fixCreatedAt, (i) => i._bmd.createdAt).reverse()
      return list
    case UPDATE_SPRINTS_ITEM:
      return state.map((i: any) => {
        if (i._id !== action.id) {
          return i
        }
        return {
          ...i,
          activeUsers: action.activeUsers,
          totalPoints: action.totalPoints
        }
      })
    case DELETE_ITEM:
      return state.filter((item: any) => {
        return item._id !== action.id
      })
    case EDIT_ITEM:
      // cut out the old sprint, replace with the new one
      state.splice(state.indexOf({ _id: action.sprint._id }), 1, action.sprint)
      return state
    case ADD_ITEM:
      state.push(action.sprint)
      return state
    default:
      return state
  }
}
