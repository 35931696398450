import { Action } from '../actions/list'
import {
  UPDATE_GROUPS_LIST,
  UPDATE_GROUPS_MEMBERS,
  UPDATE_GROUPS_ITEM
} from '../constants/types'

export interface Item {
}
export type Groups = Array<any>

export type List = {
  groups: Groups,
  groupMembers: any
}

const initialState = {
  groups: [],
  groupMembers: {}
}

export default (state: List = initialState, action: Action | any): List => {
  // console.log('team reducer', action)
  switch (action.type) {
    case UPDATE_GROUPS_LIST:
      return {
        ...state,
        groups: action.groups
      }
    case UPDATE_GROUPS_ITEM:
      return {
        ...state,
        groups: [
          ...state.groups.map((i) => {
            if (i._id !== action.id) {
              return i
            }
            return {
              ...i,
              totalUsers: action.totalUsers
            }
          })
        ]
      }
    case UPDATE_GROUPS_MEMBERS:
      return {
        ...state,
        groupMembers: action.groupMembers
      }

    default:
      return state
  }
}
