import {
  UPDATE_GROUPS_LIST,
  UPDATE_GROUPS_ITEM,
  UPDATE_GROUPS_MEMBERS
} from '../constants/types'
import { Groups } from '../reducers/list'

export type Update = {
  type: string,
  groups: Groups
}

export type UpdateUserCount = {
  type: string,
  id?: string,
  totalUsers?: number
}

export type UpdateTeamsMembers = {
  type: string,
  groupMembers: any
}

export type Action = Update | UpdateUserCount | UpdateTeamsMembers

export const update = (groups: Groups): Update => {
  return {
    type: UPDATE_GROUPS_LIST,
    groups
  }
}

export const updateUserCount = (id: string, totalUsers: number): UpdateUserCount => {
  return {
    type: UPDATE_GROUPS_ITEM,
    id,
    totalUsers
  }
}

export const updateTeamsMembers = (groupMembers) => {
  return {
    type: UPDATE_GROUPS_MEMBERS,
    groupMembers
  }
}
