import {
  UPDATE_VOLUNTEERING_SETTINGS,
  UPDATE_VOLUNTEERING_PAGE_SETTINGS,
  UPDATE_VOLUNTEERINGS_SEARCH_TERM_SETTINGS,
  UPDATE_VOLUNTEERINGS_COMMUNITY_SETTINGS,
  UPDATE_VOLUNTEERINGS_CATEGORY_SETTINGS,
  UPDATE_VOLUNTEERINGS_COLLECTION_SETTINGS,
  RESET_VOLUNTEERING_SETTINGS
} from '../constants/types'

import { Action } from '../actions/settings'

export interface Settings {
  searchTerm: any,
  itemsPerPage: number,
  isLoading: boolean,
  totalItems: number,
  currentPage: number,
  numPages: number,
  community: any,
  states: Array<any>,
  selectedState: string,
  category: any,
  collectionId: string,
}

const initialState: Settings = {
  searchTerm: '',
  itemsPerPage: 20,
  isLoading: true,
  totalItems: 0,
  currentPage: 1,
  numPages: 0,
  community: '',
  states: [],
  selectedState: '',
  category: {},
  collectionId: ''
}

export default (state: Settings = initialState, action: Action): Settings => {
  switch (action.type) {
    case UPDATE_VOLUNTEERING_SETTINGS:
      return {
        ...state,
        totalItems: action.count,
        numPages: Math.ceil(action.count / state.itemsPerPage + 1)
      }
    case UPDATE_VOLUNTEERING_PAGE_SETTINGS:
      return {
        ...state,
        currentPage: action.page
      }
    case UPDATE_VOLUNTEERINGS_SEARCH_TERM_SETTINGS:
      return {
        ...state,
        searchTerm: action.searchTerm
      }
    case UPDATE_VOLUNTEERINGS_COMMUNITY_SETTINGS:
      return {
        ...state,
        community: action.communityId
      }
    case UPDATE_VOLUNTEERINGS_CATEGORY_SETTINGS:
      return {
        ...state,
        category: action.category
      }
    case UPDATE_VOLUNTEERINGS_COLLECTION_SETTINGS:
      return {
        ...state,
        collectionId: action.collectionId
      }
    case RESET_VOLUNTEERING_SETTINGS:
      return initialState
    default:
      return state
  }
}
