import { UPDATE_LEADERBOARDS_USERS_IDS_LIST } from '../constants/types'
import { Action } from '../actions/usersIds'

export interface Item {
}

export type List = Array<Item>

const initialState: List = []

export default (state: List = initialState, action: Action): List => {
  switch (action.type) {
    case UPDATE_LEADERBOARDS_USERS_IDS_LIST:
      return action.usersIds
    default:
      return state
  }
}
