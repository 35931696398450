import {
  UPDATE_GOALS_SETTINGS,
  UPDATE_GOALS_PAGE_SETTINGS,
  UPDATE_GOALS_GROUPS_SETTINGS,
  UPDATE_GOALS_TEAMS_SETTINGS,
  UPDATE_GOALS_SEGMENTS_SETTINGS,
  UPDATE_GOALS_STATE_SETTINGS,
  UPDATE_GOALS_SORTING_SETTINGS,
  RESET_GOALS_SETTINGS
} from '../constants/types'
import { Settings } from '../reducers/settings'

export interface Action {
  type: string,
  users: Settings,
  count: number,
  page: number,
  keyword: string,
  group: any,
  team: any,
  segments: any,
  enabled: any,
  sortKey: string,
  sortDirection: object
}

export const update = (count) => {
  return {
    type: UPDATE_GOALS_SETTINGS,
    count
  }
}

export const pageUpdate = (page) => {
  return {
    type: UPDATE_GOALS_PAGE_SETTINGS,
    page
  }
}

export const groupUpdate = (group) => ({ type: UPDATE_GOALS_GROUPS_SETTINGS, group })
export const teamUpdate = (team) => ({ type: UPDATE_GOALS_TEAMS_SETTINGS, team })
export const segmentUpdate = (segments) => ({ type: UPDATE_GOALS_SEGMENTS_SETTINGS, segments })
export const stateUpdate = (enabled) => ({ type: UPDATE_GOALS_STATE_SETTINGS, enabled })

export const sortList = (sortKey, sortDirection) => {
  return {
    type: UPDATE_GOALS_SORTING_SETTINGS,
    sortKey,
    sortDirection
  }
}

export const reset = () => {
  return {
    type: RESET_GOALS_SETTINGS
  }
}
