import {
  UPDATE_TEAMS_LIST,
  UPDATE_TEAMS_ITEM,
  UPDATE_TEAMS_MEMBERS
} from '../constants/types'
import { Teams } from '../reducers/list'

export type Update = {
  type: string,
  teams: Teams
}

export type UpdateUserCount = {
  type: string,
  id?: string,
  userCount?: number
}

export type UpdateTeamsMembers = {
  type: string,
  teamsMembers: any
}

export type Action = Update | UpdateUserCount | UpdateTeamsMembers

export const update = (teams: Teams): Update => {
  return {
    type: UPDATE_TEAMS_LIST,
    teams
  }
}

export const updateUserCount = (id: string, userCount: number): UpdateUserCount => {
  return {
    type: UPDATE_TEAMS_ITEM,
    id,
    userCount
  }
}

export const updateTeamsMembers = (teamsMembers) => {
  return {
    type: UPDATE_TEAMS_MEMBERS,
    teamsMembers
  }
}
