import {
  UPDATE_PUSH_NOTIFICATIONS_SETTINGS,
  UPDATE_PUSH_NOTIFICATIONS_PAGE_SETTINGS,
  UPDATE_PUSH_NOTIFICATIONS_KEYWORD_SETTINGS,
  UPDATE_PUSH_NOTIFICATIONS_LOADING_SETTINGS,
  UPDATE_PUSH_NOTIFICATIONS_COMMUNITY_SETTINGS,
  RESET_PUSH_NOTIFICATIONS_SETTINGS
} from '../constants/types'

import { Action } from '../actions/settings'

export interface Settings {
  searchTerm: string,
  itemsPerPage: number,
  isLoading: boolean,
  totalItems: number,
  currentPage: number,
  numPages: number,
  community: any
}

const initialState: Settings = {
  searchTerm: '',
  itemsPerPage: 20,
  isLoading: true,
  totalItems: 0,
  currentPage: 1,
  numPages: 0,
  community: {
    _id: null
  }
}

export default (state: Settings = initialState, action: Action): Settings => {
  switch (action.type) {
    case UPDATE_PUSH_NOTIFICATIONS_SETTINGS:
      return {
        ...state,
        totalItems: action.count,
        numPages: Math.ceil(action.count / state.itemsPerPage + 1)
      }
    case UPDATE_PUSH_NOTIFICATIONS_PAGE_SETTINGS:
      return {
        ...state,
        currentPage: action.page
      }
    case UPDATE_PUSH_NOTIFICATIONS_KEYWORD_SETTINGS:
      return {
        ...state,
        searchTerm: action.keyword
      }
    case UPDATE_PUSH_NOTIFICATIONS_LOADING_SETTINGS:
      return {
        ...state,
        isLoading: action.isLoading
      }
    case UPDATE_PUSH_NOTIFICATIONS_COMMUNITY_SETTINGS:
      return {
        ...state,
        community: { _id: action.communityId }
      }
    case RESET_PUSH_NOTIFICATIONS_SETTINGS:
      return initialState
    default:
      return state
  }
}
