import { combineReducers } from 'redux'
import list, { Users } from './list'
import settings from './settings'
import exports_ from './exports'

export interface CombineReducers {
  list: Users
}

const rootReducer = combineReducers<CombineReducers>({
  list,
  settings,
  exports: exports_
})

export default rootReducer
