export const UPDATE_USERS_EXPORTS_LIST = 'UPDATE_USERS_EXPORTS_LIST'
export const UPDATE_USERS_LIST = 'UPDATE_USERS_LIST'
export const UPDATE_USERS_LIST_LENGTH_SETTINGS = 'UPDATE_USERS_LIST_LENGTH_SETTINGS'
export const UPDATE_USERS_SETTINGS = 'UPDATE_USERS_SETTINGS'
export const UPDATE_USERS_PAGE_SETTINGS = 'UPDATE_USERS_PAGE_SETTINGS'
export const UPDATE_USERS_KEYWORD_SETTINGS = 'UPDATE_USERS_KEYWORD_SETTINGS'
export const UPDATE_USERS_COMMUNITY_SETTINGS = 'UPDATE_USERS_COMMUNITY_SETTINGS'
export const UPDATE_USERS_TEAM_SETTINGS = 'UPDATE_USERS_TEAM_SETTINGS'
export const UPDATE_USERS_TEAMS_SETTINGS = 'UPDATE_USERS_TEAMS_SETTINGS'
export const RESET_USERS_SETTINGS = 'RESET_USERS_SETTINGS'
