import BendClient, { BendTable } from '../BendClient/index'
import * as actions from './actions'
import redux from '../../helpers/redux'
import * as _ from 'lodash'
import commonUtil from '../../helpers/commonUtil'
import { getActiveUser } from '../../helpers';
const moment = require('moment');

const forEachAsync = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

const related = {
  relations: {
    icon: 'BendFile',
    community: 'community'
  }
}

class GroupHelper {

  private api: any
  private api2: any

  constructor () {
    this.api = BendClient
    this.api2 = BendTable
  }

  public async init (communityId) {
    const settings = redux.getState().groups.settings
    settings.communityId = communityId
    const groups = await this.api2.getList(BendTable.Tables.GROUPS, settings, related)

    const formattedGroups = groups.map(group => {
      const lastUpdate = moment(group.lastUpdate / 1000000);
      group.totalUsers = group.totalUsers || 0;
      group.activeUsers = lastUpdate.isValid() && lastUpdate.diff(moment(), 'days') < 30 ? group.activeUsers || 0 : 0;
      return group;
    })

    redux.dispatch(
      actions.list.update(formattedGroups)
    )
    redux.dispatch(
      actions.settings.update(formattedGroups.length)
    )
    return groups
  }

  public async getList (id = getActiveUser().community._id) {
    const groups = await this.api2.getList(BendTable.Tables.GROUPS, { communityId: id })
    redux.dispatch(
      actions.list.update(groups)
    )
    redux.dispatch(
      actions.settings.update(groups.length)
    )
    return groups
  }

  public async sortBy (key, direction) {
    let groups = redux.getState().groups.list
    let sortedList = commonUtil.sortBy(groups, key, direction)
    redux.dispatch(
      actions.list.update(sortedList)
    )
  }

  // public updateTeamsMembers () {
  //   const teamList = redux.getState().teams.list.teams
  //   const userList = redux.getState().users.list
  //   let teamsMembers: any = {}
  //   ;(teamList || []).forEach((hm, team) => {
  //     (userList || []).forEach((hm, user) => {
  //       if (userList[user].teams
  //           && userList[user].teams.indexOf(teamList[team]._id) !== -1
  //       ) { // if team id exists on user
  //         if (teamsMembers[teamList[team]._id]) { // if team id key exists
  //           teamsMembers[teamList[team]._id].push(userList[user]._id) // add user id to array at key of team id
  //         } else {
  //           teamsMembers[teamList[team]._id] = [userList[user]._id] // create key of team id with value user id in array
  //         }
  //       }
  //     })
  //   })
  //   redux.dispatch(
  //     actions.list.updateTeamsMembers(teamsMembers)
  //   )
  // }

  public getGroupNames (ids: Array<string> = [], groups: Array<any> = []) {
    const names: Array<string> = []
    groups.map(group => {
      ids.map(id => {
        if (group._id === id) {
          names.push(group.name)
        }
      })
    })
    return names.join(', ')
  }

  public paginateToPage (page) {
    redux.dispatch(
      actions.settings.pageUpdate(page)
    )
  }

  public handleSearchKeyword (keyword) {
    redux.dispatch(
      actions.settings.searchKeywordUpdate(keyword)
    )
  }

  public selectCommunity (id) {
    let selected = redux.getState().communities.list.find(item => item._id === id)
    redux.dispatch(
      actions.settings.communityUpdate(selected)
    )
  }

  public getCommunityList () {
    return this.api.getCommunityList()
  }

  public get (id) {
    return this.api2.get(BendTable.Tables.GROUPS, id, related)
  }

  public create (data) {
    return this.api2.create(BendTable.Tables.GROUPS, data)
  }

  public update (data) {
    return this.api2.update(BendTable.Tables.GROUPS, data)
  }

  public delete (id) {
    return this.api2.delete(BendTable.Tables.GROUPS, id)
  }

  public isInUse(id) {
    return this.api.isUserGroupInUse(id);
  }

}

export default new GroupHelper()
