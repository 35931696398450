import { Action } from '../actions/list'
import {
  UPDATE_PLACES_LIST
} from '../constants/types'

export interface Item {
  points: number,
  state: string,
  _id: string,
  _geoloc: Array<number>,
  enabled: boolean,
  repeatable: boolean,
  repeatInterval: number,
  postalCode: string,
  hours: Array<any>,
  categories: Array<String>,
  _acl: {
    creator: string
  },
  _bmd: {
    createdAt: number,
    _rev: number,
    updatedAt: number,
    _gen: string
  },
  name: string,
  city: string,
  address1: string,
  community: {
    includeCommonActions: boolean,
    status: string,
    points: number,
    eventsEnabled: boolean,
    volunteerOpportunitiesEnabled: boolean,
    _id: string,
    actionsTitle: string,
    enabled: boolean,
    sprintPoints: number,
    collections: Array<String>,
    whitelabeled: boolean,
    servicesDescription: string,
    placesTitle: string,
    servicesEnabled: boolean,
    hours: number,
    placesDescription: string,
    servicesTitle: string,
    volunteerOpportunitiesDescription: string,
    creator: string,
    sprintHours: number,
    includeCommonPlaces: boolean,
    eventsDescription: string,
    showCategoriesInSearch: boolean,
    _bmd: {
      createdAt: number,
      _rev: number,
      updatedAt: number,
      _gen: string
    },
    eventsTitle: string,
    includeCommonServices: boolean,
    name: string,
    volunteerOpportunitiesTitle: string,
    userCount: number,
    code: string,
    placesEnabled: boolean,
    whitelistEnabled: boolean,
    actionsEnabled: boolean,
    teamsEnabled: boolean,
    key: {
      _type: string,
      _id: string
    },
    actionsDescription: string,
    welcomeEmailEnabled: boolean,
    includeCommonEvents: boolean,
    includeCommonVolunteerOpportunities: boolean,
    profileChartsEnabled: boolean
  },
  description: string,
  $$hashKey: string
}

export type Places = Array<Item>

const initialState: Places = []

export default (state: Places = initialState, action: Action): Places => {
  switch (action.type) {
    case UPDATE_PLACES_LIST:
      return action.places
    default:
      return state
  }
}
