import {
  UPDATE_SPRINTS_EXPORTS_LIST
} from '../constants/types'
import { Action } from '../actions/exports'

export interface Item {
  email: string,
  _id: string,
  name: string
}

export type Exports = Item[]

const initialState: Exports = []

export default (state: Exports = initialState, action: Action) => {
  switch (action.type) {
    case UPDATE_SPRINTS_EXPORTS_LIST:
      return action.exports
    default:
      return state
  }
}
