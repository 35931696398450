'use strict'

import commonUtil from '../../../src/helpers/commonUtil'
import * as categories from '../../../src/features/categories'
import { getActiveUser } from '../../../src/helpers'

angular.module('app.controllers')
  .controller('CategoryGroupController', ['$scope', '$routeParams', '$rootScope', '$modal', '$ngRedux',
    async function ($scope, $routeParams, $rootScope, $modal, $ngRedux) {
      // Init.
      $scope.categoryGroupList = []
      $scope.isLoading = true
      $scope.user = getActiveUser()
      $scope.viewCategoryGroup = false

      if ($routeParams.id != 'new')$scope.communityId = $routeParams.id

      let categoriesList
      // setTimeout(async () => {
      //   categoriesList = await categories.helpers.getCategoryGroupList($scope.communityId)
      // }, 200)
      categoriesList = await categories.helpers.getCategoryGroupList($scope.communityId)

      applyChangesOnScope($scope, () => {
        $scope.categoryGroupList = categoriesList
        $scope.isLoading = false
        $rootScope.setCategoryGroups(categoriesList)
      })

      var categoryGroupObj = {
        enabled: true
      }

      $scope.openEditCategoryGroup = (item = false) => {
        if (item) $scope.categoryGroup = item
        else $scope.categoryGroup = _.clone(categoryGroupObj)
        $scope.viewCategoryGroup = true
      }

      setTimeout(function () {
        if ($scope.categoryGroup.community) {
          $scope.categoryGroup.community = $scope.categoryGroup.community._id
        }
      }, 10)

      $scope.fileProgress = []
      $scope.isUploading = []

      $scope.close = function () {
        $scope.viewCategoryGroup = false
      }

      $scope.deleteCategoryGroup = function (id, $ev) {
        if (!id) {
          return $scope.close()
        }
        $ev.stopPropagation()
        $ev.preventDefault()
        const yeap = confirm('Deleting the category is permanent and can not be undone.')
        if (!yeap) {
          return false
        }
        categories.helpers.deleteCategoryGroup(id).then(function (ret) {
          if (ret) {
            var exist = _.find($scope.categoryGroupList, function (o) {
              return o._id === id
            })
            if (exist) {
              var idx = $scope.categoryGroupList.indexOf(exist)
              $scope.categoryGroupList.splice(idx, 1)
            }
            $scope.viewCategoryGroup = false
          } else {
          }
        })
      }

      $scope.saveCategoryGroupDo = function () {
        var data = _.clone($scope.categoryGroup)
        if (data.innerIcon) {
          data.innerIcon = commonUtil.makeBendFile(data.innerIcon._id)
        }
        if (data.outerIcon) {
          data.outerIcon = commonUtil.makeBendFile(data.outerIcon._id)
        }

        data.community = commonUtil.makeBendRef($scope.communityId, 'community')

        if (data._id) {
          delete data.$$hashKey
          categories.helpers.updateCategoryGroup(data).then(function (ret) {
            var ref = _.find($scope.categoryGroupList, function (o) {
              return o._id == ret._id
            })

            _.extend(ref, $scope.categoryGroup)
            if (ref.community) {
              ref.community = _.find($scope.communityList, function (o) {
                return o._id == ref.community
              })
            }
            $scope.viewCategoryGroup = false
          })
        } else {
          categories.helpers.createCategoryGroup(data).then(function (ret) {
            $scope.categoryGroup._id = ret._id
            if ($scope.categoryGroup.community) {
              $scope.categoryGroup.community = _.find($scope.communityList, function (o) {
                return o._id == $scope.categoryGroup.community
              })
            }
            $scope.categoryGroupList.push($scope.categoryGroup)
            $rootScope.setCategoryGroups($scope.categoryGroupList)
            $scope.viewCategoryGroup = false
          })
        }
      }
      $scope.deleteFile = function (tag, $ev) {
        $ev.stopPropagation()
        $ev.preventDefault()
        applyChangesOnScope($scope, function () {
          delete $scope.categoryGroup[tag]
        })
      }

      $scope.showFileLoading = function (tag, bShow) {
        $scope.isUploading[tag] = bShow
      }

      $scope.selectFileOpen = function (fileId, $ev) {
        if ($ev.target.tagName == 'DIV') {
          setTimeout(function () {
            $('#' + fileId).click()
          }, 0)
        }
      }

      $scope.onFileUpload = function (files, tag) {
        var file = files[0]
        $scope.fileProgress[tag] = 0
        $scope.showFileLoading(tag, true)

        categories.helpers.upload(file, function (error, uploadedFile) {
          if (error) {
            $scope.showFileLoading(tag, false)
            return
          }
          categories.helpers.getFile(uploadedFile, function (o) {
            $scope.categoryGroup[tag] = o
            $scope.showFileLoading(tag, false)
            $scope.form.validateForm.$dirty = true
          })
        }, {
          _workflow: 'logo'
        }, function (total, prog) {
          applyChangesOnScope($scope, function () {
            $scope.fileProgress[tag] = prog * 100 / total
          })
        })
      }
    // },
    //   $scope.openEditCategoryGroup = function (categoryGroupObj) {
    //     $modal.open({
    //       templateUrl: 'editCategoryGroup.html',
    //       backdrop: 'static',
    //       controller: function ($scope, $modalInstance, categoryGroupObj, categoryGroupList, communityId, user) {
    //       resolve: {
    //         categoryGroupObj: function () {
    //           return categoryGroupObj
    //         },
    //         categoryGroupList: ['$scope', function ($scope) {
    //           return $scope.categoryGroupList
    //         }],
    //         communityId: ['$scope', function ($scope) {
    //           return $scope.communityId
    //         }],
    //         user: ['$scope', function ($scope) {
    //           return $scope.user
    //         }]
    //       }
    //     })
    //   }
    }])
