import { getActiveUser } from '../../../../src/helpers'
import * as menu from '../../../../src/features/menu'
import * as communities from '../../../../src/features/communities'

angular
  .module('theme.core.navigation_controller', ['theme.core.services'])
  .controller('NavigationController', ['$scope', '$location', '$timeout', 'BendAuth', '$bend', '$rootScope', '$ngRedux', function ($scope, $location, $timeout, BendAuth, $bend, $rootScope, $ngRedux) {
    'use strict'

    $scope.menu = [{
      label: '',
      iconClasses: '',
      separator: true,
      type: 'both'
    }, {
      label: 'Dashboard',
      iconClasses: 'glyphicon glyphicon-home',
      url: '#/',
      type: 'both'
    }]

    const mapStateToThis = (state) => ({
      menu: state.menu.list
    })

    const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
    $scope.$on('$destroy', unsubscribe)

    $rootScope.initMenu = async function () {
      const user = await getActiveUser()
      if (user == null) return // check if user is signed in
      const communityAdmin = user.communityAdmin
      const community = await communities.helpers.get(user.community._id)
      menu.helpers.init(communityAdmin, community.menu) // list items are set in redux
      // menu.helpers.init(communityAdmin, []) // Forcing the default list, we can refactor this once we got confirmation this is fine
      $scope.isAdmin = true
    }

    $rootScope.initMenu()

    var setParent = function (children, parent) {
      angular.forEach(children, (child) => {
        child.parent = parent
        if (child.children !== undefined) {
          setParent(child.children, child)
        }
      })
    }

    $scope.findItemByUrl = function (children, url) {
      for (let i = 0, length = children.length; i < length; i++) {
        if (children[i].url && children[i].url.replace('#', '') === url) {
          return children[i]
        }
        if (children[i].children !== undefined) {
          const item = $scope.findItemByUrl(children[i].children, url)
          if (item) {
            return item
          }
        }
      }
    }

    setParent($scope.menu, null)

    $scope.openItems = []; $scope.selectedItems = []; $scope.selectedFromNavMenu = false

    $scope.select = function (item) {
      // close open nodes
      if (item.open) {
        item.open = false
        return
      }

      for (let i = $scope.openItems.length - 1; i >= 0; i--) {
        $scope.openItems[i].open = false
      }
      $scope.openItems = []
      let parentRef = item
      while (parentRef) {
        parentRef.open = true
        $scope.openItems.push(parentRef)
        parentRef = parentRef.parent
      }

      // handle leaf nodes
      if (!item.children || (item.children && item.children.length < 1)) {
        $scope.selectedFromNavMenu = true
        for (let j = $scope.selectedItems.length - 1; j >= 0; j--) {
          $scope.selectedItems[j].selected = false
        }
        $scope.selectedItems = []
        parentRef = item
        while (parentRef) {
          parentRef.selected = true
          $scope.selectedItems.push(parentRef)
          parentRef = parentRef.parent
        }
      }
    }

    $scope.highlightedItems = []
    const highlight = function (item) {
      let parentRef = item
      while (parentRef) {
        if (parentRef.selected) {
          parentRef = null
          continue
        }
        parentRef.selected = true
        $scope.highlightedItems.push(parentRef)
        parentRef = parentRef.parent
      }
    }

    var highlightItems = function (children, query) {
      angular.forEach(children, (child) => {
        if (child.label.toLowerCase().indexOf(query) > -1) {
          highlight(child)
        }
        if (child.children !== undefined) {
          highlightItems(child.children, query)
        }
      })
    }

    // $scope.searchQuery = '';
    $scope.$watch('searchQuery', (newVal, oldVal) => {
      const currentPath = '#' + $location.path()
      if (newVal === '') {
        for (let i = $scope.highlightedItems.length - 1; i >= 0; i--) {
          if ($scope.selectedItems.indexOf($scope.highlightedItems[i]) < 0) {
            if ($scope.highlightedItems[i] && $scope.highlightedItems[i] !== currentPath) {
              $scope.highlightedItems[i].selected = false
            }
          }
        }
        $scope.highlightedItems = []
      } else
      if (newVal !== oldVal) {
        for (let j = $scope.highlightedItems.length - 1; j >= 0; j--) {
          if ($scope.selectedItems.indexOf($scope.highlightedItems[j]) < 0) {
            $scope.highlightedItems[j].selected = false
          }
        }
        $scope.highlightedItems = []
        highlightItems($scope.menu, newVal.toLowerCase())
      }
    })

    $scope.$on('$routeChangeSuccess', () => {
      if ($scope.selectedFromNavMenu === false) {
        const item = $scope.findItemByUrl($scope.menu, $location.path())
        if (item) {
          $timeout(() => {
            applyChangesOnScope($scope, () => {
              $scope.select(item)
            })
          })
        }
      }
      $scope.selectedFromNavMenu = false
      $scope.searchQuery = ''
    })
  }])
