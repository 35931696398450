'use strict'

import * as schools from '../../../src/features/schools'

angular.module('app.controllers')
  .controller('SchoolsController', ['$scope', '$modal', '$ngRedux',
    async function ($scope, $modal, $ngRedux) {
      $scope.isLoading = true
      $scope.errorMessage = null

      const mapStateToThis = (state) => ({
        schoolsList: state.schools.list
      })

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      await schools.helpers.init()
      $scope.isLoading = false
      
      const openModal = (school) => {
        $scope.errorMessage = null

        const modalInstance = $modal.open({
          templateUrl: 'views/schools/schoolEdit.html',
          backdrop: 'static',
          controller: 'SchoolEditController',
          resolve: {
            school: () => school
          }
        })
        $scope.$modalInstance = modalInstance
        return modalInstance.result.then(() => schools.helpers.init())
      }

      $scope.createSchool = function () {
        $scope.errorMessage = null
        openModal(null)
      }

      $scope.deleteSchool = (item, $ev) => {
        $scope.errorMessage = null

        $ev.stopPropagation()

        schools.helpers.isInUse(item._id).then((inUse) => {
          if (inUse) {
            $scope.errorMessage = 'School is being used, cannot be deleted.'
            $scope.$apply()
          } else {
            schools.helpers.delete(item._id)
          }
        })
      }

      $scope.editSchool = (item) => {
        openModal(item)
      }
    }])
