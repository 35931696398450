import BendClient, { BendTable } from '../BendClient/index'

class Feature {

  private api: any
  private api2: any

  constructor () {
    this.api = BendClient
    this.api2 = BendTable
  }

  public getCommunityList () {
    return this.api.getCommunityList()
  }

  public getChallenges (settings: { communityId: string }) {
    // return this.api.fetchCalendarChallenges(settings)
    return this.api2.getList(BendTable.Tables.CHALLENGE, settings)
  }

}

export default new Feature()
