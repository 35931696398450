import { combineReducers } from 'redux'
import list, { StudioApps } from './list'
import settings from './settings'
import exportList from './exports'

export interface CombineReducers {
  list: StudioApps
}

const rootReducer = combineReducers<CombineReducers>({
  list,
  exportList,
  settings
})

export default rootReducer
