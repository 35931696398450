'use strict'

import commonUtil from '../../../src/helpers/commonUtil'
import * as collections from '../../../src/features/collections'
import * as communities from '../../../src/features/communities'
import { getActiveUser } from '../../../src/helpers'

angular.module('app.controllers')
  .controller('CollectionController', ['$scope', '$modal', '$ngRedux',
    async function ($scope, $modal, $ngRedux) {
      const listScope = $scope
      $scope.CommonUtil = commonUtil
      $scope.user = getActiveUser()

      const mapStateToThis = (state) => {
        return {
          collectionList: state.collections.list,
          communityList: state.communities.list
        }
      }

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      if ($scope.collectionList.length === 0) {
        $scope.isLoading = true
      }

      communities.helpers.initEnabled()
      await collections.helpers.init()

      $scope.isLoading = false

      $scope.editCollection = function (collection) {
        $scope.openEditCollection(collection)
      }
      $scope.createCollection = function () {
        var collectionObj = {
          enabled: true
        }
        $scope.openEditCollection(collectionObj)
      }

      $scope.openEditCollection = function (collectionObj) {
        $modal.open({
          templateUrl: 'editCollection.html',
          backdrop: 'static',
          controller: function ($scope, $modalInstance, collectionObj, collectionList, communityList, user) {
            $scope.communityList = communityList
            $scope.user = user
            $scope.collection = _.clone(collectionObj)

            setTimeout(function () {
              if ($scope.collection.community) {
                $scope.collection.community = $scope.collection.community._id
              }
            }, 10)

            $scope.CommonUtil = commonUtil
            $scope.fileProgress = []
            $scope.isUploading = []
            $scope.cancel = function () {
              $modalInstance.dismiss('cancel')
            }
            $scope.close = function () {
              $modalInstance.dismiss('cancel')
            }

            $scope.deleteCollection = async (id, e) => {
              if (!id) {
                return $scope.close()
              }
              e.preventDefault()
              e.stopPropagation()
              const yeap = confirm('Deleting the collection is permanent and can not be undone.')
              if (!yeap) {
                return false
              }
              $scope.close()
              listScope.isLoading = true
              await collections.helpers.delete(id)
              await collections.helpers.init()
              listScope.isLoading = false
            }

            $scope.saveCollectionDo = async () => {
              var data = _.clone($scope.collection)
              if (data.coverImage) {
                data.coverImage = commonUtil.makeBendFile(data.coverImage._id)
              }

              if (data.community && data.community != '') {
                data.community = commonUtil.makeBendRef(data.community, 'community')
              } else {
                delete data.community
              }

              $scope.close()
              listScope.isLoading = true
              if (data._id) {
                delete data.$$hashKey
                await collections.helpers.update(data)
              } else {
                if ($scope.user.communityAdmin) {
                  data.community = commonUtil.makeBendRef($scope.user.community._id, 'community')
                }
                await collections.helpers.create(data)
              }
              await collections.helpers.init()
              listScope.isLoading = false
            }
            $scope.deleteFile = function (tag, $ev) {
              $ev.stopPropagation()
              $ev.preventDefault()
              applyChangesOnScope($scope, function () {
                delete $scope.collection.coverImage
              })
            }

            $scope.showFileLoading = function (tag, bShow) {
              $scope.isUploading[tag] = bShow
            }

            $scope.selectFileOpen = function (fileId, $ev) {
              if ($ev.target.tagName == 'DIV') {
                setTimeout(function () {
                  $('#' + fileId).click()
                }, 0)
              }
            }

            $scope.onFileUpload = function (files, tag) {
              var file = files[0]
              $scope.fileProgress[tag] = 0
              $scope.showFileLoading(tag, true)

              collections.helpers.upload(file, function (error, uploadedFile) {
                if (error) {
                  $scope.showFileLoading(tag, false)
                  return
                }
                collections.helpers.getFile(uploadedFile, function (o) {
                  $scope.collection.coverImage = o
                  $scope.showFileLoading(tag, false)
                  $scope.form.validateForm.$dirty = true
                })
              }, {
                _workflow: 'coverPhoto'
              }, function (total, prog) {
                applyChangesOnScope($scope, function () {
                  $scope.fileProgress[tag] = prog * 100 / total
                })
              })
            }
            $scope.canSubmitValidationForm = function () {
              return $scope.form.validateForm.$valid
            }
          },
          resolve: {
            collectionObj: function () {
              return collectionObj
            },
            collectionList: ['$scope', function ($scope) {
              return $scope.collectionList
            }],
            communityList: ['$scope', function ($scope) {
              return $scope.communityList
            }],
            user: ['$scope', function ($scope) {
              return $scope.user
            }]
          }
        })
      }
    }])
