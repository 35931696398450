'use strict'

import * as communities from '../communities'
import * as surveys from '.'
import * as teams from '../teams'
import * as segments from '../segments'
// @controller: connected to whatever
angular.module('app.controllers').controller('SurveyController', [
  '$scope',
  '$location',
  'BendAuth',
  '$ngRedux',
  async function ($scope, $location, BendAuth, $ngRedux) {
    $scope.isLoading = true
    const mapStateToThis = (state) => ({
      communityList: state.communities.list,
      surveyList: state.surveys.list.surveys,
      surveyActivity: state.activities.list,
      collectionView: {
        itemsPerPage: state.surveys.settings.itemsPerPage,
        isLoading: state.surveys.settings.isLoading,
        totalItems: state.surveys.settings.totalItems,
        currentPage: state.surveys.settings.currentPage,
        numPages: state.surveys.settings.numPages
      },
      filter: {
        community: state.surveys.settings.community,
        userGroups: state.groups.list.groups,
        segments: state.segments.list
      }
    })
    $scope.user = await BendAuth.getActiveUser()
    const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
    $scope.$on('$destroy', unsubscribe)
    await surveys.helpers.reset()
    // returns object with list of all associated surveys -- this is what is displayed in survey.html view
    await surveys.helpers.init()
    // fetches teams associate with the community ID
    await teams.helpers.getList()
    // fetches segments associated with the community ID
    await segments.helpers.init()

    $scope.selectCommunity = (communityId) => {
      $scope.isLoading = true
      surveys.helpers.selectCommunity(communityId).then(() => {
        $scope.isLoading = false
      })
    }
    $scope.selectSegment = (segment) => {
      $scope.isLoading = true
      surveys.helpers.selectSegment(segment, false).then(() => {
        $scope.isLoading = false
      })
    }

    $scope.selectUserGroup = (userGroup) => {
      $scope.isLoading = true
      surveys.helpers.selectUserGroup(userGroup, false).then(() => {
        $scope.isLoading = false
      })
    }

    // user object needs to set community ID to determine what survey questions load + communityAdmin below ensures the person is the admin
    // function above^ $scope.selectCommunity called to fetch exact community by ID (bend reference) -- users community ID
    if ($scope.user.communityAdmin) {
      $scope.selectCommunity($scope.user.community._id)
    }
    // called in the communities helper.ts and fetches Enabled Community List
    communities.helpers.initEnabled().then(() => {
      // the conditional is only called if there is no id in the original object
      if (
        typeof $scope.filter.community === 'object' &&
        !$scope.filter.community._id
      ) {
        const community = _.get($scope.communityList, '[0]._id')
        $scope.selectCommunity(community)
      }
      $scope.loadList()
    })
    // $scope.loadlist runs after all "enabled" communities are fetched in the helpers.ts file of community with the initEnabled function (called above in the then promise of communities.helpers.initEnabled function)
    $scope.loadList = async () => {
      // survey.helpers.init() calls and returns object with list of all associated surveys -- this is what is displayed in survey.html view
      await surveys.helpers.init()
      $scope.isLoading = false
    }
    // opens edit survey view
    $scope.editSurvey = function (survey, $event) {
      if ($event.metaKey || $event.ctrlKey) {
        window.open('#/surveys/' + survey._id, '_blank')
      } else {
        return $location.path('/surveys/' + survey._id)
      }
    }
    // opens new blank survey
    $scope.createSurvey = function ($event) {
      if ($event.metaKey || $event.ctrlKey) {
        window.open('#/surveys/new', '_blank')
      } else {
        return $location.path('/surveys/new')
      }
    }
  }
])
