import {
  UPDATE_PLACES_COUNT,
  UPDATE_PLACES_PAGE_SETTINGS,
  UPDATE_PLACES_SEARCH_TERM_SETTINGS,
  RESET_PLACES_SETTINGS,
  UPDATE_PLACES_STATES_SETTINGS,
  UPDATE_PLACES_COMMUNITY_SETTINGS,
  UPDATE_PLACES_CATEGORY_SETTINGS,
  UPDATE_PLACES_COLLECTION_SETTINGS,
  UPDATE_PLACES_STATE_SETTINGS,
  UPDATE_PLACES_SORT_DIRECTION_SETTINGS,
  UPDATE_PLACES_SORT_KEY_SETTINGS,
  UPDATE_PLACES_LOADING_SETTINGS
} from '../constants/types'

import { Action } from '../actions/settings'

export interface Settings {
  searchTerm: any,
  itemsPerPage: number,
  isLoading: boolean,
  totalItems: number,
  currentPage: number,
  numPages: number,
  community: any,
  category: any,
  states: Array<any>,
  selectedState: string,
  collectionId: string,
  sortDirection: string,
  sortKey: string
}

const initialState: Settings = {
  searchTerm: '',
  itemsPerPage: 20,
  isLoading: true,
  totalItems: 0,
  currentPage: 1,
  numPages: 0,
  community: '',
  category: {},
  states: [],
  selectedState: '',
  collectionId: '',
  sortDirection: '',
  sortKey: ''
}

export default (state: Settings = initialState, action: Action): Settings => {
  switch (action.type) {
    case UPDATE_PLACES_COUNT:
      return {
        ...state,
        totalItems: action.count,
        numPages: Math.ceil(action.count / state.itemsPerPage + 1)
      }
    case UPDATE_PLACES_PAGE_SETTINGS:
      return {
        ...state,
        currentPage: action.page
      }
    case UPDATE_PLACES_SEARCH_TERM_SETTINGS:
      return {
        ...state,
        searchTerm: action.searchTerm
      }
    case UPDATE_PLACES_STATES_SETTINGS:
      return {
        ...state,
        states: action.states
      }
    case UPDATE_PLACES_COMMUNITY_SETTINGS:
      return {
        ...state,
        community: action.communityId
      }
    case UPDATE_PLACES_CATEGORY_SETTINGS:
      return {
        ...state,
        category: action.category
      }
    case UPDATE_PLACES_COLLECTION_SETTINGS:
      return {
        ...state,
        collectionId: action.collectionId
      }
    case UPDATE_PLACES_STATE_SETTINGS:
      return {
        ...state,
        selectedState: action.selectedState
      }
    case UPDATE_PLACES_SORT_DIRECTION_SETTINGS:
      return {
        ...state,
        sortDirection: action.sortDirection
      }
    case UPDATE_PLACES_SORT_KEY_SETTINGS:
      return {
        ...state,
        sortKey: action.sortKey
      }
    case UPDATE_PLACES_LOADING_SETTINGS:
      return {
        ...state,
        isLoading: action.isLoading
      }
    case RESET_PLACES_SETTINGS:
      return initialState
    default:
      return state
  }
}
