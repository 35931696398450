import {
  UPDATE_LEADERBOARD_SETTINGS,
  UPDATE_LEADERBOARDS_COMMUNITY_SETTINGS,
  RESET_LEADERBOARDS_SETTINGS,
  UPDATE_LEADERBOARDS_CATEGORY_SETTINGS,
  UPDATE_LEADERBOARDS_PAGE_SETTINGS,
  UPDATE_LEADERBOARDS_TOTAL_POINTS_SETTINGS,
  UPDATE_LEADERBOARDS_SPRINT_SETTINGS,
  UPDATE_LEADERBOARDS_ITEMS_PER_PAGE,
  UPDATE_LEADERBOARDS_HAS_MORE
} from '../constants/types'
import { Action } from '../actions/settings'

const initialState: Settings = {
  itemsPerPage: 20,
  totalItems: 0,
  currentPage: 1,
  numPages: 0,
  community: {},
  category: {},
  sprint: {},
  totalPoints: 0,
  more: false
}

export interface Settings {
  itemsPerPage?: number,
  totalItems: number,
  currentPage: number,
  numPages: number,
  community: object,
  category: object,
  sprint: object,
  totalPoints: number,
  more: boolean
}

export default (state: Settings = initialState, action: Action): Settings => {
  switch (action.type) {
    case UPDATE_LEADERBOARD_SETTINGS:
      return {
        ...state,
        totalItems: action.count || 0,
        numPages: Math.ceil((action.count || 0) / (state.itemsPerPage || 20) + 1)
      }
    case UPDATE_LEADERBOARDS_COMMUNITY_SETTINGS:
      return {
        ...state,
        community: action.community
      }
    case UPDATE_LEADERBOARDS_CATEGORY_SETTINGS:
      return {
        ...state,
        category: action.category
      }
    case UPDATE_LEADERBOARDS_SPRINT_SETTINGS:
      return {
        ...state,
        sprint: action.sprint
      }
    case UPDATE_LEADERBOARDS_PAGE_SETTINGS:
      return {
        ...state,
        currentPage: action.page
      }
    case UPDATE_LEADERBOARDS_ITEMS_PER_PAGE:
      return {
        ...state,
        itemsPerPage: action.itemsPerPage
      }
    case UPDATE_LEADERBOARDS_TOTAL_POINTS_SETTINGS:
      return {
        ...state,
        totalPoints: action.totalPoints
      }
    case UPDATE_LEADERBOARDS_HAS_MORE:
      return {
        ...state,
        more: action.more
      }
    case RESET_LEADERBOARDS_SETTINGS:
      return initialState
    default:
      return state
  }
}
