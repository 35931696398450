import {
  UPDATE_EVENT_LIST
} from '../constants/types'
import { Events } from '../reducers/list'

export interface Action {
  type: string,
  events: Events
}

export const update = (events: Events): Action => {
  return {
    type: UPDATE_EVENT_LIST,
    events
  }
}
