"use strict";

import commonUtil from "../../../src/helpers/commonUtil";
import * as actions from "../../../src/features/actions";
import * as tags from "../../../src/features/tags";
import * as categories from "../../../src/features/categories";
import * as surveys from "../../../src/features/surveys";
import * as teams from "../../../src/features/teams";
import * as segments from "../../../src/features/segments";

angular.module("app.controllers").controller("ActionEditController", [
  "$scope",
  "$location",
  "$routeParams",
  "BendAuth",
  "$rootScope",
  "$modal",
  "$ngRedux",
  async function(
    $scope,
    $location,
    $routeParams,
    BendAuth,
    $rootScope,
    $modal,
    $ngRedux
  ) {
	  $scope.isLoading = true
    // Init.
    if ($routeParams.id != "new") {
      $scope.actionId = $routeParams.id;
    }
    $scope.action = {
      repeatable: true,
      audios: [],
      videos: []
    };
    $scope.media = {
      video: "",
      audio: "",
      pointsOnMediaEnd: false
    };
    $scope.moreOptions = false;
    $scope.formData = {
      categories: [],
      surveys: [],
      collections: [], // we don't use these anymore
      teams: [],
      segments: [],
      intervalminute: 0,
      intervalhour: 12,
      intervalday: 0
    };
    const formDataKeys = Object.keys($scope.formData);

    const mapStateToThis = state => {
      return {
        tagList: state.tags.list,
        comments: state.comments.list,
        categories: state.categories.list,
        surveys: state.surveys.list.surveys,
        teams: state.teams.list.teams,
        segments: state.segments.list
      };
    };

    const unsubscribe = $ngRedux.connect(mapStateToThis)($scope);
    $scope.$on("$destroy", unsubscribe);

	$scope.user = await BendAuth.getActiveUser();
	await Promise.all([
		tags.helpers.init(),
		teams.helpers.getList(),
		segments.helpers.init(),
		surveys.helpers.init(),
		categories.helpers.getList($scope.user.community._id)
	])
    $scope.isLoading = true;
    $scope.collectionList = [];
    $scope.communityList = [];
    $scope.isUploading = [];
    $scope.fileProgress = [];
    $scope.form = {};

    async.parallel(
      [
        function(callback) {
          if (!$scope.user.communityAdmin) {
            actions.helpers.getCommunityList(function(rets) {
              $scope.communityList = rets;
            });
          }
          callback(null, null);
        },
        function (callback) {
          categories.helpers.getList();
          actions.helpers.getCollectionEnabledList(function(rets) {
            $scope.collectionList = rets;

            callback(null, null);
          });
        }
      ],
      function(err, rets) {
        if (err) {
          console.log(err);
          return;
        }
        if ($scope.actionId) {

          actions.helpers.get($scope.actionId).then(async ret => {


            ret.enabled =
              typeof ret.enabled !== "undefined" && ret.enabled === true;
            $scope.action = ret;
            if ($scope.action.repeatInterval) {
              $scope.formData.intervalday = parseInt(
                $scope.action.repeatInterval / (24 * 3600)
              );
              $scope.formData.intervalhour = parseInt(
                ($scope.action.repeatInterval -
                  $scope.formData.intervalday * (24 * 3600)) /
                  3600
              );
              $scope.formData.intervalminute = parseInt(
                ($scope.action.repeatInterval -
                  ($scope.formData.intervalday * (24 * 3600) +
                    $scope.formData.intervalhour * 3600)) /
                  60
              );
            }
            if ($scope.action.community) {
              $scope.action.community = $scope.action.community._id;
            } else {
              delete $scope.action.community;
            }

              if ($scope.action.surveys && $scope.action.surveys.length>0 && $scope.action.surveys[0]._id) {
                $scope.formData.surveys = $scope.action.surveys[0];
              } 

            if ($scope.action.teams && $scope.action.teams.length) {
              $scope.formData.teams = _.filter($scope.teams, function(team) {
                return $scope.action.teams.indexOf(team._id) !== -1;
              });
            }

            if ($scope.action.segments && $scope.action.segments.length) {
              $scope.formData.segments = _.filter($scope.segments, function(segment) {
                return $scope.action.segments.indexOf(segment._id) !== -1;
              });
            }

            if (
              $scope.action.categories &&
              $scope.action.categories.length > 0
            ) {
              $scope.formData.categories = _.filter($scope.categories, function(
                o
              ) {
                return $scope.action.categories.indexOf(o._id) != -1;
              });
            }

            if (
              $scope.action.collections &&
              $scope.action.collections.length > 0
            ) {
              $scope.formData.collections = _.filter(
                $scope.collectionList,
                function(o) {
                  return $scope.action.collections.indexOf(o._id) != -1;
                }
              );
            }

            if ($scope.action.videos && $scope.action.videos.length) {
              $scope.media.video = $scope.action.videos[0]; 
            }

            if ($scope.action.audios && $scope.action.audios.length) {
              $scope.media.audio = $scope.action.audios[0]; 
            }

            if ($scope.action.pointsOnMediaEnd) {
             $scope.media.pointsOnMediaEnd =  true;
            }

            $scope.isLoading = false;
          });
        } else {
			  $scope.isLoading = false;
        }

        $scope.$apply();
        
      }
    );

    $scope.getSegmentInfo = segment => {
      return segments.helpers.getSegmentInfo(segment);
    };

    // form validation using angular ng-class
    $scope.validateForm = () => {

      const required = "required";

      const name = _.get($scope.action, "name");
      const description = _.get($scope.action, "description");
      const points = _.get($scope.action, "points");
      const categoriesExist = _.get($scope.formData, "categories").length;

      if (name === "" || !name) $scope.nameReq = required;
      else $scope.nameReq = false;
      if (description === "" || !description) $scope.descriptionReq = required;
      else $scope.descriptionReq = false;
      if (categoriesExist === 0) $scope.categoriesReq = required;
      else $scope.categoriesReq = false;
      if (points === "" || !points) $scope.pointsReq = required;
      else $scope.pointsReq = false;
      if (!name || !description || categoriesExist === 0 || !points) {
        $scope.errorMsg = true;
        return false;
      }
      return true;
    };

    $scope.filterNewline = async () => {
      var description = _.get($scope.action, "description");
      var parts = description.toString().split("\n");
      _.set($scope.action, "description", parts.join(""));
    };

    $scope.saveActionDo = async () => {

      if (!$scope.validateForm()) return;
      $scope.filterNewline();
      var repeatInterval = 0;
      if ($scope.formData.intervalday != 0) {
        repeatInterval = $scope.formData.intervalday * (24 * 3600);
      }
      if ($scope.formData.intervalhour != 0) {
        repeatInterval = repeatInterval + $scope.formData.intervalhour * 3600;
      }
      if ($scope.formData.intervalminute != 0) {
        repeatInterval = repeatInterval + $scope.formData.intervalminute * 60;
      }

      if (repeatInterval > 0) {
        $scope.action.repeatInterval = repeatInterval;
      } else if ($scope.action.repeatable) {
        $scope.action.repeatInterval = 86400; // default to 1 day if no interval set
      }

      if ($scope.action.community && $scope.action.community != "") {
        $scope.action.community = commonUtil.makeBendRef(
          $scope.action.community,
          "community"
        );
      } else {
        delete $scope.action.community;
      }

      if ($scope.media.pointsOnMediaEnd) {
        $scope.action.pointsOnMediaEnd = true; 
      } else {
        $scope.action.pointsOnMediaEnd = false; 
      }

      if ($scope.media.video && $scope.media.video.length) {
        $scope.action.videos = [$scope.media.video]
      } else {
        delete $scope.action.videos
      }

      if ($scope.media.audio && $scope.media.audio.length) {
        $scope.action.audios = [$scope.media.audio]
      } else {
        delete $scope.action.audios
      }

      // on save
      formDataKeys.map(key => {
        if ($scope.formData[key] && $scope.formData[key].length > 0) {
          $scope.action[key] = $scope.formData[key].map(obj => obj._id);
        } else {
          delete $scope.action[key];
        }
      });

      // if ($scope.formData.categories.length > 0) {
      //   $scope.action.categories = commonUtil.getIdList($scope.formData.categories)
      // }

      // if ($scope.formData.collections.length > 0) {
      //   $scope.action.collections = commonUtil.getIdList($scope.formData.collections)
      // } else {
      //   delete $scope.action.collections
      // }

      // if ($scope.formData.teams && $scope.formData.teams.length > 0) {
      //   $scope.action.teams = commonUtil.getIdList($scope.formData.teams)
      // } else {
      //   delete $scope.action.teams
      // }

      if ($scope.formData.surveys._id) {
        $scope.action.surveys = commonUtil.getIdListAndName(
          $scope.formData.surveys
        );
      } 
      else {
       $scope.action.surveys = []
      }

      if ($scope.action.startMonth && $scope.action.startMonth != "") {
        $scope.action.startMonth = Number($scope.action.startMonth);
      } else {
        delete $scope.action.startMonth;
      }
      if ($scope.action.startDay && $scope.action.startDay != "") {
        $scope.action.startDay = Number($scope.action.startDay);
      } else {
        delete $scope.action.startDay;
      }

      if ($scope.action.endMonth && $scope.action.endMonth != "") {
        $scope.action.endMonth = Number($scope.action.endMonth);
      } else {
        delete $scope.action.endMonth;
      }
      if ($scope.action.endDay && $scope.action.endDay != "") {
        $scope.action.endDay = Number($scope.action.endDay);
      } else {
        delete $scope.action.endDay;
      }

      if ($scope.action._id) {
        delete $scope.action.$$hashKey;
        await actions.helpers.update($scope.action);
      } else {
        await actions.helpers.create($scope.action);
      }
      applyChangesOnScope($scope, () => {
        $location.path("/actions");
      });
    };

    $scope.deleteFile = function(tag, $ev) {
      $ev.stopPropagation();
      $ev.preventDefault();
      applyChangesOnScope($scope, function() {
        $scope.action.coverImage = null;
        delete $scope.action.coverImage;
      });
    };

    $scope.showFileLoading = function(tag, bShow) {
      $scope.isUploading[tag] = bShow;
    };

    $scope.selectFileOpen = function(fileId, $ev) {
      if ($ev.target.tagName == "DIV") {
        setTimeout(function() {
          $("#" + fileId).click();
        }, 0);
      }
    };

    $scope.onFileUpload = function(files, tag) {
      var file = files[0];
      $scope.fileProgress[tag] = 0;
      $scope.showFileLoading(tag, true);
      var reader = new FileReader();
      reader.onload = function(e) {
        $scope.uploadingFileUrl = e.target.result;
      };
      reader.readAsDataURL(file);

      actions.helpers.upload(
        file,
        function(error, uploadedFile) {
          if (error) {
            $scope.uploadingFileUrl = null;
            $scope.showFileLoading(tag, false);
            return;
          }
          actions.helpers.getFile(uploadedFile, function(o) {
            $scope.action.coverImage = o;
            $scope.showFileLoading(tag, false);
            $scope.uploadingFileUrl = null;
            $scope.$apply();
          });
        },
        {
          _workflow: "coverPhoto"
        },
        function(total, prog) {
          applyChangesOnScope($scope, function() {
            $scope.fileProgress[tag] = (prog * 100) / total;
          });
        }
      );
    };
    $rootScope.fileUploadFromSearch2 = function(file, tag) {
      file._filename = Date.now() + "";
      var files = [];
      files.push(file);
      $scope.onFileUpload(files, tag);
    };

    $scope.searchImages2 = function($ev, tag) {
      $ev.stopPropagation();
      $ev.preventDefault();
      $modal.open({
        templateUrl: "views/templates/searchImages.html",
        backdrop: "static",
        controller: function($scope, $modalInstance) {
          $scope.searchResults = 0;
          $scope.pages = 1;
          $scope.showLoadMore = false;

          $scope.cancel = function() {
            $modalInstance.dismiss("cancel");
          };
          $scope.close = function() {
            $modalInstance.dismiss("cancel");
          };

          $scope.searchImages = function(searchVal) {
            $scope.pages = 1;
            if (searchVal) {
              var URL =
                "https://pixabay.com/api/?key=2706353-ee3016f8af51ca406a8c8a3db&q=" +
                encodeURIComponent(searchVal) +
                "&image_type=photo&page=" +
                $scope.pages;
              $.getJSON(URL, function(data) {
                if (parseInt(data.totalHits) > 0) {
                  applyChangesOnScope($scope, function() {
                    if (data.totalHits > $scope.pages * 20) {
                      $scope.showLoadMore = true;
                    } else {
                      $scope.showLoadMore = false;
                    }
                    $scope.searchResults = data.hits;
                  });
                }
              });
            }
          };

          $scope.searchImagesMore = function(tabIdx, searchVal) {
            $scope.pages++;
            if (searchVal) {
              var URL =
                "https://pixabay.com/api/?key=2706353-ee3016f8af51ca406a8c8a3db&q=" +
                encodeURIComponent(searchVal) +
                "&image_type=photo&page=" +
                $scope.pages;
              $.getJSON(URL, function(data) {
                if (parseInt(data.totalHits) > 0) {
                  applyChangesOnScope($scope, function() {
                    if (data.totalHits > $scope.pages * 20) {
                      $scope.showLoadMore = true;
                    } else {
                      $scope.showLoadMore = false;
                    }

                    $scope.searchResults = $scope.searchResults.concat(
                      data.hits
                    );
                  });
                }
              });
            }
          };

          $scope.selectImage = function(searchItem) {
            $scope.isDownloading = true;
            var imageUrl = "";
            imageUrl = searchItem.webformatURL;

            var xhr = new XMLHttpRequest();
            xhr.open("GET", imageUrl, true);
            xhr.responseType = "blob";
            xhr.onload = function(e) {
              if (this.status == 200) {
                var myBlob = this.response;
                $rootScope.fileUploadFromSearch2(myBlob, tag);
                // myBlob is now the blob that the object URL pointed to.
                $scope.cancel();
              }
            };
            xhr.send();
          };
        }
      });
    };
  }
]);
