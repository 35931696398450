import {
  UPDATE_EVENT_TEMPLATES_SETTINGS,
  UPDATE_EVENT_TEMPLATES_PAGE_SETTINGS,
  UPDATE_EVENT_TEMPLATES_LOADING_SETTINGS,
  UPDATE_EVENT_TEMPLATES_COMMUNITY_SETTINGS
} from '../constants/types'

export interface Action {
  type: string,
  count: number,
  page: number,
  isLoading: boolean,
  communityId: string
}

export const update = (count) => {
  return {
    type: UPDATE_EVENT_TEMPLATES_SETTINGS,
    count
  }
}

export const pageUpdate = (page) => {
  return {
    type: UPDATE_EVENT_TEMPLATES_PAGE_SETTINGS,
    page
  }
}
export const communityUpdate = (communityId) => {
  return {
    type: UPDATE_EVENT_TEMPLATES_COMMUNITY_SETTINGS,
    communityId
  }
}

export const toggleLoading = (isLoading) => {
  return {
    type: UPDATE_EVENT_TEMPLATES_LOADING_SETTINGS,
    isLoading
  }
}
