'use strict'

import commonUtil from '../../../src/helpers/commonUtil'
import * as userActivities from '../../../src/features/userActivities'

angular.module('app.controllers')
  .controller('ActivityEditController', ['$scope', '$location', '$routeParams',
    function ($scope, $location, $routeParams) {
      // Init.
      $scope.activityId = null
      if ($routeParams.id != 'new') { $scope.activityId = $routeParams.id }
      $scope.isLoading = true
      $scope.communityList = []
      $scope.businessList = []
      $scope.actionList = []
      $scope.eventList = []
      $scope.volunteeringList = []
      $scope.serviceList = []
      $scope.activityList = []
      $scope.TypeList = ['action', 'business', 'event', 'volunteer_opportunity']
      $scope.userList = []
      $scope.formData = {
        communities: []
      }
      $scope.filter = {
        user: '',
        activity: ''
      }
      $scope.username = ''
      $scope.activity = {
        community: ''
      }
      $scope.community = ''

      async.parallel([
        async (callback) => {
          const userList = await userActivities.helpers.getUserList()
          applyChangesOnScope($scope, () => {
            $scope.userList = userList
          })
          callback(null, null)
        },
        async (callback) => {
          const communities = await userActivities.helpers.getCommunityList()
          applyChangesOnScope($scope, () => {
            $scope.communityList = communities
          })
          callback(null, null)
        }
      ], (err, rets) => {
        if (err) {
          return
        }
        if ($scope.activityId) {
          userActivities.helpers.getActivity($scope.activityId).then(function (ret) {
            $scope.activity = ret

            if ($scope.activity.activity) {
              $scope.activityList = []
              $scope.activityList.push($scope.activity.activity)
              $scope.filter.activity = $scope.activity.activity
            }

            if ($scope.activity.user) {
              _.find($scope.userList, function (u) {
                if (u._id == $scope.activity.user._id) {
                  $scope.filter.user = u
                }
              })
            } else {
              $scope.activity.user = ''
            }

            if ($scope.activity.community) {
              _.find($scope.communityList, function (com) {
                if (com._id == $scope.activity.community._id) {
                  $scope.activity.community = com._id
                  $scope.community = com.name
                }
              })
            } else {
              $scope.activity.community = ''
            }
            $scope.isLoading = false
          })
        }
      })

      $scope.refreshActivity = async (keyword) => {
        const settings = {
          keyword,
          type: _.get($scope, 'activity.type')
        }
        if (settings.type) {
          const activities = await userActivities.helpers.refreshActivities(settings)
          applyChangesOnScope($scope, () => {
            $scope.activityList = activities
          })
        }
      }

      $scope.onChangeUser = function () {
        if ($scope.filter.user) {
          $scope.activity.community = $scope.filter.user.community._id
          if ($scope.activity.community) {
            _.find($scope.communityList, function (com) {
              if (com._id == $scope.activity.community) {
                $scope.community = com.name
              }
            })
          } else {
            $scope.activity.community = ''
          }
        } else {
          $scope.activity.community = ''
        }
      }

      $scope.onChangeActivity = function () {
        if ($scope.filter.activity) {
          $scope.activity.summary = $scope.filter.activity.name
          $scope.activity.points = $scope.filter.activity.points || 0
          $scope.activity.link = $scope.filter.activity.url
        } else {
          $scope.activity.summary = ''
          $scope.activity.points = 0
        }
      }

      $scope.onChangeType = function () {
        $scope.activityList = []
        $scope.filter.activity = ''
      }
      $scope.saveActivityDo = async () => {
        if ($scope.activity.type) {
          if ($scope.filter.activity != '') {
            $scope.activity.activity = commonUtil.makeBendRef($scope.filter.activity._id, $scope.activity.type)
          } else {
            delete $scope.activity.activity
          }
        }

        if ($scope.filter.user) {
          $scope.activity.user = commonUtil.makeBendRef($scope.filter.user._id, 'user')
        } else {
          delete $scope.activity.user
        }

        if ($scope.activity.community != '') {
          $scope.activity.community = commonUtil.makeBendRef($scope.activity.community, 'community')
        } else {
          delete $scope.activity.community
        }

        var activityData = _.clone($scope.activity)

        if ($scope.activityId) {
          delete activityData.$$hashKey
          await userActivities.helpers.update(activityData)
        } else {
          await userActivities.helpers.create(activityData)
        }
        applyChangesOnScope($scope, () => {
          $location.path('/activities')
        })
      }
    }])
