import { combineReducers } from 'redux'
import list, { Menu } from './list'

export interface CombineReducers {
  list: Menu
}

const rootReducer = combineReducers<CombineReducers>({
  list
})

export default rootReducer
