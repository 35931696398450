"use strict";

import * as pushTemplates from '../../../src/features/pushTemplates'
import * as pushNotifications from '../../../src/features/pushNotifications'
import * as communities from '../../../src/features/communities'
import commonUtil from '../../../src/helpers/commonUtil'
import { getActiveUser } from '../../../src/helpers'

angular.module('app.controllers')
  .controller('PushTemplatesController', ['$scope', '$rootScope', 'BendAuth', '$modal', '$ngRedux',
    async function ($scope, $rootScope, BendAuth, $modal, $ngRedux) {
      // Init.
      //console.log('confirm changeFinal')
      //$scope.commonUtil = commonUtil
      $scope.isLoading = true
      const mapStateToThis = (state) => {
        return {
          pushTemplatesList: state.pushTemplates.list,
          collectionView: {
            itemsPerPage: state.pushTemplates.settings.itemsPerPage,
            isLoading: state.pushTemplates.settings.isLoading,
            totalItems: state.pushTemplates.settings.totalItems,
            currentPage: state.pushTemplates.settings.currentPage,
            numPages: state.pushTemplates.settings.numPages
          }
        }
      }

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      $scope.user = await BendAuth.getActiveUser()
      //console.log($scope.user)
      await pushTemplates.helpers.init()
      $scope.isLoading = false
      $scope.deleteTemplate = async (id, $ev) => {
        $ev.stopPropagation()
        $ev.preventDefault()
        const yeap = confirm('Are you sure you want to delete? This cannot be undone.')
        if (!yeap) {
          return false
        }
        await pushTemplates.helpers.delete(id)
        await pushTemplates.helpers.init()
      }

      $rootScope.editTemplate = function (template) {
        $scope.openTemplateModal(template).then(async (push) => {
          if (push.timevalue && push.timevalue instanceof Date) {
            push.time = push.timevalue.getHours() + ':' + push.timevalue.getMinutes() + ' AM'
          }
          await pushTemplates.helpers.update(push)
          await pushTemplates.helpers.init()
        })
      }

      $scope.createTemplate = function () {
        $scope.openTemplateModal().then(async (model) => {
          if (model.obj.timevalue && model.obj.timevalue instanceof Date) {
            model.obj.time = model.obj.timevalue.getHours() + ':' + model.obj.timevalue.getMinutes() + ' AM'
          }
          await pushTemplates.helpers.create(model)
          await pushTemplates.helpers.init()
        })
      }

      $scope.openTemplateModal = function (nt) {
        var modalInstance = $modal.open({
          templateUrl: 'views/push/push-template-edit-modal.html',
          backdrop: 'static',
          controller: ['$scope', '$modalInstance', function ($scope, $modalInstance) {
            $scope.audience = {
              audiences: [],
              defaultQuery: {}
            }
            
            $scope.textLimit = 140
            // var timevalue = new Date()
            if (nt) {
              //console.log(nt)
              $scope.model = nt
              if (!$scope.model.obj.deeplink){
                //console.log($scope.model.obj.params)
                for (let i of $scope.model.obj.params){
                  if (i.key == 'deeplink'){
                    $scope.model.obj.deeplink = i.value
                    //console.log('i',$scope.model.obj.deeplink)
                  }
                }
              }
            } else {
              $scope.model = {
                name: '',
                obj: {
                  params: [],
                  text: '',
                  userQuery: '{}'
                }
              }
            }
            $scope.formData = {
              deeplink:{}
            }
            //console.log('original', $scope.model.obj)
            
            //console.log('form data', $scope.formData)
            //console.log('if', $scope.model.obj.deeplink)
            if ($scope.model.obj.deeplink) {
              //console.log('if T')
              $scope.formData.deeplink.deeplink = $scope.model.obj.deeplink
            }
            //console.log($scope.formData)

            $scope.removeParam = function (param) {
              $scope.model.obj.params = _.without($scope.model.obj.params, param)
            }

            $scope.addParam = function () {
              // var count = $scope.model.obj.params.length + 1
              $scope.model.obj.params.push({
                key: '',
                value: ''
              })
            }
            $scope.deeplinks = []
            $scope.formData = {
              audiences: []//,
              //deeplink: {}
            }
            pushTemplates.helpers.fetchAudiences().then(function (ret) {
              //console.log('audience')
              $scope.audience = ret
              if (ret.audiences) {
                if ($scope.model.obj.audience) {
                  var audiences = []
                  _.map($scope.model.obj.audience.audiences, function (o) {
                    //console.log($scope.model.obj.audience.audiences)
                    var exist = _.find(ret.audiences, function (_o) {
                      return o == (_o.query['community._id'] || _o.query['teams'])
                    })
                    if (exist) {
                      audiences.push(exist)
                    }
                  })
                  $scope.formData.audiences = audiences
                }
              }
              $scope.runQuery()
            })
            //console.log('bC')
            $scope.user = getActiveUser();
            //console.log($scope.user)
            communities.helpers.get($scope.user.community._id).then(community => {
              //console.log('comunities')
              pushNotifications.helpers.fetchDeeplinks().then(function (deeplinks) {
                let defaultDeeplinks = commonUtil.defaultDeeplinks.map(link => {
                  let { deeplink, name } = link
                  return { name, deeplink: community.appName.toLowerCase() + deeplink }
                })
                $scope.allDeeplinks = defaultDeeplinks.concat(deeplinks) // store all deeplinks separately so they stay available after filter
                $scope.deeplinks = $scope.allDeeplinks
              })
            })

            $scope.filterDeeplinks = word => {
              if ($scope.allDeeplinks) {
                $scope.deeplinks = $scope.allDeeplinks.filter(link => {
                  if (link.name.toLowerCase().indexOf(word.toLowerCase()) !== -1) return true
                  else return false
                })
                if (!word || !word.length) $scope.deeplinks = $scope.allDeeplinks
              }
            }

            $scope.checkDeepLink = function (params) {
              var exist = _.find(params, function (o) {
                return o.key == 'deeplink'
              })

              if (exist) {
                return exist.value
              } else { return null }
            }
            $scope.updateModel = function () {
              //console.log($scope.formData.deeplink)
              $scope.model.obj.deeplink = $scope.formData.deeplink.deeplink
              //console.log($scope.model.obj)
            }
            $scope.updateParam = function () {
              if ($scope.formData.deeplink) {
                var exist = _.find($scope.model.obj.params, function (o) {
                  return o.key == 'deeplink'
                })

                if (exist) {
                  exist.value = $scope.formData.deeplink.deeplink
                  //console.log(exist)
                  //console.log($scope.model.obj.params)
                } else {
                  $scope.model.obj.params.push({
                    key: 'deeplink',
                    value: $scope.formData.deeplink.deeplink
                  })
                }
              } else {
                exist = _.find($scope.model.obj.params, function (o) {
                  return o.key == 'deeplink'
                })

                if (exist) {
                  $scope.removeParam(exist)
                }
              }
            }

            $scope.cancel = function () {
              $modalInstance.dismiss('cancel')
            }

            //console.log($scope.model)


            // form validation using angular ng-class
            $scope.validateForm = () => {
              const required = 'required'

              const name = _.get($scope.model, 'name')
              const text = _.get($scope.model.obj, 'text')
              const deeplink = _.get($scope.model.obj, 'deeplink')
              //console.log(deeplink)

              if (name === '' || !name) $scope.nameReq = required // we're using model here to indicate the push template
              if (text === '' || !text) $scope.textReq = required
              if (deeplink === '' || !deeplink) $scope.deeplinkReq = required
              if (!text ||
                !deeplink ||
                !name
              ) {
                $scope.errorMsg = true
                return false
              }
              return true
            }

            $scope.proceed = function () {
              if (!$scope.validateForm()) return
              $scope.model.obj.audience = {
                defaultQuery: $scope.audience.defaultQuery,
                audiences: []
              }
              _.map($scope.formData.audiences, function (o) {
                $scope.model.obj.audience.audiences.push(o.query['community._id'] || o.query['teams'])
              })

              $modalInstance.close($scope.model)
            }

            $scope.validateTextLength = function () {
              return $scope.textLimit - $scope.model.obj.text.length > 0
            }

            $scope.validateUserQuery = function () {
              $scope.userQueryError = commonUtil.jsonValidate($scope.model.obj.userQuery)
              return !$scope.userQueryError
            }
            $scope.usersCount = null
            $scope.runQuery = function () {
              if ($scope.validateUserQuery() && $scope.model.obj && $scope.model.obj.userQuery) {
                let settings = {
                  audiences: _.get($scope, 'formData.audiences'),
                  defaultQuery: _.get($scope, 'audience.defaultQuery'),
                  userQuery: _.get($scope, 'model.obj.userQuery')
                }

                pushTemplates.helpers.usersCount(settings).then((res) => {
                  $scope.usersCount = res
                })
              } else {
                $scope.usersCount = null
              }
            }
            $scope.runQuery()

            $scope.validate = function () {
              return $scope.model.name && $scope.model.obj.text && $scope.validateTextLength() && $scope.validateUserQuery()
            }
          }]
        })

        return modalInstance.result
      }
    }])
