import { UPDATE_NEWEST_USERS_SETTINGS } from '../constants/types'
import { Action } from '../actions/settings'

export interface Settings {
  itemsPerPage: number,
  totalItems: number,
  currentPage: number,
  numPages: number
}

const initialState: Settings = {
  itemsPerPage: 10,
  totalItems: 0,
  currentPage: 1,
  numPages: 0
}

export default (state: Settings = initialState, action: Action): Settings => {
  switch (action.type) {
    case UPDATE_NEWEST_USERS_SETTINGS:
      return {
        ...state,
        totalItems: action.count,
        numPages: Math.ceil(action.count / state.itemsPerPage + 1)
      }
    default:
      return state
  }
}
