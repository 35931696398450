import {
  UPDATE_STUDIO_APPS_LIST
} from '../constants/types'
import { StudioApps } from '../reducers/list'

export interface Action {
  type: string,
  studioApps: StudioApps
}

export const update = (studioApps: StudioApps): Action => { // these are actually poll questions, not studioApp objects
  return {
    type: UPDATE_STUDIO_APPS_LIST,
    studioApps
  }
}
