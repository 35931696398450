import { combineReducers } from 'redux'
import list from './list'
import settings from './settings'
import usersPoints from './usersPoints'
import usersIds from './usersIds'

const rootReducer = combineReducers({
  list,
  settings,
  usersIds,
  usersPoints
})

export default rootReducer
