import BendClient, { BendTable } from '../BendClient'
import * as actions from './actions'
import redux from '../../../src/helpers/redux'
import commonUtil from '../../../src/helpers/commonUtil'
import { getActiveUser } from '../../helpers'
import * as moment from 'moment'

class Feature {

  private api: any
  private api2: any

  constructor() {
    this.api = BendClient
    this.api2 = BendTable
  }

  public async reset() {
    redux.dispatch(
      actions.settings.reset()
    )
    this.selectCommunity(null)
    await this.init()
  }

  public async init() {
    this.getList()
  }

  public sortBy(key, direction) {
    let list = redux.getState().pushNotifications.list
    let sortedList = commonUtil.sortBy(list, key, direction)
    redux.dispatch(
      actions.list.update(sortedList)
    )
  }

  public paginateToPage(page) {
    this.toggleLoading(true)
    const currentPage = redux.getState().pushNotifications.settings.currentPage
    if (Number(page)) {
      redux.dispatch(
        actions.settings.pageUpdate(page)
      )
    } else if (page === 'Previous') {
      redux.dispatch(
        actions.settings.pageUpdate(currentPage - 1)
      )
    } else {
      redux.dispatch(
        actions.settings.pageUpdate(currentPage + 1)
      )
    }
    this.toggleLoading(false)
  }

  public handleSearchKeyword(keyword) {
    redux.dispatch(
      actions.settings.searchKeywordUpdate(keyword)
    )
  }

  public selectCommunity(id) {
    redux.dispatch(
      actions.settings.selectCommunity(id)
    )
  }

  public toggleLoading(bool) {
    redux.dispatch(
      actions.settings.toggleLoading(bool)
    )
  }

  public async getList() {
    // return this.api.getPushNotificationList()
    const { settings } = redux.getState().pushNotifications
    //console.log('GetList', BendTable.Tables.PUSH_NOTIFICATION, settings);
    const pushNotificationsList = await this.api2.getPagedList(BendTable.Tables.PUSH_NOTIFICATION, settings)
    //console.log('GetList', pushNotificationsList)
    const count = await this.api2.pagedCount(BendTable.Tables.PUSH_NOTIFICATION, settings)

    const formattedList = pushNotificationsList.map(push => {
      return {
        ...push,
        text: push.text ? push.text : push.oneSignalErrors && push.oneSignalErrors.length > 0 ? push.oneSignalErrors[0] : '',
        status: push.send_after ? 'scheduled' : push.oneSignalErrors && push.oneSignalErrors.length > 0 ? 'failed' : push.oneSignalId && push.oneSignalId.length > 0 ? 'sent' : push.status,
        recipients: push.recipients ? push.recipients : "Data Not Availible",
        datetime: push.datetime && moment(push.datetime).isValid() ? moment(push.datetime).format('LLL') : 'Invalid Date'
      }
    })

    redux.dispatch(
      actions.list.update(formattedList)
    )
    redux.dispatch(
      actions.settings.update(count)
    )
    this.toggleLoading(false)
  }

  public get(id) {
    return this.api2.get('push', id)
  }

  public async delete(id) {
    // First deletes from bend. From response, grabs the oneSignalId and uses it to delete from OneSignal
    let push: any = await new Promise((resolve)=>{
      resolve(this.api2.delete(BendTable.Tables.PUSH_NOTIFICATION, id))
      this.toggleLoading(false)
    })

    // console.log('deleted push from bend: ',push)
    let { message, options } = await this.configurePush(push)
    options.method = 'DELETE'
    options.path = options.path + `/${push.oneSignalId}?app_id=${message.app_id}`
    // console.log('options: ',options)

    return new Promise((resolve, reject) => {
      let https = require('https')
      let req = https.request(options, function (res) {
        res.on('data', function (data) {
          const response = JSON.parse(data)
          // console.log('oneSignal delete response: ', response)
          resolve({...response })
        })
      })
      req.on('error', function (e) {
        console.log('ERROR:', e)
        reject(e)
      })
      req.end()
    })
    
  }

  public async configurePush (push, communityId = getActiveUser().community._id) {
    const { datetime, text, timezone, daysToLive, scheduled, deeplink } = push

    let secsToLive
    if (daysToLive) {
      secsToLive = daysToLive * 24 * 60 * 60
    }

    let message: any = {
      contents: { 'en': text },
      ttl: secsToLive || 259200 // push notification will still deliver if device comes online with this time
    }
    if (typeof datetime === 'object' && scheduled) {
      message.send_after = datetime.toString()
    } else if (typeof datetime === 'string') {
      message.send_after = datetime
    }
    if (timezone) message.delayed_option = 'timezone'

    if (deeplink) {
      message.data = { deeplink }
    }

    let headers: any = {
      'Content-Type': 'application/json; charset=utf-8'
    }
    
    const wl = await this.api.getWhitelabel(communityId);

    if (wl && wl.length > 0){
      const data = wl[0];
      headers.Authorization = `Basic ${data.onesignalRestApiKey}`;
      message.app_id = data.onesignalAppId;
    }

    let options = {
      host: 'onesignal.com',
      port: 443,
      path: '/api/v1/notifications',
      method: 'POST',
      headers: headers
    }
    return { message, options }
  }

  // this will create the push in OneSignal now only if its scheduled for less than 5 min from now or sent immediately
  // if scheduled for later, it will save it to bend only and endpoint 5min_push_sendScheduled will create it
  public async create (push: any, userId: string = '') {
    const config = await this.configurePush(push)
    let { message, options } = config;
    // console.log('Push Create', push, userId)

    if (userId) { //Test user
      // console.log('userId exists: ',userId)
      message.include_external_user_ids = [userId]
      return this.sendNotification(message, options)
    } else {
      // console.log('no userId')
      const communityId = getActiveUser().community._id
      const { teams, segments, users } = push

      if (users && users.length) {
        // console.log('users exist: ',users)
        message.include_external_user_ids = users
        message.users = users
        return this.sendNotification(message, options)
      } else {
        // console.log('no users specified. filtering by segment/ team/ community')
        let filters = {
          teams,
          segments,
          communityId
        }

        // get list of users
        return this.api2.getList('user', filters).then(users => {
          // console.log('Filtered users from bend: ', users)
          let bendUserIds = users.map(user => user._id)
          message.include_external_user_ids = bendUserIds
          return this.sendNotification(message, options)
        })
      }

    }

  }

  public sendNotification(pushData, options) {
    const outerScope = this

    // console.log('Request message and options: ', pushData, options)
    return new Promise((resolve, reject) => {
      let https = require('https')
      let req = https.request(options, function (res) {
        res.on('data', function (data) {
          const response = JSON.parse(data)
          console.log('oneSignal push response', response)
          response.oneSignalId = response.id
          response.recipients = response.recipients
          response.users = pushData.users || []
          response.segments = pushData.segments || []

          if (pushData.contents && pushData.contents.en)
            response.text = pushData.contents.en;
          response.datetime = pushData.send_after ? moment(pushData.send_after).toDate() : new Date();
          if (pushData.send_after)
            response.send_after = moment(pushData.send_after).toDate()
          if (pushData.data && pushData.data.deeplink)
            response.deeplink = pushData.data.deeplink

          if (response.errors) {
            if (response.errors.invalid_external_user_ids) {
              response.invalidBendUserIds = response.errors.invalid_external_user_ids
            } else {
              response.oneSignalErrors = response.errors
            }
          }
          response.sent = true
          // console.log('Push before saving to bend: ',response)
          outerScope.createBend(response)
          resolve({ saved: true, ...response })
        })
      })
      req.on('error', function (e) {
        console.log('ERROR:', e)
        reject(e)
      })
      req.write(JSON.stringify(pushData))
      req.end()
    })
  }

  public fetchAudiences() {
    return this.api.fetchPushNotificationAudiences()
  }

  public fetchDeeplinks() {
    return this.api.fetchPushNotificationDeeplinks()
  }

  public createBend(push) {
    delete push.$$hashKey
    if (push._id) return this.update(push)
    else {
      // console.log('push._id not found. Creating a new record in bend.')
      return this.api2.create(BendTable.Tables.PUSH_NOTIFICATION, push)
    }
  }

  public update(push) {
    return this.api2.update(BendTable.Tables.PUSH_NOTIFICATION, push)
  }

  public saveAndSend(data) {
    return this.api.saveAndSendPushNotification(data)
  }

  public sendScheduled() {
    return this.api.sendScheduledPushNotification()
  }

  public fetchPushTemplateList() {
    const { settings } = redux.getState().pushNotifications
    // return this.api.fetchPushTemplateList()
    return this.api2.getList(BendTable.Tables.PUSH_TEMPLATE, settings)
  }

  public usersCount(settings: {
    audiences: Array<any>,
    defaultQuery: object,
    userQuery: string
  }) {
    return this.api.usersCountPushNotifications(settings)
  }

}

export default new Feature()
