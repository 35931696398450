import * as _ from 'lodash'
import * as actions from './actions'
import BendClient, { BendTable } from '../BendClient'
import commonUtil from '../../helpers/commonUtil'
import * as Bend from '../../lib/bend-1.1.8'
import redux from '../../../src/helpers/redux'
import { getActiveUser } from '../../helpers'

class Feature {

  private api: any
  private api2: any

  constructor () {
    this.api = BendClient
    this.api2 = BendTable
  }

  public async reset () {
    redux.dispatch(
      actions.settings.reset()
    )
  }

  public async init (communityId = '') {
    let settings = redux.getState().segments.settings
    if (settings.communityId == '') settings.communityId = getActiveUser().community._id
    settings.communityId = communityId

    try {

      console.time("Segments Init");

      const segmentTypes = await this.api2.getList(BendTable.Tables.SEGMENT_TYPE, settings)
      const segmentTypeIds = segmentTypes.map(t => t._id)
      const segments = await this.api2.getList(BendTable.Tables.SEGMENT, { segmentTypes: segmentTypeIds })
      segments.map(segment => {
        segmentTypes.map(type => {
          if (segment.segmentType._id === type._id) segment.segmentType = type
        })
      })
      segments.sort((a, b) => {
        return a.segmentType.name.localeCompare(b.segmentType.name) || a.name.localeCompare(b.name)
      })

      console.timeEnd("Segments Init");
      
      redux.dispatch(
        actions.list.update(segments)
      )
      redux.dispatch(
        actions.settings.update(segments.length)
      )
      return segments
    } catch (error) {
      console.log('Segments Error', error)
      redux.dispatch(
        actions.list.update([])
      )
      redux.dispatch(
        actions.settings.update(0)
      )
    }

  }

  public getList () {
    this.init()
  }

  public getSegmentInfo (segment) {
    let typeName = segment.segmentType.name
    return `${typeName}: ${segment.name} --- ${segment.userCount} users`
  }

  public getSegmentName (segment) {
    let typeName = segment.segmentType.name
    return `${typeName}: ${segment.name}`
  }

  public getUserList (segmentId) {
    return this.api2.getList(BendTable.Tables.USER, { segments: [segmentId] })
  }

  public getUserCount (segmentId) {
    return this.api2.count(BendTable.Tables.USER, { segments: [segmentId] })
  }

  public async get (id) {
    let segment = await this.api2.get(BendTable.Tables.SEGMENT, id)
    segment.segmentType = await this.api2.get(BendTable.Tables.SEGMENT_TYPE, segment.segmentType._id)
    return segment
  }

  public create (data) {
    return this.api2.create(BendTable.Tables.SEGMENT, data)
  }

  public update (data) {
    return this.api2.update(BendTable.Tables.SEGMENT, data)
  }

  public delete (id) {
    return this.api2.delete(BendTable.Tables.SEGMENT, id)
  }

}

export default new Feature()
