import { Action } from '../actions/list'
import {
  UPDATE_COMMENTS_LIST
} from '../constants/types'

export interface Comment {

}

export type Comments = Array<Comment>

const initialState: Comments = []

export default (state: Comments = initialState, action: Action): Comments => {
  switch (action.type) {
    case UPDATE_COMMENTS_LIST:
      return action.comments
    default:
      return state
  }
}
