import { combineReducers } from 'redux'
import list, { Categories } from './list'
import settings from './settings'

export interface CombineReducers {
  list: Categories
}

const rootReducer = combineReducers<CombineReducers>({
  list,
  settings
})

export default rootReducer
