import {
  RESET_SEGMENTS_SETTINGS,
  UPDATE_SEGMENTS_PAGE_SETTINGS,
  UPDATE_SEGMENTS_SETTINGS,
  UPDATE_SEGMENTS_SETTINGS_COMMUNITY_ID
} from '../constants/types'

import { Action } from '../actions/settings'

export interface Settings {
  itemsPerPage: number,
  isLoading: boolean,
  totalItems: number,
  currentPage: number,
  numPages: number,
  communityId: string
}

const initialState: Settings = {
  itemsPerPage: 20,
  isLoading: true,
  totalItems: 0,
  currentPage: 1,
  numPages: 0,
  communityId: ''
}

const dropdownItem = {
  name: 'All Teams',
  _id: null
}

export default (state: Settings = initialState, action: Action): Settings => {
  switch (action.type) {
    case UPDATE_SEGMENTS_SETTINGS:
      return {
        ...state,
        totalItems: action.count,
        numPages: Math.ceil(action.count / state.itemsPerPage + 1)
      }
    case UPDATE_SEGMENTS_PAGE_SETTINGS:
      return {
        ...state,
        currentPage: action.page
      }
    case UPDATE_SEGMENTS_SETTINGS_COMMUNITY_ID:
      return {
        ...state,
        communityId: action.communityId
      }
    case RESET_SEGMENTS_SETTINGS:
      return initialState
    default:
      return state
  }
}
