/* eslint-disable indent */
'use strict'

import commonUtil from '../../../src/helpers/commonUtil'
import * as goals from '../../../src/features/goals'
import * as groups from '../../../src/features/groups'
import * as teams from '../../../src/features/teams'
import * as segments from '../../../src/features/segments'
import bendHelper from '../../../src/helpers/bendHelper'

angular.module('app.controllers')
	.controller('GoalsController', ['$scope', '$location', '$ngRedux',
		async function ($scope, $location, $ngRedux) {
			$scope.isLoading = true

			const concatAll = (label, list) => [{ _id: null, name: label }].concat(list)
			$scope.commonUtil = commonUtil

			$scope.stateList = [
				{
					name: 'All',
					value: null
				},
				{
					name: 'Enabled',
					value: true
				}, {
					name: 'Disabled',
					value: false
				}
			]

			$scope.labelStateSelected = selected => {
				if (selected === false) {
					return "Disabled"
				} else if (selected === true) {
					return "Enabled"
				}
				return "All"
			}

			const mapStateToThis = (state) => ({
				goalsList: state.goals.list,
				groups: concatAll('All Groups', state.groups.list.groups),
				teams: concatAll('All Teams', state.teams.list.teams),
				segments: state.segments.list,
				collectionView: {
					itemsPerPage: state.goals.settings.itemsPerPage,
					totalItems: state.goals.settings.totalItems,
					currentPage: state.goals.settings.currentPage,
					numPages: state.goals.settings.numPages
				},
				filter: {
					group: state.goals.settings.group,
					team: state.goals.settings.team,
					segment: state.goals.settings.segment,
					state: state.goals.settings.enabled
				}
			})

			const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
			$scope.$on('$destroy', unsubscribe)


			if ($scope.goalsList.length === 0)
				$scope.isLoading = true


			const loadList = async function () {
				await goals.helpers.init()
				$scope.isLoading = false
			}

			const loading = (cb) => {
				return async (...param) => {
					$scope.isLoading = true
					await cb(...param)
					await loadList()
				}
			}

			await groups.helpers.init()
			await teams.helpers.getList()
			await segments.helpers.init()
			loadList()


			$scope.dateParse = date => moment(date / 1000000).format("YYYY-MM-DD")

			$scope.reset = loading(() => goals.helpers.reset())

			$scope.selectGroup = loading(group => goals.helpers.selectGroup(group))

			$scope.selectTeam = loading(team => goals.helpers.selectTeam(team))

			$scope.selectSegment = loading(segment => goals.helpers.selectSegment(segment))

			$scope.selectState = loading(state => {
				return goals.helpers.selectState(state)
			})

			$scope.deleteGoal = loading(async (id, $ev) => {
				$ev.stopPropagation()
				$ev.preventDefault()
				await goals.helpers.delete(id)

				return
			})

			$scope.enableToggle = async (item, e) => {
				await bendHelper.enableHelper(e, item, goals)
			}

			$scope.editGoal = (goal, $event) => {
				if ($event.metaKey || $event.ctrlKey) {
					window.open('#/goals/' + goal._id, '_blank')
				} else { return $location.path('/goals/' + goal._id) }
			}

			$scope.createGoal = $event => {
				if ($event.metaKey || $event.ctrlKey) {
					window.open('#/goals/new', '_blank')
				} else {
					return $location.path('/goals/new')
				}
			}

			$scope.exportGoals = () => {
				return $scope.goalsList.map(row => {
					return {
						...row,
						startDate: $scope.dateParse(row.startDate),
						endDate: $scope.dateParse(row.endDate),
						totalCompleted: row.totalCompleted || 0,
						totalStarted: row.totalStarted || 0,
					}
				})
			}

			$scope.exportGoalsActivity = async () => {
				const arrayFind = (userArray, scopeArray) => userArray.map(userValue => scopeArray.find(scopeValue => scopeValue._id == userValue).name).join('; ')

				const goalsActivities = await goals.helpers.getAllGoalsActivity()
				const qwe = goalsActivities.map((row) => {
					const { user, goal } = row

					const teams = user.teams ? arrayFind(user.teams, $scope.teams) : ''
					const segments = user.segments ? arrayFind(user.segments, $scope.segments) : ''
					const group = user.userGroup ? $scope.groups.find(group => group._id == user.userGroup._id).name : ''
	
					return {
						...row,
						goal: goal.title,
						teamNames: teams,
						segmentNames: segments,
						userGroupName: group,
						completedOn: (row.completedOn ? $scope.dateParse(row.completedOn) : null),
						created: $scope.dateParse(row._bmd.createdAt),
						lastActivityOn: $scope.dateParse(row._bmd.updatedAt)
					}
				})
				return qwe
			}

		}])
