
import * as _ from 'lodash'

import {
  UPDATE_SURVEY_QUESTIONS_SETTINGS,
  UPDATE_SURVEY_QUESTIONS_PAGE_SETTINGS,
  UPDATE_SURVEY_QUESTIONS_COMMUNITY_SETTINGS,
  UPDATE_SURVEY_QUESTIONS_TEAMS_SETTINGS,
  UPDATE_SURVEY_QUESTIONS_TEAM_SETTINGS,
  RESET_SURVEY_QUESTIONS_SETTINGS
} from '../constants/types'

import { Action } from '../actions/settings'

export interface Settings {
  itemsPerPage: number,
  isLoading: boolean,
  totalItems: number,
  currentPage: number,
  numPages: number,
  communityId: any,
  communityTeams: any,
  team: any
}

const initialState: Settings = {
  itemsPerPage: 20,
  isLoading: true,
  totalItems: 0,
  currentPage: 1,
  numPages: 0,
  communityId: '',
  communityTeams: [],
  team: {}
}

const dropdownItem = {
  name: 'All Teams',
  _id: null
}

export default (state: Settings = initialState, action: Action): Settings => {
  switch (action.type) {
    case UPDATE_SURVEY_QUESTIONS_SETTINGS:
      return {
        ...state,
        totalItems: action.count,
        numPages: Math.ceil(action.count / state.itemsPerPage + 1)
      }
    case UPDATE_SURVEY_QUESTIONS_PAGE_SETTINGS:
      return {
        ...state,
        currentPage: action.page
      }
    case UPDATE_SURVEY_QUESTIONS_COMMUNITY_SETTINGS:
      return {
        ...state,
        communityId: action.communityId || ''
      }
    case UPDATE_SURVEY_QUESTIONS_TEAMS_SETTINGS:
      const teamsList = _.without(action.communityTeams)
      const sortedList = _.orderBy(teamsList, (i: any) => i.name.toLowerCase(), ['asc'])
      const communityTeams = [
        dropdownItem,
        ...sortedList
      ]
      return {
        ...state,
        communityTeams
      }
    case UPDATE_SURVEY_QUESTIONS_TEAM_SETTINGS:
      return {
        ...state,
        team: action.team || {}
      }
    case RESET_SURVEY_QUESTIONS_SETTINGS:
      return initialState
    default:
      return state
  }
}
