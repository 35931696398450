import {
  UPDATE_ACTIONS_SETTINGS,
  UPDATE_ACTIONS_PAGE_SETTINGS,
  UPDATE_ACTIONS_SEARCH_TERM_SETTINGS,
  UPDATE_ACTIONS_COMMUNITY_SETTINGS,
  UPDATE_ACTIONS_CATEGORY_SETTINGS,
  UPDATE_ACTIONS_COLLECTION_SETTINGS,
  RESET_ACTIONS_SETTINGS
} from '../constants/types'
import { Settings } from '../reducers/settings'

export interface Action {
  type: string,
  users: Settings,
  count: number,
  page: number,
  keyword: string,
  community: any,
  searchTerm: string,
  communityId: string,
  collectionId: string,
  selectedState: string,
  categoryId: string,
}

export const update = (count: number) => {
  return {
    type: UPDATE_ACTIONS_SETTINGS,
    count
  }
}

export const pageUpdate = (page: number) => {
  return {
    type: UPDATE_ACTIONS_PAGE_SETTINGS,
    page
  }
}

export const selectSearchTerm = (searchTerm) => {
  return {
    type: UPDATE_ACTIONS_SEARCH_TERM_SETTINGS,
    searchTerm
  }
}

export const selectCommunity = (communityId) => {
  return {
    type: UPDATE_ACTIONS_COMMUNITY_SETTINGS,
    communityId
  }
}

export const selectCategory = (categoryId) => {
  return {
    type: UPDATE_ACTIONS_CATEGORY_SETTINGS,
    categoryId
  }
}

export const selectCollection = (collectionId) => {
  return {
    type: UPDATE_ACTIONS_COLLECTION_SETTINGS,
    collectionId
  }
}

export const reset = () => {
  return {
    type: RESET_ACTIONS_SETTINGS
  }
}
