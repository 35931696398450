import {
  UPDATE_ACTIVITIES_LIST
} from '../constants/types'
import { Activities } from '../reducers/list'

export interface Action {
  type: string,
  activities: Activities
}

export const update = (activities: Activities): Action => {
  return {
    type: UPDATE_ACTIVITIES_LIST,
    activities
  }
}
