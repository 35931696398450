import BendClient, { BendTable } from '../BendClient'

class Feature {

  private api: any
  private api2: any

  constructor () {
    this.api = BendClient
    this.api2 = BendTable
  }

  public getCommunityList () {
    return this.api.getCommunityList()
  }

  public getActivity (id) {
    return this.api.getActivity(id)
  }

  public update (data) {
    return this.api2.update(BendTable.Tables.ACTIVITY, data)
  }

  public delete (id) {
    return this.api2.delete(BendTable.Tables.ACTIVITY, id)
  }

  public fetchList (settings) {
    return this.api.getUsersActivityTabList(settings)
  }

  public getImages (settings: { id: string[] }) {
    return this.api.fetchImagesById(settings)
  }

  public listNavigation (settings) {
    this.api.getUsersActivityTabListNavigation(settings).then(res => {
    })
    return this.api.getUsersActivityTabListNavigation(settings)
  }

  public refreshActivities (settings: { keyword: string, type: string }) {
    return this.api.refreshActivities(settings)
  }

}

export default new Feature()
