import { Action } from '../actions/list'
import {
  UPDATE_TAGS_LIST
} from '../constants/types'

export type Tags = Array<string>

const initialState: Tags = []

export default (state: Tags = initialState, action: Action): Tags => {
  switch (action.type) {
    case UPDATE_TAGS_LIST:
      return action.tags
    default:
      return state
  }
}
