'use strict'

import * as communities from '../../../src/features/communities'
import * as teams from '../../../src/features/teams'
import * as users from '../../../src/features/users'

angular.module('app.controllers')
  .controller('TeamController', ['$scope', '$location', '$routeParams', 'BendAuth', 'CommonUtil', '$ngRedux',
    async function ($scope, $location, $routeParams, BendAuth, CommonUtil, $ngRedux) {
      $scope.CommonUtil = CommonUtil
      $scope.isLoading = true
      $scope.user = BendAuth.getActiveUser()

      const communityId = _.get($scope.user, 'communityAdmin')
        ? _.get($scope.user, 'community._id')
        : $routeParams.id

      const mapStateToThis = (state) => {
        return {
          communityList: state.communities.list,
          teamList: state.teams.list.teams,
          teamsMembers: state.teams.list.teamsMembers,
          usersList: state.users.list,
          filter: {
            community: state.teams.settings.community
          },
          sortKey: state.teams.settings.sortKey,
          sortDirection: state.teams.settings.sortDirection
        }
      }

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      if ($scope.teamList.length === 0) {
        $scope.isLoading = true
      }

      $scope.getTeamList = async () => {
        await users.helpers.getUsersOnly()
        await teams.helpers.init(communityId)
        await teams.helpers.updateTeamsMembers()
        $scope.isLoading = false
      }

      if ($scope.user.communityAdmin) {
        $scope.getTeamList()
      } else {
        await communities.helpers.init()
        if (typeof $scope.filter.community === 'object' && !$scope.filter.community._id) {
          teams.helpers.selectCommunity(null)
        }
        $scope.getTeamList()
      }

      $scope.countUsers = (teamId) => {
        if ($scope.teamsMembers[teamId]) { // must use bracket notation bc accessing key of this variable
          return $scope.teamsMembers[teamId].length
        }
      }

      $scope.handleCommunitySelect = () => {
        const id = $scope.filter.community && $scope.filter.community._id
        teams.helpers.selectCommunity(id)
        $scope.getTeamList()
      }

      $scope.editTeam = function (team, $event) {
        if ($event.metaKey || $event.ctrlKey) {
          window.open('#/teams/' + team._id, '_blank')
        } else { return $location.path('/teams/' + team._id) }
      }

      $scope.createTeam = function ($event) {
        if ($event.metaKey || $event.ctrlKey) {
          window.open('#/teams/new', '_blank')
        } else { return $location.path('/teams/new') }
      }

      $scope.sortBy = (key) => {
        $scope.isLoading = true
        let direction = $scope.sortDirection
        if (direction[key]) direction[key] = (-1) * direction[key]
        else direction[key] = -1
        teams.helpers.sortBy(key, direction)
        $scope.isLoading = false
      }

      // var oldPosList = []
      var oldTeamPosList = []
      $scope.enableSortable = function () {
        $('#teams-container').sortable({
          opacity: 0.5,
          dropOnEmpty: true,
          start: function (event, ui) {
            ui.item[0].style.backgroundColor = '#eee'
            oldTeamPosList = []
            $scope.teamList.map(function (o) {
              oldTeamPosList[o._id] = o.position
            })
          },
          update: function (event, ui) {
            $('.team-content').each(function (idx) {
              var teamKey = $(this).find('#teamId-input')[0].value
              var team = _.find($scope.teamList, function (_o) {
                return _o._id == teamKey
              })
              if (team.position != idx + 1) {
                team.position = idx + 1
                // update team
                teams.helpers.update(team)
              }
            })

            applyChangesOnScope($scope, function () {
              $scope.teamList = _.sortBy($scope.teamList, function (o) {
                return o.position
              })
            })
            ui.item[0].style.backgroundColor = ''
          }
        })
        $('#teams-container').sortable('option', 'disabled', false)
      }
    }])
