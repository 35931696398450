import {
  UPDATE_EVENT_SETTINGS,
  UPDATE_EVENT_PAGE_SETTINGS,
  RESET_EVENT_SETTINGS,
  UPDATE_EVENT_COMMUNITY_SETTINGS,
  UPDATE_EVENT_CATEGORY_SETTINGS,
  UPDATE_EVENT_COLLECTION_SETTINGS,
  UPDATE_EVENT_KEYWORD_SETTINGS,
  UPDATE_EVENT_SORTING_SETTINGS
} from '../constants/types'
import { Action } from '../actions/settings'

export interface Settings {
  searchTerm: any,
  itemsPerPage: number,
  isLoading: boolean,
  totalItems: number,
  currentPage: number,
  numPages: number,
  communityId: string,
  collection: object,
  category: object,
  sortKey: string,
  sortDirection: object
}

const initialState: Settings = {
  searchTerm: '',
  itemsPerPage: 20,
  isLoading: true,
  totalItems: 0,
  currentPage: 1,
  numPages: 0,
  communityId: '',
  collection: {
    _id: null
  },
  category: {
    _id: null
  },
  sortKey: 'name',
  sortDirection: {
    'name': 1,
    'startsAt': 1,
    'endsAt': 1,
    '_bmd.updatedAt': 1
  }
}

export default (state: Settings = initialState, action: Action): Settings => {
  switch (action.type) {
    case UPDATE_EVENT_SETTINGS:
      return {
        ...state,
        totalItems: action.count,
        numPages: Math.ceil(action.count / state.itemsPerPage)
      }
    case UPDATE_EVENT_PAGE_SETTINGS:
      return {
        ...state,
        currentPage: action.page
      }
    case UPDATE_EVENT_COMMUNITY_SETTINGS:
      return {
        ...state,
        communityId: action.communityId
      }
    case UPDATE_EVENT_CATEGORY_SETTINGS:
      return {
        ...state,
        category: action.category
      }
    case UPDATE_EVENT_COLLECTION_SETTINGS:
      return {
        ...state,
        collection: action.collection
      }
    case UPDATE_EVENT_KEYWORD_SETTINGS:
      return {
        ...state,
        searchTerm: action.keyword
      }
    case UPDATE_EVENT_SORTING_SETTINGS:
      return {
        ...state,
        sortKey: action.sortKey,
        sortDirection: action.sortDirection
      }
    case RESET_EVENT_SETTINGS:
      return initialState
    default:
      return state
  }
}
