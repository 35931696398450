import {
  UPDATE_LEADERBOARD_SETTINGS,
  UPDATE_LEADERBOARDS_COMMUNITY_SETTINGS,
  UPDATE_LEADERBOARDS_CATEGORY_SETTINGS,
  UPDATE_LEADERBOARDS_PAGE_SETTINGS,
  UPDATE_LEADERBOARDS_TOTAL_POINTS_SETTINGS,
  RESET_LEADERBOARDS_SETTINGS,
  UPDATE_LEADERBOARDS_SPRINT_SETTINGS,
  UPDATE_LEADERBOARDS_ITEMS_PER_PAGE,
  UPDATE_LEADERBOARDS_HAS_MORE
} from '../constants/types'

export interface Action {
  type: string,
  count?: number,
  itemsPerPage?: number,
  community?: any,
  category?: any,
  page?: any,
  totalPoints?: any,
  sprint?: any,
  more?: any,
}

export const update = (count: number): Action => {
  return {
    type: UPDATE_LEADERBOARD_SETTINGS,
    count
  }
}

export const communityUpdate = (community) => {
  return {
    type: UPDATE_LEADERBOARDS_COMMUNITY_SETTINGS,
    community
  }
}

export const categoryUpdate = (category) => {
  return {
    type: UPDATE_LEADERBOARDS_CATEGORY_SETTINGS,
    category
  }
}

export const sprintUpdate = (sprint) => {
  return {
    type: UPDATE_LEADERBOARDS_SPRINT_SETTINGS,
    sprint
  }
}

export const pageUpdate = (page) => {
  return {
    type: UPDATE_LEADERBOARDS_PAGE_SETTINGS,
    page
  }
}

export const updateItemsPerPage = (itemsPerPage: number | undefined): Action => {
  return {
    type: UPDATE_LEADERBOARDS_ITEMS_PER_PAGE,
    itemsPerPage
  }
}

export const updateTotalPoint = (totalPoints) => {
  return {
    type: UPDATE_LEADERBOARDS_TOTAL_POINTS_SETTINGS,
    totalPoints
  }
}

export const reset = () => {
  return {
    type: RESET_LEADERBOARDS_SETTINGS
  }
}

export const hasMore = (more) => {
  return {
    type: UPDATE_LEADERBOARDS_HAS_MORE,
    more
  }
}