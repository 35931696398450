import {
  UPDATE_PLACES_LIST
} from '../constants/types'
import { Places } from '../reducers/list'

export interface Action {
  type: string,
  places: Places
}

export const update = (places: Places): Action => {
  return {
    type: UPDATE_PLACES_LIST,
    places
  }
}
