import BendClient, { BendTable } from '../BendClient'
import * as actions from './actions'
import redux from '../../../src/helpers/redux'

class Feature {

  private api: any
  private api2: any

  constructor () {
    this.api = BendClient
    this.api2 = BendTable
  }

  public async init () {
   this.getList()
  }

  public async getList () {
    const settings = redux.getState().certifications.settings
    const params = {
      relations: {
        badge: 'BendFile'
      }
    }
    const certifications = await this.api2.getList(BendTable.Tables.CERTIFICATION, settings, params)
    redux.dispatch(
      actions.list.update(certifications)
    )
    redux.dispatch(
      actions.settings.update(certifications.length)
    )
  }

  public create (certificationData) {
    return this.api2.create(BendTable.Tables.CERTIFICATION, certificationData)
  }

  public update (certificationData) {
    return this.api2.update(BendTable.Tables.CERTIFICATION, certificationData)
  }

  public delete (id) {
    return this.api2.delete(BendTable.Tables.CERTIFICATION, id)
  }

  public upload (file, callback, ext, progressCallback) {
    this.api.upload(file, callback, ext, progressCallback)
  }

  public getFile (refObj, callback) {
    this.api.getFile(refObj, callback)
  }

  // holding on to this method as we will probably use it when we do the forms redux refactor
  // public selectCertification (id) {
  //   let selected = redux.getState().certifications.list.find(item => item._id === id)
  //   redux.dispatch(
  //     actions.settings.editCertification(selected)
  //   )
  // }
}

export default new Feature()
