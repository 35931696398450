import * as _ from 'lodash'
import BendClient, { BendTable } from '../BendClient'
import commonUtil from '../../helpers/commonUtil'
import * as actions from './actions'
import { getActiveUser } from '../../../src/helpers'
import redux from '../../../src/helpers/redux'
import * as moment from 'moment'

class Feature {

  private api: any
  private api2: any

  constructor() {
    this.api = BendClient
    this.api2 = BendTable
  }

  public initBend() {
    return BendTable.init()
  }

  public login(credentials) {
    return this.api.login(credentials)
  }

  public setActiveUser(userData) {
    this.api.setActiveUser(userData)
  }

  public async reset() {
    const user = getActiveUser()
    // Preserve teams to maintain teams filter
    const teamsList = redux.getState().users.settings.teams
    if (!user.community.admin) { // for milkcrate admins
      redux.dispatch(
        actions.settings.reset()
      )
      redux.dispatch(
        // Do not include teamsList[0] - settings.teamsUpdate adds it by default
        actions.settings.teamsUpdate(teamsList.slice(1))
      )
      this.selectCommunity(null)
      return this.init()
    } else {
      redux.dispatch(
        actions.settings.reset()
      )
      this.getList()
    }
  }

  public async init() {
    this.getList()
  }

  public async getList() {
    const settings = redux.getState().users.settings
    const related = {
      relations: {
        avatar: 'BendFile',
        community: 'community'
      }
    }
    // const users = await this.api2.getList(BendTable.Tables.USER, settings, related)
    const users = await this.api2.getPagedList(BendTable.Tables.USER, settings, related)
    // const { users } = await this.api.fetchUsers(settings)
    const count = await this.api2.count(BendTable.Tables.USER, settings)
    redux.dispatch(
      actions.list.update(users)
    )
    redux.dispatch(
      actions.settings.update(count)
    )
    return users; //We return the users in this case so we can use it to load the push users
  }

  // only gets 20?
  // public async performance_init () {
  //   const settings = redux.getState().users.settings
  //   this.api.performance_countUsers(settings).then((count) => {
  //     redux.dispatch(
  //       actions.settings.update(count)
  //     )
  //   })
  //   return new Promise((resolve, reject) => {
  //     this.api.performance_fetchUsers(settings).then((users) => {
  //       redux.dispatch(
  //         actions.list.update(users)
  //       )
  //       resolve(users)
  //     })
  //   })
  // }

  // public async getList () {
  //   return this.api2.getList(BendTable.Tables.USER)
  // }

  public getUserByEmail(email: string) {
    return this.api2.getList(BendTable.Tables.USER, { email })
  }

  public async getUsersOnly(communityId) {
    const users = await this.api.fetchUsersOnly(communityId)
    redux.dispatch(
      actions.list.update(users)
    )
  }

  public paginateToPage(page) {
    redux.dispatch(
      actions.settings.pageUpdate(page)
    )
  }

  public handleSearchKeyword(keyword) {
    redux.dispatch(
      actions.settings.searchKeywordUpdate(keyword)
    )
    this.getList()
  }

  public selectTeam(team) {
    redux.dispatch(
      actions.settings.selectTeam(team)
    )
  }

  public async fetchCommunityTeams() {
    const settings = redux.getState().users.settings
    // const teams = await this.api.fetchCommunityTeams(settings.communityId)
    const teams = await this.api2.getList(BendTable.Tables.TEAM, { communityId: settings.communityId })
    redux.dispatch(
      actions.settings.teamsUpdate(teams)
    )
  }

  public selectCommunity(id) {
    redux.dispatch(
      actions.settings.communityUpdate(id)
    )
    this.getList()
  }

  public updateItemsPerPage(num) {
    let input
    if (typeof num === 'number') input = num
    else input = null
    redux.dispatch(
      actions.settings.updateItemsPerPage(input)
    )
    this.getList()
  }

  public async selectCommunityUsers(id) {
    redux.dispatch(
      actions.settings.communityUpdate(id)
    )
    this.getList()
    // const settings = redux.getState().users.settings

    // let { users } = await this.api.fetchUsers(settings)
    // redux.dispatch(
    //   actions.list.update(users)
    // )
    // redux.dispatch(
    //   actions.settings.update(users.length)
    // )
  }

  // public async export () {
  //   const settings = redux.getState().users.settings
  //   const exportsData = await this.api.exportUsers(settings)
  //   redux.dispatch(
  //     actions.exports.update(exportsData)
  //   )
  // }

  public async export() {
    const settings = redux.getState().users.settings
    // const exportsData = await this.api.exportUsers(settings)
    const users = await this.api2.getList(BendTable.Tables.USER, settings)
    let exportData: Array<any> = []
    users.map(user => {
      let ex = {
        name: user.name,
        email: user.email,
        birthdate: user.birthdate,
        joinedOn: moment(user._bmd.createdAt / 1000000).format("YYYY-MM-DD"),
        teams: `${user.teams || ''}`,
        segments: `${user.segments || ''}`,
        segmentsNames: `${user.segmentsNames || ''}`,
        connections: `${user.connections || ''}`
      }
      exportData.push(ex)
    })
    redux.dispatch(
      actions.exports.update(exportData)
    )
  }

  public getCommunityList() {
    return this.api.getCommunityList()
  }

  // public getCategory () {
  //   return this.api.getCategoryList()
  // }

  public upload(file, callback, ext, progressCallback) {
    return this.api.upload(file, callback, ext, progressCallback)
  }

  public getFile(refObj, callback) {
    return this.api.getFile(refObj, callback)
  }

  public async get(userId) {
    const params = {
      relations: {
        avatar: 'BendFile',
        coverImage: 'BendFile',
        community: 'community'
      }
    }
    return this.api2.get(BendTable.Tables.USER, userId, params)
  }

  public async getListComplete() {
    return this.api.getUsersWithAvatar()
  }


  public async create(data) {
    return this.api2.create(BendTable.Tables.USER, data)
  }

  public async update(data) {
    return this.api.updateUser(data)
  }

  public async delete(id) {
    return this.api.deleteUser(id)
  }

  public getUserAddress(id) {
    return this.api.getUserAddress(id)
  }

  public saveUserAddress(data) {
    return this.api2.save(BendTable.Tables.USER_ADDRESS, data)
  }

  public getCommunityEnabledList() {
    return this.api.getCommunityEnabledList()
  }

  public getAdminList(BendAuth, callback) {
    const authString = 'Bend ' + getActiveUser().auth.token
    $.ajax({
      url: BendAuth.URL + BendAuth.APP_KEY + '/' + BendAuth.GROUP_ID,
      headers: { 'Authorization': authString }
    }).then(function (ret) {
      const adminList = ret.users.list
      callback(null, adminList)
    }, function (err) {
      callback(err)
    })
  }

  public setUserAsAdmin(BendAuth, userId, isAdmin, callback) {
    const authString = 'Bend ' + getActiveUser().auth.token
    $.ajax({
      url: BendAuth.URL + BendAuth.APP_KEY + '/' + BendAuth.GROUP_ID,
      headers: { 'Authorization': authString }
    }).then(function (ret) {
      let adminList = ret.users.list

      let adminUser = _.find(adminList, function (o) {
        return o._id === userId
      })

      if (isAdmin) { // add
        if (adminUser) {
          // nothing do
          callback(null)
        } else {
          adminList.push(commonUtil.makeBendRef(userId, 'user'))
        }
      } else { // remove
        if (adminUser) {
          let idx = adminList.indexOf(adminUser)
          adminList.splice(idx, 1)
        } else {
          // nothing do
          callback(null)
        }
      }

      $.ajax({
        url: BendAuth.URL + BendAuth.APP_KEY + '/' + BendAuth.GROUP_ID,
        headers: { 'Authorization': authString },
        type: 'PUT',
        data: JSON.stringify(ret)
      }).then(function (ret) {
        callback(null)
      }, function (err) {
        callback(err)
      })
    }, function (err) {
      callback(err)
    })
  }

  public getImages(settings: { id: string[] }) {
    return this.api.fetchImagesById(settings)
  }

  public fetchActivityTabList(settings: {
    userId: string,
    itemsPerPage: number,
    currentPage: number
  }) {
    return this.api.fetchUsersActivityTabList(settings)
  }

  public fetchGoalsTabList(settings: {
    userId: string,
    itemsPerPage: number,
    currentPage: number
  }) {
    return this.api.fetchUsersGoalsTabList(settings)
  }

  public fetchActivityTabListNavigation(settings: {
    userId: string,
    itemsPerPage: number,
    currentPage: number
  }) {
    return this.api.fetchUsersActivityTabListNavigation(settings)
  }

  public getAvatar() {
    return this.api.getAvatar()
  }

}

export default new Feature()
