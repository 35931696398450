import { Action } from '../actions/list'
import {
  UPDATE_CHALLENGES_LIST
} from '../constants/types'

export interface Challenge {

}

export type Challenges = Array<Challenge>

const initialState: Challenges = []

export default (state: Challenges = initialState, action: Action): Challenges => {
  switch (action.type) {
    case UPDATE_CHALLENGES_LIST:
      return action.challenges
    default:
      return state
  }
}
