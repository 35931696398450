import { Action } from '../actions/list'
import {
  UPDATE_SURVEY_QUESTIONS_LIST
} from '../constants/types'

export interface Item {
  any
}

export type SurveyQuestions = Array<any>

const initialState: SurveyQuestions = []

export default (state: SurveyQuestions = initialState, action: Action | any): SurveyQuestions => {
  switch (action.type) {
    case UPDATE_SURVEY_QUESTIONS_LIST:
      return action.surveyQuestions
    default:
      return state
  }
}
