import { combineReducers } from 'redux'
import list, { Goals } from './list'
import settings from './settings'

export interface CombineReducers {
  list: Goals
}

const rootReducer = combineReducers<CombineReducers>({
  list,
  settings
})

export default rootReducer
