'use strict'

import commonUtil from '../../../src/helpers/commonUtil'
import * as teams from '../../../src/features/teams'
import * as segmentTypes from '../../../src/features/segmentTypes'
import * as groups from '../../../src/features/groups'
import bendHelper from '../../../src/helpers/bendHelper'

const optionList = [{
  name: 'Demographics',
  fields: [
    { name: 'race', text: 'Race' },
    { name: 'gender', text: 'Gender' },
    { name: 'zipCode', text: 'Zip Code' }
  ]
}, {
  name: 'About',
  fields: [
    { name: 'name', text: 'First & Last Name' },
    { name: 'alias', text: 'Alias' },
    { name: 'profileRole', text: 'Role' },
    { name: 'profileBio', text: 'Bio' },
    { name: 'profileIndustry', text: 'Industry' },
    { name: 'profileSchool', text: 'School' },
    { name: 'profileSchoolYear', text: 'School Year' },
    { name: 'profileOrganization', text: 'Organization' },
    { name: 'profileLocation', text: 'Location' }
  ]
}, {
  name: 'Social Links',
  fields: [
    { name: 'profileUrl', text: 'Website' },
    { name: 'profileLinkedin', text: 'LinkedIn' },
    { name: 'profileTwitter', text: 'Twitter' },
    { name: 'profileSnapchat', text: 'SnapChat' },
    { name: 'profileInstagram', text: 'Instagram' },
    { name: 'profileFacebook', text: 'Facebook' },
    { name: 'profileTelephone', text: 'Phone Number' }
  ]
  }
]

const configList = [{
    name: 'Quick Stats',
    fields: [
      { name: 'profileQuickstatsEnabled', text: 'Complete Section' },
      { name: 'profileQuickstatsPointsEnabled', text: 'Points' },
      { name: 'profileQuickstatsHoursEnabled', text: 'Hours' },
      { name: 'profileQuickstatsLeaderboardEnabled', text: 'Leaderboard' },
      { name: 'profileQuickstatsConnectionsEnabled', text: 'Connections' }
    ]
  }
]

angular.module('app.controllers')
  .controller('UserGroupsEditController', ['$scope', '$location', '$routeParams', 'BendAuth', '$rootScope', '$modal', '$ngRedux',
    async function ($scope, $location, $routeParams, BendAuth, $rootScope, $modal, $ngRedux) {

      // Init.
      $scope.CommonUtil = commonUtil
      $scope.groupId = null
      $scope.optionList = optionList
      $scope.configList = configList
      $scope.editMode = false
      if ($routeParams.id != 'new') {
        $scope.groupId = $routeParams.id
        $scope.editMode = true
      }
      $scope.isLoading = true
      $scope.user = BendAuth.getActiveUser()

      teams.helpers.getList()
      segmentTypes.helpers.init();

      $scope.formData = {
        communities: [],
        segmentTypes: [],
        teams: []
      }

      $scope.group = {
        enabled: true,
        fields: {},
        config: {}
      }

      const formDataKeys = Object.keys($scope.formData)

      const mapStateToThis = (state) => {

        if (state.teams.list.teams && state.teams.list.teams.length > 0 && $scope.group.teams && $scope.group.teams.length > 0) {
          $scope.formData.teams = _.filter($scope.teams, (o) => $scope.group.teams.indexOf(o._id) != -1)
        }
        if (state.segmentTypes.list.segmentTypes && state.segmentTypes.list.segmentTypes.length > 0 && $scope.group.segmentTypes && $scope.group.segmentTypes.length > 0) {
          $scope.formData.segmentTypes = _.filter($scope.segmentTypes, (o) => $scope.group.segmentTypes.indexOf(o._id) != -1)
        }
        return {
          segmentTypes: state.segmentTypes.list,
          teams: state.teams.list.teams
        }

      }
      
      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      $scope.fileProgress = []
      $scope.isUploading = []
      
      if ($scope.groupId) { // if challenge exists
        groups.helpers.get($scope.groupId).then((ret) => {
          
          $scope.group = _.clone(ret)
          $scope.oldGroup = _.clone(ret)
          $scope.isLoading = false

          if ($scope.group.segmentTypes && $scope.group.segmentTypes.length > 0) {
            $scope.formData.segmentTypes = _.filter($scope.segmentTypes, (o) => $scope.group.segmentTypes.indexOf(o._id) != -1)
          }
          if ($scope.group.teams && $scope.group.teams.length > 0) {
            $scope.formData.teams = _.filter($scope.teams, (o) => $scope.group.teams.indexOf(o._id) != -1)
          }
          applyChangesOnScope($scope, () => {})
        })
      } else if ($routeParams.id === 'new') {
        $scope.isLoading = false
      }

      $scope.isValidForm = function () {
        let isValid = $scope.form.validateForm.$dirty && $scope.form.validateForm.$valid
        if ($scope.isSaving) {
          isValid = false
        }

        return isValid
      }

      // form validation using angular ng-class
      $scope.validateForm = () => {
        const required = 'required'

        const name = _.get($scope.group, 'name')
        const icon = _.get($scope.group, 'icon')

        // generic checks
        if (name === '' || !name) $scope.nameReq = required
        if (icon === '' || !icon) $scope.iconReq = required

        const isValid = ((!name || name.length < 1) || !icon);

        if (isValid) {
          $scope.errorMsg = true
          return false
        }
        return true
      
      }

      $scope.saveGroup = async () => {
        if (!$scope.validateForm()) return

        const groupData = _.clone($scope.group)
        if (groupData.icon) {
          groupData.icon = commonUtil.makeBendFile(groupData.icon._id)
        }

        formDataKeys.map((key) => {
          if ($scope.formData[key] && $scope.formData[key].length > 0) {
            groupData[key] = $scope.formData[key].map((obj) => obj._id)
          } else {
            delete groupData[key]
          }
        })

        if ($scope.groupId) { // if challenge exists
          $scope.isSaving = true
          delete groupData.$$hashKey
          groups.helpers.update(groupData)
        } else { // if new challenge
          $scope.isSaving = true
          groups.helpers.create(groupData)
        }
        $location.path('/groups') // moved this call before API because save animation would go and page would never redirect. this still saves data
      }

      $scope.deleteFile = function (tag, $ev) {
        $ev.stopPropagation()
        $ev.preventDefault()
        applyChangesOnScope($scope, () => {
          delete $scope.group[tag]
        })
      }

      $scope.deleteGroup = async (id, e) => {
        e.preventDefault()
        e.stopPropagation()
        const yeap = confirm('Deleting the group is permanent and can not be undone.')
        if (!yeap) {
          return false
        }

        const inUse = await groups.helpers.isInUse(id)
        if (inUse) {
          $scope.errorMessage = 'Group is being used, cannot be deleted.'
          $scope.$apply()
        } else {
          const response = await groups.helpers.delete(id)
          if (!response) return false

          applyChangesOnScope($scope, () => {
            $location.path('/groups')
          })
        }
      }

      $scope.showFileLoading = function (tag, bShow) {
        $scope.isUploading[tag] = bShow
      }

      $scope.selectFileOpen = function (fileId, $ev) {
        if ($ev.target.tagName == 'DIV') {
          setTimeout(() => {
            $('#' + fileId).click()
          }, 0)
        }
      }
 
      $scope.onFileUpload = function (files, tag) {
        const file = files[0]
        $scope.fileProgress[tag] = 0
        $scope.showFileLoading(tag, true)
        const reader = new FileReader()
        reader.onload = function (e) {
          $scope.uploadingFileUrl = e.target.result
        }
        reader.readAsDataURL(file)

        bendHelper.upload(file, (error, uploadedFile) => {
          if (error) {
            $scope.showFileLoading(tag, false)
            $scope.uploadingFileUrl = null
            return
          }
          bendHelper.getFile(uploadedFile, (o) => {
            $scope.group[tag] = o
            $scope.group.icon = o
            $scope.showFileLoading(tag, false)
            $scope.form.validateForm.$dirty = true
            $scope.uploadingFileUrl = null
          })
        }, {
          _workflow: 'coverPhoto'
        }, (total, prog) => {
          applyChangesOnScope($scope, () => {
            $scope.fileProgress[tag] = prog * 100 / total
          })
        })
      }

      $rootScope.fileUploadFromSearch2 = function (file, tag) {
        file._filename = Date.now() + ''
        const files = []
        files.push(file)
        $scope.onFileUpload(files, tag)
      }

      $scope.searchImages2 = function ($ev, tag) {
        $ev.stopPropagation()
        $ev.preventDefault()
        $modal.open({
          templateUrl: 'views/templates/searchImages.html',
          backdrop: 'static',
          controller: function ($scope, $modalInstance) {
            $scope.searchResults = 0
            $scope.pages = 1
            $scope.showLoadMore = false
            // var cacheSearchKey = ''

            $scope.cancel = function () {
              $modalInstance.dismiss('cancel')
            }
            $scope.close = function () {
              $modalInstance.dismiss('cancel')
            }

            $scope.searchImages = function (searchVal) {
              // cacheSearchKey = searchVal

              $scope.pages = 1
              if (searchVal) {
                const URL = 'https://pixabay.com/api/?key=2706353-ee3016f8af51ca406a8c8a3db&q=' + encodeURIComponent(searchVal) + '&image_type=photo&page=' + $scope.pages
                $.getJSON(URL, (data) => {
                  if (parseInt(data.totalHits) > 0) {
                    applyChangesOnScope($scope, () => {
                      if (data.totalHits > $scope.pages * 20) { $scope.showLoadMore = true } else { $scope.showLoadMore = false }

                      $scope.searchResults = data.hits
                    })
                  }
                })
              }
            }

            $scope.searchImagesMore = function (tabIdx, searchVal) {
              $scope.pages++
              if (searchVal) {
                const URL = 'https://pixabay.com/api/?key=2706353-ee3016f8af51ca406a8c8a3db&q=' + encodeURIComponent(searchVal) + '&image_type=photo&page=' + $scope.pages
                $.getJSON(URL, (data) => {
                  if (parseInt(data.totalHits) > 0) {
                    applyChangesOnScope($scope, () => {
                      if (data.totalHits > $scope.pages * 20) { $scope.showLoadMore = true } else { $scope.showLoadMore = false }

                      $scope.searchResults = $scope.searchResults.concat(data.hits)
                    })
                  }
                })
              }
            }

            $scope.selectImage = function (searchItem) {
              $scope.isDownloading = true
              let imageUrl = ''
              imageUrl = searchItem.webformatURL

              const xhr = new XMLHttpRequest()
              xhr.open('GET', imageUrl, true)
              xhr.responseType = 'blob'
              xhr.onload = function (e) {
                if (this.status == 200) {
                  const myBlob = this.response
                  $rootScope.fileUploadFromSearch2(myBlob, tag)
                  // myBlob is now the blob that the object URL pointed to.
                  $scope.cancel()
                }
              }
              xhr.send()
            }
          }
        })
      }
    }
  ])
