import * as _ from 'lodash'
import * as actions from './actions'
import BendClient, { BendTable } from '../BendClient'
import commonUtil from '../../helpers/commonUtil'
import * as Bend from '../../lib/bend-1.1.8'
import redux from '../../../src/helpers/redux'
import { getActiveUser } from '../../helpers'

class Feature {

  private api: any
  private api2: any

  constructor () {
    this.api = BendClient
    this.api2 = BendTable
  }

  public async reset () {
    redux.dispatch(
      actions.settings.reset()
    )
  }

  public async init () {
    let settings = redux.getState().segmentTypes.settings
    let segmentTypesList: any = []
    segmentTypesList = await this.api2.getList(BendTable.Tables.SEGMENT_TYPE, settings)
    redux.dispatch(
      actions.list.update(segmentTypesList)
    )
  }

  public paginateToPage (page) {
    redux.dispatch(
      actions.settings.pageUpdate(page)
    )
  }

  public update (segmentType) {
    if (!segmentType._id) {
      return this.api2.create(BendTable.Tables.SEGMENT_TYPE, segmentType)
    } else {
      return this.api2.update(BendTable.Tables.SEGMENT_TYPE, segmentType)
    }
  }

  public get (id) {
    return this.api2.get(BendTable.Tables.SEGMENT_TYPE, id)
  }

  public delete (id) {
    this.api2.delete(BendTable.Tables.SEGMENT_TYPE, id)
    this.init()
  }

}

export default new Feature()
