'use strict'

import 'Flot'
import 'Flot/jquery.flot.pie.js'

import commonUtil from '../../helpers/commonUtil'
import * as segments from '.'
import * as segmentTypes from '../segmentTypes'
import { getActiveUser } from '../../helpers'

angular.module('app.controllers')
  .controller('SegmentEditController', ['$scope', '$bend', '$location', '$routeParams', 'BendAuth', '$bootbox', '$rootScope', '$modal', '$http', 'pinesNotifications', '$ngRedux',
    function ($scope, $bend, $location, $routeParams, BendAuth, $bootbox, $rootScope, $modal, $http, pinesNotifications, $ngRedux) {
      // Init.
      $scope.commonUtil = commonUtil

      const mapStateToThis = (state) => {
        return {
          segmentTypeList: state.segmentTypes.list
        }
      }

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      $scope.formData = {
        segmentType: ''
      }

      segmentTypes.helpers.init()

      if ($routeParams.id !== 'new') {
        segments.helpers.get($routeParams.id).then(ret => {
          $scope.segment = ret
          // var segmentTypeIndex = $scope.segmentTypeList.findIndex(t => t === $scope.segment.segmentType._id)
          $scope.formData.segmentType = ret.segmentType
          applyChangesOnScope($scope, () => {})
        })
      } else {
        $scope.segment = {}
      }

      $scope.selectSegmentType = (segment) => {
      }

      $scope.deleteSegment = function (id, e) {
        e.preventDefault()
        e.stopPropagation()
        const yeap = confirm('Deleting the segment is permanent and cannot be undone.')
        if (!yeap) {
          return false
        }
        segments.helpers.delete(id)
        $location.path('/segments')
      }

      // form validation using angular ng-class
      $scope.validateForm = () => {
        const required = 'required'
        const name = _.get($scope.segment, 'name')
        const type = _.get($scope.segment, 'segmentType')
        if (name === '' || !name) $scope.nameReq = required
        if (!type) $scope.typeReq = required
        if (
          $scope.nameReq ||
          $scope.typeReq
        ) {
          $scope.errorMsg = true
          return false
        }
        return true
      }

      $scope.saveSegmentDo = function () {
        $scope.segment.segmentType = commonUtil.makeBendRef($scope.formData.segmentType._id, 'segmentType')
        if (!$scope.validateForm()) return
        var segmentData = _.clone($scope.segment)

        if (!segmentData.community) { // if community exists
          segmentData.community = commonUtil.makeBendRef(getActiveUser().community._id, 'community')
        }
        // save reference to segmentType

        $scope.isSaving = true
        segments.helpers.update(segmentData)
        $location.path('/segments')
      }
    }])
