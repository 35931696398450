'use strict'

import * as challenges from '../../../src/features/challenges'
import * as communities from '../../../src/features/communities'
import * as teams from '../../../src/features/teams'
import { getActiveUser } from '../../../src/helpers'

angular.module('app.controllers')
  .controller('ChallengeController', ['$scope', '$location', '$ngRedux',
    async function ($scope, $location, $ngRedux) {
      $scope.isLoading = true

      const mapStateToThis = (state) => {
        return {
          challengeList: state.challenges.list,
          activities: state.activities.list,
          communityList: state.communities.list,
          teamsList: state.teams.list.teams,
          collectionView: {
            itemsPerPage: state.challenges.settings.itemsPerPage,
            isLoading: state.challenges.settings.isLoading,
            totalItems: state.challenges.settings.totalItems,
            currentPage: state.challenges.settings.currentPage,
            numPages: state.challenges.settings.numPages
          },
          fields: {
            community: state.challenges.settings.community,
            selectedType: state.challenges.settings.selectedType,
            team: state.challenges.settings.team
          },
          // it should have own reducer
          filterTypeList: [{
            name: 'All Challenges',
            value: ''
          }, {
            name: 'Expired Challenges',
            value: '1'
          }, {
            name: 'Unexpired Challenges',
            value: '2'
          }]
        }
      }

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

     await challenges.helpers.init()

      $scope.user = getActiveUser()
      if (!$scope.user.community.admin) {
        communities.helpers.init().then(() => {
          if ($scope.communityList instanceof Array && $scope.communityList.length) {
            // selectCommunity usage are little bit difficult now for this feature
            // it needs refactor like team's selectCommunity method, take a look at team worker class
            if (typeof $scope.fields.community === 'object' && !$scope.fields.community._id) {
              challenges.helpers.selectCommunity($scope.communityList[0])
            }
          }
        })
      }

      if ($scope.filterTypeList instanceof Array && $scope.filterTypeList.length) {
        // selectCommunity usage are little bit difficult now for this feature
        // it needs refactor like team's selectCommunity method, take a look at team worker class
        // if (typeof $scope.fields.community === 'object' && !$scope.fields.community._id) {
        //   challenges.helpers.selectCommunity($scope.communityList[0])
        // }
        if (typeof $scope.fields.selectedType === 'object' && !$scope.fields.selectedType.value) {
          challenges.helpers.selectType($scope.filterTypeList[0])
        }
      }

      teams.helpers.getList().then(() => {
        $scope.isLoading = true
        if ($scope.teamsList instanceof Array && $scope.teamsList.length) {
          // selectCommunity usage are little bit difficult now for this feature
          // it needs refactor like team's selectCommunity method, take a look at team worker class
          // if (typeof $scope.fields.community === 'object' && !$scope.fields.community._id) {
          //   challenges.helpers.selectCommunity($scope.communityList[0])
          // }
          if (typeof $scope.fields.team === 'object' && !$scope.fields.team._id) {
            challenges.helpers.selectTeam($scope.teamsList[0])
          }
        }
       $scope.isLoading = false
      })

     

      if (!$scope.fields.community._id) {
        $scope.communityList = [{
          name: 'All Client',
          _id: null
        }]
      }

      $scope.resetFilters = async () => {
        $scope.isLoading = true
        challenges.helpers.resetFilters()
        await challenges.helpers.init()
        $scope.isLoading = false
      }

      if ($scope.challengeList.length === 0) {
        $scope.isLoading = true
      }
      await challenges.helpers.init()
      
      
      // called in table row to display team names
      $scope.getTeamNames = function (teamIds) {
        if (!teamIds ||
          teamIds.length == 0 ||
          teamIds.length === ($scope.teamsList.length - 1)
        ) {
          return 'All Users'
        }

        var names = []
        _.map($scope.teamsList, function (o) {
          if (teamIds.indexOf(o._id) != -1) { names.push(o.name) }
        })
        if (names.length > 10) return `${names.length} Teams`

        return names.join(',')
      }

      $scope.selectCommunity = async community => {
        $scope.isLoading = true
        challenges.helpers.selectTeam($scope.teamsList[0])
        challenges.helpers.selectCommunity(community)
        await challenges.helpers.fetchChallenges()
        await teams.helpers.getList(community._id)
        // await challenges.helpers.fetchCommunityTeams()
        $scope.isLoading = false
      }

      $scope.selectType = async type => {
        $scope.isLoading = true
        challenges.helpers.selectType(type)
        await challenges.helpers.fetchChallenges()
        $scope.isLoading = false
      }
      $scope.selectTeam = async team => {
        $scope.isLoading = true
        await challenges.helpers.selectTeam(team)
        await challenges.helpers.fetchChallenges()
        $scope.isLoading = false
      }

      $scope.onPageChange = async () => {
        $scope.isLoading = true
        await challenges.helpers.fetchChallenges()
        $scope.isLoading = true
      }

      $scope.editChallenge = function (challenge, $event) {
        if ($event.metaKey || $event.ctrlKey) {
          window.open('#/challenges/' + challenge._id, '_blank')
        } else { return $location.path('/challenges/' + challenge._id) }
      }

      $scope.createChallenge = function ($event) {
        if ($event.metaKey || $event.ctrlKey) {
          window.open('#/challenges/new', '_blank')
        } else { return $location.path('/challenges/new') }
      }

      $scope.getCommunityNames = function (list) {
        var ret = []
        _.map(list, function (o) {
          var client = _.find($scope.communityList, function (_o) {
            return _o._id == o
          })
          if (client) { ret.push(client.name) }
        })

        return ret.join(', ')
      }

      $(document).on('click', '.pagination li:not(.disabled) a', async e => {
        $scope.isLoading = true
        const n = Number($(e.target).text())
        challenges.helpers.paginateToPage(!isNaN(n) ? n : $scope.collectionView.currentPage + ($(e.target).text() === 'Prev' ? -1 : +1))
        await challenges.helpers.init()
        $scope.isLoading = false
      })
    }

  ])
