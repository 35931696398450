import BendClient, { BendTable } from '../BendClient/index'
import * as actions from './actions'
import redux from '../../../src/helpers/redux'
import { getActiveUser } from '../../helpers'
import * as _ from 'lodash'

class Feature {

  private api: any
  private api2: any

  constructor () {
    this.api = BendClient
    this.api2 = BendTable
  }

  public async init () {
    const user = await getActiveUser()
    if (user.communityAdmin) {
      redux.dispatch(
        actions.settings.communityUpdate(user.community._id)
      )
    }
    const { list, settings } = redux.getState().pushTemplates
    const pushTemplatesList = await this.api.fetchPushTemplates(settings)
    if (list.length === 0) {
      this.toggleLoading(true)
    }
    redux.dispatch(
      actions.list.update(pushTemplatesList)
    )
    redux.dispatch(
      actions.settings.update(pushTemplatesList.length)
    )
    this.toggleLoading(false)
  }

  // pagination not yet needed but its ready when we do
  public paginateToPage (page) {
    this.toggleLoading(true)
    redux.dispatch(
      actions.settings.pageUpdate(page)
    )
    this.toggleLoading(false)
  }

  public create (data) {
    const newData = _.clone(data)
    if (newData.obj.params) {
      newData.obj.params.map(function (o) {
        delete o.$$hashKey
      })
    }
    this.toggleLoading(true)
    return this.api2.create(BendTable.Tables.PUSH_TEMPLATE, data)
  }

  public update (data) {
    console.log('update')
    console.log(data)
    const newData = _.clone(data)
    
    delete newData.$$hashKey
    
    this.toggleLoading(true)
    return this.api2.update(BendTable.Tables.PUSH_TEMPLATE, newData)
  }

  public delete (id) {
    this.toggleLoading(true)
    return this.api2.delete(BendTable.Tables.PUSH_TEMPLATE, id)
  }

  public toggleLoading (bool) {
    redux.dispatch(
      actions.settings.toggleLoading(bool)
    )
  }

  public usersCount (settings: {
    audiences: Array<any>,
    defaultQuery: object,
    userQuery: string
  }) {
    return this.api.usersCountPushTemplates(settings)
  }

  public fetchAudiences () {
    return this.api.fetchPushNotificationAudiences()
  }

  // public async fetchDeeplinks () {
  //   const deeplinks = await this.api.fetchPushNotificationDeeplinks()
  //   redux.dispatch(
  //     actions.settings.updateDeeplinks(bool)
  //   )
  // }
}

export default new Feature()
