
'use strict'

import { getActiveUser } from '../../../src/helpers'

angular
  .module('app.common', [])
  .factory('CommonUtil', ['$location',
    function ($location) {
      var CommonUtil = {}
      var formatLL = 'MMMM D, YYYY'
      var formatLL5 = "MMM D, 'YY"

      var PIXEL_URL = 'http://tracker.mymilkcrate.com/pixel'
      // var PIXEL_URL = "http://192.168.1.120:8000/pixel"

      CommonUtil.formatDate = function (ts) {
        if (ts == '' || ts == null) { return '' }

        return moment.unix(ts / 1000000000).format(formatLL)
      }

      CommonUtil.formatSimpleDate = function (ts) {
        return moment.unix(ts / 1000000000).format(formatLL5)
      }

      CommonUtil.formatDateWithFormat = function (ts, formatStr) {
        if (ts == '' || ts == null) { return '' }

        return moment.unix(ts / 1000000000).format(formatStr)
      }

      CommonUtil.formatDateWithFormatWithTimezone = function (ts, formatStr, tz) {
        if (ts == '' || ts == null) { return '' }

        return moment.tz(new Date(ts / 1000000), tz).format(formatStr)
      }

      CommonUtil.formatDateTime2 = function (datetimeStr) {
        if (datetimeStr == '' || datetimeStr == null || typeof datetimeStr === 'undefined') { return moment.unix(Date.now() / 1000).format('YYYY-MM-DD HH:mm') }

        if (typeof datetimeStr === 'string') { return datetimeStr } else { return moment.unix(datetimeStr.getTime() / 1000).format('YYYY-MM-DD HH:mm') }
      }

      CommonUtil.formatDateTime = function (ts) {
        if (ts == '' || ts == null) { return '' }

        return moment.unix(ts / 1000000000).format(formatLL + ' h:mm a')
      }

      CommonUtil.formatDateTimeWithFormat = function (datetimeStr, ts) {
        ts = new Date(datetimeStr).getTime()
        return moment.unix(ts / 1000).format(formatLL + ' h:mm a')
      }

      CommonUtil.formatSimpleDateTime = function (ts) {
        return moment.unix(ts / 1000000000).format(formatLL5 + ' h:mm a')
      }

      CommonUtil.isPastDay = function (ts) {
        if (typeof ts === 'undefined') { return false } else {
          var delta = (ts / 1000000000 - (new Date().getTime()) / 1000)
          if (delta < 0) {
            return true
          } else { return false }
        }
      }

      CommonUtil.convertStringToDate = function (str, dateFormat) {
        return moment(str, dateFormat).toDate()
      }

      CommonUtil.isTweetURL = function (url) {
        if (url.indexOf('https://twitter.com/') == 0) {
          if (url.indexOf('/status/') != -1) { return true }
        }

        return false
      }

      CommonUtil.getAge = function (ts) {
        if (ts == null || ts == '') {
          return ''
        }

        var days = Math.floor((Date.now() / 1000 - ts / 1000000000) / 3600 / 24)

        if (days < 1) {
          return '-1 day-old'
        } else if (days < 365) {
          return days + ' day-old'
        } else {
          var years = Math.floor(days / 365)
          return years + ' yo'
        }
      }

      CommonUtil.getPastDateTime = function (ts) {
        if (ts == null || ts == '') {
          return ''
        }

        var mins = Math.floor((Date.now() / 1000 - ts / 1000000000) / 60)

        if (mins < 0) {
          return 'just now'
        } else if (mins < 60) {
          return mins + ' minutes ago'
        } else if (mins < 24 * 60) {
          return CommonUtil.formatDateWithFormat(ts, 'h:mm a')
        } else {
          return CommonUtil.formatSimpleDateTime(ts)
        }
      }

      CommonUtil.getGenderCode = function (text) {
        if (text == 'Male') {
          return 0
        } else if (text == 'Female') {
          return 1
        }

        return 2
      }

      CommonUtil.convertArrayToString = function (list) {
        var ret = ''

        if (list) {
          ret = list.join(', ')
        }

        return ret
      }

      CommonUtil.convertArrayToString2 = function (list) {
        var ret = ''

        if (list) {
          for (var i = 0; i <= list.length - 1; i++) {
            if (i < list.length - 2) {
              ret += list[i] + ', '
            } else if (i < list.length - 1) {
              ret += list[i] + ' or '
            } else {
              ret += list[i]
            }
          }
        }

        return ret
      }

      CommonUtil.checkFileType = function (type, tag) {
        var ret = false
        if (tag == 'image') {
          ret = type.match(/image.*/)
        } else if (tag == 'video') {
          ret = type.match(/video.*/)
        }

        return ret
      }

      CommonUtil.isGif = function (file) {
        if (file.type.toLowerCase().indexOf('gif') != -1) {
          return true
        }

        return false
      }

      CommonUtil.getFileAttr = function (file, attr) {
        if (!file) {
          return ''
        }

        if (file._file) {
          file = file._file
        }

        return file[attr]
      }

      CommonUtil.getSmallImage = function (file) {
        if (!file) {
          return '/assets/img/profile.jpg'
        }

        if (file._file) {
          file = file._file
        }

        if (file._versions) {
          if (file._versions.sm) {
            return file._versions.sm._downloadURL
          } else {
            return file._downloadURL
          }
        } else {
          return file._downloadURL
        }
      }

      CommonUtil.getSmallImage2 = function (file) {
        if (!file) {
          return '/assets/img/no-image.png'
        }

        if (file._file) {
          file = file._file
        }

        if (file._versions) {
          if (file._versions.sm) {
            return file._versions.sm._downloadURL
          } else {
            return file._downloadURL
          }
        } else {
          return file._downloadURL
        }
      }

      CommonUtil.getSmallImage3 = function (file, showOriginal) {
        if (!file) {
          return ''
        }

        if (file.type == 'search') {
          if (showOriginal) {
            return file.original
          } else {
            return file.preview
          }
        }

        if (file._file) {
          file = file._file
        }

        if (file._versions) {
          if (file._versions.sm) {
            return file._versions.sm._downloadURL
          } else {
            return file._downloadURL
          }
        } else {
          return file._downloadURL
        }
      }
      CommonUtil.getAvatarThumbnail = function (avatar, user) {
        if (!avatar) {
          if (user && user.defaultAvatar) {
            return '/assets/img/avatars/' + user.defaultAvatar + '.png'
          } else {
            return '/assets/img/profile.jpg'
          }
        }

        if (avatar._file) {
          return CommonUtil.getSmallImage(avatar._file)
        } else {
          return CommonUtil.getSmallImage(avatar)
        }
      }

      CommonUtil.goUserDetail = function (user, e) {
        if (user && user._id) {
          if (e) {
            e.preventDefault()
            e.stopPropagation()
          }

          if (getActiveUser().communityAdmin) {
            $location.url('/users/' + user._id)
          } else {
            $location.url('/communityUsers/' + user._id)
          }
        }
      }

      CommonUtil.getDownloadURL = function (avatar) {
        if (!avatar) {
          return null
        }

        if (avatar._file) {
          return avatar._file._downloadURL
        } else {
          return avatar._downloadURL
        }
      }

      CommonUtil.openURL = function (e, imageObj) {
        var url = CommonUtil.getDownloadURL(imageObj)
        if (url) {
          e.preventDefault()
          e.stopPropagation()

          window.open(
            url,
            '_blank' // <- This is what makes it open in a new window.
          )
        }
      }

      CommonUtil.convertReturn = function (text) {
        if (!text) { return '' }

        text = text.replace(/\n/g, '<br>')

        return text
      }

      CommonUtil.encodedText = function (text) {
        if (!text) return ''

        return encodeURIComponent(text)
      }

      CommonUtil.convertWithAMPM = function (hour) {
        hour = hour.toLowerCase()
        if (hour.indexOf('am') != -1 || hour.indexOf('pm') != -1) {
          return
        }

        var d = hour
        var endPart = ':00'

        if (d.indexOf(':') != -1) {
          endPart = d.substr(d.indexOf(':'))
          d = Number(d.substr(0, d.indexOf(':')))
        }

        if (d == 0) {
          return '12' + endPart + ' am'
        } else if (d <= 11) {
          return d + endPart + ' am'
        } else if (d == 12) {
          return '12' + endPart + ' pm'
        } else if (d > 12) {
          return (d - 12) + endPart + ' pm'
        }
      }

      CommonUtil.getMiddleImage = function (file) {
        if (file._versions) {
          if (file._versions.md) {
            return file._versions.md._downloadURL
          } else {
            return file._downloadURL
          }
        } else {
          return file._downloadURL
        }
      }

      CommonUtil.convertBoolean = function (val) {
        return val ? 'Yes' : 'No'
      }

      CommonUtil.ifnull = function (val, def) {
        return val || def
      }

      CommonUtil.makeBendRef = function (id, collectionName) {
        return {
          '_type': 'BendRef',
          '_id': id,
          '_collection': collectionName
        }
      }

      CommonUtil.makeBendFile = function (id) {
        return {
          '_type': 'BendFile',
          '_id': id
        }
      }

      CommonUtil.getFullName = function (user) {
        if (!user) { return '' }

        return CommonUtil.ifnull(user.lastName, '') + ' ' + CommonUtil.ifnull(user.firstName, '')
      }

      CommonUtil.getFullName2 = function (user) {
        if (!user) { return '' }

        var temp = []

        if (user.lastName) {
          temp.push(user.lastName)
        }

        if (user.firstName) {
          temp.push(user.firstName)
        }

        return temp.join(', ')
      }

      CommonUtil.replaceReturn = function (text) {
        if (typeof text !== 'undefined') { return text.replace(/\n/g, '<br>') } else { return '' }
      }

      CommonUtil.isRetina = function () {
        /* if (window.matchMedia) {
          var mq = window.matchMedia("only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)");
          return (mq && mq.matches || (window.devicePixelRatio > 1));
        } */
        return false
      }

      CommonUtil.jsonValidate = function (value) {
        if (value) {
          try {
            JSON.parse(value)
          } catch (e) {
            if (e) {
              return ('Object has errors')
            }
          }
        }
      }

      CommonUtil.getIdList = function (list) {
        var ret = []
        _.map(list, function (o) {
          ret.push(o._id)
        })

        return ret
      }

      CommonUtil.randomString = function (len, bits) {
        bits = bits || 36
        var outStr = ''
        var newStr = ''
        while (outStr.length < len) {
          newStr = Math.random().toString(bits).slice(2)
          outStr += newStr.slice(0, Math.min(newStr.length, (len - outStr.length)))
        }
        return outStr.toUpperCase()
      }

      CommonUtil.getToday = function () {
        var dateStr = moment.unix(Date.now() / 1000).format('YYYY/MM/DD')
        return moment(dateStr, 'YYYY/MM/DD').toDate().getTime()
      }

      CommonUtil.getDateOfWeek = function () {
        // Calcing the starting point
        var today = new Date(new Date().setHours(0, 0, 0, 0))
        var day = today.getDay()
        var date = today.getDate() - day

        // Grabbing Start/End Dates
        var StartDate = new Date(today.setDate(date))
        var EndDate = new Date(today.setDate(date + 6))
        return [StartDate, EndDate]
      }

      CommonUtil.getUser = function (user) {
        if (!user) return '-'

        return user.name
      }

      CommonUtil.genders = ['Male', 'Female', 'Transgender']

      CommonUtil.AllCountries = [
        {code: 'AF', name: 'Afghanistan'},
        {code: 'AL', name: 'Albania'},
        {code: 'DZ', name: 'Algeria'},
        {code: 'AS', name: 'American Samoa'},
        {code: 'AD', name: 'Andorre'},
        {code: 'AO', name: 'Angola'},
        {code: 'AI', name: 'Anguilla'},
        {code: 'AQ', name: 'Antarctica'},
        {code: 'AG', name: 'Antigua and Barbuda'},
        {code: 'AR', name: 'Argentina'},
        {code: 'AM', name: 'Armenia'},
        {code: 'AW', name: 'Aruba'},
        {code: 'AU', name: 'Australia'},
        {code: 'AT', name: 'Austria'},
        {code: 'AZ', name: 'Azerbaijan'},
        {code: 'BS', name: 'Bahamas'},
        {code: 'BH', name: 'Bahrain'},
        {code: 'BD', name: 'Bangladesh'},
        {code: 'BB', name: 'Barbade'},
        {code: 'BY', name: 'Belarus'},
        {code: 'BE', name: 'Belgium'},
        {code: 'BZ', name: 'Belize'},
        {code: 'BJ', name: 'Benin'},
        {code: 'BM', name: 'Bermuda'},
        {code: 'BT', name: 'Bhutan'},
        {code: 'BO', name: 'Bolivia'},
        {code: 'BQ', name: 'Bonaire, Sint Eustatius and Saba'},
        {code: 'BA', name: 'Bosnia and Herzegovina'},
        {code: 'BW', name: 'Botswana'},
        {code: 'BV', name: 'Bouvet Island'},
        {code: 'BR', name: 'Brazil'},
        {code: 'IO', name: 'British Indian Ocean Territory'},
        {code: 'VG', name: 'British Virgin Islands'},
        {code: 'BN', name: 'Brunei'},
        {code: 'BG', name: 'Bulgaria'},
        {code: 'BF', name: 'Burkina Faso'},
        {code: 'BI', name: 'Burundi'},
        {code: 'KH', name: 'Cambodia'},
        {code: 'CM', name: 'Cameroon'},
        {code: 'CA', name: 'Canada'},
        {code: 'CV', name: 'Cape Verde'},
        {code: 'KY', name: 'Cayman Islands'},
        {code: 'CF', name: 'Central African Republic'},
        {code: 'TD', name: 'Chad'},
        {code: 'CL', name: 'Chile'},
        {code: 'CN', name: 'China'},
        {code: 'CX', name: 'Christmas Island'},
        {code: 'CC', name: 'Cocos (Keeling) Islands'},
        {code: 'CO', name: 'Colombia'},
        {code: 'KM', name: 'Comoros'},
        {code: 'CG', name: 'Congo'},
        {code: 'CD', name: 'Congo (Dem. Rep.)'},
        {code: 'CK', name: 'Cook Islands'},
        {code: 'CR', name: 'Costa Rica'},
        {code: 'ME', name: 'Crna Gora'},
        {code: 'HR', name: 'Croatia'},
        {code: 'CU', name: 'Cuba'},
        {code: 'CW', name: 'Curaçao'},
        {code: 'CY', name: 'Cyprus'},
        {code: 'CZ', name: 'Czech Republic'},
        {code: 'CI', name: "Côte D'Ivoire"},
        {code: 'DK', name: 'Denmark'},
        {code: 'DJ', name: 'Djibouti'},
        {code: 'DM', name: 'Dominica'},
        {code: 'DO', name: 'Dominican Republic'},
        {code: 'TL', name: 'East Timor'},
        {code: 'EC', name: 'Ecuador'},
        {code: 'EG', name: 'Egypt'},
        {code: 'SV', name: 'El Salvador'},
        {code: 'GQ', name: 'Equatorial Guinea'},
        {code: 'ER', name: 'Eritrea'},
        {code: 'EE', name: 'Estonia'},
        {code: 'ET', name: 'Ethiopia'},
        {code: 'FK', name: 'Falkland Islands'},
        {code: 'FO', name: 'Faroe Islands'},
        {code: 'FJ', name: 'Fiji'},
        {code: 'FI', name: 'Finland'},
        {code: 'FR', name: 'France'},
        {code: 'GF', name: 'French Guiana'},
        {code: 'PF', name: 'French Polynesia'},
        {code: 'TF', name: 'French Southern Territories'},
        {code: 'GA', name: 'Gabon'},
        {code: 'GM', name: 'Gambia'},
        {code: 'GE', name: 'Georgia'},
        {code: 'DE', name: 'Germany'},
        {code: 'GH', name: 'Ghana'},
        {code: 'GI', name: 'Gibraltar'},
        {code: 'GR', name: 'Greece'},
        {code: 'GL', name: 'Greenland'},
        {code: 'GD', name: 'Grenada'},
        {code: 'GP', name: 'Guadeloupe'},
        {code: 'GU', name: 'Guam'},
        {code: 'GT', name: 'Guatemala'},
        {code: 'GG', name: 'Guernsey and Alderney'},
        {code: 'GN', name: 'Guinea'},
        {code: 'GW', name: 'Guinea-Bissau'},
        {code: 'GY', name: 'Guyana'},
        {code: 'HT', name: 'Haiti'},
        {code: 'HM', name: 'Heard and McDonald Islands'},
        {code: 'HN', name: 'Honduras'},
        {code: 'HK', name: 'Hong Kong'},
        {code: 'HU', name: 'Hungary'},
        {code: 'IS', name: 'Iceland'},
        {code: 'IN', name: 'India'},
        {code: 'ID', name: 'Indonesia'},
        {code: 'IR', name: 'Iran'},
        {code: 'IQ', name: 'Iraq'},
        {code: 'IE', name: 'Ireland'},
        {code: 'IM', name: 'Isle of Man'},
        {code: 'IL', name: 'Israel'},
        {code: 'IT', name: 'Italy'},
        {code: 'JM', name: 'Jamaica'},
        {code: 'JP', name: 'Japan'},
        {code: 'JE', name: 'Jersey'},
        {code: 'JO', name: 'Jordan'},
        {code: 'KZ', name: 'Kazakhstan'},
        {code: 'KE', name: 'Kenya'},
        {code: 'KI', name: 'Kiribati'},
        {code: 'KP', name: 'Korea (North)'},
        {code: 'KR', name: 'Korea (South)'},
        {code: 'KW', name: 'Kuwait'},
        {code: 'KG', name: 'Kyrgyzstan'},
        {code: 'LA', name: 'Laos'},
        {code: 'LV', name: 'Latvia'},
        {code: 'LB', name: 'Lebanon'},
        {code: 'LS', name: 'Lesotho'},
        {code: 'LR', name: 'Liberia'},
        {code: 'LY', name: 'Libya'},
        {code: 'LI', name: 'Liechtenstein'},
        {code: 'LT', name: 'Lithuania'},
        {code: 'LU', name: 'Luxembourg'},
        {code: 'MO', name: 'Macao'},
        {code: 'MK', name: 'Macedonia'},
        {code: 'MG', name: 'Madagascar'},
        {code: 'MW', name: 'Malawi'},
        {code: 'MY', name: 'Malaysia'},
        {code: 'MV', name: 'Maldives'},
        {code: 'ML', name: 'Mali'},
        {code: 'MT', name: 'Malta'},
        {code: 'MH', name: 'Marshall Islands'},
        {code: 'MQ', name: 'Martinique'},
        {code: 'MR', name: 'Mauritania'},
        {code: 'MU', name: 'Mauritius'},
        {code: 'YT', name: 'Mayotte'},
        {code: 'MX', name: 'Mexico'},
        {code: 'FM', name: 'Micronesia'},
        {code: 'MD', name: 'Moldova'},
        {code: 'MC', name: 'Monaco'},
        {code: 'MN', name: 'Mongolia'},
        {code: 'MS', name: 'Montserrat'},
        {code: 'MA', name: 'Morocco'},
        {code: 'MZ', name: 'Mozambique'},
        {code: 'MM', name: 'Myanmar'},
        {code: 'NA', name: 'Namibia'},
        {code: 'NR', name: 'Nauru'},
        {code: 'NP', name: 'Nepal'},
        {code: 'NL', name: 'Netherlands'},
        {code: 'AN', name: 'Netherlands Antilles'},
        {code: 'NC', name: 'New Caledonia'},
        {code: 'NZ', name: 'New Zealand'},
        {code: 'NI', name: 'Nicaragua'},
        {code: 'NE', name: 'Niger'},
        {code: 'NG', name: 'Nigeria'},
        {code: 'NU', name: 'Niue'},
        {code: 'NF', name: 'Norfolk Island'},
        {code: 'MP', name: 'Northern Mariana Islands'},
        {code: 'NO', name: 'Norway'},
        {code: 'OM', name: 'Oman'},
        {code: 'PK', name: 'Pakistan'},
        {code: 'PW', name: 'Palau'},
        {code: 'PS', name: 'Palestine'},
        {code: 'PA', name: 'Panama'},
        {code: 'PG', name: 'Papua New Guinea'},
        {code: 'PY', name: 'Paraguay'},
        {code: 'PE', name: 'Peru'},
        {code: 'PH', name: 'Philippines'},
        {code: 'PN', name: 'Pitcairn'},
        {code: 'PL', name: 'Poland'},
        {code: 'PT', name: 'Portugal'},
        {code: 'PR', name: 'Puerto Rico'},
        {code: 'QA', name: 'Qatar'},
        {code: 'RO', name: 'Romania'},
        {code: 'RU', name: 'Russia'},
        {code: 'RW', name: 'Rwanda'},
        {code: 'RE', name: 'Réunion'},
        {code: 'BL', name: 'Saint Barthélemy'},
        {code: 'SH', name: 'Saint Helena'},
        {code: 'KN', name: 'Saint Kitts and Nevis'},
        {code: 'LC', name: 'Saint Lucia'},
        {code: 'MF', name: 'Saint Martin'},
        {code: 'PM', name: 'Saint Pierre and Miquelon'},
        {code: 'VC', name: 'Saint Vincent and the Grenadines'},
        {code: 'WS', name: 'Samoa'},
        {code: 'SM', name: 'San Marino'},
        {code: 'SA', name: 'Saudi Arabia'},
        {code: 'SN', name: 'Senegal'},
        {code: 'RS', name: 'Serbia'},
        {code: 'SC', name: 'Seychelles'},
        {code: 'SL', name: 'Sierra Leone'},
        {code: 'SG', name: 'Singapore'},
        {code: 'SX', name: 'Sint Maarten'},
        {code: 'SK', name: 'Slovakia'},
        {code: 'SI', name: 'Slovenia'},
        {code: 'SB', name: 'Solomon Islands'},
        {code: 'SO', name: 'Somalia'},
        {code: 'ZA', name: 'South Africa'},
        {code: 'GS', name: 'South Georgia and the South Sandwich Islands'},
        {code: 'SS', name: 'South Sudan'},
        {code: 'ES', name: 'Spain'},
        {code: 'LK', name: 'Sri Lanka'},
        {code: 'SD', name: 'Sudan'},
        {code: 'SR', name: 'Suriname'},
        {code: 'SJ', name: 'Svalbard and Jan Mayen'},
        {code: 'SZ', name: 'Swaziland'},
        {code: 'SE', name: 'Sweden'},
        {code: 'CH', name: 'Switzerland'},
        {code: 'SY', name: 'Syria'},
        {code: 'ST', name: 'São Tomé and Príncipe'},
        {code: 'TW', name: 'Taiwan'},
        {code: 'TJ', name: 'Tajikistan'},
        {code: 'TZ', name: 'Tanzania'},
        {code: 'TH', name: 'Thailand'},
        {code: 'TG', name: 'Togo'},
        {code: 'TK', name: 'Tokelau'},
        {code: 'TO', name: 'Tonga'},
        {code: 'TT', name: 'Trinidad and Tobago'},
        {code: 'TN', name: 'Tunisia'},
        {code: 'TR', name: 'Turkey'},
        {code: 'TM', name: 'Turkmenistan'},
        {code: 'TC', name: 'Turks and Caicos Islands'},
        {code: 'TV', name: 'Tuvalu'},
        {code: 'UG', name: 'Uganda'},
        {code: 'UA', name: 'Ukraine'},
        {code: 'AE', name: 'United Arab Emirates'},
        {code: 'GB', name: 'United Kingdom'},
        {code: 'UM', name: 'United States Minor Outlying Islands'},
        {code: 'US', name: 'United States of America'},
        {code: 'UY', name: 'Uruguay'},
        {code: 'UZ', name: 'Uzbekistan'},
        {code: 'VU', name: 'Vanuatu'},
        {code: 'VA', name: 'Vatican City'},
        {code: 'VE', name: 'Venezuela'},
        {code: 'VN', name: 'Vietnam'},
        {code: 'VI', name: 'Virgin Islands of the United States'},
        {code: 'WF', name: 'Wallis and Futuna'},
        {code: 'EH', name: 'Western Sahara'},
        {code: 'YE', name: 'Yemen'},
        {code: 'ZM', name: 'Zambia'},
        {code: 'ZW', name: 'Zimbabwe'},
        {code: 'AX', name: 'Åland Islands'}
      ]

      CommonUtil.AllStates = [
        'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL',
        'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH',
        'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM',
        'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC',
        'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'
      ]

      CommonUtil.AllCategoryGroups = [
        'Community',
        'Diet',
        'Home',
        'Shopping',
        'Transit',
        'Waste'
      ]

      CommonUtil.animals = [
        'cat', 'corgi', 'fish', 'frog', 'koala', 'lion', 'otter', 'owl',
        'penguin', 'pig', 'raccoon', 'rhino', 'squirrel', 'turtle', 'whale'
      ]

      CommonUtil.setStorage = function (key, value) {
        localStorage.setItem(key, JSON.stringify(value))
      }

      CommonUtil.getStorage = function (key) {
        var value = localStorage.getItem(key)
        return value && JSON.parse(value)
      }

      CommonUtil.months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

      CommonUtil.days = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31
      ]

      CommonUtil.weekdays = [ 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday' ]

      CommonUtil.months = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ]

      CommonUtil.ckeditorOptions = {
        language: 'en',
        allowedContent: true,
        entities: false,
        height: 100,
        toolbar: [
          {
            name: 'links',
            items: ['Link', 'Unlink', 'Anchor']
          },
          {
            name: 'basicstyles',
            items: ['Bold', 'Italic', 'Strike', '-', 'RemoveFormat']
          }
        ]
      }

      CommonUtil.getPixelUrl = function (type, id) {
        return PIXEL_URL + '/' + type + '/' + (id || '')
      }

      return CommonUtil
    }
  ])
