import * as actions from './actions'
import BendClient, { BendTable } from '../BendClient/index'
import redux from '../../../src/helpers/redux'


class Feature {

  private api: any
 

  constructor () {
    this.api = BendClient
   
  }

  async init (communityAdmin, communityId) {
    const settings = redux.getState().newestUsers.settings
    const { newestUsers, count } = await this.api.fetchNewestUsers(settings, communityAdmin, communityId)
    const limitedCount = Math.min(100, count)

    redux.dispatch(
      actions.list.update(newestUsers)
    )
    redux.dispatch(
      actions.settings.update(limitedCount)
    )
  }
 
}

export default new Feature()
