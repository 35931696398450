import {
  UPDATE_ACTIVITIES_SETTINGS,
  UPDATE_ACTIVITIES_PAGE_SETTINGS,
  UPDATE_ACTIVITIES_TEAM_SETTINGS,
  UPDATE_ACTIVITIES_TEAMS_SETTINGS,
  UPDATE_ACTIVITIES_ITEMS_PER_PAGE,
  // UPDATE_USERS_KEYWORD_SETTINGS,
  // UPDATE_USERS_COMMUNITY_SETTINGS,
  RESET_ACTIVITIES_SETTINGS
} from '../constants/types'
import { Settings } from '../reducers/settings'

export interface Action {
  type: string,
  users: Settings,
  count: number,
  page: number,
  keyword: string,
  community: any,
  teamId: any,
  teams: any,
  itemsPerPage: any
}

export const update = (count: number) => {
  return {
    type: UPDATE_ACTIVITIES_SETTINGS,
    count
  }
}

export const pageUpdate = (page: number) => {
  return {
    type: UPDATE_ACTIVITIES_PAGE_SETTINGS,
    page
  }
}

export const updateItemsPerPage = (itemsPerPage) => {
  return {
    type: UPDATE_ACTIVITIES_ITEMS_PER_PAGE,
    itemsPerPage
  }
}

export const teamUpdate = (teamId) => {
  return {
    type: UPDATE_ACTIVITIES_TEAM_SETTINGS,
    teamId
  }
}

export const teamsUpdate = (teams) => {
  return {
    type: UPDATE_ACTIVITIES_TEAMS_SETTINGS,
    teams
  }
}

// export const searchKeywordUpdate = (keyword) => {
//   return {
//     type: UPDATE_USERS_KEYWORD_SETTINGS,
//     keyword
//   }
// }

// export const communityUpdate = (community) => {
//   return {
//     type: UPDATE_USERS_COMMUNITY_SETTINGS,
//     community
//   }
// }

export const reset = () => {
  return {
    type: RESET_ACTIVITIES_SETTINGS
  }
}
