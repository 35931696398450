import { Exports } from '../reducers/exports'
import {
  UPDATE_STUDIO_APPS_EXPORTS_LIST
} from '../constants/types'

export interface Action {
  type: string,
  exports: Exports
}

export const update = (exports: Exports): Action => {
  return {
    type: UPDATE_STUDIO_APPS_EXPORTS_LIST,
    exports
  }
}
