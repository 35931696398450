'use strict'

import commonUtil from '../../../src/helpers/commonUtil'
import * as activities from '../../../src/features/activities'
import * as teams from '../../../src/features/teams'
import exportHelper, { ACTIVITY_ORDER, ACTIVITY_HEADERS } from '../../../src/helpers/export'
import moment from 'moment'
import genericHelper from '../../../src/helpers/GenericHelper'

angular.module('app.controllers')
  .controller('ActivityController', ['$scope', '$location', 'BendAuth', '$ngRedux',
    async function ($scope, $location, BendAuth, $ngRedux) {
      $scope.currentPage = 1
      $scope.commonUtil = commonUtil
      $scope.genericHelper = genericHelper

      const mapStateToThis = (state) => ({
        activityList: state.activities.list,
        teamsList: state.teams.list.teams,
        collectionView: {
          itemsPerPage: state.activities.settings.itemsPerPage,
          totalItems: state.activities.settings.totalItems,
          currentPage: state.activities.settings.currentPage,
          numPages: state.activities.settings.numPages
        }
      })

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      $scope.imageMap = {}
      // $scope.allTeamsDone = false

      $scope.localStorage = JSON.parse(localStorage.getItem('ActivityPermanentFix'))
      $scope.filter = {
        type: ($scope.localStorage ? $scope.localStorage.type : ''),
        // team is now handled by redux state above
        // team: ($scope.localStorage ? $scope.localStorage.team : ''),
        searchTerm: ($scope.localStorage ? $scope.localStorage.searchTerm : ''),
        after: ($scope.localStorage ? $scope.localStorage.after : ''),
        before: ($scope.localStorage ? $scope.localStorage.before : ''),
        community: _.get($scope, 'localStorage.community') || ''
      }

      $scope.communityList = [{
        name: 'All Clients',
        _id: null
      }]
      $scope.user = BendAuth.getActiveUser()

      if ($scope.user.communityAdmin) {
        $scope.filter.community = $scope.user.community
        const start = moment()
        await teams.helpers.getList($scope.user.community._id)
        console.log(`Getting Teams took ${moment().diff(start, 'seconds')} seconds`)
      } else { // master admin
        $scope.filter.community._id = ($scope.localStorage ? $scope.localStorage.community._id : '')
        activities.helpers.getCommunityList((rets) => {
          $scope.communityList = $scope.communityList.concat(rets)
        })
        // activities.helpers.getAllTeamList(function (err, rets) {
        //   $scope.allTeams = rets
        //   $scop(number)eamsDone = true
        // })
        await activities.helpers.getAllTeams()
      }

      $scope.updateItemsPerPage = (number) => {
        activities.helpers.updateItemsPerPage(number)
      }

      $scope.updateAfter = () => {
        commonUtil.setStorage('activity-filter', Object.assign({}, {
          after: $scope.filter.after
        }))
        $scope.loadList()
      }
      $scope.updateBefore = () => {
        commonUtil.setStorage('activity-filter', Object.assign({}, {
          before: $scope.filter.before
        }))
        $scope.loadList()
      }

      $scope.reset = function () {
        $scope.filter = {
          type: '',
          team: '',
          searchTerm: '',
          after: '',
          before: ''
        }
        if (!$scope.user.communityAdmin) {
          $scope.filter = {
            community: ''
          }
        }
        $scope.isLoading = true
        activities.helpers.reset()
        activities.helpers.selectTeam()
        $scope.filter.team = $scope.teamsList[0]
        $scope.loadList()
      }

      $scope.communityChange = () => {
        // $scope.filter.team = ''
        $scope.isLoading = true
        $scope.loadList()
      }

      $scope.selectTeam = async (team) => {
        $scope.isLoading = true
        $scope.fields = { team }
        await activities.helpers.selectTeam(team._id)
        $scope.loadList()
      }

      // called in table row to display team names
      $scope.getTeamNames = function (teamIds = []) {
        if (!teamIds || teamIds.length == 0) {
          return 'No Team'
        } else if (teamIds.length > 4) {
          return `${teamIds.length} Teams`
        }

        const names = []
        _.map($scope.teamsList, (o) => {
          if (teamIds.indexOf(o._id) != -1) { names.push(o.name) }
        })

        return names.join(', ')
      }

      $scope.loadList = async () => {
        localStorage.setItem('ActivityPermanentFix', JSON.stringify($scope.filter))

        applyChangesOnScope($scope, () => {
        })
        if ($scope.activityList.length === 0) {
          $scope.isLoading = true
        }
        const settings = {
          after: _.get($scope, 'filter.after'),
          before: _.get($scope, 'filter.before'),
          searchTerm: _.get($scope, 'filter.searchTerm'),
          communityId: _.get($scope, 'filter.community._id'),
          type: _.get($scope, 'filter.type'),
          teamId: _.get($scope, 'fields.team._id', null), // could process the settings here instead of redux
          itemsPerPage: _.get($scope, 'collectionView.itemsPerPage'),
          currentPage: _.get($scope, 'collectionView.currentPage')
        }
        const start = moment()
        await activities.helpers.fetchList(settings)
        console.log(`Getting Activities took ${moment().diff(start, 'seconds')} seconds`)
        $scope.isLoading = false
      }

      $scope.loadList()

      $scope.onSearch = function () {
        $scope.isLoading = true
        $scope.loadList()
      }

      $(document).on('click', '.pagination li:not(.disabled) a', (e) => {
        $scope.isLoading = true
        const n = Number($(e.target).text())
        activities.helpers.paginateToPage(!isNaN(n) ? n : $scope.collectionView.currentPage + ($(e.target).text() === 'Prev' ? -1 : +1))
        $scope.loadList()
      })

      $scope.onPageChange = (e) => {
      }

      $scope.deleteActivity = function (id, $ev) {
        $ev.stopPropagation()
        $ev.preventDefault()
        const yeap = confirm('Deleting the activity is permanent and can not be undone.')
        if (!yeap) {
          return false
        }
        activities.helpers.delete(id)
          .then(() => {
            $scope.loadList()
          })
      }
      // this opens another page where you can edit this user activity. we don't need that. i removed the link to this function in the html
      $scope.editActivity = function (activity, $event) {
        if ($event.metaKey || $event.ctrlKey) {
          window.open('#/activities/' + activity._id, '_blank')
        } else { return $location.path('/activities/' + activity._id) }
      }

      $scope.createActivity = function ($event) {
        if ($event.metaKey || $event.ctrlKey) {
          window.open('#/activities/new', '_blank')
        } else { return $location.path('/activities/new') }
      }

      $scope.export = {}
      $scope.export.columnOrder = ACTIVITY_ORDER
      $scope.export.headers = ACTIVITY_HEADERS
      $scope.export.exportDataAll = () => exportHelper.exportActivitiesData()
        .then((activities) => activities.map((activity) => {
          activity.user = activity.user._id
          if (activity.categories) {
            activity.categories = activity.categories.join(', ')
          }
          if (activity.segments) {
            activity.segments = activity.segments.join(', ')
          }
          if (activity.teams) {
            activity.teams = activity.teams.join(', ')
          }
          if(activity.mediaType === 'image'){
            activity.media = activity.media._downloadUrl
          }
          activity.group = activity.group && activity.group._id ? activity.group._id : activity.group
          activity.createdAt = moment(activity._bmd.createdAt / 1000000).format()
          activity.updatedAt = moment(activity._bmd.updatedAt / 1000000).format()
          return activity
        }))
        .catch((error) => {
          console.log('Error Exporting', error)
        })
    }])
