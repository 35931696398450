'user strict'

import * as communities from '@/features/communities'
import alertify from 'alertifyjs'

angular.module('app.controllers')
  .controller('PushTabController', ['$scope', '$routeParams', 'CommonUtil',
    async function ($scope, $routeParams, CommonUtil) {
      $scope.isLoading = true
      $scope.CommonUtil = CommonUtil
      $scope.pushConfig = {
        p12Password: null,
        appleCertificate: {
          downloadURL: null,
          _filename: null
        },
        appleCertificateBase64: null,
      }
      $scope.appName = null
      $scope.fileProgress = []
      $scope.isUploading = []
      $scope.form = {}
      $scope.communityId = $routeParams.id

      let whitelabel = await communities.helpers.getCurrentWhitelabel($scope.communityId)
      whitelabel = whitelabel || {}
      let appConfig = await communities.helpers.getCommunityAppConfig($scope.communityId)
      appConfig = appConfig || {}

      $scope.appName = whitelabel.appName || appConfig.appName

      $scope.pushConfig = whitelabel.pushConfig || {}
      $scope.whitelabel = {
        applicationIdSuffix: whitelabel.applicationIdSuffix,
        devTeam: whitelabel.devTeam
      }

      $scope.saveDo = async () => {
        let whitelabel = await communities.helpers.getCurrentWhitelabel($scope.communityId)
        whitelabel = whitelabel || {}
        const data = _.clone($scope.pushConfig)

        if (data.appleCertificate && $scope.file !== undefined) {
          data.appleCertificate = CommonUtil.makeBendFile(data.appleCertificate._id)
          data.appleCertificateBase64 = await toBase64($scope.file)
        }

        whitelabel.pushConfig = data
        whitelabel.applicationIdSuffix = $scope.whitelabel.applicationIdSuffix
        whitelabel.devTeam = $scope.whitelabel.devTeam
        whitelabel.community = CommonUtil.makeBendRef($scope.communityId, 'community')
        whitelabel.appName = $scope.appName
        await communities.helpers.updateWhitelabel(whitelabel)

        //We get the appConfig on the same moment instead of loading and then modifying because we might override the images uploaded on the other tab. 
        let appConfig = await communities.helpers.getCommunityAppConfig($scope.communityId, {}) //Empty params so we dont get the references and break the record
        appConfig = appConfig || {}
        appConfig.appName = $scope.appName
        appConfig.community = CommonUtil.makeBendRef($scope.communityId, 'community')
        await communities.helpers.saveCommunityAppConfig(appConfig)

        alertify.notify('Saved successfully.', 'success', 5)
      }

      $scope.canSubmitValidationForm = function () {
        return $scope.form.validateForm.$valid
      }

      $scope.openFile = function (fileName) {
        $("input[name='" + fileName + "']").click()
      }

      $scope.deleteFile = function (tag, $ev) {
        $ev.stopPropagation()
        $ev.preventDefault()
        applyChangesOnScope($scope, () => {
          delete $scope.pushConfig[tag]
        })

        $scope.form.validateForm.$setDirty()
      }

      $scope.showFileLoading = function (tag, bShow) {
        $scope.isUploading[tag] = bShow
      }

      $scope.selectFileOpen = function (fileId, $ev) {
        if ($ev.target.tagName == 'DIV') {
          setTimeout(() => {
            $('#' + fileId).click()
          }, 0)
        }
      }
      $scope.onFileUpload = function (files, tag) {
        const file = files[0]

        $scope.fileProgress[tag] = 0
        $scope.showFileLoading(tag, true)
        $scope.file = file
        communities.helpers.upload(file, (error, uploadedFile) => {
          if (error) {
            $scope.showFileLoading(tag, false)
            return
          }

          communities.helpers.getFile(uploadedFile, (o) => {
            $scope.pushConfig[tag] = o
            $scope.showFileLoading(tag, false)
          })
          $scope.form.validateForm.$setDirty()
        }, null, (total, prog) => {
          applyChangesOnScope($scope, () => {
            $scope.fileProgress[tag] = prog * 100 / total
          })
        })
      }
      const toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    }])
