import * as _ from 'lodash'
import BendClient, { BendTable } from '../BendClient'
import * as actions from './actions'
import redux from '../../../src/helpers/redux'
import commonUtil from '../../helpers/commonUtil'

import { Settings } from '../../types'

class Feature {

  private api: any
  private api2: any

  constructor () {
    this.api = BendClient
    this.api2 = BendTable
  }

  public getCollectionEnabled () {
    return this.api.getCollectionEnabled()
  }

  public getCommunityList () {
    return this.api.getCommunityList()
  }

  // public getCategory () {
  //   return this.api.getCategoryList()
  // }

  public getCategoryGroupList (communityId) {
    return this.api.getCategoryGroupList(communityId)
  }

  public upload (file, callback, ext, progressCallback) {
    return this.api.upload(file, callback, ext, progressCallback)
  }

  public getFile (refObj, callback) {
    return this.api.getFile(refObj, callback)
  }

  public async getList () {
    const settings = redux.getState().volunteerings.settings
    const volunteeringList = await this.api2.getList(this.api2.Tables.VOLUNTEERING, settings)
    redux.dispatch(
      actions.settings.update(volunteeringList.length)
    )
    redux.dispatch(
      actions.list.update(volunteeringList)
    )
  }

  public get (id) {
    return this.api2.get(this.api2.Tables.VOLUNTEERING, id)
  }

  public create (data) {
    return this.api2.create(this.api2.Tables.VOLUNTEERING, data)
  }

  public update (data) {
    return this.api2.update(this.api2.Tables.VOLUNTEERING, data)
  }

  public delete (id) {
    return this.api2.delete(this.api2.Tables.VOLUNTEERING, id)
  }

  public sortBy (key, direction) {
    let list = redux.getState().volunteerings.list
    let sortedList = commonUtil.sortBy(list, key, direction)
    redux.dispatch(
      actions.list.update(sortedList)
    )
  }

  public fetchUsersById (settings: {id: string[] }) {
    return this.api.fetchUsersById(settings)
  }

  public async fetchPaginatedList (settings: Settings) {
    // const volunteeringList = await this.api2.fetchPaginatedList('volunteer_opportunity', settings)
    const volunteeringList = await this.api2.getList('volunteer_opportunity', settings)
    let userIds: any = []
    _.map(volunteeringList, function (o) {
      if (o._acl && o._acl.creator) {
        userIds.push(o._acl.creator)
      }
    })

    userIds = _.uniq(userIds)
    const users = await this.fetchUsersById({
      id: userIds
    })
    _.map(volunteeringList, function (o) {
      if (o._acl && o._acl.creator) {
        const u = _.find(users, function (_o) {
          return _o._id === o._acl.creator
        })

        if (u) {
          o._acl.creator = u
        }
      }
    })
    redux.dispatch(
      actions.settings.update(volunteeringList.length)
    )
    redux.dispatch(
      actions.list.update(volunteeringList)
    )
  }

  public reset () {
    redux.dispatch(
      actions.settings.reset()
    )
  }

  public paginateToPage (page) {
    redux.dispatch(
      actions.settings.pageUpdate(page)
    )
  }

  public async selectSearchTerm (term) {
    redux.dispatch(
      actions.settings.selectSearchTerm(term)
    )
  }

  public async selectCommunity (id: string, communityList: any[]) {
    const selected = communityList.find(item => item._id === id)
    redux.dispatch(
      actions.settings.selectCommunity(selected)
    )
  }

  public async selectCollection (id: string, collections: any[]) {
    const selected = collections.find(item => item._id === id)
    redux.dispatch(
      actions.settings.selectCollection(selected)
    )
  }

  public async selectCategory (category: any) {
    redux.dispatch(
      actions.settings.selectCategory(category)
    )
  }

}

export default new Feature()
