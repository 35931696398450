'use strict'

import commonUtil from '../../../src/helpers/commonUtil'
import * as places from '../../../src/features/places'
import * as communities from '../../../src/features/communities'
import * as collections from '../../../src/features/collections'
import * as categories from '../../../src/features/categories'
import bendHelper from '../../../src/helpers/bendHelper'

angular.module('app.controllers')
  .controller('BusinessController', ['$scope', '$location', 'BendAuth', '$ngRedux',
    async function ($scope, $location, BendAuth, $ngRedux) {
      const allCats = [{
        name: 'All categories',
        _id: null
      }]
     
      $scope.isLoading = true
      const mapStateToThis = (state) => ({
        communityList: state.communities.list,
        categories: allCats.concat(state.categories.list), // loads into dropdown filter
        categoriesGroups: state.categories.settings.groupedCategories,
        collectionsList: [{name: 'All Collections'}].concat(state.collections.list),
        // statesList: state.places.settings.states,
        placesList: state.places.list,
        activities: state.activities.list,
        collectionView: {
          itemsPerPage: state.places.settings.itemsPerPage,
          totalItems: state.places.settings.totalItems,
          currentPage: state.places.settings.currentPage,
          numPages: state.places.settings.numPages,
          isLoading: state.places.settings.isLoading
          // sortDirection: state.places.settings.sortDirection,
          // sortKey: state.places.settings.sortKey
        },
        filter: {
          searchTerm: state.places.settings.searchTerm,
          community: state.places.settings.community,
          category: state.places.settings.category,
          collection: state.places.settings.collectionId,
          state: state.places.settings.selectedState
        }
      })

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      init()
      

      $scope.sortBy = function (key) {
        $scope.sortKey = key
        $scope.sortDirection[key] = (-1) * $scope.sortDirection[key]
        places.helpers.sortBy(key, $scope.sortDirection)
      }
      $scope.sortDirection = {
        'name': 1,
        '_bmd.updatedAt': -1
      }

      function init () {
        // categories.helpers.getGroupedList()
       
        $scope.isLoading = true
        
       
        categories.helpers.getList()
        collections.helpers.init()
        communities.helpers.initEnabled()
        places.helpers.init().then(() => $scope.sortBy('_bmd.updatedAt'))
       
        setTimeout(()=>{ $scope.isLoading = false}, 300)

      }
     

      $scope.$on('$destroy', unsubscribe)
    
      $(document).on('click', '.pagination li:not(.disabled) a', (e) => {
        const n = $(e.target).text()
        places.helpers.paginateToPage(n)
        $scope.isLoading = true
        $scope.loadList()
        
 
      })
     
      $scope.loadList = async () => {
        $scope.isLoading = true
        await places.helpers.init()
       
        $scope.isLoading = false
      }

      
      $scope.collectionList = []
      $scope.stateList = ['All States'].concat(commonUtil.AllStates)

     
      $scope.user = BendAuth.getActiveUser()

      $scope.enableToggle = async (item, e) => {
        // console.log("enable toggle")
        await bendHelper.enableHelper(e, item, places)
      }

      $scope.reset = function () {
       
        $scope.isLoading = true
        places.helpers.reset()
       
        actions.helpers.reset()
        $scope.loadList()
        init()
        $scope.isLoading = false
      }

      $scope.communityList = [{
        name: 'All client',
        _id: null
      }]
      $scope.categories = [{
        name: 'All categories',
        _id: null
      }]

      $scope.onSearch = function () {
        places.helpers.selectSearchTerm($scope.filter.searchTerm)
      }

      $scope.onSearch = function () {
        places.helpers.selectSearchTerm($scope.filter.searchTerm)
      }

      $scope.selectCommunity = async (communityId) => {
       
        await places.helpers.selectCommunity(communityId)
       
      }

      $scope.selectCollection = async (collection) => {
       
        await places.helpers.selectCollection(collection)
        
      }

      $scope.selectCategory = async (category) => {
        
        // let placesList = categories.helpers.filterByCategory($scope.placesList, categoryId)
        // console.log('places list scope', $scope.placesList)
        await places.helpers.selectCategory(category)
       
      }

      $scope.selectState = async (state) => {
       
        await places.helpers.selectState(state)
      
      }

      $scope.editBusiness = function (business, $event) {
        if ($event.metaKey || $event.ctrlKey) {
          window.open('#/businesses/' + business._id, '_blank')
        } else { return $location.path('/businesses/' + business._id) }
      }

      $scope.createBusiness = function ($event) {
        if ($event.metaKey || $event.ctrlKey) {
          window.open('#/businesses/new', '_blank')
        } else { return $location.path('/businesses/new') }
      }
    }])
