'use strict'

import commonUtil from '../../../src/helpers/commonUtil'
import * as certifications from '../../../src/features/certifications'

angular.module('app.controllers')
  .controller('CertificationController', ['$scope', '$modal', '$ngRedux',
    async function ($scope, $modal, $ngRedux) {
      const listScope = $scope

      const mapStateToThis = state => {
        return {
          certifications: state.certifications.list,
          collectionView: {
            totalItems: state.certifications.settings.totalItems
          }
        }
      }

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      if ($scope.certifications.length === 0) {
        $scope.isLoading = true
      }

      await certifications.helpers.init()
      $scope.isLoading = false

      $scope.certificationList = []

      $scope.editCertification = function (certification) {
        $scope.openEditCertification(certification)
      }
      $scope.createCertification = function () {
        var certificationObj = {
          enabled: true
        }
        $scope.openEditCertification(certificationObj)
      }

      $scope.openEditCertification = function (certificationObj) {
        $modal.open({
          templateUrl: 'editCertification.html',
          backdrop: 'static',
          controller: function ($scope, $modalInstance, certificationObj, certificationList) {
            $scope.certification = _.clone(certificationObj)
            $scope.fileProgress = []
            $scope.isUploading = []
            $scope.typeList = ['Business', 'Event']

            $scope.deleteCertification = async (id, e) => {
              e.preventDefault()
              e.stopPropagation()
              const yeap = confirm('Deleting the certification is permanent and can not be undone.')
              if (!yeap) {
                return false
              }
              $modalInstance.dismiss('cancel')
              listScope.isLoading = true
              await certifications.helpers.delete(id)
              await certifications.helpers.init()
              listScope.isLoading = false
            }

            $scope.cancel = function () {
              $modalInstance.dismiss('cancel')
            }
            $scope.close = function () {
              $modalInstance.dismiss('cancel')
            }
            $scope.deleteFile = function (attr, $ev) {
              if ($ev) {
                $ev.stopPropagation()
                $ev.preventDefault()
              }
              delete $scope.certification[attr]
            }

            $scope.selectFileOpen = function ($event) {
              if ($event.target.tagName == 'INPUT') { return }

              var obj = $event.target
              setTimeout(function () {
                $(obj).parents('.upload-file-container').find("input[type='file']").eq(0).click()
              }, 0)
            }

            $scope.showFileLoading = function (tag, bShow) {
              $scope.isUploading[tag] = bShow
            }

            $scope.onFileUpload = function (files, attr) {
              var file = files[0]
              if (!commonUtil.checkFileType(file.type, 'image')) { return }

              $scope.form.validateForm.$setDirty()

              $scope.fileProgress[attr] = 0
              $scope.showFileLoading(attr, true)
              certifications.helpers.upload(file, function (error, uploadedFile) {
                if (error) {
                  $scope.showFileLoading(attr, false)
                  return
                }

                certifications.helpers.getFile(uploadedFile, function (o) {
                  applyChangesOnScope($scope, function () {
                    $scope.certification[attr] = o
                    $scope.showFileLoading(attr, false)
                  })
                })
              }, {
                _workflow: 'avatar'
              }, function (total, prog) {
                applyChangesOnScope($scope, function () {
                  $scope.fileProgress[attr] = prog * 100 / total
                })
              })
            }
            $scope.saveCertificationDo = async function () {
              let certificationData = _.chain($scope.certification)
                .clone()
                .omit('$$hashKey')
                .value()

              if (certificationData.badge) {
                certificationData.badge = commonUtil.makeBendFile(certificationData.badge._id)
              }

              $modalInstance.dismiss('cancel')
              listScope.isLoading = true

              if (certificationData._id) {
                await certifications.helpers.update(certificationData)
              } else {
                await certifications.helpers.create(certificationData)
              }

              await certifications.helpers.init()
              listScope.isLoading = false
            }

            $scope.canSubmitValidationForm = function () {
              return $scope.form.validateForm.$valid
            }
          },
          resolve: {
            certificationObj: function () {
              return certificationObj
            },
            certificationList: ['$scope', function ($scope) {
              return $scope.certificationList
            }]
          }
        })
      }
    }])
