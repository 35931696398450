import { Action } from '../actions/list'
import {
  UPDATE_SEGMENTS_LIST
} from '../constants/types'

export interface Item {
  any
}

export type Segments = Array<any>

const initialState: Segments = []

export default (state: Segments = initialState, action: Action | any): Segments => {
  switch (action.type) {
    case UPDATE_SEGMENTS_LIST:
      return action.segments
    default:
      return state
  }
}
