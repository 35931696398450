import {
  UPDATE_PLACES_COUNT,
  UPDATE_PLACES_PAGE_SETTINGS,
  UPDATE_PLACES_SEARCH_TERM_SETTINGS,
  RESET_PLACES_SETTINGS,
  UPDATE_PLACES_STATES_SETTINGS,
  UPDATE_PLACES_COMMUNITY_SETTINGS,
  UPDATE_PLACES_CATEGORY_SETTINGS,
  UPDATE_PLACES_COLLECTION_SETTINGS,
  UPDATE_PLACES_STATE_SETTINGS,
  UPDATE_PLACES_SORT_DIRECTION_SETTINGS,
  UPDATE_PLACES_SORT_KEY_SETTINGS,
  UPDATE_PLACES_LOADING_SETTINGS
} from '../constants/types'

export interface Action {
  type: string,
  count: number,
  page: number,
  community: any,
  searchTerm: string,
  communityId: string,
  collectionId: string,
  selectedState: string,
  category: any,
  states: Array<any>,
  sortDirection: string,
  sortKey: string,
  isLoading: boolean
}

export const update = (count) => {
  return {
    type: UPDATE_PLACES_COUNT,
    count
  }
}
export const pageUpdate = (page) => {
  return {
    type: UPDATE_PLACES_PAGE_SETTINGS,
    page
  }
}
export const selectSearchTerm = (searchTerm) => {
  return {
    type: UPDATE_PLACES_SEARCH_TERM_SETTINGS,
    searchTerm
  }
}
export const selectCommunity = (communityId) => {
  return {
    type: UPDATE_PLACES_COMMUNITY_SETTINGS,
    communityId
  }
}
export const selectState = (selectedState) => {
  return {
    type: UPDATE_PLACES_STATE_SETTINGS,
    selectedState
  }
}
export const selectCategory = (category) => {
  console.log('category', category)
  return {
    type: UPDATE_PLACES_CATEGORY_SETTINGS,
    category
  }
}
export const selectCollection = (collectionId) => {
  return {
    type: UPDATE_PLACES_COLLECTION_SETTINGS,
    collectionId
  }
}
export const selectSortDirection = (sortDirection) => {
  return {
    type: UPDATE_PLACES_SORT_DIRECTION_SETTINGS,
    sortDirection
  }
}
export const selectSortKey = (sortKey) => {
  return {
    type: UPDATE_PLACES_SORT_KEY_SETTINGS,
    sortKey
  }
}
export const updateStates = (states) => {
  return {
    type: UPDATE_PLACES_STATES_SETTINGS,
    states
  }
}
export const toggleLoading = (isLoading) => {
  return {
    type: UPDATE_PLACES_LOADING_SETTINGS,
    isLoading
  }
}
export const reset = () => {
  return {
    type: RESET_PLACES_SETTINGS
  }
}
