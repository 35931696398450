import {
  UPDATE_TEAMS_SETTINGS,
  UPDATE_TEAMS_PAGE_SETTINGS,
  UPDATE_TEAMS_KEYWORD_SETTINGS,
  UPDATE_TEAMS_COMMUNITY_SETTINGS,
  UPDATE_TEAMS_SORTING_SETTINGS,
  RESET_TEAMS_SETTINGS
} from '../constants/types'
import { Settings } from '../reducers/settings'

export interface Action {
  type: string,
  users: Settings,
  count: number,
  page: number,
  keyword: string,
  community: any,
  sortKey: string,
  sortDirection: object
}

export const update = (count) => {
  return {
    type: UPDATE_TEAMS_SETTINGS,
    count
  }
}

export const pageUpdate = (page) => {
  return {
    type: UPDATE_TEAMS_PAGE_SETTINGS,
    page
  }
}

export const searchKeywordUpdate = (keyword) => {
  return {
    type: UPDATE_TEAMS_KEYWORD_SETTINGS,
    keyword
  }
}

export const communityUpdate = (community) => {
  return {
    type: UPDATE_TEAMS_COMMUNITY_SETTINGS,
    community
  }
}

export const sortList = (sortKey, sortDirection) => {
  return {
    type: UPDATE_TEAMS_SORTING_SETTINGS,
    sortKey,
    sortDirection
  }
}

export const reset = () => {
  return {
    type: RESET_TEAMS_SETTINGS
  }
}
