import { combineReducers } from 'redux'
import data, { Statistics } from './data'

export interface CombineReducers {
  data: Statistics
}

const rootReducer = combineReducers<CombineReducers>({
  data
})

export default rootReducer
