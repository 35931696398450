'use strict'

import * as communities from '../../../src/features/communities'
import * as users from '../../../src/features/users'
import * as teams from '../../../src/features/teams'
import { getActiveUser } from '../../../src/helpers'
import commonUtil from '../../../src/helpers/commonUtil'
import exportHelper, {USER_HEADERS, USER_ORDER} from '../../../src/helpers/export'
import moment from 'moment'

angular.module('app.controllers')
  .controller('UserController', ['$scope', '$bend', '$location', '$routeParams', 'BendAuth', '$bootbox', '$rootScope', '$modal', '$timeout', '$ngRedux',
    async function ($scope, $bend, $location, $routeParams, BendAuth, $bootbox, $rootScope, $modal, $timeout, $ngRedux) {
      const listScope = $scope
      $scope.commonUtil = commonUtil
      $scope.isLoading = true
      $scope.currentPage = 1

      const mapStateToThis = (state) => ({
        communityList: state.communities.list,
        userList: state.users.list,
        teamsList: [{name: 'All Teams'}].concat(state.teams.list.teams),
        collectionView: {
          searchTerm: state.users.settings.searchTerm,
          itemsPerPage: state.users.settings.itemsPerPage,
          isLoading: state.users.settings.isLoading,
          totalItems: state.users.settings.totalItems,
          currentPage: state.users.settings.currentPage,
          numPages: state.users.settings.numPages
        },
        filter: {
          community: state.users.settings.community
        },
        fields: {
          searchTerm: state.users.settings.searchTerm,
          team: state.users.settings.team,
          community: state.users.settings.community,
          exportData: state.users.exports
        }
      })

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      $scope.user = getActiveUser()
      $scope.communityAdmin = getActiveUser().community.admin
      communities.helpers.get($scope.user.community._id).then((c) => {
        $scope.community = c
      })

      if (!$scope.communityAdmin) { // if super admin
        communities.helpers.init(true).then(() => {
          if (typeof $scope.fields.community === 'object' && !$scope.fields.community._id) {
            users.helpers.selectCommunity(null)
          }
        })
      } else { // if community admin
        users.helpers.selectCommunityUsers($scope.user.community.id)
      }

      teams.helpers.getList($scope.user.community._id).then(() => {
        if ($scope.teamsList.length) {
          users.helpers.selectTeam($scope.teamsList[0])
        }
      })

      setTimeout(() => {
        $scope.loadUsers()
      }, 200)

      $scope.reset = async () => {
        $scope.isLoading = true
  
        await users.helpers.reset()
        $scope.isLoading = false
        
      }
     
      $scope.loadUsers = async () => {
        if ($scope.userList.length === 0) {
          $scope.isLoading = true
        }
       
        // users.helpers.performance_init().then((users) => {
        //   $scope.isLoading = false
        // })
       await users.helpers.getList()
       await users.helpers.export()
       $scope.isLoading = false
      }

      $scope.getLength = (arr) => {
        if (!arr) return 0
        return arr.length
      }

      $scope.onSearch = () => {
        $scope.isLoading = true
        users.helpers.handleSearchKeyword($scope.fields.searchTerm)
        $scope.loadUsers()
        $scope.isLoading = false
      }

      $(document).on('click', '.pagination li:not(.disabled) a', (e) => {
        $scope.isLoading = true
        const n = Number($(e.target).text())
        users.helpers.paginateToPage(!isNaN(n) ? n : $scope.collectionView.currentPage + ($(e.target).text() === 'Prev' ? -1 : +1))
        $scope.loadUsers()
        $scope.isLoading = false
      })

      $scope.selectCommunity = (community) => {
        $scope.isLoading = true
        // const id = typeof community === 'object' && community._id
        // users.helpers.selectCommunity(id)
         users.helpers.selectCommunity(community._id)
        $scope.loadUsers()
        $scope.isLoading = false
      }

      $scope.selectTeam = async (team) => {
        $scope.isLoading = true
        await users.helpers.selectTeam(team)
        $scope.loadUsers()
        $scope.isLoading = false
      }

      // called in table row to display team names
      $scope.getTeamNames = function (teamIds = []) {
        if (teamIds.length == 0) {
          return 'No Teams'
        } else if (teamIds.length > 5) {
          return `${teamIds.length} Teams`
        }

        const names = []
        _.map($scope.teamsList, (team) => {
          if (teamIds.indexOf(team._id) != -1) { names.push(team.name) }
        })
        return names.join(', ')
      }

      $scope.updateItemsPerPage = (number) => {
        users.helpers.updateItemsPerPage(number)
      }

      $rootScope.editUser = function (user, $event) {
        if ($event.metaKey || $event.ctrlKey) {
          window.open('#/users/' + user._id + '?t=' + Date.now(), '_blank')
        } else { return $location.url('/users/' + user._id + '?t=' + Date.now()) }
      }

      $rootScope.addUser = function (user) {
        $scope.userList.push(user)
      }

      $scope.createUser = function () {
        $location.path('users/new')
      }

      $scope.export = {}
      $scope.export.columnOrder = USER_ORDER
      $scope.export.headers = USER_HEADERS
      $scope.export.exportDataAll = () => exportHelper.exportUserData()
        .then((users) => users.map((user) => {
          if (user.segments) {
            user.segments = user.segments.join(', ')
          }
          if (user.segmentsNames) {
            user.segmentsNames = user.segmentsNames.join(', ')
          }
          if (user.teams) {
            user.teams = user.teams.join(', ')
          }
          if (user.connections) {
            user.connections = user.connections.join(', ')
          }
          if (user.goalsStarted){
            user.goalsStarted = user.goalsStarted || 0
          }
          if (user.goalsAchieved){
            user.goalsAchieved = user.goalsAchieved || 0
          }

          user.createdAt = moment(user._bmd.createdAt / 1000000).format()
          user.updatedAt = moment(user._bmd.updatedAt / 1000000).format()
          return user
        }))
        .catch((error) => {
          console.log('Error Exporting', error)
        })
    }])
//     $modal.open({
//       templateUrl: 'createUser.html',
//       backdrop: 'static',
//       controller: function ($scope, BendAuth, $modalInstance, userList) { // $scope gets reset
//         $scope.user = {
//           enabled: true,
//           defaultAvatar: commonUtil.animals[0],
//           gender: 'decline'
//         }
//         $scope.communityAdmin = getActiveUser().community.admin
//         $scope.commonUtil = commonUtil
//         $scope.fileProgress = []
//         $scope.isUploading = []
//         $scope.CommunityList = []
//         $scope.openedDateSelector = [false]
//         $scope.filter = {}
//         $scope.emailError = ''
//         $scope.errorMsg = []

//         $scope.cancel = function () {
//           $modalInstance.dismiss('cancel')
//         }
//         $scope.close = function () {
//           $modalInstance.dismiss('cancel')
//         }
//         $scope.openDateWindow = function ($event, idx) {
//           $event.preventDefault()
//           $event.stopPropagation()

//           $scope.openedDateSelector[idx] = true
//         }
//         users.helpers.getCommunityList().then((rets) => {
//           $scope.CommunityList = rets
//         })
//         $scope.createUserDo = async () => {
//           $scope.emailError = ''
//           $scope.errorMsg = []
//           $scope.isSaving = true
//           let community = ''
//           if ($scope.user.community) {
//             community = commonUtil.makeBendRef($scope.user.community, 'community')
//           } else {
//             delete $scope.user.community
//           }
//           if (typeof $scope.BirthDate == 'object') {
//             $scope.user.birthdate = commonUtil.formatDateWithFormat($scope.BirthDate.getTime() * 1000000, 'YYYY-MM-DD')
//           }

//           $scope.user.username = $scope.user.email

//           if (!$scope.user.gender || $scope.user.gender == '') {
//             delete $scope.user.gender
//           } else {
//             $scope.user.gender = $scope.user.gender.toLowerCase()
//           }

//           $scope.user.allowNotifications = true
//           $scope.user.shareActivity = true

//           if ($scope.user.avatar) { $scope.user.avatar = commonUtil.makeBendFile($scope.user.avatar._id) }
//           if (listScope.user.community.admin) {
//             community = listScope.user.community
//           }
//           const dataToSave = Object.assign({}, $scope.user, { community })
//           delete dataToSave.passwordConfirm
//           try {
//             await users.helpers.create(dataToSave)
//             applyChangesOnScope($scope, () => {
//               $modalInstance.dismiss('cancel')
//             })
//             listScope.isLoading = true
//             await listScope.loadUsers()
//           } catch (err) {
//             applyChangesOnScope($scope, () => {
//               $scope.isSaving = false
//               /* needs update to catch more errors */
//               $scope.emailError = 'required'
//               $scope.errorMsg.push('A user already exists for this email address')
//               /* we should get emailExists message or status code by request */
//             })
//           }
//         }

//         $scope.openFile = function (fileName) {
//           $("input[name='" + fileName + "']").click()
//         }

//         $scope.deleteFile = function (tag, $ev) {
//           $ev.stopPropagation()
//           $ev.preventDefault()
//           applyChangesOnScope($scope, function () {
//             delete $scope.user.avatar
//           })
//         }

//         $scope.onChangeDefaultAvatar = function () {
//           $scope.user.defaultAvatar = $scope.filter.defaultAvatar
//         }

//         $scope.showFileLoading = function (tag, bShow) {
//           $scope.isUploading[tag] = bShow
//         }

//         $scope.canSubmitValidationForm = function () {
//           return $scope.form.validateForm.$valid
//         }

//         $scope.selectFileOpen = function (fileId, $ev) {
//           if ($ev.target.tagName == 'DIV') {
//             setTimeout(function () {
//               $('#' + fileId).click()
//             }, 0)
//           }
//         }

//         $scope.onFileUpload = function (files, tag) {
//           var file = files[0]

//           $scope.fileProgress[tag] = 0
//           $scope.showFileLoading(tag, true)
//           users.helpers.upload(file, function (error, uploadedFile) {
//             if (error) {
//               $scope.showFileLoading(tag, false)
//               return
//             }

//             users.helpers.getFile(uploadedFile, function (o) {
//               $scope.user.avatar = o
//               $scope.showFileLoading(tag, false)
//             })
//           }, {
//             _workflow: 'avatar'
//           }, function (total, prog) {
//             applyChangesOnScope($scope, function () {
//               $scope.fileProgress[tag] = prog * 100 / total
//             })
//           })
//         }
//       },
//       resolve: {
//         userList: function () {
//           return $scope.userList
//         }
//       }
//     })
//   }
//
// }])
