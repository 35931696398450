'use strict'

import * as userActivities from '../../../src/features/userActivities'

angular.module('app.controllers')
  .controller('UserActivityEditController', ['$scope', '$location', '$routeParams', 'CommonUtil', '$ngRedux',
    function ($scope, $location, $routeParams, CommonUtil, $ngRedux) {
      // Init.
      $scope.CommonUtil = CommonUtil
      $scope.activityId = null
      $scope.userId = $routeParams.userId
      if ($routeParams.id != 'new') { $scope.activityId = $routeParams.id }
      $scope.isLoading = true
      $scope.communityList = []
      $scope.businessList = []
      $scope.actionList = []
      $scope.eventList = []
      $scope.volunteeringList = []
      $scope.serviceList = []
      $scope.activityList = []
      $scope.TypeList = ['action', 'business', 'event', 'volunteer_opportunity']
      $scope.user = {}
      $scope.formData = {
        communities: []
      }
      $scope.filter = {
        user: '',
        activity: ''
      }
      $scope.username = ''
      $scope.activity = {
        community: ''
      }
      $scope.community = ''

      async.parallel([
        async (callback) => {
          const user = await userActivities.helpers.getUser($scope.userId)
          applyChangesOnScope($scope, () => {
            $scope.user = user
            callback(null, null)
          })
        }
      ], function (err, rets) {
        if (err) {
          return
        }
        if ($scope.activityId) {
          userActivities.helpers.getActivity($scope.activityId).then(function (ret) {
            $scope.activity = ret

            if ($scope.activity.activity) {
              $scope.activityList = []
              $scope.activityList.push($scope.activity.activity)
              $scope.filter.activity = $scope.activity.activity
            }

            $scope.isLoading = false
          })
        }
      })

      $scope.refreshActivity = async (keyword) => {
        const settings = {
          keyword,
          type: _.get($scope, 'activity.type')
        }
        if (settings.type) {
          const activities = await userActivities.helpers.refreshActivities(settings)
          applyChangesOnScope($scope, () => {
            $scope.activityList = activities
          })
        }
      }

      $scope.onChangeActivity = function () {
        if ($scope.filter.activity) {
          $scope.activity.summary = $scope.filter.activity.name
          $scope.activity.points = $scope.filter.activity.points || 0
          $scope.activity.link = $scope.filter.activity.url
        } else {
          $scope.activity.summary = ''
          $scope.activity.points = 0
        }
      }

      $scope.onChangeType = function () {
        $scope.activityList = []
        $scope.filter.activity = ''
      }
      $scope.saveActivityDo = function () {
        if ($scope.filter.activity != '') {
          $scope.activity.activity = CommonUtil.makeBendRef($scope.filter.activity._id, $scope.activity.type)
        } else {
          delete $scope.activity.activity
        }

        $scope.activity.user = CommonUtil.makeBendRef($scope.user._id, 'user')

        $scope.activity.community = CommonUtil.makeBendRef($scope.user.community._id, 'community')

        var activityData = _.clone($scope.activity)

        if ($scope.activityId) {
          delete activityData.$$hashKey
          userActivities.helpers.update(activityData).then(function (ret) {
            $location.path('/users/' + $scope.userId)
          })
        } else {
          userActivities.helpers.create(activityData).then(function (ret) {
            $location.path('/users/' + $scope.userId)
          })
        }
      }
    }])
