'use strict'

import 'Flot'
import 'Flot/jquery.flot.pie.js'

import commonUtil from '../../helpers/commonUtil'
import * as surveyQuestions from '.'
import * as surveys from '../surveys'
import * as teams from '../teams'

angular.module('app.controllers')
  .controller('SurveyQuestionEditController', ['$scope', '$location', '$routeParams', 'BendAuth', '$rootScope', '$modal', '$ngRedux',
    function ($scope, $location, $routeParams, BendAuth, $rootScope, $modal, $ngRedux) {
      // Init.
      $scope.commonUtil = commonUtil
      $scope.user = BendAuth.getActiveUser()
      $scope.isEditMode = false
      $scope.showAllFields = true
      $scope.surveyQuestionId = null
      if ($routeParams.id != 'new') {
        $scope.surveyQuestionId = $routeParams.id
        $scope.isEditMode = true
      }

      const mapStateToThis = (state) => {
        return {
          surveys: state.surveys.list,
          teams: state.teams.list.teams
        }
      }

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      $scope.init = async () => {
        surveys.helpers.init()
        teams.helpers.init()
      }
      $scope.init()

      $scope.communityList = []
      $scope.answerList = []
      $scope.responseList = []
      $scope.userList = []
      $scope.isAnswer = true


      //@reminder: below will need to updated at a later point
      $scope.surveyQuestion = {
        responseCount: 0,
        type: "single-select",
        required: true, 
        questionId: ""
      }

      $scope.survey = {}
      $scope.questionList = []
      $scope.questionObj = {}
      $scope.isShowType = 0

      $scope.formData = {
        communities: [],
        openedDateSelector: false,
        teams: [],
        survey: {
          date: '',
          name: '',
          points: 0
        }
      }

      $scope.form = {}

      $scope.teams = []

      var oldAnswersPosList = []

      // could refactor this to take the id, only push id to the communities array. This is called 
      $scope.selectCommunityTeams = async () => {

        if ($scope.formData.communities instanceof Array &&
            $scope.formData.communities.length &&
            typeof $scope.formData.communities[0]._id === 'string'
        ) {
          const communityId = _.get($scope.formData, 'communities[0]._id')
          teams.helpers.init(communityId).then(teams => {
            applyChangesOnScope($scope, () => {
              $scope.teams = _.orderBy(teams, (i) => i.name.toLowerCase(), ['asc'])
            })
          })
        }
      }

      // selects all community teams for the survey question. This is called 
      $scope.$watch('formData.communities', () => {
        $scope.selectCommunityTeams()
      })

      // preselects community and triggers team selection 
      if ($scope.user.communityAdmin) {        
        $scope.formData.communities.push($scope.user.community)
      }


      // Runs several functions that: 
        // (1) gets answers list -> talks to pollQuestionAnswer (contains the answer choices)
        // (2) gets response lists -> talks to pollQuestionResponse (contains answer, question, and user IDs)
        
      if ($scope.surveyQuestionId) {
        async.parallel([
          function (cb) {
            $scope.linkedPushToggle = false
            surveyQuestions.helpers.get($scope.surveyQuestionId).then(function (ret) {
              $scope.surveyQuestion = ret
              $scope.oldQuestion = _.clone(ret)

              if ($scope.surveyQuestion.communities && $scope.surveyQuestion.communities.length > 0) {
                $scope.formData.communities = _.filter($scope.communityList, function (o) {
                  return $scope.surveyQuestion.communities.indexOf(o._id) != -1
                })
              }
              if ($scope.surveyQuestion.survey) {
                surveys.helpers.get($scope.surveyQuestion.survey._id).then(survey => {
                  $scope.survey = survey
                  applyChangesOnScope($scope, () => {})
                })
              }

              if ($scope.surveyQuestion.teams) {
                $scope.formData.teams = _.filter($scope.teams, function (o) {
                  return $scope.surveyQuestion.teams.indexOf(o._id) != -1
                })
              }

              // update value for the toggle in the ui
              // find push associated with this survey, save it
              if (ret.push && ret.push._id) {
                const settings = {
                  surveyId: _.get(ret, '_id')
                }
                surveyQuestions.helpers.fetchLinkedPushNotification(settings).then(function (pushArr) {
                  if (pushArr.length > 0) {
                    $scope.linkedPushToggle = true
                  }
                }, function (err) { })
              } else { $scope.linkedPushToggle = false }
              cb(null, null)
            })
          },
          async (cb) => {
            const response = await surveyQuestions.helpers.getAnswerList($scope.surveyQuestionId)
            applyChangesOnScope($scope, () => {
              $scope.answerList = response
              cb(null, null)
            })
          },
          async (cb) => {
            const response = await surveyQuestions.helpers.getResponseList($scope.surveyQuestionId)
            applyChangesOnScope($scope, () => {
              if ($scope.surveyQuestionId) {
                $scope.responseList = response
              }
              cb(null, null)
            })
          }
        ], function (err, ret) {
          $scope.enableSortableAnswers()

          const chartData = []
          _.map($scope.answerList, function (o) {
            chartData.push({
              label: o.title,
              data: $scope.getCount(o)
            })
          })

          $.plot($('#response-pie'), chartData, {
            series: {
              pie: {
                show: true
              }
            },
            legend: {
              show: false
            }
          })
        })
      }


      $scope.getTeamNames = ids => {
        if (ids) return teams.helpers.getTeamNames(ids, $scope.teams)
      }

      // Event hanndler to handle drag-and-drop sorting of answer choices 
      $scope.enableSortableAnswers = function () {
        console.log("enableSortableAnswers called"); 
        $('#answers-container').sortable({
          opacity: 0.5,
          dropOnEmpty: true,
          start: function (event, ui) {
            console.log("sortable start function called"); 
            console.log("event is: ", event); 
            console.log("ui is: ", ui); 
            ui.item[0].style.backgroundColor = '#eee'
            oldAnswersPosList = []
            $scope.answerList.map(function (o) {
              console.log("o is : ", o); 
              oldAnswersPosList[o._id] = o.position
            })
          },
          update: function (event, ui) {
            console.log("sortable update function called"); 
            $('.answer-content').each(function (idx) {
              var answerKey = $(this).find('#answerId-input')[0].value
              console.log("answerKey is: ", answerKey); 
              var answer = _.find($scope.answerList, function (_o) {
                console.log("_o is :", _o)
                return _o.$$hashKey == answerKey
              })
              
              answer.position = idx + 1
              console.log("answer is: ", answer); 
            })

            applyChangesOnScope($scope, function () {
              $scope.answerList = _.sortBy($scope.answerList, function (o) {
                return o.position
              })
            })
            ui.item[0].style.backgroundColor = ''
          }
        })
        $('#answers-container').sortable('option', 'disabled', false)
      }

      // Event hanndler to handle drag-and-drop sorting of answer choices 
      $scope.disableSortableAnswers = function () {
        $('#answers-container').sortable('disable')
      }


      // EventHandler to view answer choice 
      $scope.editSurveyAnswer = function (answer) {
        $scope.surveyAnswer = answer
        $scope.viewAnswer = true
      }


      $scope.getCount = function (answer) {
        var count = 0
        _.map(answer.counts || [], function (o) {
          count += o
        })

        return count
      }

      $scope.deleteSurveyQuestion = function (id, e) {
        e.preventDefault()
        e.stopPropagation()
        const yeap = confirm('Deleting the question is permanent and can not be undone.')
        if (!yeap) {
          return false
        }
        surveyQuestions.helpers.delete(id)
      }

      // Deletes survey answer choice 
      $scope.deleteSurveyAnswer = async (item, $ev) => {
        $ev.stopPropagation()
        $ev.preventDefault()
        $scope.answerList = _.difference($scope.answerList, item)
        $scope.answerList.map(function (o, idx) {
          o.position = idx + 1
        })

        const itemIndex = $scope.answerList.indexOf(item)
        const deletedData = $scope.answerList.filter((item, index) => index !== itemIndex)

        if (!item._id) {
          $scope.answerList = deletedData
          return
        }

        await surveyQuestions.helpers.deleteSurveyAnswer(item._id)
        applyChangesOnScope($scope, () => {
          $scope.answerList = deletedData
        })
      }

      $scope.getSurveyResponses = (survey) => {
        $scope.survey = survey
      }

      $rootScope.enableSort = function () {
        $scope.enableSortableAnswers()
      }

      $scope.openDateWindow = function ($event) {
        $event.preventDefault()
        $event.stopPropagation()

        $scope.formData.openedDateSelector = true
      }

      $scope.viewAnswer = false

      var answerObj = {
        count: 0,
        percentage: 0,
        position: 0,
        question: $scope.surveyQuestion
      }

      $scope.openSurveyAnswer = () => {

        console.log("survey answer opened"); 
        // console.log("$scope.answerList.length in openSurveyAns: ", $scope.answerList.length); 
        // console.log("ans object is: ", answerObj); 

        $scope.surveyAnswer = _.clone(answerObj)
        // console.log("$scope.surveyAnswer within open: ", $scope.surveyAnswer); 
        $scope.viewAnswer = true
      }

      $scope.close = function () {
        $scope.viewAnswer = false
      }

      // saves updated answer choice edit 
        // Bug relating to ability to save answer choices 
      $scope.saveAnswerDo = function(another) {
        console.log("$scope.answerList is: ", $scope.answerList); 
        console.log("$scope.answerList.length is: ", $scope.answerList.length); 

        let surveyAns = $scope.surveyAnswer; 

        surveyAns.position = $scope.answerList.length + 1; 
        console.log("survey ans is: ", surveyAns); 

        $scope.answerList.push(surveyAns); 

        console.log("full answer list is: ", $scope.answerList); 

        if (another) {
          $scope.viewAnswer = true
        }
        $scope.viewAnswer = false
        $rootScope.enableSort()
      }

      // form validation using angular ng-class
      $scope.validateForm = () => {
        console.log("question validate form called"); 
        const required = 'required'
        const question = _.get($scope.surveyQuestion, 'question')

        console.log("question is: ", question); 

        let questionId; 

        if (question) {
          // call seperate UUID function 
          questionId = $scope.generateUUID(); 
        
        }

        console.log("$scope.surveyQuestion is: ", $scope.surveyQuestion)
        $scope.surveyQuestion.questionId = questionId; 

        const answersExist = _.get($scope, 'answerList').length
        if (question === '' || !question) $scope.questionReq = required
        if (answersExist === 0 || !answersExist) $scope.answersReq = required
        if (
          !question ||
          !answersExist === 0 ||
          !answersExist
        ) {
          $scope.errorMsg = true
          return false
        }
        return true
      }

      // Generates unique id for each question to tie to each corresponding answer 
      $scope.generateUUID = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
      }
      
      // UUID function 

      // Creates survey question (or updates survey question) 
        // see functions: 
          // (1) updateSurveyQuestion -> talks to pollQuestion & pollQuestionAnswer
          // (2) createSurveyQuestion -> talks to pollQuestion & pollQuestionAnswer
      $scope.saveSurveyDo = function () {

         if (!$scope.validateForm()) return

        var surveyQData = _.clone($scope.surveyQuestion);
        let surveyParent = commonUtil.makeBendRef($scope.survey, 'survey');
        console.log("survey Parent before: ", surveyParent); 

        var name = "test"; 
        

        if (surveyQData.community && surveyQData.community._id != '') { // if community exists
          surveyQData.community = commonUtil.makeBendRef(surveyQData.community._id, 'community')
        } else {
          delete surveyQData.community
        }
        if (typeof $scope.formData.survey.date === 'object') { // if date exists in object format
          $scope.formData.survey.date = commonUtil.formatDateWithFormat(new Date($scope.formData.survey.date).getTime() * 1000000, 'YYYY-MM-DD')
        }

        if ($scope.formData.teams &&
          $scope.formData.teams.length > 0 &&
          $scope.formData.teams.indexOf({ _id: null, name: 'All Users' }) === -1 // if all users is not selected
        ) {
          surveyQData.teams = commonUtil.getIdList($scope.formData.teams)
        } else {
          delete surveyQData.teams
        }

        // save question and answers in question object 
        $scope.questionObj.question = surveyQData;
        $scope.questionObj.answers = $scope.answerList; 
        let questionAndAnswer = _.clone($scope.questionObj); 
        // add question object into question list array 
        $scope.questionList.push(questionAndAnswer); 

        console.log("surveyParent._id is: ", surveyParent._id); 
        
        // add question list to questions property in the survey parent object 
        surveyParent._id.questions.push($scope.questionList); 



        // if (saveAnother === 'exit') $location.path('/surveyQuestions')
        // save survey question
        if ($scope.surveyQuestionId) { // if existing
          $scope.isSaving = true
          surveyQuestions.helpers.updateSurveyQuestion(surveyQData, $scope.answerList, function (surveyQRet, savedAnswers) {
            $scope.isSaving = false
            // if ($scope.shouldUpdateAutoPush($scope.oldQuestion, surveyQRet, 'pollQuestion', $scope.linkedPushToggle)) {
            //   $scope.autoPush(surveyQRet, 'pollQuestion', savedAnswers)
            // }
          })
        } else { // create question

          console.log("create question else statement"); 

          $scope.isSaving = true
          let community;
          if ($scope.user.communityAdmin) {
            community = [$scope.user.community]
          } else {
            community = $scope.formData.communities
          }

       
          // I think surveyParent._id might work console.log("surveyParent is: ", surveyParent._id); 
          surveyQuestions.helpers.createSurveyQuestion(surveyParent._id, community, $scope.answerList, function (surveyQRet, savedAnswers) {
            $scope.isSaving = false
            if ($scope.shouldUpdateAutoPush($scope.oldQuestion, surveyQRet, 'pollQuestion', $scope.linkedPushToggle)) {
              console.log("function inside ShouldUPdateAutoPush called"); 
              $scope.autoPush(surveyQRet, 'pollQuestion', savedAnswers)
            }
          })

        }
  
        $location.path('/surveyQuestions')
      }

      // $scope.createSurveyAnswer = function () {
      //   var answerObj = {
      //     count: 0,
      //     percentage: 0,
      //     position: $scope.answerList.length + 1,
      //     question: $scope.surveyQuestion
      //   }
      //   $scope.openSurveyAnswer(answerObj)
      //   console.log("get into it")
      // }

      // $scope.openSurveyAnswer = function (answerObj) {
      //   $modal.open({
      //     templateUrl: 'editAnswer.html',
      //     backdrop: 'static',
      //     controller: function ($scope, $modalInstance, answerObj, answerList) {
      //       $scope.commonUtil = commonUtil
      //       $scope.surveyAnswer = _.clone(answerObj)
      //
      //       $scope.cancel = function () {
      //         $modalInstance.dismiss('cancel')
      //       }
      //       $scope.close = function () {
      //         $modalInstance.dismiss('cancel')
      //       }
      //
      //       $scope.saveAnswerDo = function () {
      //         if ($scope.surveyAnswer.$$hashKey) {
      //           var ref = _.find(answerList, function (o) {
      //             return o.$$hashKey == $scope.surveyAnswer.$$hashKey
      //           })
      //
      //           _.extend(ref, $scope.surveyAnswer)
      //           $modalInstance.dismiss('cancel')
      //         } else {
      //           answerList.push($scope.surveyAnswer)
      //           $modalInstance.dismiss('cancel')
      //           $rootScope.enableSort()
      //         }
      //       }
      //
      //       // this is only for editing survey question answers and it doesn't do anything
      //       $scope.canSubmitValidationForm = function () {
      //         return $scope.form.validateForm2.$valid
      //       }
      //     },
      //     resolve: {
      //       answerObj: function () {
      //         return answerObj
      //       },
      //       answerList: ['$scope', function ($scope) {
      //         return $scope.answerList
      //       }]
      //     }
      //   })
      // }
    }])
