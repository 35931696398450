import {
  UPDATE_SPRINTS_LIST,
  UPDATE_SPRINTS_TAB_LIST,
  UPDATE_SPRINTS_ITEM,
  DELETE_ITEM,
  EDIT_ITEM,
  ADD_ITEM
} from '../constants/types'
import { Sprints } from '../reducers/list'

export interface Action {
  id?: string,
  type: string,
  sprints: Sprints,
  sprint?: any,
  activeUsers?: number,
  totalPoints?: number
}

export const update = (sprints: Sprints): Action => {
  return {
    type: UPDATE_SPRINTS_LIST,
    sprints
  }
}

export const updateTab = (sprints: Sprints): Action => {
  return {
    type: UPDATE_SPRINTS_TAB_LIST,
    sprints
  }
}

export const updateActiveUsers = (id: string, activeUsers: number, totalPoints: number) => {
  return {
    type: UPDATE_SPRINTS_ITEM,
    id,
    activeUsers,
    totalPoints
  }
}

export const deleteItem = (id: string) => {
  return {
    type: DELETE_ITEM,
    id
  }
}

export const editItem = (sprint: any) => {
  return {
    type: EDIT_ITEM,
    sprint
  }
}

export const addItem = (sprint: any) => {
  return {
    type: ADD_ITEM,
    sprint
  }
}
