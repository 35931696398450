import { getActiveUser } from './helpers'

export interface Settings {
  searchTerm: string,
  category: {
    id: string,
    type: string,
    name: string,
    groups: { }
  }
  collection: {
    id: string
  },
  communityId: string,
  teamId: string,
  itemsPerPage: number,
  currentPage: number
  sort: {
    direction: { },
    key: string
  }
}

export let defaultSettings = {
  searchTerm: '',
  category: {
    id: '',
    type: '',
    name: '',
    groups: {}
  },
  collection: {
    id: ''
  },
  // communityId: getActiveUser().community._id,
  communityId: '',
  teamId: '',
  itemsPerPage: 100,
  currentPage: 1,
  sort: {
    direction: {},
    key: ''
  }
}
