import { Action } from '../actions/list'
import { UPDATE_RESPONSES_LIST, UPDATE_SURVEY_RESPONSES_LIST, UPDATE_SURVEYS_LIST } from '../constants/types'

export interface Item {
}

export type Surveys = Array<any>

export type Responses = Array<any>

export type SurveyResponses = {}

export type List = {
  surveys: Surveys,
  responses: Responses,
  surveyResponses: SurveyResponses
}

const initialState = {
  surveys: [],
  responses: [],
  surveyResponses: []
}

export default (state: List = initialState, action: Action | any): List => {
  switch (action.type) {
    case UPDATE_SURVEYS_LIST:
      return {
        ...state,
        surveys: action.surveys
      }
    case UPDATE_RESPONSES_LIST:
      return {
        ...state,
        responses: action.responses
      }
    case UPDATE_SURVEY_RESPONSES_LIST:
      return {
        ...state,
        surveyResponses: action.surveyResponses
      }
    default:
      return state
  }
}
