'use strict'

import 'Flot'
import 'Flot/jquery.flot.pie.js'

import * as surveys from '.'
import * as teams from '../teams'
import * as segments from '../segments'
import * as categories from '../categories'
import commonUtil from '../../helpers/commonUtil'
import {RESPONSES_HEADERS, RESPONSES_ORDER} from '@/helpers/export'

angular.module('app.controllers').controller('SurveyEditController', [
  '$scope',
  '$location',
  '$routeParams',
  'BendAuth',
  '$rootScope',
  '$ngRedux',
  async function (
    $scope,
    $location,
    $routeParams,
    BendAuth,
    $rootScope,
    $ngRedux
  ) {
    $scope.commonUtil = commonUtil
    $scope.user = BendAuth.getActiveUser()
    $scope.survey = {}
    $scope.filterDateFrom = new Date()
    $scope.filterDateFrom.setDate($scope.filterDateFrom.getDate() - 30)
    $scope.filterDateFrom = $scope.filterDateFrom.toISOString().split('T')[0]
    $scope.filterDateTo = new Date().toISOString().split('T')[0]
    $scope.pagination = {
      itemsPerPage: 10,
      pagedItems: [],
      currentPage: []
    }
    $scope.barCharts = ['Single Select', 'Multiple Select', 'Slider']
    $scope.filterUsers = []
    $scope.orderByField = 'answeredDate'
    $scope.reverseSort = false

    $scope.answerList = []
    $scope.questionTypes = [
      'Single Select',
      'Multiple Select',
      'Single Line Text Input',
      'Multi-line Text Input',
      'Picture Input',
      'Slider',
      'Date',
      'Number Input'
    ]

    /** *** Question Types *****/
    $scope.singleSelect = false
    $scope.multipleSelect = false
    $scope.singleLineText = false
    $scope.multiLineText = false
    $scope.pictureInput = false
    $scope.slider = false
    $scope.date = false
    $scope.numberInput = false
    $scope.volunteerHours = false
    /** *** Dynamic Modals *****/
    $scope.viewDropDown = false
    $scope.viewAnswer = false

    /** *** Error Messages *****/
    $scope.questionTextErrorMsg = false
    $scope.answersErrorMsg = false
    $scope.maxValueTextError = false
    $scope.minValueTextError = false

    $scope.surveyQuestion = {
      responseCount: 0,
      type: 'Single Select',
      required: true,
      questionId: ''
    }

    $scope.questionList = []
    $scope.questionObj = {}

    $scope.formData = {
      teams: [],
      segments: []
    }

    $scope.openedDateSelector = [false, false]
    $scope.openDateWindow = function ($event, idx) {
      $event.preventDefault()
      $event.stopPropagation()

      $scope.openedDateSelector[idx] = true
    }

	$scope.isDeleted =  (item) => !item.question.deleted

    const mapStateToThis = (state) => ({
      teams: state.teams.list.teams,
      segments: state.segments.list,
      userGroups: state.groups.list.groups,
      responsesList: state.surveys.list.responses,
      surveyResponsesList: state.surveys.list.surveyResponses,
      questionTypes: $scope.questionTypes,
      categories: state.categories.list
    })

    const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
    $scope.$on('$destroy', unsubscribe)
    $scope.tabs = {
      survey: true,
      responses: false,
      report: false
    }

    $scope.user = await BendAuth.getActiveUser()

    // Gets survey by params ID from database to display (if it exists)
    if ($routeParams.id !== 'new') {
      $scope.surveyId = $routeParams.id
      $scope.isEditMode = true

      // fetches the individual survey by id
      await surveys.helpers.get($scope.surveyId).then(async (survey) => {
        // you'll want to set questions equal here as well

        $scope.survey = survey

        await surveys.helpers.editInit($scope.survey._id)
        await teams.helpers.getList($scope.survey.community._id)
        await segments.helpers.init($scope.survey.community._id)
        await categories.helpers.getList($scope.survey.community._id)
        await surveys.helpers.getSurveyResponsesList($scope.responsesList, $scope.survey, $scope.filterDateFrom, $scope.filterDateTo)

        if (survey.teams && survey.teams.length) {
          $scope.formData.teams = _.filter($scope.teams, (o) => survey.teams.indexOf(o._id) != -1)
        }
        if (survey.segments && survey.segments.length) {
          $scope.formData.segments = _.filter($scope.segments, (o) => survey.segments.indexOf(o._id) != -1)
        }
        if (survey.categories && survey.categories.length) {
          $scope.formData.categories = _.filter($scope.categories, (o) => survey.categories.indexOf(o._id) != -1)
        }
      })
    } else {
      $scope.survey = {
        enabled: true,
        notifyOnSend: false,
        version: 2,
        allQuestions: []
      }

      if ($scope.user.communityAdmin) {
        await teams.helpers.getList($scope.user.community._id)
        await segments.helpers.init($scope.user.community._id)
        await categories.helpers.getList($scope.user.community._id)
      } else {
        await teams.helpers.getList()
        await segments.helpers.init()
        await categories.helpers.getList()
      }
    }

    await $scope.responsesList.map((response) => $scope.filterUsers.push(response.user))

    $scope.$on('$viewContentLoaded', () => {
      surveys.helpers.initCharts($scope.survey, $scope.surveyResponsesList)
    })

    $scope.openQuestionTypeDropDown = () => {
      $scope.cancelQuestion().then(() => {
        $scope.surveyQuestion = {
          responseCount: 0,
          type: '',
          required: true
        }
        $scope.answerList = []
        $scope.viewDropDown = true
      })

      // make sure that answerList and $scope.surveyQuestion are blank

      // $scope.viewQuestionFields = true;
    }

    $scope.viewQuestionFields = false

    $scope.cancelQuestion = async () => {
      $scope.singleSelect = false
      $scope.multipleSelect = false
      $scope.singleLineText = false
      $scope.multiLineText = false
      $scope.pictureInput = false
      $scope.numberInput = false
      $scope.slider = false
      $scope.date = false
      $scope.volunteerHours = false

      $scope.questionTextErrorMsg = false
      $scope.answersErrorMsg = false
      $scope.maxValueTextError = false
      $scope.minValueTextError = false

      $scope.viewQuestionFields = false
      $scope.viewDropDown = false
      $scope.answerList = []
      $scope.surveyQuestion = {}
      $scope.questionObj = {}
    }

    $scope.getQuestionType = (type) => {
      switch (type) {
        case 'Single Select':
          $scope.questionType = 'Single Select'
          $scope.singleSelect = true
          $scope.viewQuestionFields = true

          $scope.date = false
          $scope.slider = false
          $scope.pictureInput = false
          $scope.multiLineText = false
          $scope.singleLineText = false
          // $scope.singleSelect = false;
          $scope.volunteerHours = false
          $scope.numberInput = false

          break
        case 'Multiple Select':
          $scope.questionType = 'Multiple Select'
          $scope.singleSelect = true
          $scope.viewQuestionFields = true

          $scope.date = false
          $scope.slider = false
          $scope.pictureInput = false
          $scope.multiLineText = false
          $scope.singleLineText = false
          // $scope.singleSelect = false;
          $scope.volunteerHours = false
          $scope.numberInput = false

          break
        case 'Single Line Text Input':
          $scope.questionType = 'Single Line Text Input'
          $scope.singleLineText = true
          $scope.viewQuestionFields = true

          $scope.date = false
          $scope.slider = false
          $scope.pictureInput = false
          $scope.multiLineText = false
          // $scope.singleLineText = false;
          $scope.singleSelect = false
          $scope.volunteerHours = false
          $scope.numberInput = false

          break
        case 'Multi-line Text Input':

          $scope.questionType = 'Multi-line Text Input'
          $scope.multiLineText = true
          $scope.viewQuestionFields = true

          $scope.date = false
          $scope.slider = false
          $scope.pictureInput = false
          // $scope.multiLineText = false;
          $scope.singleLineText = false
          $scope.singleSelect = false
          $scope.volunteerHours = false
          $scope.numberInput = false

          break
        case 'Picture Input':

          $scope.questionType = 'Picture Input'
          $scope.pictureInput = true
          $scope.viewQuestionFields = true

          $scope.date = false
          $scope.slider = false
          // $scope.pictureInput = false;
          $scope.multiLineText = false
          $scope.singleLineText = false
          $scope.singleSelect = false
          $scope.volunteerHours = false
          $scope.numberInput = false

          break
        case 'Slider':

          $scope.questionType = 'Slider'
          $scope.slider = true
          $scope.viewQuestionFields = true

          $scope.date = false
          // $scope.slider = false;
          $scope.pictureInput = false
          $scope.multiLineText = false
          $scope.singleLineText = false
          $scope.singleSelect = false
          $scope.volunteerHours = false
          $scope.numberInput = false

          break
        case 'Date':

          $scope.questionType = 'Date'
          $scope.date = true
          $scope.viewQuestionFields = true

          // $scope.date = false;
          $scope.slider = false
          $scope.pictureInput = false
          $scope.multiLineText = false
          $scope.singleLineText = false
          $scope.singleSelect = false
          $scope.volunteerHours = false
          $scope.numberInput = false

          break
        case 'Number Input':

          $scope.questionType = 'Number Input'
          $scope.numberInput = true
          $scope.viewQuestionFields = true

          $scope.date = false
          $scope.slider = false
          $scope.pictureInput = false
          $scope.multiLineText = false
          $scope.singleLineText = false
          $scope.singleSelect = false
          $scope.volunteerHours = false
          // $scope.numberInput = false;

          break
        case 'Volunteer Hours':

          $scope.questionType = 'Volunteer Hours'
          $scope.volunteerHours = true
          $scope.viewQuestionFields = true

          $scope.date = false
          $scope.slider = false
          $scope.pictureInput = false
          $scope.multiLineText = false
          $scope.singleLineText = false
          $scope.singleSelect = false
          // $scope.volunteerHours = false;
          $scope.numberInput = false

          break
      }
    }

    /* ======================== Question Start ========================= */

    $scope.saveQuestion = () => {
      let createQandA

      if (!$scope.surveyQuestion.questionId) {
        createQandA = new Promise((resolve, reject) => {
          const questionObj = {}
          const question = _.get($scope.surveyQuestion, 'question')
          let questionId

          if (question) {
            // call seperate UUID function
            questionId = $scope.generateUUID()
          }

          const questionType = _.clone($scope.questionType)
          $scope.surveyQuestion.details = _.get($scope.surveyQuestion, 'details')
          // $scope.surveyQuestion.type = _.get($scope.questionType, "type");
          $scope.surveyQuestion.type = questionType

          const allAnswers = _.clone($scope.answerList)

          _.map(allAnswers, (item) => {
            item.question.questionId = questionId
            item.question.question = $scope.surveyQuestion.question
          })

          questionObj.question = _.clone($scope.surveyQuestion)
          questionObj.answers = allAnswers

          switch ($scope.surveyQuestion.type) {
            case 'Single Select':

              if (!questionObj.question.question) {
                return $scope.questionTextErrorMsg = true
              }
              if (questionObj.answers.length === 0) {
                return $scope.answersErrorMsg = true
              }

              break
            case 'Multiple Select':

              if (!questionObj.question.question) {
                return $scope.questionTextErrorMsg = true
              } else if (questionObj.answers.length === 0) {
                return $scope.answersErrorMsg = true
              }

              break
            case 'Single Line Text Input':

              if (!questionObj.question.question) {
                return $scope.questionTextErrorMsg = true
              }

              break
            case 'Slider':

              if (!questionObj.question.question) {
                return $scope.questionTextErrorMsg = true
              }

              if (!questionObj.question.maxValue) {
                return $scope.maxValueTextError = true
              }

              if (!questionObj.question.minValue && questionObj.question.minValue !== 0) {
                return $scope.minValueTextError = true
              }
              // if max value isn't larger than min value

              break
            case 'Picture Input':

              if (!questionObj.question.question) {
                return $scope.questionTextErrorMsg = true
              }

              break

            case 'Date':

              if (!questionObj.question.question) {
                return $scope.questionTextErrorMsg = true
              }

              break
            case 'Number Input':

              if (!questionObj.question.question) {
                return $scope.questionTextErrorMsg = true
              }

              break
            case 'Volunteer Hours':

              if (!questionObj.question.question) {
                return $scope.questionTextErrorMsg = true
              }

              break

            default:

            // here you set all those scopes to true
          }

          questionObj.question.questionId = questionId

          $scope.survey.allQuestions.push(questionObj)

          resolve()
        })

        createQandA.then(() => {
          $scope.cancelQuestion()
        })
      } else {
        let updateQuestion

        updateQuestion = new Promise((resolve, reject) => {
          _.map($scope.survey.allQuestions, (item) => {
            if (item.question.questionId === $scope.surveyQuestion.questionId) {
              item.question = $scope.surveyQuestion
              item.answers = $scope.answerList
            }
          })

          resolve()
        })

        updateQuestion.then(() => {
          // $scope.cancelQuestion();
          $scope.viewQuestionFields = false
          $scope.viewDropDown = false
        })
      }
    }

    // Generates unique id for each question to tie to each corresponding answer
    $scope.generateUUID = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)
      return v.toString(16)
    })

    $scope.generateAnsId = () => 'xxxx'.replace(/[xy]/g, (c) => {
      let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)
      return v.toString(16)
    })

    // Delete quetion

    $scope.deleteSurveyQuestion = (item, $ev) => {
      $ev.stopPropagation()
      $ev.preventDefault()

    //   const itemIndex = $scope.survey.allQuestions.indexOf(item)
    //   const newQuestionList = $scope.survey.allQuestions.filter(
    //     (item, index) => index !== itemIndex
    //   )

		const newQuestionList = $scope.survey.allQuestions.map(question => {
			let newQuestion = { ...question }
			if (question == item) newQuestion.question.deleted = true
			return newQuestion
		})

      $scope.survey.allQuestions = newQuestionList

      $scope.enableQuestionSort()
    }

    $scope.editSurveyQuestion = (item) => {
      switch (item.question.type) {
        case 'Single Select':

          $scope.cancelQuestion().then(() => {
            $scope.viewAllFields()

            // $scope.viewDropDown = true;
            // $scope.viewQuestionFields = true;

            $scope.surveyQuestion = item.question
            $scope.answerList = item.answers
            $scope.singleSelect = true
            $scope.queType = item.question.type
          })

          break
        case 'Multiple Select':

          $scope.cancelQuestion().then(() => {
            $scope.viewAllFields()

            // $scope.viewDropDown = true;
            // $scope.viewQuestionFields = true;

            $scope.surveyQuestion = item.question
            $scope.answerList = item.answers
            $scope.singleSelect = true
            $scope.queType = item.question.type
          })

          break
        case 'Single Line Text Input':

          $scope.cancelQuestion().then(() => {
            $scope.viewAllFields()

            // $scope.viewDropDown = true;
            // $scope.viewQuestionFields = true;

            $scope.surveyQuestion = item.question
            $scope.singleLineText = true
            $scope.queType = item.question.type
          })

          // $scope.singleSelect = false;

          break
        case 'Multi-line Text Input':

          $scope.cancelQuestion().then(() => {
            $scope.viewAllFields()

            // $scope.viewDropDown = true;
            // $scope.viewQuestionFields = true;

            $scope.surveyQuestion = item.question
            $scope.multiLineText = true
            $scope.queType = item.question.type
          })

          break
        case 'Picture Input':

          $scope.cancelQuestion().then(() => {
            $scope.viewAllFields()

            // $scope.viewDropDown = true;
            // $scope.viewQuestionFields = true;

            $scope.surveyQuestion = item.question
            $scope.pictureInput = true
            $scope.queType = item.question.type
          })

          break
        case 'Slider':

          $scope.cancelQuestion().then(() => {
            $scope.viewAllFields()

            $scope.surveyQuestion = item.question
            $scope.slider = true
            $scope.queType = item.question.type
          })

          break
        case 'Date':

          $scope.cancelQuestion().then(() => {
            $scope.viewAllFields()

            // $scope.viewDropDown = true;
            // $scope.viewQuestionFields = true;

            $scope.surveyQuestion = item.question
            $scope.date = true
            $scope.queType = item.question.type
          })

          break
        case 'Number Input':

          $scope.cancelQuestion().then(() => {
            $scope.viewAllFields()

            // $scope.viewDropDown = true;
            // $scope.viewQuestionFields = true;

            $scope.surveyQuestion = item.question
            $scope.numberInput = true
            $scope.queType = item.question.type
          })

          break
        case 'Volunteer Hours':

          $scope.cancelQuestion().then(() => {
            $scope.viewAllFields()

            $scope.surveyQuestion = item.question
            $scope.volunteerHours = true
            $scope.queType = item.question.type
          })

          break
      }

      $scope.enableQuestionSort()
    }

    $scope.viewAllFields = () => {
      $scope.viewDropDown = true
      $scope.viewQuestionFields = true
    }

    /* ======================== Question End ========================= */

    /* ======================== Survey Answers Start ========================= */

    const answerObj = {
      position: 0,
      question: {question: $scope.surveyQuestion.question, questionId: $scope.surveyQuestion.questionId}
    }

    // Event handler to open survey answer
    $scope.openSurveyAnswer = () => {
      $scope.surveyAnswer = _.clone(answerObj)
      $scope.viewAnswer = true
    }

    // Event handler to view answer choice
    $scope.editSurveyAnswer = function (answer) {
      $scope.surveyAnswer = answer
      $scope.viewAnswer = true
    }

    // Event handler to save answer choices
    $scope.saveAnswerDo = function (data) {
      if (!$scope.surveyAnswer.ansId) {
        $scope.answersErrorMsg = false

        const ansId = $scope.generateAnsId()

        const surveyAns = _.clone($scope.surveyAnswer)
        surveyAns.position = $scope.answerList.length + 1
        surveyAns.ansId = ansId

        $scope.answerList.push(surveyAns)
      } else {

      }

      if (data) {
        $scope.viewAnswer = true
      }
      $scope.viewAnswer = false

      $rootScope.enableSort()
    }

    // Event handler to cancel answer choice input

    $scope.close = function () {
      $scope.viewAnswer = false
    }

    // Deletes survey answer choice
    $scope.deleteSurveyAnswer = async (item, $ev) => {
      $ev.stopPropagation()
      $ev.preventDefault()
      $scope.answerList = _.difference($scope.answerList, item)

      $scope.answerList.map((o, idx) => {
        o.position = idx
      })

      $rootScope.enableSort()

      const itemIndex = $scope.answerList.indexOf(item)
      const deletedData = $scope.answerList.filter(
        (item, index) => index !== itemIndex
      )

      if (!item._id) {
        $scope.answerList = deletedData
        return
      }

      await surveyQuestions.helpers.deleteSurveyAnswer(item._id)
      applyChangesOnScope($scope, () => {
        $scope.answerList = deletedData
      })
    }

    $rootScope.enableSort = function () {
      $scope.enableSortableAnswers()
    }

    /** ************* ENABLE QUESTION SORT START ***************/

    $scope.questMousedown = function () {
      $scope.enableQuestionSort()
    }

    $scope.ansMousedown = function () {
      $scope.enableSortableAnswers()
    }

    $scope.enableQuestionSort = function () {
      // change out answerList -- maybe set it equal to survey.questions?
      // alternative is then to map over those questions and set the $scope.survey object equal to that new order??

      $('#questions-container').sortable({

        opacity: 0.5,
        dropOnEmpty: true,
        start: function (event, ui) {
          ui.item[0].style.backgroundColor = '#eee'
          const oldAnswersPosList = []

          $scope.survey.allQuestions.map((o) => {
            oldAnswersPosList[o._id] = o.position
          })
        },
        update: function (event, ui) {
          $('.question-content').each(function (idx) {
            const questionKey = $(this).find('#questionId-input')[0].value

            const question = _.find($scope.survey.allQuestions, (_o) => _o.$$hashKey == questionKey)

            question.position = idx + 1
          })

          applyChangesOnScope($scope, () => {
            $scope.survey.allQuestions = _.sortBy($scope.survey.allQuestions, (o) => o.position)
          })
          ui.item[0].style.backgroundColor = ''
        }

      })
      $('#questions-container').sortable('option', 'disabled', false)
    }

    /** ************* ENABLE QUESTION SORT END ***************/

    // Event handler to handle drag-and-drop sorting of answer choices
    $scope.enableSortableAnswers = function () {
      $('#answers-container').sortable({
        opacity: 0.5,
        dropOnEmpty: true,
        start: function (event, ui) {
          ui.item[0].style.backgroundColor = '#eee'
          const oldAnswersPosList = []
          $scope.answerList.map((o) => {
            oldAnswersPosList[o._id] = o.position
          })
        },
        update: function (event, ui) {
          $('.answer-content').each(function (idx) {
            const answerKey = $(this).find('#answerId-input')[0].value

            const answer = _.find($scope.answerList, (_o) => _o.$$hashKey == answerKey)

            answer.position = idx + 1
          })

          applyChangesOnScope($scope, () => {
            $scope.answerList = _.sortBy($scope.answerList, (o) => o.position)
          })
          ui.item[0].style.backgroundColor = ''
        }
      })
      $('#answers-container').sortable('option', 'disabled', false)
    }

    // Event hanndler to handle drag-and-drop sorting of answer choices
    $scope.disableSortableAnswers = function () {
      $('#answers-container').sortable('disable')
    }

    /* ======================== Survey Answers End ========================= */

    $scope.getSegmentInfo = (segment) => segments.helpers.getSegmentInfo(segment)

    $scope.deleteSurvey = function (id, e) {
      e.preventDefault()
      e.stopPropagation()
      const yeap = confirm(
        'Deleting the question is permanent and can not be undone.'
      )
      if (!yeap) {
        return false
      }
      surveys.helpers.delete(id)
    }

    $scope.onTypeSurvey = function () {
      if (!$scope.formData.survey._id) {
        $scope.formData.survey = {
          name: $scope.formData.survey,
          date: ''
        }
      }
    }

    $scope.toggleAutoPush = () => {
      $scope.linkedPushToggle = !$scope.linkedPushToggle
    }

    // form validation using angular ng-class
    $scope.validateForm = () => {
      const required = 'required'
      const name = _.get($scope.survey, 'name', null)
      const date = _.get($scope.survey, 'date', null)
      const points = _.get($scope.survey, 'points', null)

      if (name === '' || !name) $scope.nameReq = required
      if (!date) $scope.dateReq = required
      if (!points) $scope.pointsReq = required
      if (!name || !date || !points) {
        $scope.errorMsg = true
        return false
      }
      return true
    }

    $scope.selectCommunity = async (communityId) => {
      $scope.isLoading = true
      surveys.helpers.selectCommunity(communityId).then(() => {
        $scope.isLoading = false
      })
      await $scope.refresh($scope.responsesList, $scope.survey, $scope.filterDate, $scope.surveyResponsesList)
    }

    $scope.selectTeam = async (team) => {
      $scope.isLoading = true
      surveys.helpers.selectTeamEdit(team).then(() => {
        $scope.isLoading = false
      })
      await $scope.refresh($scope.responsesList, $scope.survey, $scope.filterDateFrom, $scope.filterDateTo, $scope.surveyResponsesList)
    }

    $scope.selectSegment = async (segment) => {
      $scope.isLoading = true
      surveys.helpers.selectSegment(segment).then(() => {
        $scope.isLoading = false
      })
      await $scope.refresh($scope.responsesList, $scope.survey, $scope.filterDateFrom, $scope.filterDateTo, $scope.surveyResponsesList)
    }

    $scope.selectUserGroup = (userGroup) => {
      $scope.isLoading = true
      surveys.helpers.selectUserGroup(userGroup).then(() => {
        $scope.isLoading = false
      })
      $scope.refresh($scope.responsesList, $scope.survey, $scope.filterDateFrom, $scope.filterDateTo, $scope.surveyResponsesList)
    }

    $scope.explode = function (array) {
      if (array) {
        let text = ''
        for (let i = 0; i < array.length; i++) {
          text += array[i] + ' '
        }
        return text
      } else return array
    }

    $scope.groupToPages = function () {
      $scope.pagination.pagedItems = []

      for (const [question, response] of Object.entries($scope.surveyResponsesList)) {
        $scope.pagination.pagedItems[question] = []
        $scope.pagination.currentPage[question] = 0
        for (let i = 0; i < response.answers.length; i++) {
          if (i % $scope.pagination.itemsPerPage === 0) {
            $scope.pagination.pagedItems[question][Math.floor(i / $scope.pagination.itemsPerPage)] = [response.answers[i]]
          } else {
            $scope.pagination.pagedItems[question][0].push(response.answers[i])
          }
        }
      }
    }

    $scope.range = function (size, start, end) {
      const ret = []

      if (size < end) {
        end = size
        start = size - $scope.surveyResponsesList.length
      }
      for (let i = start; i < end; i++) {
        ret.push(i)
      }
      return ret
    }

    $scope.prevPage = function (key) {
      if ($scope.pagination.currentPage[key] > 0) {
        $scope.pagination.currentPage[key]--
      }
    }

    $scope.nextPage = function (key) {
      if ($scope.pagination.currentPage[key] < $scope.pagination.pagedItems[key].length - 1) {
        $scope.pagination.currentPage[key]++
      }
    }

    $scope.setPage = function (key) {
      $scope.pagination.currentPage[key] = this.n
    }

    $scope.groupToPages()

    $scope.selectDateFrom = async function (date) {
      $scope.filterDateFrom = date
      await $scope.refresh($scope.responsesList, $scope.survey, date, $scope.filterDateTo, $scope.surveyResponsesList)
    }

    $scope.selectDateTo = async function (date) {
      $scope.filterDateTo = date
      await $scope.refresh($scope.responsesList, $scope.survey, $scope.filterDateFrom, date, $scope.surveyResponsesList)
    }

    $scope.details = async function (user) {
      $scope.tabs.report = true
      await $scope.refresh(await surveys.helpers.editInit($scope.survey._id, user), $scope.survey, $scope.filterDateFrom, $scope.filterDateTo, $scope.surveyResponsesList)
    }

    $scope.refresh = async function (responsesList, survey, dateFrom, dateTo, surveyResponsesList) {
      await surveys.helpers.getSurveyResponsesList(responsesList, survey, dateFrom, dateTo)
      await $scope.groupToPages()
      await surveys.helpers.initCharts(survey, surveyResponsesList)
    }

    $scope.export = {}
    $scope.export.columnOrder = RESPONSES_ORDER
    $scope.export.headers = RESPONSES_HEADERS
    $scope.export.exportDataAll = async function () {
      try {
        const cvsExport = []
        await $scope.responsesList.forEach((response) => {
          response.questions.forEach((question) => {
            let answer = ''
            if (question.questionType == 'Picture Input' && question.answers && question.answers[0] && question.answers[0]._downloadURL) 
              answer = question.answers[0]._downloadURL
            else 
              answer = ($scope.explode((question.answers)) || '');

            cvsExport.push({
              surveyName: ($scope.survey.name || ''),
              teams: ($scope.explode(response.teams) || ''),
              segments: ($scope.explode(response.segments) || ''),
              userID: (response.user._id || ''),
              userEmail: (response.user.email || ''),
              userName: (response.user.name || ''),
              questionText: ((question.questionText + ' ' + question.questionDetail) || ''),
              questionType: (question.questionType || ''),
              answer: answer,
              answerDate: (response.answeredDate || '')
            })
          })
        })
        return cvsExport
      } catch (error) {
        console.log('Error Exporting: ', error)
      }
    }

    // saves new surveys or edits existing surveys -- updateSurvey in helper.ts file shares creating and updating to the database
    $scope.saveSurveyDo = function () {
      const surveyData = _.clone($scope.survey)

      /* ===================* START *=================== */

      let parentQ
      _.map(surveyData.allQuestions, (item) => {
        parentQ = item.question

        delete item.$$hashKey
        _.map(item.answers, (answers) => {
          answers.question = parentQ

          delete answers.$$hashKey
        })
      })

      /* ===================* END *=================== */

      if (surveyData.community && surveyData.community._id != '') {
        // if community exists
        surveyData.community = commonUtil.makeBendRef(
          surveyData.community._id,
          'community'
        )
      } else {
        delete surveyData.community
      }
      if (new Date($scope.survey.date)) {
        // if date exists in object format
        $scope.survey.date = commonUtil.formatDateWithFormat(
          new Date($scope.survey.date).getTime() * 1000000,
          'YYYY-MM-DD'
        )
      }

      if (
        $scope.formData.teams &&
        $scope.formData.teams.length > 0 &&
        $scope.formData.teams.indexOf({_id: null, name: 'All Users'}) === -1 // if all users is not selected
      ) {
        surveyData.teams = commonUtil.getIdList($scope.formData.teams)
      } else {
        delete surveyData.teams
      }

      if ($scope.formData.segments && $scope.formData.segments.length > 0) {
        surveyData.segments = commonUtil.getIdList($scope.formData.segments)
      } else {
        delete surveyData.segments
      }

      if ($scope.formData.categories && $scope.formData.categories.length > 0) {
        surveyData.categories = commonUtil.getIdList($scope.formData.categories)
      } else {
        delete surveyData.categories
      }

      surveys.helpers.updateSurvey(surveyData).then((survey) => {
        // OLD COMMENT: ISSUES CALLING BELOW, SHOULDUPDATE IS NOT A FN
        // if (autoPush.shouldUpdateAutoPush($scope.oldData, survey, 'survey', $scope.linkedPushToggle)) {
        //   const pushText = 'Take the ' + $scope.survey.name + ' survey today for ' + $scope.survey.points + ' points!'
        //   autoPush.autoPush(pushText, survey, 'survey')
        // }
      })
      $location.path('/surveys')
    }

    $scope.deleteSurvey = function (id) {
      surveys.helpers.delete(id)
      $location.path('/surveys')
    }
  }
])
