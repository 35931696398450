import { Action } from '../actions/list'
import {
  UPDATE_CHARTS_LIST,
  UPDATE_CHART_ACTIVITY_DATA,
  UPDATE_CHART_ACTIVITY_VIEWS_DATA,
  UPDATE_CHART_ACTIVITY_TIMES_DATA,
  UPDATE_CHART_DEMOGRAPHIC_DATA,
  UPDATE_CHART_USER_DATA
} from '../constants/types'

export interface ChartData {
  activity: Array<any>,
  users: Array<any>,
  demographics: Array<any>,
  charts: Array<any>,
  activityTimes: Object
}

export type Charts = Array<any>

const initialState: ChartData = {
  activity: [],
  users: [],
  demographics: [],
  charts: [],
  activityTimes: {}
}

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case UPDATE_CHARTS_LIST:
      return {
        ...state,
        charts: action.charts
      }
    case UPDATE_CHART_ACTIVITY_DATA:
      return {
        ...state,
        activity: action.data
      }
    case UPDATE_CHART_ACTIVITY_VIEWS_DATA:
      return {
        ...state,
        activityViews: action.data
      }
    case UPDATE_CHART_ACTIVITY_TIMES_DATA:
      return {
        ...state,
        activityTimes: action.data
      }
    case UPDATE_CHART_USER_DATA:
      return {
        ...state,
        users: action.data
      }
    case UPDATE_CHART_DEMOGRAPHIC_DATA:
      return {
        ...state,
        demographics: action.data
      }
    default:
      return state
  }
}
