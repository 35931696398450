import { Action } from '../actions/list'
import {
  UPDATE_USERS_LIST
} from '../constants/types'

export interface Item {
  sprintRank: number,
  email: string,
  _id: string,
  enabled: boolean,
  sprintPoints: number,
  race: string,
  username: string,
  teams: Array<string>,
  _acl: {
    creator: string
  }
  _bmd: {
    createdAt: number,
    _rev: number,
    updatedAt: number,
    _gen: string
  }
  previousRank: number,
  gender: string,
  name: string,
  allowNotifications: boolean,
  defaultAvatar: string,
  previousSprintRank: number,
  code: string,
  shareActivity: boolean,
  community: {
    includeCommonActions: boolean,
    status: string,
    points: number,
    eventsEnabled: boolean,
    volunteerOpportunitiesEnabled: boolean,
    _id: string,
    location: string,
    actionsTitle: string,
    enabled: boolean,
    sprintPoints: number,
    collections: Array<string>,
    servicesDescription: string,
    placesTitle: string,
    welcomeEmailAction: string,
    welcomeEmailActionURL: string,
    servicesEnabled: boolean,
    hours: number,
    placesDescription: string,
    categories: Array<string>,
    servicesTitle: string,
    shareUrl: string,
    volunteerOpportunitiesDescription: string,
    _acl: {
      creator: string
    }
    sprintHours: number,
    includeCommonPlaces: boolean,
    eventsDescription: string,
    showCategoriesInSearch: boolean,
    _bmd: {
      createdAt: number,
      _rev: number,
      updatedAt: number,
      _gen: string
    },
    eventsTitle: string,
    includeCommonServices: boolean,
    name: string,
    teamsPromptEnabled: boolean,
    volunteerOpportunitiesTitle: string,
    promptForRace: boolean,
    userCount: number,
    welcomeEmailSubject: string,
    code: string,
    placesEnabled: boolean,
    whitelistEnabled: boolean,
    actionsEnabled: boolean,
    teamsEnabled: boolean,
    logo: {
      _type: string,
      _id: string
    },
    actionsDescription: string,
    welcomeEmailEnabled: boolean,
    includeCommonEvents: boolean,
    includeCommonVolunteerOpportunities: boolean,
    shareMessage: string,
    description: string,
    promptForZipCode: boolean,
    profileChartsEnabled: boolean
  }
  rank: number,
  birthdate: string,
  engagements: number,
  $$hashKey: string
}

export type Users = Array<Item>

const initialState: Users = []

export default (state: Users = initialState, action: Action | any): Users => {
  switch (action.type) {
    case UPDATE_USERS_LIST:
      return action.users
    default:
      return state
  }
}
