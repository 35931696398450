import * as communities from '../../../../src/features/communities'

angular.module('theme.core.main_controller',
  [
    'theme.core.services',
    'bend',
    'app.auth'
  ])
  .controller('MainController', ['$scope', '$theme', '$timeout', 'progressLoader', 'BendAuth', '$modal', '$bend', 'pinesNotifications', 'CommonUtil', '$ngRedux',
    async function ($scope, $theme, $timeout, progressLoader, BendAuth, $modal, $bend, pinesNotifications, CommonUtil, $ngRedux) {
      'use strict'
      // $scope.layoutIsSmallScreen = false;
      $scope.layoutFixedHeader = $theme.get('fixedHeader')
      $scope.layoutPageTransitionStyle = $theme.get('pageTransitionStyle')
      $scope.layoutDropdownTransitionStyle = $theme.get('dropdownTransitionStyle')
      $scope.layoutPageTransitionStyleList = ['bounce',
        'flash',
        'pulse',
        'bounceIn',
        'bounceInDown',
        'bounceInLeft',
        'bounceInRight',
        'bounceInUp',
        'fadeIn',
        'fadeInDown',
        'fadeInDownBig',
        'fadeInLeft',
        'fadeInLeftBig',
        'fadeInRight',
        'fadeInRightBig',
        'fadeInUp',
        'fadeInUpBig',
        'flipInX',
        'flipInY',
        'lightSpeedIn',
        'rotateIn',
        'rotateInDownLeft',
        'rotateInDownRight',
        'rotateInUpLeft',
        'rotateInUpRight',
        'rollIn',
        'zoomIn',
        'zoomInDown',
        'zoomInLeft',
        'zoomInRight',
        'zoomInUp'
      ]

      $scope.layoutLoading = true
      $scope.userInfo = null
      $scope.CommonUtil = CommonUtil
      $scope.org = {}
      $scope.community = {}

      $scope.initUser = function () {
        $scope.userInfo = BendAuth.getActiveUser()

        if ($scope.userInfo.communityAdmin) {
          communities.helpers.get($scope.userInfo.community._id).then((response) => {
            $scope.community = response
          })
        }
        //$scope.initIntercom()
      }

      // starts intercom if user is logged in or not
      $scope.initIntercom = function () {
        const appId = 'zbbkl3nw'
        if ($scope.userInfo) {
          const intercom = {
            name: $scope.userInfo.name,
            email: $scope.userInfo.email,
            user_hash: $scope.userInfo.intercomHash
          }
          window.intercomSettings = {
            app_id: appId,
            ...intercom
          }
        } else {
          window.intercomSettings = {
            app_id: appId
          }
        }
        /* eslint-disable */
        // this function also lives in index.html but it seems like it needs to be in both places to work reliably
        (function () { var w = window; var ic = w.Intercom; if (typeof ic === "function") { ic('reattach_activator'); ic('update', intercomSettings); } else { var d = document; var i = function () { i.c(arguments) }; i.q = []; i.c = function (args) { i.q.push(args) }; w.Intercom = i; function l() { var s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://widget.intercom.io/widget/zbbkl3nw'; var x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); } if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } } })()
        /* eslint-enable */
      }

      //$scope.initIntercom()

      if (BendAuth.isLoggedIn()) {
        $scope.initUser()
      }

      $scope.$on('login_initialize', function () {
        $scope.initUser()
      })

      $scope.getLayoutOption = function (key) {
        return $theme.get(key)
      }

      $scope.setNavbarClass = function (classname, $event) {
        $event.preventDefault()
        $event.stopPropagation()
        $theme.set('topNavThemeClass', classname)
      }

      $scope.setSidebarClass = function (classname, $event) {
        $event.preventDefault()
        $event.stopPropagation()
        $theme.set('sidebarThemeClass', classname)
      }

      $scope.$watch('layoutFixedHeader', function (newVal, oldval) {
        if (newVal === undefined || newVal === oldval) {
          return
        }
        $theme.set('fixedHeader', newVal)
      })
      $scope.$watch('layoutLayoutBoxed', function (newVal, oldval) {
        if (newVal === undefined || newVal === oldval) {
          return
        }
        $theme.set('layoutBoxed', newVal)
      })
      $scope.$watch('layoutLayoutHorizontal', function (newVal, oldval) {
        if (newVal === undefined || newVal === oldval) {
          return
        }
        $theme.set('layoutHorizontal', newVal)
      })
      $scope.$watch('layoutPageTransitionStyle', function (newVal) {
        $theme.set('pageTransitionStyle', newVal)
      })
      $scope.$watch('layoutDropdownTransitionStyle', function (newVal) {
        $theme.set('dropdownTransitionStyle', newVal)
      })

      $scope.hideHeaderBar = function () {
        $theme.set('headerBarHidden', true)
      }

      $scope.showHeaderBar = function ($event) {
        $event.stopPropagation()
        $theme.set('headerBarHidden', false)
      }

      $scope.toggleLeftBar = function () {
        if ($scope.layoutIsSmallScreen) {
          return $theme.set('leftbarShown', !$theme.get('leftbarShown'))
        }
        $theme.set('leftbarCollapsed', !$theme.get('leftbarCollapsed'))
      }

      $scope.toggleRightBar = function () {
        $theme.set('rightbarCollapsed', !$theme.get('rightbarCollapsed'))
      }

      $scope.toggleSearchBar = function ($event) {
        $event.stopPropagation()
        $event.preventDefault()
        $theme.set('showSmallSearchBar', !$theme.get('showSmallSearchBar'))
      }

      $scope.$on('themeEvent:maxWidth767', function (event, newVal) {
        $timeout(function () {
          $scope.layoutIsSmallScreen = newVal
          if (!newVal) {
            $theme.set('leftbarShown', false)
          } else {
            $theme.set('leftbarCollapsed', false)
          }
        })
      })
      $scope.$on('themeEvent:changed:fixedHeader', function (event, newVal) {
        $scope.layoutFixedHeader = newVal
      })
      $scope.$on('themeEvent:changed:layoutHorizontal', function (event, newVal) {
        $scope.layoutLayoutHorizontal = newVal
      })
      $scope.$on('themeEvent:changed:layoutBoxed', function (event, newVal) {
        $scope.layoutLayoutBoxed = newVal
      })

      // there are better ways to do this, e.g. using a dedicated service
      // but for the purposes of this demo this will do :P
      $scope.isLoggedIn = true
      $scope.logOut = function () {
        BendAuth.logOut().then(() => {
          applyChangesOnScope($scope, () => { })
        })
      }
      $scope.logIn = function () {
        $scope.isLoggedIn = true
      }

      $scope.getUserName = function () {
        var user = BendAuth.getActiveUser()
        if (user) { return user.username } else { return '' }
      }

      $scope.rightbarAccordionsShowOne = false
      $scope.rightbarAccordions = [{
        open: true
      }, {
        open: true
      }, {
        open: true
      }, {
        open: true
      }, {
        open: true
      }, {
        open: true
      }, {
        open: true
      }]

      $scope.$on('$routeChangeStart', function () {
        BendAuth.checkAuth()

        progressLoader.start()
        progressLoader.set(50)
      })
      $scope.$on('$routeChangeSuccess', function () {
        progressLoader.end()
        if ($scope.layoutLoading) {
          $scope.layoutLoading = false
        }
      })

      $scope.$on('update-organization', function (evt, org) {
        $scope.org.name = org.name
      })

      $scope.openProfile = function () {
        $modal.open({
          templateUrl: 'userProfile.html',
          controller: function ($scope, $modalInstance) {
            $scope.isLoadingSaveProfile = false
            $scope.isLoadingSaveAccout = false
            var activeUser = BendAuth.getActiveUser()

            $scope.user = activeUser
            $scope.form = {
              name: $scope.user.name,
              email: $scope.user.email
            }
            $scope.form.isValidPassword = false

            $scope.cancel = function () {
              $modalInstance.dismiss('cancel')
            }
            $scope.close = function () {
              $modalInstance.dismiss('cancel')
            }
            $scope.saveProfile = function () {
              $scope.isLoadingSaveProfile = true
              activeUser.name = $scope.form.name
              $bend.User.update(activeUser).then(function (user) {
                $scope.isLoadingSaveProfile = false
              }, function (err) {
                $scope.isLoadingSaveProfile = false
              })
            }

            $scope.saveAccount = function () {
              var activeUserClone = _.clone(activeUser)
              $bend.setActiveUser(null)
              var credentials = { username: activeUser.username, password: $scope.form.oldPassword }
              $bend.User.login(credentials).then(
                function (res) {
                  $scope.isLoadingSaveAccout = true
                  activeUser = BendAuth.getActiveUser()
                  activeUser.password = $scope.form.newPassword
                  $bend.User.update(activeUser).then(function (user) {
                    var creditial = { username: activeUser.username, password: $scope.form.newPassword }
                    $bend.Sync.destruct()
                    $bend.setActiveUser(null)
                    $scope.isLoadingSaveAccout = false
                    BendAuth.logIn(creditial)

                    var notify = pinesNotifications.notify({
                      text: '<p>Password changed</p>',
                      type: 'info',
                      width: '200px'
                    })
                    setTimeout(function () {
                      notify.remove()
                    }, 2000)
                    // return $location.path('/signin');
                  }, function (err) {
                    $scope.isLoadingSaveAccout = false
                  })
                },
                function (error) {
                  $scope.form.isValidPassword = true
                  $bend.setActiveUser(activeUserClone)
                  activeUser = BendAuth.getActiveUser()
                }
              )
            }

            $scope.canSubmitValidationForm = function () {
              return $scope.form.validateForm.$valid
            }
            $scope.canSubmitAccountForm = function () {
              return $scope.form.accountForm.$valid
            }
          },
          size: 'lg'
        })
      }
    }])
