import {
  UPDATE_CATEGORIES_SETTINGS,
  UPDATE_CATEGORIES_GROUPS,
  UPDATE_CATEGORIES_GROUPED_LIST,
  UPDATE_CATEGORIES_PAGE_SETTINGS,
  UPDATE_CATEGORIES_COMMUNITY_SETTINGS,
  RESET_CATEGORIES_SETTINGS
} from '../constants/types'

import { Action } from '../actions/settings'

export interface Settings {
  searchTerm: any,
  itemsPerPage: number,
  isLoading: boolean,
  totalItems: number,
  currentPage: number,
  numPages: number,
  community: {
    _id: string
  },
  groups: any,
  groupedCategories: any
}

const initialState: Settings = {
  searchTerm: '',
  itemsPerPage: 20,
  isLoading: true,
  totalItems: 0,
  currentPage: 1,
  numPages: 0,
  community: {
    _id: ''
  },
  groups: [],
  groupedCategories: []
}

export default (state: Settings = initialState, action: Action): Settings => {
  switch (action.type) {
    case UPDATE_CATEGORIES_SETTINGS:
      return {
        ...state,
        totalItems: action.count,
        numPages: Math.ceil(action.count / state.itemsPerPage + 1)
      }
    case UPDATE_CATEGORIES_PAGE_SETTINGS:
      return {
        ...state,
        currentPage: action.page
      }
    case UPDATE_CATEGORIES_GROUPS:
      return {
        ...state,
        groups: action.groups
      }
    case UPDATE_CATEGORIES_GROUPED_LIST:
      return {
        ...state,
        groupedCategories: action.groupedCategories
      }
    case UPDATE_CATEGORIES_COMMUNITY_SETTINGS:
      return {
        ...state,
        community: action.community
      }
    case RESET_CATEGORIES_SETTINGS:
      return initialState
    default:
      return state
  }
}
