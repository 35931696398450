import BendClient, { BendTable } from '../BendClient/index'
import * as actions from './actions'
import redux from '../../helpers/redux'
import { getActiveUser } from '../../helpers'

class Feature {

    private api: any
    private api2: any

    constructor() {
        this.api = BendClient
        this.api2 = BendTable
    }

    public async init() {
        const { list } = redux.getState().eventTemplates

        this.api.fetchEventTemplates().then( eventTemplatesList => {
            if (list.length === 0) this.toggleLoading(true)

            redux.dispatch(actions.list.update(eventTemplatesList))
            this.toggleLoading(false)
            })
    }

    public create(data) {
        this.toggleLoading(true)
        return this.api2.create(BendTable.Tables.EVENT_TEMPLATE, data)
    }

    public update(data) {
        const newData = { ...data }
        delete newData.$$hashKey

        this.toggleLoading(true)
        return this.api2.update(BendTable.Tables.EVENT_TEMPLATE, newData)
    }

    public get(id) {
        return this.api2.get(BendTable.Tables.EVENT_TEMPLATE, id)
    }


    public delete(id) {
        this.toggleLoading(true)
        return this.api2.delete(BendTable.Tables.EVENT_TEMPLATE, id)
    }

    public toggleLoading(bool) {
        redux.dispatch(actions.settings.toggleLoading(bool))
    }

    public upload(file, callback, ext, progressCallback) {
        return this.api.upload(file, callback, ext, progressCallback)
    }

    public getFile(refObj, callback) {
        return this.api.getFile(refObj, callback)
    }

}

export default new Feature()
