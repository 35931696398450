'use strict'

import * as communities from '../../../src/features/communities'
import alertify from 'alertifyjs'
import { getActiveUser } from '../../../src/helpers/index'; 

angular.module('app.controllers')
  .controller('CommunityWhiteLabelController', ['$scope', '$routeParams', 'CommonUtil', '$ngRedux',
    async function ($scope, $routeParams, CommonUtil, $ngRedux) {
      $scope.isLoading = true
      $scope.CommonUtil = CommonUtil
      $scope.clientAppConfig = {}
      $scope.fileProgress = []
      $scope.isUploading = []
      $scope.communityId = $routeParams.id
      $scope.whiteLabel = {}
      $scope.appleCategories = ['Books', 'Medical', 'Business', 'Music', 'Developer Tools', 'Navigation', 'Education', 'News', 'Entertainment', 'Photo & Video', 'Finance', 'Productivity', 'Food & Drink', 'Reference', 'Games', 'Shopping', 'Graphics & Design', 'Social Networking', 'Health & Fitness', 'Sports', 'Lifestyle', 'Travel', 'Kids', 'Utilities', 'Magazines & Newspapers', 'Weather']
      $scope.statusOptions = ['Draft', 'Pending Review', 'Pending Release']
      $scope.pastRequestUpdate = false; 
      $scope.whiteLabel.requestedUpdate = false; 
      $scope.masterAdmin = false; 

      // Currently we only support portrait screenshots.
      $scope.appleScreenshotRequirments = {
        iPadPro: {
          inch: 12.9,
          amount: 5,
          width: 2048,
          height: 2732
        },
        iPhoneSixSPlus: {
          inch: 5.5,
          amount: 5,
          width: 1242,
          height: 2208
        },
        iPhoneXSMax: {
          inch: 6.5,
          amount: 5,
          width: 1242,
          height: 2688
        }
      }

      $scope.form = {} 

      /**
       *  This functions is used for ng-repeat so that we can make a for loon from min to max
       *
       * @param min
       * @param max
       * @param step
       * @returns {[]}
       */
      $scope.range = function (min, max, step) {
        step = step || 1
        const input = []
        for (let i = min; i <= max; i += step) input.push(i)
        return input
      }

      /**
       * This function takes an url to a picture and return a promise containing the width and height of the image
       *
       * @param url
       * @returns {Promise<unknown>}
       */
      function imageProcessor (url) {
        return new Promise((resolve, reject) => {
          const img = new Image()
          img.onload = () => resolve({height: img.height, width: img.width})
          img.onerror = reject
          img.src = url
        })
      }

      /**
       * This function checks if the dimensions is good for android uploading
       *
       * @param url String
       * @param minDimension number
       * @param maxDimension number
       * @returns boolean
       */
      const checkDimensions = async function (url, minDimension, maxDimension) {
        const dimensions = await imageProcessor(url)
        return (dimensions.width < maxDimension && dimensions.height < maxDimension && dimensions.width > minDimension && dimensions.height > minDimension)
      }

      /**
       * This function checks if the resolution is good for apple uploading
       *
       * @param url String
       * @param width number
       * @param height number
       * @returns boolean
       */
      const checkResolution = async function (url, width, height) {
        const dimensions = await imageProcessor(url)
        // This is because it does not matter what the with is and what the height is for Apple
        return ((dimensions.width == width && dimensions.height == height) || (dimensions.height == width && dimensions.width == height))
      }

      /**
       * This function checks if the resolution is equal or bigger
       *
       * @param url String
       * @param width number
       * @param height number
       * @returns boolean
       */
      const checkResolutionBigger = async function (url, width, height) {
        const dimensions = await imageProcessor(url)
        return (dimensions.width <= width && dimensions.height >= height)
      }

      $scope.lockWhitelabel = async () => {

        if (activeUser.email !== "master@milkcrate.tech") {
          document.getElementById("saveWhitelabelBtn1").style.display = "none"; 
          document.getElementById("saveWhitelabelBtn2").style.display = "none"; 
        }

        const requestUpdateBtn = document.getElementById("requestUpdateBtn"); 
        requestUpdateBtn.classList.add("bottom-button-gray"); 
        requestUpdateBtn.classList.add("btn-light"); 
        requestUpdateBtn.classList.remove("btn-success"); 
        requestUpdateBtn.textContent = "Locked"
        requestUpdateBtn.disabled = true; 
        $scope.pastRequestUpdate = true; 

        const requestUpdateBtn2 = document.getElementById("requestUpdateBtn2"); 
        requestUpdateBtn2.classList.add("bottom-button-gray"); 
        requestUpdateBtn2.classList.add("btn-light"); 
        requestUpdateBtn2.classList.remove("btn-success"); 
        requestUpdateBtn2.textContent = "Locked"
        requestUpdateBtn2.disabled = true; 
        $scope.pastRequestUpdate = true; 


      }

      const activeUser = getActiveUser(); 

      if (activeUser && activeUser.email && activeUser.email === "master@milkcrate.tech") {
        $scope.masterAdmin = true; 
      }

      const appConfig = await communities.helpers.getCommunityAppConfig($scope.communityId)
      $scope.clientAppConfig = appConfig || {}
      const communityId = $scope.communityId || appConfig.community._id
      $scope.whiteLabel = await communities.helpers.getCurrentWhitelabel(communityId)
      $scope.isLoading = false

      if ($scope.whiteLabel && $scope.whiteLabel.requestedUpdate && $scope.whiteLabel.requestedUpdate !== undefined) {
        $scope.lockWhitelabel(); 
        $scope.clientAppConfig.statusOption = "Pending Review"
      }

      if (!$scope.clientAppConfig || !$scope.clientAppConfig.statusOption || $scope.clientAppConfig.statusOption === undefined) {
        $scope.clientAppConfig.statusOption = "Draft"
      } 

      $scope.requestUpdate = async () => {
        const confirmRequest = confirm("Are you sure? This will be set live in your next update for your app, once approved.")
        if (!confirmRequest) {
          return false; 
        } else {
          $scope.lockWhitelabel(); 
          $scope.whiteLabel.requestedUpdate = true; 
          $scope.clientAppConfig.statusOption = "Pending Review"
          $scope.saveDo();
        }
      }

      $scope.saveDo = async () => {

        try {
          const data = _.clone($scope.clientAppConfig)
          let canSafe = true
          let errorMessage = 'You should not see this'

          if (data.statusOption && data.statusOption === "Draft") {
            $scope.whiteLabel.requestedUpdate = false; 
          }

          for (let i = 1; i <= 9; i++) {
            if (data['welcomeBackground' + i]) {
              if (!await checkResolutionBigger(data['welcomeBackground' + i]._downloadURL, 800, 800)) {
                canSafe = false
                errorMessage = 'The Welcome Visuals ' + i + ' requires these dimensions: ' + 800 + ' x ' + 800
              }
              data['welcomeBackground' + i] = CommonUtil.makeBendFile(data['welcomeBackground' + i]._id)
            }
          }

          if (data.iosAppIcon) {
            if (!await checkResolution(data.iosAppIcon._downloadURL, 1024, 1024)) {
              canSafe = false
              errorMessage = 'The IOS app icon requires these dimensions: ' + 1024 + ' x ' + 1024
            }
            data.iosAppIcon = CommonUtil.makeBendFile(data.iosAppIcon._id)
          }

          for (const name in $scope.appleScreenshotRequirments) {
            const product = $scope.appleScreenshotRequirments[name]
            for (let i = 1; i <= product.amount; i++) {
              if (data['iosScreenshot' + name + i]) {
                if (!await checkResolution(data['iosScreenshot' + name + i]._downloadURL, product.width, product.height)) {
                  canSafe = false
                  errorMessage = 'The ' + name + ' IOS screenshot requires these dimensions: ' + product.width + ' x ' + product.height
                }
                data['iosScreenshot' + name + i] = CommonUtil.makeBendFile(data['iosScreenshot' + name + i]._id)
              }
            }
          }

          if (data.androidAppIcon) {
            if (!await checkResolution(data.androidAppIcon._downloadURL, 512, 512)) {
              canSafe = false
              errorMessage = 'The android app icon requires these dimensions: ' + 512 + ' x ' + 512
            }
            data.androidAppIcon = CommonUtil.makeBendFile(data.androidAppIcon._id)
          }

          for (let i = 1; i <= 4; i++) {
            if (data['androidScreenshot' + i]) {
              // These are the android dimensions
              if (!await checkDimensions(data['androidScreenshot' + i]._downloadURL, 320, 3840)) {
                canSafe = false
                errorMessage = 'The android screenshot are smaller than 320 px or bigger than 3840 px'
              }
              data['androidScreenshot' + i] = CommonUtil.makeBendFile(data['androidScreenshot' + i]._id)
            }
          }

          if(data.navigationColour){
            const hexRegex = /^#[0-9a-f]{3,6}$/i
            if(!hexRegex.test(data.navigationColour)){
              canSafe = false 
              errorMessage = 'The Navigation Colour requires a Hex Color Code (ex.#FFFFFF)'
            }
          }

          
          if (data.androidCoverImage) {
            if (!await checkResolutionBigger(data.androidCoverImage._downloadURL, 1024, 500)) {
              canSafe = false
              errorMessage = 'The Android Cover Image these dimensions: ' + 1024 + ' x ' + 500
            }
            data.androidCoverImage = CommonUtil.makeBendFile(data.androidCoverImage._id)
          }

          if (canSafe) {
            let whitelabel = $scope.whiteLabel
            data.community = CommonUtil.makeBendRef(communityId, 'community')
            await communities.helpers.saveCommunityAppConfig(data)
            if (!whitelabel) {
              whitelabel = {
                applicationIdSuffix: null
              }
            }

            whitelabel.community = CommonUtil.makeBendRef(communityId, 'community')
            await communities.helpers.updateWhitelabel(whitelabel)

            alertify.set('notifier', 'position', 'top-right')
            alertify.notify('Saved successfully.', 'success', 5)
          } else {
            alertify.set('notifier', 'position', 'top-right')
            alertify.notify('Unable to save because: ' + errorMessage, 'error', 5)
          }
        } catch (e) {
          console.log('ERROR: ', e)
          alertify.set('notifier', 'position', 'top-right')
          alertify.notify('An unexpected error has occurred, please contact Milk Crate', 'error', 5)
        }
      }

      $scope.canSubmitValidationForm = function () {
        return $scope.form.validateForm.$valid
      }

      $scope.openFile = function (fileName) {
        $("input[name='" + fileName + "']").click()
      }

      $scope.deleteFile = function (tag, $ev) {
        $ev.stopPropagation()
        $ev.preventDefault()
        applyChangesOnScope($scope, () => {
          delete $scope.clientAppConfig[tag]
        })

        $scope.form.validateForm.$setDirty()
      }

      $scope.showFileLoading = function (tag, bShow) {
        $scope.isUploading[tag] = bShow
      }

      $scope.selectFileOpen = function (fileId, $ev) {
        if ($ev.target.tagName == 'DIV') {
          setTimeout(() => {
            $('#' + fileId).click()
          }, 0)
        }
      }

      /**
       * This function converts a Blob (or a File) to a dataUrl
       *
       * @param blob
       * @param callback
       */
      function blobToDataURL (blob, callback) {
        const a = new FileReader()
        a.onload = function (e) {
          callback(e.target.result)
        }
        a.readAsDataURL(blob)
      }

      /**
       * This function converts a dataUrl to a file with the png extension.
       *
       * @param dataUrl
       * @param file
       * @returns {File}
       */
      function dataURLtoImageFile (dataUrl, file) {
        // eslint-disable-next-line one-var,prefer-const
        let arr = dataUrl.split(','), mime = arr[0].match(/:(.*?);/)[1], bstr = atob(arr[1]), n = bstr.length,
          u8arr = new Uint8Array(n)

        let newName = file.name.substr(0, file.name.indexOf('.'))
        newName = newName + '.png'

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], newName, {
          lastModified: file.lastModified,
          ebkitRelativePath: file.webkitRelativePath,
          size: file.size,
          type: mime
        })
      }

      /**
       * This function converts a dataUrl to a pgn dataUrl
       *
       * @param dataUrl
       * @param callback
       */
      function getDataUriPng (dataUrl, callback) {
        const image = new Image()

        image.onload = function () {
          const canvas = document.createElement('canvas')
          canvas.width = this.naturalWidth
          canvas.height = this.naturalHeight
          canvas.getContext('2d').drawImage(this, 0, 0)

          callback(canvas.toDataURL('image/png'))
        }

        image.src = dataUrl
      }

      $scope.onFileUploadPng = function (files, tag) {
        const preConvertFile = files[0]

        if (preConvertFile.type == 'image/png') {
          $scope.onFileUpload(files, tag)
        } else {
          blobToDataURL(preConvertFile, (dataurl) => {
            getDataUriPng(dataurl, (dataUri) => {
              const file = dataURLtoImageFile(dataUri, preConvertFile)

              $scope.fileProgress[tag] = 0
              $scope.showFileLoading(tag, true)
              communities.helpers.upload(file, (error, uploadedFile) => {
                if (error) {
                  $scope.showFileLoading(tag, false)
                  return
                }

                communities.helpers.getFile(uploadedFile, (o) => {
                  $scope.clientAppConfig[tag] = o
                  $scope.showFileLoading(tag, false)
                })
                $scope.form.validateForm.$setDirty()
              }, null, (total, prog) => {
                applyChangesOnScope($scope, () => {
                  $scope.fileProgress[tag] = prog * 100 / total
                })
              })
            })
          })
        }
      }

      $scope.onFileUpload = function (files, tag) {
        const file = files[0]

        $scope.fileProgress[tag] = 0
        $scope.showFileLoading(tag, true)
        communities.helpers.upload(file, (error, uploadedFile) => {
          if (error) {
            $scope.showFileLoading(tag, false)
            return
          }

          communities.helpers.getFile(uploadedFile, (o) => {
            $scope.clientAppConfig[tag] = o
            $scope.showFileLoading(tag, false)
            $scope.$apply(); 
          })
          $scope.form.validateForm.$setDirty()
        }, null, (total, prog) => {
          applyChangesOnScope($scope, () => {
            $scope.fileProgress[tag] = prog * 100 / total
          })
        })
      }
    }])
