import { UPDATE_COMMUNITIES_BOARD_LIST } from '../constants/types'
import { List } from '../reducers/list'

export interface Action {
  type: string,
  communities: List
}

export const update = (communities: List): Action => {
  return {
    type: UPDATE_COMMUNITIES_BOARD_LIST,
    communities
  }
}
