import {
  UPDATE_SURVEY_QUESTIONS_LIST,
  UPDATE_SURVEY_QUESTION_OBJECTS
} from '../constants/types'
import { SurveyQuestions } from '../reducers/list'

export interface Action {
  type: string,
  surveyQuestions?: SurveyQuestions
}

export const update = (surveyQuestions: SurveyQuestions): Action => { // these are actually poll questions, not surveyQuestion objects
  console.log("list.ts sQ is: ", surveyQuestions); 
  return {
    type: UPDATE_SURVEY_QUESTIONS_LIST,
    surveyQuestions
  }
}
