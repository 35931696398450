import {
  RESET_SURVEYS_SETTINGS,
  UPDATE_SURVEY_USER_GROUP_SETTING,
  UPDATE_SURVEYS_COMMUNITY_SETTINGS,
  UPDATE_SURVEYS_PAGE_SETTINGS,
  UPDATE_SURVEYS_SEGMENTS,
  UPDATE_SURVEYS_SEGMENTS_SETTINGS,
  UPDATE_SURVEYS_SETTINGS,
  UPDATE_SURVEYS_TEAM_SETTINGS,
  UPDATE_SURVEYS_TEAMS_SETTINGS
} from '../constants/types'
import { Settings } from '../reducers/settings'

export interface Action {
  type: string,
  users: Settings,
  count: number,
  page: number,
  communityId: any,
  communityTeams: any,
  surveyResponses: any,
  team: any,
  segment: any,
  userGroup: any
}

export const update = (count) => {
  return {
    type: UPDATE_SURVEYS_SETTINGS,
    count
  }
}

export const pageUpdate = (page) => {
  return {
    type: UPDATE_SURVEYS_PAGE_SETTINGS,
    page
  }
}

export const selectCommunity = (communityId) => {
  return {
    type: UPDATE_SURVEYS_COMMUNITY_SETTINGS,
    communityId
  }
}

export const selectTeam = (team) => {
  return {
    type: UPDATE_SURVEYS_TEAM_SETTINGS,
    team
  }
}

export const selectSegment = (segment) => {
  return {
    type: UPDATE_SURVEYS_SEGMENTS_SETTINGS,
    segment
  }
}

export const selectUserGroup = (userGroup) => {
  return {
    type: UPDATE_SURVEY_USER_GROUP_SETTING,
    userGroup
  }
}

export const teamsUpdate = (communityTeams) => {
  return {
    type: UPDATE_SURVEYS_TEAMS_SETTINGS,
    communityTeams
  }
}

export const segmentUpdate = (segment) => {
  return {
    type: UPDATE_SURVEYS_SEGMENTS,
    segment
  }
}

export const reset = () => {
  return {
    type: RESET_SURVEYS_SETTINGS
  }
}
