import {
  UPDATE_ORGANIZATIONS_SETTINGS,
  UPDATE_ORGANIZATIONS_PAGE_SETTINGS,
  UPDATE_ORGANIZATIONS_LOADING_SETTINGS,
  UPDATE_ORGANIZATIONS_COMMUNITY_SETTINGS
} from '../constants/types'

export interface Action {
  type: string,
  count: number,
  page: number,
  isLoading: boolean,
  communityId: string
}

export const update = (count) => {
  return {
    type: UPDATE_ORGANIZATIONS_SETTINGS,
    count
  }
}

export const pageUpdate = (page) => {
  return {
    type: UPDATE_ORGANIZATIONS_PAGE_SETTINGS,
    page
  }
}
export const communityUpdate = (communityId) => {
  return {
    type: UPDATE_ORGANIZATIONS_COMMUNITY_SETTINGS,
    communityId
  }
}

export const toggleLoading = (isLoading) => {
  return {
    type: UPDATE_ORGANIZATIONS_LOADING_SETTINGS,
    isLoading
  }
}
