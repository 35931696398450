import { Action } from '../actions/list'
import {
  UPDATE_GOALS_LIST,
  UPDATE_GOALS_ITEM
} from '../constants/types'

export interface Item {
}
export type Goals = Array<any>

export type List = {
  goals: Goals,
}

const initialState = {
  goals: [],
}

export default (state: List = initialState, action: Action | any): List => {
  // console.log('team reducer', action)
  switch (action.type) {
    case UPDATE_GOALS_LIST:
      return action.goals

    case UPDATE_GOALS_ITEM:
      return action.goals
      
    default:
      return state
  }
}
