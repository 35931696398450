import { combineReducers } from 'redux'
import list, { Tags } from './list'

export interface CombineReducers {
  list: Tags
}

const rootReducer = combineReducers<CombineReducers>({
  list
})

export default rootReducer
