'use strict'

import * as events from '../../../src/features/events'
import * as communities from '../../../src/features/communities'
import * as categories from '../../../src/features/categories'
import * as activities from '../../../src/features/activities'
import commonUtil from '../../../src/helpers/commonUtil'
import bendHelper from '../../../src/helpers/bendHelper'
import moment from 'moment-timezone'

let currentDate, monthStart, monthEnd, shortWeekFormat = 'MMMM YYYY'

function setCurrentDate (aMoment) {
  currentDate = aMoment
  monthStart = currentDate.clone().startOf('month')
  monthEnd = currentDate.clone().endOf('month')
}

angular.module('app.controllers')
  .controller('EventController', ['$scope', '$location', 'BendAuth', '$ngRedux',
    async function ($scope, $location, BendAuth, $ngRedux) {
      $scope.CommonUtil = commonUtil

      $scope.user = BendAuth.getActiveUser()

      const allCats = [{
        name: 'All categories',
        _id: null
      }]

      setCurrentDate(moment())
      $scope.currentMonth = function () { return currentDate.format(shortWeekFormat) }

      $scope.nextMonth = function () {
        setCurrentDate(currentDate.add(1, 'month'))
      }
      $scope.prevMonth = function () {
        if (currentDate.isAfter(moment().subtract(3, 'month'))) { // The user can only see one month back
          setCurrentDate(currentDate.subtract(1, 'month'))
        }
      }
      $scope.month = (item) => {
        const eventTime = moment(item.startsAtLong / 1000000)
        return (eventTime >= monthStart && eventTime <= monthEnd)
      }

      // initialize with current date
      setCurrentDate(moment())

      const mapStateToThis = (state) => ({
        eventList: state.events.list,
        categories: allCats.concat(state.categories.list),
        communityList: state.communities.list,
        activities: state.activities.list,
        collectionView: {
          searchTerm: state.events.settings.searchTerm,
          isLoading: state.events.settings.isLoading,
          totalItems: state.events.settings.totalItems
        },
        filter: {
          community: state.events.settings.community,
          category: state.events.settings.category
        }
      })

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      if (!$scope.user.communityAdmin) communities.helpers.init()
      categories.helpers.getList()

      $scope.sortDirection = {
        startsAtLong: 1,
        name: 1
      }

      $(document).on('click', '.pagination li:not(.disabled) a', (e) => {
        $scope.isLoading = true
        const n = $(e.target).text()
        events.helpers.paginateToPage(n)
        $scope.loadList()
      })

      events.helpers.reloadLists()

      $scope.reset = async () => {
        $scope.isLoading = true
        const communityId = $scope.user.communityAdmin ? $scope.user.community._id : null
        await events.helpers.reset(communityId)
        $scope.isLoading = false
      }

      $scope.loadList = async () => {
        if ($scope.eventList.length === 0) {
          $scope.isLoading = true
        }
        if ($scope.user.communityAdmin) {
          events.helpers.selectCommunity($scope.user.community._id)
        }
        await events.helpers.init()

        $scope.isLoading = false
      }

      $scope.sortBy = (key) => {
        $scope.sortKey = key
        const direction = $scope.sortDirection
        direction[key] = (-1) * direction[key]
        events.helpers.sortBy(key, direction)
      }

      setTimeout(() => {
        $scope.loadList().then(() => $scope.sortBy('startsAtLong'))
      }, 200)

      $scope.onSearch = function () {
        $scope.isLoading = true
        $scope.loadList()
      }

      $scope.selectCommunity = (community) => {
        $scope.isLoading = true
        const id = typeof community === 'object' && community._id
        events.helpers.selectCommunity(id)
        $scope.loadList()
      }

      $scope.selectCategory = (category) => {
        $scope.isLoading = true
        const id = typeof category === 'object' && category._id // this is for if category groups are integrated
        events.helpers.selectCategory(category)
        events.helpers.getList()
        $scope.isLoading = false
      }

      $scope.editEvent = function (event, $e) {
        const path = event.version == 2 ? 'events' : 'legacy/events'
        const instancePath = event.version == 2 && event.instanceId ? `/instance/${event.instanceId}` : ''
        if ($e.metaKey || $e.ctrlKey) {
          window.open(`#/${path}/${event._id}${instancePath}`, '_blank')
        } else { return $location.path(`/${path}/${event._id}${instancePath}`) }
      }

      $scope.createEvent = function ($event) {
        if ($event.metaKey || $event.ctrlKey) {
          window.open('#/events/new', '_blank')
        } else { return $location.path('/events/new') }
      }

      $scope.enableToggle = async (item, e) => {
        await bendHelper.enableHelper(e, item, events)
      }
    }])
