import { combineReducers } from 'redux'
import { reducer as activitiesReducer } from '../features/activities'
import { reducer as actionsReducer } from '../features/actions'
import { reducer as categoriesReducer } from '../features/categories'
import { reducer as communitiesReducer } from '../features/communities'
import { reducer as commentsReducer } from '../features/comments'
import { reducer as challengesReducer } from '../features/challenges'
import { reducer as chartsReducer } from '../features/charts'
import { reducer as customActivitiesReducer } from '../features/customActivities'
import { reducer as collectionReducer } from '../features/collections'
import { reducer as eventsReducer } from '../features/events'
import { reducer as eventTeampltesReducer } from '../features/eventTemplates'
import { reducer as leaderboardsReducer } from '../features/leaderboards'
import { reducer as menuReducer } from '../features/menu'
import { reducer as newestUsersReducer } from '../features/newestUsers'
import { reducer as sprintsReducer } from '../features/sprints'
import { reducer as statisticsReducer } from '../features/statistics'
import { reducer as surveysReducer } from '../features/surveys'
import { reducer as surveyQuestionsReducer } from '../features/surveyQuestions'
import { reducer as segmentsReducer } from '../features/segments'
import { reducer as segmentTypesReducer } from '../features/segmentTypes'
import { reducer as studioAppsReducer } from '../features/studioApps'
import { reducer as tasksReducer } from '../features/tasks'
import { reducer as tagsReducer } from '../features/tags'
import { reducer as teamsReducer } from '../features/teams'
import { reducer as groupsReducer } from '../features/groups'
import { reducer as goalsReducer } from '../features/goals'
import { reducer as placesReducer } from '../features/places'
import { reducer as organizationsReducer } from '../features/organizations'
import { reducer as schoolsReducer } from '../features/schools'
import { reducer as pollQuestionsReducer } from '../features/pollQuestions'
import { reducer as pushNotificationsReducer } from '../features/pushNotifications'
import { reducer as pushTemplatesReducer } from '../features/pushTemplates'
import { reducer as usersReducer } from '../features/users'
import { reducer as volunteeringsReducer } from '../features/volunteerings'

const rootReducer = combineReducers({
  actions: actionsReducer,
  activities: activitiesReducer,
  categories: categoriesReducer,
  challenges: challengesReducer,
  collections: collectionReducer,
  communities: communitiesReducer,
  comments: commentsReducer,
  charts: chartsReducer,
  customActivities: customActivitiesReducer,
  events: eventsReducer,
  eventTemplates: eventTeampltesReducer,
  leaderboards: leaderboardsReducer,
  menu: menuReducer,
  newestUsers: newestUsersReducer,
  places: placesReducer,
  pollQuestions: pollQuestionsReducer,
  pushNotifications: pushNotificationsReducer,
  pushTemplates: pushTemplatesReducer,
  segments: segmentsReducer,
  segmentTypes: segmentTypesReducer,
  sprints: sprintsReducer,
  statistics: statisticsReducer,
  studioApps: studioAppsReducer,
  surveys: surveysReducer,
  surveyQuestions: surveyQuestionsReducer,
  tags: tagsReducer,
  organizations: organizationsReducer,
  schools: schoolsReducer,
  tasks: tasksReducer,
  teams: teamsReducer,
  users: usersReducer,
  volunteerings: volunteeringsReducer,
  groups: groupsReducer,
  goals: goalsReducer
})

export default rootReducer
