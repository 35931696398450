'use strict'

import * as eventTemplates from '../../../src/features/eventTemplates'

angular.module('app.controllers')
    .controller('EventTemplatesController', ['$scope', '$location', 'BendAuth', '$ngRedux',
        async function ($scope, $location, BendAuth, $ngRedux) {

            const mapStateToThis = (state) => {
                const { settings, list } = state.eventTemplates;
                return {
                    eventTemplatesList: list,
                    collectionView: {
                        itemsPerPage: settings.itemsPerPage,
                        isLoading: settings.isLoading,
                        totalItems: settings.totalItems,
                        currentPage: settings.currentPage,
                        numPages: settings.numPages
                    }
                }
            }

            const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
            $scope.$on('$destroy', unsubscribe)

            $scope.user = await BendAuth.getActiveUser()

            await eventTemplates.helpers.init()

            $scope.editTemplate = async ($event, item) => {
                if ($event.metaKey || $event.ctrlKey)
                    window.open(`#/eventTemplates/${item._id}`, '_blank')
                else
                    return $location.path(`/eventTemplates/${item._id}`)
            }

            $scope.createTemplate = async ($event) => {
                if ($event.metaKey || $event.ctrlKey)
                    window.open('#/eventTemplates/new', '_blank')
                else
                    return $location.path('/eventTemplates/new')
            }

            $scope.deleteTemplate = async (id, $ev) => {
                $ev.stopPropagation()
                $ev.preventDefault()
                const yeap = confirm('Are you sure you want to delete? This cannot be undone.')
                if (yeap) {
                    await eventTemplates.helpers.delete(id)
                    await eventTemplates.helpers.init()
                }
            }
        }]
    )