export const UPDATE_TEAMS_EXPORTS_LIST = 'UPDATE_TEAMS_EXPORTS_LIST'
export const UPDATE_TEAMS_LIST = 'UPDATE_TEAMS_LIST'
export const UPDATE_TEAMS_LIST_ITEM = 'UPDATE_TEAMS_LIST_ITEM'
export const UPDATE_TEAMS_MEMBERS = 'UPDATE_TEAMS_MEMBERS'
export const UPDATE_TEAMS_SETTINGS = 'UPDATE_TEAMS_SETTINGS'
export const UPDATE_TEAMS_SORTING_SETTINGS = 'UPDATE_TEAMS_SORTING_SETTINGS'
export const UPDATE_TEAMS_PAGE_SETTINGS = 'UPDATE_TEAMS_PAGE_SETTINGS'
export const UPDATE_TEAMS_KEYWORD_SETTINGS = 'UPDATE_TEAMS_KEYWORD_SETTINGS'
export const UPDATE_TEAMS_COMMUNITY_SETTINGS = 'UPDATE_TEAMS_COMMUNITY_SETTINGS'
export const RESET_TEAMS_SETTINGS = 'RESET_TEAMS_SETTINGS'
export const UPDATE_TEAMS_ITEM = 'UPDATE_TEAMS_ITEM'
