import * as actions from './actions'
import * as segmentActions from '../segments/actions'
import BendClient, { BendTable } from '../BendClient'
import redux from '../../../src/helpers/redux'
import * as segments from '../segments'
import * as _ from 'lodash'
import * as Highcharts from 'highcharts';
class Feature {

  private api: any
  private api2: any

  constructor () {
    this.api = BendClient
    this.api2 = BendTable
  }

  public manageItems () {
    return this.api.manageItems('survey')
  }

  public async reset () {
    redux.dispatch(
      actions.settings.reset()
    )
    await this.selectCommunity(null)
  }

  public async editInit (surveyId?, user?) {
    let settings = redux.getState().surveys.settings
    let responsesList: any = []
    if (user && user._id) settings.userId = user._id
    settings.surveyId = surveyId
    responsesList = await this.api2.getList(BendTable.Tables.SURVEY_RESPONSE, settings)
    const related = {
      relations: {
        avatar: 'BendFile'
      }
    }

    if (!user) {
      await responsesList.map(async response => {
        response.user = await this.api2.get(BendTable.Tables.USER, response.user._id, related)
        await response.questions.map(async question => {
          if (question.questionType == 'Picture Input' && question.answers[0]) {
            question.answers[0] = await this.api.getFiles([question.answers[0]._id])
            question.answers[0] = question.answers[0][0]
          }
        })
      })
    } else {
      await responsesList.map(async response => {
        response.user = user
      })
    }

    redux.dispatch(
      actions.list.updateResponses(responsesList)
    )

    return responsesList
  }

  public async getSurveyResponsesList (responsesList, survey, dateTo = '', dateFrom = '') {
    let surveyResponsesList: any = {}

    for (const i in survey.allQuestions) {
      const question = survey.allQuestions[i].question
      surveyResponsesList[i] = {
        text: question.question,
        subText: question.details,
        type: question.type,
        answers: []
      }
      responsesList.forEach(response => {
        if (!dateTo) dateTo = response.answeredDate
        if (!dateFrom) dateFrom = response.answeredDate
        response.questions.forEach(answer => {
          if (question.questionId == answer.questionId && new Date(dateTo) <= new Date(response.answeredDate) && new Date(dateFrom) >= new Date(response.answeredDate)) {
            surveyResponsesList[i].answers.push({
              user: response.user.name,
              answer: answer.answers,
              date: response.answeredDate
            })
          }
        })
      })
    }

    redux.dispatch(
      actions.list.updateSurveyResponses(surveyResponsesList)
    )

    return surveyResponsesList
  }

  public async init () {
    let settings = redux.getState().surveys.settings
    let surveysList: any = []

    // fetches all survey associated with client/community
    surveysList = await this.api2.getList(BendTable.Tables.SURVEY, settings)

    this.api.getSurveyQuestionAndResponseCount(surveysList).then((obj) => {

      surveysList.map(survey => {

        obj.questions.map(question => {
          if (question.survey._id === survey._id) {

            if (!survey.questions) survey.questions = 1
            else survey.questions++

          }
        })

        if (obj.responses) {
          obj.responses.map(response => {

            if (response.survey._id === survey._id) {
              if (!survey.responses) survey.responses = 1
              else survey.responses++
            }
          })
        } else survey.responses = 0
      })

      redux.dispatch(
        actions.list.update(surveysList)
      )
      return surveysList
    })
  }

  public initCharts (survey, surveyResponses) {
    const chartData: Array<any> = [];

    for (const i in survey.allQuestions) {
      const question = survey.allQuestions[i].question
      if (question.type == 'Single Select' || question.type == 'Multiple Select' || question.type == 'Slider') {
        const categories: string[] = []
        survey.allQuestions[i].answers.map(answer => categories.push(answer.title))
        chartData[i] = {
          title: question.question,
          subtitle: question.details,
          categories: categories,
          type: question.type,
          data: []
        }
        const categoryData = {}
        if (question.type == 'Slider') {
          for (let j = question.minValue; j <= question.maxValue; j++) categoryData[j] = 0
        } else {
          categories.map(category => categoryData[category] = 0)
        }

        for (const j in surveyResponses) {
          const surveyResponse = surveyResponses[j]
          if (surveyResponse.text == question.question) {
            surveyResponse.answers.forEach(answers => {
              answers.answer.forEach(answer => {
                for (const k in categoryData) {
                  if (k == answer) categoryData[k] += 1
                }
              })
            })
            if (question.type == 'Slider') {
              for (const k in categoryData) {
                chartData[i].data.push({
                  'name': k,
                  'y': categoryData[k]
                })
              }
            } else {
              chartData[i].data = _.values(categoryData)
            }
          }
        }
      }
    }

    if (chartData) {
      for (const i in chartData) {
        if (chartData[i].type == 'Single Select' || chartData[i].type == 'Multiple Select') {
          Highcharts.chart(`container-${i}`, {
            chart: {
              type: 'column'
            },
            plotOptions: {
              column: {
                colorByPoint: true
              }
            },
            title: {
              text: chartData[i].title
            },
            subtitle: {
              text: chartData[i].subtitle
            },
            xAxis: {
              categories: chartData[i].categories,
              labels: {
                rotation: -45,
                align: 'right'
              }
            },
            yAxis: {
              min: 0,
              title: {
                text: 'Responses'
              }
            },
            legend: {
              enabled: false
            },
            tooltip: {
              formatter: function () {
                return '<b>' + this.x + '</b><br/>' +
                  'Responses: ' + Highcharts.numberFormat(this.y, 1)
              }
            },
            series: [{
              type: 'column',
              name: 'Responses',
              data: chartData[i].data,
              dataLabels: {
                enabled: true,
                x: 4,
                y: 20
              }
            }]
          })
        }
        if (chartData[i].type == 'Slider') {
          Highcharts.chart(`container-${i}`, {
            chart: {
              //plotBackgroundColor: null,
              //plotBorderWidth: null,
              plotShadow: false,
              type: 'pie'
            },
            title: {
              text: chartData[i].title
            },
            subtitle: {
              text: chartData[i].subtitle
            },
            tooltip: {
              pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
              ,valueSuffix: '%'

            },
            // accessibility: {
            //   point: {
            //     valueSuffix: '%'
            //   }
            // },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
              }
            },
            series: [{
              type: 'pie',
              name: 'Responses',
              colorByPoint: true,
              data: chartData[i].data
            }]
          })
        }
      }
    }
    return chartData
  }

  public paginateToPage (page) {
    redux.dispatch(
      actions.settings.pageUpdate(page)
    )
  }

  // find community by id in community collection
  public async selectCommunity (id) {

    redux.dispatch(
      actions.settings.selectTeam(null)
    )
    redux.dispatch(
      actions.settings.selectCommunity(id)
    )

    const teams = await this.api2.getList(BendTable.Tables.TEAM)

    redux.dispatch(
      actions.settings.teamsUpdate(teams)
    )

    redux.dispatch(
      segmentActions.settings.updateCommunity(id)
    )

    await segments.helpers.init()
    await this.init()
  }

  public async selectTeam (id) {
    let selected = redux.getState().surveys.settings.communityTeams.find(item => item._id === id)
    redux.dispatch(
      actions.settings.selectTeam(selected)
    )
    await this.init()
  }

  public async selectTeamEdit (selected) {
    redux.dispatch(
      actions.settings.selectTeam({ name: selected.name, _id: selected._id })
    )
    await this.editInit()
  }

  public async selectSegment (segment, editPage = true) {
    redux.dispatch(
      actions.settings.selectSegment({ name: segment.name, _id: segment._id })
    )
    if (editPage) await this.editInit()
    else await this.init()
  }

  public async selectUserGroup (userGroup, editPage = true) {
    redux.dispatch(
      actions.settings.selectUserGroup({ name: userGroup.name, _id: userGroup._id })
    )
    if (editPage) await this.editInit()
    else await this.init()
  }

  public deletePush (id) {
    return this.api.deletePushNotification(id)
  }

  public updateSurvey (survey) {
    if (!survey._id) {
      return this.api2.create(BendTable.Tables.SURVEY, survey)
    } else {
      return this.api2.update(BendTable.Tables.SURVEY, survey)
    }
  }

  public getCommunityList () {
    return this.api.getCommunityList()
  }

  public get (id) {
    return this.api2.get(BendTable.Tables.SURVEY, id)
  }

  public async delete (id) {
    await this.api2.delete(BendTable.Tables.SURVEY, id)
    this.init()
  }

  public updatePush (data) {
    return this.api.updatePushNotification(data)
  }

}

export default new Feature()
