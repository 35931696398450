import {
  UPDATE_CHARTS_SETTINGS,
  UPDATE_CHARTS_PAGE_SETTINGS,
  UPDATE_CHARTS_KEYWORD_SETTINGS,
  UPDATE_CHARTS_COMMUNITY,
  UPDATE_CHARTS_TEAMS,
  UPDATE_CHARTS_TEAM,
  UPDATE_CHARTS_TYPE,
  RESET_CHARTS_SETTINGS
} from '../constants/types'
import { Settings } from '../reducers/settings'

export interface Action {
  type: string,
  users: Settings,
  count: number,
  page: number,
  keyword: string,
  community: {
    _id: string,
    name: string
  },
  selectedType: any,
  team: any,
  teams: any
}

export const update = (count) => {
  return {
    type: UPDATE_CHARTS_SETTINGS,
    count
  }
}
export const pageUpdate = (page) => {
  return {
    type: UPDATE_CHARTS_PAGE_SETTINGS,
    page
  }
}

export const searchKeywordUpdate = (keyword) => {
  return {
    type: UPDATE_CHARTS_KEYWORD_SETTINGS,
    keyword
  }
}

export const selectCommunity = (community) => {
  return {
    type: UPDATE_CHARTS_COMMUNITY,
    community
  }
}

export const selectType = (selectedType) => {
  return {
    type: UPDATE_CHARTS_TYPE,
    selectedType
  }
}
export const selectTeam = (team) => {
  return {
    type: UPDATE_CHARTS_TEAM,
    team
  }
}
export const reset = () => {
  return {
    type: RESET_CHARTS_SETTINGS
  }
}
