'use strict'

import * as pushNotifications from '../../../src/features/pushNotifications'
import * as communities from '../../../src/features/communities'
import commonUtil from '../../../src/helpers/commonUtil'
import { getActiveUser } from '../../../src/helpers'

angular.module('app.controllers')
  .controller('PushController', ['$scope', '$location', '$rootScope', '$modal', 'BendUtils', '$ngRedux',
    async function ($scope, $location, $rootScope, $modal, BendUtils, $ngRedux) {
      $scope.communityAdmin = getActiveUser().community.admin
      $scope.isLoading = true
      const listScope = $scope

      const mapStateToThis = (state) => {
        return {
          pushNotifications: state.pushNotifications.list,
          communities: [{
            name: 'All Notifications',
            _id: null
          }].concat(state.communities.list),
          collectionView: {
            itemsPerPage: state.pushNotifications.settings.itemsPerPage,
            isLoading: state.pushNotifications.settings.isLoading,
            totalItems: state.pushNotifications.settings.totalItems,
            currentPage: state.pushNotifications.settings.currentPage,
            numPages: state.pushNotifications.settings.numPages
          },
          filter: {
            community: state.pushNotifications.settings.community
          },
          fields: {
            searchTerm: state.pushNotifications.settings.searchTerm,
            community: state.pushNotifications.settings.community,
            exportData: state.pushNotifications.exports
          }
        }
      }

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      

      $scope.sortDirection = {
        'text': 1,
        'datetime': -1
      }
      $scope.sortKey = 'name'

      $scope.sortBy = function (key) {
        $scope.sortKey = key
        $scope.sortDirection[key] = (-1) * $scope.sortDirection[key]
        pushNotifications.helpers.sortBy(key, $scope.sortDirection)
      }

      $scope.user = getActiveUser()
    
      // load pushes for community only if user is community admin
      if ($scope.user.communityAdmin) {
        $scope.isLoading = true
        pushNotifications.helpers.selectCommunity($scope.user.community._id)
        pushNotifications.helpers.init().then(() => $scope.sortBy('datetime'))
        
        setTimeout(()=>{ $scope.isLoading = false}, 300)
        
       
      } else {
        communities.helpers.initEnabled().then(() => {
          if (typeof $scope.filter.community === 'object' && !$scope.filter.community._id) {
            const community = _.get($scope.communities, 0)
            pushNotifications.helpers.selectCommunity(community)
          }
        })
        pushNotifications.helpers.init()
        setTimeout(()=>{ $scope.isLoading = false}, 300)
       
      }

      $scope.selectCommunity = async (community) => {
        $scope.isLoading = true
        //pushNotifications.helpers.toggleLoading(true)
        pushNotifications.helpers.selectCommunity(community)
        await pushNotifications.helpers.init()
     
        $scope.isLoading = false
      }

      $scope.pushes = []
      $scope.commonUtil = commonUtil
      $scope.audienceList = [{
        name: 'All Notifications',
        _id: null
      }]
      $scope.filter = {
        audience: (commonUtil.getStorage('push-filter') ? commonUtil.getStorage('push-filter').audience : '')
      }

      pushNotifications.helpers.fetchAudiences().then(function (ret) {
        if (ret.audiences) {
          $scope.audienceList = ret.audiences
        }
      })

      $scope.getAudiences = function (audience) {
        if (!audience) {
          return false
        }
        if (!audience.audiences) {
          return '-'
        }

        var audiences = []
        _.map(audience.audiences, function (o) {
          var exist = _.find($scope.audienceList, function (_o) {
            return o == (_o.query && (_o.query['community._id'] || _o.query['teams']))
          })
          if (exist) {
            audiences.push(exist.name)
          }
        })

        return audiences.join(',')
      }

      // why do we have two delete functions here? is there benefit in doing this?
      // - because, we use this function in modal view also
      $scope.deleteConfirm = () => {
        const yeap = confirm('Are you sure you want to delete? This cannot be undone.')
        if (!yeap) {
          return false
        }
        return true
      }

      $scope.deleteItem = async (id) => {
        pushNotifications.helpers.toggleLoading(true)
        await pushNotifications.helpers.delete(id)
        await pushNotifications.helpers.init()
      }

      $scope.deletePush = async function (pushId, $ev) {
        $ev.stopPropagation()
        // console.log('pushId: ',pushId)
        await pushNotifications.helpers.delete(pushId)
        let pushIndex = $scope.pushNotifications.findIndex(p => {
          return p._id === pushId
        })
        $scope.pushNotifications.splice(pushIndex, 1)
      }

      $rootScope.editPush = function (push) {
        $location.path(`/pushes/${push._id}`)
        // $scope.openPushModal(push).then(function (ret) {
        //   if (typeof ret.datetime === 'object') {
        //     var dateStr = moment(ret.datetime).format('YYYY-MM-DD HH:mm:ss')
        //     var dateStrWithTimeZone = moment.tz(dateStr, push.timezone).format()
        //     ret.datetime = new Date(dateStrWithTimeZone).getTime() * 1000000
        //   }
        //   _.extend(push, ret)
        // })
      }

      $scope.createPush = function () {
        $location.path('/pushes/new')
        // $scope.openPushModal().then(function (ret) {
        //   $scope.pushes.unshift(ret)
        // })
      }

      $scope.existPending = function () {
        var exist = _.filter($scope.pushes, function (o) {
          return o.status == 'pending'
        })

        if (exist) { return false }

        return true
      }

      $scope.executeSchedule = function () {
       $scope.isLoading = true
        BendUtils.wrapInCallback(pushNotifications.helpers.sendScheduled(), function (error, result) {
          pushNotifications.helpers.getList(function (rets) {
            // $scope.pushes = rets
           $scope.isLoading = false
          })
        })
      }

      $(document).on('click', '.pagination li:not(.disabled) a', (e) => {
        const n = Number($(e.target).text())
        pushNotifications.helpers.paginateToPage(!isNaN(n) ? n : $(e.target).text())
        pushNotifications.helpers.toggleLoading(true)
        pushNotifications.helpers.init()
      })

      $scope.openPushModal = async function (nt) {
        var modalInstance = $modal.open({
          templateUrl: 'views/push/push-edit-modal.html',
          backdrop: 'static',
          controller: function ($scope, $modalInstance, CommonUtil) {
            $scope.textLimit = 140
            $scope.timezones = moment.tz.names()
            $scope.communityAdmin = getActiveUser().community.admin
            $scope.audience = {
              audiences: [],
              defaultQuery: {}
            }
            $scope.user = getActiveUser()
            $scope.deletePush = async (id, $ev) => {
              if (listScope.deleteConfirm()) {
                $modalInstance.dismiss('cancel')
                await listScope.deleteItem(id)
              }
            }

            $scope.push = {}
            $scope.deeplinks = []

            $scope.formData = {
              audiences: [],
              deeplink: null
            }

            if (nt) {
              $scope.push = _.clone(nt)
              $scope.push.timezone = $scope.push.timezone || 'US/Eastern'
              $scope.push.datetime = new Date(nt.datetime / 1000000)
            } else {
              $scope.push = {
                params: [],
                title: '',
                text: '',
                scheduled: false,
                userQuery: '{}',
                status: 'pending',
                datetime: new Date(), // CommonUtil.formatDateWithFormat(new Date() * 1000000, 'YYYY-MM-DD')
                timezone: 'US/Eastern'
              }
            }

            $scope.dateTimeValue = CommonUtil.formatDateWithFormatWithTimezone($scope.push.datetime.getTime() * 1000000, 'YYYY-MM-DD HH:mm', $scope.push.timezone)

            pushNotifications.helpers.fetchAudiences().then(function (ret) {
              $scope.audience = ret
              if (ret.audiences) {
                if ($scope.push.audience) {
                  var audiences = []
                  _.map($scope.push.audience.audiences, function (o) {
                    var exist = _.find(ret.audiences, function (_o) {
                      return o == (_o.query['community._id'] || _o.query['teams'])
                    })
                    if (exist) {
                      audiences.push(exist)
                    }
                  })
                  $scope.formData.audiences = audiences
                }
              }
              $scope.runQuery()
            })

            communities.helpers.get($scope.user.community._id).then(community => {
              pushNotifications.helpers.fetchDeeplinks().then(function (deeplinks) {
                let defaultDeeplinks = commonUtil.defaultDeeplinks.map(link => {
                  let { deeplink, name } = link
                  return { name, deeplink: community.appName.toLowerCase() + deeplink }
                })
                $scope.allDeeplinks = defaultDeeplinks.concat(deeplinks) // store all deeplinks separately so they stay available after filter
                $scope.deeplinks = $scope.allDeeplinks
              })
            })

            $scope.filterDeeplinks = word => {
              if ($scope.allDeeplinks) {
                $scope.deeplinks = $scope.allDeeplinks.filter(link => {
                  if (link.name.toLowerCase().indexOf(word.toLowerCase()) !== -1) return true
                  else return false
                })
                if (!word || !word.length) $scope.deeplinks = $scope.allDeeplinks
              }
            }

            $scope.checkDeepLink = function (params) {
              var exist = _.find(params, function (o) {
                return o.key == 'deeplink'
              })

              if (exist) {
                return exist.value
              } else { return null }
            }

            $scope.setTemplate = function (template) {
              if (template.obj) {
                _.extend($scope.push, template.obj)
                $scope.push.datetime = new Date()

                if ($scope.push.audience) {
                  var audiences = []
                  _.map($scope.push.audience.audiences, function (o) {
                    var exist = _.find($scope.audience.audiences, function (_o) {
                      return o == (_o.query['community._id'] || _o.query['teams'])
                    })
                    if (exist) {
                      audiences.push(exist)
                    }
                  })
                  $scope.formData.audiences = audiences
                }

                if ($scope.runQuery) {
                  $scope.runQuery()
                }
              }
            }

            $scope.removeParam = function (param) {
              $scope.push.params = _.without($scope.push.params, param)
            }

            $scope.addParam = function () {
              $scope.push.params.push({
                key: '',
                value: ''
              })
            }

            $scope.updateParam = function () {
              if ($scope.formData.deeplink) {
                var exist = _.find($scope.push.params, function (o) {
                  return o.key == 'deeplink'
                })

                if (exist) {
                  exist.value = $scope.formData.deeplink.deeplink
                } else {
                  $scope.push.params.push({
                    key: 'deeplink',
                    value: $scope.formData.deeplink.deeplink
                  })
                }
              } else {
                exist = _.find($scope.push.params, function (o) {
                  return o.key == 'deeplink'
                })

                if (exist) {
                  $scope.removeParam(exist)
                }
              }
            }

            $scope.dateOptions = {
              // formatYear: 'yy',
              showWeeks: false,
              format: 'yyyy-MM-dd'
            }

            $scope.onDateTimeSet = function (newDate, oldDate) {
              $scope.dateTimeValue = CommonUtil.formatDateTime2(newDate)
              $scope.push.datetime = newDate
            }

            $scope.scheduledDate = {
              opened: false,
              open: function ($event) {
                $event.preventDefault()
                $event.stopPropagation()

                this.opened = true
              }
            }

            $scope.isInitializing = true

            $scope.fetch = function (callback) {
              async.parallel({
                templates: function (callback) {
                  pushNotifications.helpers.fetchPushTemplateList().then((response) => {
                    callback(null, response)
                  })
                }
              }, callback)
            }

            $scope.initialize = function () {
              $scope.fetch(function (err, data) {
                if (err) {
                  $scope.isInitializing = false
                  return
                }
                $scope.templates = data.templates

                $scope.isInitializing = false
              })
            }

            $scope.initialize()

            $scope.cancel = function () {
              $modalInstance.dismiss('cancel')
            }

            $scope.proceed = function () {
              var push = _.clone($scope.push)
              delete push.$$hashKey

              pushNotifications.helpers.sendPush(push)
              // if (ret.params) {
              //   ret.params.map(function (o) {
              //     delete o.$$hashKey
              //   })
              // }

              // if (ret.datetime && typeof $scope.dateTimeInput === 'object') {
              //   var dateStr = moment(ret.datetime).format('YYYY-MM-DD HH:mm:ss')
              //   var dateStrWithTimeZone = moment.tz(dateStr, ret.timezone).format()
              //   ret.datetime = new Date(dateStrWithTimeZone).getTime() * 1000000
              // } else {
              //   ret.datetime = ret.datetime.getTime() * 1000000
              // }
              // ret.audience = {
              //   defaultQuery: $scope.audience.defaultQuery,
              //   audiences: []
              // }

              // _.map($scope.formData.audiences, function (o) {
              //   ret.audience.audiences.push((o.query['community._id'] || o.query['teams']))
              // })

              // BendUtils.wrapInCallback(pushNotifications.helpers.saveAndSend({ obj: ret }), function (error, result) {
              //   if (error) {
              //     return
              //   }

              //   pushNotifications.helpers.init()

              //   $modalInstance.close(result)
              // })
            }

            $scope.sendToUser = function (username) {
              $scope.isUserSending = true
              var ret = _.clone($scope.push)
              delete ret.$$hashKey
              if (ret.params) {
                ret.params.map(function (o) {
                  delete o.$$hashKey
                })
              }
              var param = {
                obj: ret,
                username: username
              }
              BendUtils.wrapInCallback(pushNotifications.helpers.saveAndSend(param), function (error, result) {
                $scope.isUserSending = false
                if (error) {
                }
              })
            }

            $scope.validateTextLength = function () {
              return $scope.textLimit - $scope.push.text.length > 0
            }

            $scope.validateUserQuery = function () {
              $scope.userQueryError = CommonUtil.jsonValidate($scope.push.userQuery)
              return !$scope.userQueryError
            }

            $scope.usersCount = null
            $scope.runQuery = function () {
              if ($scope.validateUserQuery() && $scope.push && $scope.push.userQuery) {
                let settings = {
                  audiences: _.get($scope, 'formData.audiences'),
                  defaultQuery: _.get($scope, 'audience.defaultQuery'),
                  userQuery: _.get($scope, 'push.userQuery')
                }

                pushNotifications.helpers.usersCount(settings).then((res) => {
                  $scope.usersCount = res
                })
              } else {
                $scope.usersCount = null
              }
            }

            $scope.$watch('formData.audiences.length', function () {
              $scope.runQuery()
            }, true)

            $scope.validate = function () {
              return $scope.push.text && $scope.validateTextLength() && $scope.validateUserQuery()
            }
          }
        })

        return modalInstance.result
      }
    }])
