'use strict'

import * as charts from '../../../src/features/charts'
import * as teams from '../../../src/features/teams'
import commonUtil from '../../../src/helpers/commonUtil'
import _ from 'lodash'

angular.module('app.controllers')
  .controller('ChartController', ['$scope', '$location', 'BendAuth', '$ngRedux',
    async function ($scope, $location, BendAuth, $ngRedux) {
      $scope.isLoading = true
      $scope.commonUtil = commonUtil
      $scope._ = _

      const mapStateToThis = (state) => {
        return {
          chartList: state.charts.list.charts,
          teamList: state.teams.list.teams,
          fields: {
            community: state.charts.settings.community,
            team: state.charts.settings.team
          }
        }
      }

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      setTimeout(() => charts.helpers.init(), 200)
      teams.helpers.init()
      $scope.user = BendAuth.getActiveUser()

      if (!$scope.fields.community._id) {
        $scope.communityList = [{
          name: 'All Client',
          _id: null
        }]
      }

      $scope.resetFilters = async () => {
        $scope.isLoading = true
        charts.helpers.resetFilters()
        await charts.helpers.init()
        $scope.isLoading = false
      }

      await charts.helpers.init()
      $scope.isLoading = false
      // called in table row to display team names
      $scope.getTeamNames = function (teamIds) {
        if (!teamIds ||
            teamIds.length == 0 ||
            teamIds.length === ($scope.teamList.length - 1)
        ) {
          return 'All Users'
        }

        var names = []
        _.map($scope.teamList, function (o) {
          if (teamIds.indexOf(o._id) != -1) { names.push(o.name) }
        })

        return names.join(',')
      }

      $scope.deleteChart = async function (id, e) {
        e.preventDefault()
        e.stopPropagation()
        const yeap = confirm('Deleting the action is permanent and can not be undone.')
        if (!yeap) {
          return false
        }
        await charts.helpers.delete(id)
        charts.helpers.init()
        // applyChangesOnScope($scope, () => {})
      }

      $scope.selectCommunity = async community => {
       
        charts.helpers.selectTeam($scope.teamList[0])
        charts.helpers.selectCommunity(community)
        await charts.helpers.init()
        await teams.helpers.getList()
       
      }

      $scope.selectType = async type => {
        
        charts.helpers.selectType(type)
        await charts.helpers.init()
        
      }
      $scope.selectTeam = async team => {
       
        await charts.helpers.selectTeam(team)
        await charts.helpers.init()
       
      }

      $scope.chartDatesToDateStr = (item) => {
        let startArr = []
        let endArr = []
        let timeStr = ''
        if (item.startDate) startArr = item.startDate.split('-') // charts
        if (item.endDate) endArr = item.endDate.split('-')
        if (item.startDate && item.endDate) timeStr = startArr.slice(1).join('/') + ' to ' + endArr.slice(1).join('/')
        else timeStr = 'All Dates'
        return timeStr
      }

      $scope.onPageChange = async () => {
        $scope.isLoading = true
        await charts.helpers.init()
        $scope.isLoading = false
      }

      $scope.editChart = function (chart, $event) {
        if ($event.metaKey || $event.ctrlKey) {
          window.open('#/charts/' + chart._id, '_blank')
        } else { return $location.path('/charts/' + chart._id) }
      }

      $scope.createChart = function ($event) {
        if ($event.metaKey || $event.ctrlKey) {
          window.open('#/charts/new', '_blank')
        } else { return $location.path('/charts/new') }
      }

      $scope.getCommunityNames = function (list) {
        var ret = []
        _.map(list, function (o) {
          var client = _.find($scope.communityList, function (_o) {
            return _o._id == o
          })
          if (client) { ret.push(client.name) }
        })

        return ret.join(', ')
      }

      $(document).on('click', '.pagination li:not(.disabled) a', async e => {
        $scope.isLoading = true
        const n = Number($(e.target).text())
        charts.helpers.paginateToPage(!isNaN(n) ? n : $scope.collectionView.currentPage + ($(e.target).text() === 'Prev' ? -1 : +1))
        await charts.helpers.init()
        $scope.isLoading = false
      })
    }

  ])
