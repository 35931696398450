import { combineReducers } from 'redux'
import list, { EventTemplates } from './list'
import settings from './settings'

export interface CombineReducers {
  list: EventTemplates
}

const rootReducer = combineReducers<CombineReducers>({
  list,
  settings
})

export default rootReducer
