'use strict'

import * as comments from '../../../src/features/comments'

angular.module('app.controllers')
  .controller('CommentsTabController', ['$scope', '$location', '$ngRedux',
    async function ($scope, $location, $ngRedux) {
      $scope.isLoading = true

      const mapStateToThis = (state) => {
        return {
          commentList: state.comments.list,
          collectionView: {
            itemsPerPage: state.comments.settings.itemsPerPage,
            totalItems: state.comments.settings.totalItems,
            currentPage: state.comments.settings.currentPage,
            numPages: state.comments.settings.numPages
          },
          filters: {
            subject: state.comments.settings.subject,
            subjectType: state.comments.settings.subjectType
          }
        }
      }

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      try {
        if ($location.path().indexOf('/new') === -1) {
          await comments.helpers.setSubject($location.path())
          comments.helpers.init()
        } else {
          comments.helpers.resetList()
        }
      } catch (error) {
        console.log('Error Loading Comments Tag', error)
      }

      $scope.isLoading = false

      $scope.parseComment = (comment) => {
        if (comment) {
          let txtRating = comment.split(': ')[1]
          let txt = txtRating.slice(0, txtRating.length - 9)
          return txt
        }
      }

      $scope.parseRating = (comment) => {
        if (comment) {
          let rating = comment.slice(comment.length - 10)
          return rating.slice(2, 9)
        }
      }

      $scope.sortBy = async (sorter) => {
        $scope.isLoading = true
        await comments.helpers.sortComments(sorter, $scope.commentList)
        $scope.isLoading = false
      }

      $scope.deleteComment = (id, event) => {
        event.stopPropagation()
        event.preventDefault()
        const answer = confirm('Deleting this comment is permanent and can not be undone.')
        if (answer) {
          comments.helpers.delete(id, $scope.commentList)
        }
      }

      // onPageChange()
    }])
