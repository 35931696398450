import * as actions from './actions'
import redux from '../../../src/helpers/redux'
class MenuHelper {

  //Called by the NavigationController in initMenu
  public async init (isCommunityAdmin: boolean = false, list = []) {
    if (isCommunityAdmin && list.length === 0) {
      redux.dispatch(
        actions.list.updateDefault()
      )
    } else if (list.length) {
      redux.dispatch(
        actions.list.updateList(list)
      )
    } else {
      redux.dispatch(
        actions.list.updateMaster()
      )
    }
  }

}

export default new MenuHelper()
