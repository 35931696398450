import {
  UPDATE_CERTIFICATIONS_COUNT
} from '../constants/types'

import { Action } from '../actions/settings'

export interface Settings {
  isLoading: boolean,
  totalItems: number
}

const initialState: Settings = {
  isLoading: true,
  totalItems: 0
}

export default (state: Settings = initialState, action: Action): Settings => {
  switch (action.type) {
    case UPDATE_CERTIFICATIONS_COUNT:
      return {
        ...state,
        totalItems: action.count
      }
    default:
      return state
  }
}
