import BendClient, { BendTable } from '../BendClient'
import * as actions from './actions'
import redux from '../../../src/helpers/redux'

class Feature {

  private api: any
  private api2: any

  constructor () {
    this.api = BendClient
    this.api2 = BendTable
  }

  // manageItems () {
  //   return this.api.manageItems('sprint')
  // }

  public reset () {
    redux.dispatch(
      actions.settings.reset()
    )
    this.selectCommunity(null)
    return this.init(null)
  }

  public async init (communityId) {
    const settings = redux.getState().sprints.settings
    settings.communityId = communityId
    // const { sprints, count } = await this.api.fetchSprints(settings)
    const sprints = await this.api2.getList(BendTable.Tables.SPRINT, settings, { relations: { community: 'community' } })
    redux.dispatch(
      actions.list.update(sprints)
    )
    redux.dispatch(
      actions.settings.update(sprints.length)
    )
  }

  public async initInputList (communityId: string): Promise<any> {
    const sprints = await this.api.fetchInputList()
    const filteredSprintList = sprints
      .filter((v) => (v.community && v.community._id) === communityId)
    redux.dispatch(
      actions.list.update([{ name: 'All Time', _id: null }].concat(filteredSprintList))
    )
  }

  public paginateToPage (page) {
    redux.dispatch(
      actions.settings.pageUpdate(page)
    )
  }

  public handleSearchKeyword (keyword) {
    redux.dispatch(
      actions.settings.searchKeywordUpdate(keyword)
    )
  }

  public selectCommunity (id) {
    let selected = redux.getState().communities.list.find(item => item._id === id)
    redux.dispatch(
      actions.settings.communityUpdate(selected)
    )
  }

  public async updateActiveUsersAndTotalPointsFields () {
    const list: any = Array.from(redux.getState().sprints.list)
    const activeUsersPromises: any = list.map(async (i) => {
      if (i.community && i.community._id) {
        const { count, totalPoints } = await this.api.getActiveUsersAndTotalPointsBySprint(i.community._id, i.startDate, i.endDate)
        return {
          id: i._id,
          activeUsers: count,
          totalPoints
        }
      }
    })
    const activeUsers: any = await Promise.all(activeUsersPromises)

    activeUsers.forEach((i) => {
      if (i && i._id) {
        redux.dispatch(
          actions.list.updateActiveUsers(i.id, i.activeUsers, i.totalPoints)
        )
      }
    })
  }

  public get (id: string) {
    return this.api2.get(BendTable.Tables.SPRINT, id, { relations: { community: 'community' } })
  }

  public create (data: any) {
    return this.api2.create(BendTable.Tables.SPRINT, data)
  }

  public update (data: any) {
    return this.api2.update(BendTable.Tables.SPRINT, data)
  }

  public delete (id: string) {
    return this.api2.delete(BendTable.Tables.SPRINT, id)
  }

}

export default new Feature()
