import * as _ from 'lodash'

import {
  UPDATE_USERS_SETTINGS,
  UPDATE_USERS_PAGE_SETTINGS,
  UPDATE_USERS_LIST_LENGTH_SETTINGS,
  UPDATE_USERS_KEYWORD_SETTINGS,
  UPDATE_USERS_COMMUNITY_SETTINGS,
  UPDATE_USERS_TEAM_SETTINGS,
  UPDATE_USERS_TEAMS_SETTINGS,
  RESET_USERS_SETTINGS
} from '../constants/types'

import { Action } from '../actions/settings'

const defaultTeam = {
  name: 'All Teams',
  _id: null
}

export interface Settings {
  searchTerm: any,
  itemsPerPage: number,
  isLoading: boolean,
  totalItems: number,
  currentPage: number,
  numPages: number,
  communityId: string,
  team: any,
  teams: any,
}

const initialState: Settings = {
  searchTerm: '',
  itemsPerPage: 100,
  isLoading: true,
  totalItems: 0,
  currentPage: 1,
  numPages: 0,
  communityId: '',
  team: {
    _id: null
  },
  teams: []
}

export default (state: Settings = initialState, action: Action): Settings => {
  switch (action.type) {
    case UPDATE_USERS_SETTINGS:
      return {
        ...state,
        totalItems: action.count,
        numPages: Math.ceil(action.count / state.itemsPerPage + 1)
      }
    case UPDATE_USERS_PAGE_SETTINGS:
      return {
        ...state,
        currentPage: action.page
      }
    case UPDATE_USERS_LIST_LENGTH_SETTINGS:
      return {
        ...state,
        itemsPerPage: action.itemsPerPage
      }
    case UPDATE_USERS_KEYWORD_SETTINGS:
      return {
        ...state,
        searchTerm: action.keyword
      }
    case UPDATE_USERS_COMMUNITY_SETTINGS:
      return {
        ...state,
        communityId: action.communityId
      }
    case UPDATE_USERS_TEAM_SETTINGS:
      return {
        ...state,
        team: action.team
      }
    case UPDATE_USERS_TEAMS_SETTINGS:
      const sortedList = _.orderBy(action.teams, (i: any) => i.name.toLowerCase(), ['asc'])
      const teams = [
        defaultTeam,
        ...sortedList
      ]
      return {
        ...state,
        teams
      }
    case RESET_USERS_SETTINGS:
      return {
        teams: state.teams,
        ...initialState
      }
    default:
      return state
  }
}
