import {
  UPDATE_CHALLENGES_LIST
} from '../constants/types'
import { Challenges } from '../reducers/list'

export interface Action {
  type: string,
  challenges: Challenges
}

export const update = (challenges: Challenges): Action => {
  return {
    type: UPDATE_CHALLENGES_LIST,
    challenges
  }
}
