'use strict'

import * as pushNotifications from '../../../src/features/pushNotifications'
// import * as teams from '../../../src/features/teams'
import * as segments from '../../../src/features/segments'
import * as users from '../../../src/features/users'
import * as communities from '../../../src/features/communities'
import commonUtil from '../../../src/helpers/commonUtil'
import { getActiveUser } from '../../../src/helpers'

angular.module('app.controllers')
  .controller('PushEditController', ['$scope', '$bend', '$location', '$routeParams', 'BendAuth', 'CommonUtil', '$bootbox', '$rootScope', 'BendUtils', '$ngRedux',
    async function ($scope, $bend, $location, $routeParams, BendAuth, CommonUtil, $bootbox, $rootScope, BendUtils, $ngRedux) {
      $scope.user = getActiveUser()
      const url = $location.$$path
      const pushId = url.slice(url.lastIndexOf('/') + 1)
      const whitelabel = await communities.helpers.getCurrentWhitelabel($scope.user.community.id)
      await segments.helpers.init();
      const communityUsers = await users.helpers.getList()

      $scope.formData = {
        audiences: [],
        segments: [],
        deeplink: {},
        users: []
      }

      $scope.checkCommunityPush = () => {
        if(whitelabel.onesignalAppId && whitelabel.onesignalRestApiKey){

      return $scope.hasCommunityPushId= true
      }
      return $scope.hasCommunityPushId= false
      }

      $scope.checkCommunityPush()
      if (pushId !== 'new') {
        $scope.pushId = pushId
        $scope.isNew = false // hides "Schedule" button
        pushNotifications.helpers.get(pushId).then(push => {
          console.log('Push', push)
          $scope.push = push
          if (push.deeplink) $scope.formData.deeplink.deeplink = push.deeplink

          if ($scope.push.users && $scope.push.users.length > 0) {
            $scope.formData.users = $scope.users.filter(function (o) {
              return $scope.push.users.indexOf(o._id) != -1
            })
          }
          if ($scope.push.segments) {
            $scope.formData.segments = $scope.segments.filter(team => {
              return $scope.push.segments.indexOf(team._id) !== -1
            })
          }

          if (push.datetime) {
            $scope.dateTimeValue = moment($scope.push.datetime).format('MM/DD/YY HH:mm')
            $scope.push.scheduled = true
            $scope.isAfter = moment(push.datetime).isAfter()
          }

        })
      } else {
        $scope.isNew = true // unhides "Schedule" button
        $scope.push = {
          params: [],
          title: '',
          text: '',
          scheduled: false,
          userQuery: '{}',
          status: 'pending',
          datetime: new Date(), // CommonUtil.formatDateWithFormat(new Date() * 1000000, 'YYYY-MM-DD')
          timezone: 'US/Eastern'
        }

        //console.log('Params', $routeParams)
        if ($routeParams && $routeParams.users && $routeParams.users.length) {
          const selectedUsers = $routeParams.users.split(',')
          $scope.formData.users = communityUsers.filter(function (o) {
            return selectedUsers.indexOf(o._id) != -1
          })
        }

      }

      const mapStateToThis = (state) => {
        return {
          // teams: state.teams.list.teams,
          segments: state.segments.list,
          users: state.users.list,
          communities: [{
            name: 'All Notifications',
            _id: null
          }].concat(state.communities.list),
          collectionView: {
            itemsPerPage: state.pushNotifications.settings.itemsPerPage,
            isLoading: state.pushNotifications.settings.isLoading,
            totalItems: state.pushNotifications.settings.totalItems,
            currentPage: state.pushNotifications.settings.currentPage,
            numPages: state.pushNotifications.settings.numPages
          },
          filter: {
            community: state.pushNotifications.settings.community
          },
          fields: {
            searchTerm: state.pushNotifications.settings.searchTerm,
            community: state.pushNotifications.settings.community,
            exportData: state.pushNotifications.exports
          }
        }
      }

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      // teams.helpers.getList().then(teams => {
      //   if ($scope.push.teams) {
      //     $scope.formData.teams = teams.filter(team => {
      //       return $scope.push.teams.indexOf(team._id) !== -1
      //     })
      //   }
      // })

      $scope.textLimit = 140
      $scope.timezones = moment.tz.names()
      $scope.communityAdmin = getActiveUser().community.admin
      $scope.audience = {
        segments: [],
        community: ''
      }
      $scope.user = getActiveUser()

      $scope.sendTest = false

      $scope.deletePush = async (pushId) => {
        console.log('pushId: ',pushId)
        await pushNotifications.helpers.delete(pushId)
        console.log('Backend deletion done. Fetching new list...')
        await pushNotifications.helpers.getList()
        console.log('Got new list. Redirecting to list...')
        $location.path('/pushes')
      }

      $scope.push = {}
      $scope.deeplinks = []

      pushNotifications.helpers.fetchAudiences().then(function (ret) {
        $scope.audience = ret
        if (ret.audiences) {
          if ($scope.push.audience) {
            var audiences = []
            _.map($scope.push.audience.audiences, function (o) {
              var exist = _.find(ret.audiences, function (_o) {
                return o == (_o.query['community._id'])
                // return o == (_o.query['community._id'] || _o.query['teams'])
              })
              if (exist) {
                audiences.push(exist)
              }
            })
            $scope.formData.audiences = audiences
          }
        }
        $scope.runQuery()
      })

      communities.helpers.get($scope.user.community._id).then(community => {
        pushNotifications.helpers.fetchDeeplinks().then(function (deeplinks) {
          let defaultDeeplinks = commonUtil.defaultDeeplinks.map(link => {
            let { deeplink, name } = link
            return { name, deeplink: community.appName.toLowerCase() + deeplink }
          })
          $scope.allDeeplinks = defaultDeeplinks.concat(deeplinks) // store all deeplinks separately so they stay available after filter
          $scope.deeplinks = $scope.allDeeplinks
        })
      })

      $scope.getSegmentInfo = (segment) => {
        return segments.helpers.getSegmentInfo(segment)
      }

      $scope.filterDeeplinks = word => {
        if ($scope.allDeeplinks) {
          $scope.deeplinks = $scope.allDeeplinks.filter(link => {
            if (link.name.toLowerCase().indexOf(word.toLowerCase()) !== -1) return true
            else return false
          })
          if (!word || !word.length) $scope.deeplinks = $scope.allDeeplinks
        }
      }

      $scope.checkDeepLink = function (params) {
        var exist = _.find(params, function (o) {
          return o.key == 'deeplink'
        })

        if (exist) {
          return exist.value
        } else { return null }
      }

      $scope.setTemplate = function (template) {
        if (template.obj) {
          _.extend($scope.push, template.obj)
          $scope.push.datetime = new Date()

          if ($scope.push.audience) {
            var audiences = []
            _.map($scope.push.audience.audiences, function (o) {
              var exist = _.find($scope.audience.audiences, function (_o) {
                return o == (_o.query['community._id'])
                // return o == (_o.query['community._id'] || _o.query['teams'])
              })
              if (exist) {
                audiences.push(exist)
              }
            })
            $scope.formData.audiences = audiences
          }

          if ($scope.runQuery) {
            $scope.runQuery()
          }
        }
      }

      $scope.removeParam = function (param) {
        $scope.push.params = _.without($scope.push.params, param)
      }

      $scope.addParam = function () {
        $scope.push.params.push({
          key: '',
          value: ''
        })
      }

      $scope.updateParam = function () {
        if ($scope.formData.deeplink) {
          var exist = _.find($scope.push.params, function (o) {
            return o.key == 'deeplink'
          })

          if (exist) {
            exist.value = $scope.formData.deeplink.deeplink
          } else {
            $scope.push.params.push({
              key: 'deeplink',
              value: $scope.formData.deeplink.deeplink
            })
          }
        } else {
          exist = _.find($scope.push.params, function (o) {
            return o.key == 'deeplink'
          })

          if (exist) {
            $scope.removeParam(exist)
          }
        }
      }

      $scope.dateOptions = {
        // formatYear: 'yy',
        showWeeks: false,
        format: 'yyyy-MM-dd'
      }

      $scope.onDateTimeSet = function (newDate) {
        $scope.dateTimeValue = CommonUtil.formatDateTime2(newDate)
        $scope.push.datetime = newDate
      }

      $scope.scheduledDate = {
        opened: false,
        open: function ($event) {
          $event.preventDefault()
          $event.stopPropagation()

          this.opened = true
        }
      }

      $scope.fetch = function (callback) {
        async.parallel({
          templates: function (callback) {
            pushNotifications.helpers.fetchPushTemplateList().then((response) => {
              callback(null, response)
            })
          }
        }, callback)
      }

      $scope.initialize = function () {
        $scope.fetch(function (err, data) {
          if (err) {
            return
          }
          $scope.templates = data.templates
        })
      }

      $scope.initialize()

      $scope.cancel = function () {
        $location.path('/pushes')
      }

      $scope.updatePush = function () {
        $scope.push.deeplink = $scope.formData.deeplink.deeplink
        // $scope.push.teams = $scope.formData.teams.map(t => t._id)
        $scope.push.segments = $scope.formData.segments.map(t => t._id)
        // $scope.updateParam()
      }

      $scope.create = function () {
        var push = _.clone($scope.push)
        delete push.$$hashKey
        delete push.params

        if ($scope.formData.users.length > 0) {
          push.users = commonUtil.getIdList($scope.formData.users)
        } else {
          delete push.users
        }

        pushNotifications.helpers.create(push)
        pushNotifications.helpers.getList()
        $location.path('/pushes')
      }

      $scope.sendTestToUser = function (email) {
        $scope.isUserSending = true
        var ret = _.clone($scope.push)
        delete ret.$$hashKey
        if (ret.params) {
          ret.params.map(function (o) {
            delete o.$$hashKey
          })
        }
        return users.helpers.getUserByEmail(email)
          .then(userArr => {
            if (userArr && userArr.length) {
              return pushNotifications.helpers.create(ret, userArr[0]._id)
            } else {
              $scope.errorMsg = 'error: no users were found'
              return false
            }
          })
          .then(() => {
            $scope.isUserSending = false
          })
          .catch(err => {
            console.log('Error Sending to Test User', err)
            $scope.isUserSending = false
          })
      }

      $scope.validateTextLength = function () {
        return $scope.textLimit - $scope.push.text.length > 0
      }

      $scope.validateUserQuery = function () {
        $scope.userQueryError = CommonUtil.jsonValidate($scope.push.userQuery)
        return !$scope.userQueryError
      }

      $scope.usersCount = null
      $scope.runQuery = function () {
        if ($scope.validateUserQuery() && $scope.push && $scope.push.userQuery) {
          let settings = {
            audiences: _.get($scope, 'formData.audiences'),
            userQuery: _.get($scope, 'push.userQuery')
          }

          pushNotifications.helpers.usersCount(settings).then((res) => {
            $scope.usersCount = res
          })
        } else {
          $scope.usersCount = null
        }
      }

      $scope.$watch('formData.audiences.length', function () {
        $scope.runQuery()
      }, true)

      $scope.validate = function () {
        return $scope.push.text && $scope.validateTextLength() && $scope.validateUserQuery()
      }
    }])
