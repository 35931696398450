import {
  UPDATE_CUSTOM_ACTIVITIES_SETTINGS,
  UPDATE_CUSTOM_ACTIVITIES_PAGE_SETTINGS,
  UPDATE_CUSTOM_ACTIVITIES_SEARCH_TERM_SETTINGS,
  UPDATE_CUSTOM_ACTIVITIES_COMMUNITY_SETTINGS,
  UPDATE_CUSTOM_ACTIVITIES_CATEGORY_SETTINGS,
  UPDATE_CUSTOM_ACTIVITIES_COLLECTION_SETTINGS,
  RESET_CUSTOM_ACTIVITIES_SETTINGS
} from '../constants/types'
import { Settings } from '../reducers/settings'

export interface Action {
  type: string,
  users: Settings,
  count: number,
  page: number,
  keyword: string,
  community: any,
  searchTerm: string,
  communityId: string,
  collectionId: string,
  selectedState: string,
  category: any
}

export const update = (count: number) => {
  return {
    type: UPDATE_CUSTOM_ACTIVITIES_SETTINGS,
    count
  }
}

export const pageUpdate = (page: number) => {
  return {
    type: UPDATE_CUSTOM_ACTIVITIES_PAGE_SETTINGS,
    page
  }
}

export const selectSearchTerm = (searchTerm) => {
  return {
    type: UPDATE_CUSTOM_ACTIVITIES_SEARCH_TERM_SETTINGS,
    searchTerm
  }
}

export const selectCommunity = (communityId) => {
  return {
    type: UPDATE_CUSTOM_ACTIVITIES_COMMUNITY_SETTINGS,
    communityId
  }
}

export const selectCategory = (category) => {
  return {
    type: UPDATE_CUSTOM_ACTIVITIES_CATEGORY_SETTINGS,
    category
  }
}

export const selectCollection = (collectionId) => {
  return {
    type: UPDATE_CUSTOM_ACTIVITIES_COLLECTION_SETTINGS,
    collectionId
  }
}

export const reset = () => {
  return {
    type: RESET_CUSTOM_ACTIVITIES_SETTINGS
  }
}
