import BendClient, { BendTable } from '../BendClient'
import * as actions from './actions'
import * as _ from 'lodash'
import { getActiveUser } from '../../../src/helpers'
import redux from '../../../src/helpers/redux'
class Feature {

  private api: any
  private api2: any

  constructor () {
    this.api = BendClient
    this.api2 = BendTable
  }

  public reset () {
    redux.dispatch(
      actions.settings.reset()
    )
    this.selectCommunity(null)
    return this.getList()
  }

  public async loadList (id = null, params = {}) {
    // const settings = redux.getState().categories.settings
    const categoriesList = await this.api.getCategoryList(id, params)
    redux.dispatch(
      actions.list.update(categoriesList)
    )
    redux.dispatch(
      actions.settings.update(categoriesList.length)
    )
    return categoriesList
  }

  public async getList (id = null) {
    console.time("Categories getList");
    const params = { relations: { community: 'community' } }
    await this.loadList(id, params)
    console.timeEnd("Categories getList");
  }

  public async getGroupedList () {
    const catGroupList = await this.api.getCategoryGroupList()
    const cats = await this.api.getCategoryList()
    const categoryGroups: any = this.doGroups(catGroupList)

    // call actions here
    redux.dispatch(
      actions.settings.updateGroupedCategories(categoryGroups)
    )
    redux.dispatch(
      actions.settings.updateGroups(categoryGroups)
    )
    redux.dispatch(
      actions.list.update(cats)
    )
    // return categories
  }

  public sortCategories (categoryList, categoryGroups) {
    let user = getActiveUser()
    let usingCustomGroups
    let milkCategories: any = []
    let clientCategories: any = []
    let communityId = user.community._id
    // places.helpers.getCategoryGroupList(communityId).then((rets) => {
    if (categoryGroups !== []) {
      usingCustomGroups = true
    }
    _.forEach(categoryList, function (category) {
      if (category.community &&
        category.community._id &&
        category.community._id === communityId) {
        clientCategories.push(category) // separate categories that belong to this client
      } else if (!category.community) {
        milkCategories.push(category) // make array of milkcrate categories
      }
    })
    if (clientCategories.length === 0) { // if no client categories
      clientCategories = milkCategories // client uses milkcrate categories
    } else if (!user.communityAdmin) { // if milkcrate admin
      clientCategories = categoryList // show them all categories
    } else if (!usingCustomGroups) { // if not using custom categories
      clientCategories = clientCategories.concat(milkCategories) // they have access to milkcrate categories
    }
    if (user.community._id === '58dec3004bad30145b037541') { // give milkcrate the main categories
      clientCategories = milkCategories
    }
    return [clientCategories, milkCategories]
  }

  // public getCategoryGroupList () {
  //   const params = {
  //     relations: {
  //       innerIcon: 'BendFile',
  //       outerIcon: 'BendFile',
  //       community: 'community'
  //     }
  //   }
  //   return this.api2.getList(BendTable.Tables.CATEGORY_GROUP, undefined, params)
  // }

  public getCategoryGroupList (id) {
    return this.api.getCategoryGroupList(id)
  }

  public getCategoryListWithImages () {
    const params = {
      relations: {
        image1: 'BendFile',
        image2: 'BendFile',
        image3: 'BendFile',
        image4: 'BendFile'
      }
    }
    return this.api2.getList(BendTable.Tables.CATEGORY_GROUP, undefined, params)
  }

  public async getCommunityGroupedList (id) {
    const user = getActiveUser()
    const communityFilterId = redux.getState().leaderboards.settings.community._id
    const communityAdmin = user.community.admin
    const communityAdminId = user.community && user.community.id
    const communityId = communityAdmin ? communityAdminId : communityFilterId
    const catList = await this.api.fetchGroupedList()
    let cats: any = []
    if (communityId === null) {
      cats = catList
    } else {
      const regularCatIds = await this.api.fetchCategoriesRecordByCommunity(communityId)
      const regularCats = catList.filter((i) => {
        return regularCatIds.find((i2) => i._id === i2)
      })
      const customCatList = await this.api.fetchCustomCategoriesByCommunity(communityId)
      const customGroups = await this.api.fetchCustomCategoryGroupByCommunity(communityId)
      const customCats = customCatList.map((i) => {
        const groupObj = customGroups.find((i2) => _.get(i, 'group._id') === i2._id)
        const groupName = _.get(groupObj, 'name')
        return {
          ...i,
          group: groupName
        }
      })
      const userCats = [
        ...regularCats,
        ...customCats
      ]
      cats = userCats
    }
    // const categories: any = this.doGroups(cats) for doing groups in ui (custom way)
    const categories: any = [{ name: 'All Categories', _id: null }, ...cats]
    redux.dispatch(
      actions.settings.updateGroupedCategories(categories)
    )
    redux.dispatch(
      actions.settings.updateGroups(categories)
    )
  }

  public filterByCategory (list, categoryId) {
    const filteredList = list.filter(item => {
      item.categories.map(cat => {
        if (cat === categoryId) return true
        else return false
      })
    })
    return filteredList
  }

  public doGroups (rets) {
    let sortedRets = _.sortBy(rets, function (o) { // sorts by value, preserves original order of equal values
      return o.group
    })
    let group: any = ''
    let categories: any = [{ name: 'All Categories', _id: null }]
    let categoryGroups: any = {}

    const ungroupedCattegoryName = 'Other'
    ;_.forEach(sortedRets, function (o, idx) {
      if (typeof o.group === 'string' && o.group !== group) {
        group = o.group
        categoryGroups[group] = []

        categories.push({
          name: group,
          type: 'group'
        })
      } else if (typeof o.group === 'object' && group !== ungroupedCattegoryName) {
        group = ungroupedCattegoryName
        categoryGroups[group] = []

        categories.push({
          name: group,
          type: 'group'
        })
      }

      categories.push(o)
      if (categoryGroups[group]) {
        categoryGroups[group].push(o._id)
      }
    })

    return categories
  }

  public getGroupName (group, groupList) {
    if (!group) return ''
    if (typeof group === 'string') {
      return group
    }

    let exist = _.find(groupList, function (o) {
      return o._id === group._id
    })

    if (exist) {
      return exist.name
    }

    return ''
  }

  public paginateToPage (page) {
    const currentPage = redux.getState().categories.settings.currentPage
    if (Number(page)) {
      redux.dispatch(
        actions.settings.pageUpdate(page)
      )
    } else if (page === 'Previous') {
      redux.dispatch(
        actions.settings.pageUpdate(currentPage - 1)
      )
    } else {
      redux.dispatch(
        actions.settings.pageUpdate(currentPage + 1)
      )
    }
  }

  public selectCommunity (id) {
    let selected = redux.getState().communities.list.find(item => item._id === id)
    redux.dispatch(
      actions.settings.communityUpdate(selected)
    )
  }

  public create (data) {
    return this.api2.create(BendTable.Tables.CATEGORY, data)
  }

  public update (data) {
    return this.api2.update(BendTable.Tables.CATEGORY, data)
  }

  public get (id) {
    const params = {
      relations: {
        coverImage: 'BendFile',
        image1: 'BendFile',
        image2: 'BendFile',
        image3: 'BendFile',
        image4: 'BendFile',
        buttonImage: 'BendFile',
        buttonImageSelected: 'BendFile',
        icon: 'BendFile',
        iconSticker: 'BendFile',
        community: 'community'
      }
    }
    return this.api2.get(BendTable.Tables.CATEGORY, id, params)
  }

  public delete (id) {
    return this.api2.delete(BendTable.Tables.CATEGORY, id)
  }

  public getAllCategoryGroups () {
    // return this.api.getAllCategoryGroups()
    this.api2.getList(BendTable.Tables.CATEGORY_GROUP).then(groups => {
      redux.dispatch(
        actions.settings.updateGroups(groups)
      )
    })
  }

  public createCategoryGroup (data) {
    return this.api2.create(BendTable.Tables.CATEGORY_GROUP, data)
  }

  public updateCategoryGroup (data) {
    return this.api2.update(BendTable.Tables.CATEGORY_GROUP, data)
  }

  public deleteCategoryGroup (id) {
    return this.api2.delete(BendTable.Tables.CATEGORY_GROUP, id)
  }

  public upload (file, callback, ext, progressCallback) {
    return this.api.upload(file, callback, ext, progressCallback)
  }
  public getFile (refObj, callback) {
    return this.api.getFile(refObj, callback)
  }
}

export default new Feature()
