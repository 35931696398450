/* jshint ignore:start */
angular.module('theme.core.templates', []).run(['$templateCache', function ($templateCache) {
  'use strict'

  $templateCache.put('templates/contextual-progressbar.html',
    '<div class="contextual-progress">\n' +
    '\t<div class="clearfix">\n' +
    '\t\t<div class="progress-title">{{heading}}</div>\n' +
    '\t\t<div class="progress-percentage">{{percent | number:0}}%</div>\n' +
    '\t</div>\n' +
    '\t<div class="progress">\n' +
    "\t\t<div class=\"progress-bar\" ng-class=\"type && 'progress-bar-' + type\" role=\"progressbar\" aria-valuenow=\"{{value}}\" aria-valuemin=\"0\" aria-valuemax=\"{{max}}\" ng-style=\"{width: percent + '%'}\" aria-valuetext=\"{{percent | number:0}}%\" ng-transclude></div>\n" +
    '\t</div>\n' +
    '</div>\n'
  )

  $templateCache.put('templates/custom-styles.html',
    '<style>\n' +
    '\t.dropdown.open .dropdown-menu.animated {\n' +
    "\t\t-webkit-animation-name: {{getLayoutOption('dropdownTransitionStyle')}};\n" +
    "\t\tanimation-name: {{getLayoutOption('dropdownTransitionStyle')}};\n" +
    '\t\t-webkit-animation-duration: .5s;\n' +
    '\t\tanimation-duration: .5s;\n' +
    '\t}\n' +
    '\t.dropdown-menu.animated {\n' +
    '\t\t-webkit-animation-name: none;\n' +
    '\t\tanimation-name: none;\n' +
    '\t}\n' +
    '\n' +
    '\t.mainview-animation.animated.ng-enter {\n' +
    "\t\t-webkit-animation-name: {{getLayoutOption('pageTransitionStyle')}};\n" +
    "\t\tanimation-name: {{getLayoutOption('pageTransitionStyle')}};\n" +
    '\t}\n' +
    '</style>'
  )

  $templateCache.put('templates/editStory.html',
    '<div class="modal-header">\n' +
    '  <h3 class="modal-title">Edit Story</h3>\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <form class="form-horizontal"  name="form.validateForm">\n' +
    '    <div class="form-group">\n' +
    '      <label class="col-sm-3 control-label">Title</label>\n' +
    '      <div class="col-sm-9">\n' +
    '        <input class="form-control" ng-model="story.title" required>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="form-group">\n' +
    '      <label class="col-sm-3 control-label">Subtitle</label>\n' +
    '      <div class="col-sm-9">\n' +
    '        <input class="form-control" ng-model="story.subtitle">\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="form-group">\n' +
    '      <label class="col-sm-3 control-label">Stub</label>\n' +
    '      <div class="col-sm-9">\n' +
    '        <input class="form-control" ng-model="story.stub" placeholder="{{CommonUtil.getStubName(story.title)}}">\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="form-group">\n' +
    '      <label class="col-sm-3 control-label">Cover Photo</label>\n' +
    "      <div class=\"col-sm-9 upload-file-container pointer\" ng-click=\"selectFileOpen('coverPhotoFile', $event)\">\n" +
    "        <div ng-hide=\"story.coverPhoto\" style=\"height: 120px;\" class=\"drop-container\" drag-and-drop drop-handler=\"onFileUpload(files,'coverPhoto')\">\n" +
    '          <div class="drop-informer" style="position: relative; top: 50%; margin-top: -19px;">\n' +
    '            Drop image here or <a href="" ng-click="searchImages2($event)">search for one</a>\n' +
    '          </div>\n' +
    '        </div>\n' +
    "        <div ng-show=\"story.coverPhoto\" drag-and-drop drop-handler=\"onFileUpload(files,'coverPhoto')\">\n" +
    '          <a href="" ng-click="CommonUtil.openURL($event, story.coverPhoto)">\n' +
    '            <img ng-src="{{CommonUtil.getSmallImage3(story.coverPhoto)}}" class="cover-photo" >\n' +
    '          </a>\n' +
    "          <i class=\"fa fa-2x fa-trash-o pointer\" ng-click=\"deleteFile('coverPhoto', $event)\"></i>\n" +
    "          <input type=\"file\" name=\"coverPhoto\" id=\"coverPhotoFile\" onchange=\"angular.element(this).scope().onFileUpload(this.files,'coverPhoto');\" aria-label=\"coverPhoto\" ng-show=\"false\">\n" +
    '        </div>\n' +
    "        <div data-ng-show=\"isUploading['coverPhoto']\" class=\"file-loading\">\n" +
    '          <div style="margin-top: 70px;">\n' +
    '            <div class="file-progress">\n' +
    "              <div class=\"progress-bar-warning\" style=\"width:{{fileProgress['coverPhoto']}}%;\"></div>\n" +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="form-group">\n' +
    '      <label class="col-sm-3 control-label">Type</label>\n' +
    '      <div class="col-sm-9">\n' +
    '        <!--<select class="form-control" ng-model="story.type" ng-options="item.name for item in storyTypeList track by item._id">\n' +
    '            <option value="">None</option>\n' +
    '        </select>-->\n' +
    '        <ui-select multiple ng-model="storyContentType.selected" close-on-select="false" style="width: 100%;">\n' +
    '          <ui-select-match placeholder="Select types">{{$select.selected[$index].name}}</ui-select-match>\n' +
    '          <ui-select-choices repeat="item in storyTypeList | filter: $select.search">\n' +
    '            <span ng-bind-html="item.name | highlight: $select.search"></span>\n' +
    '          </ui-select-choices>\n' +
    '        </ui-select>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="form-group">\n' +
    '      <label class="col-sm-3 control-label">Category</label>\n' +
    '      <div class="col-sm-9">\n' +
    '        <ui-select multiple ng-model="storyCategory.selected" close-on-select="false" style="width: 100%;">\n' +
    '          <ui-select-match placeholder="Select categories">{{$select.selected[$index].name}}</ui-select-match>\n' +
    '          <ui-select-choices repeat="item in contentCategories | filter: $select.search">\n' +
    '            <span ng-bind-html="item.name | highlight: $select.search"></span>\n' +
    '          </ui-select-choices>\n' +
    '        </ui-select>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="form-group">\n' +
    '      <label class="col-sm-3 control-label">Publication Date</label>\n' +
    '      <div class="col-sm-9">\n' +
    '        <div class="input-group">\n' +
    '          <input type="text" spellcheck="false" class="form-control datepicker" datepicker-popup="yyyy-MM-dd" ng-model="pubDate" is-open="openedPubDate" close-text="Close"/>\n' +
    '          <div class="input-group-btn">\n' +
    '            <button type="button" class="btn btn-default" ng-click="openPubDate($event)"><i class="glyphicon glyphicon-calendar"></i></button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="form-group">\n' +
    '      <label class="col-sm-3 control-label">Sharable</label>\n' +
    '      <div class="col-sm-9">\n' +
    "        <input type=\"checkbox\" ng-model=\"story.sharable\" class=\"js-switch\" ui-switch=\"{color: '#4697ce', secondaryColor: '#c5cbd1'}\" />\n" +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="form-group">\n' +
    '      <label class="col-sm-3 control-label">App Only</label>\n' +
    '      <div class="col-sm-9">\n' +
    "        <input type=\"checkbox\" ng-model=\"story.appOnly\" class=\"js-switch\" ui-switch=\"{color: '#4697ce', secondaryColor: '#c5cbd1'}\" />\n" +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="form-group">\n' +
    '      <label class="col-sm-3 control-label">Enabled</label>\n' +
    '      <div class="col-sm-9">\n' +
    "        <input type=\"checkbox\" ng-model=\"story.enabled\" class=\"js-switch\" ui-switch=\"{color: '#4697ce', secondaryColor: '#c5cbd1'}\" />\n" +
    '      </div>\n' +
    '    </div>\n' +
    '  </form>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-default" ng-click="close()">Cancel</button>\n' +
    '  <button class="btn btn-primary" ng-click="saveStoryDo()"  ng-disabled="!canSubmitValidationForm()||isSaving">\n' +
    '    <span ng-show="isSaving"><i class="fa fa-spinner fa-spin"></i> Saving...</span>\n' +
    '    <span ng-hide="isSaving" class="">Save Story</span>\n' +
    '  </button>\n' +
    '</div>\n'
  )

  $templateCache.put('templates/geocodeAddress.html',
    '<div class="modal-header">\n' +
    '  <h3 class="modal-title">Geocode Address</h3>\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <div class="form-group" style="height:30px">\n' +
    '    <div class="col-sm-9">\n' +
    '      <input id="address" class="form-control">\n' +
    '    </div>\n' +
    '    <div class="col-sm-3">\n' +
    '      <button id="submit" class="btn btn-default">Geocode</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div id="geo_map" style="height:400px;width:100%"></div>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <div class="col-sm-8">\n' +
    '    <input id="geoLat" class="pull-left" style="border:none" readonly>\n' +
    '    <input id="geoLng" class="pull-left" style="border:none" readonly>\n' +
    '  </div>\n' +
    '  <div class="col-sm-4">\n' +
    '    <button class="btn btn-default" ng-click="close()">Cancel</button>\n' +
    '    <button class="btn btn-primary" ng-click="confirmAddress()">Confirm</button>\n' +
    '  </div>\n' +
    '</div>\n'
  )

  $templateCache.put('templates/grid-view.html',
    '<div>\n' +
    '\t<div ng-show="params.isInitializing()" class="loading-overlay" ng-style="styleForOverlay()">\n' +
    '\t\t<span><i class="fa fa-spinner fa-spin"></i> Loading...</span>\n' +
    '\t</div>\t\n' +
    '\t<div class="row">\n' +
    '        <div class="col-md-12">\n' +
    '        \t<div style="height: 200px;" ng-show="!items.length && params.isInitializing()"></div>\n' +
    '\t\t\t<table class="table table-hover sortable-table challenges-table grid-view table-with-action-bar">\n' +
    '\t\t\t\t<thead ng-hide="!items.length || params.hideHeader">\n' +
    '\t\t\t\t<tr>\n' +
    "\t\t\t\t<th ng-repeat=\"column in getColumns()\" ng-click=\"sortBy(column.name)\" style=\"{{column.type=='sortHandle' ? 'width:30px;' : ''}}\" ng-hide=\"column.hidden || column.isHidden(item)\">{{column.header ? column.header : column.name}}<i ng-show=\"filter.sortBy==column.name\" ng-class=\"{'fa fa-caret-down pull-right': filter.ascending, 'fa fa-caret-up pull-right': !filter.ascending}\"></i></th>\t\n" +
    '\t\t\t\t</tr>\n' +
    '\t\t\t\t</thead>\n' +
    '\t\t\t\t<tr ng-show="!params.isInitializing() && items.length<1 && params.emptyText">\n' +
    '\t\t\t\t\t<td colspan="{{columns.length}}" style="text-align: center; height: 54px;"><h4 style="text-align: center;line-height: 100px;"><i>{{params.emptyText}}</i></h4></td>\n' +
    '\t\t\t\t</tr>\n' +
    '\t\t\t\t<tbody ui-sortable="params.sortableOptions" ng-model="items">\t\n' +
    '\t\t\t\t\t<tr class="challenge-row" ng-repeat="item in items track by item._id" ng-show="params.showRow(item)">\n' +
    "\t\t\t\t\t\t<td ng-repeat=\"column in getColumns()\" class=\"{{column.cellClass}}\" ng-click=\"params.getUrl(item) && column.type!='actions' && column.type!='link' && column.type!='editable' ? go($event, params.getUrl(item)) : ( column.type!='actions' ? params.onClick(item) : false);\" ng-hide=\"column.hidden || column.isHidden(item)\" ng-style=\"column.style\">\n" +
    '\n' +
    "\t\t\t\t\t\t\t<div class=\"{{column.divClass}}\" ng-if=\"column.type=='index'\">{{($parent.$parent.$index)+1}}</div>\n" +
    '\t\t\t\t\t\t\t\n' +
    "\t\t\t\t\t\t\t<div class=\"{{column.divClass}}\" ng-if=\"column.type=='fileUploadProgress'\">\n" +
    '\t\t\t\t\t\t\t\t<a class="file-name" ng-href="{{item.blob && item.blob._file ? item.blob._file._downloadUrl : (item.blob ? item.blob._downloadUrl : null)}}" target="_blank">{{column.filename(item)}}</a>\n' +
    '\t\t\t\t\t\t\t\t<div class="upload-progress" ng-show="column.shouldShowProgress(item)">\n' +
    '\t\t\t\t\t\t\t\t\t<progressbar ng-class="column.progressClass(item)" value="column.progressValue(item)" type="danger" animate="true"></progressbar>\n' +
    '\t\t\t\t\t\t\t\t\t<p class="progress-label">{{column.progressLabel(item)}}</p>\n' +
    '\t\t\t\t\t\t\t\t</div>\n' +
    '\t\t\t\t\t\t\t</div>\n' +
    "\t\t\t\t\t\t\t<div class=\"{{column.divClass}}\" ng-if=\"column.type=='thumbnail'\">\n" +
    '\t\t\t\t\t\t\t\t<div class="challenge-thumbnail-container">\n' +
    '\t\t\t\t\t\t\t\t\t<div class="challenge-thumbnail" ng-style="column.thumbnail(item)"></div>\n' +
    '\t\t\t\t\t\t\t\t</div>\n' +
    '\t\t\t\t\t\t\t\t<div class="hoverable-content challenge-title-text">{{column.value(item)}}</div>\n' +
    '\t\t\t\t\t\t\t</div>\n' +
    "\t\t\t\t\t\t\t<div class=\"{{column.divClass}}\" ng-if=\"column.type=='labelsList'\">\n" +
    '\t\t\t\t\t\t\t\t<div class="hoverable-content" style="padding-left: 3px;" ng-hide="column.value(item) && column.value(item).length > 0">N/A</div>\n' +
    '\t\t\t\t\t\t\t\t<ul ng-show="column.value(item) && column.value(item).length > 0">\n' +
    '\t\t\t\t\t\t\t\t\t<li ng-repeat="val in column.value(item)">{{val}}</li>\n' +
    '\t\t\t\t\t\t\t\t</ul>\n' +
    '\t\t\t\t\t\t\t</div>\n' +
    "\t\t\t\t\t\t\t<div class=\"hoverable-content cell-no-wrap handle\" ng-if=\"column.type=='sortHandle'\">\n" +
    '\t\t\t\t\t\t\t\t<i class="fa fa-bars"></i> {{ column.value(item)}}\n' +
    '\t\t\t\t\t\t\t</div>      \n' +
    '\t\t\t\t\t\t\t\n' +
    "\t\t\t\t\t\t\t<div class=\"hoverable-content cell-no-wrap handle\" ng-if=\"column.type=='dragBar'\">\n" +
    '\t\t\t\t\t\t\t\t<div class="list-cell index-bar">{{($parent.$parent.$index)+1}}</div>\n' +
    '                                <div class="list-cell drag-bar"></div>\n' +
    '\t\t\t\t\t\t\t</div>      \n' +
    '\n' +
    '\t\t\t\t\t\t\t\n' +
    "\t\t\t\t\t\t\t<div class=\"{{column.divClass}}\" ng-if=\"column.type=='actions'\">\n" +
    "\t\t\t\t\t\t\t\t<button ng-repeat=\"button in column.buttons\" ng-click=\"button.onClick(item)\" class=\"btn btn-{{button.size ? button.size : 'sm'}} btn-{{button.type}}\" ng-disabled=\"button.isDisabled(item)\"><i class=\"fa fa-{{button.icon}}\"></i> {{button.title}}</button>\t\t\t\t\t\t\t\t\t\t\t\t\t\t\n" +
    '\t\t\t\t\t\t\t</div>\n' +
    "\t\t\t\t\t\t\t<div class=\"{{column.divClass}}\" ng-if=\"column.type=='link'\" ng-click=\"go($event, column.url(item), column.clearSearch)\" ng-show=\"column.value(item) !==undefined\">\n" +
    '\t\t\t\t\t\t\t\t{{ column.value(item)}}\n' +
    '\t\t\t\t\t\t\t</div>\n' +
    "\t\t\t\t\t\t\t<div class=\"{{column.divClass}}\" ng-if=\"column.type=='raw'\" ng-bind-html=\"column.value(item)\"></div>\n" +
    "\t\t\t\t\t\t\t<div class=\"{{column.divClass}}\" ng-if=\"column.type=='json'\" ng-show=\"column.value(item) !==undefined\">\n" +
    '\t\t\t\t\t\t\t\t<pre class="code" style="margin-bottom: 0px;">{{ column.value(item) | json }}</pre>\n' +
    '\t\t\t\t\t\t\t</div>\n' +
    "\t\t\t\t\t\t\t<div class=\"{{column.divClass}}\" ng-if=\"column.type=='editable'\">\n" +
    '\t\t\t\t\t\t\t\t\n' +
    '\t\t\t\t\t\t\t\t<!-- EDITABLE FIELDS -->\n' +
    "\t\t\t\t\t\t\t\t<a ng-if=\"column.editable.getType(item)=='text'\" ng-show=\"column.editable.getType(item)=='text'\" editable-text=\"column.value(item)\" e-name=\"{{column.type}}\" onbeforesave=\"column.editable.save($data, item)\">{{ column.value(item) || column.editable.emptyText }}</a>\t\n" +
    "\t\t\t\t\t\t\t\t<a ng-if=\"column.editable.getType(item)=='select'\" ng-show=\"column.editable.getType(item)=='select'\" editable-select=\"column.value(item)\" e-name=\"{{column.type}}\" onbeforesave=\"column.editable.save($data, item)\" e-ng-options=\"s.value as s.text for s in column.editable.data\">{{ column.editable.showValue(item)}}</a>\t\t\t\t\t\t\t\t\t\t\t\t\t\t\n" +
    "\t\t\t\t\t\t\t\t<a ng-if=\"column.editable.getType(item)=='textarea'\" ng-show=\"column.editable.getType(item)=='textarea'\" editable-textarea=\"column.value(item)\" e-name=\"{{column.type}}\" onbeforesave=\"column.editable.save($data, item)\" e-rows=\"7\" e-cols=\"40\">{{ column.value(item) || column.editable.emptyText }}</a>\t\t\t\t\t\t\t\t\t\n" +
    '\t\t\t\t\t\t\t\t\n' +
    "\t\t\t\t\t\t\t\t<div ng-if=\"column.editable.getType(item)=='file'\" ng-show=\"column.editable.getType(item)=='file'\">\n" +
    '\t\t\t\t\t\t\t\t\t<a target="_blank" ng-href="{{item.blob._file._downloadURL}}" ng-show="item.blob._file">{{item.blob._file._filename}}</a>\n' +
    "\t\t\t\t\t\t\t\t\t<button ng-click=\"column.editable.onUploadClick(item)\" class=\"btn btn-sm btn-primary\"><i class=\"fa fa-upload\"></i><!-- {{item.blob._file ? 'Change' : 'Upload'}}--></button>\t\n" +
    '\t\t\t\t\t\t\t\t</div>\n' +
    '\t\t\t\t\t\t\t\t\n' +
    "\t\t\t\t\t\t\t\t<div ng-if=\"column.editable.getType(item)=='json'\" ng-show=\"column.editable.getType(item)=='json'\">\n" +
    '\t\t\t\t\t\t\t\t\t<button ng-click="column.editable.onJsonClick(item)" class="btn btn-sm btn-primary"><i class="fa fa-code"></i></button>\t\n' +
    '\t\t\t\t\t\t\t\t</div>\n' +
    '\n' +
    '\t\t\t\t\t\t\t\t\n' +
    '\t\t\t\t\t\t\t</div>\n' +
    "\t\t\t\t\t\t\t<div class=\"{{column.divClass}}\" ng-if=\"column.type=='label'\" ng-show=\"column.value(item) !==undefined\">\n" +
    '\t\t\t\t\t\t\t\t<span class="{{column.getItemClass(item)}}">\n' +
    '                                    <strong>{{column.value(item)}}</strong>\n' +
    '                                </span>              \n' +
    '\t\t\t\t\t\t\t</div>\n' +
    '\t\t\t\t\t\t\t\n' +
    "\t\t\t\t\t\t\t<div class=\"{{column.divClass}}\" ng-if=\"column.type=='toggleSwitch'\" ng-show=\"column.value(item)!==undefined\">\n" +
    '\t\t\t\t\t\t\t\t<toggle-switch-with-events model="item[column.name]" class="success" on-label="YES" off-label="NO" on-toggle="column.onToggle(item)"></toggle-switch-with-events>\n' +
    '\t\t\t\t\t\t\t</div>\n' +
    '\t\t\t\t\t\t\t\t\n' +
    "\t\t\t\t\t\t\t<div class=\"{{column.divClass}}\" ng-if=\"!column.type || column.type=='default'\">{{ column.value(item)}}</div>\t\t\t\t\n" +
    '\t\t\t\t\t\t</td>\t\t\n' +
    '\t\t\t\t\t</tr>\n' +
    '\t\t\t\t</tbody>\n' +
    '\t\t\t</table>\n' +
    '\t\t</div>\n' +
    '\t</div>\t\n' +
    '\t\n' +
    '\t <!-- PAGINATION -->\n' +
    '    <div class="row" ng-hide="!items.length">\n' +
    '        <div class="col-md-6 text-left">\n' +
    '            Documents per page:\n' +
    '            <div class="btn-group" role="group" aria-label="...">\n' +
    '                <button type="button" class="btn btn-sm btn-default" ng-model="filter.itemsPerPage" ng-repeat="count in [10, 20, 50, 100]"\n' +
    '                        btn-radio="{{count}}" ng-change="onPageChange(false)">{{count}}\n' +
    '                </button>               \n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="col-md-6 text-right" ng-show="items.length">\n' +
    '            <pagination class="mb5 pagination-sm" items-per-page="filter.itemsPerPage" total-items="params.getTotalItemCount()" ng-model="currentPage" max-size="10"></pagination>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>'
  )

  $templateCache.put('templates/list-view.html',
    '<div>\n' +
    '\t<div ng-show="params.isInitializing()" ng-style="styleForOverlay()" style="width: 100%; height: 100%; position: absolute; top:0; left:0; background-color:rgba(255,255,255,0.4);text-align: center;">\n' +
    '\t\t<span><i class="fa fa-spinner fa-spin"></i> Loading...</span>\n' +
    '\t</div>\n' +
    '\t<div class="row">\n' +
    '        <div class="col-md-12">\n' +
    '        \t<div style="height: 200px;" ng-show="!items.length && params.isInitializing()"></div>\n' +
    '\t\t\t<table class="table table-hover">\n' +
    '\t\t\t\t<thead ng-hide="!items.length || params.hideHeader">\n' +
    '\t\t\t\t<tr>\n' +
    "\t\t\t\t<th ng-repeat=\"column in getColumns()\" ng-hide=\"column.hidden || column.isHidden(item)\">{{column.header ? column.header : column.name}}<i ng-show=\"filter.sortBy==column.name\" ng-class=\"{'fa fa-caret-down pull-right': filter.ascending, 'fa fa-caret-up pull-right': !filter.ascending}\"></i></th>\n" +
    '\t\t\t\t</tr>\n' +
    '\t\t\t\t</thead>\n' +
    '\t\t\t\t<tr ng-show="!params.isInitializing() && items.length<1 && params.emptyText">\n' +
    '\t\t\t\t\t<td colspan="{{columns.length}}" style="text-align: center; height: 54px;"><h4 style="text-align: center;line-height: 100px;"><i>{{params.emptyText}}</i></h4></td>\n' +
    '\t\t\t\t</tr>\n' +
    '\t\t\t\t<tbody ng-model="items">\n' +
    '\t\t\t\t\t<tr class="challenge-row" ng-repeat="item in items track by item._id">\n' +
    "\t\t\t\t\t\t<td ng-repeat=\"column in getColumns()\" class=\"{{column.cellClass}}\" ng-click=\"params.getUrl(item) && column.type!='actions' && column.type!='link' && column.type!='editable' ? go($event, params.getUrl(item)) : ( column.type!='actions' ? params.onClick(item) : false);\" ng-hide=\"column.hidden || column.isHidden(item)\" ng-style=\"column.style\">\n" +
    '\n' +
    "\t\t\t\t\t\t\t<div class=\"{{column.divClass}}\" ng-if=\"column.type=='index'\">{{($parent.$parent.$index)+1}}</div>\n" +
    '\n' +
    "\t\t\t\t\t\t\t<div class=\"{{column.divClass}}\" ng-if=\"column.type=='fileUploadProgress'\">\n" +
    '\t\t\t\t\t\t\t\t<a class="file-name" ng-href="{{item.blob && item.blob._file ? item.blob._file._downloadUrl : (item.blob ? item.blob._downloadUrl : null)}}" target="_blank">{{column.filename(item)}}</a>\n' +
    '\t\t\t\t\t\t\t\t<div class="upload-progress" ng-show="column.shouldShowProgress(item)">\n' +
    '\t\t\t\t\t\t\t\t\t<progressbar ng-class="column.progressClass(item)" value="column.progressValue(item)" type="danger" animate="true"></progressbar>\n' +
    '\t\t\t\t\t\t\t\t\t<p class="progress-label">{{column.progressLabel(item)}}</p>\n' +
    '\t\t\t\t\t\t\t\t</div>\n' +
    '\t\t\t\t\t\t\t</div>\n' +
    "\t\t\t\t\t\t\t<div class=\"{{column.divClass}}\" ng-if=\"column.type=='thumbnail'\">\n" +
    '\t\t\t\t\t\t\t\t<div class="challenge-thumbnail-container">\n' +
    '\t\t\t\t\t\t\t\t\t<div class="challenge-thumbnail" ng-style="column.thumbnail(item)"></div>\n' +
    '\t\t\t\t\t\t\t\t</div>\n' +
    '\t\t\t\t\t\t\t\t<div class="hoverable-content challenge-title-text">{{column.value(item)}}</div>\n' +
    '\t\t\t\t\t\t\t</div>\n' +
    "\t\t\t\t\t\t\t<div class=\"{{column.divClass}}\" ng-if=\"column.type=='labelsList'\">\n" +
    '\t\t\t\t\t\t\t\t<div class="hoverable-content" style="padding-left: 3px;" ng-hide="column.value(item) && column.value(item).length > 0">N/A</div>\n' +
    '\t\t\t\t\t\t\t\t<ul ng-show="column.value(item) && column.value(item).length > 0">\n' +
    '\t\t\t\t\t\t\t\t\t<li ng-repeat="val in column.value(item)">{{val}}</li>\n' +
    '\t\t\t\t\t\t\t\t</ul>\n' +
    '\t\t\t\t\t\t\t</div>\n' +
    "\t\t\t\t\t\t\t<div class=\"hoverable-content cell-no-wrap handle\" ng-if=\"column.type=='sortHandle'\">\n" +
    '\t\t\t\t\t\t\t\t<i class="fa fa-bars"></i> {{ column.value(item)}}\n' +
    '\t\t\t\t\t\t\t</div>\n' +
    '\n' +
    "\t\t\t\t\t\t\t<div class=\"hoverable-content cell-no-wrap handle\" ng-if=\"column.type=='dragBar'\">\n" +
    '\t\t\t\t\t\t\t\t<div class="list-cell index-bar">{{($parent.$parent.$index)+1}}</div>\n' +
    '                                <div class="list-cell drag-bar"></div>\n' +
    '\t\t\t\t\t\t\t</div>\n' +
    '\n' +
    '\n' +
    "\t\t\t\t\t\t\t<div class=\"{{column.divClass}}\" ng-if=\"column.type=='actions'\">\n" +
    "\t\t\t\t\t\t\t\t<button ng-repeat=\"button in column.buttons\" ng-click=\"button.onClick(item)\" class=\"btn btn-{{button.size ? button.size : 'sm'}} btn-{{button.type}}\" ng-disabled=\"button.isDisabled(item)\"><i class=\"fa fa-{{button.icon}}\"></i> {{button.title}}</button>\n" +
    '\t\t\t\t\t\t\t</div>\n' +
    "\t\t\t\t\t\t\t<div class=\"{{column.divClass}}\" ng-if=\"column.type=='link'\" ng-click=\"go($event, column.url(item), column.clearSearch)\" ng-show=\"column.value(item) !==undefined\">\n" +
    '\t\t\t\t\t\t\t\t{{ column.value(item)}}\n' +
    '\t\t\t\t\t\t\t</div>\n' +
    "\t\t\t\t\t\t\t<div class=\"{{column.divClass}}\" ng-if=\"column.type=='raw'\" ng-bind-html=\"column.value(item)\"></div>\n" +
    "\t\t\t\t\t\t\t<div class=\"{{column.divClass}}\" ng-if=\"column.type=='json'\" ng-show=\"column.value(item) !==undefined\">\n" +
    '\t\t\t\t\t\t\t\t<pre class="code" style="margin-bottom: 0px;">{{ column.value(item) | json }}</pre>\n' +
    '\t\t\t\t\t\t\t</div>\n' +
    "\t\t\t\t\t\t\t<div class=\"{{column.divClass}}\" ng-if=\"column.type=='editable'\">\n" +
    '\n' +
    '\t\t\t\t\t\t\t\t<!-- EDITABLE FIELDS -->\n' +
    "\t\t\t\t\t\t\t\t<a ng-if=\"column.editable.getType(item)=='text'\" ng-show=\"column.editable.getType(item)=='text'\" editable-text=\"column.value(item)\" e-name=\"{{column.type}}\" onbeforesave=\"column.editable.save($data, item)\">{{ column.value(item) || column.editable.emptyText }}</a>\n" +
    "\t\t\t\t\t\t\t\t<a ng-if=\"column.editable.getType(item)=='select'\" ng-show=\"column.editable.getType(item)=='select'\" editable-select=\"column.value(item)\" e-name=\"{{column.type}}\" onbeforesave=\"column.editable.save($data, item)\" e-ng-options=\"s.value as s.text for s in column.editable.data\">{{ column.editable.showValue(item)}}</a>\n" +
    "\t\t\t\t\t\t\t\t<a ng-if=\"column.editable.getType(item)=='textarea'\" ng-show=\"column.editable.getType(item)=='textarea'\" editable-textarea=\"column.value(item)\" e-name=\"{{column.type}}\" onbeforesave=\"column.editable.save($data, item)\" e-rows=\"7\" e-cols=\"40\">{{ column.value(item) || column.editable.emptyText }}</a>\n" +
    '\n' +
    "\t\t\t\t\t\t\t\t<div ng-if=\"column.editable.getType(item)=='file'\" ng-show=\"column.editable.getType(item)=='file'\">\n" +
    '\t\t\t\t\t\t\t\t\t<a target="_blank" ng-href="{{item.blob._file._downloadURL}}" ng-show="item.blob._file">{{item.blob._file._filename}}</a>\n' +
    "\t\t\t\t\t\t\t\t\t<button ng-click=\"column.editable.onUploadClick(item)\" class=\"btn btn-sm btn-primary\"><i class=\"fa fa-upload\"></i><!-- {{item.blob._file ? 'Change' : 'Upload'}}--></button>\n" +
    '\t\t\t\t\t\t\t\t</div>\n' +
    '\n' +
    "\t\t\t\t\t\t\t\t<div ng-if=\"column.editable.getType(item)=='json'\" ng-show=\"column.editable.getType(item)=='json'\">\n" +
    '\t\t\t\t\t\t\t\t\t<button ng-click="column.editable.onJsonClick(item)" class="btn btn-sm btn-primary"><i class="fa fa-code"></i></button>\n' +
    '\t\t\t\t\t\t\t\t</div>\n' +
    '\n' +
    '\n' +
    '\t\t\t\t\t\t\t</div>\n' +
    "\t\t\t\t\t\t\t<div class=\"{{column.divClass}}\" ng-if=\"column.type=='label'\" ng-show=\"column.value(item) !==undefined\">\n" +
    '\t\t\t\t\t\t\t\t<span class="{{column.getItemClass(item)}}">\n' +
    '                                    <strong>{{column.value(item)}}</strong>\n' +
    '                                </span>\n' +
    '\t\t\t\t\t\t\t</div>\n' +
    '\n' +
    "\t\t\t\t\t\t\t<div class=\"{{column.divClass}}\" ng-if=\"column.type=='toggleSwitch'\" ng-show=\"column.value(item)!==undefined\">\n" +
    '\t\t\t\t\t\t\t\t<toggle-switch-with-events model="item[column.name]" class="success" on-label="YES" off-label="NO" on-toggle="column.onToggle(item)"></toggle-switch-with-events>\n' +
    '\t\t\t\t\t\t\t</div>\n' +
    '\n' +
    "\t\t\t\t\t\t\t<div class=\"{{column.divClass}}\" ng-if=\"!column.type || column.type=='default'\">{{ column.value(item)}}</div>\n" +
    '\t\t\t\t\t\t</td>\n' +
    '\t\t\t\t\t</tr>\n' +
    '\t\t\t\t</tbody>\n' +
    '\t\t\t</table>\n' +
    '\t\t</div>\n' +
    '\t</div>\n' +
    '</div>\n'
  )

  $templateCache.put('templates/nav_renderer.html',
    '<div ng-if="item.separator==true">\n' +
    '\t<span>{{item.label}}</span>\n' +
    '</div>\n' +
    '<a ng-if="!item.separator" ng-click="select(item)" ng-href="{{item.url}}">\n' +
    '\t<i ng-if="item.iconClasses" class="{{item.iconClasses}}"></i><span>{{item.label}}</span>\n' +
    '\t<span ng-bind-html="item.html"></span>\n' +
    '</a>\n' +
    '<ul ng-if="item.children.length" data-slide-out-nav="item.open || (searchQuery.length>0 && item.selected)">\n' +
    '    <li ng-repeat="item in item.children"\n' +
    '\t    ng-class="{ hasChild: (item.children!==undefined),\n' +
    '                      active: item.selected,\n' +
    '                        open: (item.children!==undefined) && item.open,\n' +
    "              'search-focus': (searchQuery.length>0 && item.selected) }\"\n" +
    ' \t\tng-show="!(searchQuery.length>0 && !item.selected)"\n' +
    "    \tng-include=\"'templates/nav_renderer.html'\"\n" +
    '    ></li>\n' +
    '</ul>\n'
  )

  $templateCache.put('templates/nav_renderer_horizontal.html',
    '<a ng-click="select(item)" ng-href="{{item.url}}">\n' +
    '  <i ng-if="item.iconClasses" class="{{item.iconClasses}}"></i><span>{{item.label}}</span>\n' +
    '  <span ng-bind-html="item.html"></span>\n' +
    '</a>\n' +
    '<ul ng-if="item.children.length">\n' +
    '    <li ng-repeat="item in item.children"\n' +
    '      ng-class="{ hasChild: (item.children!==undefined),\n' +
    '                      active: item.selected,\n' +
    '                        open: (item.children!==undefined) && item.open }"\n' +
    "      ng-include=\"'templates/nav_renderer_horizontal.html'\"\n" +
    '    ></li>\n' +
    '</ul>\n'
  )

  $templateCache.put('templates/panel-tabs-without-heading.html',
    '<div class="panel {{panelClass}}">\n' +
    '  <div class="panel-heading" ng-attr-ng-drag-handle="{{draggable}}">\n' +
    '        <h2>\n' +
    "            <ul class=\"nav nav-{{type || 'tabs'}}\" ng-class=\"{'nav-stacked': vertical, 'nav-justified': justified}\" ng-transclude></ul>\n" +
    '        </h2>\n' +
    '  </div>\n' +
    '  <div class="panel-body">\n' +
    '    <div class="tab-content">\n' +
    '        <div class="tab-pane"\n' +
    '            ng-repeat="tab in tabs"\n' +
    '            ng-class="{active: tab.active}"\n' +
    '            tab-content-transclude="tab">\n' +
    '        </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n'
  )

  $templateCache.put('templates/panel-tabs.html',
    '<div class="panel {{panelClass}}">\n' +
    '  <div class="panel-heading" ng-attr-ng-drag-handle="{{draggable}}">\n' +
    '        <h2><i ng-if="panelIcon" class="{{panelIcon}}"></i>{{(panelIcon? " ":"")+heading}}</h2>\n' +
    '        <div class="panel-ctrls">\n' +
    "            <ul class=\"nav nav-{{type || 'tabs'}}\" ng-class=\"{'nav-stacked': vertical, 'nav-justified': justified}\" ng-transclude></ul>\n" +
    '        </div>\n' +
    '  </div>\n' +
    '  <div class="panel-body">\n' +
    '    <div class="tab-content">\n' +
    '        <div class="tab-pane"\n' +
    '            ng-repeat="tab in tabs"\n' +
    '            ng-class="{active: tab.active}"\n' +
    '            tab-content-transclude="tab">\n' +
    '        </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n'
  )

  $templateCache.put('templates/panel.html',
    '<div class="panel {{panelClass}}">\n' +
    '  <div class="panel-heading">\n' +
    '        <h2><i ng-if="panelIcon" class="{{panelIcon}}"></i>{{(panelIcon? " ":"")+heading}}</h2>\n' +
    '        <div class="panel-ctrls">\n' +
    '        </div>\n' +
    '  </div>\n' +
    '  <div class="panel-body" ng-transclude>\n' +
    '  </div>\n' +
    '</div>\n'
  )

  $templateCache.put('templates/searchImages.html',
    '<style>\n' +
    '  .tab-midnightblue.tab-container .tab-content {\n' +
    '    border: 1px solid #D7d7d7 !important;\n' +
    '  }\n' +
    '  .tab-container > .nav-tabs > li.active > a {\n' +
    '    color: white !important;\n' +
    '    font-size: 16px;\n' +
    '    font-weight: bold;\n' +
    '  }\n' +
    '</style>\n' +
    '<div class="modal-header">\n' +
    '  <h3 class="modal-title">Search Images</h3>\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '  <div class="container-fluid" >\n' +
    '    <div class="input-group">\n' +
    '      <input type="text" class="form-control" placeholder="Search images..." id="searchKey2" ng-model="searchKey2" ng-enter="searchImages(searchKey2)">\n' +
    '      <span class="input-group-btn">\n' +
    '\t\t\t\t\t<a class="btn btn-default" href="" ng-click="searchImages(searchKey2)"><i class="fa fa-fw fa-search"></i></a>\n' +
    '\t\t\t\t</span>\n' +
    '    </div>\n' +
    '    <div style="height:500px;overflow: auto;">\n' +
    '      <div style="display: inline-block">\n' +
    '        <div ng-repeat="item in searchResults" class="search-result-item">\n' +
    '          <img ng-src="{{item.previewURL}}" style="height: 80px;width:160px;object-fit: cover;" class="pointer" ng-click="selectImage(item)">\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div style="text-align: center">\n' +
    '        <button class="btn btn-default" ng-click="searchImagesMore(searchKey2)" ng-show="showLoadMore">Load More</button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <img ng-show="hide" id="selectedFile">\n' +
    '  </div>\n' +
    '  <div data-ng-show="isDownloading" style="width: 100%; height: 100%; position: absolute; top:0; left:0;background-color: rgba(0, 0, 0, 0.3);z-index: 1000;text-align: center;">\n' +
    '    <div style="margin-top: 250px">\n' +
    '      <p style="font-size:20px;color:white"><i class="fa fa-spinner fa-spin"></i> Downloading...</p>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-default" ng-click="close()">Close</button>\n' +
    '</div>\n'
  )

  $templateCache.put('templates/themed-tabs-bottom.html',
    "<div class=\"tab-container tab-{{theme || 'primary'}} tab-{{position || 'normal'}}\">\n" +
    '  <div class="tab-content">\n' +
    '    <div class="tab-pane"\n' +
    '        ng-repeat="tab in tabs"\n' +
    '        ng-class="{active: tab.active}"\n' +
    '        tab-content-transclude="tab">\n' +
    '    </div>\n' +
    '  </div>\n' +
    "  <ul class=\"nav nav-{{type || 'tabs'}}\" ng-class=\"{'nav-stacked': vertical, 'nav-justified': justified}\" ng-transclude></ul>\n" +
    '</div>\n'
  )

  $templateCache.put('templates/themed-tabs.html',
    "<div class=\"tab-container tab-{{theme || 'primary'}} tab-{{position || 'normal'}}\">\n" +
    "  <ul class=\"nav nav-{{type || 'tabs'}}\" ng-class=\"{'nav-stacked': vertical, 'nav-justified': justified}\" ng-transclude></ul>\n" +
    '  <div class="tab-content">\n' +
    '    <div class="tab-pane"\n' +
    '        ng-repeat="tab in tabs"\n' +
    '        ng-class="{active: tab.active}"\n' +
    '        tab-content-transclude="tab">\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n'
  )

  $templateCache.put('templates/tile-generic.html',
    '<div class="info-tiles tiles-{{type}}">\n' +
    '\t<div class="tiles-heading">\n' +
    '\t\t{{heading}}\n' +
    '\t</div>\n' +
    '\t<div class="tiles-body" ng-transclude>\n' +
    '\t</div>\n' +
    '</div>\n'
  )

  $templateCache.put('templates/tile-large.html',
    '<a class="info-tiles tiles-{{item.color}}" ng-href="{{item.href}}">\n' +
    '    <div class="tiles-heading">\n' +
    '        <div class="pull-left">{{item.title}}</div>\n' +
    '        <div class="pull-right">{{item.titleBarInfo}}</div>\n' +
    '    </div>\n' +
    '    <div class="tiles-body">\n' +
    '        <div class="pull-left"><i class="{{item.classes}}"></i></div>\n' +
    '        <div class="pull-right" ng-show="item.text">{{item.text}}</div>\n' +
    '        <div class="pull-right" ng-show="!item.text" ng-transclude></div>\n' +
    '    </div>\n' +
    '</a>\n'
  )

  $templateCache.put('templates/tile-shortcut.html',
    '<a class="shortcut-tiles tiles-{{item.color}}" ng-href="{{item.href}}">\n' +
    '\t<div class="tiles-body">\n' +
    '\t\t<div class="pull-left"><i class="{{item.classes}}"></i></div>\n' +
    '\t\t<div class="pull-right"><span class="badge">{{item.titleBarInfo}}</span></div>\n' +
    '\t</div>\n' +
    '\t<div class="tiles-footer">\n' +
    '\t\t{{item.text}}\n' +
    '\t</div>\n' +
    '</a>\n'
  )

  $templateCache.put('templates/tile-simple.html',
    '<a class="info-tiles tiles-{{item.color}}" ng-href="{{item.href}}">\n' +
    '    <div class="tiles-heading">\n' +
    '        <div class="text-center">{{item.title}}</div>\n' +
    '    </div>\n' +
    '    <div class="tiles-body">\n' +
    '        <div class="text-center" ng-show="item.text">{{item.text}}</div>\n' +
    '    </div>\n' +
    '</a>'
  )

  $templateCache.put('templates/upload-button.html',
    '<div>\n' +
    "    <input type='file' name='file' style='display:none'/>\n" +
    "    <button type=\"button\" class=\"btn btn-default\"><i ng-class=\"{ 'fa fa-cloud-upload':!isUploading, 'fa fa-spinner fa-spin':isUploading }\"></i> {{isUploading ? 'Uploading' : 'Upload...'}}</button>\n" +
    '</div>'
  )

  $templateCache.put('templates/userActivitiesTab.html',
    '<div class="row" ng-controller="userActivitiesTabController">\n' +
    '  <div class="col-md-12" style="padding:10px 20px;">\n' +
    '    <h3 ng-show="activityList.length>1">{{activityList.length}} users have done this</h3>\n' +
    '    <h3 ng-show="activityList.length==1">1 user have done this</h3>\n' +
    '    <h3 ng-show="activityList.length==0">No user have done this</h3>\n' +
    '    <div class="row" style="padding-bottom: 10px;" ng-if="!user.communityAdmin">\n' +
    '      <div class="col-md-5">\n' +
    '        <div class="input-icon right mb10">\n' +
    '          <ui-select style="width:100%" ng-model="filter.community" title="Select a Client" on-select="loadList()">\n' +
    '            <ui-select-match placeholder="Select a Client">{{$select.selected.name}}</ui-select-match>\n' +
    '            <ui-select-choices repeat="item in communityList | filter: $select.search">\n' +
    '              <span ng-bind-html="item.name | highlight: $select.search"></span>\n' +
    '            </ui-select-choices>\n' +
    '          </ui-select>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="row">\n' +
    '      <div class="col-md-12">\n' +
    '        <table class="table table-hover">\n' +
    '          <thead>\n' +
    '          <tr>\n' +
    '            <th>User Avatar</th>\n' +
    '            <th>User Name</th>\n' +
    '            <th>Client</th>\n' +
    '            <th>When</th>\n' +
    '            <!--<th></th>-->\n' +
    '          </tr>\n' +
    '          </thead>\n' +
    '          <tbody>\n' +
    '          <tr ng-repeat="item in activityList" ng-click="editActivity(item, $event)" style="cursor: pointer;">\n' +
    '            <td>\n' +
    '              <a href="" ng-click="CommonUtil.openURL($event, item.user.avatar)">\n' +
    '                <img ng-src="{{CommonUtil.getAvatarThumbnail(item.user.avatar, item.user)}}" style="height:60px;">\n' +
    '              </a>\n' +
    '            </td>\n' +
    '            <td>\n' +
    '              {{item.user.name}}\n' +
    '            </td>\n' +
    '            <td>\n' +
    '              {{item.community.name}}\n' +
    '            </td>\n' +
    '            <td>\n' +
    "              <nobr>{{CommonUtil.formatDateWithFormat(item._bmd.createdAt, 'MMM DD,YYYY hh:mm a')}}</nobr>\n" +
    '            </td>\n' +
    '            <!--<td>\n' +
    '              <button class="btn btn-sm btn-danger pull-right" ng-click="deleteActivity(item._id, $event)">Delete</button>\n' +
    '            </td>-->\n' +
    '          </tr>\n' +
    '          </tbody>\n' +
    '        </table>\n' +
    '        <div class="row">\n' +
    '          <div class="col-md-12 text-center">\n' +
    '            <pagination class="mb5 pagination-sm" items-per-page="collectionView.itemsPerPage" total-items="collectionView.totalItems" ng-model="collectionView.currentPage" max-size="10" ng-change="onPageChange()"></pagination>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div data-ng-show="isLoading" style="width: 100%; height: 100%; position: absolute; top:0; left:0; background-color:rgba(255,255,255,0.4);text-align: center;">\n' +
    '      <div style="background-color: rgba(255,255,255,0.7); margin: 3px;">\n' +
    '        <p><i class="fa fa-spinner fa-spin"></i> Loading...</p>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <script type="text/ng-template" id="activityDetail.html">\n' +
    '      <div class="modal-header">\n' +
    '        <h3 class="modal-title">Activity</h3>\n' +
    '      </div>\n' +
    '      <div class="modal-body">\n' +
    '        <form class="form-horizontal"  name="form.validateForm">\n' +
    '          <div class="form-group">\n' +
    '            <label class="col-sm-3 control-label">User</label>\n' +
    '            <div class="col-sm-9">\n' +
    '              <input class="form-control" ng-model="activity.user.name" readonly>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="form-group">\n' +
    '            <label class="col-sm-3 control-label">Client</label>\n' +
    '            <div class="col-sm-9">\n' +
    '              <input class="form-control" ng-model="activity.community.name" readonly>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="form-group">\n' +
    '            <label class="col-sm-3 control-label">Activity Summary</label>\n' +
    '            <div class="col-sm-9">\n' +
    '              <textarea class="form-control" ng-model="activity.summary" readonly></textarea>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="form-group">\n' +
    '            <label class="col-sm-3 control-label">Link</label>\n' +
    '            <div class="col-sm-9">\n' +
    '              <input type="url" class="form-control" ng-model="activity.link" readonly>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="form-group">\n' +
    '            <label class="col-sm-3 control-label">Points</label>\n' +
    '            <div class="col-sm-9">\n' +
    '              <input input-number type="number" class="form-control" ng-model="activity.points" readonly>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="form-group">\n' +
    '            <label class="col-sm-3 control-label">Like Count</label>\n' +
    '            <div class="col-sm-9">\n' +
    '              <input input-number type="number" class="form-control" ng-model="activity.likeCount" readonly>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </form>\n' +
    '      </div>\n' +
    '      <div class="modal-footer">\n' +
    '        <button class="btn btn-default" ng-click="close()">Close</button>\n' +
    '        <!--<button class="btn btn-primary" ng-click="saveActivityDo()"  ng-disabled="!form.validateForm.$dirty">Save Activity</button>-->\n' +
    '      </div>\n' +
    '    </script>\n' +
    '  </div>\n' +
    '</div>\n'
  )
}])
/* jshint ignore:end */
