import {
  UPDATE_CHARTS_LIST,
  UPDATE_CHART_ACTIVITY_DATA,
  UPDATE_CHART_ACTIVITY_VIEWS_DATA,
  UPDATE_CHART_ACTIVITY_TIMES_DATA,
  UPDATE_CHART_DEMOGRAPHIC_DATA,
  UPDATE_CHART_USER_DATA
} from '../constants/types'
import { Charts } from '../reducers/list'

export interface Action {
  type: string,
  charts?: Charts,
  data?: any
}

export const update = (charts: Charts): Action => {
  return {
    type: UPDATE_CHARTS_LIST,
    charts
  }
}

export const updateActivityData = (data: any): Action => {
  return {
    type: UPDATE_CHART_ACTIVITY_DATA,
    data
  }
}

export const updateActivityViewData = (data: any): Action => {
  return {
    type: UPDATE_CHART_ACTIVITY_VIEWS_DATA,
    data
  }
}

export const updateActivityTimesData = (data: any): Action => {
  return {
    type: UPDATE_CHART_ACTIVITY_TIMES_DATA,
    data
  }
}

export const updateUserData = (data: any): Action => {
  return {
    type: UPDATE_CHART_USER_DATA,
    data
  }
}

export const updateDemographicData = (data: any): Action => {
  return {
    type: UPDATE_CHART_DEMOGRAPHIC_DATA,
    data
  }
}
