'use strict'

import * as organizations from '../../../src/features/organizations'

angular.module('app.controllers')
  .controller('OrganizationEditController', ['$scope', '$modalInstance', '$ngRedux', 'organization',
    function ($scope, $modalInstance, $ngRedux, organization) {
      const mapStateToThis = (state) => ({
        organizationsList: state.organizations.list
      })

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      if (organization) $scope.model = { name: organization.name }

      $scope.proceed = function () {
        $scope.errorMessage = null
        const model = {...$scope.model}

        const exists = $scope.organizationsList.find((org) => org.name == model.name && (!organization || (organization && organization._id != org._id)))
        if (exists) {
          $scope.errorMessage = 'Organization already exists'
        } else {
          const result = (organization
            ? organizations.helpers.update({...organization, name: model.name})
            : organizations.helpers.create(model)
          )

          result.then((result) => {
            if (result && result.error) {
              $scope.errorMessage = result.error
            } else {
              $modalInstance.close(result)
            }
          })
        }
      }

      $scope.cancel = function () {
        $modalInstance.dismiss('cancel')
      }
    }])
