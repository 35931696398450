import {
  UPDATE_STUDIO_APPS_SETTINGS,
  UPDATE_STUDIO_APPS_PAGE_SETTINGS,
  RESET_STUDIO_APPS_SETTINGS
} from '../constants/types'
import { Settings } from '../reducers/settings'

export interface Action {
  type: string,
  users: Settings,
  count: number,
  page: number,
  communityId: any,
  communityTeams: any,
  studioAppResponses: any,
  team: any
}

export const update = (count) => {
  return {
    type: UPDATE_STUDIO_APPS_SETTINGS,
    count
  }
}

export const pageUpdate = (page) => {
  return {
    type: UPDATE_STUDIO_APPS_PAGE_SETTINGS,
    page
  }
}

export const reset = () => {
  return {
    type: RESET_STUDIO_APPS_SETTINGS
  }
}
