import { Action } from '../actions/list'
import {
  UPDATE_POLL_QUESTIONS_LIST
} from '../constants/types'

export interface Item {
  any
}

export type PollQuestions = Array<Item>

const initialState: PollQuestions = []

export default (state: PollQuestions = initialState, action: Action | any): PollQuestions => {
  switch (action.type) {
    case UPDATE_POLL_QUESTIONS_LIST:
      return action.pollQuestions
    default:
      return state
  }
}
