import { combineReducers } from 'redux'
import list from './list'
import settings from './settings'

const rootReducer = combineReducers({
  list,
  settings
})

export default rootReducer
