import {
  UPDATE_SEGMENT_TYPES_SETTINGS,
  UPDATE_SEGMENT_TYPE_RESPONSES,
  UPDATE_SEGMENT_TYPES_PAGE_SETTINGS,
  UPDATE_SEGMENT_TYPES_COMMUNITY_SETTINGS,
  UPDATE_SEGMENT_TYPES_TEAMS_SETTINGS,
  UPDATE_SEGMENT_TYPES_TEAM_SETTINGS,
  RESET_SEGMENT_TYPES_SETTINGS
} from '../constants/types'
import { Settings } from '../reducers/settings'

export interface Action {
  type: string,
  users: Settings,
  count: number,
  page: number,
  communityId: any,
  communityTeams: any,
  segmentTypeResponses: any,
  team: any
}

export const update = (count) => {
  return {
    type: UPDATE_SEGMENT_TYPES_SETTINGS,
    count
  }
}

export const pageUpdate = (page) => {
  return {
    type: UPDATE_SEGMENT_TYPES_PAGE_SETTINGS,
    page
  }
}

export const selectCommunity = (communityId) => {
  return {
    type: UPDATE_SEGMENT_TYPES_COMMUNITY_SETTINGS,
    communityId
  }
}

export const selectTeam = (team) => {
  return {
    type: UPDATE_SEGMENT_TYPES_TEAM_SETTINGS,
    team
  }
}

export const teamsUpdate = (communityTeams) => {
  return {
    type: UPDATE_SEGMENT_TYPES_TEAMS_SETTINGS,
    communityTeams
  }
}

export const getSegmentTypeResponses = (segmentTypeResponses) => {
  return {
    type: UPDATE_SEGMENT_TYPE_RESPONSES,
    segmentTypeResponses
  }
}

export const reset = () => {
  return {
    type: RESET_SEGMENT_TYPES_SETTINGS
  }
}
