
'use strict'

import commonUtil from '../../../src/helpers/commonUtil'
import * as communities from '../../../src/features/communities'
import * as categories from '../../../src/features/categories'
import { getActiveUser } from '../../../src/helpers/index'; 


angular.module('app.controllers')
  .controller('CategoryEditController', ['$scope', '$location', '$routeParams', '$ngRedux',
    async function ($scope, $location, $routeParams, $ngRedux) {

      const mapStateToThis = (state) => {
        return {
          communities: state.communities.list,
          categoryGroups: state.categories.settings.groups
        }
      }

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      await categories.helpers.getAllCategoryGroups()
      communities.helpers.init()
      const activeUser = getActiveUser(); 

      $scope.groupList = commonUtil.AllCategoryGroups
      $scope.fileProgress = []
      $scope.isUploading = []
      $scope.groups = _.filter($scope.categoryGroups, function (o) {
        return o.community 
      })
      $scope.category = {}
      $scope.master = false; 

      if (activeUser.email === "master@milkcrate.tech") {
        $scope.master = true; 
      }

      if ($routeParams.id !== 'new') {
        let category = await categories.helpers.get($routeParams.id)
        if (category.community && typeof category.community === 'object') {
          category.community = category.community._id // ugh why darkness?!?!
        }
        if (category.group && typeof category.group === 'object') {
          category.group = category.group._id
        }
        $scope.category = _.clone(category)
      }

      $scope.cancel = () => {
        $location.path('/categories')
      }

      $scope.deleteCategory = async (id, e) => {
        if (!id) {
          return $scope.cancel()
        }
        e.preventDefault()
        e.stopPropagation()
        const yeap = confirm('Deleting the category is permanent and can not be undone.')
        if (!yeap) {
          return false
        }
        $scope.cancel()
        $scope.isLoading = true
        await categories.helpers.delete(id)
      }

      $scope.selectClient = function () {
        if ($scope.category.community) {
          $scope.category.group = ''
          $scope.groups = _.filter($scope.categoryGroups, function (o) {
            return o.community && o.community._id == $scope.category.community
          })
        } else {
          $scope.category.group = ''
        }
      }

      function isValid (category) {
        $scope.errorMsg = false;
        let { community, coverImage, slug, icon, iconSticker} = category
        const required = 'required'
        $scope.slugReq = false;
        $scope.coverImageReq = false;
        $scope.iconReq = false;
        $scope.iconStickerReq = false;
        if(!slug) $scope.slugReq = required
        if(!coverImage) $scope.coverImageReq = required
        if(!icon) $scope.iconReq = required
        if(!iconSticker) $scope.iconStickerReq = required
        console.log('slugReq:',$scope.slugReq)
        if (!coverImage || !community || !slug || !icon || !iconSticker) {
          $scope.errorMsg = true
          return false
        }
        return true
      }

      $scope.saveCategoryDo = async () => {
      
        var category = _.clone($scope.category)
        if (activeUser && activeUser.email !== "master@milkcrate.tech") {
          category.community = commonUtil.makeBendRef(activeUser.community._id, 'community')
        } else if (category && category.community) {
            category.community = commonUtil.makeBendRef(category.community, 'community'); 
        }
        console.log('category:',category)
        if (!isValid(category)) return

        if (category.community) category.group = commonUtil.makeBendRef(category.group, 'categoryGroup')
        if (category.icon) category.icon = commonUtil.makeBendFile(category.icon._id)
        if (category.image1) category.image1 = commonUtil.makeBendFile(category.image1._id)
        if (category.image2) category.image2 = commonUtil.makeBendFile(category.image2._id)
        if (category.image3) category.image3 = commonUtil.makeBendFile(category.image3._id)
        if (category.image4) category.image4 = commonUtil.makeBendFile(category.image4._id)
        if (category.coverImage) category.coverImage = commonUtil.makeBendFile(category.coverImage._id)
        if (category.buttonImage) category.buttonImage = commonUtil.makeBendFile(category.buttonImage._id)
        if (category.iconSticker) category.iconSticker = commonUtil.makeBendFile(category.iconSticker._id)
        if (category.buttonImageSelected) category.buttonImageSelected = commonUtil.makeBendFile(category.buttonImageSelected._id)

        delete category.$$hashKey
        $scope.isLoading = true
        if (category._id) categories.helpers.update(category)
        else categories.helpers.create(category)
        $location.path('/categories')
      }

      $scope.deleteFile = function (tag, $ev) {
        $ev.stopPropagation()
        $ev.preventDefault()
        applyChangesOnScope($scope, function () {
          delete $scope.category[tag]
        })
      }

      $scope.showFileLoading = function (tag, bShow) {
        $scope.isUploading[tag] = bShow
      }

      $scope.selectFileOpen = function (fileId, $ev) {
        if ($ev.target.tagName == 'DIV') {
          setTimeout(function () {
            $('#' + fileId).click()
          }, 0)
        }
      }

      $scope.onFileUpload = function (files, tag, workflow) {
        var file = files[0]
        $scope.fileProgress[tag] = 0
        $scope.showFileLoading(tag, true)

        categories.helpers.upload(file, function (error, uploadedFile) {
          if (error) {
            $scope.showFileLoading(tag, false)
            return
          }
          categories.helpers.getFile(uploadedFile, function (o) {
            $scope.category[tag] = o
            $scope.showFileLoading(tag, false)
          })
        }, {
          _workflow: workflow
        }, function (total, prog) {
          applyChangesOnScope($scope, function () {
            $scope.fileProgress[tag] = prog * 100 / total
          })
        })
      }
    }
  ])
