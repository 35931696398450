import { UPDATE_LEADERBOARDS_USERS_POINTS_LIST } from '../constants/types'
import { Action } from '../actions/usersPoints'

export interface Item {
}

export type List = Array<Item>

const initialState: List = []

export default (state: List = initialState, action: Action): List => {
  switch (action.type) {
    case UPDATE_LEADERBOARDS_USERS_POINTS_LIST:
      return action.usersPoints
    default:
      return state
  }
}
