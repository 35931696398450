import {
  UPDATE_SCHOOLS_LIST
} from '../constants/types'
import { Schools } from '../reducers/list'

export interface Action {
  type: string,
  schools: Schools
}

export const update = (schools: Schools): Action => {
  return {
    type: UPDATE_SCHOOLS_LIST,
    schools
  }
}
