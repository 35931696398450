import {
  UPDATE_ORGANIZATIONS_SETTINGS,
  UPDATE_ORGANIZATIONS_PAGE_SETTINGS,
  UPDATE_ORGANIZATIONS_LOADING_SETTINGS,
  UPDATE_ORGANIZATIONS_COMMUNITY_SETTINGS
} from '../constants/types'

import { Action } from '../actions/settings'

export interface Settings {
  itemsPerPage: number,
  isLoading: boolean,
  totalItems: number,
  currentPage: number,
  numPages: number,
  community: { _id: string }
}

const initialState: Settings = {
  itemsPerPage: 20,
  isLoading: true,
  totalItems: 0,
  currentPage: 1,
  numPages: 0,
  community: { _id: '' }
}

export default (state: Settings = initialState, action: Action): Settings => {
  switch (action.type) {
    case UPDATE_ORGANIZATIONS_SETTINGS:
      return {
        ...state,
        totalItems: action.count,
        numPages: Math.ceil(action.count / state.itemsPerPage + 1)
      }
    case UPDATE_ORGANIZATIONS_PAGE_SETTINGS:
      return {
        ...state,
        currentPage: action.page
      }
    case UPDATE_ORGANIZATIONS_LOADING_SETTINGS:
      return {
        ...state,
        isLoading: action.isLoading
      }
    case UPDATE_ORGANIZATIONS_COMMUNITY_SETTINGS:
      return {
        ...state,
        community: { _id: action.communityId }
      }
    default:
      return state
  }
}
