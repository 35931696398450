import * as _ from 'lodash'
import redux from '../../../src/helpers/redux'
import BendClient, { BendTable } from '../BendClient'

import * as actions from './actions'
import { Settings, defaultSettings } from '../../types'
import { getActiveUser } from '../../helpers';

class Activities {

  private api: any
  private api2: any

  constructor() {
    this.api = BendClient
    this.api2 = BendTable
  }

  public getCommunityList(callback) {
    this.api.getCommunityList().then((response) => {
      callback(response)
    })
  }

  public async getAllTeams() {
    const teams = await this.api.getAllTeams()
    redux.dispatch(
      actions.settings.teamsUpdate(teams)
    )
  }

  public selectTeam(team) {
    redux.dispatch(
      actions.settings.teamUpdate(team)
    )
  }

  public updateItemsPerPage(num) {
    let input
    if (typeof num === 'number') input = num
    else input = null
    redux.dispatch(
      actions.settings.updateItemsPerPage(input)
    )
    const { settings } = redux.getState().activities
    this.fetchList(settings)
  }

  public delete(id) {
    return this.api2.delete(BendTable.Tables.ACTIVITY, id)
  }

  public getImages(settings: { id: string[] }) {
    return this.api.fetchImagesById(settings)
  }

  public paginateToPage(page) {
    redux.dispatch(
      actions.settings.pageUpdate(page)
    )
  }

  public reset() {
    // preserve team list to maintain teams filter
    const teams = redux.getState().activities.settings.teams
    redux.dispatch(
      actions.settings.reset()
    )
    redux.dispatch(
      // Do not include teams[0] - settings.teamsUpdate adds it by default
      actions.settings.teamsUpdate(teams.slice(1))
    )
  }

  public async fetchList(settings: Settings = defaultSettings) {    
    if (!settings.communityId) settings.communityId = getActiveUser().community._id
    this.listNavigation(settings).then((count: number) => {
      redux.dispatch(
        actions.settings.update(count)
      )
    })
    let activities = await this.api.fetchActivitiesPage(settings)
    let imageMap: any = {}
    let imageIds: any = []
    _.each(activities, function (o) {
      if (o.user.avatar) {
        if (imageIds.indexOf(o.user.avatar._id) === -1) {
          imageIds.push(o.user.avatar._id)
        }
      }
    })
    this.getImages({ id: imageIds }).then(function (rets) {
      _.each(rets, function (o) {
        imageMap[o._id] = o
      })
      _.map(activities, function (o) {
        if (o.user.avatar) {
          o.user.avatar = imageMap[o.user.avatar._id]
        }
      })
    })
    redux.dispatch(
      actions.list.update(activities)
    )
  }

  public async fetchSurveyActivities() {
    const surveyActivity = await this.api.fetchSurveyActivities()
    redux.dispatch(
      actions.list.update(surveyActivity)
    )
  }

  public listNavigation(settings: Settings = defaultSettings) {
    return this.api.activitiesPageNavigation(settings)
  }

}

export default new Activities()
