import * as _ from 'lodash'
import * as actions from './actions'
import BendClient from '../BendClient/index'
import * as Bend from '../../lib/bend-1.1.8'
import redux from '../../../src/helpers/redux'

class Feature {

  private api: any

  constructor () {
    this.api = BendClient
  }

  // used by dashboard
  async init (communityAdmin, communityId) {
    const settings = redux.getState().leaderboards.settings
    const { leaderboards, count } = await this.api.fetchLeaderboard(settings, communityAdmin, communityId)
    const limitedCount = Math.min(200, count)

    redux.dispatch(
      actions.list.update(leaderboards)
    )
    redux.dispatch(
      actions.settings.update(limitedCount)
    )
  }

  // used by leaderboards page
  public async initPageList (loadingMore = false) {

    try {
      const settings = redux.getState().leaderboards.settings
      const list = redux.getState().leaderboards.list || []
      const leaderboard = await this.api.fetchLeaderboardUsers(settings) // get activities for current sprint or for category
      
      let hasMore = false;
      if (leaderboard.length > 49)
        hasMore = true;
      redux.dispatch(
        actions.settings.hasMore(hasMore)
      )

      //Ugly way but we don't have the pre calculated data now and we need to avoid queries
      let filteredList: any = [];
      
      leaderboard.forEach(item => {
        if (item.name && item.deleted != true && item.email)
          filteredList.push(item)
      });
      //console.log('FilteredList', filteredList)
      let fullList = settings.currentPage == 1 ? filteredList : list.concat(filteredList)
      redux.dispatch(
        actions.list.update(fullList)
      )

      if (loadingMore == false){ //Lets calculate points and user count only on first loading
        const userIds = await this.api.countLeaderboardUsers(settings) // get totalusers for current sprint or for category
        const totalPoints = await this.api.sumLeaderboardPoints(settings) // get totalusers for current sprint or for category
        redux.dispatch(
          actions.usersIds.update(userIds)
        )
        redux.dispatch(
          actions.settings.updateTotalPoint(totalPoints)
        )
      }
    } catch (error) {
        console.error(error)
    }
    
  }

  clearPagination(){
    redux.dispatch(actions.list.update([]))
    redux.dispatch(actions.settings.pageUpdate(1))
  }

  public selectCommunity (id) {
    let selected = redux.getState().communities.list.find(item => item._id === id)
    redux.dispatch(
      actions.settings.communityUpdate(selected)
    )
  }

  public selectCategory (id) {
    console.log('redux', redux.getState())
    let categories = redux.getState().categories.list
    let selected = categories.find(item => item._id === id)
    this.clearPagination();
    redux.dispatch(
      actions.settings.categoryUpdate(selected)
    )
  }

  public selectSprint (id) {
    let selected = redux.getState().sprints.list.find(item => item._id === id)
    this.clearPagination()
    redux.dispatch(
      actions.settings.sprintUpdate(selected)
    )
  }

  public paginateToPage (page) {
    redux.dispatch(
      actions.settings.pageUpdate(page)
    )
  }

  public async readMoreOnPage () {
    const stateSettings = redux.getState().leaderboards.settings
    this.paginateToPage(stateSettings.currentPage + 1)
    await this.initPageList(true)
  }

  public async reset () {
    const user = Bend.getActiveUser()
    const { communityAdmin } = user
    const userCommunityId = user.community && user.community._id
    const communities = redux.getState().communities.list
    redux.dispatch(
      actions.settings.reset()
    )
    if (communityAdmin) {
      this.selectCommunity(userCommunityId)
    } else {
      this.selectCommunity(_.get(communities, '[0]._id'))
    }
    this.selectCategory(null)
    this.selectSprint(null)
    await this.initPageList()
  }

}

export default new Feature()
