import { UPDATE_LEADERBOARDS_USERS_POINTS_LIST } from '../constants/types'
import { List } from '../reducers/usersPoints'

export interface Action {
  type: string,
  usersPoints: List
}

export const update = (usersPoints: List): Action => {
  return {
    type: UPDATE_LEADERBOARDS_USERS_POINTS_LIST,
    usersPoints
  }
}
