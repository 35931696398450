export const UPDATE_CHALLENGES_EXPORTS_LIST = 'UPDATE_CHALLENGES_EXPORTS_LIST'
export const UPDATE_CHALLENGES_LIST = 'UPDATE_CHALLENGES_LIST'
export const UPDATE_CHALLENGES_SETTINGS = 'UPDATE_CHALLENGES_SETTINGS'
export const UPDATE_CHALLENGES_PAGE_SETTINGS = 'UPDATE_CHALLENGES_PAGE_SETTINGS'
export const UPDATE_CHALLENGES_KEYWORD_SETTINGS = 'UPDATE_CHALLENGES_KEYWORD_SETTINGS'
export const UPDATE_CHALLENGES_COMMUNITY = 'UPDATE_CHALLENGES_COMMUNITY'
export const UPDATE_CHALLENGES_TYPE = 'UPDATE_CHALLENGES_TYPE'
export const UPDATE_CHALLENGES_TEAM = 'UPDATE_CHALLENGES_TEAM'
export const UPDATE_CHALLENGES_TEAMS = 'UPDATE_CHALLENGES_TEAMS'
export const RESET_CHALLENGES_SETTINGS = 'RESET_CHALLENGES_SETTINGS'
