import {
  UPDATE_PUSH_NOTIFICATIONS_SETTINGS,
  UPDATE_PUSH_NOTIFICATIONS_PAGE_SETTINGS,
  UPDATE_PUSH_NOTIFICATIONS_KEYWORD_SETTINGS,
  UPDATE_PUSH_NOTIFICATIONS_LOADING_SETTINGS,
  UPDATE_PUSH_NOTIFICATIONS_COMMUNITY_SETTINGS,
  RESET_PUSH_NOTIFICATIONS_SETTINGS
} from '../constants/types'
import { Settings } from '../reducers/settings'

export interface Action {
  type: string,
  pushNotifications: Settings,
  count: number,
  page: number,
  keyword: string,
  communityId: string,
  isLoading: boolean
}

export const update = (count) => {
  return {
    type: UPDATE_PUSH_NOTIFICATIONS_SETTINGS,
    count
  }
}

export const pageUpdate = (page) => {
  return {
    type: UPDATE_PUSH_NOTIFICATIONS_PAGE_SETTINGS,
    page
  }
}

export const searchKeywordUpdate = (keyword) => {
  return {
    type: UPDATE_PUSH_NOTIFICATIONS_KEYWORD_SETTINGS,
    keyword
  }
}

export const selectCommunity = (communityId) => {
  return {
    type: UPDATE_PUSH_NOTIFICATIONS_COMMUNITY_SETTINGS,
    communityId
  }
}

export const toggleLoading = (loadingState) => {
  return {
    type: UPDATE_PUSH_NOTIFICATIONS_LOADING_SETTINGS,
    isLoading: loadingState
  }
}

export const reset = () => {
  return {
    type: RESET_PUSH_NOTIFICATIONS_SETTINGS
  }
}
