import { combineReducers } from 'redux'
import list from './list'
import form from './form'

const rootReducer = combineReducers({
  list,
  form
})

export default rootReducer
