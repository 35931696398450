import { UPDATE_STATISTICS_LIST } from '../constants/types'
import { Action } from '../actions/data'

export interface Statistics {
  totalUsers: number,
  totalActivities: number,
  totalPoints: number,
  thisWeekPoints: number,
 
}

const initialState: Statistics = {
  totalUsers: 0,
  totalActivities: 0,
  totalPoints: 0,
  thisWeekPoints: 0
}

export default (state: Statistics = initialState, action: Action | any): Statistics => {
  switch (action.type) {
    case UPDATE_STATISTICS_LIST:
      return action.statistics
    default:
      return state
  }
}
