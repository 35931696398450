import 'imports-loader?define=>false!../assets/plugins/form-colorpicker/js/bootstrap-colorpicker.min'
import 'imports-loader?define=>false!../assets/plugins/form-fseditor/jquery.fseditor-min'
import 'imports-loader?define=>false!../assets/plugins/form-jasnyupload/fileinput.min'
import 'imports-loader?define=>false!../assets/plugins/flot/jquery.flot.spline'
import 'imports-loader?define=>false!../assets/plugins/jquery-timepicker/jquery.timepicker.min'
import 'imports-loader?define=>false!../scripts/core/bend-angular-1.1.8'
import 'imports-loader?define=>false!../scripts/controller/controllers'
import 'imports-loader?define=>false!!../scripts/core/controllers/mainController'
import 'imports-loader?define=>false!../scripts/core/controllers/messagesController'
import 'imports-loader?define=>false!../scripts/core/controllers/navigationController'
import 'imports-loader?define=>false!../scripts/core/controllers/notificationsController'
import 'imports-loader?define=>false!../scripts/core/directives/directives'
import 'imports-loader?define=>false!../scripts/core/directives/form'
import 'imports-loader?define=>false!../scripts/core/directives/ui'
import 'imports-loader?define=>false!../scripts/core/modules/templateOverrides'
import 'imports-loader?define=>false!../scripts/core/modules/templates'
import 'imports-loader?define=>false!../scripts/core/modules/panels/ngDraggable'
import 'imports-loader?define=>false!../scripts/core/modules/panels/panels'
import 'imports-loader?define=>false!../scripts/core/modules/panels/directives'
import 'imports-loader?define=>false!../scripts/core/services/services'
import 'imports-loader?define=>false!../scripts/core/services/theme'
import 'imports-loader?define=>false!../scripts/core/theme'
import 'imports-loader?define=>false!../scripts/calendar/calendar'
import 'imports-loader?define=>false!../scripts/chart/canvas'
import 'imports-loader?define=>false!../scripts/chart/flot'
import 'imports-loader?define=>false!../scripts/chart/morris'
import 'imports-loader?define=>false!../scripts/chart/sparklines'
import 'imports-loader?define=>false!../scripts/gallery/gallery'
import 'imports-loader?define=>false!../scripts/common/Directives'
import 'imports-loader?define=>false!../scripts/common/Model'
import 'imports-loader?define=>false!../scripts/common/Util'
import 'imports-loader?define=>false!../scripts/common/BendAuth'
import 'imports-loader?define=>false!../scripts/common/BendPusher'
import 'imports-loader?define=>false!../scripts/common/Services'
import 'imports-loader?define=>false!../scripts/controller/SigninController'
import 'imports-loader?define=>false!../scripts/controller/ChartController'
import 'imports-loader?define=>false!../scripts/controller/ChartEditController'
import 'imports-loader?define=>false!../scripts/controller/CustomActivityController'
import 'imports-loader?define=>false!../scripts/controller/CustomActivityEditController'
import 'imports-loader?define=>false!../scripts/controller/DashboardController'
import 'imports-loader?define=>false!../scripts/controller/ActionController'
import 'imports-loader?define=>false!../scripts/controller/ActionEditController'
import 'imports-loader?define=>false!../scripts/controller/BusinessController'
import 'imports-loader?define=>false!../scripts/controller/BusinessEditController'
import 'imports-loader?define=>false!../scripts/controller/ChallengeController'
import 'imports-loader?define=>false!../scripts/controller/ChallengeEditController'
import 'imports-loader?define=>false!../scripts/controller/ActivityController'
import 'imports-loader?define=>false!../scripts/controller/ActivityEditController'
import 'imports-loader?define=>false!../scripts/controller/UserActivityEditController'
import 'imports-loader?define=>false!../scripts/controller/CommunityController'
import 'imports-loader?define=>false!../scripts/controller/CommunityEditController'
import 'imports-loader?define=>false!../scripts/controller/CommunityConfigController'
import 'imports-loader?define=>false!../scripts/controller/EventController'
import 'imports-loader?define=>false!../scripts/controller/EventEditController'
import 'imports-loader?define=>false!../scripts/controller/EventRegistrationsController'
import 'imports-loader?define=>false!../scripts/controller/EventCheckinsController'
import 'imports-loader?define=>false!../scripts/controller/EventCancellationsController'
import 'imports-loader?define=>false!../scripts/controller/EventTemplatesController'
import 'imports-loader?define=>false!../scripts/controller/EventTemplatesEditController'
import 'imports-loader?define=>false!../scripts/controller/VolunteeringController'
import 'imports-loader?define=>false!../scripts/controller/VolunteeringEditController'
import 'imports-loader?define=>false!../scripts/controller/PushTemplatesController'
import 'imports-loader?define=>false!../scripts/controller/PushController'
import 'imports-loader?define=>false!../scripts/controller/PushEditController'
import 'imports-loader?define=>false!../scripts/controller/PollQuestionController' // for dailyPolls
import 'imports-loader?define=>false!../scripts/controller/StudioAppController'
import 'imports-loader?define=>false!../scripts/controller/CommentsTabController'
import 'imports-loader?$UI=jquery-ui/sortable!../scripts/controller/TeamController'
import 'imports-loader?define=>false!../scripts/controller/TeamEditController'
import 'imports-loader?define=>false!../scripts/controller/CalendarController'
import 'imports-loader?define=>false!../scripts/controller/UtilityController'
import 'imports-loader?define=>false!../scripts/controller/LeaderboardController'
import 'imports-loader?define=>false!../scripts/controller/TasksController'
import 'imports-loader?define=>false!../scripts/controller/CommunityWhiteLabelController'
import 'imports-loader?define=>false!../scripts/controller/CommunityPushTabController'
import 'imports-loader?define=>false!../scripts/controller/PushTabController'
import 'imports-loader?define=>false!../scripts/controller/UserController'
import 'imports-loader?define=>false!../scripts/controller/OrganizationsController'
import 'imports-loader?define=>false!../scripts/controller/OrganizationEditController'
import 'imports-loader?define=>false!../scripts/controller/SchoolsController'
import 'imports-loader?define=>false!../scripts/controller/SchoolEditController'
import 'imports-loader?define=>false!../scripts/controller/CategoryController'
import 'imports-loader?define=>false!../scripts/controller/CategoryEditController'
import 'imports-loader?define=>false!../scripts/controller/GoalsController'
import 'imports-loader?define=>false!../scripts/controller/GoalsEditController'


// UserGroups
import 'imports-loader?define=>false!../scripts/controller/UserGroupsController'
import 'imports-loader?define=>false!../scripts/controller/UserGroupsEditController'
import 'imports-loader?define=>false!../../src/lib/dropzone-directive.js'

// Legacy
import 'imports-loader?define=>false!../scripts/controller/legacy/EventController'
import 'imports-loader?define=>false!../scripts/controller/legacy/EventEditController'

// new format
import 'imports-loader?define=>false!../../src/features/moderation/ModerationController'
import 'imports-loader?define=>false!../../src/features/surveys/SurveyController'
import 'imports-loader?define=>false!../../src/features/surveys/SurveyEditController'
import 'imports-loader?define=>false!../../src/features/surveyQuestions/SurveyQuestionController'
import 'imports-loader?define=>false!../../src/features/segments/SegmentController'
import 'imports-loader?define=>false!../../src/features/segments/SegmentEditController'
import 'imports-loader?define=>false!../../src/features/segmentTypes/SegmentTypeController'
import 'imports-loader?define=>false!../../src/features/segmentTypes/SegmentTypeEditController'
import 'imports-loader?define=>false!../../src/features/surveyQuestions/SurveyQuestionEditController' // has modal sub-scope

// REMOVED MODAL SUB-SCOPE HERE AS TEST, NOW CREATE/EDIT HAPPENS ON SAME VIEW AS LIST
// REMOVED MODAL SUB-SCOPE, NOW CREATE/EDIT HAPPENS ON SAME VIEW AS LIST
import 'imports-loader?define=>false!../scripts/controller/SprintController'
import 'imports-loader?define=>false!../scripts/controller/CategoryGroupController'
import 'imports-loader?define=>false!../scripts/controller/PollQuestionEditController' // for dailyPolls
import 'imports-loader?define=>false!../scripts/controller/UserEditController'

// CONTROLLERS THAT HAVE MODAL SUB-SCOPE BUILT INTO THEM
import 'imports-loader?define=>false!../scripts/controller/userActivitiesTabController'
import 'imports-loader?define=>false!../scripts/controller/attendanceTabController'
import 'imports-loader?define=>false!../scripts/controller/registrantsTabController'
import 'imports-loader?define=>false!../scripts/controller/CertificationController'
import 'imports-loader?define=>false!../scripts/controller/CollectionController'

import Raven from 'raven-js'
import angularRavenPlugin, {moduleName as ngRaven} from 'raven-js/plugins/angular'
import ngRedux from 'ng-redux'
import thunk from 'redux-thunk'
import rootReducer from '../../src/reducers'
// import persistStorage from '../../src/helpers/persistStorage'
import {isProduction} from '../../src/helpers'
import redux from '../../src/helpers/redux'

Raven
  .config('https://513d0592ab0b4a22ba20e0da1f4aec78@sentry.io/1056021')
  .addPlugin(angularRavenPlugin, angular)
  .install()

angular
  .module('themesApp', [
    'theme',
    'theme.core.services',
    'theme.chart.flot',
    'oc.lazyLoad',
    /* 'theme.demos', */
    'LocalStorageModule',
    'theme.core.directives',
    'bend',
    'app.common',
    'app.auth',
    'app.pusher',
    'app.directives',
    'app.model',
    'app.services',
    'ui.bootstrap.datetimepicker',
    'base64',
    'ngCookies',
    'ui.select',
    'ngSanitize',
    'NgSwitchery',
    'ngCsv',
    'ui.ace',
    'app.controllers',
    'colorpicker.module',
    'theme.calendar',
    'ui.calendar',
    'ckeditor',
    'dropzone',
    ngRedux,
    ngRaven
  ])
  .config(['$provide', '$routeProvider', 'localStorageServiceProvider', '$sceDelegateProvider', '$ngReduxProvider', function ($provide, $routeProvider, localStorageServiceProvider, $sceDelegateProvider, $ngReduxProvider) {
    'use strict'
    // const middlewares = [ persistStorage.middlware(), thunk ]
    const middlewares = [thunk]
    const storeEnhancers = isProduction() ? [] : [window.__REDUX_DEVTOOLS_EXTENSION__()]
    // $ngReduxProvider.createStoreWith(rootReducer, middlewares, storeEnhancers, persistStorage.initialState)
    $ngReduxProvider.createStoreWith(rootReducer, middlewares)
    localStorageServiceProvider.setPrefix('Milkcrate')
    $sceDelegateProvider.resourceUrlWhitelist(['**'])
    $routeProvider
      .when('/', {
        templateUrl: 'views/index.html'
        // resolve: {
        // }
      })
      .when('/users/create', {
        templateUrl: function (param) {
          return 'views/users/userEdit.html'
        }
      })
      .when('/users/:id', {
        templateUrl: function (param) {
          return 'views/users/userEdit.html'
        }
      })
      .when('/users', {
        templateUrl: function (param) {
          return 'views/users/users.html'
        }
      })
      .when('/charts', {
        templateUrl: function (param) {
          return 'views/charts/charts.html'
        }
      })
      .when('/charts/new', {
        templateUrl: function (param) {
          return 'views/charts/chartEdit.html'
        }
      })
      .when('/charts/:id', {
        templateUrl: function (param) {
          return 'views/charts/chartEdit.html'
        }
      })
      .when('/customActivities', {
        templateUrl: function (param) {
          return 'views/customActivities/customActivities.html'
        }
      })
      .when('/customActivities/new', {
        templateUrl: function (param) {
          return 'views/customActivities/customActivityEdit.html'
        }
      })
      .when('/customActivities/:id', {
        templateUrl: function (param) {
          return 'views/customActivities/customActivityEdit.html'
        }
      })
      .when('/signin', {
        templateUrl: function (param) {
          return 'views/signin.html'
        }
      })
      .when('/actions', {
        templateUrl: function (param) {
          return 'views/actions/actions.html'
        }
      })
      .when('/actions/:id', {
        templateUrl: function (param) {
          return 'views/actions/actionEdit.html'
        }
      })
      .when('/actions/new', {
        templateUrl: function (param) {
          return 'views/actions/actionEdit.html'
        }
      })
      .when('/businesses', {
        templateUrl: function (param) {
          return 'views/businesses/businesses.html'
        }
      })
      .when('/businesses/:id', {
        templateUrl: function (param) {
          return 'views/businesses/businessEdit.html'
        }
      })
      .when('/businesses/new', {
        templateUrl: function (param) {
          return 'views/businesses/businessEdit.html'
        }
      })
      .when('/challenges', {
        templateUrl: function (param) {
          return 'views/challenges/challenges.html'
        }
      })
      .when('/challenges/:id', {
        templateUrl: function (param) {
          return 'views/challenges/challengeEdit.html'
        }
      })
      .when('/challenges/new', {
        templateUrl: function (param) {
          return 'views/challenges/challengeEdit.html'
        }
      })
      .when('/dailyPolls', {
        templateUrl: function (param) {
          return 'views/polls/pollQuestions.html'
        }
      })
      .when('/dailyPolls/:id', {
        templateUrl: function (param) {
          return 'views/polls/pollQuestionEdit.html'
        }
      })
      .when('/dailyPolls/new', {
        templateUrl: function (param) {
          return 'views/polls/pollQuestionEdit.html'
        }
      })
      .when('/moderation', {
        templateUrl: function (param) {
          return 'views/moderation/moderation.html'
        }
      })
      .when('/studioApps', {
        templateUrl: function (param) {
          return 'views/studioApps/studioApps.html'
        }
      })
      .when('/gds', {
        templateUrl: function (param) {
          return 'views/gds/gds.html'
        }
      })
      .when('/surveys', {
        templateUrl: function (param) {
          return 'views/surveys/surveys.html'
        }
      })
      .when('/surveys/:id', {
        templateUrl: function (param) {
          return 'views/surveys/surveyEdit.html'
        }
      })
      .when('/surveys/new', {
        templateUrl: function (param) {
          return 'views/surveys/surveyEdit.html'
        }
      })
      .when('/surveyQuestions', {
        templateUrl: function (param) {
          return 'views/surveys/surveyQuestions.html'
        }
      })
      .when('/surveyQuestions/:id', {
        templateUrl: function (param) {
          return 'views/surveys/surveyQuestionEdit.html'
        }
      })
      .when('/surveyQuestions/new', {
        templateUrl: function (param) {
          return 'views/surveys/surveyQuestionEdit.html'
        }
      })
      .when('/segmentTypes', {
        templateUrl: function (param) {
          return 'views/segments/segmentTypes.html'
        }
      })
      .when('/segmentTypes/:id', {
        templateUrl: function (param) {
          return 'views/segments/segmentTypeEdit.html'
        }
      })
      .when('/segmentTypes/new', {
        templateUrl: function (param) {
          return 'views/segments/segmentTypeEdit.html'
        }
      })
      .when('/segments', {
        templateUrl: function (param) {
          return 'views/segments/segments.html'
        }
      })
      .when('/segments/:id', {
        templateUrl: function (param) {
          return 'views/segments/segmentEdit.html'
        }
      })
      .when('/segments/new', {
        templateUrl: function (param) {
          return 'views/segments/segmentEdit.html'
        }
      })
      .when('/categories', {
        templateUrl: function (param) {
          return 'views/categories/categories.html'
        }
      })
      .when('/categories/:id', {
        templateUrl: function (param) {
          return 'views/categories/categoryEdit.html'
        }
      })
      .when('/categories/new', {
        templateUrl: function (param) {
          return 'views/categories/categoryEdit.html'
        }
      })
      .when('/collections', {
        templateUrl: function (param) {
          return 'views/collections/collections.html'
        }
      })
      .when('/communities', {
        templateUrl: function (param) {
          return 'views/communities/communities.html'
        }
      })
      .when('/communities/:id', {
        templateUrl: function (param) {
          return 'views/communities/communityEdit.html'
        }
      })
      .when('/communityConfig', {
        templateUrl: function (param) {
          return 'views/groups/communityConfig.html'
        }
      })
      .when('/communities/:id/:tab', {
        templateUrl: function (param) {
          return 'views/communities/communityEdit.html'
        }
      })
      .when('/communities/new', {
        templateUrl: function (param) {
          return 'views/communities/communityEdit.html'
        }
      })
      .when('/events', {
        templateUrl: function (param) {
          return 'views/events/events.html'
        }
      })
      .when('/events/:id', {
        templateUrl: function (param) {
          return 'views/events/eventEdit.html'
        }
      })
      .when('/events/:id/instance/:instance', {
        templateUrl: function (param) {
          return 'views/events/eventEdit.html'
        }
      })
      .when('/events/new', {
        templateUrl: function (param) {
          return 'views/events/eventEdit.html'
        }
      })
      .when('/eventTemplates', {
        templateUrl: function (param) {
          return 'views/events/eventTemplates.html'
        }
      })
      .when('/eventTemplates/:id', {
        templateUrl: function (param) {
          return 'views/events/eventTemplatesEdit.html'
        }
      })
      .when('/eventTemplates/new', {
        templateUrl: function (param) {
          return 'views/events/eventTemplatesEdit.html'
        }
      })
      .when('/eventTemplates', {
        templateUrl: function (param) {
          return 'views/events/eventTemplates.html'
        }
      })
      .when('/eventTemplates/:id', {
        templateUrl: function (param) {
          return 'views/events/eventTemplatesEdit.html'
        }
      })
      .when('/eventTemplates/new', {
        templateUrl: function (param) {
          return 'views/events/eventTemplatesEdit.html'
        }
      })
      .when('/volunteerings', {
        templateUrl: function (param) {
          return 'views/volunteering/volunteering.html'
        }
      })
      .when('/volunteerings/:id', {
        templateUrl: function (param) {
          return 'views/volunteering/volunteeringEdit.html'
        }
      })
      .when('/volunteerings/new', {
        templateUrl: function (param) {
          return 'views/volunteering/volunteeringEdit.html'
        }
      })
      .when('/services', {
        templateUrl: function (param) {
          return 'views/services/services.html'
        }
      })
      .when('/services/:id', {
        templateUrl: function (param) {
          return 'views/services/serviceEdit.html'
        }
      })
      .when('/services/new', {
        templateUrl: function (param) {
          return 'views/services/serviceEdit.html'
        }
      })
      .when('/activities', {
        templateUrl: function (param) {
          return 'views/activities/activities.html'
        }
      })
      .when('/activities/:id', {
        templateUrl: function (param) {
          return 'views/activities/activityEdit.html'
        }
      })
      .when('/users/:userId/activities/:id', {
        templateUrl: function (param) {
          return 'views/activities/userActivityEdit.html'
        }
      })
      .when('/activities/new', {
        templateUrl: function (param) {
          return 'views/activities/activityEdit.html'
        }
      })
      .when('/users/:userId/activities/new', {
        templateUrl: function (param) {
          return 'views/activities/userActivityEdit.html'
        }
      })
      .when('/certifications', {
        templateUrl: function (param) {
          return 'views/certifications/certifications.html'
        }
      })
      .when('/pushes', {
        templateUrl: function (param) {
          return 'views/push/pushes.html'
        }
      })
      .when('/pushes/new', {
        templateUrl: function (param) {
          return 'views/push/pushEdit.html'
        }
      })
      .when('/pushes/:id', {
        templateUrl: function (param) {
          return 'views/push/pushEdit.html'
        }
      })
      .when('/templates', {
        templateUrl: function (param) {
          return 'views/push/templates.html'
        }
      })
      .when('/communityActivities', {
        templateUrl: function (param) {
          return 'views/communityActivities/activities.html'
        }
      })
      .when('/communityActivities/:id', {
        templateUrl: function (param) {
          return 'views/communityActivities/activityEdit.html'
        }
      })
      .when('/communityActivities/new', {
        templateUrl: function (param) {
          return 'views/communityActivities/activityEdit.html'
        }
      })
      .when('/sprints', {
        templateUrl: function (param) {
          return 'views/sprints/sprints.html'
        }
      })
      .when('/whitelabel', {
        templateUrl: function (param) {
          return 'views/communities/whiteLabel.html'
        }
      })
      .when('/communityUsers', {
        templateUrl: function (param) {
          return 'views/users/community/users.html'
        }
      })
      .when('/communityUsers/create', {
        templateUrl: function (param) {
          return 'views/users/community/userEdit.html'
        }
      })
      .when('/communityUsers/:id', {
        templateUrl: function (param) {
          return 'views/users/community/userEdit.html'
        }
      })
      .when('/communityUsers/:userId/activities/:id', {
        templateUrl: function (param) {
          return 'views/users/community/communityUserActivityEdit.html'
        }
      })
      .when('/communityUsers/:userId/activities/new', {
        templateUrl: function (param) {
          return 'views/users/community/communityUserActivityEdit.html'
        }
      })
      .when('/uncodedBusinesses', {
        templateUrl: function (param) {
          return 'views/businesses/uncodedBusinesses.html'
        }
      })
      .when('/teams', {
        templateUrl: function (param) {
          return 'views/teams/teams.html'
        }
      })
      .when('/teams/:id', {
        templateUrl: function (param) {
          return 'views/teams/teamEdit.html'
        }
      })
      .when('/teams/new', {
        templateUrl: function (param) {
          return 'views/teams/teamEdit.html'
        }
      })
      .when('/challengeCalendar', {
        templateUrl: function (param) {
          return 'views/calendars/challengeCalendar.html'
        }
      })
      .when('/leaderboards', {
        templateUrl: function (param) {
          return 'views/leaderboards/leaderboards.html'
        }
      })
      .when('/utilities', {
        templateUrl: function (param) {
          return 'views/utilities/index.html'
        }
      })
      .when('/organizations', {
        templateUrl: function (param) {
          return 'views/organizations/organizations.html'
        }
      })
      .when('/schools', {
        templateUrl: function (param) {
          return 'views/schools/schools.html'
        }
      })
      .when('/legacy/events', {
        templateUrl: function (param) {
          return 'views/legacy/events/events.html'
        }
      })
      .when('/legacy/events/:id', {
        templateUrl: function (param) {
          return 'views/legacy/events/eventEdit.html'
        }
      })
      .when('/legacy/events/new', {
        templateUrl: function (param) {
          return 'views/legacy/events/eventEdit.html'
        }
      })
      .when('/groups', {
        templateUrl: function (param) {
          return 'views/groups/userGroups.html'
        }
      })
      .when('/groups/:id', {
        templateUrl: function (param) {
          return 'views/groups/groupEdit.html'
        }
      })
      .when('/groups/new', {
        templateUrl: function (param) {
          return 'views/groups/groupEdit.html'
        }
      })
      .when('/goals', {
        templateUrl: function (param) {
          return 'views/goals/goals.html'
        }
      })
      .when('/goals/:id', {
        templateUrl: function (param) {
          return 'views/goals/goalEdit.html'
        }
      })
      .when('/goals/new', {
        templateUrl: function (param) {
          return 'views/goals/goalEdit.html'
        }
      })
      .when('#', {
        templateUrl: 'views/index.html'
      })
      .otherwise({
        // redirectTo: '/'
      })
  }])
  .run(['$rootScope', 'BendAuth', 'BendPusher', 'pinesNotifications', '$bend', '$cookieStore', '$cookies', '$ngRedux', '$route', '$location', function ($rootScope, BendAuth, BendPusher, pinesNotifications, $bend, $cookieStore, $cookies, $ngRedux, $route, $location) {
    // Init globals.

    redux.inject($ngRedux)
    $rootScope.globals = {
      isAdmin: false
    }
    $rootScope.logoutNotify = null

    if (!$rootScope.itemsPerPage) {
      const saved = parseInt($cookies.itemsPerPage)
      $rootScope.itemsPerPage = saved || 20
    }
    $rootScope.setItemsPerPage = function (val) {
      if (val) {
        val = parseInt(val)
        $cookies.itemsPerPage = val
        $rootScope.itemsPerPage = val
      }
    }

    // Start auth check workflow.
    BendAuth.checkAuth()
    if (BendAuth.isLoggedIn()) {
      // BendPusher.init();
      const stateObj = $cookieStore.get(BendAuth.getActiveUser()._id + '_state')
      // console.log("cookie data", BendAuth.getActiveUser()._id, stateObj);
      if (stateObj) {
        $rootScope.globals.state = stateObj
      }
    }

    const original = $location.path
    $location.path = function (path, reload) {
      if (reload === false) {
        const lastRoute = $route.current
        var un = $rootScope.$on('$locationChangeSuccess', () => {
          $route.current = lastRoute
          un()
        })
      }
      return original.apply($location, [path])
    }

    setInterval(() => {
      const now = Date.now()

      if (BendAuth.isLoggedIn()) {
        $bend.LocalStorage.save('tokenLastUsedTime', now.valueOf())
      }
    }, 1000 * 60)
  }]);

(function () {
  const initInjector = angular.injector(['ng', 'bend', 'app.auth'])
  const BendAuthBootstrap = initInjector.get('BendAuthBootstrap')
  BendAuthBootstrap.bootstrapService()
})()
