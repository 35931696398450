import { Action } from '../actions/list'
import {
  UPDATE_EVENT_LIST
} from '../constants/types'

export interface Item {
  points: number,
  state: string,
  _id: string,
  _geoloc: Array<number>,
  enabled: boolean,
  collections: Array<string>,
  postalCode: string,
  certification: {
    _type: string,
    _collection: string
  },
  url: string,
  startsAt: string,
  categories: Array<string>
  _acl: {
    creator: {
      sprintRank: number,
      email: string,
      points: number,
      _id: string,
      enabled: boolean,
      sprintPoints: number,
      username: string,
      birthday: string,
      _acl: {
        creator: string
      },
      _bmd: {
        createdAt: number,
        _rev: number,
        updatedAt: number,
        _gen: string
      },
      previousRank: number,
      gender: string,
      name: string,
      allowNotifications: boolean
      communityTotals: number,
      defaultAvatar: string,
      previousSprintRank: number,
      isAdmin: boolean,
      segments: Array<string>,
      shareActivity: boolean,
      community: {
        _type: string,
        _id: string,
        _collection: string
      },
      rank: number,
      notifications: Array<string>
      birthdate: string
    },
    _bmd: {
      createdAt: number,
      _rev: number,
      updatedAt: number,
      _gen: string
    }
  },
  name: string,
  city: string,
  address1: string,
  community: {
    _type: string,
    _id: string,
    _collection: string
  },
  activityCount: number,
  description: string,
  times: [{
    from: string,
    date: string,
    until: string
  }],
  endsAt: string,
  speakers: Array<string>
}

export type Events = Array<Item>

const initialState: Events = []

export default (state: Events = initialState, action: Action): Events => {
  switch (action.type) {
    case UPDATE_EVENT_LIST:
      return action.events
    default:
      return state
  }
}
