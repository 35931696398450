'use strict'

import commonUtil from '../../../src/helpers/commonUtil'
import * as charts from '../../../src/features/charts'
import * as teams from '../../../src/features/teams'
import * as segments from '../../../src/features/segments'
import * as places from '../../../src/features/places'

import _ from 'lodash'
// import Highcharts from 'highcharts' // getting compiler error when using this so i'm loading highcharts into the app/index.html

angular.module('app.controllers')
  .controller('ChartEditController', ['$scope', '$location', 'BendAuth', '$ngRedux',
    async function ($scope, $location, BendAuth, $ngRedux) {
      $scope.commonUtil = commonUtil
      $scope.configureChartForm = configureChartForm

      $scope.urlParams = $location.$$path.split('/')[2]
      if ($scope.urlParams != 'new') {
        $scope.chartId = $scope.urlParams
        $scope.chart = await charts.helpers.get($scope.chartId)
      }
      $scope.isLoading = true

      $scope.dateErrorMsg = null

      const mapStateToThis = (state) => {
        return {
          activityData: state.charts.list.activity,
          activityViewData: state.charts.list.activityViews,
          activityTimes: state.charts.list.activityTimes,
          demographicData: state.charts.list.demographics,
          userData: state.charts.list.users,
          teams: state.teams.list.teams,
          segments: state.segments.list,
          places: state.places.list,
          fields: {
            community: state.charts.settings.community,
            team: state.charts.settings.team
          }
        }
      }

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)
      teams.helpers.init()
      segments.helpers.init()
      places.helpers.init().then(() => {
        if ($scope.chart.places && $scope.chart.places.length) {
          $scope.chart.places.map(place => {
            $scope.places.map((p) => {
              if (place._id === p._id) $scope.formData.places.push(p)
            })
          })
        }
      })

      $scope.formData = {
        teams: [],
        places: [],
        segments: [],
        chartType: {}
      }

      $scope.formControls = {
        openedStartDateSelector: false,
        openedEndDateSelector: false,
        upToDate: false
      }

      $scope.user = BendAuth.getActiveUser()
      $scope.chartObj = null

      if ($scope.chart) { // if chart exists
        $scope.isLoading = false

        // we probably don't actually need formData in this case
        // we call commonUtil.getNamesFromList([item], list) to get the name in the view
        // the way to use formData to store lists of entire objects is visible in volunteerEditController or others.
        // i don't like how this way requires calling a function in the HTML just to display the name
        if ($scope.chart.teams && $scope.chart.teams.length) $scope.formData.teams = $scope.chart.teams
        if ($scope.chart.segments && $scope.chart.segments.length) $scope.formData.segments = $scope.chart.segments
        configureChartForm()

        $scope.formData.chartType = commonUtil.chartTypes.all.filter(type => {
          return type.type === $scope.chart.type
        })[0]
        if (!$scope.chart.endDate) $scope.formControls.upToDate = true

        applyChangesOnScope($scope, () => {})
        //console.log($scope.chart.startDate)
      } else if ($scope.urlParams === 'new') {
        $scope.isLoading = false

        let today = new Date()
        let month = today.toString().slice(4, 7)
        let lastMonthMs = today.getTime() - commonUtil.monthDays[month] * 86400000 // days in month x 1 day in ms
        

        $scope.chart = {
          table: '',
          teams: [],
          segments: [],
          startDate: commonUtil.formatDateWithFormat(lastMonthMs * 1000000, 'YYYY-MM-DD'),
          endDate: commonUtil.formatDateWithFormat(today.getTime() * 1000000, 'YYYY-MM-DD'),
          dataLabels: true,
          lineColor: '#7cb5ec'
        }
      }

      $scope.moreOptions = false
      $scope.showDates = true
      $scope.demographic = false
      $scope.showTeams = true
      $scope.showEvergreen = true
      $scope.showTimeScale = true
      $scope.chartOptions = null

      $scope.getSegmentInfo = (segment) => {
        return segments.helpers.getSegmentInfo(segment)
      }

      // set variables to control ng-if render
      // set properties on chart.data that also control render
      function configureChartForm () {
        if ($scope.chart.evergreen) {
          $scope.showDates = false
          delete $scope.chart.endDate
          delete $scope.chart.startDate
        } else {
          $scope.showDates = true
          //console.log($scope.chart.startDate)
        }
        if ($scope.formControls.upToDate) delete $scope.chart.endDate
        if ($scope.chart.data && $scope.chart.data.text) {
          const chartType = $scope.chart.data.text
          let type
          if (
            chartType === 'User Activity by Type' ||
            chartType.indexOf('Demographic') !== -1 ||
            chartType.indexOf('Times') !== -1
          ) {
            type = 'pie'
          } else type = 'nonPie'
          $scope.selectedChartType = type
          // console.log('type', type)
          $scope.chartTypes = commonUtil.chartTypes[type]
          $scope.demographic = false
          $scope.timesChart = false
          // for use in ng-if
          if ($scope.chart.data.option && $scope.chart.data.option.code) {
            $scope.demographic = $scope.chart.data.option.code.indexOf('demographic') !== -1
            $scope.timesChart = $scope.chart.data.option.code.indexOf('times') !== -1
          }

          if ($scope.demographic || $scope.timesChart) {
            $scope.showTimeScale = false
          } else if (!$scope.chart.evergreen) {
            $scope.showTeams = true
            $scope.showDates = true
          }
          let {
            option, // top level option
            subOption, // selected subOption
            subOptions // list of subOptions
          } = $scope.chart.data

          if (!subOptions) {
            if (option && option.subOptions) $scope.chart.data.subOptions = option.subOptions
            else $scope.chart.data.subOptions = false
          }
          if ((option && option.countOptions) || (subOption && subOption.countOptions)) $scope.chart.data.countOptions = true
          else $scope.chart.data.countOptions = false
          if (option && option.subOptions === false) $scope.chart.data.subOptions = false

          $scope.chart.data.timeOptions = false
          if (subOption && subOption.timeOptions) $scope.chart.data.timeOptions = true
          if (option && option.timeOptions === false) $scope.chart.data.timeOptions = false

          // console.log('configure chart', $scope.chart.data)
        }
        // $scope.prepChartObj()
        applyChangesOnScope($scope, () => {})
        //console.log($scope.chart.startDate)
      }

      $scope.openDateWindow = function ($event, str) {
        $event.preventDefault()
        $event.stopPropagation()
        if (str === 'start') {
          $scope.formControls.openedStartDateSelector = true
        } else {
          $scope.formControls.openedEndDateSelector = true
        }
      }

      // form validation using angular ng-class
      $scope.validateForm = () => {
        const required = 'required'

        const title = _.get($scope.chart, 'title', null)
        const startDate = _.get($scope.formControls, 'startDate', null)
        const endDate = _.get($scope.formControls, 'endDate', null)

        // generic checks
        if (!title) $scope.titleReq = required
        if (!startDate) $scope.startDateReq = required
        if (!endDate) $scope.endDateReq = required

        if (
          !title ||
          !startDate ||
          !endDate
        ) {
          $scope.errorMsg = true
          return false
        }
        $scope.errorMsg = false
        return true
      }

      // used before save and before previewing chart
      $scope.prepChartObj = (saving = false) => {
        $scope.chart.teams = [] // clear previous teams to update for changes
        $scope.chart.segments = [] // clear previous segmetns to update for changes
        $scope.formData.teams.forEach(team => {
          if (typeof team === 'string') $scope.chart.teams.push(team)
          else $scope.chart.teams.push(team._id)
        })
        $scope.formData.segments.forEach(segment => {
          if (typeof segment === 'string') $scope.chart.segments.push(segment)
          else $scope.chart.segments.push(segment._id)
        })
        $scope.chart.places = []
        $scope.formData.places.map(place => {
          $scope.chart.places.push({ _id: place._id, name: place.name })
        })
        $scope.chart.teams = _.uniq($scope.chart.teams)
        if ($scope.chart.teams.length === 0) $scope.chart.teamsSeries = false
        if ($scope.formData.chartType) { // this used to be necessary before I was passing in default chart types in helpers
          delete $scope.formData.chartType.$$hashKey
          let parsedChartType = typeof $scope.formData.chartType === 'string' ? JSON.parse($scope.formData.chartType) : $scope.formData.chartType
          $scope.chart.type = parsedChartType.type // chart type was turning into JSON
        }
        // this is a fix for if the selected dates are far enough in the future that the browser might crash unexpectedly
        //console.log($scope.chart.startDate)
        let startDate = new Date($scope.chart.startDate).getTime()
        //console.log("0", $scope.chart.startDate, startDate)
        let endDate = new Date($scope.chart.endDate).getTime()
        let oneMonthInFuture = Date.now() + (31 * 86400000)
        if (startDate > oneMonthInFuture || endDate > oneMonthInFuture) {
          var correctedEndDate = new Date(Date.now() + (31 * 86400000))
          var correctedStartDate = new Date(Date.now() - (31 * 86400000))
          $scope.chart.endDate = correctedEndDate
          $scope.chart.startDate = correctedStartDate
          $scope.dateErrorMsg = 'Your selected date range was unacceptable, please accept this adjustment.' // :D
          //console.log($scope.chart.startDate)
        }
        // console.log('chart with options keys', $scope.chart.data)

        let {
          option,
          subOption,
          countOption,
          timeOption,
          table,
          code,
          chartType
        } = $scope.chart.data

        // let code = ''

        // endpoint prep
        if (option && option.endpoint) $scope.chart.data.endpoint = option.endpoint
        if (subOption && subOption.endpoint) $scope.chart.data.endpoint = subOption.endpoint
        if (timeOption && timeOption.endpoint) $scope.chart.data.endpoint = timeOption.endpoint
        // code prep
        if (option && option.code) code = code.concat(option.code) // rewrite it here
        if (subOption && subOption.code) code = code.concat(subOption.code) // add options here
        if (countOption && countOption.code) code = code.concat(countOption.code)
        if (timeOption && timeOption.code) code = code.concat(timeOption.code)
        $scope.chart.code = code.toLowerCase()
        // table prep
        if (option && option.table) table = option.table
        if (option && option.table) table = option.table
        if (subOption && subOption.table) table = subOption.table
        $scope.chart.table = table
        // chartType prep
        if (option && option.chartType) chartType = option.chartType
        $scope.chart.type = chartType
        // value prep
        if (option && option.value) $scope.chart.data.value = option.value

        if (saving) {
          if ($scope.chart.data) delete $scope.chart.data.$$hashKey
          if (option) {
            delete option.$$hashKey
          }
          if (subOption) {
            delete subOption.$$hashKey
          }
          if (countOption) {
            delete countOption.$$hashKey
          }
          if (timeOption) {
            delete timeOption.$$hashKey
          }
          // might need to keep these around so they are adaptable when updating
          // delete $scope.chart.data.options
          // delete $scope.chart.data.subOptions
          // delete $scope.chart.data.countOptions // potential options holder
          // delete $scope.chart.data.timeOptions
        }
      }

      $scope.previewChart = async () => {
        $scope.prepChartObj()
        // console.log('chartobj', $scope.chart)
        let chartObj = await charts.helpers.buildChart($scope.chart, $scope.teams, $scope.segments)
        if (typeof chartObj === 'string') $scope.errorMsg = chartObj
        else {
          $scope.errorMsg = false
          chartObj.chart.renderTo = 'highcharts-container'
          Highcharts.chart(chartObj)
        }
      }

      $scope.saveChartDo = async () => {
        // if (!$scope.validateForm()) return
        $scope.prepChartObj(true)
        var chartData = _.clone($scope.chart)
        if (!chartData.evergreen) {
          let startDate
          //console.log("1",$scope.chart.startDate, startDate)
          let endDate
          if (chartData.startDate) {
            chartData.startDate = $scope.chart.startDate
          }
          if (chartData.endDate) {
            chartData.endDate = $scope.chart.endDate
          }
        }

        if (chartData.segments && chartData.segments.length > 0) {
          chartData.segments = chartData.segments.map(segment => {
            if (typeof segment === 'object') return segment._id // for new items added to list
            else return segment // for ids coming in from db
          })
        }
        if (chartData.teams && chartData.teams.length > 0) {
          chartData.teams = chartData.teams.map(team => {
            if (typeof team === 'object') return team._id // for new items added to list
            else return team // for ids coming in from db
          })
        }
        chartData.teams = _.uniq(chartData.teams) // remove duplicates

        charts.helpers.update(chartData, function (err, chartRet) {
          $scope.isSaving = false
          if (err) {}
        })
        $location.path('/charts') // moved this call before API because save animation would go and page would never redirect. this still saves data
      }

      $scope.deleteChart = async (id, e) => {
        e.preventDefault()
        e.stopPropagation()
        const yeap = confirm('Deleting the chart is permanent and can not be undone.')
        if (!yeap) {
          return false
        }
        const response = await charts.helpers.delete(id)
        if (!response) {
          return false
        }
        applyChangesOnScope($scope, () => {
          $location.path('/charts')
        })
      }
    }
  ])
