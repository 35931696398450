import {
  UPDATE_CATEGORIES_SETTINGS,
  UPDATE_CATEGORIES_GROUPS,
  UPDATE_CATEGORIES_GROUPED_LIST,
  UPDATE_CATEGORIES_PAGE_SETTINGS,
  UPDATE_CATEGORIES_COMMUNITY_SETTINGS,
  RESET_CATEGORIES_SETTINGS
} from '../constants/types'
import { Settings } from '../reducers/settings'

export interface Action {
  type: string,
  categories: Settings,
  groups: any,
  groupedCategories: any,
  count: number,
  page: number,
  keyword: string,
  community: any
}

export const update = (count) => {
  return {
    type: UPDATE_CATEGORIES_SETTINGS,
    count
  }
}

export const updateGroups = (groups) => {
  return {
    type: UPDATE_CATEGORIES_GROUPS,
    groups
  }
}

export const updateGroupedCategories = (groupedCategories) => {
  return {
    type: UPDATE_CATEGORIES_GROUPED_LIST,
    groupedCategories
  }
}

export const pageUpdate = (page) => {
  return {
    type: UPDATE_CATEGORIES_PAGE_SETTINGS,
    page
  }
}

export const communityUpdate = (community) => {
  return {
    type: UPDATE_CATEGORIES_COMMUNITY_SETTINGS,
    community
  }
}

export const reset = () => {
  return {
    type: RESET_CATEGORIES_SETTINGS
  }
}
