import { UPDATE_NEWEST_USERS_LIST } from '../constants/types'
import { Action } from '../actions/list'

export interface Item {
  email: string,
  _id: string,
  enabled: boolean,
  username: string,
  _acl: {
    creator: string
  },
  _bmd: {
    createdAt: number,
    _rev: number,
    updatedAt: number,
    _gen: string
  },
  name: string,
  allowNotifications: boolean,
  defaultAvatar: string,
  code: string,
  shareActivity: boolean,
  community: {
    includeCommonActions: boolean,
    status: string,
    points: number,
    eventsEnabled: boolean,
    volunteerOpportunitiesEnabled: boolean,
    _id: string,
    location: string,
    actionsTitle: string,
    enabled: boolean,
    sprintPoints: number,
    collections: Array<number>,
    servicesDescription: string,
    placesTitle: string,
    welcomeEmailAction: string,
    welcomeEmailActionURL: string,
    servicesEnabled: boolean,
    hours: number,
    placesDescription: string,
    categories: Array<string>,
    servicesTitle: string,
    volunteerOpportunitiesDescription: string,
    _acl: {
      creator: string
    },
    sprintHours: number,
    includeCommonPlaces: boolean,
    eventsDescription: string,
    showCategoriesInSearch: boolean,
    _bmd: {
      createdAt: number,
      _rev: number,
      updatedAt: number,
      _gen: string
    },
    eventsTitle: string,
    includeCommonServices: boolean,
    name: string,
    volunteerOpportunitiesTitle: string,
    promptForRace: boolean,
    userCount: number,
    welcomeEmailSubject: string,
    code: string,
    placesEnabled: boolean,
    actionsEnabled: boolean,
    teamsEnabled: boolean,
    logo: {
      _type: string,
      _id: string
    },
    actionsDescription: string,
    welcomeEmailEnabled: boolean,
    includeCommonEvents: boolean,
    includeCommonVolunteerOpportunities: boolean,
    description: string,
    sprints: Array<string>,
    promptForZipCode: boolean,
    profileChartsEnabled: boolean
  },
  $$hashKey: string
}

export type List = Item[]

const initialState: List = []

export default (state: List = initialState, action: Action): List => {
  switch (action.type) {
    case UPDATE_NEWEST_USERS_LIST:
      return action.newestUsers
    default:
      return state
  }
}
