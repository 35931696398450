import {
  UPDATE_TAGS_LIST
} from '../constants/types'
import { Tags } from '../reducers/list'

export interface Action {
  type: string,
  tags: Tags
}

export const update = (tags: Tags): Action => {
  return {
    type: UPDATE_TAGS_LIST,
    tags
  }
}
