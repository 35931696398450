export const UPDATE_CHARTS_EXPORTS_LIST = 'UPDATE_CHARTS_EXPORTS_LIST'
export const UPDATE_CHARTS_LIST = 'UPDATE_CHARTS_LIST'
export const UPDATE_CHARTS_SETTINGS = 'UPDATE_CHARTS_SETTINGS'
export const UPDATE_CHARTS_PAGE_SETTINGS = 'UPDATE_CHARTS_PAGE_SETTINGS'
export const UPDATE_CHARTS_KEYWORD_SETTINGS = 'UPDATE_CHARTS_KEYWORD_SETTINGS'
export const UPDATE_CHARTS_COMMUNITY = 'UPDATE_CHARTS_COMMUNITY'
export const UPDATE_CHARTS_TYPE = 'UPDATE_CHARTS_TYPE'
export const UPDATE_CHARTS_TEAM = 'UPDATE_CHARTS_TEAM'
export const UPDATE_CHARTS_TEAMS = 'UPDATE_CHARTS_TEAMS'
export const RESET_CHARTS_SETTINGS = 'RESET_CHARTS_SETTINGS'
export const UPDATE_CHART_DEMOGRAPHIC_DATA = 'UPDATE_CHART_DEMOGRAPHIC_DATA'
export const UPDATE_CHART_ACTIVITY_DATA = 'UPDATE_CHART_ACTIVITY_DATA'
export const UPDATE_CHART_ACTIVITY_VIEWS_DATA = 'UPDATE_CHART_ACTIVITY_VIEWS_DATA'
export const UPDATE_CHART_ACTIVITY_TIMES_DATA = 'UPDATE_CHART_ACTIVITY_TIMES_DATA'
export const UPDATE_CHART_USER_DATA = 'UPDATE_CHART_USER_DATA'
