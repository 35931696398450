import { combineReducers } from 'redux'
import list, { Places } from './list'
import settings, { Settings } from './settings'

export interface CombineReducers {
  list: Places,
  settings: Settings
}

const rootReducer = combineReducers<CombineReducers>({
  list,
  settings
})

export default rootReducer
