import {
  UPDATE_PUSH_NOTIFICATIONS_LIST
} from '../constants/types'
import { PushNotifications } from '../reducers/list'

export interface Action {
  type: string,
  pushNotifications: PushNotifications
}

export const update = (pushNotifications: PushNotifications): Action => {
  return {
    type: UPDATE_PUSH_NOTIFICATIONS_LIST,
    pushNotifications
  }
}
