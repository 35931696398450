import { Action } from '../actions/list'
import {
  UPDATE_PUSH_NOTIFICATIONS_LIST
} from '../constants/types'

export interface Item {
  any
}

export type PushNotifications = Array<Item>

const initialState: PushNotifications = []

export default (state: PushNotifications = initialState, action: Action | any): PushNotifications => {
  switch (action.type) {
    case UPDATE_PUSH_NOTIFICATIONS_LIST:
      return action.pushNotifications
    default:
      return state
  }
}
