angular.module('theme.core.template_overrides', [])
  .config(['$provide', function ($provide) {
    'use strict'
    $provide.decorator('tabsetDirective', ['$delegate',
      function ($delegate) {
        $delegate[0].templateUrl = function (element, attr) {
          if (attr.tabPosition || attr.tabTheme) {
            if (attr.tabPosition && (attr.tabPosition === '\'bottom\'' || attr.tabPosition === 'bottom')) {
              return 'templates/themed-tabs-bottom.html'
            }
            return 'templates/themed-tabs.html'
          } else if (attr.panelTabs && attr.heading !== undefined) {
            return 'templates/panel-tabs.html'
          } else if (attr.panelTabs && attr.heading === undefined) {
            return 'templates/panel-tabs-without-heading.html'
          } else {
            return 'templates/themed-tabs.html'
          }
        }

        // "hacks" because 1.3.x broke scope extensions
        $delegate[0].$$isolateBindings.heading = {
          attrName: 'heading',
          mode: '@',
          optional: true
        }

        $delegate[0].$$isolateBindings.panelClass = {
          attrName: 'panelClass',
          mode: '@',
          optional: true
        }

        $delegate[0].$$isolateBindings.panelIcon = {
          attrName: 'panelIcon',
          mode: '@',
          optional: true
        }

        $delegate[0].$$isolateBindings.theme = {
          attrName: 'tabTheme',
          mode: '@',
          optional: true
        }

        $delegate[0].$$isolateBindings.position = {
          attrName: 'tabPosition',
          mode: '@',
          optional: true
        }

        $delegate[0].$$isolateBindings.draggable = {
          attrName: 'ngDrag',
          mode: '=',
          optional: true
        }

        return $delegate
      }]
    )
    $provide.decorator('progressbarDirective', ['$delegate',
      function ($delegate) {
        $delegate[0].templateUrl = function (element, attr) {
          if (attr.contextual && attr.contextual === 'true') {
            return 'templates/contextual-progressbar.html'
          }

          return 'template/progressbar/progressbar.html'
        }

        $delegate[0].$$isolateBindings.heading = {
          attrName: 'heading',
          mode: '@'
        }

        return $delegate
      }
    ])
  }])
  /* jshint ignore:start */
  .run(['$templateCache', function ($templateCache) {
    $templateCache.put('footerTemplate.html',
      '<div ng-show="showFooter" class="ng-grid-footer" ng-style="footerStyle()">\r' +
      '\n' +
      '    <div class="col-md-4" >\r' +
      '\n' +
      "        <div class=\"ngFooterTotalItems\" ng-class=\"{'ngNoMultiSelect': !multiSelect}\" >\r" +
      '\n' +
      '            <span class="ngLabel">{{i18n.ngTotalItemsLabel}} {{maxRows()}}</span><span ng-show="filterText.length > 0" class="ngLabel">({{i18n.ngShowingItemsLabel}} {{totalFilteredItemsLength()}})</span>\r' +
      '\n' +
      '        </div>\r' +
      '\n' +
      '        <div class="ngFooterSelectedItems" ng-show="multiSelect">\r' +
      '\n' +
      '            <span class="ngLabel">{{i18n.ngSelectedItemsLabel}} {{selectedItems.length}}</span>\r' +
      '\n' +
      '        </div>\r' +
      '\n' +
      '    </div>\r' +
      '\n' +
      "    <div class=\"col-md-4\" ng-show=\"enablePaging\" ng-class=\"{'ngNoMultiSelect': !multiSelect}\">\r" +
      '\n' +
      '            <label class="control-label ng-grid-pages center-block">{{i18n.ngPageSizeLabel}}\r' +
      '\n' +
      '               <select class="form-control input-sm" ng-model="pagingOptions.pageSize" >\r' +
      '\n' +
      '                      <option ng-repeat="size in pagingOptions.pageSizes">{{size}}</option>\r' +
      '\n' +
      '                </select>\r' +
      '\n' +
      '        </label>\r' +
      '\n' +
      '</div>\r' +
      '\n' +
      // "<pagination total-items=\"totalFilteredItemsLength()\" ng-model=\"pagingOptions.currentPage\"></pagination>" +
      // "\n" +
      '     <div class="col-md-4">\r' +
      '\n' +
      '        <div class="pull-right ng-grid-pagination">\r' +
      '\n' +
      '            <button type="button" class="btn btn-default btn-sm" ng-click="pageToFirst()" ng-disabled="cantPageBackward()" title="{{i18n.ngPagerFirstTitle}}"><i class="fa fa-angle-double-left"></i></button>\r' +
      '\n' +
      '            <button type="button" class="btn btn-default btn-sm" ng-click="pageBackward()" ng-disabled="cantPageBackward()" title="{{i18n.ngPagerPrevTitle}}"><i class="fa fa-angle-left"></i></button>\r' +
      '\n' +
      '            <label class="control-label">\r' +
      '\n' +
      '                   <input class="form-control input-sm" min="1" max="{{currentMaxPages}}" type="number" style="width:50px; height: 24px; margin-top: 1px; padding: 0 4px;" ng-model="pagingOptions.currentPage"/>\r' +
      '\n' +
      '            </label>\r' +
      '\n' +
      '            <span class="ngGridMaxPagesNumber" ng-show="maxPages() > 0">/ {{maxPages()}}</span>\r' +
      '\n' +
      '            <button type="button" class="btn btn-default btn-sm" ng-click="pageForward()" ng-disabled="cantPageForward()" title="{{i18n.ngPagerNextTitle}}"><i class="fa fa-angle-right"></i></button>\r' +
      '\n' +
      '            <button type="button" class="btn btn-default btn-sm" ng-click="pageToLast()" ng-disabled="cantPageToLast()" title="{{i18n.ngPagerLastTitle}}"><i class="fa fa-angle-double-right"></i></button>\r' +
      '\n' +
      '        </div>\r' +
      '\n' +
      '     </div>\r' +
      '\n' +
      '</div>\r' +
      '\n'
    )

    $templateCache.put('template/rating/rating.html',
      '<span ng-mouseleave="reset()" ng-keydown="onKeydown($event)" tabindex="0" role="slider" aria-valuemin="0" aria-valuemax="{{range.length}}" aria-valuenow="{{value}}">\n' +
      "    <i ng-repeat=\"r in range track by $index\" ng-mouseenter=\"enter($index + 1)\" ng-click=\"rate($index + 1)\" class=\"fa\" ng-class=\"$index < value && (r.stateOn || 'fa-star') || (r.stateOff || 'fa-star-o')\">\n" +
      "        <span class=\"sr-only\">({{ $index < value ? '*' : ' ' }})</span>\n" +
      '    </i>\n' +
      '</span>')

    $templateCache.put('bootstrap/match.tpl.html', "<div class=\"ui-select-match\" ng-hide=\"$select.open\" ng-disabled=\"$select.disabled\" ng-class=\"{\'btn-default-focus\':$select.focus}\"><button type=\"button\" class=\"form-control ui-select-toggle\" tabindex=\"-1\" ;=\"\" ng-disabled=\"$select.disabled\" ng-click=\"$select.activate()\"><span ng-show=\"$select.isEmpty()\" class=\"ui-select-placeholder text-muted\">{{$select.placeholder}}</span> <span ng-hide=\"$select.isEmpty()\" class=\"ui-select-match-text\" ng-class=\"{\'ui-select-allow-clear\': $select.allowClear && !$select.isEmpty()}\" ng-transclude=\"\"></span> <i class=\"caret caret-right\" ng-click=\"$select.toggle($event)\"></i></button> <button type=\"button\" class=\"ui-select-clear\" ng-if=\"$select.allowClear && !$select.isEmpty()\" ng-click=\"$select.select(undefined)\"><i class=\"glyphicon glyphicon-remove\"></i></button></div>")
  }])
  /* jshint ignore:end */
