import BendClient from '../BendClient'
import * as actions from './actions'
import redux from '../../../src/helpers/redux'

class Feature {

  private api: any

  constructor () {
    this.api = BendClient
  }

  public async init () {
    console.time("Tags Init");
    const tags = await this.api.getCommunityTags()
    console.timeEnd("Tags Init");

    redux.dispatch(
      actions.list.update(tags)
    )
  }

  public create (data) {
    return this.api.createCollection(data)
  }

  public update (data) {
    return this.api.updateCollection(data)
  }

  public delete (id) {
    return this.api.deleteCollection(id)
  }
}

export default new Feature()
