'use strict'

import * as segments from '.'
import exportHelper, { SEGMENTS_ORDER, SEGMENTS_HEADERS } from '../../helpers/export'
import moment from 'moment'

angular.module('app.controllers')
  .controller('SegmentController', ['$scope', '$location', '$routeParams', 'BendAuth', '$bootbox', '$rootScope', '$timeout', '$ngRedux',
    async function ($scope, $location, $routeParams, BendAuth, $bootbox, $rootScope, $timeout, $ngRedux) {
       $scope.isLoading = true

      const mapStateToThis = (state) => {
        return {
          segments: state.segments.list,
          collectionView: {
            itemsPerPage: state.segmentTypes.settings.itemsPerPage,
            isLoading: state.segmentTypes.settings.isLoading,
            totalItems: state.segmentTypes.settings.totalItems,
            currentPage: state.segmentTypes.settings.currentPage,
            numPages: state.segmentTypes.settings.numPages
          },
          filter: {
            community: state.segmentTypes.settings.community,
            team: state.segmentTypes.settings.team
          },
          fields: {
            community: state.segmentTypes.settings.community,
            teams: state.segmentTypes.settings.communityTeams
          }
        }
      }

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      $scope.user = BendAuth.getActiveUser()
      await segments.helpers.init()
      $scope.isLoading = false
      $scope.editSegment = (segmentType, $event) => {
        if ($event.metaKey || $event.ctrlKey) {
          window.open('#/segmentTypes/' + segmentType._id, '_blank')
        } else {
          return $location.path('/segments/' + segmentType._id)
        }
      }

      $scope.createSegment = ($event) => {
        if ($event.metaKey || $event.ctrlKey) {
          window.open('#/segmentTypes/new', '_blank')
        } else { return $location.path('/segments/new') }
      }
      
      $scope.export = {}
      $scope.export.columnOrder = SEGMENTS_ORDER
      $scope.export.headers = SEGMENTS_HEADERS
      $scope.export.exportDataAll = () => {
        return exportHelper.exportSegmentsData()
          .then(segments => {
            return segments.map(segment => {
              segment.segmentType = segment.segmentType._id
              segment.createdAt = moment(segment._bmd.createdAt / 1000000).format()
              segment.updatedAt = moment(segment._bmd.updatedAt / 1000000).format()
              return segment
            })
          })
          .catch(error => {
            console.log('Error Exporting', error)
          })
      }
    }])
