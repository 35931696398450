export const UPDATE_LEADERBOARD_LIST = 'UPDATE_LEADERBOARD_LIST'
export const UPDATE_LEADERBOARD_SETTINGS = 'UPDATE_LEADERBOARD_SETTINGS'
export const UPDATE_LEADERBOARDS_USERS_POINTS_LIST = 'UPDATE_LEADERBOARDS_USERS_POINTS_LIST'
export const UPDATE_LEADERBOARDS_USERS_IDS_LIST = 'UPDATE_LEADERBOARDS_USERS_IDS_LIST'
export const UPDATE_LEADERBOARDS_COMMUNITY_SETTINGS = 'UPDATE_LEADERBOARDS_COMMUNITY_SETTINGS'
export const RESET_LEADERBOARDS_SETTINGS = 'RESET_LEADERBOARDS_SETTINGS'
export const UPDATE_LEADERBOARDS_CATEGORY_SETTINGS = 'UPDATE_LEADERBOARDS_CATEGORY_SETTINGS'
export const UPDATE_LEADERBOARDS_PAGE_SETTINGS = 'UPDATE_LEADERBOARDS_PAGE_SETTINGS'
export const UPDATE_LEADERBOARDS_TOTAL_POINTS_SETTINGS = 'UPDATE_LEADERBOARDS_TOTAL_POINTS_SETTINGS'
export const UPDATE_LEADERBOARDS_SPRINT_SETTINGS = 'UPDATE_LEADERBOARDS_SPRINT_SETTINGS'
export const UPDATE_LEADERBOARDS_ITEMS_PER_PAGE = 'UPDATE_LEADERBOARDS_ITEMS_PER_PAGE'
export const UPDATE_LEADERBOARDS_HAS_MORE = 'UPDATE_LEADERBOARDS_HAS_MORE'
