import { TOGGLE_TASK_FORM } from '../constants/types'

export interface Action {
  type: string
}

export const toggleShowForm = (): Action => {
  return {
    type: TOGGLE_TASK_FORM
  }
}
