import { UPDATE_RESPONSES_LIST, UPDATE_SURVEY_RESPONSES_LIST, UPDATE_SURVEYS_LIST } from '../constants/types'
import { Responses, SurveyResponses, Surveys } from '../reducers/list'

export interface Action {
  type: string,
  surveys: Surveys,
  responses: Responses,
  surveyResponses: SurveyResponses
}

export const update = (surveys: Surveys) => { // these are actually poll questions, not survey objects
  return {
    type: UPDATE_SURVEYS_LIST,
    surveys
  }
}

export const updateResponses = (responses: Responses) => {
  return {
    type: UPDATE_RESPONSES_LIST,
    responses
  }
}

export const updateSurveyResponses = (surveyResponses: SurveyResponses) => {
  return {
    type: UPDATE_SURVEY_RESPONSES_LIST,
    surveyResponses
  }
}
