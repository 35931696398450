'use strict'

import * as leaderboards from '../../../src/features/leaderboards'
import * as communities from '../../../src/features/communities'
import * as categories from '../../../src/features/categories'
import * as sprints from '../../../src/features/sprints'
import { getActiveUser } from '../../../src/helpers'

angular.module('app.controllers')
  .controller('LeaderboardController', ['$scope', '$location', 'CommonUtil', '$ngRedux',
    async function ($scope, $location, CommonUtil, $ngRedux) {
      $scope.CommonUtil = CommonUtil
      $scope.user = getActiveUser()

      const mapStateToThis = (state) => {
        return {
          communityList: state.communities.list,
          categories: state.categories.list,
          categoriesGroups: state.categories.settings.groupedCategories,
          sprintList: state.sprints.list,
          userList: state.leaderboards.list,
          userPoints: state.leaderboards.usersPoints,
          userIdList: state.leaderboards.usersIds,
          currentPage: state.leaderboards.settings.currentPage,
          isMore: state.leaderboards.settings.more,
          totalPoints: state.leaderboards.settings.totalPoints,
          filter: {
            community: state.leaderboards.settings.community,
            category: state.leaderboards.settings.category,
            sprint: state.leaderboards.settings.sprint
          }
        }
      }

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      if ($scope.userList.length === 0) {
        $scope.isLoading = true
      }

      await communities.helpers.init()

      if ($scope.user.communityAdmin) {
        leaderboards.helpers.selectCommunity(_.get($scope, 'user.community._id'))
      } else {
        // super admins fix
        const communityIsSelected = Boolean($scope.filter.community._id)
        if (!communityIsSelected) {
          const id = _.get($scope.communityList, '[0]._id')
          leaderboards.helpers.selectCommunity(id)
        }
      }

      leaderboards.helpers.paginateToPage(1)
      categories.helpers.getList().then(cats => {
        $scope.getList()
      })

      const communityId = $scope.user.communityAdmin
        ? $scope.user.community._id
        : $scope.filter.community._id
      sprints.helpers.initInputList(communityId).then(() => {
        if (typeof $scope.filter.sprint === 'object' && !$scope.filter.sprint._id) {
          leaderboards.helpers.selectSprint(null)
        }
      })

      $scope.reset = async () => {
        $scope.isLoading = true
        await leaderboards.helpers.reset()
        // await categories.helpers.getCommunityGroupedList()
        $scope.isLoading = false
      }

      $scope.getList = async () => {
        await leaderboards.helpers.initPageList()
        $scope.isLoading = false
      }

      $scope.handleCommunitySelect = async () => {
        $scope.isLoading = true
        const id = _.get($scope.filter, 'community._id')
        leaderboards.helpers.selectCommunity(id)
        categories.helpers.getCommunityGroupedList(id)
        leaderboards.helpers.selectCategory(null)
        sprints.helpers.initInputList($scope.filter.community._id)
        leaderboards.helpers.selectSprint(null)
        await $scope.getList()
      }

      $scope.handleCategorySelect = async (id) => {
        $scope.isLoading = true
        leaderboards.helpers.selectCategory(id)
        // leaderboards.helpers.selectSprint(null)
        $scope.getList()
      }

      $scope.handleSprintSelect = () => {
        $scope.isLoading = true
        const id = $scope.filter.sprint && $scope.filter.sprint._id
        leaderboards.helpers.selectSprint(id)
        $scope.getList()
      }
      $scope.selectClient = function () {
        const id = $scope.filter.community && $scope.filter.community._id
        leaderboards.helpers.selectCommunity(id)
        $scope.getList()
      }

      $scope.loadMore = async () => {
        $scope.isLoading = true
        await leaderboards.helpers.readMoreOnPage()
        $scope.isLoading = false
      }

      $scope.onPageChange = function () {
        $scope.getList()
      }

      $scope.handleItemClick = function (item, $event) {
        if ($event.metaKey || $event.ctrlKey) {
          window.open('#/users/' + item._id, '_blank')
        } else {
          return $location.path('/users/' + item._id)
        }
      }
    }])
