import * as _ from 'lodash'
import * as moment from 'moment-timezone'

import * as actions from './actions'
import { getActiveUser } from '../../../src/helpers'
import BendClient, { BendTable } from '../BendClient'
import commonUtil from '../../helpers/commonUtil'
import redux from '../../../src/helpers/redux'


// FLOW HERE =>
// buildChart(chartObj, teams, segments) =>
// getChartData(chartObj) => might hit endpoint if endpoint key exists, fills redux with data
// prepChartObj(chartObj, teams, segments) // returns chart object that will be usable by Highcharts
class Feature {

  private api: any
  private api2: any

  constructor () {
    this.api = BendClient
    this.api2 = BendTable
  }

  public async init () {
    const charts = await this.api2.getList(BendTable.Tables.CHART)    
    redux.dispatch(
      actions.list.update(charts)
    )
    return charts
  }

  // make chart object that will be displayed using Highcharts
  public prepChartObj (chartConfig, communityTeams, communitySegments) {

    let otherKeys: any = {
      allowDecimals: false,
      plotOptions: {
        dataLabels: {
          enabled: chartConfig.dataLabels
        },
        enableMouseTracking: true
      },
      series: [{ // default for single series charts
        name: chartConfig.data.text,
        color: chartConfig.lineColor,
        data: []
      }],
      xAxis: {
        categories: []
      },
      yAxis: {
        title: {
          text: chartConfig.yLabel || chartConfig.data.yAxisLabel
        }
      },
      legend: {
        enabled: false
      }
    }
    if (chartConfig.teams.length > 0 && chartConfig.teamsSeries) {
      otherKeys.series = []
      let index = 0
      chartConfig.teams.forEach(teamId => {
        if (communityTeams && communityTeams.length) {
          communityTeams.forEach(team => {
            if (team._id === teamId) {
              let exists = otherKeys.series.find(series => series.name === team.name)
              if (!exists) {
                otherKeys.series.push({
                  name: team.name,
                  _id: team._id,
                  color: colorOptions[index],
                  data: []
                })
                index++
              }
            }
          })
        }
      })
      otherKeys.legend.enabled = true
    }

    if (chartConfig.segments && chartConfig.segments.length > 0 && chartConfig.segmentsSeries) {
      otherKeys.series = []
      let index = 0
      chartConfig.segments.forEach(segmentId => {
        communitySegments.forEach(segment => {
          if (segment._id === segmentId) {
            let exists = otherKeys.series.find(series => series.name === segment.name)
            if (!exists) {
              otherKeys.series.push({
                name: segment.name,
                _id: segment._id,
                color: colorOptions[index],
                data: []
              })
              index++
            }
          }
        })
      })
      otherKeys.legend.enabled = true
    }

    let pieKeys = {
      chart: {
        type: 'pie'
      },
      plotOptions: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          style: {
            color: 'black'
          }
        }
      },
      series: [{
        name: '',
        colorByPoint: true,
        data: [
          // example data for pie chart
          // {
          //   name: 'Chrome',
          //   y: 61.41,
          //   sliced: true,
          //   selected: true
          // }, {
          //   name: 'Internet Explorer',
          //   y: 11.84
          // }
        ]
      }]
    }
    if (chartConfig.data.query) pieKeys.series[0].name = chartConfig.data.query.table
    else pieKeys.series[0].name = chartConfig.data.text
    let chartOptions = chartConfig.type === 'pie' ? pieKeys : otherKeys
    let { startDate, endDate } = chartConfig
    let dateStr = 'All data'
    if (chartConfig.evergreen) dateStr = 'Last month\'s data'
    if (startDate && endDate && startDate !== 'Invalid date' && endDate !== 'Invalid date') {
      dateStr = `${moment.utc(startDate).toString().split(' ').slice(1, 4).join(' ')} through ${moment.utc(endDate).toString().split(' ').slice(1, 4).join(' ')}`
    }
    if (startDate && !endDate) dateStr = `${moment.utc(startDate).toString().split(' ').slice(1, 4).join(' ')} through Today`
    let chart: any = { // this is unfortunate to have such a long function but so it is
      chart: {
        plotBackgroundColor: chartConfig.backgroundColor,
        plotBorderWidth: null,
        plotShadow: null,
        height: chartConfig.height || 400,
        type: chartConfig.data.type || 'line'
      },
      title: {
        text: chartConfig.title
      },
      subtitle: {
        text: chartConfig.subtitle || dateStr
      },
      ...chartOptions
    }
    let { option, subOption } = chartConfig.data
    if (option && option.type) {
      chart.chart.type = chartConfig.data.option.type
    }
    if (subOption && subOption.type) {
      chart.chart.type = chartConfig.data.subOption.type
    }

    return chart
  }

  public prepWeekStr (date) {
    let periodStart = moment.utc(date).startOf('week')
    let periodEnd = moment.utc(date).startOf('week').add(1, 'week')
    let timeStr = `${periodStart.toString().split(' ').slice(1, 3).join(' ')} to ${periodEnd.toString().split(' ').slice(1, 3).join(' ')}`
    return timeStr
  }

  public prepTimeStrings (chartConfig, chart) {
    // make same number of data buckets in each series
    // timeStr becomes a string describing the time intervals in the timescale that is set for the chart (defaulted to weeks)
    // these are stored in the xAxis.categories to be displayed on the chart
    let { timeScale = 'Weeks', startDate, endDate } = chartConfig
    let dateObjStart
    let dateObjEnd
    let monthEnd
    if (
      chartConfig.evergreen ||
      (!startDate && !endDate) // if it was deleted from trying a different type of chart
    ) { // default dates to last month
      dateObjEnd = moment.utc()
      dateObjStart = moment.utc().subtract(1, 'month')
    } else {
      dateObjStart = moment.utc(startDate)
      dateObjEnd = moment.utc(endDate)
    }
    let bucketNum = 0
    if (timeScale === 'Days') bucketNum = ((dateObjEnd.valueOf() - dateObjStart.valueOf()) / 86400000)
    // below -- 30.42 is avg days/month in non-leap year, 30.5 in leap year
    if (timeScale === 'Months') bucketNum = ((dateObjEnd.valueOf() - dateObjStart.valueOf()) / (86400000 * 30.42))
    if (timeScale === 'Weeks') {
      dateObjStart = dateObjStart.startOf('week')
      bucketNum = (dateObjEnd.valueOf() - dateObjStart.valueOf()) / (7 * 86400000)
    }
    bucketNum = Math.ceil(bucketNum)

    // loop to fill up x axis categories with correct date strings
    for (let i = 0; i < bucketNum; i++) {
      let timeStr = ''
      let periodEnd
      if (timeScale === 'Weeks') {
        timeStr = this.prepWeekStr(dateObjStart.valueOf())
        dateObjStart.add(1, 'week')
      }
      if (timeScale === 'Days') {
        timeStr = dateObjStart.toString().split(' ').slice(1, 3).join(' ')
        dateObjStart.add(1, 'day')
      }
      if (timeScale === 'Months') {
        timeStr = dateObjStart.toString().split(' ').slice(1, 2).join(' ')
        dateObjStart.add(commonUtil.monthDays[timeStr], 'day') // days in month x 1 day in ms
      }
      chart.xAxis.categories.push(timeStr)
      chart.series.map(series => series.data.push(0)) // prep data buckets
      // dateObjStart = periodEnd // reset for next week not necessary because the dateObjStart keeps getting modified and used directly
    }
    return chart
  }

  // structures chart obj for display as bar
  public prepDataForBarChart (chart: any, data: any) {
    data.map(obj => {
      if (obj.summary) { // Activity data has a summary key
        let text = obj.summary
        if (chart.xAxis.categories.indexOf(text) === -1) {
          chart.xAxis.categories.push(text)
          chart.series[0].data.push(0) // match data buckets to number of locations
        }
        let index = chart.xAxis.categories.indexOf(text)
        chart.series[0].data[index] += 1 // count for every time a place is visited
      } else if (obj.activity) { // activityView data has activity key
        let text = obj.activity.name
        if (chart.xAxis.categories.indexOf(text) === -1) {
          chart.xAxis.categories.push(text)
          chart.series[0].data.push(0) // match data buckets to number of locations
        }
        let index = chart.xAxis.categories.indexOf(text)
        chart.series[0].data[index] += 1 // count for every time a place is visited
      }
    })
    // using Array.sort on names array based on values in series[0].data was not working as expected
    // this is simpler anyway though a lot of mapping
    // build up array of final names and values, sort that based on values, replace chart arrays with new names and values
    let orderedData: any = []
    chart.xAxis.categories.map(cat => {
      orderedData.push({ name: cat })
    })
    chart.series[0].data.map((point, index) => {
      orderedData[index].value = point
    })
    chart.xAxis.categories = []
    chart.series[0].data = []
    orderedData = orderedData.sort((a, b) => {
      return b.value - a.value
    })
    orderedData.map(obj => {
      chart.xAxis.categories.push(obj.name)
      chart.series[0].data.push(obj.value)
    })

    // GET PIE AND LINE CHARTS TO FIND WORK WITH PLACES TOO
    return chart
  }

  // build chart obj to be displayed as pie chart
  public prepDataForPieChart (data, chartConfig, chart) {
    let chartCode = chartConfig.code
    if (chartCode.indexOf('demographic') === -1 && chartCode.indexOf('times') === -1) {
      let activityTypes: Array<string> = []
      data.forEach(activity => {
        if (activityTypes.indexOf(activity.type) === -1) activityTypes.push(activity.type)
      })
      activityTypes.forEach(type => { // prep series.data array for pie chart
        chart.series[0].data.push({
          name: type,
          y: 0
        })
      })
      // count occurrences for each activity type
      data.forEach(activity => {
        chart.series[0].data.forEach(chartData => {
          if (chartData.name === activity.type) {
            chartData.y += 1
          }
        })
      })
    } else if (chartCode.indexOf('demographic') !== -1) { // chart_ communityDemographics endpoint
      if (!chartConfig.data.subOption) chartConfig.data.subOption = { code: 'age' }
      let demographicDataSet = chartConfig.data.subOption.code
      chart.series[0].data = data[demographicDataSet]
    } else if (chartCode.indexOf('times') !== -1) { // chart_activityTimes endpoint
      let { code } = chartConfig.data.option
      let { timeOption } = chartConfig.data
      if (!timeOption) timeOption = { text: 'Time of day' }
      // console.log('key',key)
      // console.log('option',option)
      if (data[code] && data[code][timeOption.text]) {
        chart.series[0].data = data[code][timeOption.text]
      } else if (code.indexOf('all activity') !== -1) {
        chart.series[0].data = data['allActivity'][timeOption.text]
      } else {
        return 'error: data of this type does not exist'
      }
    }
    chart.chart.type = 'pie'
    return chart
  }

  // command function, returns Highcharts chart obj to controller
  public async buildChart (chartConfig: any, communityTeams, communitySegments) {
    chartConfig.userTimezoneOffsetMin = new Date().getTimezoneOffset()
    const data = await this.getChartData(chartConfig)
    if (!data.allActivity && data.length === 0) return 'Error: No data found matching your criteria, please adjust date ranges or chart options and try again'
    let chart = this.prepChartObj(chartConfig, communityTeams, communitySegments)
    // chart = this.xAxisConfig(chart)
    let { timeScale = 'Weeks' } = chartConfig
    // below are types that apply to multiple chartConfigs
    let pieTypes = [
      'demographic',
      'times'
    ]
    // let barTypes = [
    //   'app views'
    // ]
    let lineOrBarChart = true
    // let barChartOnly
    let chartCode = chartConfig.code.toLowerCase()
    let chartStyle = chartConfig.data.option.chartType
    pieTypes.forEach(type => {
      if (chartCode.indexOf(type) !== -1) lineOrBarChart = false
    })
    // barTypes.forEach(type => {
    //   if (chartCode.indexOf(type) !== -1) barChartOnly = true
    // })

    // line and bar charts plot against day/month/week units, pie charts don't
    // if (barChartOnly || chart.chart.type === 'bar') {
    if (chart.chart.type === 'bar') {
      chart = this.prepDataForBarChart(chart, data)
      // let barPreppedData = ['chart_appViews'] // endpoint prepped the data already
      // let { endpoint } = chartConfig.data
      // if (endpoint && barPreppedData.indexOf(endpoint) !== -1) {
      //   chart.xAxis.categories = data.xAxis.categories
      //   chart.series[0].data = data.seriesData
      // } else {
      // }
    } else if (!lineOrBarChart || chart.chart.type === 'pie') { // make a pie chart
      chart = this.prepDataForPieChart(data, chartConfig, chart)
    } else { // make a line or bar chart
      chart = this.prepTimeStrings(chartConfig, chart)
      // later we should add sprints
      // if (timeScale === 'Sprints') timeStr = dateObj.toString().slice(4,7)
      // push months into xAxis cats
      // add points to month bucket
      let activeUsersOverTime: any = {}
      let allActiveUsers: any = []
      let dataNotDisplaying: any = {}
      let rejectedData: any = {}
      // let matchingData: any = []
      data.map(item => {
        if (
          chartConfig.table === 'activity' &&
          chartConfig.code.indexOf(item.type) === -1
        ) {
          if (!rejectedData[item.type]) rejectedData[item.type] = 0
          rejectedData[item.type] += 1
          return
        }
        // matchingData.push(item)
        let dateObj = moment.utc(item._bmd.createdAt / 1000000)
        let timeStr = ''
        if (timeScale === 'Days') timeStr = dateObj.toString().split(' ').slice(1, 3).join(' ')
        if (timeScale === 'Months') timeStr = dateObj.toString().split(' ').slice(1, 2).join(' ')
        if (timeScale === 'Weeks') timeStr = this.prepWeekStr(item._bmd.createdAt / 1000000)

        let timeStrIndex = chart.xAxis.categories.indexOf(timeStr)
        if (item.points < 0) console.log('error negative points', item)
        // for active user chart
        // if (chartCode.indexOf('active users') !== -1) {
        //   if (activeUsersOverTime[timeStr]) {
        //     if (activeUsersOverTime[timeStr].indexOf(item.user._id) === -1) activeUsersOverTime[timeStr].push(item.user._id)
        //   } else activeUsersOverTime[timeStr] = []
        //   if (chartCode.indexOf('active users') === -1) chart.series[0].data[timeStrIndex] = activeUsersOverTime[timeStr].length
        // }
        // for all other charts
        if (chartConfig.teams && chartConfig.teams.length > 0 && chartConfig.teamsSeries) {
          item.teams.forEach(teamId => {
            let seriesIndex = chart.series.findIndex(teamData => teamId === teamData._id)
            if (seriesIndex !== -1) {
              // count points if
              if (chartCode.indexOf('all user points') !== -1) chart.series[seriesIndex].data[timeStrIndex] += item.points || 0
              // count occurrences if
              if (chartCode.indexOf('all user points') === -1) chart.series[seriesIndex].data[timeStrIndex] += 1
            }
          })
        } else if (chartConfig.segments && chartConfig.segments.length > 0 && chartConfig.segmentsSeries) { // THIS NEEDS TO BE ADAPTED FOR SEGMENTS
          item.segments.forEach(segmentId => {
            let seriesIndex = chart.series.findIndex(segmentData => segmentId === segmentData._id)
            if (seriesIndex !== -1) {
              // count points if
              if (chartCode.indexOf('all user points') !== -1) chart.series[seriesIndex].data[timeStrIndex] += item.points || 0
              // count occurrences if
              if (chartCode.indexOf('all user points') === -1) chart.series[seriesIndex].data[timeStrIndex] += 1
            }
          })
        } else {
          if (timeStrIndex === -1) {
            if (!dataNotDisplaying[timeStr]) dataNotDisplaying[timeStr] = 0
            dataNotDisplaying[timeStr] += 1
          }
          let { option, countOption } = chartConfig.data
          if (!countOption) countOption = { code: 'runningTotal' }
          if (countOption.code === 'runningTotal') {
            if (!option.value || option.value === item.type) { // if it is false, count all activities
              for (let i = timeStrIndex; i < (chart.series[0].data.length); i++) { // we need to count upward instead, count current user in all forward buckets
                if (chartCode.indexOf('points') !== -1) chart.series[0].data[i] += item.points || 0
                if (chartCode.indexOf('points') === -1) chart.series[0].data[i] += 1
              }
            }
          } else if (countOption.code === 'weekByWeek') {
            if (chartCode.indexOf('points') !== -1) chart.series[0].data[timeStrIndex] += item.points || 0 // the only points counter
            if (chartCode.indexOf('points') === -1) chart.series[0].data[timeStrIndex] += 1 // count occurrences
          } else if (chartCode.indexOf('active users') !== -1) {
            if (allActiveUsers.indexOf(item.user._id) === -1) {
              allActiveUsers.push(item.user._id) // might not use this, but it would show total active users over period
            }
            // THIS ISN'T WORKING, SHOULD ONLY BE 3 USERS ON MILKCRATE OVER LAST MONTH TODO MICHAEL
            // MUST BE COUNTING EACH ACTIVITY SOMEHOW
            if (!activeUsersOverTime[timeStr]) activeUsersOverTime[timeStr] = []
            if (activeUsersOverTime[timeStr].indexOf(item.user._id) === -1) activeUsersOverTime[timeStr].push(item.user._id)
            chart.series[0].data[timeStrIndex] += activeUsersOverTime[timeStr].length // count occurrences
          } else {
            for (let i = timeStrIndex; i < (chart.series[0].data.length); i++) { // we need to count upward instead, count current user in all forward buckets
              chart.series[0].data[i] += 1
            }
          }
        }
      })
      if (Object.keys(dataNotDisplaying) && Object.keys(dataNotDisplaying).length !== 0) console.log(chart.title.text, 'data not displaying:', dataNotDisplaying)
      if (Object.keys(rejectedData) && Object.keys(rejectedData).length !== 0) console.log('data not matching params in ' + chart.title.text, rejectedData)

    }
    return chart
  }

  // gets data from endpoint (for activity times of day and days of week)
  // or pulls data directly
  public async getChartData (chart) {
    const user = getActiveUser()
    // const user = await getActiveUser()
    const chartCode = chart.code
    const queryTable = chart.data.table
    let data = await this.api.getChartData(chart, user.community._id)
    if (chartCode.indexOf('times') !== -1) {
      redux.dispatch(
        actions.list.updateActivityTimesData(data)
      )
    } else if (chartCode.indexOf('views') !== -1) {
      redux.dispatch(
        actions.list.updateActivityViewData(data)
      )
    } else if (chartCode.indexOf('demographic') !== -1) {
      redux.dispatch(
        actions.list.updateDemographicData(data) // actually this is already processed into highchart pie ready data
      )
    } else if (queryTable) {
      if (queryTable === 'user') {
        redux.dispatch(
          actions.list.updateUserData(data)
        )
      } else if (queryTable === 'activity') {
        redux.dispatch(
          actions.list.updateActivityData(data)
        )
      }
    }
    return data
  }
  public paginateToPage (page) {
    redux.dispatch(
      actions.settings.pageUpdate(page)
    )
  }

  public handleSearchKeyword (keyword) {
    redux.dispatch(
      actions.settings.searchKeywordUpdate(keyword)
    )
  }

  public selectCommunity (community) {
    redux.dispatch(
      actions.settings.selectCommunity(community)
    )
  }

  public selectType (type) {
    redux.dispatch(
      actions.settings.selectType(type)
    )
  }

  public selectTeam (team) {
    redux.dispatch(
      actions.settings.selectTeam(team)
    )
  }

  public getCommunityList () {
    return this.api.getCommunityList()
  }

  public getCategoryListWithImages () {
    return this.api.getCategoryListWithImages()
  }

  public get (id) {
    return this.api2.get(BendTable.Tables.CHART, id)
  }

  public update (charts) {
    return this.api2.update(BendTable.Tables.CHART, charts)
  }

  public delete (id) {
    return this.api2.delete(BendTable.Tables.CHART, id)
  }

  public upload (file, callback, ext, progressCallback) {
    return this.api.upload(file, callback, ext, progressCallback)
  }

  public getFile (refObj, callback) {
    return this.api.getFile(refObj, callback)
  }

  public async refreshActivities (settings: {
    keyword: string,
    type: string
  }) {
    // eliminate the following block once we figure out whats wrong with this.user in bendClient
    const communityId = getActiveUser().community.id
    let activities = await this.api.refreshChartActivities(settings)
    activities.filter(activity => {
      return activity.community._id === communityId
    })
    return activities
    // return this.api.refreshChartActivities(settings) // re-enable this once issue is fixed
  }

}

const colorOptions = ['#e6194B', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#42d4f4', '#f032e6', '#bfef45', '#fabebe', '#469990', '#e6beff', '#9A6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#a9a9a9', '#ffffff']

export default new Feature()
