import { Action } from '../actions/list'
import {
  UPDATE_EVENT_TEMPLATES_LIST
} from '../constants/types'

export interface Item {
  any
}

export type EventTemplates = Array<Item>

const initialState: EventTemplates = []

export default (state: EventTemplates = initialState, action: Action | any): EventTemplates => {
  switch (action.type) {
    case UPDATE_EVENT_TEMPLATES_LIST:
      return action.eventTemplates
    default:
      return state
  }
}
