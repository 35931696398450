'use strict'

import * as schools from '../../../src/features/schools'

angular.module('app.controllers')
  .controller('SchoolEditController', ['$scope', '$modalInstance', '$ngRedux', 'school',
    function ($scope, $modalInstance, $ngRedux, school) {
      const mapStateToThis = (state) => ({
        schoolsList: state.schools.list
      })

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      if (school) $scope.model = { name: school.name }

      $scope.proceed = function () {
        $scope.errorMessage = null
        const model = {...$scope.model}

        const exists = $scope.schoolsList.find((org) => org.name == model.name && (!school || (school && school._id != org._id)))
        if (exists) {
          $scope.errorMessage = 'School already exists'
        } else {
          const result = (school
            ? schools.helpers.update({...school, name: model.name})
            : schools.helpers.create(model)
          )

          result.then((result) => {
            if (result && result.error) {
              $scope.errorMessage = result.error
            } else {
              $modalInstance.close(result)
            }
          })
        }
      }

      $scope.cancel = function () {
        $modalInstance.dismiss('cancel')
      }
    }])
