/*
 *
 * https://github.com/fatlinesofcode/ngDraggable
 */
/* jshint ignore:start */
/* eslint no-mixed-operators: 0 */
angular.module('ngDraggable', [])
  .directive('ngDrag', ['$rootScope', '$parse', '$document', '$window', function ($rootScope, $parse, $document, $window) {
    return {
      restrict: 'A',
      scope: true,
      link: function (scope, element, attrs) {
        scope.value = attrs.ngDrag
        var _centerAnchor = false
        var offset
        var _mx
        var _my
        var _tx
        var _ty
        var _mrx
        var _mry
        var _hasTouch = ('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch
        var _pressEvents = 'touchstart mousedown'
        var _moveEvents = 'touchmove mousemove'
        var _releaseEvents = 'touchend mouseup'

        // to identify the element in order to prevent getting superflous events when a single element has both drag and drop directives on it.
        var _myid = scope.$id
        var _data = null

        var _dragEnabled = false

        var _pressTimer = null

        var onDragSuccessCallback = $parse(attrs.ngDragSuccess) || null

        var initialize = function () {
          element.attr('draggable', 'false') // prevent native drag
          toggleListeners(true)
        }

        // this same func is in ngDrop, it needs to be DRYed up but don't know if its
        // worth writing a service (or equivalent) for one function
        var _privoffset = function (docElem) {
          var box = {
            top: 0,
            left: 0
          }
          if (typeof docElem[0].getBoundingClientRect !== 'undefined') {
            box = docElem[0].getBoundingClientRect()
          }
          return {
            top: box.top + $window.pageYOffset - docElem[0].clientTop,
            left: box.left + $window.pageXOffset - docElem[0].clientLeft
          }
        }

        var toggleListeners = function (enable) {
          if (!enable) return
          // add listeners.

          scope.$on('$destroy', onDestroy)
          scope.$watch(attrs.ngDrag, onEnableChange)
          scope.$watch(attrs.ngCenterAnchor, onCenterAnchor)
          scope.$watch(attrs.ngDragData, onDragDataChange)
          element.on(_pressEvents, onpress)
          if (!_hasTouch && element[0].nodeName.toLowerCase() == 'img') {
            element.on('mousedown', function () {
              return false
            }) // prevent native drag for images
          }
        }
        var onDestroy = function (enable) {
          toggleListeners(false)
        }
        var onDragDataChange = function (newVal, oldVal) {
          _data = newVal
        }
        var onEnableChange = function (newVal, oldVal) {
          _dragEnabled = (newVal)
        }
        var onCenterAnchor = function (newVal, oldVal) {
          if (angular.isDefined(newVal)) { _centerAnchor = (newVal || 'true') }
        }

        // var isClickableElement = function (evt) {
        //   return (
        //     angular.isDefined(angular.element(evt.target).attr('ng-click')) || angular.isDefined(angular.element(evt.target).attr('ng-dblclick')) || angular.isDefined(angular.element(evt.target).attr('ng-cancel-drag'))
        //   )
        // }

        // var isDragHandle = function (evt) {
        //     return !(angular.element(evt.target).closest("[ng-drag-handle]").length > 0);
        // }
        var isDragHandle = function (evt) {
          var attr = angular.element(evt.target).attr('ng-drag-handle')
          return (typeof attr !== typeof undefined && attr !== false)
        }

        /*
         * When the element is clicked start the drag behaviour
         * On touch devices as a small delay so as not to prevent native window scrolling
         */
        var onpress = function (evt) {
          if (!_dragEnabled || !isDragHandle(evt)) return
          if (evt.type == 'mousedown' && evt.button !== 0) return
          element.css('width', element.width() + 'px')
          // disable drag on clickable element
          // if (isClickableElement(evt) || !isDragHandle(evt)) {
          // return;
          // }

          if (_hasTouch) {
            cancelPress()
            _pressTimer = setTimeout(function () {
              cancelPress()
              onlongpress(evt)
            }, 100)
            $document.on(_moveEvents, cancelPress)
            $document.on(_releaseEvents, cancelPress)
          } else {
            onlongpress(evt)
          }
        }
        var cancelPress = function () {
          clearTimeout(_pressTimer)
          $document.off(_moveEvents, cancelPress)
          $document.off(_releaseEvents, cancelPress)
        }
        var onlongpress = function (evt) {
          if (!_dragEnabled) return
          evt.preventDefault()
          element.addClass('dragging')
          offset = _privoffset(element)

          element.centerX = element[0].offsetWidth / 2
          element.centerY = element[0].offsetHeight / 2

          _mx = (evt.pageX || evt.touches[0].pageX)
          _my = (evt.pageY || evt.touches[0].pageY)
          _mrx = _mx - offset.left
          _mry = _my - offset.top
          if (_centerAnchor) {
            _tx = _mx - element.centerX - $window.pageXOffset
            _ty = _my - element.centerY - $window.pageYOffset
          } else {
            _tx = _mx - _mrx - $window.pageXOffset
            _ty = _my - _mry - $window.pageYOffset
          }

          $document.on(_moveEvents, onmove)
          $document.on(_releaseEvents, onrelease)
          $rootScope.$broadcast('draggable:start', {
            x: _mx,
            y: _my,
            tx: _tx,
            ty: _ty,
            event: evt,
            element: element,
            data: _data
          })
        }

        var onmove = function (evt) {
          if (!_dragEnabled) return
          evt.preventDefault()

          _mx = (evt.pageX || evt.touches[0].pageX)
          _my = (evt.pageY || evt.touches[0].pageY)

          if (_centerAnchor) {
            _tx = _mx - element.centerX - $window.pageXOffset
            _ty = _my - element.centerY - $window.pageYOffset
          } else {
            _tx = _mx - _mrx - $window.pageXOffset
            _ty = _my - _mry - $window.pageYOffset
          }

          moveElement(_tx, _ty)

          $rootScope.$broadcast('draggable:move', {
            x: _mx,
            y: _my,
            tx: _tx,
            ty: _ty,
            event: evt,
            element: element,
            data: _data,
            uid: _myid
          })
        }

        var onrelease = function (evt) {
          if (!_dragEnabled) { return }
          evt.preventDefault()
          $rootScope.$broadcast('draggable:end', {
            x: _mx,
            y: _my,
            tx: _tx,
            ty: _ty,
            event: evt,
            element: element,
            data: _data,
            callback: onDragComplete,
            uid: _myid
          })
          element.removeClass('dragging')
          reset()
          $document.off(_moveEvents, onmove)
          $document.off(_releaseEvents, onrelease)
        }

        var onDragComplete = function (evt) {
          if (!onDragSuccessCallback) return

          scope.$apply(function () {
            onDragSuccessCallback(scope, {
              $data: _data,
              $event: evt
            })
          })
        }

        var reset = function () {
          element.css({
            left: '',
            top: '',
            position: '',
            'z-index': '',
            margin: ''
          })
        }

        var moveElement = function (x, y) {
          element.css({
            left: (x + 'px'),
            top: (y + 'px'),
            position: 'fixed',
            'z-index': 99999
            //, margin: '0'  don't monkey with the margin,
          })
        }
        initialize()
      }
    }
  }])

  .directive('ngDrop', ['$parse', '$timeout', '$window', function ($parse, $timeout, $window) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        scope.value = attrs.ngDrop

        var _myid = scope.$id

        var _dropEnabled = false

        var onDropCallback = ($parse(attrs.ngDropSuccess) == angular.noop) ? function (scope, obj) {
          element.find('.drag-placeholder').after(obj.$event.element)
          obj.$event.element.show()
        } : $parse(attrs.ngDropSuccess)

        var initialize = function () {
          toggleListeners(true)
        }

        var toggleListeners = function (enable) {
          // remove listeners

          if (!enable) return
          // add listeners.
          attrs.$observe('ngDrop', onEnableChange)
          scope.$on('$destroy', onDestroy)
          // scope.$watch(attrs.uiDraggable, onDraggableChange);
          scope.$on('draggable:start', onDragStart)
          scope.$on('draggable:move', onDragMove)
          scope.$on('draggable:end', onDragEnd)
        }

        // this same func is in ngDrag, it needs to be DRYed up but don't know if its
        // worth writing a service (or equivalent) for one function
        var _privoffset = function (docElem) {
          var box = {
            top: 0,
            left: 0
          }
          if (typeof docElem[0].getBoundingClientRect !== 'undefined') {
            box = docElem[0].getBoundingClientRect()
          }
          return {
            top: box.top + $window.pageYOffset - docElem[0].clientTop,
            left: box.left + $window.pageXOffset - docElem[0].clientLeft
          }
        }

        var onDestroy = function (enable) {
          toggleListeners(false)
        }
        var onEnableChange = function (newVal, oldVal) {
          _dropEnabled = scope.$eval(newVal)
        }
        var onDragStart = function (evt, obj) {
          if (!_dropEnabled) return
          $('.drag-placeholder').show()
          if (isTouching(obj.x, obj.y, obj.element)) {
            obj.element.css({
              position: 'fixed',
              top: obj.ty + 'px',
              left: obj.tx + 'px'
            })
            if (element.find('.drag-placeholder').length < 1) {
              var dragPlaceholder = $('<div class="drag-placeholder"></div>').css({
                height: obj.element.innerHeight() + 'px',
                width: '100%'
              })
              $(obj.element).after(dragPlaceholder)
            }
          }
        }
        var onDragMove = function (evt, obj) {
          if (!_dropEnabled) return
          if (isTouching(obj.x, obj.y, obj.element)) {
            if (element.find('.drag-placeholder').length < 1) {
              var dragPlaceholder = $('<div class="drag-placeholder"></div>').css({
                height: obj.element.innerHeight() + 'px',
                width: '100%'
              })
              $(element).append(dragPlaceholder)
            }
            element.find('[ng-drag]:not(.dragging)').each(function () {
              if (hitTestElement(obj.x, obj.y, $(this))) {
                // $(this).css('border', '1px solid blue');
                if (($(this).offset().top + $(this).height() / 2) < obj.y) {
                  // $(this).css('border', '1px solid red');
                  // element.find('.drag-placeholder').before(this);
                  $(this).after(element.find('.drag-placeholder'))
                } else {
                  // element.find('.drag-placeholder').after(this);
                  $(this).before(element.find('.drag-placeholder'))
                }
                // else
              }
            })
          } else {
            if (element.find('.drag-placeholder').length > 0) {
              element.find('.drag-placeholder').remove()
            }
          }
        }

        var onDragEnd = function (evt, obj) {
          // don't listen to drop events if this is the element being dragged
          if (!_dropEnabled || _myid === obj.uid) return
          $('.drag-placeholder').hide()
          obj.element.css('width', '')
          if (isTouching(obj.x, obj.y, obj.element)) {
            obj.element.hide()
            // call the ngDraggable ngDragSuccess element callback
            if (obj.callback) {
              obj.callback(obj)
            }

            $timeout(function () {
              onDropCallback(scope, {
                $data: obj.data,
                $event: obj
              })
              $('.drag-placeholder').remove()
            })
          }
          updateDragStyles(false, obj.element)
        }

        var isTouching = function (mouseX, mouseY, dragElement) {
          var touching = hitTest(mouseX, mouseY)
          updateDragStyles(touching, dragElement)
          return touching
        }

        var updateDragStyles = function (touching, dragElement) {
          if (touching) {
            element.addClass('drag-enter')
            dragElement.addClass('drag-over')
          } else {
            element.removeClass('drag-enter')
            dragElement.removeClass('drag-over')
          }
        }

        var hitTest = function (x, y) {
          var bounds = _privoffset(element)
          bounds.right = bounds.left + element[0].offsetWidth
          bounds.bottom = bounds.top + element[0].offsetHeight
          return x >= bounds.left && x <= bounds.right && y <= bounds.bottom && y >= bounds.top
        }

        var hitTestElement = function (x, y, element) {
          var bounds = _privoffset(element)
          bounds.right = bounds.left + element[0].offsetWidth
          bounds.bottom = bounds.top + element[0].offsetHeight
          return x >= bounds.left && x <= bounds.right && y <= bounds.bottom && y >= bounds.top
        }

        initialize()
      }
    }
  }])
  .directive('ngDragClone', ['$parse', '$timeout', function ($parse, $timeout) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        var img
        var _allowClone = true
        scope.clonedData = {}
        var initialize = function () {
          img = element.find('img')
          element.attr('draggable', 'false')
          img.attr('draggable', 'false')
          reset()
          toggleListeners(true)
        }

        var toggleListeners = function (enable) {
          // remove listeners

          if (!enable) return
          // add listeners.
          scope.$on('draggable:start', onDragStart)
          scope.$on('draggable:move', onDragMove)
          scope.$on('draggable:end', onDragEnd)
          preventContextMenu()
        }
        var preventContextMenu = function () {
          //  element.off('mousedown touchstart touchmove touchend touchcancel', absorbEvent_);
          img.off('mousedown touchstart touchmove touchend touchcancel', absorbEvent_)
          //  element.on('mousedown touchstart touchmove touchend touchcancel', absorbEvent_);
          img.on('mousedown touchstart touchmove touchend touchcancel', absorbEvent_)
        }
        var onDragStart = function (evt, obj, elm) {
          _allowClone = true
          if (angular.isDefined(obj.data.allowClone)) {
            _allowClone = obj.data.allowClone
          }
          if (_allowClone) {
            scope.$apply(function () {
              scope.clonedData = obj.data
            })
            element.css('width', obj.element[0].offsetWidth)
            element.css('height', obj.element[0].offsetHeight)

            moveElement(obj.tx, obj.ty)
          }
        }
        var onDragMove = function (evt, obj) {
          if (_allowClone) {
            moveElement(obj.tx, obj.ty)
          }
        }
        var onDragEnd = function (evt, obj) {
          // moveElement(obj.tx,obj.ty);
          if (_allowClone) {
            reset()
          }
        }

        var reset = function () {
          element.css({
            left: 0,
            top: 0,
            position: 'fixed',
            'z-index': -1,
            visibility: 'hidden'
          })
        }
        var moveElement = function (x, y) {
          element.css({
            left: (x + 'px'),
            top: (y + 'px'),
            position: 'fixed',
            'z-index': 99999,
            'visibility': 'visible'
            //, margin: '0'  don't monkey with the margin,
          })
        }

        var absorbEvent_ = function (event) {
          var e = event.originalEvent
          e.preventDefault && e.preventDefault()
          e.stopPropagation && e.stopPropagation()
          e.cancelBubble = true
          e.returnValue = false
          return false
        }

        initialize()
      }
    }
  }])
  .directive('ngPreventDrag', ['$parse', '$timeout', function ($parse, $timeout) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        var initialize = function () {
          element.attr('draggable', 'false')
          toggleListeners(true)
        }

        var toggleListeners = function (enable) {
          // remove listeners

          if (!enable) return
          // add listeners.
          element.on('mousedown touchstart touchmove touchend touchcancel', absorbEvent_)
        }

        var absorbEvent_ = function (event) {
          var e = event.originalEvent
          e.preventDefault && e.preventDefault()
          e.stopPropagation && e.stopPropagation()
          e.cancelBubble = true
          e.returnValue = false
          return false
        }

        initialize()
      }
    }
  }])
  .directive('ngCancelDrag', [function () {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        element.find('*').attr('ng-cancel-drag', 'ng-cancel-drag')
      }
    }
  }])
/* jshint ignore:end */
