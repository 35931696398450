import {
  UPDATE_SEGMENT_TYPES_LIST
} from '../constants/types'
import { SegmentTypes } from '../reducers/list'

export interface Action {
  type: string,
  segmentTypes: SegmentTypes
}

export const update = (segmentTypes: SegmentTypes): Action => { // these are actually poll questions, not segmentType objects
  return {
    type: UPDATE_SEGMENT_TYPES_LIST,
    segmentTypes
  }
}
