class Redux {
  private redux: any
  constructor () {
    this.redux = {}
  }

  public inject (dependency) {
    this.redux = dependency
  }

  public dispatch (args) {
    return this.redux.dispatch(args)
  }

  public getState () {
    return this.redux.getState()
  }

}

export default new Redux()
