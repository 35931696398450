'use strict'

import * as userActivities from '../../../src/features/userActivities'
import { getActiveUser } from '../../../src/helpers'
import genericHelper from '../../../src/helpers/GenericHelper'

angular.module('app.controllers')
  .controller('userActivitiesTabController', ['$scope', '$location', '$bootbox', '$modal', '$ngRedux',
    function ($scope, $location, $bootbox, $modal, $ngRedux) {
      $scope.genericHelper =  genericHelper
      $scope.isLoading = true
      $scope.activityList = []
      $scope.imageMap = {}
      $scope.collectionView = {
        searchTerm: '',
        itemsPerPage: 20,
        isLoading: true,
        totalItems: 0,
        currentPage: 1,
        numPages: 0
      }

      $scope.user = getActiveUser()

      $scope.communityList = [{
        name: 'All client',
        _id: null
      }]
      $scope.filter = {
        community: ''
      }

      var type = ''
      var activityId = ''
      if ($location.path().indexOf('businesses') != -1) {
        type = 'business'
      } else if ($location.path().indexOf('actions') != -1) {
        type = 'action'
      } else if ($location.path().indexOf('events') != -1) {
        type = 'event'
      } else if ($location.path().indexOf('volunteerings') != -1) {
        type = 'volunteer_opportunity'
      } else if ($location.path().indexOf('customActivities') != -1) {
        type = 'customActivity'
      }

      $scope.type = type

      activityId = $location.path().substr($location.path().lastIndexOf('/') + 1)


      userActivities.helpers.getCommunityList().then((rets) => {
        $scope.communityList = $scope.communityList.concat(rets)
      })

      $scope.loadList = async () => {
        //console.log("async load list")
        $scope.isLoading = true

        const settings = _.clone($scope.collectionView)
        settings.activityId = activityId
        settings.type = type
        settings.communityId = _.get($scope, 'filter.community._id')

        const response = await userActivities.helpers.fetchList(settings)
        //console.log(response)

        $scope.activityList = response
        $scope.isLoading = false

        $scope.imageMap = {}

        let imageIds = []
        _.each(response, function (o) {
          if (o.user.avatar) {
            if (imageIds.indexOf(o.user.avatar._id) == -1) {
              imageIds.push(o.user.avatar._id)
            }
          }
        })

        userActivities.helpers.getImages({ id: imageIds }).then(function (rets) {
          _.each(rets, function (o) {
            $scope.imageMap[o._id] = o
          })
          _.map($scope.activityList, function (o) {
            if (o.user.avatar) {
              o.user.avatar = $scope.imageMap[o.user.avatar._id]
            }
          })
        })

        const count = await userActivities.helpers.listNavigation(settings)
        $scope.collectionView.totalItems = count
        $scope.collectionView.numPages = Math.ceil($scope.collectionView.totalItems / $scope.collectionView.itemsPerPage)
      }


      $scope.loadList()

      $scope.onSearch = function () {
        $scope.loadList()
      }

      $scope.onPageChange = function () {
        $scope.loadList()
      }

      $scope.deleteActivity = function (id, $ev) {
        $ev.stopPropagation()
        $ev.preventDefault()
        var msg = 'Deleting the activity is permanent and can not be undone.'
        $bootbox.confirm(msg, function (result) {
          if (result) {
            userActivities.helpers.delete(id).then(function (ret) {
              if (ret) {
                for (var i = 0; i < $scope.activityList.length; i++) {
                  if ($scope.activityList[i]._id == id) {
                    applyChangesOnScope($scope, function () {
                      $scope.activityList.splice(i, 1)
                    })
                  }
                }
              }
            })
          }
        })
      }

      $scope.editActivity = function (activity, $event) {
        $modal.open({
          templateUrl: 'activityDetail.html',
          backdrop: 'static',
          controller: function ($scope, $modalInstance, activity) {
            $scope.activity = _.clone(activity)
            $scope.cancel = function () {
              $modalInstance.dismiss('cancel')
            }
            $scope.close = function () {
              $modalInstance.dismiss('cancel')
            }
          },
          resolve: {
            activity: function () {
              return activity
            }
          }
        })
      }
    }])
