import { Action } from '../actions/list'
import {
  UPDATE_SCHOOLS_LIST
} from '../constants/types'

export interface Item {
  any
}

export type Schools = Array<Item>

const initialState: Schools = []

export default (state: Schools = initialState, action: Action | any): Schools => {
  switch (action.type) {
    case UPDATE_SCHOOLS_LIST:
      return action.schools
    default:
      return state
  }
}
