import * as _ from 'lodash'
import * as actions from './actions'
import BendClient, { BendTable } from '../BendClient'
import redux from '../../../src/helpers/redux'
import commonUtil from '../../helpers/commonUtil'

class Feature {

  private api: any
  private api2: any

  constructor() {
    this.api = BendClient
    this.api2 = BendTable
  }

  public init() {
    this.fetchComments()
  }

  public async fetchComments() {
    let settings = null;
    try {
      settings = redux.getState().comments.settings
      let comments = await this.api.fetchComments(settings)
      comments = await this.setAvatars(comments)
      this.updateCommentList(comments)
    } catch (error) {
      console.log('Error Fetching Comments', error, settings)
    }
  }

  public async getCommentsFromEndpoint(str, data) {
    return this.api2.callEndpoint(str, data)
  }

  public async setSubject(path) {
    let subjectId;
    if (path.indexOf('/instance/') !== -1) {
      //Recurrent event has different path
      const firstPart = path.split('/instance/')[0];
      subjectId = firstPart.substr(firstPart.lastIndexOf('/') + 1)
    } else {
      subjectId = path.substr(path.lastIndexOf('/') + 1)
    }
    let subjectType
    if (path.indexOf('businesses') !== -1) {
      subjectType = 'business'
    } else if (path.indexOf('actions') !== -1) {
      subjectType = 'action'
    } else if (path.indexOf('events') !== -1) {
      subjectType = 'event'
    } else if (path.indexOf('volunteerings') !== -1) {
      subjectType = 'volunteer_opportunity'
    } else if (path.indexOf('customActivities') !== -1) {
      subjectType = 'customActivity'
    }

    const subject = await this.api.fetchSubjectByType(subjectId, subjectType)
    redux.dispatch(
      actions.settings.updateSubject(subject, subjectType)
    )
  }

  public resetList() {
    this.updateCommentList([])
    redux.dispatch(
      actions.settings.updateSubject({}, '')
    )
  }

  public sortComments(sorter, comments) {
    if (sorter === 'user') {
      comments = comments.sort((a, b) => {
        a = a.user.name
        b = b.user.name
        // Get last name, if present
        if (a && a.includes(' ')) a = a.trim().split(' ').pop()
        if (b && b.includes(' ')) b = b.trim().split(' ').pop()
        // Sorts undefined to the end, if present
        return (a || b) ? (!a ? 1 : !b ? -1 : a.localeCompare(b)) : 0
      })
    }
    if (sorter === 'date') {
      comments = comments.sort((a, b) => {
        a = a._bmd.createdAt
        b = b._bmd.createdAt
        return b - a
      })
    }
    redux.dispatch(
      actions.list.update(comments)
    )
  }

  public async delete(id) {
    let comments = redux.getState().comments.list
    const deleted = await this.api2.delete(BendTable.Tables.COMMENT, id)
    if (deleted) {
      const index = comments.findIndex(comment => deleted._id === comment._id)
      comments.splice(index, 1)
      this.updateCommentList(comments)
    }
  }

  // Private

  private updateCommentList(comments) {
    redux.dispatch(
      actions.list.update(comments)
    )
    redux.dispatch(
      actions.settings.updateCount(comments.length)
    )
  }

  private async setAvatars(comments) {
    let imageMap = {}
    let imageIds: string[] = []

    comments.forEach(comment => {
      if (comment.user.avatar && imageIds.indexOf(comment.user.avatar._id) === -1) {
        imageIds.push(comment.user.avatar._id)
      }
    })

    const files = await this.getImages({ id: imageIds })

    files.forEach(file => imageMap[file._id] = file)
    comments.forEach(comment => {
      if (comment.user.avatar) {
        comment.user.avatar = imageMap[comment.user.avatar._id]
      }
    })
    return comments
  }

  private getImages(settings: { id: string[] }) {
    return this.api.fetchImagesById(settings)
  }
}

export default new Feature()
