export const UPDATE_SEGMENT_TYPES_LIST = 'UPDATE_SEGMENT_TYPES_LIST'
export const UPDATE_SEGMENT_TYPE_OBJECTS = 'UPDATE_SEGMENT_TYPE_OBJECTS'
export const GET_SEGMENT_TYPE_NAMES = 'GET_SEGMENT_TYPE_NAMES'
export const UPDATE_SEGMENT_TYPES_SETTINGS = 'UPDATE_SEGMENT_TYPES_SETTINGS'
export const UPDATE_SEGMENT_TYPE_RESPONSES = 'UPDATE_SEGMENT_TYPE_RESPONSES'
export const UPDATE_SEGMENT_TYPES_PAGE_SETTINGS = 'UPDATE_SEGMENT_TYPES_PAGE_SETTINGS'
export const UPDATE_SEGMENT_TYPES_COMMUNITY_SETTINGS = 'UPDATE_SEGMENT_TYPES_COMMUNITY_SETTINGS'
export const UPDATE_SEGMENT_TYPES_TEAMS_SETTINGS = 'UPDATE_SEGMENT_TYPES_TEAMS_SETTINGS'
export const UPDATE_SEGMENT_TYPES_TEAM_SETTINGS = 'UPDATE_SEGMENT_TYPES_TEAM_SETTINGS'
export const RESET_SEGMENT_TYPES_SETTINGS = 'RESET_SEGMENT_TYPES_SETTINGS'
