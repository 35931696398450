/* globals _: true, async: true, applyChangesOnScope: true */

import * as tasks from '../../../src/features/tasks'
import { getActiveUser } from '../../../src/helpers'

angular.module('app.controllers')
  .controller('TasksController', ['$scope', '$bend', 'BendAuth', 'CommonUtil', '$ngRedux',
    function ($scope, $bend, BendAuth, CommonUtil, $ngRedux) {
      'use strict'

      $scope.newTaskTitle = ''
      $scope.newTaskLabelText = ''

      $scope.titleInput = ''

      var user = getActiveUser()
      var communityId = user.community.admin ? user.community.id : $scope.communityId

      const mapStateToThis = (state) => {
        return {
          tasks: state.tasks.list.incomplete,
          tasksComplete: state.tasks.list.complete,
          showForm: state.tasks.form.showForm
        }
      }

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      tasks.helpers.init(communityId)

      $scope.handleStartAddNew = () => {
        tasks.helpers.startAddNew()
      }

      $scope.handleStartEdit = (item) => {
        $scope.titleInput = item.title
        tasks.helpers.startEditing({ id: item._id })
      }

      $scope.handleCancelEdit = (e, item) => {
        tasks.helpers.cancelEdit(e, {
          id: item._id,
          title: item.title
        })
      }

      $scope.remove = (scope, item) => {
        tasks.helpers.deleteItem({ id: item._id })
      }

      $scope.complete = (scope, item) => {
        tasks.helpers.complete(item)
      }

      $scope.incomplete = function (item, index) {
        tasks.helpers.incomplete(item, index)
      }

      $scope.newItem = async (title, label, color) => {
        if (title === '') {
          return
        }

        const newTaskData = {
          title,
          label,
          color
        }
        await tasks.helpers.addingNew(communityId, newTaskData)
        $scope.newTaskTitle = ''
        $scope.newTaskLabelText = ''
      }

      $scope.cancelAdd = function ($event) {
        if ($event.keyCode !== 27) {
          return
        }
        tasks.helpers.cancelingAdd()
        this.newTaskTitle = ''
        this.newTaskLabelText = ''
      }

      $scope.doneEditing = function (item) {
        tasks.helpers.saving({
          ...item,
          title: this.titleInput
        })
      }
    }])
