import { Action } from '../actions/list'
import {
  UPDATE_STUDIO_APPS_LIST
} from '../constants/types'

export interface Item {
  any
}

export type StudioApps = Array<any>

const initialState: StudioApps = []

export default (state: StudioApps = initialState, action: Action | any): StudioApps => {
  switch (action.type) {
    case UPDATE_STUDIO_APPS_LIST:
      return action.studioApps
    default:
      return state
  }
}
