import BendClient, { BendTable } from '../BendClient/index'
import * as actions from './actions'
import redux from '../../helpers/redux'
import * as Bend from '../../lib/bend-1.1.8'

class Feature {

    private api: any
    private api2: any

    constructor() {
        this.api = BendClient
        this.api2 = BendTable
    }

    public async init() {
        const { list } = redux.getState().organizations
        const organizationsList = await this.api.fetchOrganizations()
        if (list.length === 0) this.toggleLoading(true)

        redux.dispatch(actions.list.update(organizationsList))
        redux.dispatch(actions.settings.update(organizationsList.length))
        this.toggleLoading(false)
    }

    public create(data) {
        this.toggleLoading(true)
        return this.api2.create(BendTable.Tables.ORGANIZATIONS, data)
    }

    public update(data) {
        const newData = { ...data }
        delete newData.$$hashKey

        this.toggleLoading(true)
        return this.api2.update(BendTable.Tables.ORGANIZATIONS, newData)
    }

    public get(id) {
        return this.api2.get(BendTable.Tables.ORGANIZATIONS, id)
    }

    public delete(id) {
        this.toggleLoading(true)
        return this.api2.delete(BendTable.Tables.ORGANIZATIONS, id).then(() => this.init())
    }

    public toggleLoading(bool) {
        redux.dispatch(actions.settings.toggleLoading(bool))
    }

    public isInUse(organizationId) {
        return this.api.isOrganizationInUse(organizationId);
    }


}

export default new Feature()
