import { Action } from '../actions/list'
import {
  UPDATE_ACTIONS_LIST
} from '../constants/types'

export interface Item {
  $$hashKey: string,
  type: string,
  description: string,
  details: string,
  enabled: true,
  name: string,
  slug: string,
  url: string,
  _acl: {
    creator: string
  },
  _bmd: {
    createdAt: number,
    updatedAt: number,
    _gen: string,
    _rev: number
  }
}

export type Activities = Array<Item>

const initialState: Activities = []

export default (state: Activities = initialState, action: Action): Activities => {
  switch (action.type) {
    case UPDATE_ACTIONS_LIST:
      return action.activities
    default:
      return state
  }
}
