"use strict";

import commonUtil from "../../../src/helpers/commonUtil";
import * as places from "../../../src/features/places";
import * as users from "../../../src/features/users";
import * as tags from "../../../src/features/tags";
import * as teams from "../../../src/features/teams";
import * as segments from "../../../src/features/segments";
import * as categories from "../../../src/features/categories";
import * as surveys from "../../../src/features/surveys";

angular.module("app.controllers").controller("BusinessEditController", [
  "$scope",
  "$location",
  "$routeParams",
  "BendAuth",
  "$rootScope",
  "$modal",
  "$ngRedux",
  async function(
    $scope,
    $location,
    $routeParams,
    BendAuth,
    $rootScope,
    $modal,
    $ngRedux
  ) {
    // Init.
    if ($routeParams.id != "new") {
      $scope.businessId = $routeParams.id;
    }
    $scope.moreOptions = false;

    $scope.business = {
      repeatable: true,
      points: 5,
      hours: [],
      surveys: []
    };

    const mapStateToThis = state => {
      return {
        tagList: state.tags.list,
        comments: state.comments.list,
        segments: state.segments.list,
        categories: state.categories.list,
        surveys: state.surveys.list.surveys,
        teams: state.teams.list.teams
      };
    };

    const unsubscribe = $ngRedux.connect(mapStateToThis)($scope);
    $scope.$on("$destroy", unsubscribe);

    $scope.user = await BendAuth.getActiveUser();
    tags.helpers.init();
    teams.helpers.init();
    segments.helpers.init();
    categories.helpers.getList($scope.user.community._id);
    surveys.helpers.init();

    $scope.commonUtil = commonUtil;
    $scope.isLoading = true;
    $scope.isUploading = [];
    $scope.fileProgress = [];
    $scope.collectionList = [];
    $scope.communityList = [];
    $scope.foursquareTagsList = [];
    $scope.stateList = commonUtil.AllStates;
    $scope.Tags = [];
    $scope.FoursquareTags = [];
    $scope.formData = {
      categories: [],
      surveys: [],
      teams: [],
      segments: [],
      collections: [],
      intervalminute: 0,
      intervalhour: 12,
      intervalday: 0
    };
    $scope.state = $scope.stateList[0];
    $scope.add_count = 0;

    $scope.IsEmptyTimes = true;

    $scope.form = {};

    $scope.getSegmentInfo = segment => {
      return segments.helpers.getSegmentInfo(segment);
    };

    $scope.deleteBusiness = async e => {
      const id = $scope.businessId;
      if (!id) {
        return $location.path("/businesses");
      }
      e.stopPropagation();
      e.preventDefault();
      const yeap = confirm(
        "Deleting the places is permanent and can not be undone."
      );
      if (!yeap) {
        return false;
      }
      await places.helpers.delete(id);
      applyChangesOnScope($scope, () => {
        $location.path("/businesses");
      });
    };

    async.parallel(
      [
        async callback => {
          const communities = await places.helpers.getCommunityList();
          $scope.communityList = communities;
          callback(null, null);
        },
        async callback => {
          const collections = await places.helpers.getCollectionEnabled();
          $scope.collectionList = collections;
          callback(null, null);
        }
      ],
      function(err, rets) {
        if (err) {
          return;
        }
        if ($scope.businessId) {
          places.helpers.get($scope.businessId).then(async ret => {
            ret.enabled =
              typeof ret.enabled !== "undefined" && ret.enabled === true;
            // ret.enabled = (ret.enabled !== false) // if null, ret.enabled = false, if enabled === true, enabled = true :D
            $scope.business = ret;
            $scope.business.points = $scope.business.points || 5;
            $scope.isLoading = false;
            if ($scope.business.repeatInterval) {
              $scope.formData.intervalday = parseInt(
                $scope.business.repeatInterval / (24 * 3600)
              );
              $scope.formData.intervalhour = parseInt(
                ($scope.business.repeatInterval -
                  $scope.formData.intervalday * (24 * 3600)) /
                  3600
              );
              $scope.formData.intervalminute = parseInt(
                ($scope.business.repeatInterval -
                  ($scope.formData.intervalday * (24 * 3600) +
                    $scope.formData.intervalhour * 3600)) /
                  60
              );
            }
            if ($scope.business.hours) {
              $scope.IsEmptyTimes = $scope.business.hours.length > 0;
            }
            if ($scope.business.state) {
              $scope.state = $scope.business.state;
            }
            // convert check-in radius to feet for American users
            if ($scope.business.checkinRadius) {
              $scope.business.checkinRadius = commonUtil.metersToFeet(
                $scope.business.checkinRadius
              );
            }
            if (
              $scope.business.categories &&
              $scope.business.categories.length > 0
            ) {
              $scope.formData.categories = _.filter($scope.categories, function(
                o
              ) {
                return $scope.business.categories.indexOf(o._id) != -1;
              });
            }

            if ($scope.business.surveys && $scope.business.surveys.length>0 && $scope.business.surveys[0]._id) {
              $scope.formData.surveys = $scope.business.surveys[0];
            } 

            if ($scope.business.teams && $scope.business.teams.length) {
              $scope.formData.teams = _.filter($scope.teams, function(team) {
                return $scope.business.teams.indexOf(team._id) !== -1;
              });
            }

            if ($scope.business.segments && $scope.business.segments.length) {
              $scope.formData.segments = _.filter($scope.segments, function(
                team
              ) {
                return $scope.business.segments.indexOf(team._id) !== -1;
              });
            }

            if (
              $scope.business.collections &&
              $scope.business.collections.length > 0
            ) {
              $scope.formData.collections = _.filter(
                $scope.collectionList,
                function(o) {
                  return $scope.business.collections.indexOf(o._id) != -1;
                }
              );
            }

            if ($scope.business.community) {
              $scope.business.community = $scope.business.community._id;
            } else {
              $scope.business.community = "";
            }

            if ($scope.business._acl && $scope.business._acl.creator) {
              const user = await users.helpers.get(
                $scope.business._acl.creator
              );
              applyChangesOnScope($scope, () => {
                $scope.creator = user;
              });
            }

            if ($scope.business.address1) {
              // address does exist
              $scope.addressExists = true;
              $scope.addressRequired = true;
             
            } else if (!$scope.business.address1) {
              // address doesn't exist
              $scope.addressExists = false;
              $scope.addressRequired = false;
              $scope.geocodeReq = false;
              
             
              
            }
            $scope.isLoading = false;
          });
        } else {
          // new record
          $scope.addressExists = true;
          $scope.addressRequired = true;
          $scope.business.enabled = true;
        }
      }
    );
      
    $scope.RemoveHour = function(obj, e) {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }

      var idx = $scope.business.hours.indexOf(obj);
      $scope.business.hours.splice(idx, 1);
      $scope.IsEmptyTimes = $scope.business.hours.length > 0;
    };

    $scope.getDays = function(hours) {
      return hours["days"];
    };

    $scope.getOpenTimes = function(hours) {
      var time = [];
      for (var j = 0; j < hours["open"].length; j++) {
        var fromuntil =
          commonUtil.convertWithAMPM(hours["open"][j]["from"]) +
          "-" +
          commonUtil.convertWithAMPM(hours["open"][j]["until"]);
        time.push(fromuntil);
      }

      return time.join(", ");
    };

    $scope.AddHours = function() {
      if (!$scope.business.hours) {
        $scope.business.hours = [];
      }

      $scope.business.hours.push({
        days: "",
        open: []
      });
      $scope.IsEmptyTimes = $scope.business.hours.length > 0;
      $scope.form.validateForm.$setDirty();
    };

    $scope.deleteFile = function(tag, $ev) {
      $ev.stopPropagation();
      $ev.preventDefault();
      applyChangesOnScope($scope, function() {
        delete $scope.business.coverImage;
      });
    };

    $scope.showFileLoading = function(tag, bShow) {
      $scope.isUploading[tag] = bShow;
    };

    $scope.selectFileOpen = function(fileId, $ev) {
      if ($ev.target.tagName == "DIV") {
        setTimeout(function() {
          $("#" + fileId).click();
        }, 0);
      }
    };

    $scope.onFileUpload = function(files, tag) {
      var file = files[0];
      $scope.fileProgress[tag] = 0;
      $scope.showFileLoading(tag, true);
      var reader = new FileReader();
      reader.onload = function(e) {
        $scope.uploadingFileUrl = e.target.result;
      };
      reader.readAsDataURL(file);

      users.helpers.upload(
        file,
        function(error, uploadedFile) {
          if (error) {
            $scope.uploadingFileUrl = null;

            $scope.showFileLoading(tag, false);
            return;
          }
          users.helpers.getFile(uploadedFile, function(o) {
            $scope.uploadingFileUrl = null;

            $scope.business.coverImage = o;
            $scope.showFileLoading(tag, false);
            $scope.$apply();
          });
        },
        {
          _workflow: "coverPhoto"
        },
        function(total, prog) {
          applyChangesOnScope($scope, function() {
            $scope.fileProgress[tag] = (prog * 100) / total;
          });
        }
      );
    };

    // make address fields required or not
    $scope.addressReq = () => {
      $scope.addressRequired = !$scope.addressRequired;
      if ($scope.addressRequired === false) {
        $scope.geocodeAddress(); // if no address, open the geocode window
      }
    };

    // form validation using angular ng-class
    $scope.validateForm = () => {
      const required = "required";

      const name = _.get($scope.business, "name");
      const description = _.get($scope.business, "description");
      const _geoloc = _.get($scope.business, "_geoloc");
      const points = _.get($scope.business, "points");
      const categories = _.get($scope.formData, "categories");
      const address1 = _.get($scope.business, "address1");
      const city = _.get($scope.business, "city");
      const state = _.get($scope.business, "state");
      const postalCode = _.get($scope.business, "postalCode");

      if (name === "" || !name) $scope.nameReq = required;
      else $scope.nameReq = false;
      if (description === "" || !description) $scope.descriptionReq = required;
      else $scope.descriptionReq = false;
      if (categories.length === 0 || !categories)
        $scope.categoriesReq = required;
      else $scope.categoriesReq = false;
      if (_geoloc === "" || !_geoloc) $scope.geocodeReq = required;
       else $scope.geocodeReq = false;

      // address requirements group, cancels requirement if address doesn't exist
      if (
        $scope.addressRequired &&
        (!address1 || !city || !state || !postalCode)
      ) {
        if (address1 === "" || !address1) $scope.address1Req = required;
        if (city === "" || !city) $scope.cityReq = required;
        if (points === 0 || !points) $scope.pointsReq = required;
        if (state === "" || !state) $scope.stateReq = required;
        if (postalCode === "" || !postalCode) $scope.postalCodeReq = required;
       

      }
      
      if (
        !name ||
        !description ||
        categories.length === 0 ||
        (!_geoloc && $scope.addressRequired) ||
        !points
      ) {
        $scope.errorMsg = true;
        return false;
      }
      if (
        $scope.addressRequired &&
        (!address1 || !city || !state || !postalCode)
      ) {
        $scope.errorMsg = true;
        return false;
      }
     
      return true;
    };

    $scope.filterNewline = async () => {
      var description = _.get($scope.business, "description");
      var parts = description.toString().split("\n");
      _.set($scope.business, "description", parts.join(""));
    };

    $scope.saveBusinessDo = async () => {

      // set points as number
      $scope.business.points = Number($scope.business.points);
      if (!$scope.validateForm()) return;
      $scope.filterNewline();
      var repeatInterval = 0;
      if ($scope.formData.intervalday != 0) {
        repeatInterval = $scope.formData.intervalday * (24 * 3600);
      }
      if ($scope.formData.intervalhour != 0) {
        repeatInterval = repeatInterval + $scope.formData.intervalhour * 3600;
      }
      if ($scope.formData.intervalminute != 0) {
        repeatInterval = repeatInterval + $scope.formData.intervalminute * 60;
      }

      if (repeatInterval > 0) {
        $scope.business.repeatInterval = repeatInterval;
      }

      if ($scope.business.community && $scope.business.community != "") {
        $scope.business.community = commonUtil.makeBendRef(
          $scope.business.community,
          "community"
        );
      } else {
        delete $scope.business.community;
      }

      if ($scope.formData.teams && $scope.formData.teams.length > 0) {
        $scope.business.teams = commonUtil.getIdList($scope.formData.teams);
      } else {
        delete $scope.business.teams;
      }

      if ($scope.formData.segments && $scope.formData.segments.length > 0) {
        $scope.business.segments = commonUtil.getIdList(
          $scope.formData.segments
        );
      } else {
        delete $scope.business.segments;
      }

      if (!$scope.business.checkinRadius) {
        $scope.business.checkinRadius = 250;
      } else {
        $scope.business.checkinRadius = commonUtil.feetToMeters(
          $scope.business.checkinRadius
        );
      }

      if (
        $scope.business._geoloc &&
        $scope.business._geoloc[0] &&
        $scope.business._geoloc[1] &&
        $scope.business._geoloc[0] != "" &&
        $scope.business._geoloc[1] != ""
      ) {
        $scope.business._geoloc = [
          parseFloat($scope.business._geoloc[0]),
          parseFloat($scope.business._geoloc[1])
        ];
      } else {
        delete $scope.business._geoloc;
      }

      var days = document.getElementsByName("days-input");
      var times = document.getElementsByName("times-input");
      var hours = [];
      for (var i = 0; i < days.length; i++) {
        var open = [];
        var time = times[i].value;
        var timeArray = time.split(",");
        for (var j = 0; j < timeArray.length; j++) {
          var oneOpen = timeArray[j];
          oneOpen = oneOpen.toUpperCase();
          var from = oneOpen.split("-")[0];
          var until = oneOpen.split("-")[1];

          if (!from || !until) continue;

          from = from.replace(/\s/g, "");
          until = until.replace(/\s/g, "");

          var fromTime = parseInt(from.split(":")[0]);
          var untilTime = parseInt(until.split(":")[0]);

          if (from.indexOf("AM") != -1) {
            from = from.split("AM")[0];
            if (fromTime >= 12) {
              fromTime = fromTime - 12;
              from =
                fromTime +
                ":" +
                (from.split(":")[1] ? from.split(":")[1] : "00");
            }
          } else if (from.indexOf("PM") != -1) {
            from = from.split("PM")[0];
            if (fromTime < 12) {
              fromTime = fromTime + 12;
              from =
                fromTime +
                ":" +
                (from.split(":")[1] ? from.split(":")[1] : "00");
            }
          }

          if (until.indexOf("AM") != -1) {
            until = until.split("AM")[0];
            if (untilTime >= 12) {
              untilTime = untilTime - 12;
              until =
                untilTime +
                ":" +
                (until.split(":")[1] ? until.split(":")[1] : "00");
            }
          } else if (until.indexOf("PM") != -1) {
            until = until.split("PM")[0];
            if (untilTime < 12) {
              untilTime = untilTime + 12;
              until =
                untilTime +
                ":" +
                (until.split(":")[1] ? until.split(":")[1] : "00");
            }
          }

          fromTime = parseInt(from.split(":")[0]);
          untilTime = parseInt(until.split(":")[0]);

          if (fromTime > untilTime) {
            if (fromTime < 12) {
              untilTime = untilTime + 12;
              until =
                untilTime +
                ":" +
                (until.split(":")[1] ? until.split(":")[1] : "00");
            }
          }

          var fromuntil = { from: from, until: until };
          open.push(fromuntil);
        }
        var hour = { days: days[i].value, open: open };
        hours.push(hour);
      }

      let cleanedHours = [];
      hours.forEach(hour => {
        delete hour.$$hashKey;
        cleanedHours.push(hour);
      });
      if (cleanedHours.length != 0) {
        $scope.business.hours = cleanedHours;
      }
      // this is great! except that somehow the hashkey is showing up again in the endpoint
      // so now its being deleted there too

      if ($scope.formData.categories.length > 0) {
        $scope.business.categories = commonUtil.getIdList(
          $scope.formData.categories
        );
        _.map($scope.formData.categories, function(o) {});
      } else {
        delete $scope.business.categories;
      }

      if ($scope.formData.surveys._id) {
        $scope.business.surveys = commonUtil.getIdListAndName(
          $scope.formData.surveys
        );
      } else {
        $scope.business.surveys = []; 
      }

      if ($scope.formData.collections.length > 0) {
        $scope.business.collections = commonUtil.getIdList(
          $scope.formData.collections
        );
      } else {
        delete $scope.business.collections;
      }

      delete $scope.business.certifications;
      delete $scope.business.certification;

      var businessData = _.clone($scope.business);
      if (businessData.coverImage) {
        businessData.coverImage = commonUtil.makeBendFile(
          businessData.coverImage._id
        );
      }
      if ($scope.businessId) {
        delete businessData.$$hashKey;
        await places.helpers.update(businessData);
      } else {
        await places.helpers.create(businessData);
      }
      applyChangesOnScope($scope, () => {
        $location.path("/businesses");
      });
    };

    // attempted here to reset geocode. marker doesn't move to new address but new geocode is right
    $scope.resetGeocode = function() {
      geoAddress = "";
      marker = null;
      delete $scope.business._geoloc;
      $scope.viewGeocode = false;
      addressList = [];
    };

    var addressList = [];
    $scope.openGeocode = function() {
      addressList = [];
      marker = null; // this down't work to reset marker if address is changed
      if ($scope.business.address1 && $scope.business.address1 != "") {
        addressList.push($scope.business.address1);
      }
      if ($scope.business.city && $scope.business.city != "") {
        addressList.push($scope.business.city);
      }
      if ($scope.business.state && $scope.business.state != "") {
        addressList.push($scope.business.state);
      }
      if ($scope.business.postalCode && $scope.business.postalCode != "") {
        addressList.push($scope.business.postalCode);
      }
      $scope.viewGeocode = true;
      setTimeout(() => $scope.initMap(), 200);
    };

    $scope.close = function() {
      $scope.viewGeocode = false;
    };

    $scope.confirmAddress = function() {
      if (markerGeocode.indexOf("") !== -1) return;
      $scope.business._geoloc = [markerGeocode[1], markerGeocode[0]]; //2019-05-06 Switching values since
      $scope.viewGeocode = false;
    };

    var geoAddress;
    var marker = null;
    $scope.initMap = function() {
      var map = new google.maps.Map(document.getElementById("geo_map"), {
        zoom: 12,
        center: { lat: 42.3005383, lng: -71.0654838 } // phila! :D
      });

      var geocoder = new google.maps.Geocoder();

      if (addressList.length > 0) {
        geoAddress = addressList.join(", ");
        $scope.geocodeAddress(geocoder, map);
      } else {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(function(position) {
            var pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };

            map.setCenter(pos);
          });
        }
      }

      document.getElementById("submit").addEventListener("click", function() {
        $scope.geocodeAddress(geocoder, map);
      });

      map.addListener("click", function(e) {
        $scope.placeMarkerAndPanTo(e.latLng, map);
      });
    };

    var markerGeocode = [];
    $scope.placeMarkerAndPanTo = function(latLng, map) {
      if (marker) {
        marker.setPosition(latLng);
      } else {
        marker = new google.maps.Marker({
          map: map,
          position: latLng
        });
      }
      markerGeocode = [latLng.lat(), latLng.lng()];
    };

    $scope.geocodeAddress = function(geocoder, resultsMap) {
      if (geoAddress && geoAddress !== "") {
        geocoder.geocode({ address: geoAddress }, function(results, status) {
          if (status === "OK") {
            resultsMap.setCenter(results[0].geometry.location);
            $scope.placeMarkerAndPanTo(
              results[0].geometry.location,
              resultsMap
            );
          } else {
            alert(
              "Geocode was not successful for the following reason: " + status
            );
          }
        });
      }
    };

    $rootScope.fileUploadFromSearch2 = function(file, tag) {
      file._filename = Date.now() + "";
      var files = [];
      files.push(file);
      $scope.onFileUpload(files, tag);
    };

    $scope.searchImages2 = function($ev, tag) {
      $ev.stopPropagation();
      $ev.preventDefault();
      $modal.open({
        templateUrl: "views/templates/searchImages.html",
        backdrop: "static",
        controller: function($scope, $modalInstance) {
          $scope.searchResults = 0;
          $scope.pages = 1;
          $scope.showLoadMore = false;

          $scope.cancel = function() {
            $modalInstance.dismiss("cancel");
          };
          $scope.close = function() {
            $modalInstance.dismiss("cancel");
          };

          $scope.searchImages = function(searchVal) {
            $scope.pages = 1;
            if (searchVal) {
              var URL =
                "https://pixabay.com/api/?key=2706353-ee3016f8af51ca406a8c8a3db&q=" +
                encodeURIComponent(searchVal) +
                "&image_type=photo&page=" +
                $scope.pages;
              $.getJSON(URL, function(data) {
                if (parseInt(data.totalHits) > 0) {
                  applyChangesOnScope($scope, function() {
                    if (data.totalHits > $scope.pages * 20) {
                      $scope.showLoadMore = true;
                    } else {
                      $scope.showLoadMore = false;
                    }

                    $scope.searchResults = data.hits;
                  });
                }
              });
            }
          };

          $scope.searchImagesMore = function(tabIdx, searchVal) {
            $scope.pages++;
            if (searchVal) {
              var URL =
                "https://pixabay.com/api/?key=2706353-ee3016f8af51ca406a8c8a3db&q=" +
                encodeURIComponent(searchVal) +
                "&image_type=photo&page=" +
                $scope.pages;
              $.getJSON(URL, function(data) {
                if (parseInt(data.totalHits) > 0) {
                  applyChangesOnScope($scope, function() {
                    if (data.totalHits > $scope.pages * 20) {
                      $scope.showLoadMore = true;
                    } else {
                      $scope.showLoadMore = false;
                    }

                    $scope.searchResults = $scope.searchResults.concat(
                      data.hits
                    );
                  });
                }
              });
            }
          };

          $scope.selectImage = function(searchItem) {
            $scope.isDownloading = true;
            var imageUrl = "";
            imageUrl = searchItem.webformatURL;

            var xhr = new XMLHttpRequest();
            xhr.open("GET", imageUrl, true);
            xhr.responseType = "blob";
            xhr.onload = function(e) {
              if (this.status == 200) {
                var myBlob = this.response;
                $rootScope.fileUploadFromSearch2(myBlob, tag);
                // myBlob is now the blob that the object URL pointed to.
                $scope.cancel();
              }
            };
            xhr.send();
          };
        }
      });
    };
  }
]);
