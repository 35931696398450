'use strict'

import * as teams from '../../../src/features/teams'

angular.module('app.controllers')
  .controller('TeamEditController', ['$scope', '$location', '$routeParams', 'BendAuth', 'CommonUtil', '$ngRedux',
    function ($scope, $location, $routeParams, BendAuth, CommonUtil, $ngRedux) {
      // Init.
      $scope.CommonUtil = CommonUtil
      $scope.teamId = null
      $scope.editMode = false
      if ($routeParams.id != 'new') {
        $scope.teamId = $routeParams.id
        $scope.editMode = true
      }
      $scope.isLoading = true
      $scope.user = BendAuth.getActiveUser()
      $scope.communityAdmin = $scope.user.communityAdmin

      const mapStateToThis = (state) => {
        return {
          communityList: state.communities.list,
          teamsMembers: state.teams.list.teamsMembers,
          usersList: state.users.list, // userlist is preserved from teamController in redux
          filter: {
            community: state.teams.settings.community
          }
        }
      }

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      $scope.teamUsersList = $scope.usersList.filter(user => {
        if (user.teams) {
          if (user.teams.indexOf($scope.teamId) !== -1) return true
          else return false
        } else return false
      })

      $scope.breadcrumb = {
        name: '',
        communityId: ''
      }

      $scope.communityList = []

      async.parallel([
        async (callback) => {
          const communities = await teams.helpers.getCommunityList()
          applyChangesOnScope($scope, () => {
            $scope.communityList = communities
            const communityId = _.get($location.search(), 'communityId')
            $scope.breadcrumb.communityId = communityId
            $scope.team = {}
            $scope.team.community = communities.find((i) => i._id === communityId)
          })
          callback(null, null)
        }
      ], async (err, retsError) => {
        if (err) {
          return
        }
        if ($scope.teamId) {
          const response = await teams.helpers.get($scope.teamId)
          applyChangesOnScope($scope, () => {
            $scope.team = response
            $scope.breadcrumb.name = _.get(response, 'name')
            $scope.breadcrumb.communityId = _.get(response, 'community._id')
            if ($scope.team.community) {
              $scope.team.community = _.find($scope.communityList, function (o) {
                return o._id == $scope.team.community._id
              })
            }
            $scope.isLoading = false
          })
        }
      })

      $scope.isValidForm = function () {
        var isValid = $scope.form.validateForm.$dirty && $scope.form.validateForm.$valid
        if ($scope.isSaving) {
          isValid = false
        }

        return isValid
      }

      $scope.editUser = function (user, $event) {
        if ($event.metaKey || $event.ctrlKey) {
          window.open('#/users/' + user._id + '?t=' + Date.now(), '_blank')
        } else { return $location.url('/users/' + user._id + '?t=' + Date.now()) }
      }

      $scope.deleteTeam = async (id, $ev) => {
        $ev.stopPropagation()
        $ev.preventDefault()
        const yeap = confirm('Deleting the team is permanent and can not be undone.')
        if (!yeap) {
          return false
        }
        const uriToRedirect = (communityId) => (
          $scope.user.communityAdmin
            ? '/teams'
            : `/communities/${communityId}/teams`
        )
        const response = await teams.helpers.delete(id)
        const uri = _.get(response, 'community._id')
        const redirectUri = uriToRedirect(uri)
        applyChangesOnScope($scope, () => {
          $location.path(redirectUri)
        })
      }

      $scope.saveTeamDo = async () => {
        var teamData = _.clone($scope.team)
        const uriToRedirect = (communityId) => (
          $scope.user.communityAdmin
            ? '/teams'
            : `/communities/${communityId}/teams`
        )

        if ($scope.teamId) {
          $scope.isSaving = true
          delete teamData.$$hashKey
          await teams.helpers.update(teamData)
          applyChangesOnScope($scope, () => {
            const uri = _.get(teamData, 'community._id')
            $scope.isSaving = false
            const redirectUri = uriToRedirect(uri)
            $location.path(redirectUri)
          })
        } else {
          $scope.isSaving = true
          if ($scope.user.communityAdmin) {
            teamData.community = $scope.user.community
          }
          await teams.helpers.create(teamData)
          applyChangesOnScope($scope, () => {
            $scope.isSaving = false
            $location.path(
              uriToRedirect(teamData.community._id)
            ).search('')
          })
        }
      }
    }])
