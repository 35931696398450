'use strict'

import * as communities from '../../../src/features/communities'

angular.module('app.controllers')
  .controller('CommunityController', ['$scope', '$location', 'BendAuth', 'CommonUtil', '$ngRedux',
    function ($scope, $location, BendAuth, CommonUtil, $ngRedux) {
      $scope.isLoading = false
      $scope.CommonUtil = CommonUtil
      $scope.statusList = ['Live', 'Onboarding', 'Demo']

      $scope.filter = {
        status: (CommonUtil.getStorage('community-filter') ? CommonUtil.getStorage('community-filter').status : '')
      }

      const mapStateToThis = (state) => {
        return {
          communityList: state.communities.pageList
        }
      }

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      // send community admins back to their dashboard
      if (BendAuth.getActiveUser().communityAdmin) $location.path('')

      $scope.onSearch = async () => {
        const status = _.get($scope, 'filter.status')
        CommonUtil.setStorage('community-filter', { status })
        if ($scope.communityList.length === 0) {
          $scope.isLoading = true
        }
        await communities.helpers.fetchByStatus(status)
        applyChangesOnScope($scope, () => {
          $scope.isLoading = false
        })
      }

      $scope.selectCommunity = () => {
        $scope.isLoading = true
        $scope.onSearch()
      }

      $scope.onSearch()

      $scope.createCommunity = function () {
        return $location.path('/communities/new')
      }

      $scope.editCommunity = function (communityObj) {
        $(window).scrollTop(0)
        return $location.path('/communities/' + communityObj._id)
      }
    }])
