export const UPDATE_GOALS_LIST = 'UPDATE_GOALS_LIST'
export const UPDATE_GOALS_ITEM = 'UPDATE_GOALS_ITEM'
export const UPDATE_GOALS_SETTINGS = 'UPDATE_GOALS_SETTINGS'
export const UPDATE_GOALS_SORTING_SETTINGS = 'UPDATE_GOALS_SORTING_SETTINGS'
export const UPDATE_GOALS_PAGE_SETTINGS = 'UPDATE_GOALS_PAGE_SETTINGS'
export const UPDATE_GOALS_GROUPS_SETTINGS = 'UPDATE_GOALS_GROUPS_SETTINGS'
export const UPDATE_GOALS_TEAMS_SETTINGS = 'UPDATE_GOALS_TEAMS_SETTINGS'
export const UPDATE_GOALS_SEGMENTS_SETTINGS = 'UPDATE_GOALS_SEGMENTS_SETTINGS'
export const UPDATE_GOALS_STATE_SETTINGS = 'UPDATE_GOALS_STATE_SETTINGS'
export const RESET_GOALS_SETTINGS = 'RESET_GOALS_SETTINGS'
