'use strict'

import commonUtil from '../../../../src/helpers/commonUtil'
import autoPush from '../../../../src/helpers/autoPush'
import * as categories from '../../../../src/features/categories'
import * as events from '../../../../src/features/events'
import * as tags from '../../../../src/features/tags'
import * as users from '../../../../src/features/users'
import * as teams from '../../../../src/features/teams'
import * as segments from '../../../../src/features/segments'
import { getActiveUser } from '../../../../src/helpers'
import moment from 'moment'

const DATE_FORMAT = 'YYYY-MM-DD LT';

angular.module('app.controllers')
  .controller('LegacyEventEditController', ['$scope', '$location', '$routeParams', '$rootScope', '$modal', '$ngRedux',
    function ($scope, $location, $routeParams, $rootScope, $modal, $ngRedux) {
      // Init.
      $scope.commonUtil = commonUtil
      $scope.eventId = null
      if ($routeParams.id != 'new') { $scope.eventId = $routeParams.id }
      $scope.isLoading = true
      $scope.moreOptions = false

      const mapStateToThis = (state) => {
        return {
          tagList: state.tags.list,
          comments: state.comments.list,
          segments: state.segments.list,
          categories: state.categories.list,
          teams: state.teams.list.teams
        }
      }

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      $scope.isUploading = []

      $scope.event = {
        repeatable: true
      }

      $scope.communityList = []

      $scope.stateList = commonUtil.AllStates
      $scope.formData = {
        categories: [],
        collections: [],
        intervalminute: 0,
        intervalhour: 0,
        intervalday: 0,
        state: '',
        teams: [],
        segments: []
      }
      $scope.certificationList = []
      $scope.fileProgress = []
      $scope.openedDateSelector = [false, false]
      $scope.openedTimeDateSelector = []
      $scope.event = {
        times: []
      }
      $scope.times = []

      $scope.user = getActiveUser()
      $scope.form = {}

      $scope.toggleConsistent = {
        // day: false,
        week: false,
        month: false
      }

      $scope.date = {}
      $scope.from = {}
      $scope.until = {}

      $scope.creator = null

      async.parallel([
        async (callback) => {
          if (!$scope.user.communityAdmin) {
            const response = await events.helpers.getCommunityList()
            $scope.communityList = response
            const certifications = await events.helpers.getCertificationList()
            $scope.certificationList = certifications
            const collections = await events.helpers.getCollectionEnabledList()
            $scope.collectionList = collections
            callback(null, null)
          }
          callback(null, null)
        }, async (callback) => {
          $scope.collectionList = await events.helpers.getCollectionEnabledList()
          callback(null, null)
        }, (callback) => {
          categories.helpers.getList($scope.user.community._id).then(() => {
            callback(null, null)
          })
        }, (callback) => {
          segments.helpers.init().then(() => {
            callback(null, null)
          })
        }, (callback) => {
          tags.helpers.init().then(() => {
            callback(null, null)
          })
        }, (callback) => {
          teams.helpers.init().then(() => {
            callback(null, null)
          })
        }], function (err, rets) {
          if (err) {
            return
          }

          if ($scope.eventId) {
            events.helpers.get($scope.eventId).then(async function (ret) {
              ret.enabled = (typeof ret.enabled !== 'undefined' && ret.enabled === true)
              $scope.oldEvent = _.clone(ret)
              $scope.event = ret
              $scope.isLoading = false

              if ( // address does exist
                $scope.event.address1
              ) {
                $scope.addressExists = true
                $scope.addressRequired = true
              } else if ( // address doesn't exist
                !$scope.event.address1
              ) {
                $scope.addressExists = false
                $scope.addressRequired = false
              }

              // update value for the toggle in the ui
              // find push associated with this poll, save it
              // if (ret.push && ret.push._id) {
              //   const eventId = _.get(ret, '_id')
              //   events.helpers.fetchLinked(eventId).then(function (pushArr) {
              //     if (pushArr.length > 0) {
              //       $scope.linkedPush = pushArr[0]
              //       $scope.linkedPushToggle = true
              //     }
              //   }, function (err) { })
              // } else { $scope.linkedPushToggle = false }

              if ($scope.event.teams && $scope.event.teams.length) {
                $scope.formData.teams = $scope.teams.filter(function (team) {
                  return $scope.event.teams.indexOf(team._id) !== -1
                })
              }

              if ($scope.event.categories && $scope.event.categories.length > 0) {
                $scope.formData.categories = $scope.categories.filter(function (o) {
                  return $scope.event.categories.indexOf(o._id) != -1
                })
              }

              if ($scope.event.segments && $scope.event.segments.length > 0) {
                $scope.formData.segments = $scope.segments.filter(function (o) {
                  return $scope.event.segments.indexOf(o._id) != -1
                })
              }

              if ($scope.event.repeatInterval) {
                $scope.formData.intervalday = parseInt($scope.event.repeatInterval / (24 * 3600))
                $scope.formData.intervalhour = parseInt(($scope.event.repeatInterval - $scope.formData.intervalday * (24 * 3600)) / (3600))
                $scope.formData.intervalminute = parseInt(($scope.event.repeatInterval - (($scope.formData.intervalday * (24 * 3600)) + $scope.formData.intervalhour * 3600)) / 60)
              }

              // convert check-in radius to feet for American users
              if ($scope.event.checkinRadius) {
                $scope.event.checkinRadius = commonUtil.metersToFeet($scope.event.checkinRadius)
              }

              // set legacy points value to checkinPoints - shouldn't be needed anymore
              // if (!$scope.event.checkinPoints) $scope.event.checkinPoints = $scope.event.points
              // if (!$scope.event.registrationPoints) $scope.event.registrationPoints = $scope.event.points / 10

              if ($scope.event.certification) {
                $scope.event.certification = $scope.event.certification._id
              } else {
                $scope.event.certification = ''
              }

              if ($scope.event.community) {
                $scope.event.community = $scope.event.community._id
              } else {
                $scope.event.community = ''
              }

              if ($scope.event.collections && $scope.event.collections.length > 0) {
                $scope.formData.collections = _.filter($scope.collectionList, function (o) {
                  return $scope.event.collections.indexOf(o._id) != -1
                })
              }

              if ($scope.event.times) {
                $scope.add_count = $scope.event.times.length
                for (var i = 0; i < $scope.event.times.length; i++) {
                  var time = $scope.event.times[i]
                  time['index'] = i
                  $scope.times.push(time)
                  $scope.openedTimeDateSelector.push(false)
                }
              }

              $scope.savedFrom = $scope.times[0].from
              $scope.savedUntil = $scope.times[0].until

              if ($scope.event._acl && $scope.event._acl.creator) {
                const userId = _.get($scope.event, '_acl.creator')
                const user = await users.helpers.get(userId)
                applyChangesOnScope($scope, () => {
                  $scope.creator = user
                })
              }
              $scope.formData.state = $scope.event.state
            }) // get event
          } else if (!$scope.eventId) {
            $scope.addressExists = true
            $scope.addressRequired = true
            $scope.linkedPushToggle = false
            $scope.add_count = 0
          }
        }) // end async parallel

      // prevents bad times from being saved, UI-observed revert to saved version -- maybe happens in other fns here
      $scope.submitEditButton = () => {
        if ($scope.eventId) {
          return $scope.submitEditButtonExistedEvent()
        }

        let errors = 0

        const dates = Object.entries($scope.date).map(item => {
          return {
            id: item[0],
            date: item[1]
          }
        })
        const froms = Object.entries($scope.from).map(item => {
          return {
            id: item[0],
            time: item[1]
          }
        })
        const untils = Object.entries($scope.until).map(item => {
          return {
            id: item[0],
            time: item[1]
          }
        })

        const dateIsValid = date =>
          moment(date, ['YYYY-MM-DD'], true).isValid()
        const timeIsValid = time =>
          moment(time, ['LT', 'HH:mm', 'hh:mma', 'h:mma'], true).isValid()

        if (dates.length === 0 || dates.length < $scope.times.length) {
          errors++
        }

        if (froms.length === 0 || froms.length < $scope.times.length) {
          errors++
        }

        if (untils.length === 0 || untils.length < $scope.times.length) {
          errors++
        }

        dates.forEach(({ date, id }) => {
          const fixDate = moment(date).format('YYYY-MM-DD')
          const success = date && dateIsValid(fixDate)
          if (!success) {
            errors++
          }
        })

        froms.forEach(({ time, id }) => {
          const success = timeIsValid(time)
          if (!success) {
            errors++
          }
          $scope.form.validateForm[`from-input[${id}]`].$setValidity('from-input', success)
        })

        untils.forEach(({ time, id }) => {
          const success = timeIsValid(time)
          if (!success) {
            errors++
          }
          $scope.form.validateForm[`until-input[${id}]`].$setValidity('until-input', success)
        })

        if (!$scope.event.name) {
          errors++
        }

        return errors === 0
      }

      // prevents bad times from being saved, UI-observed revert to saved version -- maybe happens in other fns here
      $scope.submitEditButtonExistedEvent = () => {
        let errors = 0

        const dateIsValid = date =>
          moment(date, ['YYYY-MM-DD'], true).isValid()
        const timeIsValid = time =>
          moment(time, ['LT', 'HH:mm', 'hh:mma', 'h:mma'], true).isValid()

        const dateIsSuccess = (
          $scope.times.length &&
          $scope.times[0] &&
          $scope.times[0].date &&
          dateIsValid(
            moment($scope.times[0].date).format('YYYY-MM-DD')
          )
        )
        const fromIsSuccess = timeIsValid($scope.savedFrom)
        const untilIsSuccess = timeIsValid($scope.savedUntil)
        if (!dateIsSuccess) {
          errors++
        }
        if (!fromIsSuccess) {
          errors++
        }
        if (!untilIsSuccess) {
          errors++
        }
        if ($scope.form.validateForm['from-input']) {
          $scope.form.validateForm['from-input'].$setValidity('from-input', fromIsSuccess)
        }
        if ($scope.form.validateForm['until-input']) {
          $scope.form.validateForm['until-input'].$setValidity('until-input', untilIsSuccess)
        }

        return errors === 0
      }

      $scope.getSegmentInfo = segment => {
        return segments.helpers.getSegmentInfo(segment)
      }

      // prevents bad times from being saved, UI-observed revert to saved version -- maybe happens in other fns here
      $scope.AddTimes = function () {
        if (!$scope.times) {
          $scope.times = []
        }
        $scope.times.push({
          'index': $scope.add_count,
          'days': '',
          'from': '00:00',
          'until': '00:00'
        })

        $scope.add_count++
        $scope.IsEmptyTimes = ($scope.times.length > 0)
        if ($scope.openedTimeDateSelector.length < $scope.add_count) {
          $scope.openedTimeDateSelector.push(false)
        } else { $scope.openedTimeDateSelector[$scope.add_count - 1] = false }

        setTimeout(() => {
          $('[name^=from-input]').timepicker({ 'timeFormat': 'g:i a' })
          $('[name^=from-input]').on('changeTime', (e) => {
            const id = $(e.target).attr('itemId')
            const time = $(e.target).val()
            $scope.from[id] = time
            $scope.times[0].from = time
            // $scope.$apply(() => {
            //   $scope.form.validateForm[`from-input[${id}]`].$setDirty()
            // })
          })
          $('[name^=until-input]').timepicker({ 'timeFormat': 'g:i a' })
          $('[name^=until-input]').on('changeTime', (e) => {
            const id = $(e.target).attr('itemId')
            const time = $(e.target).val()
            $scope.until[id] = time
            $scope.times[0].until = time

            // $scope.$apply(() => {
            //   $scope.form.validateForm[`until-input[${id}]`].$setDirty()
            // })
          })
        }, 0)
      }

      $scope.RemoveTime = function (obj, e) {
        if (e) {
          e.preventDefault()
          e.stopPropagation()
        }

        var idx = $scope.times.indexOf(obj)
        $scope.times.splice(idx, 1)
        $scope.add_count--

        delete $scope.date[obj.$$hashKey]
        delete $scope.from[obj.$$hashKey]
        delete $scope.until[obj.$$hashKey]
      }

      $scope.deleteEvent = async (id, $ev) => {
        const yeap = confirm('Deleting the events is permanent and cannot be undone.')
        if (!yeap) {
          return false
        }
        const response = await events.helpers.delete(id)
        applyChangesOnScope($scope, () => {
          $location.path('/legacy/events')
          const pushId = _.get(response, 'push._id')
          if (pushId) {
            events.helpers.deletePush(pushId)
          }
        })
      }

      $scope.getDays = function (etime) {
        return etime['date']
      }
      $scope.getFrom = function (etime) {
        return etime['from']
      }
      window.a = $scope
      $scope.getUntil = function (etime) {
        return etime['until']
      }

      // make address fields required or not
      $scope.addressReq = () => {
        $scope.addressRequired = !$scope.addressRequired
        if ($scope.addressRequired === false) {
          $scope.geocodeAddress() // if no address, open the geocode window
        }
      }

      $scope.toggleAutoPush = () => {
        $scope.linkedPushToggle = !$scope.linkedPushToggle
      }

      $scope.prepObj = function () {
        $scope.event.state = $scope.formData.state
      }

      // form validation using angular ng-class
      $scope.validateForm = () => {
        const required = 'required'

        const name = _.get($scope.event, 'name')
        const description = _.get($scope.event, 'description')
        const _geoloc = _.get($scope.event, '_geoloc')
        // const times = _.get($scope.event, 'times') // removed this because times array wasn't being built until later on in the save process and this was preventing save
        const address1 = _.get($scope.event, 'address1')
        const city = _.get($scope.event, 'city')
        const state = _.get($scope.event, 'state')
        const postalCode = _.get($scope.event, 'postalCode')
        const categories = _.get($scope.formData, 'categories')
        const checkinPoints = $scope.event.checkinPoints

        if (name === '' || !name) $scope.nameReq = required
        else $scope.nameReq = false
        if (description === '' || !description) $scope.descriptionReq = required
        else $scope.descriptionReq = false
        if (categories.length === 0 || !categories) $scope.categoriesReq = required
        else $scope.categoriesReq = false
        if (checkinPoints === 0 || !checkinPoints) $scope.checkinPointsReq = required
        else $scope.checkinPointsReq = false
        if (_geoloc === '' || !_geoloc) $scope.geocodeReq = required // disabled bc provider error
        else $scope.geocodeReq = false

        // address requirements group, cancels requirement if address doesn't exist
        if ($scope.addressRequired &&
          (!address1 ||
            !city ||
            !state ||
            !postalCode)
        ) {
          if (address1 === '' || !address1) $scope.address1Req = required
          else $scope.address1Req = false
          if (city === '' || !city) $scope.cityReq = required
          else $scope.cityReq = false
          if (state === '' || !state) $scope.stateReq = required
          else $scope.stateReq = false
          if (postalCode === '' || !postalCode) $scope.postalCodeReq = required
          else $scope.postalCodeReq = false
        }
        if (
          !name ||
          !description ||
          !categories.length ||
          !_geoloc ||
          // !times ||
          // times.length === 0 ||
          !checkinPoints
        ) {
          $scope.errorMsg = true
          return false
        }
        if ($scope.addressRequired &&
          (!address1 ||
            !city ||
            !state ||
            !postalCode)
        ) {
          $scope.errorMsg = true
          return false
        }
        return true
      }

      $scope.filterNewline = async () => {
        var description = _.get($scope.event, 'description')
        var parts = description.toString().split('\n')
        _.set($scope.event, 'description', parts.join(''))
      }

      $scope.saveEventDo = async function () {
        $scope.prepObj()
        if (!$scope.validateForm()) return
        $scope.filterNewline()
        var repeatInterval = 0
        if ($scope.formData.intervalday != 0) {
          repeatInterval = $scope.formData.intervalday * (24 * 3600)
        }
        if ($scope.formData.intervalhour != 0) {
          repeatInterval = repeatInterval + $scope.formData.intervalhour * (3600)
        }
        if ($scope.formData.intervalminute != 0) {
          repeatInterval = repeatInterval + $scope.formData.intervalminute * (60)
        }

        if (repeatInterval > 0) {
          $scope.event.repeatInterval = repeatInterval
        }

        if (!$scope.event.checkinRadius) {
          $scope.event.checkinRadius = 250
        } else {
          $scope.event.checkinRadius = commonUtil.feetToMeters($scope.event.checkinRadius)
        }

        if ($scope.event.certification != '') {
          $scope.event.certification = commonUtil.makeBendRef($scope.event.certification, 'certification')
        }

        if ($scope.event.community && $scope.event.community != '') {
          $scope.event.community = commonUtil.makeBendRef($scope.event.community, 'community')
        } else {
          delete $scope.event.community
        }

        // probably unnecessary due to validation
        if ($scope.event._geoloc && $scope.event._geoloc[0] && $scope.event._geoloc[1] && $scope.event._geoloc[0] != '' && $scope.event._geoloc[1] != '') { $scope.event._geoloc = [parseFloat($scope.event._geoloc[0]), parseFloat($scope.event._geoloc[1])] } else { delete $scope.event._geoloc }

        if ($scope.formData.collections.length > 0) {
          $scope.event.collections = commonUtil.getIdList($scope.formData.collections)
        } else {
          delete $scope.event.collections
        }

        var days = document.getElementsByName('day-input')
        var froms = document.querySelectorAll('[name^=from-input]')
        var untils = document.querySelectorAll('[name^=until-input]')

        $scope.event.state = $scope.formData.state

        // i know this is repetative below and there should be a better way. the data wasn't displaying in the
        // form when i tried to improve this using commonUtil.objArrsToIds. i gave up after 3 tries
        if ($scope.formData.teams && $scope.formData.teams.length > 0) {
          $scope.event.teams = commonUtil.getIdList($scope.formData.teams)
        } else {
          delete $scope.event.teams
        }

        if ($scope.formData.segments && $scope.formData.segments.length > 0) {
          $scope.event.segments = commonUtil.getIdList($scope.formData.segments)
        } else {
          delete $scope.event.segments
        }

        if ($scope.formData.categories.length > 0) {
          $scope.event.categories = commonUtil.getIdList($scope.formData.categories)
          _.map($scope.formData.categories, function (o) {
            // searchTextList.push(o.name);
          })
        } else {
          delete $scope.event.categories
        }

        var eventData = _.clone($scope.event)
        if (eventData.coverImage) {
          eventData.coverImage = commonUtil.makeBendFile(eventData.coverImage._id)
        }
        if (eventData.sponsorLogo) {
          eventData.sponsorLogo = commonUtil.makeBendFile(eventData.sponsorLogo._id)
        }
        if ($scope.eventId) { // existing
          var times = []
          var startsAt = null
          var endsAt = null
          for (var i = 0; i < days.length; i++) {
            var time = { 'date': days[i].value, 'from': froms[i].value, 'until': untils[i].value }
            times.push(time)

            if (!startsAt) {
              startsAt = time.date + ' ' + time.from
            } else {
              if (startsAt > time.date + ' ' + time.from) { startsAt = time.date + ' ' + time.from }
            }

            if (!endsAt) {
              endsAt = time.date + ' ' + time.until
            } else {
              if (endsAt < time.date + ' ' + time.until) { endsAt = time.date + ' ' + time.until }
            }

          }

          if (startsAt) {
            eventData.startsAt = moment(startsAt, DATE_FORMAT).valueOf() * 1000000
          }
          if (endsAt) {
            eventData.endsAt = moment(endsAt, DATE_FORMAT).valueOf() * 1000000
          }

          console.log('Event Data', eventData)

          eventData.times = times

          delete eventData.$$hashKey
          const event = await events.helpers.update(eventData)
          applyChangesOnScope($scope, () => {
            $location.path('/events')
            if (autoPush.shouldUpdateAutoPush($scope.oldEvent, event, 'event', $scope.linkedPushToggle)) {
              autoPush.autoPush(event.name, event, 'event', $scope.user)
            }
          })
        } else { // doesn't exist yet
          delete eventData.$$hashKey
          times = []
          if (days.length > 0) {
            for (i = 0; i < days.length; i++) {
              if (days[i].value != '' && froms[i].value != '' && untils[i].value != '') {
                time = { 'date': days[i].value, 'from': froms[i].value, 'until': untils[i].value }

                times.push(time)
              }
            }
          }

          if (times.length > 0) {
            // async.map was being used here and taking super long to return rets to its callback
            // this is much faster
            times.map((time) => {
              var eData = $.extend(true, {}, eventData)

              var startsAt = time.date + ' ' + time.from
              var endsAt = time.date + ' ' + time.until

              if (startsAt) {
                eData.startsAt = moment(startsAt, DATE_FORMAT).valueOf() * 1000000
              }
              if (endsAt) {
                eData.endsAt = moment(endsAt, DATE_FORMAT).valueOf() * 1000000
              }
              eData.times = [time]
              events.helpers.create(eData).then(function (eventRet) {
                if (autoPush.shouldUpdateAutoPush($scope.oldEvent, eventRet, 'event', $scope.linkedPushToggle)) {
                  autoPush.autoPush(eventRet.name, eventRet, 'event', $scope.user)
                }
              })
            })
            $location.path('/events')
          }
        }
      }

      $scope.openTimeDateWindow = function ($event, idx) {
        $event.preventDefault()
        $event.stopPropagation()

        $scope.openedTimeDateSelector[idx] = true
      }

      $scope.deleteFile = function (tag, $ev) {
        $ev.stopPropagation()
        $ev.preventDefault()
        applyChangesOnScope($scope, function () {
          if (tag === 'coverImage') delete $scope.event.coverImage
          if (tag === 'sponsorLogo') delete $scope.event.sponsorLogo
        })
      }

      $scope.showFileLoading = function (tag, bShow) {
        $scope.isUploading[tag] = bShow
      }

      $scope.selectFileOpen = function (fileId, $ev) {
        if ($ev.target.tagName == 'DIV') {
          setTimeout(function () {
            $('#' + fileId).click()
          }, 0)
        }
      }

      $scope.onFileUpload = function (files, tag) {
        let imgNum = 0
        if (tag === 'sponsorLogo' && files.length > 1) imgNum = 1
        var file = files[imgNum]
        // var file = files[0]
        $scope.fileProgress[tag] = 0
        $scope.showFileLoading(tag, true)
        var reader = new FileReader()
        reader.onload = function (e) {
          if (tag === 'coverImage') $scope.uploadingFileUrl = e.target.result
          if (tag === 'sponsorLogo') $scope.uploadingSponsorUrl = e.target.result
        }
        reader.readAsDataURL(file)

        events.helpers.upload(file, function (error, uploadedFile) {
          if (error) {
            $scope.uploadingFileUrl = null
            $scope.showFileLoading(tag, false)
            return
          }
          events.helpers.getFile(uploadedFile, function (o) {
            $scope.uploadingFileUrl = null
            if (tag === 'coverImage') $scope.event.coverImage = o
            if (tag === 'sponsorLogo') $scope.event.sponsorLogo = o
            $scope.showFileLoading(tag, false)
          })
        }, {
          _workflow: 'coverPhoto'
        }, function (total, prog) {
          applyChangesOnScope($scope, function () {
            $scope.fileProgress[tag] = prog * 100 / total
          })
        })
      }

      // attempted here to reset geocode. marker doesn't move to new address but new geocode is right
      $scope.resetGeocode = function () {
        geoAddress = ''
        marker = null
        delete $scope.event._geoloc
        $scope.viewGeocode = false
        addressList = []
      }

      var addressList = []
      $scope.openGeocode = function () {
        addressList = []
        marker = null // this down't work to reset marker if address is changed
        if ($scope.event.address1 && $scope.event.address1 != '') { addressList.push($scope.event.address1) }
        if ($scope.event.city && $scope.event.city != '') { addressList.push($scope.event.city) }
        if ($scope.event.state && $scope.event.state != '') { addressList.push($scope.event.state) }
        if ($scope.event.postalCode && $scope.event.postalCode != '') { addressList.push($scope.event.postalCode) }
        $scope.viewGeocode = true
        setTimeout(() => $scope.initMap(), 200) // google maps needs this timeout for some reason
      }

      $scope.close = function () {
        $scope.viewGeocode = false
      }

      $scope.confirmAddress = function () {
        if (markerGeocode.indexOf('') !== -1) return
        $scope.event._geoloc = [markerGeocode[1], markerGeocode[0]]
        $scope.viewGeocode = false
      }

      var geoAddress
      var marker = null
      $scope.initMap = function () {
        var map = new google.maps.Map(document.getElementById('geo_map'), {
          zoom: 12,
          center: { lat: 42.3005383, lng: -71.0654838 } // phila! :D
        })

        var geocoder = new google.maps.Geocoder()

        if (addressList.length > 0) {
          geoAddress = addressList.join(', ')
          // document.getElementById('address').value = address
          $scope.geocodeAddress(geocoder, map)
        } else {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
              var pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
              }

              map.setCenter(pos)
            })
          }
        }

        document.getElementById('submit').addEventListener('click', function () {
          $scope.geocodeAddress(geocoder, map)
        })

        map.addListener('click', function (e) {
          $scope.placeMarkerAndPanTo(e.latLng, map)
        })
      }

      var markerGeocode = []
      $scope.placeMarkerAndPanTo = function (latLng, map) {
        if (marker) {
          marker.setPosition(latLng)
        } else {
          marker = new google.maps.Marker({
            map: map,
            position: latLng
          })
        }
        markerGeocode = [latLng.lat(), latLng.lng()]
      }

      $scope.geocodeAddress = function (geocoder, resultsMap) {
        if (geoAddress && geoAddress !== '') {
          geocoder.geocode({ 'address': geoAddress }, function (results, status) {
            if (status === 'OK') {
              resultsMap.setCenter(results[0].geometry.location)
              $scope.placeMarkerAndPanTo(results[0].geometry.location, resultsMap)
            } else {
              alert('Geocode was not successful for the following reason: ' + status)
            }
          })
        }
      }

      // $rootScope.setLocation = function (lat, lng) {
      //   $scope.event._geoloc = [lng, lat]
      // }

      // $scope.geocodeAddress = function () {
      //   var addressList = []
      //   if ($scope.event.address1 && $scope.event.address1 != '') { addressList.push($scope.event.address1) }
      //   if ($scope.event.city && $scope.event.city != '') { addressList.push($scope.event.city) }
      //   if ($scope.event.state && $scope.event.state != '') { addressList.push($scope.event.state) }
      //   if ($scope.event.postalCode && $scope.event.postalCode != '') { addressList.push($scope.event.postalCode) }

      //   $modal.open({
      //     templateUrl: 'views/templates/geocodeAddress.html',
      //     backdrop: 'static',
      //     controller: function ($scope, $modalInstance) {
      //       $scope.cancel = function () {
      //         $modalInstance.dismiss('cancel')
      //       }
      //       $scope.close = function () {
      //         $modalInstance.dismiss('cancel')
      //       }
      //       $scope.confirmAddress = function () {
      //         var lat = $('#geoLat').val()
      //         var lng = $('#geoLng').val()
      //         if (lat == '' || lng == '') return

      //         $rootScope.setLocation(lat, lng)
      //         $modalInstance.dismiss('cancel')
      //       }

      //       var marker = null
      //       $scope.initMap = function () {
      //         var map = new google.maps.Map(document.getElementById('geo_map'), {
      //           zoom: 12,
      //           center: {lat: 42.3005383, lng: -71.0654838}
      //         })

      //         var geocoder = new google.maps.Geocoder()

      //         if (addressList.length > 0) {
      //           var address = addressList.join(', ')
      //           document.getElementById('address').value = address
      //           $scope.geocodeAddress(geocoder, map)
      //         } else {
      //           if (navigator.geolocation) {
      //             navigator.geolocation.getCurrentPosition(function (position) {
      //               var pos = {
      //                 lat: position.coords.latitude,
      //                 lng: position.coords.longitude
      //               }

      //               map.setCenter(pos)
      //             })
      //           }
      //         }

      //         document.getElementById('submit').addEventListener('click', function () {
      //           $scope.geocodeAddress(geocoder, map)
      //         })

      //         map.addListener('click', function (e) {
      //           $scope.placeMarkerAndPanTo(e.latLng, map)
      //         })
      //       }

      //       setTimeout(function () {
      //         $scope.initMap()
      //       }, 100)

      //       $scope.placeMarkerAndPanTo = function (latLng, map) {
      //         if (marker) {
      //           marker.setPosition(latLng)
      //         } else {
      //           marker = new google.maps.Marker({
      //             map: map,
      //             position: latLng
      //           })
      //         }

      //         $('#geoLat').val(latLng.lat())
      //         $('#geoLng').val(latLng.lng())
      //       }

      //       $scope.geocodeAddress = function (geocoder, resultsMap) {
      //         var address = document.getElementById('address').value
      //         geocoder.geocode({'address': address}, function (results, status) {
      //           if (status === 'OK') {
      //             resultsMap.setCenter(results[0].geometry.location)
      //             $scope.placeMarkerAndPanTo(results[0].geometry.location, resultsMap)
      //           } else {
      //             alert('Geocode was not successful for the following reason: ' + status)
      //           }
      //         })
      //       }
      //     }
      //   })
      // }
      $rootScope.fileUploadFromSearch2 = function (file, tag) {
        file._filename = Date.now() + ''
        var files = []
        files.push(file)
        $scope.onFileUpload(files, tag)
      }

      $scope.searchImages2 = function ($ev, tag) {
        $ev.stopPropagation()
        $ev.preventDefault()
        $modal.open({
          templateUrl: 'views/templates/searchImages.html',
          backdrop: 'static',
          controller: function ($scope, $modalInstance) {
            $scope.commonUtil = commonUtil
            $scope.searchResults = 0
            $scope.pages = 1
            $scope.showLoadMore = false
            // var cacheSearchKey = ''

            $scope.cancel = function () {
              $modalInstance.dismiss('cancel')
            }
            $scope.close = function () {
              $modalInstance.dismiss('cancel')
            }

            $scope.searchImages = function (searchVal) {
              $scope.pages = 1
              if (searchVal) {
                var URL = 'https://pixabay.com/api/?key=2706353-ee3016f8af51ca406a8c8a3db&q=' + encodeURIComponent(searchVal) + '&image_type=photo&page=' + $scope.pages
                $.getJSON(URL, function (data) {
                  if (parseInt(data.totalHits) > 0) {
                    applyChangesOnScope($scope, function () {
                      if (data.totalHits > $scope.pages * 20) { $scope.showLoadMore = true } else { $scope.showLoadMore = false }

                      $scope.searchResults = data.hits
                    })
                  }
                })
              }
            }

            $scope.searchImagesMore = function (tabIdx, searchVal) {
              $scope.pages++
              if (searchVal) {
                var URL = 'https://pixabay.com/api/?key=2706353-ee3016f8af51ca406a8c8a3db&q=' + encodeURIComponent(searchVal) + '&image_type=photo&page=' + $scope.pages
                $.getJSON(URL, function (data) {
                  if (parseInt(data.totalHits) > 0) {
                    applyChangesOnScope($scope, function () {
                      if (data.totalHits > $scope.pages * 20) { $scope.showLoadMore = true } else { $scope.showLoadMore = false }

                      $scope.searchResults = $scope.searchResults.concat(data.hits)
                    })
                  }
                })
              }
            }

            $scope.selectImage = function (searchItem) {
              $scope.isDownloading = true
              var imageUrl = ''
              imageUrl = searchItem.webformatURL

              var xhr = new XMLHttpRequest()
              xhr.open('GET', imageUrl, true)
              xhr.responseType = 'blob'
              xhr.onload = function (e) {
                if (this.status == 200) {
                  var myBlob = this.response
                  $rootScope.fileUploadFromSearch2(myBlob, tag)
                  // myBlob is now the blob that the object URL pointed to.
                  $scope.cancel()
                }
              }
              xhr.send()
            }
          }
        })
      }
    }])
