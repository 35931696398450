'use strict'

import * as communities from '../../../src/features/communities'
import * as groups from '../../../src/features/groups'

angular.module('app.controllers')
  .controller('UserGroupsController', ['$scope', '$location', '$routeParams', 'BendAuth', 'CommonUtil', '$ngRedux',
    async function ($scope, $location, $routeParams, BendAuth, CommonUtil, $ngRedux) {
      $scope.CommonUtil = CommonUtil

      $scope.user = BendAuth.getActiveUser()

      const communityId = _.get($scope.user, 'communityAdmin')
        ? _.get($scope.user, 'community._id')
        : $routeParams.id

      const mapStateToThis = (state) => ({
        communityList: state.communities.list,
        groupsList: state.groups.list.groups,
        filter: {
          community: state.groups.settings.community
        },
        sortKey: state.groups.settings.sortKey,
        sortDirection: state.groups.settings.sortDirection
      })

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      if ($scope.groupsList.length === 0) {
        $scope.isLoading = true
      }

      $scope.getGroupsList = async () => {
        await groups.helpers.init(communityId)
        $scope.isLoading = false
      }

      if ($scope.user.communityAdmin) {
        $scope.getGroupsList()
      } else {
        await communities.helpers.init()
        if (typeof $scope.filter.community === 'object' && !$scope.filter.community._id) {
          groups.helpers.selectCommunity(null)
        }
        $scope.getGroupsList()
      }

      $scope.handleCommunitySelect = () => {
        const id = $scope.filter.community && $scope.filter.community._id
        groups.helpers.selectCommunity(id)
        $scope.getTeamList()
      }

      $scope.editGroup = function (group, $event) {
        if ($event.metaKey || $event.ctrlKey) {
          window.open('#/userGroups/' + group._id, '_blank')
        } else { return $location.path('/groups/' + group._id) }
      }

      $scope.createUserGroup = function ($event) {
        if ($event.metaKey || $event.ctrlKey) {
          window.open('#/groups/new', '_blank')
        } else { return $location.path('/groups/new') }
      }

      $scope.sortBy = (key) => {
        $scope.isLoading = true
        const direction = $scope.sortDirection
        if (direction[key]) direction[key] = (-1) * direction[key]
        else direction[key] = -1
        groups.helpers.sortBy(key, direction)
        $scope.isLoading = false
      }

      // var oldPosList = []
      let oldTeamPosList = []
      $scope.enableSortable = function () {
        $('#groups-container').sortable({
          opacity: 0.5,
          dropOnEmpty: true,
          start: function (event, ui) {
            ui.item[0].style.backgroundColor = '#eee'
            oldTeamPosList = []
            $scope.groupsList.map((o) => {
              oldTeamPosList[o._id] = o.position
            })
          },
          update: function (event, ui) {
            $('.group-content').each(function (idx) {
              const teamKey = $(this).find('#groupId-input')[0].value
              const team = _.find($scope.groupsList, (_o) => _o._id == teamKey)
              if (team.position != idx + 1) {
                team.position = idx + 1
                // update team
                groups.helpers.update(team)
              }
            })

            applyChangesOnScope($scope, () => {
              $scope.groupsList = _.sortBy($scope.groupsList, (o) => o.position)
            })
            ui.item[0].style.backgroundColor = ''
          }
        })
        $('#groups-container').sortable('option', 'disabled', false)
      }
    }])
