import {
  UPDATE_ACTIONS_SETTINGS,
  UPDATE_ACTIONS_PAGE_SETTINGS,
  UPDATE_ACTIONS_SEARCH_TERM_SETTINGS,
  UPDATE_ACTIONS_COMMUNITY_SETTINGS,
  UPDATE_ACTIONS_CATEGORY_SETTINGS,
  UPDATE_ACTIONS_COLLECTION_SETTINGS,
  RESET_ACTIONS_SETTINGS
} from '../constants/types'

import { Action } from '../actions/settings'

export interface Settings {
  searchTerm: any,
  itemsPerPage: number,
  isLoading: boolean,
  totalItems: number,
  currentPage: number,
  numPages: number,
  community: any,
  selectedState: string,
  categoryId: string,
  collectionId: string,
}

const initialState: Settings = {
  searchTerm: '',
  itemsPerPage: 20,
  isLoading: true,
  totalItems: 0,
  currentPage: 1,
  numPages: 0,
  community: '',
  selectedState: '',
  categoryId: '',
  collectionId: ''
}

export default (state: Settings = initialState, action: Action): Settings => {
  switch (action.type) {
    case UPDATE_ACTIONS_SETTINGS:
      return {
        ...state,
        totalItems: action.count,
        numPages: Math.ceil(action.count / state.itemsPerPage + 1)
      }
    case UPDATE_ACTIONS_PAGE_SETTINGS:
      return {
        ...state,
        currentPage: action.page
      }
    case UPDATE_ACTIONS_SEARCH_TERM_SETTINGS:
      return {
        ...state,
        searchTerm: action.searchTerm
      }
    case UPDATE_ACTIONS_COMMUNITY_SETTINGS:
      return {
        ...state,
        community: action.communityId
      }
    case UPDATE_ACTIONS_CATEGORY_SETTINGS:
      return {
        ...state,
        categoryId: action.categoryId
      }
    case UPDATE_ACTIONS_COLLECTION_SETTINGS:
      return {
        ...state,
        collectionId: action.collectionId
      }
    case RESET_ACTIONS_SETTINGS:
      return initialState
    default:
      return state
  }
}
