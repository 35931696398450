/* eslint no-useless-escape: 0 */
'use strict'

import * as communities from '../../../src/features/communities'
import commonUtil from '../../../src/helpers/commonUtil'
import {defaultList} from '../../../src/features/menu/reducers/list'

angular.module('app.controllers')
  .controller('CommunityEditController', ['$scope', '$location', '$routeParams', 'BendAuth', 'CommonUtil', '$rootScope', '$modal', '$ngRedux',
    function ($scope, $location, $routeParams, BendAuth, CommonUtil, $rootScope, $modal, $ngRedux) {
      const listScope = $scope
      $scope.isLoading = true
      $scope.CommonUtil = CommonUtil
      $scope.community = {
        enabled: true,
        points: 0,
        welcomeEmailEnabled: false,
        actionsEnabled: true,
        placesEnabled: true,
        servicesEnabled: true,
        eventsEnabled: true,
        volunteerOpportunitiesEnabled: true,
        actionsTitle: 'Actions',
        placesTitle: 'Places',
        servicesTitle: 'Services',
        eventsTitle: 'Events',
        volunteerOpportunitiesTitle: 'Volunteer Opportunities',
        actionsDescription: 'Explore easy, self-reported lifestyle behaviors',
        placesDescription: 'Check in to local, sustainable businesses nearby',
        eventsDescription: 'Register for green events and add to your calendar',
        servicesDescription: 'Sign up for eco-friendly lifestyle services',
        volunteerOpportunitiesDescription: "Find one that's right for you",
        showCategoriesInSearch: true,
        includeCommonActions: false,
        includeCommonPlaces: false,
        includeCommonServices: false,
        includeCommonEvents: false,
        includeCommonVolunteerOpportunities: false,
        profileChartsEnabled: true,
        whitelistEnabled: false,
        enableNewChat: true,
        profileBioEnabled: true,
        profileFacebookEnabled: true,
        profileTwitterEnabled: true,
        profileInstagramEnabled: true,
        profileSegmentsEnabled: true,
        profileRoleEnabled: true,
        profileIndustryEnabled: true,
        profileOrganizationEnabled: true,

        profileQuickstatsEnabled: true,
        profileQuickstatsPointsEnabled: true,
        profileQuickstatsHoursEnabled: true,
        profileQuickstatsLeaderboardEnabled: true,
        profileQuickstatsConnectionsEnabled: true,

        promptForEthnicity: true,
        promptForBirthday: true,
        promptForGender: true,
        promptForRace: true,
        promptForZipCode: true,

        pointsGem: false,
        menu: [],
        teamsEnabled: false,
        segmentsEnabled: false,
        // menu: [{}]

      }

      $scope.aws = {
        warnUsersUpgrade: false, 
        forceUsersUpgrade: false,
        minimumAppVersion: "",
        allowLegacyAdmin: false
      }

      $scope.saveAws = false; 
      $scope.minAppWarning = false; 
      $scope.warnForceWarning = false; 

      $scope.whitelabelId = ""
      $scope.whitelistedEmailsList = []
      $scope.whitelistedDomains = []
      $scope.fileProgress = []
      $scope.isUploading = []
      $scope.formData = {
        collections: [],
        categories: [],
        sprints: [],
        menuOptions: defaultList
      }
      $scope.collectionList = []
      $scope.sprintList = []
      $scope.tasks = []
      $scope.categoryGroups = []
      $scope.categories = []
      $scope.defaultCategories = ['Animals', 'Arts', 'Baby', 'Beauty', 'Bicycles', 'Civic', 'Coffee', 'Community', 'Construction', 'Dining', 'Drinks', 'Education', 'Energy', 'Fashion', 'Finance', 'Food', 'Garden', 'Green Space', 'Health & Wellness', 'Home & Office', 'Media', 'Products', 'Services', 'Special Events', 'Tourism & Hospitality', 'Transit', 'Waste']

      $scope.stateList = CommonUtil.AllStates

      $scope.communityId = null

      if ($routeParams.id != 'new') { $scope.communityId = $routeParams.id }

      // send community admins back to their dashboard
      if (BendAuth.getActiveUser().communityAdmin) $location.path('')

      $scope.form = {}

      const mapStateToThis = (state) => ({
        sprintList: state.sprints.list
      })

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      $rootScope.setCategoryGroups = function (groups) {
        $scope.categoryGroups = groups
      }

      $scope.edit = (item) => {
        // there is no edit functionality
      }

      async.parallel([
        function (callback) {
          communities.helpers.getCollectionEnabledList().then((rets) => {
            $scope.collectionList = rets
            callback(null, null)
          })
        },
        function (callback) {
          if ($scope.communityId) {
            communities.helpers.getCategoryEnabledList().then((rets) => {
              $scope.categories = rets
              callback(null, null)
            })
          } else {
            communities.helpers.getGlobalCategoryEnabledList().then((rets) => {
              $scope.categories = rets
              callback(null, null)
            })
          }
        },
        function (callback) {
          communities.helpers.getTaskList($scope.communityId).then((rets) => {
            callback(null, null)
          })
        },
        function (callback) {
          communities.helpers.getCurrentWhitelabel($scope.communityId).then((rets) => {
            $scope.whitelabel = rets
            if (rets && rets._id){
              $scope.whitelabelId = rets._id; 
              if (rets.awsConfig && rets.awsConfig !== undefined) {
                $scope.aws = rets.awsConfig
              }
            }
            callback(null, null)
          })
        }
      ], (err, rets) => {
        if ($scope.communityId) {
          communities.helpers.get($scope.communityId).then((ret) => {
            $scope.community = Object.assign($scope.community, ret)
            if (ret.collections && ret.collections.length > 0) {
              $scope.formData.collections = _.filter($scope.collectionList, (o) => ret.collections.indexOf(o._id) != -1)
            }
            if (ret.categories && ret.categories.length > 0) {
              $scope.formData.categories = _.filter($scope.categories, (o) => ret.categories.indexOf(o._id) != -1)
            }
            /* if(ret.sprints && ret.sprints.length > 0 ) {
                ret.sprints.map(function(o){
                    var exist = _.find($scope.sprintList, function(_o){
                        return _o._id == o
                    })
                    $scope.formData.sprints.push(exist)
                })
            } */
            if (!$scope.community.menu) $scope.community.menu = []
            $scope.isLoading = false
          })
        } else {
          // $scope.formData.categories = _.filter($scope.categories, function (o) {
          //   return $scope.defaultCategories.indexOf(o.name) != -1
          // })
          $scope.formData.categories = []
        }
      })

      $(document).ready(() => {
        const tabName = $routeParams.tab
        const el = $('.nav-tabs').find(`li[tab-name="${tabName}"] a`)
        if (el.length) {
          el.get(0).click()
        }
      })

      $scope.changeUrl = (e) => {
        const tabName = $(e.target).parent().attr('tab-name')
        if (tabName.length) {
          const uri = `/communities/${$routeParams.id}/${tabName}`
          applyChangesOnScope($scope, () => {
            $location.path(uri, false)
            $rootScope.isLoading = false
          })
        }
      }

      $scope.formatDate = function (time) {
        return moment(time / 1000000).format('MMMM Do YYYY, HH:mm:ss')
      }

      $scope.fetchWhiteListedEmails = async () => {
        const response = await communities.helpers.fetchWhiteListedEmails($scope.communityId)
        applyChangesOnScope($scope, () => {
          $scope.whitelistedEmailsList = response
          $scope.isLoading = false
        })
      }

      $scope.handleWhiteListedEmailsDeleteClick = async (item) => {
        $scope.isLoading = true
        const dataToUpdate = {
          _id: item._id,
          community: item.community,
          email: item.email,
          deleted: true
        }
        await communities.helpers.deleteWhiteListedEmail(dataToUpdate)
        await $scope.fetchWhiteListedEmails()
      }

      $scope.addWhiteListEmails = function () {
        const $rootScope = $scope
        var modalInstance = $modal.open({
          templateUrl: 'views/communities/whitelist.modal.html',
          controller: function ($scope, $modalInstance) {
            $scope.whitelistEmails = '' // ng-model
            const validateEmail = function (email) {
              const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              return re.test(email)
            }
            $scope.addToWhitelist = async () => {
              const invalidEmails = $scope.whitelistEmails
                .split('\n')
                .filter((email) => !validateEmail(email))
              const errorMessage = invalidEmails
                .map((email) => 'invalid: ' + email)
                .join('\n')
              if (invalidEmails.length) {
                alert(errorMessage)
                return false
              }
              const dataToSave = {
                emails: $scope.whitelistEmails.split('\n'),
                communityId: $rootScope.communityId
              }
              $rootScope.isLoading = true
              try {
                await communities.helpers.addWhiteListedEmails(dataToSave)
                $scope.cancel()
              } catch (err) {
                const error = _.get(err, 'name')
                if (error) {
                  alert(error)
                }
              }
              await listScope.fetchWhiteListedEmails()
            }
            $scope.cancel = function () {
              modalInstance.dismiss('cancel')
            }
          }
        })
      }

      $scope.addMenuItem = (index = null) => {
        if (!index && index !== 0) $scope.community.menu.push({})
        else $scope.community.menu.splice(index, 0, {})
      }

      $scope.deleteMenuItem = (index) => {
        $scope.community.menu.splice(index, 1)
      }

      $scope.customizeMenu = (fillFromDefault) => {
        if (fillFromDefault) $scope.community.menu = $scope.formData.menuOptions
        else $scope.community.menu = []
      }

      $scope.validateAws = (awsConfig) => {
        const required = 'required'
        if ( (awsConfig.forceUsersUpgrade || awsConfig.warnUsersUpgrade) && !awsConfig.minimumAppVersion ) {
              $scope.minAppWarning = true; 
              $scope.minApp = required; 
              $scope.warnForceWarning = false; 
              return false; 
        } else {
          $scope.minAppWarning = false; 
          $scope.minApp = false 
        }

        if (awsConfig.minimumAppVersion && !awsConfig.forceUsersUpgrade && !awsConfig.warnUsersUpgrade) {
            $scope.warnForceWarning = true;  
            $scope.minAppWarning = false; 
            $scope.minApp = false 
            return false
        } else {
          $scope.warnForceWarning = false; 
        }
        return true
      }

      $scope.saveCommunityDo = async () => {

        if ($scope.aws.minimumAppVersion || $scope.aws.forceUsersUpgrade || $scope.aws.warnUsersUpgrade || $scope.aws.allowLegacyAdmin) {
          if (!$scope.validateAws($scope.aws)) return; 
          $scope.saveAws = true; 
        }

        if ($scope.saveAws) {
          const whitelabelData = _.clone($scope.whitelabel); 
          const awsConfig = _.clone($scope.aws);
          whitelabelData.awsConfig = awsConfig
          await communities.helpers.saveAwsConfig(whitelabelData)
        }

        if ($scope.formData.collections.length > 0) {
          $scope.community.collections = CommonUtil.getIdList($scope.formData.collections)
        } else { delete $scope.community.collections }

        if ($scope.formData.categories.length > 0) {
          $scope.community.categories = CommonUtil.getIdList($scope.formData.categories)
        } else { delete $scope.community.categories }
        /* if($scope.formData.sprints.length > 0) {
            $scope.community.sprints = CommonUtil.getIdList($scope.formData.sprints);
        }
        else {
            delete $scope.community.sprints;
        } */

        if ($scope.community._geoloc && $scope.community._geoloc[0] && $scope.community._geoloc[1] && $scope.community._geoloc[0] != '' && $scope.community._geoloc[1] != '') { $scope.community._geoloc = [parseFloat($scope.community._geoloc[0]), parseFloat($scope.community._geoloc[1])] } else { delete $scope.community._geoloc }

        const data = _.clone($scope.community);

        if (data.code) data.code = data.code.toLowerCase()
        else data.code = null

        if (data.logo) {
          data.logo = CommonUtil.makeBendFile(data.logo._id)
        }

        if (
          data.menu &&
          !commonUtil.objectsAreSame(data.menu[0]) && // not sure why menu is getting an empty object by default, but this is a cool fn!
          data.menu.length > 0
        ) {
          data.menu.map((item) => {
            delete item.$$hashKey
            return item
          })
        } else delete data.menu

        if (data._id) {
          delete data.$$hashKey
          await communities.helpers.update(data)
        } else {
          const ret = await communities.helpers.create(data)
          $scope.community._id = ret._id
        }
        applyChangesOnScope($scope, () => {
          $location.path('/communities')
        })
      }

      $scope.deleteCommunity = async (id, e) => {
        e.preventDefault()
        e.stopPropagation()
        const yeap = confirm('Deleting the client is permanent and can not be undone.')
        if (!yeap) {
          return false
        }
        await communities.helpers.delete(id)
        applyChangesOnScope($scope, () => {
          $location.path('/communities')
        })
      }

      $scope.canSubmitValidationForm = function () {
        return $scope.form.validateForm.$valid
      }

      $scope.openFile = function (fileName) {
        $("input[name='" + fileName + "']").click()
      }

      $scope.deleteFile = function (tag, $ev) {
        $ev.stopPropagation()
        $ev.preventDefault()
        applyChangesOnScope($scope, () => {
          delete $scope.community.logo
        })

        $scope.form.validateForm.$setDirty()
      }

      $scope.showFileLoading = function (tag, bShow) {
        $scope.isUploading[tag] = bShow
      }

      $scope.selectFileOpen = function (fileId, $ev) {
        if ($ev.target.tagName == 'DIV') {
          setTimeout(() => {
            $('#' + fileId).click()
          }, 0)
        }
      }

      $scope.onFileUpload = function (files, tag) {
        const file = files[0]

        $scope.fileProgress[tag] = 0
        $scope.showFileLoading(tag, true)
        communities.helpers.upload(file, (error, uploadedFile) => {
          if (error) {
            $scope.showFileLoading(tag, false)
            return
          }

          communities.helpers.getFile(uploadedFile, (o) => {
            $scope.community.logo = o
            $scope.showFileLoading(tag, false)
          })
          $scope.form.validateForm.$setDirty()
        }, {
          _workflow: 'logo'
        }, (total, prog) => {
          applyChangesOnScope($scope, () => {
            $scope.fileProgress[tag] = prog * 100 / total
          })
        })
      }

      $rootScope.setLocation = function (lat, lng) {
        $scope.community._geoloc = [lng, lat]
        $scope.form.validateForm.$setDirty()
      }

      $scope.geocodeAddress = function () {
        const addressList = []
        if ($scope.community.address1 && $scope.community.address1 != '') { addressList.push($scope.community.address1) }
        if ($scope.community.city && $scope.community.city != '') { addressList.push($scope.community.city) }
        if ($scope.community.state && $scope.community.state != '') { addressList.push($scope.community.state) }
        if ($scope.community.postalCode && $scope.community.postalCode != '') { addressList.push($scope.community.postalCode) }

        $modal.open({
          templateUrl: 'views/templates/geocodeAddress.html',
          backdrop: 'static',
          controller: function ($scope, $modalInstance) {
            $scope.cancel = function () {
              $modalInstance.dismiss('cancel')
            }
            $scope.close = function () {
              $modalInstance.dismiss('cancel')
            }
            $scope.confirmAddress = function () {
              const lat = $('#geoLat').val()
              const lng = $('#geoLng').val()
              if (lat == '' || lng == '') return

              $rootScope.setLocation(lat, lng)
              $modalInstance.dismiss('cancel')
            }

            let marker = null
            $scope.initMap = function () {
              const map = new google.maps.Map(document.getElementById('geo_map'), {
                zoom: 12,
                center: { lat: 42.3005383, lng: -71.0654838 }
              })

              const geocoder = new google.maps.Geocoder()

              if (addressList.length > 0) {
                const address = addressList.join(', ')
                document.getElementById('address').value = address
                $scope.geocodeAddress(geocoder, map)
              } else {
                if (navigator.geolocation) {
                  navigator.geolocation.getCurrentPosition((position) => {
                    const pos = {
                      lat: position.coords.latitude,
                      lng: position.coords.longitude
                    }

                    map.setCenter(pos)
                  })
                }
              }

              document.getElementById('submit').addEventListener('click', () => {
                $scope.geocodeAddress(geocoder, map)
              })

              map.addListener('click', (e) => {
                $scope.placeMarkerAndPanTo(e.latLng, map)
              })
            }

            setTimeout(() => {
              $scope.initMap()
            }, 100)

            $scope.placeMarkerAndPanTo = function (latLng, map) {
              if (marker) {
                marker.setPosition(latLng)
              } else {
                marker = new google.maps.Marker({
                  map: map,
                  position: latLng
                })
              }

              $('#geoLat').val(latLng.lat())
              $('#geoLng').val(latLng.lng())
            }

            $scope.geocodeAddress = function (geocoder, resultsMap) {
              const address = document.getElementById('address').value
              geocoder.geocode({ 'address': address }, (results, status) => {
                if (status === 'OK') {
                  resultsMap.setCenter(results[0].geometry.location)
                  $scope.placeMarkerAndPanTo(results[0].geometry.location, resultsMap)
                } else {
                  alert('Geocode was not successful for the following reason: ' + status)
                }
              })
            }
          }
        })
      }
    }])
