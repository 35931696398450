'use strict'

import * as organizations from '../../../src/features/organizations'

angular.module('app.controllers')
  .controller('OrganizationsController', ['$scope', '$modal', '$ngRedux',
    async function ($scope, $modal, $ngRedux) {
      $scope.isLoading = true
      $scope.errorMessage = null

      const mapStateToThis = (state) => ({
        organizationsList: state.organizations.list
      })

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      await organizations.helpers.init()
      $scope.isLoading = false
      const openModal = (organization) => {
        $scope.errorMessage = null

        const modalInstance = $modal.open({
          templateUrl: 'views/organizations/organizationEdit.html',
          backdrop: 'static',
          controller: 'OrganizationEditController',
          resolve: {
            organization: () => organization
          }
        })
        $scope.$modalInstance = modalInstance
        return modalInstance.result.then(() => organizations.helpers.init())
      }

      $scope.createOrganization = function () {
        $scope.errorMessage = null
        openModal(null)
      }
     
      $scope.deleteOrganization = (item, $ev) => {
        $scope.errorMessage = null

        $ev.stopPropagation()
       
        // const inUse = await organizations.helpers.isInUse(item._id)
        organizations.helpers.isInUse(item._id).then((inUse) => {
          if (inUse) {
            $scope.errorMessage = 'Organization is being used, cannot be deleted.'
            $scope.$apply()
          } else {
            organizations.helpers.delete(item._id)
          // await organizations.helpers.init()
          }
        })
      }

      $scope.editOrganization = (item) => {
        openModal(item)
      }
    }])
