import {
  UPDATE_ACTIVITIES_COMPLETED
} from '../constants/types'

export interface Action {
  type: string,
  completionCount: any
}

export const updateCompletionCount = (completionCount) => {
  return {
    type: UPDATE_ACTIVITIES_COMPLETED,
    completionCount
  }
}
