import {
  UPDATE_COMMENTS_TOTALS_SETTINGS,
  UPDATE_COMMENTS_PAGE_SETTINGS,
  UPDATE_COMMENTS_SUBJECT_SETTINGS,
} from '../constants/types'

export interface Action {
  type: string,
  count: number,
  page: number,
  subject: any,
  subjectType: string
}

export const updateCount = (count) => {
  return {
    type: UPDATE_COMMENTS_TOTALS_SETTINGS,
    count
  }
}
export const updatePage = (page) => {
  return {
    type: UPDATE_COMMENTS_PAGE_SETTINGS,
    page
  }
}
export const updateSubject = (subject, subjectType) => {
  return {
    type: UPDATE_COMMENTS_SUBJECT_SETTINGS,
    subject,
    subjectType
  }
}
