// app/views/index.html runs this controller

import * as statistics from '../../../src/features/statistics'
import * as leaderboards from '../../../src/features/leaderboards'
import * as communities from '../../../src/features/communities'
import * as newestUsers from '../../../src/features/newestUsers'
import * as charts from '../../../src/features/charts'
import * as teams from '../../../src/features/teams'
import commonUtil from '../../../src/helpers/commonUtil'
import Highcharts from 'highcharts'



angular.module('app.controllers')
  .controller('DashboardController', ['$scope', 'BendAuth', '$location', '$ngRedux',
    async function ($scope, BendAuth, $location, $ngRedux, $filter) {
      'use strict'
      $scope.isLoading = true
      $scope.commonUtil = commonUtil
      $scope.dashboardCharts = []
      $scope.user = await BendAuth.getActiveUser()
      $scope.goals = []
 
    

      $scope.viewChart = async (chart, divId) => {
        $scope.isLoading = true
        //console.log(chart, chart.teams)
        let chartObj = await charts.helpers.buildChart(chart, $scope.chartTeams)
        if (typeof chartObj === 'string') $scope.errorMsg = chartObj
        else {
          chartObj.chart.renderTo = divId
          Highcharts.chart(chartObj)
          //console.log("check")
          $scope.isLoading = false
        }
      }
    
      $scope.loadCommunities = function () {
        communities.helpers.initBoard()
      }
      
      if (!$scope.user || !$scope.user.community || !$scope.user.community._id) {
        console.log('INVALID CREDENTAILS, LOGGING OUT AND RELOADING')
        localStorage.clear()
        location.reload(true)
      } else if ($scope.user.communityAdmin) {
        $scope.communityId = $scope.user.community._id
        leaderboards.helpers.init($scope.user.communityAdmin, $scope.communityId)
        newestUsers.helpers.init($scope.user.communityAdmin, $scope.communityId)
        statistics.helpers.init($scope.user)
        teams.helpers.init()
     
        statistics.helpers.getGoal().then((data) => { $scope.goals = data})

   

        
        $scope.communityId = $scope.user.community._id
        $scope.chartTeams = await teams.helpers.getList()
        await charts.helpers.init().then(charts => {          
          charts = charts.sort((a, b) => {
            return b.order || 99 - a.order || 99
          })
          charts.forEach(chart => {
            // used for ng-repeat in html
            if (chart.dashboard) {
              $scope.dashboardCharts.push(chart._id)
              $scope.viewChart(chart, chart._id)
            }
          })
        })
        $scope.isLoading = false
      } else if (!$scope.user.communityAdmin) {
        $scope.loadCommunities()
        communities.helpers.init()
        $scope.isLoading = false
      }

      const mapStateToThis = (state) => {
        return {
          chartList: state.charts.list.charts || [],
          teams: state.teams.list.teams,
          activityData: state.charts.list.activity,
          demographicData: state.charts.list.demographics,
          userData: state.charts.list.users,
          tasks: state.tasks,
          data: state.statistics.data,
          leaderBoard: state.leaderboards.list,
          leaderboardPage: state.leaderboards.settings,
          newUsers: state.newestUsers.list,
          newUsersPage: state.newestUsers.settings,
          communities: state.communities.boardList
        }
      }
    
      $scope.goToChart = (chartId) => {
        $location.path(`/charts/${chartId}`)
      }

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      $scope.loadLeaderboardPage = function () {
        leaderboards.helpers.init($scope.user.communityAdmin, $scope.communityId)
        
      }

      // $scope.loadCommunities()

      $scope.loadNewUsersPage = function () {
        newestUsers.helpers.init($scope.user.communityAdmin, $scope.communityId)
      }
      
      // if ($scope.user.communityAdmin) { $scope.loadLeaderboardPage() }

      // $scope.loadNewUsersPage()

      $scope.onLeaderBoardPageChange = function () {
        $scope.loadLeaderboardPage()
       
      }

      $scope.onNewUsersPageChange = function () {
        $scope.loadNewUsersPage()
      }
    }])
