import {
  UPDATE_PUSH_TEMPLATES_LIST
} from '../constants/types'
import { PushTemplates } from '../reducers/list'

export interface Action {
  type: string,
  pushTemplates: PushTemplates
}

export const update = (pushTemplates: PushTemplates): Action => {
  return {
    type: UPDATE_PUSH_TEMPLATES_LIST,
    pushTemplates
  }
}
