import {
  UPDATE_GOALS_SETTINGS,
  UPDATE_GOALS_PAGE_SETTINGS,
  UPDATE_GOALS_GROUPS_SETTINGS,
  UPDATE_GOALS_TEAMS_SETTINGS,
  UPDATE_GOALS_SEGMENTS_SETTINGS,
  UPDATE_GOALS_STATE_SETTINGS,
  UPDATE_GOALS_SORTING_SETTINGS,
  RESET_GOALS_SETTINGS
} from '../constants/types'

import { Action } from '../actions/settings'

export interface Settings {
  itemsPerPage: number,
  isLoading: boolean,
  totalItems: number,
  currentPage: number,
  numPages: number,
  group: any,
  team: any,
  segments: any,
  enabled: any,
  sortKey: string,
  sortDirection: Object
}

const initialState: Settings = {
  itemsPerPage: 20,
  isLoading: true,
  totalItems: 0,
  currentPage: 1,
  numPages: 0,
  group: null,
  team: null,
  segments: null,
  enabled: null,
  sortKey: '',
  sortDirection: {
    totalUsers: null,
    activeUsers: null,
    name: null
  }
}

export default (state: Settings = initialState, action: Action): Settings => {
  switch (action.type) {
    case UPDATE_GOALS_SETTINGS:
      return {
        ...state,
        totalItems: action.count,
        numPages: Math.ceil(action.count / state.itemsPerPage + 1)
      }
    case UPDATE_GOALS_PAGE_SETTINGS:
      return {
        ...state,
        currentPage: action.page
      }
    case UPDATE_GOALS_GROUPS_SETTINGS:
      return {
        ...state,
        group: action.group
      }
    case UPDATE_GOALS_TEAMS_SETTINGS:
      return {
        ...state,
        team: action.team
      }
    case UPDATE_GOALS_SEGMENTS_SETTINGS:
      return {
        ...state,
        segments: action.segments
      }
    case UPDATE_GOALS_STATE_SETTINGS:
      return {
        ...state,
        enabled: action.enabled
      }
    case UPDATE_GOALS_SORTING_SETTINGS:
      return {
        ...state,
        sortKey: action.sortKey,
        sortDirection: action.sortDirection
      }
    case RESET_GOALS_SETTINGS:
      return initialState
    default:
      return state
  }
}
