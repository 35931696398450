'use strict'

import * as pollQuestions from '../../../src/features/pollQuestions'
import * as communities from '../../../src/features/communities'

angular.module('app.controllers')
  .controller('PollQuestionController', ['$scope', '$location', 'BendAuth', '$ngRedux',
    async function ($scope, $location, BendAuth, $ngRedux) {
       $scope.isLoading = true

      const mapStateToThis = (state) => {
        return {
          communityList: state.communities.list,
          pollQuestionsList: state.pollQuestions.list,
          collectionView: {
            itemsPerPage: state.pollQuestions.settings.itemsPerPage,
            isLoading: state.pollQuestions.settings.isLoading,
            totalItems: state.pollQuestions.settings.totalItems,
            currentPage: state.pollQuestions.settings.currentPage,
            numPages: state.pollQuestions.settings.numPages
          },
          filter: {
            community: state.pollQuestions.settings.community,
            team: state.pollQuestions.settings.team
          },
          fields: {
            community: state.pollQuestions.settings.community,
            teams: state.pollQuestions.settings.communityTeams
          }
        }
      }

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      applyChangesOnScope($scope, () => {
      
        if ($scope.pollQuestionsList.length === 0) {
          $scope.isLoading = true
          
          
        } else {
          $scope.isLoading = false
          
        }
      })
      
      // user object needs to set community ID to determine what poll questions load
      $scope.user = BendAuth.getActiveUser()
      
      $scope.selectCommunity = async communityId => {
        $scope.isLoading = true
        
        pollQuestions.helpers.selectCommunity(communityId).then(() => {
         
          
        })
      }
      

      if ($scope.user.communityAdmin) {
        $scope.selectCommunity($scope.user.community._id)
      }

      // must be called after community is set above
      communities.helpers.initEnabled().then(() => {
        if (typeof $scope.filter.community === 'object' && !$scope.filter.community._id) {
          const community = _.get($scope.communityList, '[0]._id')
          $scope.selectCommunity(community)
        }
        setTimeout(() => $scope.loadList(), 200)
        
          
       
      })


      // Loads poll questions by invoking helpers file
      $scope.loadList = async () => {
       
        $scope.isLoading = true
        
        await pollQuestions.helpers.init()
       
       
       $scope.isLoading = false
        applyChangesOnScope($scope, () => {})
       
              
        
      }
      
   

      $scope.selectTeam = async teamId => {
       
        $scope.isLoading = true
        await pollQuestions.helpers.selectTeam(teamId)
        $scope.isLoading = false
        
      }
      
      $scope.getTeamNames = function (teamIds) {
        if (!teamIds || teamIds.length == 0) return ''

        var names = []
        _.map($scope.fields.teams, function (o) {
          if (teamIds.indexOf(o._id) != -1) { names.push(o.name) }
        })

        return names.join(',')
      }

      $scope.getCommunityNames = function (list) {
        var ret = []
        _.map(list, function (o) {
          var client = _.find($scope.communityList, function (_o) {
            return _o._id == o
          })
          if (client) { ret.push(client.name) }
        })

        return ret.join(', ')
      }

      // path to edit poll question
      $scope.editPollQuestion = function (pollQuestions, $event) {
        if ($event.metaKey || $event.ctrlKey) {
          window.open('#/pollQuestiones/' + pollQuestions._id, '_blank')
        } else {
          return $location.path('/dailyPolls/' + pollQuestions._id)
        }
      }

      // path to create poll question 
      $scope.createPollQuestion = function ($event) {
        if ($event.metaKey || $event.ctrlKey) {
          window.open('#/dailyPolls/new', '_blank')
        } else { return $location.path('/dailyPolls/new') }
      }

      $scope.getResponseCount = function (list) {
        if (!list || list.length == 0) return 0

        if ($scope.user.communityAdmin) {
          return list[$scope.user.community._id] || 0
        }

        var count = 0
        _.map(list, function (o) {
          count += o
        })
       
        return count
      }
     
    }])
