//import BendClient from '../BendClient'
import * as actions from './actions'
import { Statistics } from './reducers/data'
import redux from '../../../src/helpers/redux'
import BendClient from '../BendClient'
import {getActiveUser} from '../../../src/helpers'

import * as Bend from '../../lib/bend-1.1.8'

class Feature {
  private api: any
  private bend: any

  constructor () {
    this.api = BendClient
    this.bend = Bend
  
  }

  public async init (user) {
    const response: Statistics = await this.api.fetchStatisticsData(user)
    if (response && response.totalUsers) { // check to prevent code showing up on the screen
      redux.dispatch(
        actions.data.update(response)
      )
    }
  }


  public async getGoal(){
    try{
      const query = new this.bend.Query()
      query.equalTo("enabled", true)
      query.notEqualTo('deleted', true)
      query.descending("_bmd.updatedAt");
      query.limit(10)

      if (getActiveUser().communityAdmin) {
        query.equalTo('community._id', getActiveUser().community._id)
      }
     
      return this.bend.DataStore.find('goals', query)

    }
    catch(error){
      console.log(error)
      return []
      }
      
    }
    

}

export default new Feature()
