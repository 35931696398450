import { combineReducers } from 'redux'
import list, { Comments } from './list'
import settings, { Settings } from './settings'

export interface CombineReducers {
  list: Comments,
  settings: Settings
}

const rootReducer = combineReducers<CombineReducers>({
  list,
  settings
})

export default rootReducer
