export const UPDATE_GROUPS_EXPORTS_LIST = 'UPDATE_GROUPS_EXPORTS_LIST'
export const UPDATE_GROUPS_LIST = 'UPDATE_GROUPS_LIST'
export const UPDATE_GROUPS_LIST_ITEM = 'UPDATE_GROUPS_LIST_ITEM'
export const UPDATE_GROUPS_MEMBERS = 'UPDATE_GROUPS_MEMBERS'
export const UPDATE_GROUPS_SETTINGS = 'UPDATE_GROUPS_SETTINGS'
export const UPDATE_GROUPS_SORTING_SETTINGS = 'UPDATE_GROUPS_SORTING_SETTINGS'
export const UPDATE_GROUPS_PAGE_SETTINGS = 'UPDATE_GROUPS_PAGE_SETTINGS'
export const UPDATE_GROUPS_KEYWORD_SETTINGS = 'UPDATE_GROUPS_KEYWORD_SETTINGS'
export const UPDATE_GROUPS_COMMUNITY_SETTINGS = 'UPDATE_GROUPS_COMMUNITY_SETTINGS'
export const RESET_GROUPS_SETTINGS = 'RESET_GROUPS_SETTINGS'
export const UPDATE_GROUPS_ITEM = 'UPDATE_GROUPS_ITEM'
