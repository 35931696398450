'use strict'
import * as volunteerings from '../../../src/features/volunteerings'
import * as categories from '../../../src/features/categories'
import commonUtil from '../../../src/helpers/commonUtil'
import bendHelper from '../../../src/helpers/bendHelper'

angular.module('app.controllers')
  .controller('VolunteeringController', ['$scope', '$location', 'BendAuth', '$ngRedux',
    async function ($scope, $location, BendAuth, $ngRedux) {
      $scope.CommonUtil = commonUtil

      const allCats = [{
        name: 'All categories',
        _id: null
      }]

      const mapStateToThis = (state) => ({
        activities: state.activities.list,
        volunteeringList: state.volunteerings.list,
        categories: allCats.concat(state.categories.list),
        collectionView: {
          itemsPerPage: state.volunteerings.settings.itemsPerPage,
          totalItems: state.volunteerings.settings.totalItems,
          currentPage: state.volunteerings.settings.currentPage,
          numPages: state.volunteerings.settings.numPages
        },
        filter: {
          searchTerm: state.volunteerings.settings.searchTerm,
          community: state.volunteerings.settings.community,
          category: state.volunteerings.settings.category,
          collection: state.volunteerings.settings.collectionId
        }
      })

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      if ($scope.volunteeringList.length === 0) {
        $scope.isLoading = true
      }

      $scope.sortDirection = {
        'name': 1,
        '_bmd.updatedAt': 1
      }
      $scope.sortKey = 'name'

      $scope.user = BendAuth.getActiveUser()

      if ($scope.volunteeringList.length === 0) {
        $scope.isLoading = true
      }

      $scope.onSearch = function () {
        $scope.isLoading = true
        volunteerings.helpers.selectSearchTerm($scope.filter.searchTerm)
        $scope.loadList()
      }

      $scope.selectCategory = (category) => {
        $scope.isLoading = true
        volunteerings.helpers.selectCategory(category)
        $scope.loadList()
      }

      $scope.selectCollection = (collectionId) => {
        $scope.isLoading = true
        volunteerings.helpers.selectCollection(collectionId, $scope.collectionList)
        $scope.loadList()
      }

      $scope.selectClient = (communityId) => {
        $scope.isLoading = true
        volunteerings.helpers.selectCommunity(communityId, $scope.communityList)
        $scope.loadList()
      }

      $scope.reset = function () {
        $scope.isLoading = true
        volunteerings.helpers.reset()
        $scope.loadList()
      }

      $scope.collectionList = []

      $scope.communityList = [{
        name: 'All client',
        _id: null
      }]

      async.parallel([
        function (cb) {
          categories.helpers.getList()
          volunteerings.helpers.getCollectionEnabled().then((rets) => {
            $scope.allcollections = {
              name: 'All Collections',
              _id: null
            }
            $scope.collectionList = [$scope.allcollections].concat(rets)
            cb(null)
          })
        },
        function (cb) {
          volunteerings.helpers.getCommunityList().then((rets) => {
            $scope.communityList = $scope.communityList.concat(rets)
            cb(null)
          })
        }
      ], async (err, rets) => {
        await $scope.loadList()
        $scope.sortBy('_bmd.updatedAt')
      })

      $scope.sortBy = function (key) {
        $scope.sortKey = key
        $scope.sortDirection[key] = (-1) * $scope.sortDirection[key]
        volunteerings.helpers.sortBy(key, $scope.sortDirection)
      }

      $scope.loadList = function () {
        volunteerings.helpers.getList().then(() => {
          $scope.isLoading = false
        })
      }

      $scope.sortDirection = {
        'name': 1,
        '_bmd.updatedAt': -1
      }

      $(document).on('click', '.pagination li:not(.disabled) a', (e) => {
        $scope.isLoading = true
        const n = Number($(e.target).text())
        volunteerings.helpers.paginateToPage(!isNaN(n) ? n : $scope.collectionView.currentPage + ($(e.target).text() === 'Prev' ? -1 : +1))
        $scope.loadList()
      })

      $scope.onPageChange = function () {
        $scope.loadList()
      }

      $scope.editVolunteering = function (volunteering, $e) {
        if ($e.metaKey || $e.ctrlKey) {
          window.open('#/volunteerings/' + volunteering._id, '_blank')
        } else { return $location.path('/volunteerings/' + volunteering._id) }
      }

      $scope.createVolunteering = function ($event) {
        if ($event.metaKey || $event.ctrlKey) {
          window.open('#/volunteerings/new', '_blank')
        } else { return $location.path('/volunteerings/new') }
      }

      $scope.enableToggle = async (item, e) => {
        // console.log("enable toggle")
        await bendHelper.enableHelper(e, item, volunteerings)
      }
    }])
