import {
  UPDATE_POLL_QUESTIONS_SETTINGS,
  UPDATE_POLL_QUESTIONS_PAGE_SETTINGS,
  UPDATE_POLL_QUESTIONS_COMMUNITY_SETTINGS,
  UPDATE_POLL_QUESTIONS_TEAMS_SETTINGS,
  UPDATE_POLL_QUESTIONS_TEAM_SETTINGS,
  RESET_POLL_QUESTIONS_SETTINGS
} from '../constants/types'
import { Settings } from '../reducers/settings'

export interface Action {
  type: string,
  users: Settings,
  count: number,
  page: number,
  communityId: string,
  communityTeams: any,
  team: any
}

export const update = (count) => {
  return {
    type: UPDATE_POLL_QUESTIONS_SETTINGS,
    count
  }
}

export const pageUpdate = (page) => {
  return {
    type: UPDATE_POLL_QUESTIONS_PAGE_SETTINGS,
    page
  }
}

export const selectCommunity = (communityId) => {
  return {
    type: UPDATE_POLL_QUESTIONS_COMMUNITY_SETTINGS,
    communityId
  }
}

export const selectTeam = (team) => {
  return {
    type: UPDATE_POLL_QUESTIONS_TEAM_SETTINGS,
    team
  }
}

export const teamsUpdate = (communityTeams) => {
  return {
    type: UPDATE_POLL_QUESTIONS_TEAMS_SETTINGS,
    communityTeams
  }
}

export const reset = () => {
  return {
    type: RESET_POLL_QUESTIONS_SETTINGS
  }
}
