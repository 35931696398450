'use strict'

import commonUtil from '../../../src/helpers/commonUtil'
import * as challenges from '../../../src/features/challenges'
import * as teams from '../../../src/features/teams'
import * as segments from '../../../src/features/segments'
import * as pushNotifications from '../../../src/features/pushNotifications'

angular.module('app.controllers')
  .controller('ChallengeEditController', ['$scope', '$location', '$routeParams', 'BendAuth', '$rootScope', '$modal', '$ngRedux',
    async function ($scope, $location, $routeParams, BendAuth, $rootScope, $modal, $ngRedux) {
      // Init.
      $scope.CommonUtil = commonUtil
      $scope.challengeId = null
      $scope.editMode = false
      if ($routeParams.id != 'new') {
        $scope.challengeId = $routeParams.id
        $scope.editMode = true
      }
      $scope.isLoading = true
      $scope.user = BendAuth.getActiveUser()
      
      teams.helpers.init()
      segments.helpers.init()

      $scope.formData = {
        communities: [],
        teams: [],
        segments: []
      }

      const formDataKeys = Object.keys($scope.formData)

      const mapStateToThis = (state) => {
        // formDataHelper($scope, 'challenge')
        // prep formdata to display when loading data
        if (
          $scope.teams &&
          $scope.segments
        ) {
          formDataKeys.map(key => {
            if (
              !$scope.challenge ||
              !$scope.challenge[key]
            ) {
              return
            }
            $scope.formData[key] = $scope[key].filter(obj => {
              return $scope.challenge[key].indexOf(obj._id) !== -1
            })
          })
        }
        return {
          teams: state.teams.list.teams,
          segments: state.segments.list
        }
      }

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)
      

      $scope.communityList = []
      $scope.businessList = []
      $scope.actionList = []
      $scope.eventList = []
      $scope.volunteeringList = []
      $scope.serviceList = []
      $scope.activityList = []
      // $scope.categories = []
      // $scope.selectedCategory = {}

      $scope.fileProgress = []
      $scope.isUploading = []

      $scope.filter = {
        activity: ''
      }
      $scope.TypeList = ['action', 'business', 'event', 'volunteer_opportunity', 'website','survey']

      $scope.challenge = {
        type: ''
      }

      $scope.formControls = {
        repeating: false,
        openedDateSelector: false
      }

      $scope.openDateWindow = function ($event) {
        $event.preventDefault()
        $event.stopPropagation()

        $scope.formControls.openedDateSelector = true
      }

      $scope.formatDates = dateArr => {
        if (dateArr.length > 0) {
          _.map(dateArr, date => {
            date.startsAt = commonUtil.formatDateWithFormat(date.startsAt, 'YYYY-MM-DD LT')
          })
        }
      }

      $scope.teams = []
      $scope.timeS = new Date().valueOf()

      if ($scope.challengeId) { // if challenge exists
        challenges.helpers.get($scope.challengeId).then(function (ret) {
          $scope.challenge = _.clone(ret)
          $scope.oldChallenge = _.clone(ret)
          $scope.isLoading = false
          // update value for the toggle in the ui
          // find push associated with this poll, save it
          if (ret.push && ret.push._id) {
            const challengeId = _.get(ret, '_id', null)
            challenges.helpers.fetchLinkedPushNotification(challengeId).then(function (pushArr) {
              if (pushArr.length > 0) {
                $scope.linkedPush = pushArr[0]
                $scope.pushToggle = true
              } else {
                $scope.pushToggle = false
              }
            }, function (err) { })
          } else $scope.pushToggle = false

          if ($scope.challenge.communities && $scope.challenge.communities.length > 0) {
            $scope.formData.communities = _.filter($scope.communityList, function (o) {
              return $scope.challenge.communities.indexOf(o._id) != -1
            })
          }

          if ($scope.challenge.activity) {
            $scope.activityList = []
            $scope.activityList.push($scope.challenge.activity)
            $scope.filter.activity = $scope.challenge.activity
            // $scope.selectedCategory = $scope.getCategory($scope.challenge.activity)
          }
          if ($scope.challenge.startsAt) {
            // commonUtil.formatDate(ret.startsAt);
            // commonUtil.formatDateWithFormat(ret.startsAt,"YYYY-MM-DD");
            $scope.StartsAt = commonUtil.formatDateWithFormat(ret.startsAt, 'YYYY-MM-DD LT')
          }
          if ($scope.challenge.endsAt) {
            $scope.EndsAt = commonUtil.formatDateWithFormat(ret.endsAt, 'YYYY-MM-DD LT')
          }
          if ( // if there is a repeat object
            $scope.challenge.repeat &&
            ($scope.challenge.repeat.specificDates || $scope.challenge.repeat.consistentDates)
          ) {
            $scope.formControls.repeating = true
            $scope.formatDates($scope.challenge.repeat.dates)
            // if ($scope.challenge.repeat.every.unit === 'day') $scope.toggleRepeatType('day')
            if ($scope.challenge.repeat.every.unit === 'week') $scope.toggleRepeatType('week')
            if ($scope.challenge.repeat.every.unit === 'month') $scope.toggleRepeatType('month')
          } else { // if challenge has no repeat object
            $scope.challenge.repeat = {
              specificDates: false,
              consistentDates: false,
              every: {
                unit: '', // week/month
                interval: 0 // number of units between each occurrence
              },
              weekdays: [], // 3 letter abbrev
              months: [],
              dates: [], // "2018-05-02"
              expires: '',
              maxOccurrences: 999 // required
            }
          }
          $scope.weekInterval = {
            zero: $scope.challenge.repeat.every.interval === 0,
            one: $scope.challenge.repeat.every.interval === 1,
            two: $scope.challenge.repeat.every.interval === 2
          }

          applyChangesOnScope($scope, () => {})
        })
      } else if ($routeParams.id === 'new') {
        $scope.isLoading = false
        $scope.linkedPushToggle = false
        // repeat setup
        $scope.formControls.repeating = false

        $scope.challenge.repeat = {
          specificDates: false,
          consistentDates: false,
          every: {
            unit: '', // week/month
            interval: 0 // number of units between each occurrence
          },
          weekdays: [], // 3 letter abbrev
          months: [],
          endsAt: 0,
          dates: [], // "2018-05-02"
          expires: '',
          maxOccurrences: 999 // required
        }

        $scope.weekInterval = {
          zero: true,
          one: false,
          three: false
        }
      }

      $scope.getSegmentInfo = (segment) => {
        return segments.helpers.getSegmentInfo(segment)
      }

      $scope.updateTeams = () => {
        var communityIds = []
        ;($scope.formData.communities || []).forEach((o) => {
          communityIds.push(o._id)
        })
        challenges.helpers.getTeamsByCommunity({
          communityId: communityIds
        }).then(function (rets) {
          $scope.teams = _.orderBy(rets, (i) => i.name.toLowerCase(), ['asc'])

          if (!$scope.challenge.teams || $scope.challenge.teams.length == 0) { return }

          $scope.formData.teams = _.filter($scope.teams, (o) => {
            return $scope.challenge.teams.indexOf(o._id) != -1
          })
        })
      }

      $scope.refreshActivity = function (keyword) {
        const settings = {
          type: _.get($scope, 'challenge.type'),
          keyword
        }

        if ($scope.challenge.type == '') return

        challenges.helpers.refreshActivities(settings).then(function (rets) {
          $scope.activityList = rets
        })
      }

      $scope.isValidForm = function () {
        var isValid = $scope.form.validateForm.$dirty && $scope.form.validateForm.$valid
        if ($scope.isSaving) {
          isValid = false
        }

        return isValid
      }

      $scope.onChangeType = function () {
        $scope.activityList = []
        $scope.filter.activity = ''
        if ($scope.challenge.type == 'website') {
          $scope.challenge.headlineColor = '#ffffff'
        }
      }

      $scope.onChangeActivity = function () {
        $scope.challenge.points = $scope.filter.activity.points
        let communityId = $scope.user.community._id
        // $scope.challenge.imageNum = 1
        // used to set to 1. this is a hack to prevent an image from showing up for concilio and milkcrate apps only
        // if no imageNum exists for either, one of the image icons will show up anyway
        if (communityId === 'bfLyRS1BSSGkeSnbJsz6kg' || communityId === '58dec3004bad30145b037541') $scope.challenge.imageNum = 4
      }

      $scope.days = commonUtil.weekdays
      $scope.months = commonUtil.months

      $scope.toggleConsistent = {
        // day: false,
        week: false,
        month: false
      }

      $scope.toggleWeekInterval = (interval) => {
        if (interval === 0) {
          $scope.weekInterval.one = false
          $scope.weekInterval.two = false
        }
        if (interval === 1) {
          $scope.weekInterval.zero = false
          $scope.weekInterval.two = false
        }
        if (interval === 2) {
          $scope.weekInterval.zero = false
          $scope.weekInterval.one = false
        }
        if (interval !== $scope.challenge.repeat.every.interval) {
          $scope.challenge.repeat.every.interval = interval
        } else {
          $scope.challenge.repeat.every.interval = 0
        }
      }

      $scope.toggleRepeatTypeCallCount = 0

      $scope.toggleRepeatType = (type) => {
        if (
          $scope.toggleRepeatTypeCallCount > 0 && // if not the first time this fn is called
          $scope.challenge.repeat.every.unit === type
        ) { // if toggling again with same type, clear value
          $scope.challenge.repeat.every.unit = ''
        } else {
          $scope.challenge.repeat.every.unit = type // else update type
        }
        // if (type === 'day') { // set other types to false to make only one toggle true ever
        //   $scope.toggleConsistent.week = false
        //   $scope.toggleConsistent.month = false
        //   if ($scope.toggleRepeatTypeCallCount === 0) $scope.toggleConsistent.day = true
        // }
        if (type === 'week') {
          // $scope.toggleConsistent.day = false
          $scope.toggleConsistent.month = false
          if ($scope.toggleRepeatTypeCallCount === 0) $scope.toggleConsistent.week = true // ugly, I know. but necessary for now
        } else if (type === 'month') {
          // $scope.toggleConsistent.day = false
          $scope.toggleConsistent.week = false
          if ($scope.toggleRepeatTypeCallCount === 0) $scope.toggleConsistent.month = true
        }
        $scope.toggleRepeatTypeCallCount++
      }

      $scope.toggleConsistentDates = () => {
        if ($scope.challenge.repeat.consistentDates) $scope.challenge.repeat.specificDates = false
      }

      $scope.toggleSpecificDates = () => {
        if ($scope.challenge.repeat.specificDates) $scope.challenge.repeat.consistentDates = false
      }

      $scope.addRepeatDate = () => {
        $scope.challenge.repeat.dates.push({ startsAt: new Date() })
      }

      $scope.removeDate = (index) => {
        $scope.challenge.repeat.dates.splice(index, 1)
      }

      $scope.toggleAutoPush = () => {
        $scope.linkedPushToggle = !$scope.linkedPushToggle
      }

      $scope.toggleRepeating = () => {
        if (!$scope.formControls.repeating) {
          $scope.challenge.repeat.consistentDates = false
          $scope.challenge.repeat.specificDates = false
        }
      }

      // form validation using angular ng-class
      $scope.validateForm = () => {
        const required = 'required'

        const type = _.get($scope.challenge, 'type')
        const url = _.get($scope.challenge, 'url')
        const headline = _.get($scope.challenge, 'headline')
        const coverImage = _.get($scope.challenge, 'coverImage')
        const title = _.get($scope.challenge, 'title')
        const points = _.get($scope.challenge, 'points')
        const activity = _.get($scope.filter, 'activity')
        const startsAt = _.get($scope, 'StartsAt')
        const endsAt = _.get($scope, 'EndsAt')

        // website or not specific checks
        if (type != 'website') {
          if (activity === '' || !activity) $scope.activityReq = required
        } else { // if type is website
          if (url === '' || !url) $scope.urlReq = required
          if (headline === '' || !headline) $scope.headlineReq = required
        }
        // generic checks
        if (coverImage === '' || !coverImage) $scope.coverImageReq = required
        if (title === '' || !title) $scope.titleReq = required
        if (type === '' || !type) $scope.typeReq = required
        if (points === '' || !points) $scope.pointsReq = required
        if (startsAt === '' || !startsAt) $scope.startsAtReq = required
        if (endsAt === '' || !endsAt) $scope.endsAtReq = required

        // trigger error message
        if (!type || type === 'website') { // checks if type is website
          if (!headline ||
              !points ||
              !url ||
              !coverImage ||
              !startsAt ||
              !endsAt
          ) {
            $scope.errorMsg = true
            return false
          }
        }
        if (!type || type != 'website') {
          if (!title ||
            !activity ||
            !points ||
            !coverImage ||
            !startsAt ||
            !endsAt
          ) {
            $scope.errorMsg = true
            return false
          }
        }
        return true
    }
      $scope.saveChallengeDo = async () => {
        if (!$scope.validateForm())return
        if ($scope.StartsAt) {
          $scope.challenge.startsAt = commonUtil.convertStringToDate($scope.StartsAt, 'YYYY-MM-DD LT').getTime() * 1000000
        } else {
          delete $scope.challenge.startsAt
        }
        if ($scope.EndsAt) {
          $scope.challenge.endsAt = commonUtil.convertStringToDate($scope.EndsAt, 'YYYY-MM-DD LT').getTime() * 1000000
        } else {
          delete $scope.challenge.endsAt
        }

        if ($scope.challenge.type) {
          if ($scope.filter.activity != '') {
            $scope.challenge.activity = commonUtil.makeBendRef($scope.filter.activity._id, $scope.challenge.type)
          } else {
            delete $scope.challenge.activity
          }
        }

        if ($scope.challenge.points && $scope.challenge.points != '') {
          $scope.challenge.points = Number($scope.challenge.points)
        } else {
          delete $scope.challenge.points
        }

        var challengeData = _.clone($scope.challenge)
        if (challengeData.coverImage) {
          challengeData.coverImage = commonUtil.makeBendFile(challengeData.coverImage._id)
        }

        formDataKeys.map(key => {
          if ($scope.formData[key] && $scope.formData[key].length > 0) {
            challengeData[key] = $scope.formData[key].map(obj => obj._id)
          } else {
            delete challengeData[key]
          }
        })
        // if ($scope.formData.teams && $scope.formData.teams.length > 0) {
        //   challengeData.teams = commonUtil.getIdList($scope.formData.teams)
        // } else {
        //   delete challengeData.teams
        // }

        if ($scope.challenge.repeat && $scope.challenge.repeat.dates.length > 0) {
          _.map($scope.challenge.repeat.dates, date => {
            var bendDate = new Date(date.startsAt).getTime() * 1000000
            date.startsAt = bendDate
          })
        }
        $location.path('/challenges') // moved this call before API because save animation would go and page would never redirect. this still saves data
        if ($scope.challengeId) { // if challenge exists
          $scope.isSaving = true
          delete challengeData.$$hashKey
          challenges.helpers.update(challengeData)
          // applyChangesOnScope($scope, () => {
          //   $scope.isSaving = false
          //   if (autoPush.shouldUpdateAutoPush($scope.oldChallenge, challengeRet, 'challenge', $scope.linkedPushToggle)) {
          //     autoPush.autoPush(challengeRet.title, challengeRet, 'challenge', $scope.user)
          //   }
          // })
        } else { // if new challenge
          $scope.isSaving = true
          // let community
          // if (!$scope.user.communityAdmin) {
          //   community = $scope.formData.communities
          // } else {
          //   community = [$scope.user.community]
          // }
          challenges.helpers.createChallenge(challengeData)
          // challenges.helpers.createChallenge(challengeData, community, function (err, challengeRet) {
          //   $scope.isSaving = false
          //   if (err) {
          //     return
          //   }
          //   if (autoPush.shouldUpdateAutoPush($scope.oldChallenge, challengeRet, 'challenge', $scope.linkedPushToggle)) {
          //     autoPush.autoPush(challengeRet.title, challengeRet, 'challenge', $scope.user)
          //   }
          // })
        }
      }

      $scope.deleteFile = function (tag, $ev) {
        $ev.stopPropagation()
        $ev.preventDefault()
        applyChangesOnScope($scope, function () {
          delete $scope.challenge[tag]
        })
      }

      $scope.deleteChallenge = async (id, e) => {
        e.preventDefault()
        e.stopPropagation()
        const yeap = confirm('Deleting the challange is permanent and can not be undone.')
        if (!yeap) {
          return false
        }
        const response = await challenges.helpers.delete(id)
        if (!response) {
          return false
        }
        applyChangesOnScope($scope, () => {
          $location.path('/challenges')
          const pushId = _.get(response, 'push._id', null)
          if (pushId) {
            pushNotifications.helpers.delete(pushId)
          }
        })
      }

      $scope.showFileLoading = function (tag, bShow) {
        $scope.isUploading[tag] = bShow
      }

      $scope.selectFileOpen = function (fileId, $ev) {
        if ($ev.target.tagName == 'DIV') {
          setTimeout(function () {
            $('#' + fileId).click()
          }, 0)
        }
      }

      $scope.onFileUpload = function (files, tag) {
        var file = files[0]
        $scope.fileProgress[tag] = 0
        $scope.showFileLoading(tag, true)
        var reader = new FileReader()
        reader.onload = function (e) {
          $scope.uploadingFileUrl = e.target.result
        }
        reader.readAsDataURL(file)

        challenges.helpers.upload(file, function (error, uploadedFile) {
          if (error) {
            $scope.showFileLoading(tag, false)
            $scope.uploadingFileUrl = null
            return
          }
          challenges.helpers.getFile(uploadedFile, function (o) {
            $scope.challenge[tag] = o
            $scope.showFileLoading(tag, false)
            $scope.form.validateForm.$dirty = true
            $scope.uploadingFileUrl = null
          })
        }, {
          _workflow: 'coverPhoto'
        }, function (total, prog) {
          applyChangesOnScope($scope, function () {
            $scope.fileProgress[tag] = prog * 100 / total
          })
        })
      }

      $rootScope.fileUploadFromSearch2 = function (file, tag) {
        file._filename = Date.now() + ''
        var files = []
        files.push(file)
        $scope.onFileUpload(files, tag)
      }

      $scope.searchImages2 = function ($ev, tag) {
        $ev.stopPropagation()
        $ev.preventDefault()
        $modal.open({
          templateUrl: 'views/templates/searchImages.html',
          backdrop: 'static',
          controller: function ($scope, $modalInstance) {
            $scope.searchResults = 0
            $scope.pages = 1
            $scope.showLoadMore = false
            // var cacheSearchKey = ''

            $scope.cancel = function () {
              $modalInstance.dismiss('cancel')
            }
            $scope.close = function () {
              $modalInstance.dismiss('cancel')
            }

            $scope.searchImages = function (searchVal) {
              // cacheSearchKey = searchVal

              $scope.pages = 1
              if (searchVal) {
                var URL = 'https://pixabay.com/api/?key=2706353-ee3016f8af51ca406a8c8a3db&q=' + encodeURIComponent(searchVal) + '&image_type=photo&page=' + $scope.pages
                $.getJSON(URL, function (data) {
                  if (parseInt(data.totalHits) > 0) {
                    applyChangesOnScope($scope, function () {
                      if (data.totalHits > $scope.pages * 20) { $scope.showLoadMore = true } else { $scope.showLoadMore = false }

                      $scope.searchResults = data.hits
                    })
                  }
                })
              }
            }

            $scope.searchImagesMore = function (tabIdx, searchVal) {
              $scope.pages++
              if (searchVal) {
                var URL = 'https://pixabay.com/api/?key=2706353-ee3016f8af51ca406a8c8a3db&q=' + encodeURIComponent(searchVal) + '&image_type=photo&page=' + $scope.pages
                $.getJSON(URL, function (data) {
                  if (parseInt(data.totalHits) > 0) {
                    applyChangesOnScope($scope, function () {
                      if (data.totalHits > $scope.pages * 20) { $scope.showLoadMore = true } else { $scope.showLoadMore = false }

                      $scope.searchResults = $scope.searchResults.concat(data.hits)
                    })
                  }
                })
              }
            }

            $scope.selectImage = function (searchItem) {
              $scope.isDownloading = true
              var imageUrl = ''
              imageUrl = searchItem.webformatURL

              var xhr = new XMLHttpRequest()
              xhr.open('GET', imageUrl, true)
              xhr.responseType = 'blob'
              xhr.onload = function (e) {
                if (this.status == 200) {
                  var myBlob = this.response
                  $rootScope.fileUploadFromSearch2(myBlob, tag)
                  // myBlob is now the blob that the object URL pointed to.
                  $scope.cancel()
                }
              }
              xhr.send()
            }
          }
        })
      }
    }
  ])
