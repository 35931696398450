import * as _ from 'lodash'
import * as actions from './actions'
import BendClient, { BendTable } from '../BendClient'
import commonUtil from '../../helpers/commonUtil'
import * as Bend from '../../lib/bend-1.1.8'
import redux from '../../../src/helpers/redux'
import { getActiveUser } from '../../helpers'

class Feature {

  private api: any
  private api2: any

  constructor () {
    this.api = BendClient
    this.api2 = BendTable
  }

  public async reset () {
    redux.dispatch(
      actions.settings.reset()
    )
    await this.selectCommunity(null)
  }

  // Fetches all questions from Poll Question collection, filtering on the community ID  
  public async init () {
    // let settings = redux.getState().surveyQuestions.settings
    let settings = redux.getState().surveys.settings
    let surveysList: any = []
    // settings.communityId = getActiveUser().community._id
    // settings.survey = true
    // const list = await this.api2.getList(BendTable.Tables.POLL_QUESTION, settings)
    // console.log("list in helpers.ts is: ", list); 
    // console.log("settings is: ", settings); 

    surveysList = await this.api2.getList(BendTable.Tables.SURVEY, settings)

      let questionsArr: any = []; 

      _.map(surveysList, function(survey) {

        if (survey.version) {
         
          _.map(survey.questions, function(questions) {
            let questionObj = {
              surveyName: "",
              question: "",
              answerChoices: 0,
              createdAt: 0
            }; 
            questionObj.surveyName = survey.name;  
            questionObj.createdAt = survey._bmd.createdAt; 
            console.log("Q within second map is: ", questions[0]); 
            // console.log("Q with answers is: ", questions.answers); 
            // console.log("Q with q Obj is: ", questions.question); 
            // console.log("Q with answers LENGTH is: ", questions[0].answers.length); 
            // console.log("Q with qText is: ", questions[0].question.question); 
            questionObj.question = questions[0].question; 
            questionObj.answerChoices = questions[0].answers.length; 
            questionsArr.push(questionObj); 
          })
        }
      
      })
    
    // console.log(questionsArr); 


    redux.dispatch(
      actions.list.update(questionsArr)
    )
    redux.dispatch(
      actions.settings.update(questionsArr.length)
    )
  }

  public paginateToPage (page) {
    redux.dispatch(
      actions.settings.pageUpdate(page)
    )
  }

  public async selectCommunity (id) {
    redux.dispatch(
      actions.settings.selectCommunity(id)
    )
  }

  public deleteSurveyAnswer (id) {
    return this.api2.delete(BendTable.Tables.POLL_QUESTION_ANSWER, id)
  }

  public updateSurveyQuestion (data, answerList: Array<any> = [], callback) {
    const async = (window as any).async

    console.log("Bend.getActiveUser().communityAdmin", Bend.getActiveUser().communityAdmin); 
    if (Bend.getActiveUser().communityAdmin) {

      console.log("updateSurveyQuestion if called"); 
      const question = _.clone(data)

      question.answerCount = answerList.length
      let responseCount = 0
      _.map(answerList, function (o) {
        responseCount += (o.count || 0)
      })
      question.responseCount = responseCount

      console.log("surveyQuestions helpers.ts updateSurveyQuestion question: ", question); 

      Bend.execute('saveDataForCommunityAdmin', {
        type: 'pollQuestion',
        data: question
      }).then((ret) => {

        console.log("return of updateSurveyQuestion is: ", ret); 
        // save answers
        answerList.map(answer => {
          delete answer.$$hashKey
          if (responseCount > 0) { answer.percentage = answer.count * 100 / responseCount }
          answer.question = ret
          Bend.execute('saveDataForCommunityAdmin', {
            type: 'pollQuestionAnswer',
            data: answer
          })
        })
      })
    } else {
      console.log("updateSurveyQuestion else called"); 

      const question = _.clone(data)
      question.answerCount = answerList.length

      let responseCount = 0
      _.map(answerList, function (o) {
        responseCount += (o.count || 0)
      })

      question.responseCount = responseCount
      Bend.DataStore.update('pollQuestion', question).then((pollRet) => {
        let answers = $.extend(true, {}, answerList)
        _.map(answers, function (o) {
          delete o.$$hashKey
          if (responseCount > 0) { o.percentage = o.count * 100 / responseCount }
        })

        // get all original answers
        const query = new Bend.Query()
        query.equalTo('question._id', question._id)
        query.notEqualTo('deleted', true)
        Bend.DataStore.find('pollQuestionAnswer', query).then((oldAnswers) => {
          let deletedAnswers: any = []
          _.map(oldAnswers, function (o) {
            let exist = _.find(answers, function (_o) {
              return _o._id === o._id
            })

            if (!exist) {
              // delete
              o.deleted = true
              deletedAnswers.push(o)
            }
          })

          async.parallel([
            (cb) => {
              // save answer
              async.map(answers, (answer, _cb) => {
                Bend.DataStore.save('pollQuestionAnswer', answer).then(function (ret) {
                  _cb(null, ret)
                }, function (err) {
                  _cb(err)
                })
              }, function (err, ret) {
                cb(err, ret)
              })
            },
            (cb) => {
              // delete answer
              async.map(deletedAnswers, (answer, _cb) => {
                Bend.DataStore.save('pollQuestionAnswer', answer).then(function (ret) {
                  _cb(null, ret)
                }, function (err) {
                  _cb(err)
                })
              }, function (err, ret) {
                cb(err, ret)
              })
            }
          ], function (err, ret) {
            callback(pollRet, ret)
          })
        }, function (err) {
          callback(err)
        })
      })
    }
  }


  /* tslint:disable: handle-callback-err */
  // Original version
  // public updateSurveyQuestion (data, answerList: Array<any> = [], callback) {
  //   const async = (window as any).async
  //   if (Bend.getActiveUser().communityAdmin) {
  //     const question = _.clone(data)
  //     question.answerCount = answerList.length
  //     let responseCount = 0
  //     _.map(answerList, function (o) {
  //       responseCount += (o.count || 0)
  //     })
  //     question.responseCount = responseCount
  //
  //     Bend.execute('saveDataForCommunityAdmin', {
  //       type: 'pollQuestion',
  //       data: question
  //     }).then((ret) => {
  //       // save answers
  //       let answers = $.extend(true, {}, answerList)
  //       _.map(answers, (o) => {
  //         delete o.$$hashKey
  //         if (responseCount > 0) { o.percentage = o.count * 100 / responseCount }
  //       })
  //
  //       // get all original answers
  //       const query = new Bend.Query()
  //       query.equalTo('question._id', question._id)
  //       query.notEqualTo('deleted', true)
  //       Bend.DataStore.find('pollQuestionAnswer', query).then((oldAnswers) => {
  //         let deletedAnswers: any = []
  //         _.map(oldAnswers, function (o) {
  //           let exist = _.find(answers, function (_o) {
  //             return _o._id === o._id
  //           })
  //
  //           if (!exist) {
  //             // delete
  //             o.deleted = true
  //             deletedAnswers.push(o)
  //           }
  //         })
  //
  //         async.parallel([
  //           (cb) => {
  //             // save answer
  //             async.map(answers, (answer, _cb) => {
  //               Bend.execute('saveDataOnlyForCommunityAdmin', {
  //                 type: 'pollQuestionAnswer',
  //                 data: answer
  //               }).then(function (ret) {
  //                 _cb(null, ret)
  //               }, function (err) {
  //                 _cb(err)
  //               })
  //             }, function (err, answerList) {
  //               callback(ret, answerList)
  //               // cb(err, ret)
  //             })
  //           },
  //           (cb) => {
  //             // delete answer
  //             async.map(deletedAnswers, (answer, _cb) => {
  //               Bend.execute('saveDataOnlyForCommunityAdmin', {
  //                 type: 'pollQuestionAnswer',
  //                 data: answer
  //               }).then(function (ret) {
  //                 _cb(null, ret)
  //               }, function (err) {
  //                 _cb(err)
  //               })
  //             }, function (err, ret) {
  //               cb(err, ret)
  //             })
  //           }
  //         ])
  //       }, function (err) {
  //         callback(err)
  //       })
  //       callback(ret)
  //     }, function (err) {
  //       callback(err)
  //     })
  //   } else {
  //     const question = _.clone(data)
  //     question.answerCount = answerList.length
  //
  //     let responseCount = 0
  //     _.map(answerList, function (o) {
  //       responseCount += (o.count || 0)
  //     })
  //
  //     question.responseCount = responseCount
  //     Bend.DataStore.update('pollQuestion', question).then((pollRet) => {
  //       let answers = $.extend(true, {}, answerList)
  //       _.map(answers, function (o) {
  //         delete o.$$hashKey
  //         if (responseCount > 0) { o.percentage = o.count * 100 / responseCount }
  //       })
  //
  //       // get all original answers
  //       const query = new Bend.Query()
  //       query.equalTo('question._id', question._id)
  //       query.notEqualTo('deleted', true)
  //       Bend.DataStore.find('pollQuestionAnswer', query).then((oldAnswers) => {
  //         let deletedAnswers: any = []
  //         _.map(oldAnswers, function (o) {
  //           let exist = _.find(answers, function (_o) {
  //             return _o._id === o._id
  //           })
  //
  //           if (!exist) {
  //             // delete
  //             o.deleted = true
  //             deletedAnswers.push(o)
  //           }
  //         })
  //
  //         async.parallel([
  //           (cb) => {
  //             // save answer
  //             async.map(answers, (answer, _cb) => {
  //               Bend.DataStore.save('pollQuestionAnswer', answer).then(function (ret) {
  //                 _cb(null, ret)
  //               }, function (err) {
  //                 _cb(err)
  //               })
  //             }, function (err, ret) {
  //               cb(err, ret)
  //             })
  //           },
  //           (cb) => {
  //             // delete answer
  //             async.map(deletedAnswers, (answer, _cb) => {
  //               Bend.DataStore.save('pollQuestionAnswer', answer).then(function (ret) {
  //                 _cb(null, ret)
  //               }, function (err) {
  //                 _cb(err)
  //               })
  //             }, function (err, ret) {
  //               cb(err, ret)
  //             })
  //           }
  //         ], function (err, ret) {
  //           callback(pollRet, ret)
  //         })
  //       }, function (err) {
  //         callback(err)
  //       })
  //     })
  //   }
  // }


  
  public async createSurveyQuestion (data, communityList, answerList, callback) {
    const $bend = Bend
    const async = (window as any).async
    if ($bend.getActiveUser().communityAdmin) {
      let newData = _.clone(data)


     
      delete newData.$$hashKey; 

      _.map(newData.questions, function(answer) {
        console.log("first level map: ", answer); 
        _.map(answer, function(ans) {
          console.log("second level map: ", ans); 
          // ans.question.answerCount = ans.answers.length; 
          _.map(ans.answers, function(a) {
            delete a.question.required
            delete a.question.answerCount
            delete a.question.type
            delete a.question.responseCount
            delete a.question.details
            delete a.$$hashKey
            console.log("a is:", a); 
          })
       
        })
      })

      console.log("newData is: ", newData); 


      newData.community = commonUtil.makeBendRef($bend.getActiveUser().community._id, 'community')
      newData.communities = [$bend.getActiveUser().community._id] // for backward capability
      // newData.answerCount = answerList.length

     
      $bend.execute('saveDataForCommunityAdmin', {
        type: 'survey',
        data: newData
      }).then(function (ret) {
        // save answers

        

        let answers = $.extend(true, {}, answerList)
        _.map(answers, function (o) {
          delete o.$$hashKey
          o.question = commonUtil.makeBendRef(ret._id, 'pollQuestion')
        })

        async.map(answers, function (answer, _cb) {
          $bend.execute('saveDataOnlyForCommunityAdmin', {
            type: 'pollQuestionAnswer',
            data: answer
          }).then(function (ret) {
            _cb(null, ret)
          }, function (err) {
            _cb(err)
          })
        }, function (err, answerList) {
          callback(ret, answerList)
        })
      }, function (err) {
        callback(err)
      })
    } else {
      async.map(communityList, function (community, cb) {
        let newData = _.clone(data)
        newData.community = commonUtil.makeBendRef(community._id, 'community')
        newData.communities = [community._id] // for backward capability
        newData.answerCount = answerList.length

        $bend.DataStore.save('pollQuestion', newData).then(function (pollRet) {
          // save answers
          let answers = $.extend(true, {}, answerList)
          _.map(answers, function (o) {
            delete o.$$hashKey
            o.question = commonUtil.makeBendRef(pollRet._id, 'pollQuestion')
          })

          async.map(answers, function (answer, _cb) {
            $bend.DataStore.save('pollQuestionAnswer', answer).then(function (ret) {
              _cb(null, ret)
            }, function (err) {
              _cb(err)
            })
          }, function (err, ret) {
            callback(pollRet, ret)
            cb(err, ret)
          })
        }, function (err) {
          cb(err)
        })
      }, function (err, ret) {
        callback(err, ret)
      })
    }
  }

  public getCommunityList () {
    return this.api.getCommunityList()
  }

  public get (id) {
    return this.api2.get(BendTable.Tables.POLL_QUESTION, id)
  }

  public delete (id) {
    return this.api2.delete(BendTable.Tables.POLL_QUESTION, id)
  }

  public getAnswerList (id) {
    return this.api.getAnswerListClient(this.api2, id)
  }

  public getResponseList (id) {
    return this.api.getResponseList(id)
  }

  public deleteSurveyQuestionAnswer (id) {
    return this.api.deleteSurveyQuestionAnswer(id)
  }

  public updatePush (data) {
    return this.api.updatePushNotification(data)
  }

  public fetchLinkedPushNotification (settings: { surveyQuestionId: string }) {
    return this.api.fetchLinkedPushNotificationBySurveyQuestion(settings)
  }

}

export default new Feature()
