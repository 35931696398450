angular
  .module('theme.core.services', [])
  .factory('progressLoader', function () {
    'use strict'
    return {
      start: function () {
        angular.element.skylo('start')
      },
      set: function (position) {
        angular.element.skylo('set', position)
      },
      end: function () {
        angular.element.skylo('end')
      },
      get: function () {
        return angular.element.skylo('get')
      },
      inch: function (amount) {
        angular.element.skylo('show', function () {
          angular.element(document).skylo('inch', amount)
        })
      }
    }
  })
  .factory('EnquireService', ['$window', function ($window) {
    'use strict'
    return $window.enquire
  }])
  .factory('pinesNotifications', ['$window', function ($window) {
    'use strict'
    return {
      notify: function (args) {
        args.mouse_reset = false
        var notification = new $window.PNotify(args)
        notification.notify = notification.update
        return notification
      }
    }
  }])
  .factory('$bootbox', ['$modal', '$window', function ($modal, $window) {
    'use strict'
    // NOTE: this is a workaround to make BootboxJS somewhat compatible with
    // Angular UI Bootstrap in the absence of regular bootstrap.js
    if (angular.element.fn.modal === undefined) {
      angular.element.fn.modal = function (directive) {
        var that = this
        if (directive === 'hide') {
          if (this.data('bs.modal')) {
            this.data('bs.modal').close()
            angular.element(that).remove()
          }
          return
        } else if (directive === 'show') {
          return
        }

        var modalInstance = $modal.open({
          template: angular.element(this).find('.modal-content').html()
        })
        this.data('bs.modal', modalInstance)
        setTimeout(function () {
          angular.element('.modal.ng-isolate-scope').remove()
          angular.element(that).css({
            opacity: 1,
            display: 'block'
          }).addClass('in')
        }, 100)
      }
    }

    return $window.bootbox
  }])
  .service('lazyLoad', ['$q', '$timeout', function ($q, $t) {
    'use strict'
    var deferred = $q.defer()
    var promise = deferred.promise
    this.load = function (files) {
      angular.forEach(files, function (file) {
        if (file.indexOf('.js') > -1) { // script
          (function (d, script) {
            var fDeferred = $q.defer()
            script = d.createElement('script')
            script.type = 'text/javascript'
            script.async = true
            script.onload = function () {
              $t(function () {
                fDeferred.resolve()
              })
            }
            script.onerror = function () {
              $t(function () {
                fDeferred.reject()
              })
            }

            promise = promise.then(function () {
              script.src = file
              d.getElementsByTagName('head')[0].appendChild(script)
              return fDeferred.promise
            })
          }(document))
        }
      })

      deferred.resolve()

      return promise
    }
  }])
  .filter('safe_html', ['$sce', function ($sce) {
    'use strict'
    return function (val) {
      return $sce.trustAsHtml(val)
    }
  }])
