import { Action } from '../actions/list'
import {
  UPDATE_PUSH_TEMPLATES_LIST
} from '../constants/types'

export interface Item {
  any
}

export type PushTemplates = Array<Item>

const initialState: PushTemplates = []

export default (state: PushTemplates = initialState, action: Action | any): PushTemplates => {
  switch (action.type) {
    case UPDATE_PUSH_TEMPLATES_LIST:
      return action.pushTemplates
    default:
      return state
  }
}
