import {
  UPDATE_ACTIVITIES_COMPLETED
} from '../constants/types'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ACTIVITIES_COMPLETED:
      return action.completionCount
    default:
      return state
  }
}
