'use strict'

import * as userActivities from '../../../src/features/userActivities'
import * as events from '../../../src/features/events'
import { getActiveUser } from '../../../src/helpers'
import genericHelper from '../../../src/helpers/GenericHelper'

angular.module('app.controllers')
  .controller('EventCheckinsController', ['$scope', '$location', '$routeParams', '$bootbox', '$modal', '$ngRedux',
    function ($scope, $location, $routeParams, $bootbox, $modal, $ngRedux) {
      $scope.genericHelper = genericHelper
      $scope.isLoading = true
      $scope.activityList = []
      $scope.imageMap = {}
      $scope.collectionView = {
        searchTerm: '',
        itemsPerPage: 20,
        isLoading: true,
        totalItems: 0,
        currentPage: 1,
        numPages: 0
      }
      $scope.eventId = null
      $scope.instanceId = null
      if ($location.path().indexOf('new') == -1) { //We need to load an event
        const indexInstance = $location.path().indexOf('/instance/')
        const indexEvent = $location.path().indexOf('/events/')

        if (indexInstance > -1) {
          $scope.instanceId = $location.path().substr(indexInstance + 10).trim()
        }
        if (indexEvent > -1) {
          const lastIndex = indexInstance == -1 ? 100 : indexInstance;
          $scope.eventId = $location.path().substring(indexEvent + 8, lastIndex).trim()
        }
      }

      $scope.user = getActiveUser()

      $scope.communityList = [{
        name: 'All client',
        _id: null
      }]
      $scope.filter = {
        community: ''
      }

      var activityId = ''
      $scope.type = 'event'

      activityId = $location.path().substr($location.path().lastIndexOf('/') + 1)

      userActivities.helpers.getCommunityList().then((rets) => {
        $scope.communityList = $scope.communityList.concat(rets)
      })

      $scope.loadList = async () => {
        $scope.isLoading = true
        const settings = _.clone($scope.collectionView)
        settings.activityId = activityId
        settings.type = $scope.type
        settings.communityId = _.get($scope, 'filter.community._id')

        const response = await events.helpers.getCheckins($scope.eventId, $scope.instanceId)

        $scope.activityList = response
        $scope.isLoading = false

        $scope.collectionView.totalItems = response.length || 0
        $scope.collectionView.numPages = Math.ceil($scope.collectionView.totalItems / $scope.collectionView.itemsPerPage)
		$scope.$apply();
  
	}

      $scope.loadList()

      $scope.onSearch = function () {
        $scope.loadList()
      }

      $scope.onPageChange = function () {
        $scope.loadList()
      }

      $scope.sendPush = () => {
        const activities = $scope.activityList || []
        let users = {}
        activities.forEach(activity => {
          if (activity && activity.user && activity.user._id) {
            users[activity.user._id] = true
          }
        });
        const userIds = Object.keys(users)
        location.href = `#/pushes/new?users=${userIds.join(',')}`
      }

	  $scope.cancelCheckIn = async (item, $event) => {
		$event.stopPropagation()
		$scope.isLoading = true

		const response = await events.helpers.cancelCheckIn(item._id)
		$scope.loadList()

	  }

      $scope.editActivity = function (activity, $event) {
        $modal.open({
          templateUrl: 'activityDetail.html',
          backdrop: 'static',
          controller: function ($scope, $modalInstance, activity) {
            $scope.activity = _.clone(activity)
            $scope.cancel = function () {
              $modalInstance.dismiss('cancel')
            }
            $scope.close = function () {
              $modalInstance.dismiss('cancel')
            }
          },
          resolve: {
            activity: function () {
              return activity
            }
          }
        })
      }
    }])
