import {
  UPDATE_SEGMENTS_LIST,
  UPDATE_SEGMENT_OBJECTS
} from '../constants/types'
import { Segments } from '../reducers/list'

export interface Action {
  type: string,
  segments?: Segments
}

export const update = (segments: Segments): Action => {
  return {
    type: UPDATE_SEGMENTS_LIST,
    segments
  }
}
