'use strict'

import { getActiveUser } from '../../../src/helpers'

angular
  .module('app.pusher', [])
  .factory('BendPusher', ['$bend',
    function ($bend) {
      var BendPusher = {}
      BendPusher.APP_KEY = '589d36e94bad3014f50128ce'
      BendPusher.APP_SECRET = 'deduKe8DAuA1ry2cYYQXSQEFHgZy9qTvrL0D2lsc'
      BendPusher.initialized = false

      BendPusher.init = function (callback) {
        if (BendPusher.initialized) { return false }
        $bend.Pusher.init(BendPusher.receiveMessage, BendPusher.didConnect, BendPusher.didDisconnect).then(function (result) {
          if (callback) { callback() }
        }, function (error) {
        })
      }

      BendPusher.subscribe = function (msg, callback) {
        $bend.Pusher.subscribe(msg).then(function (result) {
          if (callback) { callback(result) }
        }, function (error) {
        })
      }

      BendPusher.push = function (event, channel, data, callback) {
        $bend.Pusher.push(event, channel, data).then(function (result) {
          if (callback) { callback(result) }
        }, function (error) {
        })
      }

      BendPusher.receiveMessage = function (msg) {
        var jsMsg = JSON.parse(msg)
        if (jsMsg.id == 1) {

        }
      }
      BendPusher.didConnect = function () {
        var token = getActiveUser()._bmd.authtoken
        $bend.Pusher.login(token, BendPusher.APP_KEY).then(function (result) {
          BendPusher.initialized = true
        }, function (error) {
        })
      }
      BendPusher.didDisconnect = function () {
        BendPusher.initialized = false
      }

      return BendPusher
    }
  ])
