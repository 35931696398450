'use strict'

import * as communities from '../../../src/features/communities'
import * as sprints from '../../../src/features/sprints'

angular.module('app.controllers')
  .controller('SprintController', ['$scope', '$routeParams', 'BendAuth', 'CommonUtil', '$location', '$ngRedux',
    async function ($scope, $routeParams, BendAuth, CommonUtil, $location, $ngRedux) {
      $scope.CommonUtil = CommonUtil
      $scope.user = BendAuth.getActiveUser()
      $scope.editSprint = false
      $scope.sprint = {}
      const communityId = _.get($scope.user, 'communityAdmin')
        ? _.get($scope.user, 'community._id')
        : $routeParams.id

      const mapStateToThis = (state) => {
        return {
          communityList: state.communities.list,
          sprintList: state.sprints.list,
          collectionView: {
            itemsPerPage: state.sprints.settings.itemsPerPage,
            totalItems: state.sprints.settings.totalItems,
            currentPage: state.sprints.settings.currentPage,
            numPages: state.sprints.settings.numPages
          }
        }
      }

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      if ($scope.sprintList.length === 0) {
        $scope.isLoading = true
      }

      await communities.helpers.initEnabled()

      $(document).on('click', '.pagination li:not(.disabled) a', (e) => {
        const n = Number($(e.target).text())
        sprints.helpers.paginateToPage(!isNaN(n) ? n : $scope.collectionView.currentPage + ($(e.target).text() === 'Prev' ? -1 : +1))
        $scope.loadList()
      })

      $scope.loadList = async () => {
        await sprints.helpers.init(communityId)
        sprints.helpers.updateActiveUsersAndTotalPointsFields()
        $scope.isLoading = false
      }

      setTimeout(() => $scope.loadList(), 200)

      $scope.onSearch = function () {
        $scope.loadList($scope.collectionView.searchTerm)
      }

      $scope.onPageChange = function () {
        $scope.loadList($scope.collectionView.searchTerm)
      }

      function validateForm () {
        let { startDate, endDate } = $scope.sprint
        if (startDate && endDate) return true
        // if ($scope.startDate && $scope.endDate) return true
        else return false
      }

      $scope.openSprint = async (id = null) => {
        $scope.editSprint = true
        if (id) {
          $scope.sprint = await sprints.helpers.get(id)
          if ($scope.sprint.startDate) $scope.startDate = $scope.sprint.startDate
          if ($scope.sprint.endDate) $scope.endDate = $scope.sprint.endDate

          if ($scope.sprint.community) {
            $scope.community = communities.helpers.get($scope.sprint.community._id)
          }
          applyChangesOnScope($scope, () => {})
        }
        // if (!id) return $location.path('/sprints/new')
        // else return $location.path(`/sprints/${id}`)
      }

      $scope.close = () => {
        $scope.editSprint = false
      }

      $scope.saveSprint = async () => {
        if (!validateForm()) return false
        var sprint = _.clone($scope.sprint)
        delete sprint.createdAt
        if (sprint.community) {
          sprint.community = CommonUtil.makeBendRef(sprint.community._id, 'community')
        }
        if (typeof sprint.startDate === 'object') {
          sprint.startDate = CommonUtil.formatDateWithFormat(new Date(sprint.startDate).getTime() * 1000000, 'YYYY-MM-DD')
        }
        if (typeof sprint.endDate === 'object') {
          sprint.endDate = CommonUtil.formatDateWithFormat(new Date(sprint.endDate).getTime() * 1000000, 'YYYY-MM-DD')
        }
        if (sprint._id) {
          delete sprint.$$hashKey
          await sprints.helpers.update(sprint)
        } else {
          await sprints.helpers.create(sprint)
        }
        setTimeout(() => $scope.loadList(), 200)
        $scope.editSprint = false
      }

      $scope.delete = async (id, $ev) => {
        $ev.stopPropagation()
        $ev.preventDefault()
        const yeap = confirm('Deleting the sprint is permanent and can not be undone.')
        if (!yeap) {
          return false
        }
        await sprints.helpers.delete(id)
        setTimeout(() => {
          $scope.loadList()
          $scope.editSprint = false
        }, 200)
      }

      $scope.openedDateSelector = [false, false]
      $scope.openDateWindow = function ($event, idx) {
        $event.preventDefault()
        $event.stopPropagation()

        $scope.openedDateSelector[idx] = true
      }

      // const rootScope = $scope

      // $scope.openEditSprint = function (sprintObj) {
      //   $modal.open({
      //     templateUrl: 'editSprint.html',
      //     backdrop: 'static',
      //     controller: ['$scope', '$modalInstance', 'sprintObj', 'sprintList', 'communityList',
      //       function ($scope, $modalInstance, sprintObj, sprintList, communityList) {
      //         $scope.sprint = _.clone(sprintObj)
      //         $scope.CommonUtil = CommonUtil
      //         $scope.communityList = communityList
      //         $scope.openedDateSelector = [false, false]
      //         if ($scope.sprint.startDate) {
      //           $scope.startDate = $scope.sprint.startDate
      //         }
      //         if ($scope.sprint.endDate) {
      //           $scope.endDate = $scope.sprint.endDate
      //         }

      //         if ($scope.sprint.community) {
      //           $scope.sprint.community = _.find(communityList, function (o) {
      //             return o._id == $scope.sprint.community._id
      //           })
      //         }
      //         $scope.openDateWindow = function ($event, idx) {
      //           $event.preventDefault()
      //           $event.stopPropagation()

      //           $scope.openedDateSelector[idx] = true
      //         }
      //         $scope.canSubmitValidationForm = function () {
      //           return $scope.form.sprintValidateForm.$valid
      //         }
      //       }],
      //     resolve: {
      //       sprintObj: function () {
      //         return sprintObj
      //       },
      //       sprintList: ['$scope', function ($scope) {
      //         return $scope.sprintList
      //       }],
      //       communityList: ['$scope', function ($scope) {
      //         return $scope.communityList
      //       }]
      //     }
      //   })
      // }
    }])
