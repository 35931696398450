'use strict'

import commonUtil from '../../../src/helpers/commonUtil'
import * as goals from '../../../src/features/goals'
import * as groups from '../../../src/features/groups'
import * as teams from '../../../src/features/teams'
import * as segments from '../../../src/features/segments'
import * as categories from "../../../src/features/categories";
import { getActiveUser } from "../../../src/helpers";

import bendHelper from '../../../src/helpers/bendHelper'

import alertify from 'alertifyjs'
import moment from "moment-timezone";
import { createSemanticDiagnosticsBuilderProgram } from 'typescript'


const COMPLETED = "COMPLETED"
const INCOMPLETE = "INCOMPLETE"

angular.module('app.controllers')
	.controller('GoalsEditController', ['$scope', '$ngRedux', '$routeParams', '$route', '$location', '$rootScope','$modal',
		async function ($scope, $ngRedux, $routeParams, $route, $location, $rootScope, $modal) {
			$scope.commonUtil = commonUtil

			if ($routeParams.id != "new") {
				$scope.goalId = $routeParams.id
			}
			$scope.user = getActiveUser();

			$scope.goal = {
				enabled: false,
				startDate: null,
				endDate: null,
				neverEnds: false,
				group: null,
				team: null,
				segment: null,
				points: null,
				visibleAllUsers: false,
			}


			$scope.formData = {
				startDate: null,
				endDate: null,
				groups: null,
				teams: null,
				segments: null,
				category: null
			}

			$scope.order = {
				field: null,
				reverse: false,
				comparator: null
			}

			$scope.listFilterComplete = [
				{
					name: "All",
					value: null
				}, {
					name: "Only completed",
					value: COMPLETED
				}, {
					name: "Only incomplete",
					value: INCOMPLETE
				},
			]
			
			$scope.fileProgress = 0 
			$scope.isUploading = false
			$scope.openedTimeDateSelector = [];

			const resetValidations = () => {
				return {
					name: false,
					description: false,
					iconUrl: false,
					accessible: false,
					startDate: false,
					endDate: false,
					category: false,
					points: false
				}
			}

			$scope.resetFilters = () => {
				$scope.filter = {
					group: null,
					team: null,
					segment: null,
					searchTerm: null,
					completed: null,
				}
			}

			const mapStateToThis = state => {
				return {
					segments: state.segments.list,
					categories: state.categories.list,
					groups: state.groups.list.groups,
					teams: state.teams.list.teams
				};
			};

			const unsubscribe = $ngRedux.connect(mapStateToThis)($scope);
			$scope.$on("$destroy", unsubscribe);

			const loadData = async () => {
				$scope.isLoading = true
				await segments.helpers.init();
				await groups.helpers.init();
				await teams.helpers.getList();
				await categories.helpers.getList($scope.user.community._id);

				if ($scope.goalId) {
					const goal = await goals.helpers.get($scope.goalId)
					const goalsStatus = await goals.helpers.getActivity($scope.goalId)
					$scope.goalsStatus = goalsStatus

					let formData = {}

					formData.icon = goal.icon
					formData.startDate = moment(goal.startDate / 1000000).format("YYYY-MM-DD")
					formData.endDate = moment(goal.endDate / 1000000).format("YYYY-MM-DD")
					
					//  THOSE LINES AND HIS USE ARE FOR BACKWARD COMPATIBILITY - THIS CAN BE DELETED IN THE FUTURE WHEN THERE ARE GOALS WITHOUT GROUP/TEAM/SEGMENT FIELD
					const concatIfExist = (list, object) => object ? list ? [...list, object._id] : [object._id] : list				
					goal.groups = concatIfExist(goal.groups, goal.group)
					goal.teams = concatIfExist(goal.teams, goal.team)
					goal.segments = concatIfExist(goal.segments, goal.segment)
					delete goal.group
					delete goal.team
					delete goal.segment
					// END - BACKWARD COMPATIBILITY
					
					const findHelper = (listsId, listObjects) => listsId.map(id => listObjects.find(object => object._id === id))

					if (goal.groups) formData.group = findHelper(goal.groups, $scope.groups)
					if (goal.teams) formData.team = findHelper(goal.teams, $scope.teams)
					if (goal.segments) formData.segment = findHelper(goal.segments, $scope.segments)

					
					$scope.goal = goal

					formData.category = $scope.categories.find(category => category._id === goal.category[0])

					$scope.formData = formData
					
					$scope.isLoading = false
					$scope.$apply()
				} else {
					$scope.isLoading = false
					$scope.$apply()
				}
			}


			$scope.validations = resetValidations()

			$scope.resetFilters()
			await loadData()

			const onSaveSuccess = () => {
				$route.reload()
				$location.path('/goals')
				alertify.notify('Saved successfully.', 'success', 5)
			}

			$scope.validateForm = () => {
				const { title, neverEnds, points, description, visibleAllUsers } = $scope.goal
				const { startDate, endDate, group, team, segment, category, icon } = $scope.formData
				const required = "required";

				$scope.errorMsg = false;

				let validation = resetValidations()

				if (title === "" || !title) validation.name = required;
				if (description === "" || !description) validation.description = required;
				if (!icon) validation.icon = required

				if (!moment(startDate).isValid()) validation.startDate = required
				if (!neverEnds && !moment(endDate).isValid()) validation.endDate = required


				if (!visibleAllUsers && !group && !team && !segment) validation.accessible = required
				if (!Number(points)) validation.points = required
				if (!category) validation.category = required

				$scope.validation = validation
				return Object.values(validation).every(value => value === false)
			}


			$scope.saveDo = async () => {
				if ($scope.validateForm()) {
					$scope.isSaving = true

					let goal = { ...$scope.goal }
					const { startDate, endDate, group, team, segment, category, icon } = $scope.formData

					goal.startDate = moment(startDate).valueOf() * 1000000
					const endDateFinal = goal.neverEnds ? moment(startDate).add('year', 100) : moment(endDate)
					goal.endDate = endDateFinal.valueOf() * 1000000

					if (!goal.visibleAllUsers && group) goal.groups = group.map(g => g._id)
					else delete goal.groups
					if (!goal.visibleAllUsers && team) goal.teams = team.map(t => t._id)
					else delete goal.teams
					if (!goal.visibleAllUsers && segment) goal.segments = segment.map(s => s._id)
					else delete goal.segments

					goal.category = [category._id]
					goal.icon = commonUtil.makeBendFile(icon._id)
					goal.icon['_downloadUrl'] = icon._downloadURL
					goal.points = Number(goal.points)
					
					try {
						if ($scope.goalId) {
							await goals.helpers.update(goal)
							applyChangesOnScope($scope, () => {
								onSaveSuccess()
							})
						} else {
							await goals.helpers.create(goal)
							onSaveSuccess()
						}
					} catch (e) {
						$scope.isSaving = false
						console.log('ERROR: ', e)
						alertify.notify('An unexpected error has occurred, please contact Milk Crate', 'error', 5)

					}
				}
			}

			$scope.deleteGoal = () => {
				$scope.isLoading = true
				goals.helpers.delete($scope.goalId).then(result => {
					$scope.isLoading = false
					$route.reload()
					$location.path('/goals')
					alertify.notify('Deleted successfully.', 'success', 5)
				})
			}


			$scope.changeNeverEnds = () => {
				$scope.formData.endDate = null
			}

			$scope.openTimeDateWindow = ($event, idx) => {
				$event.preventDefault();
				$event.stopPropagation();

				$scope.openedTimeDateSelector[idx] = true;
			};

			$scope.updateGroup = () => {
				$scope.formData.team = null
				$scope.formData.segment = null
			}
			$scope.updateTeam = () => {
				$scope.formData.group = null
				$scope.formData.segment = null
			}
			$scope.updateSegment = () => {
				$scope.formData.team = null
				$scope.formData.group = null
			}

			$scope.filterGoalStatus = ({goalAchievedOn, userGroupName,teamNames, segmentNames, name}) => {
				const { filter } = $scope

				let completed = true
				if (filter.completed && filter.completed.value == COMPLETED) {
					completed = goalAchievedOn !== null
				} else if (filter.completed && filter.completed.value == INCOMPLETE) {
					completed = goalAchievedOn === null
				}

				const group = !filter.group || (filter.group && userGroupName && userGroupName.includes(filter.group.name))
				const team = !filter.team || (filter.team && teamNames && teamNames.includes(filter.team.name))
				const segment = !filter.segment || (filter.segment && segmentNames && segmentNames.includes(filter.segment.name))
				const searchTerm = !filter.searchTerm || (filter.searchTerm && name && name.includes(filter.searchTerm))
				
				return completed && group && team && segment && searchTerm
			}

			$scope.sortBy = newField => {
				const { field, reverse, comparator } = $scope.order

				$scope.order.field = newField
				$scope.order.reverse = field == newField ? !reverse : false

				switch (newField) {
					case 'currentPoints':
						$scope.order.comparator = (v1, v2) => v1 > v2
						break;
					case 'lastActivityOn':
					case 'created':
					case 'goalAchievedOn':
						$scope.order.comparator = (v1, v2) => moment(v1) > moment(v2)
						break;
					default:
						$scope.order.comparator = (v1, v2) => v1 > v2
				}
			}

			$scope.exportGoalsStatus = () => {
				return $scope.goalsStatus.map( row => {
					return {
						...row,
						teamNames: row.teamNames ? row.teamNames.join('; ') :'',
						segmentNames: row.segmentNames ? row.segmentNames.join('; ') :''
					}
				})
			}


			$scope.deleteFile = function ($ev) {
				$ev.stopPropagation()
				$ev.preventDefault()
				applyChangesOnScope($scope, function () {
				  delete $scope.formData.icon
				  delete $scope.goal.icon
				  $scope.uploadingFileUrl = null
				})
			  }
	
		
			  $scope.selectFileOpen = function (fileId, $ev) {
				if ($ev.target.tagName == 'DIV') {
				  setTimeout(function () {
					$('#' + fileId).click()
				  }, 0)
				}
			  }
		
			  $scope.onFileUpload = function (files) {
				var file = files[0]

				$scope.fileProgress = 0
				$scope.isUploading = true

				var reader = new FileReader()
				reader.onload = function (e) {
				  $scope.uploadingFileUrl = e.target.result
				}
				reader.readAsDataURL(file)
		
				goals.helpers.upload(file, function (error, uploadedFile) {
				  if (error) {
					$scope.isUploading = false
					$scope.uploadingFileUrl = null
					return
				  }

				  goals.helpers.getFile(uploadedFile, function (o) {
					$scope.formData.icon = o

					$scope.isUploading = false
					$scope.form.validateForm.$dirty = true
					$scope.uploadingFileUrl = null
					$scope.$apply()
				  })
				}, {
				  _workflow: 'logo'
				}, function (total, prog) {
				  applyChangesOnScope($scope, function () {
					$scope.fileProgress = prog * 100 / total
				  })
				})
			  }














			$rootScope.fileUploadFromSearch2 = function (file) {
				file._filename = Date.now() + ''
				var files = []
				files.push(file)
				$scope.onFileUpload(files)
			  }
		
			  $scope.searchImages2 = function ($ev) {
				$ev.stopPropagation()
				$ev.preventDefault()
				$modal.open({
				  templateUrl: 'views/templates/searchImages.html',
				  backdrop: 'static',
				  controller: function ($scope, $modalInstance) {
					$scope.searchResults = 0
					$scope.pages = 1
					$scope.showLoadMore = false
					// var cacheSearchKey = ''
		
					$scope.cancel = function () {
					  $modalInstance.dismiss('cancel')
					}
					$scope.close = function () {
					  $modalInstance.dismiss('cancel')
					}
		
					$scope.searchImages = function (searchVal) {
					  // cacheSearchKey = searchVal
		
					  $scope.pages = 1
					  if (searchVal) {
						var URL = 'https://pixabay.com/api/?key=2706353-ee3016f8af51ca406a8c8a3db&q=' + encodeURIComponent(searchVal) + '&image_type=photo&page=' + $scope.pages
						$.getJSON(URL, function (data) {
						  if (parseInt(data.totalHits) > 0) {
							applyChangesOnScope($scope, function () {
							  if (data.totalHits > $scope.pages * 20) { $scope.showLoadMore = true } else { $scope.showLoadMore = false }
		
							  $scope.searchResults = data.hits
							})
						  }
						})
					  }
					}
		
					$scope.searchImagesMore = function (tabIdx, searchVal) {
					  $scope.pages++
					  if (searchVal) {
						var URL = 'https://pixabay.com/api/?key=2706353-ee3016f8af51ca406a8c8a3db&q=' + encodeURIComponent(searchVal) + '&image_type=photo&page=' + $scope.pages
						$.getJSON(URL, function (data) {
						  if (parseInt(data.totalHits) > 0) {
							applyChangesOnScope($scope, function () {
							  if (data.totalHits > $scope.pages * 20) { $scope.showLoadMore = true } else { $scope.showLoadMore = false }
		
							  $scope.searchResults = $scope.searchResults.concat(data.hits)
							})
						  }
						})
					  }
					}
		
					$scope.selectImage = function (searchItem) {
					  $scope.isDownloading = true
					  var imageUrl = ''
					  imageUrl = searchItem.webformatURL
		
					  var xhr = new XMLHttpRequest()
					  xhr.open('GET', imageUrl, true)
					  xhr.responseType = 'blob'
					  xhr.onload = function (e) {
						if (this.status == 200) {
						  var myBlob = this.response
						  $rootScope.fileUploadFromSearch2(myBlob)
						  // myBlob is now the blob that the object URL pointed to.
						  $scope.cancel()
						}
					  }
					  xhr.send()
					}
				  }
				})
			  }





		}])