export const UPDATE_SURVEYS_LIST = 'UPDATE_SURVEYS_LIST'
export const UPDATE_RESPONSES_LIST = 'UPDATE_RESPONSES_LIST'
export const UPDATE_SURVEYS_SETTINGS = 'UPDATE_SURVEYS_SETTINGS'
export const UPDATE_SURVEY_RESPONSES = 'UPDATE_SURVEY_RESPONSES'
export const UPDATE_SURVEYS_PAGE_SETTINGS = 'UPDATE_SURVEYS_PAGE_SETTINGS'
export const UPDATE_SURVEYS_COMMUNITY_SETTINGS = 'UPDATE_SURVEYS_COMMUNITY_SETTINGS'
export const UPDATE_SURVEYS_TEAMS_SETTINGS = 'UPDATE_SURVEYS_TEAMS_SETTINGS'
export const UPDATE_SURVEYS_TEAM_SETTINGS = 'UPDATE_SURVEYS_TEAM_SETTINGS'
export const RESET_SURVEYS_SETTINGS = 'RESET_SURVEYS_SETTINGS'
export const UPDATE_SURVEYS_SEGMENTS_SETTINGS = 'UPDATE_SURVEYS_SEGMENTS_SETTINGS'
export const UPDATE_SURVEY_USER_GROUP_SETTING = 'UPDATE_SURVEY_USER_GROUP_SETTING'
export const UPDATE_SURVEYS_SEGMENTS = 'UPDATE_SURVEYS_SEGMENTS'
export const UPDATE_SURVEY_RESPONSES_LIST = 'UPDATE_SURVEY_RESPONSES_LIST'
