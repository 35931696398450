"use strict";

import commonUtil from "../../../src/helpers/commonUtil";
import * as categories from "../../../src/features/categories";
import * as surveys from "../../../src/features/surveys";
import * as eventTemplates from "../../../src/features/eventTemplates";
import * as events from "../../../src/features/events";
import * as users from "../../../src/features/users";
import * as segments from "../../../src/features/segments";
import { getActiveUser } from "../../../src/helpers";
import moment from "moment-timezone";

const DATE_FORMAT = "YYYY-MM-DD hh:mm A";
const SHORT_DATE_FORMAT = "YYYY-MM-DD";
const HOUR_MIN_FORMAT = "hh:mm A";
const HOUR_TIMEPICKER_FORMAT = "h:i A";
const tz = moment.tz.guess();

const loadData = async $scope => {
  await users.helpers.init();
  await categories.helpers.getList($scope.user.community._id);
  await surveys.helpers.init();
  await segments.helpers.init();
  if (!$scope.eventId) await eventTemplates.helpers.init();
  $scope.users = await users.helpers.getListComplete()
};

const generateFullDate = (day, time) => {
  if (!day || !time) return null;

  try {
    const date = moment(
      `${moment(day).format(SHORT_DATE_FORMAT)} ${time}`,
      DATE_FORMAT,
      true
    );
    if (date.isValid()) {
      return date;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

const deleteRepeatEnd = $scope => {
  delete $scope.event.recurrent.repeatEndsOnUTC;
  delete $scope.event.recurrent.repeatEndsOn;
  delete $scope.event.recurrent.repeatEndsOnTime;
  delete $scope.event.recurrent.repeatEndsOnTz;
};

angular.module("app.controllers").controller("EventEditController", [
  "$scope",
  "$location",
  "$routeParams",
  "$rootScope",
  "$modal",
  "$ngRedux",
  "$route",
  (
    $scope,
    $location,
    $routeParams,
    $rootScope,
    $modal,
    $ngRedux,
    $route,
    $timeout
  ) => {
    // Init.
    $scope.commonUtil = commonUtil;
    $scope.isLoading = false;
    $scope.isSaving = false;
    $scope.pastEvent = false;
    $scope.eventId = null;
    $scope.instanceId = null;
    if ($routeParams.id != "new") {
      $scope.eventId = $routeParams.id;
      $scope.isLoading = true;
    }
    if ($routeParams.instance != null) {
      $scope.instanceId = $routeParams.instance;
    }
    $scope.moreOptions = false;
    $scope.fileProgress = [];
    $scope.openedTimeDateSelector = [];
    $scope.weekdaysName = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday"
    ];

    $scope.isUploading = [];

    $scope.dates = {
      startsAtDisplay: null,
      startsAtFrom: null,
      startsAtUntil: null,
      repeatEndsOnDisplay: null,
      repeatEndsOnTime: null,
      startsAtUTC: null,
      startsAtTz: null
    };

    $scope.toggleSelectedWeekday = weekday => {
      const idx = $scope.event.recurrent.repeatWeekdays.indexOf(weekday);
      if (idx > -1) {
        $scope.event.recurrent.repeatWeekdays.splice(idx, 1);
      } else {
        $scope.event.recurrent.repeatWeekdays.push(weekday);
      }
    };

    $scope.onRepeatEveryTypeChanged = () => {
      $scope.event.recurrent.repeatWeekdays = []; // We reset the weekday selector
      if ($scope.event.startsAtDisplay) {
        const date = moment($scope.event.startsAtDisplay);
        if (date.isValid()) {
          const weekday = date.isoWeekday(); // Gives us the date of the week starting in 1
          $scope.event.recurrent.repeatWeekdays = [
            $scope.weekdaysName[weekday - 1]
          ];
        }
      }
    };

    const mapStateToThis = state => ({
      comments: state.comments.list,
      segments: state.segments.list,
      categories: state.categories.list,
      surveys: state.surveys.list.surveys,
      eventTemplates: state.eventTemplates.list
    });

    const unsubscribe = $ngRedux.connect(mapStateToThis)($scope);
    $scope.$on("$destroy", unsubscribe);

    $scope.stateList = commonUtil.AllStates;
    $scope.formData = {
      categories: [],
      speakers: [],
      surveys: [],
      collections: [],
      state: "",
      segments: []
    };
    $scope.eventTemplate = { selected: null}

    $scope.event = {
      ...$scope.dates,
      isRecurrent: false,
      isRecommended: false,
      recurrent: {
        repeatEvery: null,
        repeatEveryType: "day",
        repeatWeekdays: [],
        repeatEndType: "never",
        repeatEndsAfter: null,
        repeatEndsOnUTC: null,
        repeatEndsOnTz: null
      },
      type: "geo-checkin",
      //  Recurrent data
      allDayEvent: false,
      surveys: [],
      shortDescription: "",
      version: 2
    };

    $scope.user = getActiveUser();
    $scope.form = {};

    $scope.creator = null;

    const observer = new MutationObserver((mutations, me) => {
      // `mutations` is an array of mutations that occurred
      // `me` is the MutationObserver instance
      const inputFrom = $("[name^=from-input]");
      const inputUntil = $("[name^=until-input]");
      const inputEnd = $("[name^=until-input-end");
      if (inputFrom.length && inputUntil.length && inputEnd.length) {
        $("[name^=from-input]").timepicker({
          timeFormat: HOUR_TIMEPICKER_FORMAT,
          step: 15
        });
        $("[name^=from-input]").on("changeTime", e => {
          const time = $(e.target).val();
          $scope.event.startsAtFrom = time;
        });
        $("[name^=until-input]").timepicker({
          timeFormat: HOUR_TIMEPICKER_FORMAT,
          step: 15
        });
        $("[name^=until-input]").on("changeTime", e => {
          const time = $(e.target).val();
          $scope.event.startsAtUntil = time;
        });
        $("[name^=until-input-end").timepicker({
          timeFormat: HOUR_TIMEPICKER_FORMAT
        });
        $("[name^=until-input-end]").on("changeTime", e => {
          const time = $(e.target).val();
          $scope.event.recurrent.repeatEndsOnTime = time;
        });
        me.disconnect(); // Stop listening to changes
      }
    });

    // start observing
    observer.observe(document, {
      childList: true,
      subtree: true
    });

    try {
      loadData($scope)
        .then(() => {

          if ($scope.eventId) {
            events.helpers.get($scope.eventId, $scope.instanceId).then(ret => {
              console.timeEnd("Getting Event");
              console.time("Formatting Event");
              ret.enabled =
                typeof ret.enabled !== "undefined" && ret.enabled === true;
              $scope.oldEvent = _.clone(ret);
              $scope.event = ret;
              $scope.updateType();

              if (
                $scope.event.categories &&
                $scope.event.categories.length > 0
              ) {
                $scope.formData.categories = $scope.categories.filter(
                  o => $scope.event.categories.indexOf(o._id) != -1
                );
              }
              if ($scope.event.segments && $scope.event.segments.length > 0) {
                $scope.formData.segments = $scope.segments.filter(
                  o => $scope.event.segments.indexOf(o._id) != -1
                );
              }
              // convert check-in radius to feet for American users
              if ($scope.event.checkinRadius) {
                $scope.event.checkinRadius = commonUtil.metersToFeet(
                  $scope.event.checkinRadius
                );
              }
              if ($scope.event.community) {
                $scope.event.community = $scope.event.community._id;
              } else {
                $scope.event.community = "";
              }

              if (
                $scope.event.isRecurrent &&
                $scope.event.recurrent &&
                $scope.event.recurrent.repeatEndsOnUTC
              ) {
                const repeatEndDate = moment(
                  $scope.event.recurrent.repeatEndsOnUTC / 1000000
                );
                $scope.event.repeatEndsOnDisplay = repeatEndDate.format(
                  SHORT_DATE_FORMAT
                );
                $scope.event.recurrent.repeatEndsOnTime = repeatEndDate.format(
                  HOUR_MIN_FORMAT
                );
              }
              if ($scope.event.speakers && $scope.event.speakers.length > 0) {
                $scope.formData.speakers = $scope.users.filter(
                  o => $scope.event.speakers.indexOf(o._id) != -1
                );
              }

              if ($scope.event.surveys && $scope.event.surveys.length>0 && $scope.event.surveys[0]._id) {
                $scope.formData.surveys = $scope.event.surveys[0];
              } 

              if ($scope.event.startsAtUTC) {
                const startDate = moment($scope.event.startsAtUTC / 1000000);
                $scope.event.startsAtDisplay = startDate.format(
                  SHORT_DATE_FORMAT
                );
                $scope.event.startsAtFrom = startDate.format(HOUR_MIN_FORMAT);
                $scope.event.startsAtUntil = startDate
                  .clone()
                  .add($scope.event.timeLength, "minutes")
                  .format(HOUR_MIN_FORMAT);
                $scope.pastEvent = startDate.isBefore(moment());
              }

              $scope.setCreator();
              $scope.formData.state = $scope.event.state;
              $scope.isLoading = false;
              console.timeEnd("Formatting Event");
              $scope.$apply();
            }); // get event
          } else if (!$scope.eventId) {
            $scope.linkedPushToggle = false;
            $scope.add_count = 0;
            if ($scope.eventTemplates && $scope.eventTemplates.length > 0) {
              $scope.template = {};
              $scope.template.selected = $scope.eventTemplates[0];
            }
          }
        })
        .catch(err => {
          console.log("Error locating Event", err);
        });
    } catch (error) {
      console.log(error);
      return;
    }

    $scope.updateType = () => {
      if ($scope.event.type) {
        if ($scope.event.type == "geo-checkin") {
          $scope.addressExists = true;
          $scope.digitalReq = false;
          $scope.addressRequired = true;
        } else if ($scope.event.type == "self-checkin") {
          $scope.addressExists = true;
          $scope.digitalReq = false;
          $scope.addressRequired = true;
        } else if ($scope.event.type == "url-checkin") {
          $scope.addressExists = false;
          $scope.digitalReq = true;
          $scope.addressRequired = false;
        } else if ($scope.event.type == "phone-checkin") {
          $scope.addressExists = false;
          $scope.digitalReq = true;
          $scope.addressRequired = false;
        }
      } else if (
        !$scope.event.address1 &&
        !$scope.event.city &&
        !$scope.event.state
      ) {
        $scope.event.type = "self-checkin";
        $scope.updateType();
      } else if ($scope.event.address1) {
        $scope.event.type = "geo-checkin";
        $scope.updateType();
      } else if ($scope.event.isPhoneCheckIn) {
        $scope.event.type = "phone-checkin";
        $scope.updateType();
      } else if ($scope.event.digital) {
        $scope.event.type = "url-checkin";
        $scope.updateType();
      } else {
        $scope.event.type = "geo-checkin";
        $scope.updateType();
      }
    };

    $scope.setCreator = () => {
      if ($scope.event._acl && $scope.event._acl.creator) {
        const userId = _.get($scope.event, "_acl.creator");
        users.helpers
          .get(userId)
          .then(user => {
            if (user) {
              $scope.creator = user;
            }
          })
          .catch(err => {
            console.log("Error Loading Creator", err);
          });
      }
    };

    $scope.getSegmentInfo = segment => segments.helpers.getSegmentInfo(segment);

    $scope.delete = async (saveType = null, id) => {
      if (saveType != null) {
        // Recurrent, check just in case, as saveType should never be null at this point
        switch (saveType) {
          case "individual":
            const originalEvent = await events.helpers.get(
              $scope.eventId,
              $scope.instanceId
            );
            originalEvent.isRecurrent = false;
            originalEvent.isException = true;
            originalEvent.isRescheduled = false;
            originalEvent.isDeleted = true;
            originalEvent.parentId =
              originalEvent.parentId || originalEvent._id;
            delete originalEvent.recurrent;
            if ($scope.event.isException == true) {
              await events.helpers.update(originalEvent);
            } else {
              originalEvent.eventId = originalEvent._id;
              delete originalEvent._id;
              await events.helpers.create(originalEvent);
            }
            break;
          case "following":
            const eventToUpdate = $scope.event.isException
              ? $scope.event.eventId
              : $scope.eventId; // If this is an exception, we need to update the parent segment, otherwise we are on the segment itself
            const mainEvent = await events.helpers.get(eventToUpdate); // We update the event we are looking
            delete mainEvent.originalRepeatEndsAfter;
            delete mainEvent.instanceId;
            mainEvent.recurrent.repeatEndType = "on";
            mainEvent.recurrent.repeatEndsOnUTC = $scope.event.startsAtUTC; // The parent event now ends at the moment we are looking
            mainEvent.recurrent.repeatEndsOnTz = tz;
            await events.helpers.update(mainEvent);
            if ($scope.event.isException == true) {
              // If this is an exception, we delete it
              await events.helpers.delete($scope.eventId);
            }
            break;

          case "all":
            const allEvent = await events.helpers.get($scope.eventId);
            await events.helpers.delete(allEvent._id);
            break;

          default:
            break;
        }
        $scope.applyChanges();
      }
    };

    $scope.deleteEvent = async id => {
      if (
        $scope.event.isRecurrent == true ||
        $scope.event.isException == true
      ) {
        $scope.openModal(
          $scope.delete,
          id,
          $scope.event.parentId,
          $scope.event.instanceId
        );
      } else {
        const yeap = confirm(
          "Deleting the event is permanent and cannot be undone."
        );
        if (!yeap) {
          return false;
        }
        await events.helpers.delete(id);
        $scope.applyChanges();
      }
    };

    $scope.digitalSwitch = () => {
      $scope.addressExists = !$scope.addressExists;
      $scope.digitalReq = !$scope.digitalReq;
      $scope.event.isPhoneCheckIn = false;
      $scope.errorMsg = false;

      if ($scope.addressExists == false) {
        $scope.resetGeocode();
        for (const j in $scope.event) {
          if (
            ["address1", "address2", "city", "state", "postalCode"].includes(j)
          ) {
            $scope.event[j] = undefined;
          }
        }
        $scope.addressRequired = false;
      } else {
        $scope.event.digital = undefined;
        $scope.addressRequired = true;
      }
    };

    $scope.toggleAutoPush = () => {
      $scope.linkedPushToggle = !$scope.linkedPushToggle;
    };

    $scope.prepObj = function() {
      if ($scope.addressRequired) {
        $scope.event.state = $scope.formData.state;
      }
    };

    // form validation using angular ng-class
    $scope.validateForm = () => {
      const required = "required";

      $scope.errorMsg = false;
      $scope.recurrentReq = false;
      $scope.recurrentWeekReq = false;
      $scope.recurrentAfterReq = false;
      $scope.recurrentEndReq = false;

      const name = _.get($scope.event, "name");
      const type = _.get($scope.event, "type");
      const description = _.get($scope.event, "description");
      const shortDescription =_.get($scope.event, "shortDescription");
      const _geoloc = _.get($scope.event, "_geoloc");
      const address1 = _.get($scope.event, "address1");
      const city = _.get($scope.event, "city");
      const state = _.get($scope.event, "state");
      const postalCode = _.get($scope.event, "postalCode");
      const categories = _.get($scope.formData, "categories");
      const checkinPoints = $scope.event.checkinPoints;
      const digital = _.get($scope.event, "digital");
      const startsAtDisplay = _.get($scope.event, "startsAtDisplay");
      const allDayEvent = _.get($scope.event, "allDayEvent");
      const isRecurrent = _.get($scope.event, "isRecurrent");
      const isRecommended = _.get($scope.event, "isRecommended");
      
      if (name === "" || !name) $scope.nameReq = required;
      else $scope.nameReq = false;
      if (description === "" || !description) $scope.descriptionReq = required;
      else $scope.descriptionReq = false;
      if (categories.length === 0 || !categories)
        $scope.categoriesReq = required;
      else $scope.categoriesReq = false;
      if (checkinPoints === 0 || !checkinPoints)
        $scope.checkinPointsReq = required;
      else $scope.checkinPointsReq = false;
     
      // address requirements group, cancels requirement if address doesn't exist
      if ($scope.digitalReq && !digital) {
        if (digital === "" || !digital) $scope.digitalReq = required;
        else $scope.digitalReq = false;
      }
      if (
        $scope.addressRequired &&
        (!address1 || !city || !state || !_geoloc || !postalCode)
      ) {
        if (address1 === "" || !address1) $scope.address1Req = required;
        else $scope.address1Req = false;
        if (city === "" || !city) $scope.cityReq = required;
        else $scope.cityReq = false;
        if (state === "" || !state) $scope.stateReq = required;
        else $scope.stateReq = false;
        if (postalCode === "" || !postalCode) $scope.postalCodeReq = required;
        else $scope.postalCodeReq = false;
        if (_geoloc === "" || !_geoloc) $scope.geocodeReq = required;
        // disabled bc provider error
        else $scope.geocodeReq = false;
      }

      // Dates Validation
      if (allDayEvent) {
        // A full day event only needs the start day
        const startDate = moment(startsAtDisplay);
        if (startDate && startDate.isValid() == false) {
          $scope.timesReq = required;
        } else {
          $scope.timesReq = false; // We have the date
        }
      } else {
        // Otherwise we need all the fields
        const startDate = generateFullDate(
          startsAtDisplay,
          $scope.event.startsAtFrom
        );
        const endDate = generateFullDate(
          startsAtDisplay,
          $scope.event.startsAtUntil
        );
        if (startDate && startDate.isValid() == true) {
          $scope.timesReq = false; // We have the date
        } else {
          $scope.timesReq = required;
        }
        if (endDate && endDate.isValid() == true) {
          $scope.timesReq = false; // We have the date
        } else {
          $scope.timesReq = required;
        }
        if (
          endDate &&
          startDate &&
          startDate.isValid() &&
          endDate.isValid() &&
          endDate.isBefore(startDate)
        ) {
          $scope.timesReq = required;
        }
      }
 
     if (type == 'url-checkin' || type == 'phone-checkin') {
        //We validate if we need the digital value
        if (typeof digital !== 'undefined' && digital !== "" && (digital.includes("http://") || digital.includes("https://") || digital.includes("+"))) {
          $scope.digitalReq = false
        } else {
          $scope.digitalReq = required
        }
      } else {
        $scope.digitalReq = false
      }
     
      // Recurrent validation
      if (isRecurrent) {
        // Check for what we need to save for a recurrent event
        if (
          !$scope.event.recurrent.repeatEveryType ||
          !$scope.event.recurrent.repeatEvery
        ) {
          $scope.recurrentReq = required;
        }
        if (
          $scope.event.recurrent.repeatEveryType == "week" &&
          $scope.event.recurrent.repeatWeekdays.length == 0
        ) {
          $scope.recurrentWeekReq = required;
        }
        if (
          $scope.event.recurrent.repeatEndType == "after" &&
          !$scope.event.recurrent.repeatEndsAfter
        ) {
          $scope.recurrentAfterReq = required;
        }
        if ($scope.event.recurrent.repeatEndType == "on") {
          const repeatEndDate = generateFullDate(
            $scope.event.repeatEndsOnDisplay,
            $scope.event.recurrent.repeatEndsOnTime
          );
          if (repeatEndDate && repeatEndDate.isValid() == true) {
            $scope.recurrentEndReq = false; // We have the date
          } else {
            $scope.recurrentEndReq = required;
          }
        }
      }
      
      // Should we say this is not valid?
      if (
        !name ||
        !description ||
        !categories.length ||
        !checkinPoints ||
        $scope.timesReq ||
        $scope.recurrentReq ||
        $scope.recurrentWeekReq ||
        $scope.recurrentAfterReq ||
        $scope.recurrentEndReq ||
        $scope.digitalReq 
        
      ) {
        $scope.errorMsg = true;
      }
      if (
        $scope.addressRequired &&
        (!address1 || !city || !state || !_geoloc || !postalCode)
      ) {
        $scope.errorMsg = true;
      }
      if ($scope.digitalReq && !digital) {
        $scope.errorMsg = true;
      }

      if ($scope.errorMsg) {
        return false;
      } else {
        return true;
      }
    
    };

    $scope.filterNewline = () => {
      const description = _.get($scope.event, "description");
      const parts = description.toString().split("\n");
      _.set($scope.event, "description", parts.join(""));
    };

    $scope.openModal = (
      callback,
      id = null,
      parentId = null,
      instanceId = null
    ) => {
      const modalInstance = $modal.open({
        templateUrl: "myModalContent.html",
        controller: [
          "$scope",
          "$modalInstance",
          ($scope, $modalInstance) => {
            $scope.parentId = parentId;
            $scope.instanceId = instanceId;
            $scope.saveType = instanceId > 1 ? "individual" : "following";
            $scope.save = () => {
              $modalInstance.close($scope.saveType);
            };
            $scope.cancel = () => {
              $modalInstance.dismiss("cancel");
            };
          }
        ]
      });
      modalInstance.result.then(
        saveType => {
          callback(saveType, id);
        },
        () => {
          console.log("Modal dismissed at: " + new Date());
        }
      );
    };

    $scope.save = () => {

      if (
        $scope.eventId &&
        ($scope.event.isRecurrent == true || $scope.event.isException == true)
      ) {
        $scope.openModal(
          $scope.saveEventDo,
          null,
          $scope.event.parentId,
          $scope.event.instanceId
        );
      } else {
        $scope.saveEventDo();
      }
    };

    $scope.sanitizeEvent = eventData => {
      if (eventData.allDayEvent) {
        eventData.startsAtFrom = "12:00 AM";
        eventData.startsAtUntil = "11:59 PM";
        eventData.timeLength = 1439; // 1440 minutes in a day minus 1
      }

      const startDate = generateFullDate(
        eventData.startsAtDisplay,
        eventData.startsAtFrom
      );
      eventData.startsAtUTC = startDate.utc().valueOf() * 1000000;
      eventData.startsAtTz = tz;

      const endDate = generateFullDate(
        eventData.startsAtDisplay,
        eventData.startsAtUntil
      );
      eventData.timeLength = endDate.diff(startDate, "minutes");

      if (eventData.isRecurrent === true) {
        switch (eventData.recurrent.repeatEndType) {
          case "on":
            const repeatEndDate = generateFullDate(
              eventData.repeatEndsOnDisplay,
              eventData.recurrent.repeatEndsOnTime
            );
            eventData.recurrent.repeatEndsOnUTC =
              repeatEndDate.utc().valueOf() * 1000000;
            eventData.recurrent.repeatEndsOnTz = tz;
            delete eventData.repeatEndsAfter;
            break;
          case "after":
            deleteRepeatEnd($scope);
            break;
          case "never":
            deleteRepeatEnd($scope);
            delete eventData.recurrent.repeatEndsAfter;
            break;
          default:
            break;
        }
        if (
          eventData.recurrent.repeatEveryType &&
          eventData.recurrent.repeatEveryType !== "week"
        ) {
          delete eventData.repeatWeekdays;
        }
      } else {
        delete eventData.recurrent;
      }

      if (!eventData.checkinRadius) {
        eventData.checkinRadius = 250;
      } else {
        eventData.checkinRadius = commonUtil.feetToMeters(
          eventData.checkinRadius
        );
      }

      if (eventData.community && eventData.community != "") {
        eventData.community = commonUtil.makeBendRef(
          eventData.community,
          "community"
        );
      } else {
        delete eventData.community;
      }

      // probably unnecessary due to validation
      if (
        eventData._geoloc &&
        eventData._geoloc[0] &&
        eventData._geoloc[1] &&
        eventData._geoloc[0] != "" &&
        eventData._geoloc[1] != ""
      ) {
        eventData._geoloc = [
          parseFloat(eventData._geoloc[0]),
          parseFloat(eventData._geoloc[1])
        ];
      } else {
        delete eventData._geoloc;
      }

      eventData.state = $scope.formData.state;

      // i know this is repetative below and there should be a better way. the data wasn't displaying in the
      // form when i tried to improve this using commonUtil.objArrsToIds. i gave up after 3 tries

      if ($scope.formData.segments && $scope.formData.segments.length > 0) {
        eventData.segments = commonUtil.getIdList($scope.formData.segments);
      } else {
        delete eventData.segments;
      }

      if ($scope.formData.categories.length > 0) {
        eventData.categories = commonUtil.getIdList($scope.formData.categories);
      } else {
        delete eventData.categories;
      }

      if ($scope.formData.surveys && $scope.formData.surveys._id) {  
        eventData.surveys = commonUtil.getIdListAndName(
          $scope.formData.surveys
        );
      }
      else {
        eventData.surveys = []
       }

      if ($scope.formData.speakers && $scope.formData.speakers.length > 0) {
        eventData.speakers = commonUtil.getIdList($scope.formData.speakers);
      } else {
        delete eventData.speakers;
      }

      if ($scope.event.url == "") {
        delete eventData.url;
      }

      if (eventData.state == "") {
        delete eventData.state;
      }

      if (eventData.coverImage) {
        eventData.coverImage = commonUtil.makeBendFile(
          eventData.coverImage._id
        );
      }
      if (eventData.sponsorLogo) {
        eventData.sponsorLogo = commonUtil.makeBendFile(
          eventData.sponsorLogo._id
        );
      }
      delete eventData.$$hashKey;
      delete eventData.spotsLeft;
      delete eventData.startsAtDisplay;
      delete eventData.repeatEndsOnDisplay;
      delete eventData.startsAt;
      delete eventData.endsAt;
    };

    $scope.saveEventDo = async function(saveType = null) {
      $scope.prepObj();
      if (!$scope.validateForm() ) return;
      $scope.filterNewline();

      const eventData = _.clone({
        ...$scope.event
      });
      $scope.sanitizeEvent(eventData);

      $scope.isSaving = true;

      try {
        if ($scope.eventId) {
          if (eventData.recurrent) delete eventData.recurrent.repeatEndsOnTime;
          switch (saveType) {
            case "all":
              // This option should only appear if the event is the original one and not an exception, to make calculations simpler
              delete eventData.instanceId;
              delete eventData.eventId;
              if (eventData.originalRepeatEndsAfter) {
                // If we are saving the event, we need to keep the original events and not the pending from this point
                eventData.recurrent.repeatEndsAfter =
                  eventData.originalRepeatEndsAfter;
              }
              delete eventData.originalRepeatEndsAfter;
              delete eventData.nextEvent;
              delete eventData.previousEvent;
              await events.helpers.update(eventData);
              break;
            case "following":
              // We create an event from this date and delete anything that is beyond this date
              eventData.parentId = eventData.parentId || $scope.eventId;
              delete eventData._id;
              delete eventData.originalRepeatEndsAfter;
              delete eventData.instanceId;
              eventData.previousEvent = $scope.eventId;
              delete eventData.nextEvent;
              const nextEvent = await events.helpers.create(eventData);
              const previousEvent = await events.helpers.get($scope.eventId);
              previousEvent.nextEvent = nextEvent._id;
              await events.helpers.update(previousEvent);
              break;
            case "individual":
              eventData.parentId = eventData.parentId || $scope.eventId;
              eventData.isRecurrent = false;
              eventData.isRescheduled = true;
              eventData.isDeleted = false;
              eventData.eventId = eventData.eventId || $scope.eventId;
              delete eventData.originalRepeatEndsAfter;
              delete eventData.nextEvent;
              delete eventData.previousEvent;
              delete eventData.recurrent;
              if (eventData.isException == true) {
                // We are already saving the exception
                await events.helpers.update(eventData);
              } else {
                // doesn't exist yet
                eventData.isException = true;
                delete eventData._id;
                await events.helpers.create(eventData);
              }

              break;
            default:
              await events.helpers.update(eventData);
              break;
          }
          const parentId = $scope.event.parentId || $scope.eventId;
          await events.helpers.toggleEnabled(parentId, $scope.event.enabled);
        } else {
          await events.helpers.create(eventData);
        }
        $scope.applyChanges();
      } catch (error) {
        $scope.isSaving = false;
        console.log(error);
      }
    };

    $scope.enableToggle = (e = null) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      const eventId = $scope.event.parentId || $scope.event._id;
      const toggle = $scope.event.enabled || false;
      $scope.event.enabled = !toggle;
      events.helpers.toggleEnabled(eventId, !toggle);
    };

    $scope.applyChanges = () => {
      applyChangesOnScope($scope, () => {
        $route.reload();
        $location.path("/events");
      });
    };

    $scope.openTimeDateWindow = function($event, idx) {
      $event.preventDefault();
      $event.stopPropagation();

      $scope.openedTimeDateSelector[idx] = true;
    };

    $scope.deleteFile = function(tag, $ev) {
      $ev.stopPropagation();
      $ev.preventDefault();
      applyChangesOnScope($scope, () => {
        if (tag === "coverImage") delete $scope.event.coverImage;
        if (tag === "sponsorLogo") delete $scope.event.sponsorLogo;
      });
    };

    $scope.showFileLoading = function(tag, bShow) {
      $scope.isUploading[tag] = bShow;
    };

    $scope.selectFileOpen = function(fileId, $ev) {
      if ($ev.target.tagName == "DIV") {
        setTimeout(() => {
          $("#" + fileId).click();
        }, 0);
      }
    };

    $scope.onFileUpload = function(files, tag) {
      let imgNum = 0;
      if (tag === "sponsorLogo" && files.length > 1) imgNum = 1;
      const file = files[imgNum];
      // var file = files[0]
      $scope.fileProgress[tag] = 0;
      $scope.showFileLoading(tag, true);
      const reader = new FileReader();
      reader.onload = function(e) {
        if (tag === "coverImage") $scope.uploadingFileUrl = e.target.result;
        if (tag === "sponsorLogo") $scope.uploadingSponsorUrl = e.target.result;
      };
      reader.readAsDataURL(file);

      events.helpers.upload(
        file,
        (error, uploadedFile) => {
          if (error) {
            $scope.uploadingFileUrl = null;
            $scope.showFileLoading(tag, false);
            return;
          }
          events.helpers.getFile(uploadedFile, o => {
            $scope.uploadingFileUrl = null;
            if (tag === "coverImage") $scope.event.coverImage = o;
            if (tag === "sponsorLogo") $scope.event.sponsorLogo = o;
            $scope.showFileLoading(tag, false);
            $scope.$apply();
          });
        },
        {
          _workflow: "coverPhoto"
        },
        (total, prog) => {
          applyChangesOnScope($scope, () => {
            $scope.fileProgress[tag] = (prog * 100) / total;
          });
        }
      );
    };

    // attempted here to reset geocode. marker doesn't move to new address but new geocode is right
    $scope.resetGeocode = function() {
      geoAddress = "";
      marker = null;
      delete $scope.event._geoloc;
      $scope.viewGeocode = false;
      addressList = [];
    };

    let addressList = [];
    $scope.openGeocode = function() {
      addressList = [];
      marker = null; // this down't work to reset marker if address is changed
      if ($scope.event.address1 && $scope.event.address1 != "") {
        addressList.push($scope.event.address1);
      }
      if ($scope.event.city && $scope.event.city != "") {
        addressList.push($scope.event.city);
      }
      if ($scope.event.state && $scope.event.state != "") {
        addressList.push($scope.event.state);
      }
      if ($scope.event.postalCode && $scope.event.postalCode != "") {
        addressList.push($scope.event.postalCode);
      }
      $scope.viewGeocode = true;
      setTimeout(() => $scope.initMap(), 200); // google maps needs this timeout for some reason
    };

    $scope.close = function() {
      $scope.viewGeocode = false;
    };

    $scope.confirmAddress = function() {
      if (markerGeocode.indexOf("") !== -1) return;
      $scope.event._geoloc = [markerGeocode[1], markerGeocode[0]];
      $scope.viewGeocode = false;
    };

    let geoAddress;
    let marker = null;
    $scope.initMap = function() {
      const map = new google.maps.Map(document.getElementById("geo_map"), {
        zoom: 12,
        center: { lat: 42.3005383, lng: -71.0654838 } // phila! :D
      });

      const geocoder = new google.maps.Geocoder();

      if (addressList.length > 0) {
        geoAddress = addressList.join(", ");
        // document.getElementById('address').value = address
        $scope.geocodeAddress(geocoder, map);
      } else {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(position => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            map.setCenter(pos);
          });
        }
      }

      document.getElementById("submit").addEventListener("click", () => {
        $scope.geocodeAddress(geocoder, map);
      });

      map.addListener("click", e => {
        $scope.placeMarkerAndPanTo(e.latLng, map);
      });
    };

    let markerGeocode = [];
    $scope.placeMarkerAndPanTo = function(latLng, map) {
      if (marker) {
        marker.setPosition(latLng);
      } else {
        marker = new google.maps.Marker({
          map: map,
          position: latLng
        });
      }
      markerGeocode = [latLng.lat(), latLng.lng()];
    };

    $scope.geocodeAddress = function(geocoder, resultsMap) {
      if (geoAddress && geoAddress !== "") {
        geocoder.geocode({ address: geoAddress }, (results, status) => {
          if (status === "OK") {
            resultsMap.setCenter(results[0].geometry.location);
            $scope.placeMarkerAndPanTo(
              results[0].geometry.location,
              resultsMap
            );
          } else {
            alert(
              "Geocode was not successful for the following reason: " + status
            );
          }
        });
      }
    };

    $rootScope.fileUploadFromSearch2 = function(file, tag) {
      file._filename = Date.now() + "";
      const files = [];
      files.push(file);
      $scope.onFileUpload(files, tag);
    };

    $scope.searchImages2 = function($ev, tag) {
      $ev.stopPropagation();
      $ev.preventDefault();
      $modal.open({
        templateUrl: "views/templates/searchImages.html",
        backdrop: "static",
        controller: function($scope, $modalInstance) {
          $scope.commonUtil = commonUtil;
          $scope.searchResults = 0;
          $scope.pages = 1;
          $scope.showLoadMore = false;
          // var cacheSearchKey = ''

          $scope.cancel = function() {
            $modalInstance.dismiss("cancel");
          };
          $scope.close = function() {
            $modalInstance.dismiss("cancel");
          };

          $scope.searchImages = function(searchVal) {
            $scope.pages = 1;
            if (searchVal) {
              const URL =
                "https://pixabay.com/api/?key=2706353-ee3016f8af51ca406a8c8a3db&q=" +
                encodeURIComponent(searchVal) +
                "&image_type=photo&page=" +
                $scope.pages;
              $.getJSON(URL, data => {
                if (parseInt(data.totalHits) > 0) {
                  applyChangesOnScope($scope, () => {
                    if (data.totalHits > $scope.pages * 20) {
                      $scope.showLoadMore = true;
                    } else {
                      $scope.showLoadMore = false;
                    }

                    $scope.searchResults = data.hits;
                  });
                }
              });
            }
          };

          $scope.searchImagesMore = function(tabIdx, searchVal) {
            $scope.pages++;
            if (searchVal) {
              const URL =
                "https://pixabay.com/api/?key=2706353-ee3016f8af51ca406a8c8a3db&q=" +
                encodeURIComponent(searchVal) +
                "&image_type=photo&page=" +
                $scope.pages;
              $.getJSON(URL, data => {
                if (parseInt(data.totalHits) > 0) {
                  applyChangesOnScope($scope, () => {
                    if (data.totalHits > $scope.pages * 20) {
                      $scope.showLoadMore = true;
                    } else {
                      $scope.showLoadMore = false;
                    }

                    $scope.searchResults = $scope.searchResults.concat(
                      data.hits
                    );
                  });
                }
              });
            }
          };

          $scope.selectImage = function(searchItem) {
            $scope.isDownloading = true;
            let imageUrl = "";
            imageUrl = searchItem.webformatURL;

            const xhr = new XMLHttpRequest();
            xhr.open("GET", imageUrl, true);
            xhr.responseType = "blob";
            xhr.onload = function(e) {
              if (this.status == 200) {
                const myBlob = this.response;
                $rootScope.fileUploadFromSearch2(myBlob, tag);
                // myBlob is now the blob that the object URL pointed to.
                $scope.cancel();
              }
            };
            xhr.send();
          };
        }
      });
    };

    $scope.loadTemplate = $ev => {
      $ev.stopPropagation();
      $ev.preventDefault();

      if (!$scope.eventTemplate.selected) return
      const { event } = $scope.eventTemplate.selected;
      $scope.event = { ...event };

      const formData = {};

      if (event.categories && event.categories.length > 0) {
        formData.categories = $scope.categories.filter(item =>
          event.categories.includes(item._id)
        );
      }
      if (event.segments && event.segments.length > 0) {
        formData.segments = $scope.segments.filter(item =>
          event.segments.includes(item._id)
        );
      }
      if (event.state) formData.state = event.state;
      $scope.formData = formData;
    };
  }
]);
