import {
  UPDATE_CERTIFICATIONS_COUNT
} from '../constants/types'

export interface Action {
  type: string,
  count: number
}

export const update = (count): Action => {
  return {
    type: UPDATE_CERTIFICATIONS_COUNT,
    count
  }
}
