export const UPDATE_SPRINTS_EXPORTS_LIST = 'UPDATE_SPRINTS_EXPORTS_LIST'
export const UPDATE_SPRINTS_LIST = 'UPDATE_SPRINTS_LIST'
export const UPDATE_SPRINTS_TAB_LIST = 'UPDATE_SPRINTS_TAB_LIST'
export const UPDATE_SPRINTS_SETTINGS = 'UPDATE_SPRINTS_SETTINGS'
export const UPDATE_SPRINTS_PAGE_SETTINGS = 'UPDATE_SPRINTS_PAGE_SETTINGS'
export const UPDATE_SPRINTS_KEYWORD_SETTINGS = 'UPDATE_SPRINTS_KEYWORD_SETTINGS'
export const UPDATE_SPRINTS_COMMUNITY_SETTINGS = 'UPDATE_SPRINTS_COMMUNITY_SETTINGS'
export const RESET_SPRINTS_SETTINGS = 'RESET_SPRINTS_SETTINGS'
export const UPDATE_SPRINTS_ITEM = 'UPDATE_SPRINTS_ITEM'

export const DELETE_ITEM = 'DELETE_ITEM'
export const ADD_ITEM = 'ADD_ITEM'
export const EDIT_ITEM = 'EDIT_ITEM'
// // export const ADD_ITEM = 'ADD_ITEM'
