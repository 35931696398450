import { UPDATE_COMMUNITIES_BOARD_LIST } from '../constants/types'
import { Action } from '../actions/list'

export interface Item {
  includeCommonActions: boolean
  status: string
  points: number
  eventsEnabled: boolean
  volunteerOpportunitiesEnabled: boolean
  _id: string
  actionsTitle: string
  enabled: boolean
  servicesDescription: string
  placesTitle: string
  servicesEnabled: boolean
  placesDescription: string
  servicesTitle: string
  volunteerOpportunitiesDescription: string
  includeCommonPlaces: boolean
  eventsDescription: string
  showCategoriesInSearch: boolean
  eventsTitle: string
  includeCommonServices: boolean
  name: string
  volunteerOpportunitiesTitle: string
  code: string
  placesEnabled: boolean
  whitelistEnabled: boolean
  actionsEnabled: boolean
  actionsDescription: string
  welcomeEmailEnabled: boolean
  includeCommonEvents: boolean
  includeCommonVolunteerOpportunities: boolean
  profileChartsEnabled: boolean
}
export type List = Item[]

const initialState: List = []

export default (state: List = initialState, action: Action): List => {
  switch (action.type) {
    case UPDATE_COMMUNITIES_BOARD_LIST:
      return action.communities
    default:
      return state
  }
}
