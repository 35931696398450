import { UPDATE_NEWEST_USERS_SETTINGS } from '../constants/types'

export interface Action {
  type: string,
  count: number
}

export const update = (count: number): Action => {
  return {
    type: UPDATE_NEWEST_USERS_SETTINGS,
    count
  }
}
