'use strict'

import * as events from '../../../../src/features/events'
import * as communities from '../../../../src/features/communities'
import * as categories from '../../../../src/features/categories'
import * as collections from '../../../../src/features/collections'
import * as activities from '../../../../src/features/activities'
import commonUtil from '../../../../src/helpers/commonUtil'
import bendHelper from '../../../../src/helpers/bendHelper'

angular.module('app.controllers')
  .controller('LegacyEventController', ['$scope', '$location', 'BendAuth', '$ngRedux',
    async function ($scope, $location, BendAuth, $ngRedux) {
      $scope.CommonUtil = commonUtil

      $scope.user = BendAuth.getActiveUser()

      const allCats = [{
        name: 'All categories',
        _id: null
      }]

      const mapStateToThis = (state) => ({
        eventList: state.events.list,
        categories: allCats.concat(state.categories.list),
        collectionList: state.collections.list,
        communityList: state.communities.list,
        activities: state.activities.list,
        collectionView: {
          searchTerm: state.events.settings.searchTerm,
          itemsPerPage: state.events.settings.itemsPerPage,
          isLoading: state.events.settings.isLoading,
          totalItems: state.events.settings.totalItems,
          currentPage: state.events.settings.currentPage,
          numPages: state.events.settings.numPages
        },
        filter: {
          community: state.events.settings.community,
          category: state.events.settings.category,
          collection: state.events.settings.collection
        }
        // sortKey: state.events.settings.sortKey,
        // sortDirection: state.events.settings.sortDirection
      })

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      if (!$scope.user.communityAdmin) communities.helpers.init()
      categories.helpers.getList()
      collections.helpers.initWithAll()

      $scope.sortDirection = {
        startsAt: -1,
        name: 1
      }

      $(document).on('click', '.pagination li:not(.disabled) a', (e) => {
        $scope.isLoading = true
        const n = $(e.target).text()
        events.helpers.paginateToPage(n)
        $scope.loadList()
      })

      events.helpers.reloadLists()

      $scope.reset = async () => {
        $scope.isLoading = true
        const communityId = $scope.user.communityAdmin ? $scope.user.community._id : null
        await events.helpers.reset(communityId)
        $scope.isLoading = false
      }

      $scope.loadList = async () => {
        if ($scope.eventList.length === 0) {
          $scope.isLoading = true
        }
        if ($scope.user.communityAdmin) {
          events.helpers.selectCommunity($scope.user.community._id)
        }
        await events.helpers.init()
        $scope.isLoading = false
      }

      $scope.sortBy = async (key) => {
        $scope.sortKey = key
        const direction = $scope.sortDirection
        direction[key] = (-1) * direction[key]
        events.helpers.sortBy(key, direction)
      }
      setTimeout(() => {
        $scope.loadList().then(() => $scope.sortBy('startsAt'))
      }, 200)

      $scope.onSearch = function () {
        $scope.isLoading = true
        events.helpers.handleSearchKeyword($scope.collectionView.searchTerm)
        $scope.loadList()
      }

      $scope.selectCommunity = (community) => {
        $scope.isLoading = true
        const id = typeof community === 'object' && community._id
        events.helpers.selectCommunity(id)
        $scope.loadList()
      }

      $scope.selectCollection = (collection) => {
        $scope.isLoading = true
        const id = typeof collection === 'object' && collection._id
        events.helpers.selectCollection(id)
        $scope.loadList()
      }

      $scope.selectCategory = (category) => {
        $scope.isLoading = true
        const id = typeof category === 'object' && category._id // this is for if category groups are integrated
        events.helpers.selectCategory(category)
        events.helpers.getList()
        $scope.isLoading = false
      }

      $scope.editEvent = function (event, $e) {
        if ($e.metaKey || $e.ctrlKey) {
          window.open('#/legacy/events/' + event._id, '_blank')
        } else { return $location.path('/legacy/events/' + event._id) }
      }

      $scope.createEvent = function ($event) {
        if ($event.metaKey || $event.ctrlKey) {
          window.open('#/legacy/events/new', '_blank')
        } else { return $location.path('/legacy/events/new') }
      }

      $scope.enableToggle = async (item, e) => {
        // console.log("enable toggle")
        await bendHelper.enableHelper(e, item, events)
      }
    }])
