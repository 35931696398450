import {
  UPDATE_EVENT_TEMPLATES_LIST
} from '../constants/types'
import { EventTemplates } from '../reducers/list'

export interface Action {
  type: string,
  eventTemplates: EventTemplates
}

export const update = (eventTemplates: EventTemplates): Action => {
  return {
    type: UPDATE_EVENT_TEMPLATES_LIST,
    eventTemplates
  }
}
