'use strict'

import commonUtil from '../../helpers/commonUtil'

import helpers from './helpers'

angular.module('app.controllers')
  .controller('ModerationController', ['$scope', 'BendAuth', '$rootScope', '$ngRedux',
    async function ($scope, BendAuth, $rootScope, $ngRedux) {
      $scope.commonUtil = commonUtil
      $scope.isLoading = true

      $scope.user = BendAuth.getActiveUser()

      const mapStateToThis = (state) => {
        return {
          filter: {
            community: state.teams.settings.community
          }
          // sortKey: state.comments.settings.sortKey,
          // sortDirection: state.comments.settings.sortDirection
        }
      }
   
      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      $scope.sortKey = ''
      $scope.sortDirection = {
        comment: null,
        image: null,
        text: null
      }
      $scope.sortDirection['_bmd.createdAt'] = true
      $scope.sortDirection['user.name'] = false
      $scope.sortBy = (key) => {

        $scope.sortKey = key
        let direction = $scope.sortDirection
        if (direction[key]) direction[key] = (-1) * direction[key]
        else direction[key] = -1
        $scope.comments = commonUtil.sortBy($scope.comments, key, direction)
      }
     
    
      function load () {
        $scope.isLoading = true
        helpers.initPostsAndComments().then(obj => {
          $scope.comments = obj.comments
          $scope.posts = obj.posts
          
        
          applyChangesOnScope($scope, () => {})
         
      
          $scope.isLoading = false
        })
        helpers.initConversations().then(convosAndUsers => {
          $scope.convosAndUsers = convosAndUsers
          $scope.isLoading = false
        })
       
      }

      load()
     
      $scope.view = {
        posts: true,
        comments: false,
        conversations: false
      }
      

      $scope.getHighestUnread = convo => {
        if (convo.unread1 > convo.unread2) return `${convo.user2.name} sent ${convo.unread1} unread`
        if (convo.unread2 > convo.unread1) return `${convo.user1.name} sent ${convo.unread2} unread`
      }

      
      $scope.viewList = (txt) => {
        Object.keys($scope.view).map(list => {
          $scope.view[list] = false
        })
        $scope.view[txt] = !$scope.view[txt]
      }

      $scope.deleteCommentOrPost = async (id, txt) => {
        await helpers.deleteCommentOrPost(id, txt)
        setTimeout(() => load(), 200)
      }

     
    }])
