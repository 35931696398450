export const UPDATE_TASKS_LIST = 'UPDATE_TASKS_LIST'
export const ADD_TASK = 'ADD_TASK'
export const REMOVE_INCOMPLETE_TASK = 'REMOVE_INCOMPLETE_TASK'
export const MARK_TASK_COMPLETE = 'MARK_TASK_COMPLETE'
export const MARK_TASK_INCOMPLETE = 'MARK_TASK_INCOMPLETE'
export const EDIT_TASK = 'EDIT_TASK'
export const EDIT_TASKS_EDITING_LIST = 'EDIT_TASKS_EDITING_LIST'
export const CANCEL_TASK_EDIT = 'CANCEL_TASK_EDIT'
export const SAVE_EDITED_TASK = 'SAVE_EDITED_TASK'
export const TOGGLE_TASK_FORM = 'TOGGLE_TASK_FORM'
