import * as _ from 'lodash'

import {
  UPDATE_COMMENTS_TOTALS_SETTINGS,
  UPDATE_COMMENTS_PAGE_SETTINGS,
  UPDATE_COMMENTS_SUBJECT_SETTINGS,
} from '../constants/types'

import { Action } from '../actions/settings'

export interface Settings {
  itemsPerPage: number,
  totalItems: number,
  currentPage: number,
  numPages: number,
  subject: any,
  subjectType: string
}

const initialState: Settings = {
  itemsPerPage: 20,
  totalItems: 0,
  currentPage: 1,
  numPages: 0,
  subject: {
    _id: null
  },
  subjectType: ''
}

export default (state: Settings = initialState, action: Action): Settings => {
  switch (action.type) {
    case UPDATE_COMMENTS_TOTALS_SETTINGS:
      return {
        ...state,
        totalItems: action.count,
        numPages: Math.ceil(action.count / state.itemsPerPage + 1)
      }
    case UPDATE_COMMENTS_PAGE_SETTINGS:
      return {
        ...state,
        currentPage: action.page
      }
    case UPDATE_COMMENTS_SUBJECT_SETTINGS:
      return {
        ...state,
        subject: action.subject,
        subjectType: action.subjectType
      }
    default:
      return state
  }
}
