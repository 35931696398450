'use strict'

import * as utilities from '../../../src/features/utilities'

angular.module('app.controllers')
  .controller('UtilityController', ['$scope', '$bend', '$location', '$routeParams', 'BendAuth', 'CommonUtil', '$bootbox', '$rootScope', '$modal', '$timeout', 'pinesNotifications',
    function ($scope, $bend, $location, $routeParams, BendAuth, CommonUtil, $bootbox, $rootScope, $modal, $timeout, pinesNotifications) {
      $scope.uncodedBusinessCount = 0
      $scope.runningBulk = false
      $scope.isAdmin = false

      $scope.getUncodedBusinessCount = function () {
        utilities.helpers.getUncodedBusinessCount().then((count) => {
          $scope.uncodedBusinessCount = count
        })
      }

      $scope.executeBulkForUncodedBusiness = function (geocoder) {
        if (!$scope.runningBulk) return

        if ($scope.uncodedBusinessCount == 0) {
          $scope.runningBulk = false
          return
        }

        utilities.helpers.getBusinesses().then(function (rets) {
          if (rets == 0) {
            $scope.runningBulk = false
            return
          }

          async.eachSeries(rets, function (business, callback) {
            if (!$scope.runningBulk) {
              callback(null, null)
              return
            }
            var addressList = []
            if (business.address1 && business.address1 != '') { addressList.push(business.address1) }
            if (business.city && business.city != '') { addressList.push(business.city) }
            if (business.state && business.state != '') { addressList.push(business.state) }
            if (business.postalCode && business.postalCode != '') { addressList.push(business.postalCode) }

            if (addressList.length > 0) {
              $scope.geocodeAddress(geocoder, addressList.join(','), function (err, lat, lang) {
                if (err) {
                  if (err == 'ZERO_RESULTS') {
                    callback(null, null)
                    return
                  }
                  alert('The daily quota for geocoding has been reached. Please try again later.')
                  callback(err)
                  return
                }

                // update business
                business._geoloc = [lang, lat]
                utilities.helpers.updateBusiness(business).then(function (ret) {
                  $scope.uncodedBusinessCount--
                  callback(null, ret)
                }, function (err) {
                })
              })
            } else {
              callback(null, null)
            }
          }, function (err, ret) {
            if (err) {
              $scope.runningBulk = false
              return
            }

            $scope.executeBulkForUncodedBusiness(geocoder)
          })
        }, function (err) {
          $scope.runningBulk = false
        })
      }

      $scope.geocodeBusiness = function (e) {
        if (e) {
          e.preventDefault()
          e.stopPropagation()
        }

        if ($scope.runningBulk) {
          $scope.runningBulk = false
          return
        }

        var geocoder = new google.maps.Geocoder()
        $scope.runningBulk = true
        $scope.executeBulkForUncodedBusiness(geocoder)
      }

      $scope.geocodeAddress = function (geocoder, address, callback) {
        geocoder.geocode({'address': address}, function (results, status) {
          if (status === 'OK') {
            var latLng = results[0].geometry.location
            callback(null, latLng.lat(), latLng.lng())
          } else {
            callback(status)
            // alert('Geocode was not successful for the following reason: ' + status);
          }
        })
      }

      $scope.uncodedBusinessCount = 0
      $scope.getUncodedBusinessCount()
    }])
