import { TOGGLE_TASK_FORM } from '../constants/types'
import { Action } from '../actions/form'

const initialState = {
  showForm: false,
  showTasksTab: true,
  showCompletedTab: false
}

export interface Form {
  showForm: boolean,
  showTasksTab: boolean,
  showCompletedTab: boolean
}

export default (state: Form = initialState, action: Action): Form => {
  switch (action.type) {
    case TOGGLE_TASK_FORM:
      return {
        ...state,
        showForm: !state.showForm
      }
    default:
      return state
  }
}
