import {
  UPDATE_USERS_LIST
} from '../constants/types'
import { Users } from '../reducers/list'

export interface Action {
  type: string,
  users: Users
}

export const update = (users: Users): Action => {
  return {
    type: UPDATE_USERS_LIST,
    users
  }
}
