import {
  UPDATE_CERTIFICATIONS_LIST
} from '../constants/types'
import { Certifications } from '../reducers/list'

export interface Action {
  type: string,
  certifications: Certifications
}

export const update = (certifications: Certifications): Action => {
  return {
    type: UPDATE_CERTIFICATIONS_LIST,
    certifications
  }
}
