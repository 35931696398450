import BendClient, { BendTable } from '../BendClient/index'
import * as actions from './actions'
import redux from '../../helpers/redux'
import commonUtil from '../../helpers/commonUtil'
import { getActiveUser } from '../../helpers';
const moment = require('moment');

const forEachAsync = async (array, callback) => {
	for (let index = 0; index < array.length; index++) {
		await callback(array[index], index, array)
	}
}

class Feature {

	private api: any
	private api2: any

	constructor() {
		this.api = BendClient
		this.api2 = BendTable
	}

	public async init() {
		const settings = redux.getState().goals.settings
		const related = {
			relations: {
			  icon: 'BendFile',
			}
		  }

		const goals = await this.api2.getList(BendTable.Tables.GOALS, settings,related)

		redux.dispatch(
			actions.settings.update(goals.length)
		)
		redux.dispatch(
			actions.list.update(goals)
		)
		return goals
	}

	// public async getList (id = getActiveUser().community._id) {
	//   const groups = await this.api2.getList(BendTable.Tables.GROUPS, { communityId: id })
	//   redux.dispatch(
	//     actions.list.update(groups)
	//   )
	//   redux.dispatch(
	//     actions.settings.update(groups.length)
	//   )
	//   return groups
	// }

	public async sortBy(key, direction) {
		let goals = redux.getState().goals.list
		let sortedList = commonUtil.sortBy(goals, key, direction)
		redux.dispatch(
			actions.list.update(sortedList)
		)
	}

	public paginateToPage(page) {
		redux.dispatch(
			actions.settings.pageUpdate(page)
		)
	}

	public selectGroup(id) {
		let selected = redux.getState().groups.list.groups.find(item => item._id === id)
		redux.dispatch(
			actions.settings.groupUpdate(selected)
		)
	}

	public selectTeam(id) {
		let selected = redux.getState().teams.list.teams.find(item => item._id === id)
		redux.dispatch(
			actions.settings.teamUpdate(selected)
		)
	}


	public selectSegment(id) {
		let selected = redux.getState().segments.list.find(item => item._id === id)
		redux.dispatch(
			actions.settings.segmentUpdate([selected])
		)
	}

	public selectState(value) {
		redux.dispatch(
			actions.settings.stateUpdate(value)
		)
	}

	public get(id) {
		const related = {
			relations: {
			  icon: 'BendFile',
			}
		  }
	  
		return this.api2.get(BendTable.Tables.GOALS, id, related)
	}

	public create(data) {
		return this.api2.create(BendTable.Tables.GOALS, data)
	}

	public update(data) {
		return this.api2.update(BendTable.Tables.GOALS, data)
	}

	public delete(id) {
		return this.api2.delete(BendTable.Tables.GOALS, id)
	}

	public reset() {
		redux.dispatch(
			actions.settings.reset()
		)
	}

	public getActivity(id) {
		return this.api.getGoalActivity(id)
	}

	public  getAllGoalsActivity() {
		const params = {
			relations: {
				user: 'user',
				goal: 'goals'
			  }
		  }
		  const settings = {
			community: getActiveUser().community._id
		  }
		return this.api2.getList(BendTable.Tables.GOALS_STATUS, settings, params)
	}

	public upload (file, callback, ext, progressCallback) {
		return this.api.upload(file, callback, ext, progressCallback)
	  }
	
	  public getFile (refObj, callback) {
		return this.api.getFile(refObj, callback)
	  }
	// public isInUse(id) {
	//   return this.api.isUserGroupInUse(id);
	// }

}

export default new Feature()
