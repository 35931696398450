import * as _ from 'lodash'
import BendClient from '../features/BendClient'
import * as challenges from '../features/challenges'
import * as communities from '../features/communities'
import * as surveys from '../features/surveys'
import * as events from '../features/events'
import { getActiveUser } from '../helpers'

class Feature {

  private api: any

  constructor() {
    this.api = BendClient
  }

  manageItems() {
    return this.api.manageItems('push')
  }

  /*
  * update if any values have changed
  * delete push if requested
  * oldData - original linked item
  * newData - updated linked item
  */
  public shouldUpdateAutoPush = (oldData: any = {}, newData, type, pushToggle) => {
    // delete associated push if link toggled off
    if (!pushToggle && oldData.push) {
      this.manageItems().delete(oldData.push._id)
      newData.push = null
      if (type === 'challenge') challenges.helpers.manageItems().edit(newData)
      if (type === 'event') events.helpers.manageItems().edit(newData)
      // if (type === 'pollQuestion') this.manageItems().edit(newData).then((res, err) => { })
      // if (type === 'pollQuestion') .updatePollQuestion(newData, $scope.answerList, function (ret) { })
      return undefined
    }

    if (!pushToggle) return false

    // generic checks
    let oldPushId = 0
    let oldTeams = 0
    let newTeams = 0
    if (oldData) oldPushId = _.get(oldData, 'push._id', null)
    if (oldData && oldData.teams) oldTeams = _.get(oldData, 'teams', []).sort()
    if (newData && newData.teams) newTeams = _.get(newData, 'teams', []).sort()

    if (pushToggle) {
      if (
        !_.isEqual(oldTeams, newTeams) ||
        !oldPushId // wasn't an associated push, now we wants it
      ) {
        return true
      }
      // type specific checks
      if (
        (type === 'challenge' ||
          type === 'event') &&
        (oldData.startsAt !== newData.startsAt ||
          oldData.endsAt !== newData.endsAt)
      ) {
        return true
      }
      if (
        type === 'challenge' &&
        oldData.title !== newData.title
      ) {
        return true
      } else if (
        type === 'event' &&
        oldData.name !== newData.name
      ) {
        return true
      } else if (
        type === 'pollQuestion' &&
        (oldData.pollDate !== newData.pollDate ||
          oldData.text !== newData.text) // add question
      ) {
        return true
      }
    }
  }

  // should check here for if push exists and update it if so regardless, maybe update fn should be for deleting push
  /*
  * send push notification at noon on day that poll/event/challenge goes live
  */
  public async autoPush(pushText, data, type) {
    const user = getActiveUser()
    let push: any = {}

    const community = await communities.helpers.get(user.community._id)

    // default settings, user doesn't choose these

    push.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone // we can also use Date().getTimezoneOffset() to calculate noon local time for the push to be sent
    /// build reference to linked db record into the push
    push[type] = {
      _type: 'BendRef',
      _id: data._id,
      _collection: type
    }
    /// other default settings
    push.scheduled = true
    push.status = 'pending'
    push.userQuery = '{}'
    push.params = [{
      key: 'deeplink',
      value: 'milkcrate://home'
    }]
    // push.group = $scope.user.community._id
    /// prep with default audience object
    push.audience = {
      defaultQuery: {
        'community._id': user.community.id
      },
      audiences: []
    }
    push._acl = {
      creator: user._id
    }

    let appName = community.appName.toLowerCase()

    // custom settings, user chooses these
    if (type === 'pollQuestion') { // this is saved in the pollq controller bc answerlist
      push.title = 'Today\'s Poll'
      // push.params[0].value = community.appName + '://pollQuestion/' + id
    } else if (type === 'challenge') {
      push.title = 'Today\'s Challenge'
      push.params[0].value = appName + '://actions/' + data.activity._id
    } else if (type === 'event') {
      push.title = 'Today\'s Event'
      push.params[0].value = appName + '://events/' + data._id
    } else if (type === 'survey') {
      push.title = 'Today\'s Event'
      push.params[0].value = appName + '://surveys/' + data._id
    }
    push.text = pushText

    if (type === 'challenge' || type === 'event') {
      let startDate = new Date(data.startsAt / 1000000)
      let endDate = new Date(data.endsAt / 1000000)
      if (
        startDate.getTime() < Date.now() && // challenge is on right now
        endDate.getTime() > Date.now()
      ) {
        push.datetime = (Date.now() + 60000 * 2) * 1000000// send push in 2 minutes, gives time to cancel if desired
      } else { // schedule push for startDate 9am
        startDate.setHours(9)
        startDate.setMinutes(0)
        startDate.setSeconds(0)
        push.datetime = startDate.getTime() * 1000000 // should this be dateTime or datetime?
      }
    } else if (type === 'survey') {
      let dateTime = new Date(data.date)
      dateTime.setHours(9)
      dateTime.setMinutes(0)
      dateTime.setSeconds(0)
      push.datetime = dateTime
    }

    if (data.teams) { // checks happen when team id array is built on save
      push.audience.audiences = data.teams
    }

    if (!data.push) { // save push
      this.manageItems().add(push).then(result => {
        data.push = {
          _type: 'BendRef',
          _id: result._id,
          _collection: 'push'
        }
        // add push link to the poll question
        if (type === 'challenge') challenges.helpers.manageItems().edit(data)
        if (type === 'event') events.helpers.manageItems().edit(data)
        if (type === 'survey') surveys.helpers.manageItems().edit(data)
        // these need extra functionality because of saving the answerlist seperately, already working in pollQEditController
        // if (type === 'pollQuestion') this.pollQuestionsHelpers.manageItems().edit(data)
      })
    } else { // update push
      this.manageItems().edit(push).then(result => {
        data.push = {
          _type: 'BendRef',
          _id: result._id,
          _collection: 'push'
        }
        // add push link to the poll question
        if (type === 'challenge') challenges.helpers.manageItems().edit(data)
        if (type === 'event') events.helpers.manageItems().edit(data)
        // these need extra functionality because of saving the answerlist seperately, already working in pollQEditController
        // if (type === 'pollQuestion') this.pollQuestionsHelpers.manageItems().edit(data)
      })
    }
  }

}

export default new Feature()
