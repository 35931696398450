'use strict'

import * as communities from '../communities'
import * as surveys from '../surveys'
import * as surveyQuestions from '.'

angular.module('app.controllers')
  .controller('SurveyQuestionController', ['$scope', '$location', 'BendAuth', '$ngRedux',
    async function ($scope, $location, BendAuth, $ngRedux) {
       $scope.isLoading = true

      const mapStateToThis = (state) => {
        return {
          surveyQuestions: state.surveyQuestions.list,
          surveys: state.surveys.list,
          collectionView: {
            itemsPerPage: state.surveys.settings.itemsPerPage,
            isLoading: state.surveys.settings.isLoading,
            totalItems: state.surveys.settings.totalItems,
            currentPage: state.surveys.settings.currentPage,
            numPages: state.surveys.settings.numPages
          },
          filter: {
            community: state.surveys.settings.community,
            team: state.surveys.settings.team
          },
          fields: {
            community: state.surveys.settings.community,
            teams: state.surveys.settings.communityTeams
          }
        }
      }
 
      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      $scope.user = BendAuth.getActiveUser()
      surveyQuestions.helpers.init()
      setTimeout(() => surveyQuestions.helpers.init(), 1000)

      // Fetches all questions from Poll Question collection, filtering on the community ID  
      surveys.helpers.init()


      $scope.selectCommunity = async communityId => {
        $scope.isLoading = true
        surveys.helpers.selectCommunity(communityId).then(() => {
          $scope.isLoading = false
        })
      }

      // user object needs to set community ID to determine what survey questions load
      if ($scope.user.communityAdmin) {
        $scope.selectCommunity($scope.user.community._id)
      }

      // must be called after community is set above
      communities.helpers.initEnabled().then(() => {        
        if (typeof $scope.filter.community === 'object' && !$scope.filter.community._id) {
          const community = _.get($scope.communityList, '[0]._id')
          $scope.selectCommunity(community)
        }
        $scope.loadList()
      })

      $scope.loadList = async () => {
        await surveys.helpers.init()
        $scope.isLoading = false
      }

      // Path to edit a specific a question 
      $scope.editSurveyQuestion = function (survey, $event) {

        if ($event.metaKey || $event.ctrlKey) {
          window.open('#/surveys/' + survey._id, '_blank')
        } else {
          return $location.path('/surveyQuestions/' + survey._id)
        }
      }

      // Path to create a new survey question 
      $scope.createSurveyQuestion = function ($event) {
        if ($event.metaKey || $event.ctrlKey) {
          window.open('#/surveys/new', '_blank')
        } else { return $location.path('/surveyQuestions/new') }
      }

      // for FPC -- responseCount in each questions object is always 0 - why?
        // Answer: Likely because the questioin object isn't being updated anywhere with the right number of responses  
      $scope.getResponseCount = function (list) {
        if (!list || list.length == 0) return 0
        if ($scope.user.communityAdmin) {
          return list[$scope.user.community._id] || 0
        }
        var count = 0
        _.map(list, function (o) {
          count += o
        })
        return count
      }
    }])
