import * as calendar from '../../../src/features/calendar'

angular.module('app.controllers')
  .controller('ChallengeCalendarController', ['$scope', '$theme', '$bend', 'BendAuth', '$ngRedux',
    async function ($scope, $theme, BendAuth, $ngRedux) {
      'use strict'

      $scope.filter = {
        community: null
      }
      $scope.events = []
      $scope.eventSources = [$scope.events]
      $scope.communityList = []
      $scope.user = BendAuth.getActiveUser()

      $scope.uiConfig = {
        calendar: {
          header: {
            left: 'prev,next today',
            center: 'title',
            right: 'month,agendaWeek,agendaDay'
          },
          selectable: true,
          buttonText: {
            prev: '<i class="fa fa-angle-left"></i>',
            next: '<i class="fa fa-angle-right"></i>',
            prevYear: '<i class="fa fa-angle-double-left"></i>', // <<
            nextYear: '<i class="fa fa-angle-double-right"></i>', // >>
            today: 'Today',
            month: 'Month',
            week: 'Week',
            day: 'Day'
          }
        }
      }

      $scope.getChallengeList = async () => {
        const settings = {
          communityId: $scope.user.community._id
        }
        const challenges = await calendar.helpers.getChallenges(settings)
        applyChangesOnScope($scope, () => {
          $scope.challengeList = challenges
          $scope.isLoading = false

          var current = Date.now()
          $scope.events.length = 0
          _.map($scope.challengeList, function (o) {
            if (o.startsAt && o.endsAt) {
              $scope.events.push({
                title: o.title,
                start: new Date(o.startsAt / 1000000),
                end: new Date(o.endsAt / 1000000),
                url: '#/challenges/' + o._id,
                allDay: false,
                backgroundColor: (o.startsAt / 1000000 > current ? $theme.getBrandColor('primary')
                  : (o.endsAt / 1000000 < current ? $theme.getBrandColor('midnightblue')
                    : $theme.getBrandColor('warning')
                  )
                )
              })
            }
          })
        })
      }

      if (!$scope.user.communityAdmin) {
        calendar.helpers.getCommunityList().then((rets) => {
          $scope.communityList = rets
          $scope.filter.community = $scope.communityList[0]
          $scope.getChallengeList()
        })
      } else {
        $scope.filter.community = $scope.user.community._id
        $scope.getChallengeList()
      }

      // var date = new Date()
      // var d = date.getDate()
      // var m = date.getMonth()
      // var y = date.getFullYear()
    }])
