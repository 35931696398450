import {
  UPDATE_CATEGORIES_LIST
} from '../constants/types'
import { Categories } from '../reducers/list'

export interface Action {
  type: string,
  categories: Categories
}

export const update = (categories: Categories): Action => {
  return {
    type: UPDATE_CATEGORIES_LIST,
    categories
  }
}
