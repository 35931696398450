import {
  UPDATE_STUDIO_APPS_EXPORTS_LIST
} from '../constants/types'
import { Action } from '../actions/exports'

// contactName,
//   contactEmail,
//   contactPhone,
//   organization,
//   push,
//   volunteerops,
//   actions,
//   chat,
//   places: checkin,
//     polls,
//     socialMedia,
//     events,
//     sponsorships,
//     donations,
//     categories

export interface Item {
  contactEmail: string,
  contactName: string,
  url: string,
  organization: string,
  push: boolean,
  volunteerops: boolean,
  actions: boolean,
  chat: boolean,
  places: boolean,
  polls: boolean,
  socialMedia: boolean,
  events: boolean,
  sponsorships: boolean,
  donations: boolean,
  categories: Array<string>
}

export type Exports = Item[]

const initialState: Exports = []

export default (state: Exports = initialState, action: Action) => {
  switch (action.type) {
    case UPDATE_STUDIO_APPS_EXPORTS_LIST:
      return action.exports
    default:
      return state
  }
}
