'use strict'

import * as segmentTypes from '../segmentTypes'
import commonUtil from '../../helpers/commonUtil'

angular.module('app.controllers')
  .controller('SegmentTypeController', ['$scope', '$location', '$routeParams', 'BendAuth', '$bootbox', '$rootScope', '$timeout', '$ngRedux',
    async function ($scope, $location, $routeParams, BendAuth, $bootbox, $rootScope, $timeout, $ngRedux) {
       $scope.isLoading = true

      const mapStateToThis = (state) => {
        return {
          communityList: state.communities.list,
          segmentTypeList: state.segmentTypes.list,
          collectionView: {
            itemsPerPage: state.segmentTypes.settings.itemsPerPage,
            isLoading: state.segmentTypes.settings.isLoading,
            totalItems: state.segmentTypes.settings.totalItems,
            currentPage: state.segmentTypes.settings.currentPage,
            numPages: state.segmentTypes.settings.numPages
          },
          filter: {
            community: state.segmentTypes.settings.community,
            team: state.segmentTypes.settings.team,
            segmentType: state.segmentTypes.settings.segmentType
          },
          fields: {
            community: state.segmentTypes.settings.community,
            teams: state.segmentTypes.settings.communityTeams,
            segmentTypes: state.segmentTypes.settings.segmentTypes
          }
        }
      }

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)
      $scope.commonUtil = commonUtil

      await segmentTypes.helpers.init()
    
      $scope.user = BendAuth.getActiveUser()
      
      $scope.isLoading = false

      $scope.editSegmentType = function (segmentType, $event) {
        if ($event.metaKey || $event.ctrlKey) {
          window.open('#/segmentTypes/' + segmentType._id, '_blank')
        } else {
          return $location.path('/segmentTypes/' + segmentType._id)
        }
      }

      $scope.createSegmentType = function ($event) {
        if ($event.metaKey || $event.ctrlKey) {
          window.open('#/segmentTypes/new', '_blank')
        } else { return $location.path('/segmentTypes/new') }
      }
    }])
