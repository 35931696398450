import * as _ from 'lodash'
import * as actions from './actions'
import { getActiveUser } from '../../../src/helpers'
import BendClient, { BendTable } from '../BendClient'
import commonUtil from '../../helpers/commonUtil'
import redux from '../../../src/helpers/redux'

class Feature {

  private api: any
  private api2: any

  constructor () {
    this.api = BendClient
    this.api2 = BendTable
  }

  manageItems () {
    return this.api.manageItems('challenge')
  }

  public resetFilters () {
    const state = redux.getState()
    const communityList = state.communities.list
    // Preserve teamList to maintain reset button
    const teamList = state.challenges.settings.teams
    // it should have own reducer
    const filterTypeList = [{
      name: 'All Challenges',
      value: ''
    }, {
      name: 'Expired Challenges',
      value: '1'
    }, {
      name: 'Unexpired Challenges',
      value: '2'
    }]
    redux.dispatch(
      actions.settings.reset()
    )
    if (communityList instanceof Array && communityList.length) {
      // selectCommunity usage are little bit difficult now for this feature
      // it needs refactor like team's selectCommunity method, take a look at team worker class
      this.selectCommunity(communityList[0])
    }
    if (filterTypeList instanceof Array && filterTypeList.length) {
      // selectCommunity usage are little bit difficult now for this feature
      // it needs refactor like team's selectCommunity method, take a look at team worker class
      this.selectType(filterTypeList[0])
    }
    if (teamList instanceof Array && teamList.length) {
      // selectCommunity usage are little bit difficult now for this feature
      // it needs refactor like team's selectCommunity method, take a look at team worker class
      this.selectTeam(teamList[0])
    }

  }

  public async init () {
    const user = getActiveUser()
    if (user.community.admin) {
      this.selectCommunity(user.community)
    }
    this.fetchChallenges()
  }

  public async fetchChallenges () {
    const settings = redux.getState().challenges.settings
    const params = {
      relations: {
        community: 'community',
        activity: 'activity'
      }
    }
    let challenges = await this.api2.getList(BendTable.Tables.CHALLENGE, {}, params)
    // let challenges = await this.api.fetchChallenges(settings)

    challenges = challenges.sort((a, b) => b.startsAt - a.startsAt) // future challenges on top
    redux.dispatch(
      actions.list.update(challenges)
    )
    redux.dispatch(
      actions.settings.update(challenges.length)
    )
  }

  public paginateToPage (page) {
    redux.dispatch(
      actions.settings.pageUpdate(page)
    )
  }

  public handleSearchKeyword (keyword) {
    redux.dispatch(
      actions.settings.searchKeywordUpdate(keyword)
    )
  }

  public selectCommunity (community) {
    redux.dispatch(
      actions.settings.selectCommunity(community)
    )
  }

  public selectType (type) {
    redux.dispatch(
      actions.settings.selectType(type)
    )
  }

  public selectTeam (team) {
    redux.dispatch(
      actions.settings.selectTeam(team)
    )
  }

  public getCommunityList () {
    return this.api.getCommunityList()
  }

  public getCategoryListWithImages () {
    return this.api.getCategoryListWithImages()
  }

  public get (id) {
    const params = {
      relations: {
        activity: ['action', 'business', 'event', 'volunteer_opportunity'],
        coverImage: 'BendFile'
      }
    }
    return this.api2.get(BendTable.Tables.CHALLENGE, id, params)
  }

  public update (data) {
    return this.api2.update(BendTable.Tables.CHALLENGE, data)
  }

  public delete (id) {
    return this.api2.delete(BendTable.Tables.CHALLENGE, id)
  }

  public upload (file, callback, ext, progressCallback) {
    return this.api.upload(file, callback, ext, progressCallback)
  }

  public getFile (refObj, callback) {
    return this.api.getFile(refObj, callback)
  }

  public createChallenge (data) {
    let newData = _.clone(data)
    const userCommunityId = getActiveUser().community.id
    newData.communities = [userCommunityId] // for backward capability

    return this.api2.create(BendTable.Tables.CHALLENGE, newData)
  }

  public fetchLinkedPushNotification (settings: { challengeId: string }) {
    return this.api.fetchLinkedPushNotificationByChallenge(settings)
  }

  // public getTeamsByCommunity (settings: { communityId: string[] }) {
  //   return this.api.fetchTeamsByCommunities(settings)
  // }

  public async refreshActivities (settings: {
    keyword: string,
    type: string
  }) {
    // eliminate the following block once we figure out whats wrong with this.user in bendClient
    const communityId = getActiveUser().community.id
    let activities = await this.api2.getList(settings.type)
    // let activities = await this.api.refreshChallengeActivities(settings)
    activities = activities.filter(activity => {
      if (activity.community) return activity.community._id === communityId
      else return false
    })
    return activities
    // return this.api.refreshChallengeActivities(settings) // re-enable this once issue is fixed
  }

}

export default new Feature()
