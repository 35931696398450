'use strict'
//I think this is maybe deprecated and we are not using it. 
import * as communities from '../../../src/features/communities'

angular.module('app.controllers')
  .controller('CommunityPushTabController', ['$scope', '$routeParams', 'CommonUtil', 'pinesNotifications', '$ngRedux',
    async function ($scope, $routeParams, CommonUtil, pinesNotifications, $ngRedux) {
      $scope.isLoading = true
      $scope.CommonUtil = CommonUtil
      $scope.pushConfig = {}
      $scope.appName = null
      $scope.fileProgress = []
      $scope.isUploading = []
      $scope.communityId = $routeParams.id

      $scope.form = {}

      const pushConfig = await communities.helpers.getCommunityPushConfig($scope.communityId)
      $scope.pushConfig = pushConfig
      const appConfig = await communities.helpers.getCommunityAppConfig($scope.communityId)      
      $scope.appName = appConfig.appName

      $scope.saveDo = async () => {
        const data = _.clone($scope.pushConfig)
        if (data.appleCertificate) {
          data.appleCertificate = CommonUtil.makeBendFile(data.appleCertificate._id)
        }
        data.community = CommonUtil.makeBendRef($scope.communityId, 'community')
        await communities.helpers.saveCommunityPushConfig(data)

        //We get the appConfig on the same moment instead of loading and then modifying because we might override the images uploaded on the other tab. 
        const appConfig = await communities.helpers.getCommunityAppConfig($scope.communityId, {}) //Empty params so we dont get the references and break the record
        appConfig.appName = $scope.appName
        appConfig.community = CommonUtil.makeBendRef(communityId, 'community')
        await communities.helpers.saveCommunityAppConfig(appConfig)

        const notify = pinesNotifications.notify({
          text: '<p>Saved successfully.</p>',
          type: 'info',
          width: '200px'
        })
        setTimeout(() => {
          notify.remove()
        }, 2000)
      }

      $scope.canSubmitValidationForm = function () {
        return $scope.form.validateForm.$valid
      }

      $scope.openFile = function (fileName) {
        $("input[name='" + fileName + "']").click()
      }

      $scope.deleteFile = function (tag, $ev) {
        $ev.stopPropagation()
        $ev.preventDefault()
        applyChangesOnScope($scope, () => {
          delete $scope.pushConfig[tag]
        })

        $scope.form.validateForm.$setDirty()
      }

      $scope.showFileLoading = function (tag, bShow) {
        $scope.isUploading[tag] = bShow
      }

      $scope.selectFileOpen = function (fileId, $ev) {
        if ($ev.target.tagName == 'DIV') {
          setTimeout(() => {
            $('#' + fileId).click()
          }, 0)
        }
      }

      $scope.onFileUpload = function (files, tag) {
        const file = files[0]

        $scope.fileProgress[tag] = 0
        $scope.showFileLoading(tag, true)
        communities.helpers.upload(file, (error, uploadedFile) => {
          if (error) {
            $scope.showFileLoading(tag, false)
            return
          }

          communities.helpers.getFile(uploadedFile, (o) => {
            $scope.pushConfig[tag] = o
            $scope.showFileLoading(tag, false)
          })
          $scope.form.validateForm.$setDirty()
        }, null, (total, prog) => {
          applyChangesOnScope($scope, () => {
            $scope.fileProgress[tag] = prog * 100 / total
          })
        })
      }
    }])
