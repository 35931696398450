import * as _ from 'lodash'
import * as actions from './actions'
import BendClient, { BendTable } from '../BendClient'
import commonUtil from '../../helpers/commonUtil'
import * as Bend from '../../lib/bend-1.1.8'
import redux from '../../../src/helpers/redux'

class Feature {

  private api: any
  private api2: any

  constructor () {
    this.api = BendClient
    this.api2 = BendTable
  }

  public async reset () {
    redux.dispatch(
      actions.settings.reset()
    )
    await this.selectCommunity(null)
  }

  public async init () {
    const settings = redux.getState().pollQuestions.settings
    const params = {
      relations: {
        community: 'community'
      }
    }
    settings.survey = false
    const pollQuestionsList = await this.api2.getList(BendTable.Tables.POLL_QUESTION, settings, params) 
    redux.dispatch(
      actions.list.update(pollQuestionsList)
    )
    redux.dispatch(
      actions.settings.update(pollQuestionsList.length)
    )
  }

  public paginateToPage (page) {
    redux.dispatch(
      actions.settings.pageUpdate(page)
    )
  }

  public async selectCommunity (id) {
    redux.dispatch(
      actions.settings.selectCommunity(id)
    )
  }

  public async selectTeam (id) {
    let selected = redux.getState().pollQuestions.settings.communityTeams.find(item => item._id === id)
    redux.dispatch(
      actions.settings.selectTeam(selected)
    )
    await this.init()
  }

  public deletePush (id) {
    return this.api.deletePushNotification(id)
  }

  public deleteAnswer (id) {
    return this.api2.delete(BendTable.Tables.POLL_QUESTION_ANSWER, id)
  }

  /* tslint:disable: handle-callback-err */
  // it would be better to delete answers when user clicks on delete, much less confusing
  public updatePollQuestion (data, answerList: Array<any> = [], callback) {
    const async = (window as any).async
    if (Bend.getActiveUser().communityAdmin) {
      const question = _.clone(data)
      question.answerCount = answerList.length
      let responseCount = 0
      _.map(answerList, function (o) {
        responseCount += (o.count || 0)
      })
      question.responseCount = responseCount

      Bend.execute('saveDataForCommunityAdmin', {
        type: 'pollQuestion',
        data: question
      }).then((ret) => {
        // save answers
        answerList.map(answer => {
          delete answer.$$hashKey
          if (responseCount > 0) { answer.percentage = answer.count * 100 / responseCount }
          answer.question = ret
          Bend.execute('saveDataForCommunityAdmin', {
            type: 'pollQuestionAnswer',
            data: answer
          })
        })
      })
    } else { // ignore below, only for master admin which doesn't do this anymore
      const question = _.clone(data)
      question.answerCount = answerList.length

      let responseCount = 0
      _.map(answerList, function (o) {
        responseCount += (o.count || 0)
      })

      question.responseCount = responseCount
      Bend.DataStore.update('pollQuestion', question).then((pollRet) => {
        let answers = $.extend(true, {}, answerList)
        _.map(answers, function (o) {
          delete o.$$hashKey
          if (responseCount > 0) { o.percentage = o.count * 100 / responseCount }
        })

        // get all original answers
        const query = new Bend.Query()
        query.equalTo('question._id', question._id)
        query.notEqualTo('deleted', true)
        Bend.DataStore.find('pollQuestionAnswer', query).then((oldAnswers) => {
          let deletedAnswers: any = []
          _.map(oldAnswers, function (o) {
            let exist = _.find(answers, function (_o) {
              return _o._id === o._id
            })

            if (!exist) {
              // delete
              o.deleted = true
              deletedAnswers.push(o)
            }
          })

          async.parallel([
            (cb) => {
              // save answer
              async.map(answers, (answer, _cb) => {
                Bend.DataStore.save('pollQuestionAnswer', answer).then(function (ret) {
                  _cb(null, ret)
                }, function (err) {
                  _cb(err)
                })
              }, function (err, ret) {
                cb(err, ret)
              })
            },
            (cb) => {
              // delete answer
              async.map(deletedAnswers, (answer, _cb) => {
                Bend.DataStore.save('pollQuestionAnswer', answer).then(function (ret) {
                  _cb(null, ret)
                }, function (err) {
                  _cb(err)
                })
              }, function (err, ret) {
                cb(err, ret)
              })
            }
          ], function (err, ret) {
            callback(pollRet, ret)
          })
        }, function (err) {
          callback(err)
        })
      })
    }
  }

  public createPollQuestion (data, communityList, answerList, callback) {
    const $bend = Bend
    const CommonUtil = commonUtil
    const async = (window as any).async
    if ($bend.getActiveUser().communityAdmin) {
      let newData = _.clone(data)
      newData.community = CommonUtil.makeBendRef($bend.getActiveUser().community._id, 'community')
      newData.communities = [$bend.getActiveUser().community._id] // for backward capability
      newData.answerCount = answerList.length

      $bend.execute('saveDataForCommunityAdmin', {
        type: 'pollQuestion',
        data: newData
      }).then(function (ret) {
        // save answers
        let answers = $.extend(true, {}, answerList)
        _.map(answers, function (o) {
          delete o.$$hashKey
          o.question = CommonUtil.makeBendRef(ret._id, 'pollQuestion')
        })

        async.map(answers, function (answer, _cb) {
          $bend.execute('saveDataOnlyForCommunityAdmin', {
            type: 'pollQuestionAnswer',
            data: answer
          }).then(function (ret) {
            _cb(null, ret)
          }, function (err) {
            _cb(err)
          })
        }, function (err, answerList) {
          callback(ret, answerList)
        })
      }, function (err) {
        callback(err)
      })
    } else {
      async.map(communityList, function (community, cb) {
        let newData = _.clone(data)

        newData.community = CommonUtil.makeBendRef(community._id, 'community')
        newData.communities = [community._id] // for backward capability
        newData.answerCount = answerList.length

        $bend.DataStore.save('pollQuestion', newData).then(function (pollRet) {

          // save answers
          let answers = $.extend(true, {}, answerList)
          _.map(answers, function (o) {
            delete o.$$hashKey
            o.question = CommonUtil.makeBendRef(pollRet._id, 'pollQuestion')
          })

          async.map(answers, function (answer, _cb) {
            $bend.DataStore.save('pollQuestionAnswer', answer).then(function (ret) {
              _cb(null, ret)
            }, function (err) {
              _cb(err)
            })
          }, function (err, ret) {
            callback(pollRet, ret)
            cb(err, ret)
          })
        }, function (err) {
          cb(err)
        })
      }, function (err, ret) {
        callback(err, ret)
      })
    }
  }

  public getCommunityList () {
    return this.api.getCommunityList()
  }

  public get (id) {
    return this.api2.get(BendTable.Tables.POLL_QUESTION, id)
  }

  public delete (id) {
    return this.api2.delete(BendTable.Tables.POLL_QUESTION, id)
  }

  public getAnswerList (id) {
    return this.api.getAnswerListClient(this.api2, id)
    /*return new Promise((resolve, reject) => {
      const params = {
        relations: {
          question: 'pollQuestion'
        }
      }
      const settings = {
        pollQuestionId: id
      }
      this.api2.getList(BendTable.Tables.POLL_QUESTION_ANSWER, settings, params).then(list => {
        resolve(list)
      })
    })*/
  }

  public getResponseList (id) {
    return this.api.getResponseList(id)
  }

  public updateChallenge (data) {
    return this.api.updateChallenge(data)
  }

  public updatePush (data) {
    return this.api.updatePushNotification(data)
  }

  public fetchLinkedPushNotification (settings: { pollQuestionId: string }) {
    return this.api.fetchLinkedPushNotificationByPollQuestion(settings)
  }

}

export default new Feature()
