'use strict'

import commonUtil from '../../../src/helpers/commonUtil'
import * as actions from '../../../src/features/actions'
import * as categories from '../../../src/features/categories'
import bendHelper from '../../../src/helpers/bendHelper'

angular.module('app.controllers')
  .controller('ActionController', ['$scope', '$location', 'BendAuth', '$ngRedux',
    function ($scope, $location, BendAuth, $ngRedux) {
      $scope.communityList = [{
        name: 'All client',
        _id: null
      }]

      const allCats = [{
        name: 'All categories',
        _id: null
      }]

      const mapStateToThis = (state) => ({
        actionList: state.actions.list,
        activities: state.activities.list,
        categories: allCats.concat(state.categories.list),
        collectionView: {
          // itemsPerPage: state.actions.settings.itemsPerPage,
          totalItems: state.actions.settings.totalItems
          // currentPage: state.actions.settings.currentPage,
          // numPages: state.actions.settings.numPages
        },
        filter: {
          searchTerm: state.actions.settings.searchTerm,
          community: state.actions.settings.community,
          category: state.actions.settings.categoryId,
          collection: state.actions.settings.collectionId
        }
      })

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      if ($scope.actionList.length === 0) {
        $scope.isLoading = true
      }

      $scope.deleteAction = async (id, e) => {
        e.preventDefault()
        e.stopPropagation()
        const yeap = confirm('Deleting the action is permanent and can not be undone.')
        if (!yeap) {
          return false
        }
        $scope.isLoading = true
        await actions.helpers.delete(id)
        $scope.loadList()
      }

      $scope.reset = function () {
        $scope.isLoading = true
        actions.helpers.reset()
        $scope.loadList()
      }

      // $(document).on('click', '.pagination li:not(.disabled) a', (e) => {
      //   $scope.isLoading = true
      //   const n = Number($(e.target).text())
      //   actions.helpers.paginateToPage(!isNaN(n) ? n : $scope.collectionView.currentPage + ($(e.target).text() === 'Prev' ? -1 : +1))
      //   $scope.loadList()
      // })

      $scope.sortDirection = {
        'name': 1,
        '_bmd.updatedAt': -1
      }
      $scope.sortKey = '_bmd.updatedAt'

      $scope.user = BendAuth.getActiveUser()

      actions.helpers.getCommunityList((rets) => {
        $scope.communityList = $scope.communityList.concat(rets)
      })

      $scope.categoryGroup = {} // selected category group?
      $scope.collectionList = []
      async.parallel([
        function (cb) {
          categories.helpers.getList()
          actions.helpers.getCollectionEnabledList((rets) => {
            $scope.allcollections = {
              name: 'All Collections',
              _id: null
            }
            $scope.collectionList = [$scope.allcollections].concat(rets)
            cb(null)
          })
        }
      ], (err, rets) => {
        $scope.loadList()
      })

      $scope.loadList = async () => {
        commonUtil.setStorage('action-filter', Object.assign({}, $scope.filter, {
          searchTerm: $scope.collectionView.searchTerm
        }))

        const settings = _.clone($scope.collectionView)
        settings.searchTerm = _.get($scope, 'filter.searchTerm')
        settings.communityId = _.get($scope, 'filter.community._id')
        settings.collectionId = _.get($scope, 'filter.collection._id')
        settings.category = _.get($scope, 'filter.category')
        settings.categoryGroup = $scope.categoryGroup
        settings.sortDirection = $scope.sortDirection
        settings.sortKey = $scope.sortKey

        await actions.helpers.getList(settings)
        $scope.isLoading = false
      }

      $scope.sortBy = function (key) {
        $scope.sortKey = key
        $scope.sortDirection[key] = (-1) * $scope.sortDirection[key]
        actions.helpers.sortBy(key, $scope.sortDirection)
      }

      $scope.onSearch = function () {
        $scope.isLoading = true
        actions.helpers.selectSearchTerm($scope.filter.searchTerm)
        $scope.loadList()
      }

      $scope.selectCategory = (categoryId) => {
        $scope.isLoading = true
        actions.helpers.selectCategory(categoryId, $scope.categories)
        $scope.loadList()
      }

      $scope.selectCollection = (collectionId) => {
        $scope.isLoading = true
        actions.helpers.selectCollection(collectionId, $scope.collectionList)
        $scope.loadList()
      }

      $scope.selectClient = (communityId) => {
        $scope.isLoading = true
        actions.helpers.selectCommunity(communityId, $scope.communityList)
        $scope.loadList()
      }

      // $scope.onPageChange = () => {
      // }

      $scope.enableToggle = async (item, e) => {
        // console.log("enable toggle")
        await bendHelper.enableHelper(e, item, actions)
      }

      $scope.editAction = function (act, $event) {
        if ($event.metaKey || $event.ctrlKey) {
          window.open('#/actions/' + act._id, '_blank')
        } else { return $location.path('/actions/' + act._id) }
      }
      $scope.createAction = function ($event) {
        if ($event.metaKey || $event.ctrlKey) {
          window.open('#/actions/new', '_blank')
        } else { return $location.path('/actions/new') }
      }
    }])
