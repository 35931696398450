import { Action } from '../actions/list'
import {
  UPDATE_CERTIFICATIONS_LIST
} from '../constants/types'

export interface Item {
  $$hashKey: string,
  type: string,
  description: string,
  details: string,
  enabled: true,
  name: string,
  slug: string,
  url: string,
  _acl: {
    creator: string
  },
  _bmd: {
    createdAt: number,
    updatedAt: number,
    _gen: string,
    _rev: number
  }
}

export type Certifications = Array<Item>

const initialState: Certifications = []

export default (state: Certifications = initialState, action: Action): Certifications => {
  switch (action.type) {
    case UPDATE_CERTIFICATIONS_LIST:
      return action.certifications
    default:
      return state
  }
}
