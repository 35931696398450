import * as _ from 'lodash'
import BendClient, { BendTable } from '../features/BendClient'
import { getActiveUser } from '../helpers'

const CSV_SEPARATOR = ',' //Not used, seems that the HTML does not replace the comma
const USER_HEADERS = ['Enabled?', 'User ID', 'Name', 'Email', 'Birthdate', 'Community Admin?', 'Ethnicity', 'Gender', 'Race', 'Zip Code', 'Share Activity?', 'Segments', 'Segments Names', 'Teams', 'Connections', 'Created', 'Updated']
const USER_ORDER = ['enabled', '_id', 'name','email','birthdate','communityAdmin', 'ethnicity', 'gender', 'race', 'zipCode', 'shareActivity', 'segments', 'segmentsNames', 'teams', 'connections', 'createdAt', 'updatedAt']
const SEGMENTS_HEADERS = ['Segment ID', 'Segment Name', 'User Count', 'Segment Type ID', 'Created', 'Updated']
const SEGMENTS_ORDER = ['_id', 'name', 'userCount', 'segmentType', 'createdAt', 'updatedAt']
const ACTIVITY_HEADERS = ['Hidden?', 'Activity ID',  'Categories', 'Activity Type', 'Activity Summary', 'Group',  'Points', 'Volunteer Hours', 'Segments', 'Teams', 'User ID', 'ImageURL','Created', 'Updated']
const ACTIVITY_ORDER = ['hidden', '_id',  'categories', 'type', 'summary', 'group', 'points', 'hours', 'segments',  'teams', 'user', 'media','createdAt', 'updatedAt']
const RESPONSES_HEADERS = ['surveyName', 'teams',  'segments', 'userID', 'userEmail', 'userName',  'questionText', 'questionType', 'answer', 'answerDate']
const RESPONSES_ORDER = ['surveyName', 'teams',  'segments', 'userID', 'userEmail', 'userName',  'questionText', 'questionType', 'answer', 'answerDate']

class Export {

  private api: any
  private api2: any

  constructor () {
    this.api = BendClient
    this.api2 = BendTable
  }

  public exportUserData = async (community: any = null) => {
    try {
      const user = getActiveUser()
      const data = await this.api.getUsers()
      return data
    } catch (error) {
      console.log(error)
      return []
    }
  }

  public exportSegmentsData = async (community: any = null) => {
    try {
      const user = getActiveUser()
      const data = await this.api2.getList(BendTable.Tables.SEGMENT, { communityId: user.community._id })
      return data
    } catch (error) {
      console.log(error)
      return []
    }
  }

  public exportActivitiesData = async (community: any = null) => {
    try {
      const user = getActiveUser()
      const data = await this.api2.getList(BendTable.Tables.ACTIVITY, { communityId: user.community._id }, {relations: {
        media: 'BendFile'}})
      return data
    } catch (error) {
      console.log(error)
      return []
    }
  }

}

export {
  CSV_SEPARATOR,USER_HEADERS, USER_ORDER, SEGMENTS_HEADERS, SEGMENTS_ORDER, ACTIVITY_HEADERS, ACTIVITY_ORDER, RESPONSES_HEADERS, RESPONSES_ORDER
}
export default new Export()
