import {
  RESET_SEGMENTS_SETTINGS,
  UPDATE_SEGMENTS_PAGE_SETTINGS,
  UPDATE_SEGMENTS_SETTINGS,
  UPDATE_SEGMENTS_SETTINGS_COMMUNITY_ID
} from '../constants/types'
import { Settings } from '../reducers/settings'

export interface Action {
  type: string,
  users: Settings,
  count: number,
  page: number,
  communityId: string
}

export const update = (count) => {
  return {
    type: UPDATE_SEGMENTS_SETTINGS,
    count
  }
}

export const pageUpdate = (page) => {
  return {
    type: UPDATE_SEGMENTS_PAGE_SETTINGS,
    page
  }
}

export const updateCommunity = (id) => {
  return {
    type: UPDATE_SEGMENTS_SETTINGS_COMMUNITY_ID,
    communityId: id
  }
}

export const reset = () => {
  return {
    type: RESET_SEGMENTS_SETTINGS
  }
}
