import {
  UPDATE_ORGANIZATIONS_LIST
} from '../constants/types'
import { Organizations } from '../reducers/list'

export interface Action {
  type: string,
  organizations: Organizations
}

export const update = (organizations: Organizations): Action => {
  return {
    type: UPDATE_ORGANIZATIONS_LIST,
    organizations
  }
}
