import {
  UPDATE_EVENT_SETTINGS,
  UPDATE_EVENT_PAGE_SETTINGS,
  RESET_EVENT_SETTINGS,
  UPDATE_EVENT_COMMUNITY_SETTINGS,
  UPDATE_EVENT_CATEGORY_SETTINGS,
  UPDATE_EVENT_COLLECTION_SETTINGS,
  UPDATE_EVENT_KEYWORD_SETTINGS,
  UPDATE_EVENT_SORTING_SETTINGS
} from '../constants/types'
import { Settings } from '../reducers/settings'

export interface Action {
  type: string,
  events: Settings,
  count: number,
  page: number,
  category: object,
  collection: object,
  communityId: string,
  keyword: string,
  sortKey: string,
  sortDirection: object
}

export const update = (count) => {
  return {
    type: UPDATE_EVENT_SETTINGS,
    count
  }
}

export const communityUpdate = (communityId) => {
  return {
    type: UPDATE_EVENT_COMMUNITY_SETTINGS,
    communityId
  }
}

export const categoryUpdate = (category) => {
  return {
    type: UPDATE_EVENT_CATEGORY_SETTINGS,
    category
  }
}

export const searchKeywordUpdate = (keyword) => {
  return {
    type: UPDATE_EVENT_KEYWORD_SETTINGS,
    keyword
  }
}

export const collectionUpdate = (collection) => {
  return {
    type: UPDATE_EVENT_COLLECTION_SETTINGS,
    collection
  }
}

export const pageUpdate = (page) => {
  return {
    type: UPDATE_EVENT_PAGE_SETTINGS,
    page
  }
}

export const sortList = (sortKey, sortDirection) => {
  return {
    type: UPDATE_EVENT_SORTING_SETTINGS,
    sortKey,
    sortDirection
  }
}

export const reset = () => {
  return {
    type: RESET_EVENT_SETTINGS
  }
}
