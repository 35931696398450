import * as _ from 'lodash'

import {
  UPDATE_ACTIVITIES_SETTINGS,
  UPDATE_ACTIVITIES_PAGE_SETTINGS,
  UPDATE_ACTIVITIES_TEAM_SETTINGS,
  UPDATE_ACTIVITIES_TEAMS_SETTINGS,
  UPDATE_ACTIVITIES_ITEMS_PER_PAGE,
  RESET_ACTIVITIES_SETTINGS
} from '../constants/types'

import { Action } from '../actions/settings'

const defaultTeam = {
  name: 'All Teams',
  _id: null
}

export interface Settings {
  searchTerm: any,
  itemsPerPage: number,
  isLoading: boolean,
  totalItems: number,
  currentPage: number,
  numPages: number,
  communityId: string,
  teamId: string,
  teams: any
}

const initialState: Settings = {
  searchTerm: '',
  itemsPerPage: 50,
  isLoading: true,
  totalItems: 0,
  currentPage: 1,
  numPages: 0,
  communityId: '',
  teamId: '',
  teams: []
}

export default (state: Settings = initialState, action: Action): Settings => {
  switch (action.type) {
    case UPDATE_ACTIVITIES_SETTINGS:
      return {
        ...state,
        totalItems: action.count,
        numPages: Math.ceil(action.count / state.itemsPerPage + 1)
      }
    case UPDATE_ACTIVITIES_PAGE_SETTINGS:
      return {
        ...state,
        currentPage: action.page
      }
    case UPDATE_ACTIVITIES_ITEMS_PER_PAGE:
      return {
        ...state,
        itemsPerPage: action.itemsPerPage
      }
    case UPDATE_ACTIVITIES_TEAM_SETTINGS:
      return {
        ...state,
        teamId: action.teamId
      }
    case UPDATE_ACTIVITIES_TEAMS_SETTINGS:
      const sortedList = _.orderBy(action.teams, (i: any) => i.name.toLowerCase(), ['asc'])
      const teams = [
        defaultTeam,
        ...sortedList
      ]
      return {
        ...state,
        teams
      }
    case RESET_ACTIVITIES_SETTINGS:
      return initialState
    default:
      return state
  }
}
