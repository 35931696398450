import BendClient from '../BendClient'

class Feature {

  private api: any

  constructor () {
    this.api = BendClient
  }

  public getUncodedBusinessCount () {
    return this.api.getUtilityUncodedBusinessCount()
  }

  public getBusinesses () {
    return this.api.getUtilityBusinesses()
  }

  public updateBusiness (data) {
    return this.api.updateBusiness(data)
  }
}

export default new Feature()
