import * as _ from 'lodash'
import * as actions from './actions'
import { List } from './reducers/list'
import BendClient, { BendTable } from '../BendClient'
import redux from '../../../src/helpers/redux'

const defaultParams = {
  relations: {
    welcomeBackground1: 'BendFile',
    welcomeBackground2: 'BendFile',
    welcomeBackground3: 'BendFile',
    welcomeBackground4: 'BendFile',
    welcomeBackground5: 'BendFile',
    welcomeBackground6: 'BendFile',
    iosAppIcon: 'BendFile',
    iosScreenshotiPhoneSixSPlus1: 'BendFile',
    iosScreenshotiPhoneSixSPlus2: 'BendFile',
    iosScreenshotiPhoneSixSPlus3: 'BendFile',
    iosScreenshotiPhoneSixSPlus4: 'BendFile',
    iosScreenshotiPhoneSixSPlus5: 'BendFile',
    iosScreenshotiPadPro1: 'BendFile',
    iosScreenshotiPadPro2: 'BendFile',
    iosScreenshotiPadPro3: 'BendFile',
    iosScreenshotiPadPro4: 'BendFile',
    iosScreenshotiPadPro5: 'BendFile',
    iosScreenshotiPhoneXSMax1: 'BendFile',
    iosScreenshotiPhoneXSMax2: 'BendFile',
    iosScreenshotiPhoneXSMax3: 'BendFile',
    iosScreenshotiPhoneXSMax4: 'BendFile',
    iosScreenshotiPhoneXSMax5: 'BendFile',
    androidAppIcon: 'BendFile',
    androidScreenshot1: 'BendFile',
    androidScreenshot2: 'BendFile',
    androidScreenshot3: 'BendFile',
    androidScreenshot4: 'BendFile',
    androidScreenshot5: 'BendFile',
    androidCoverImage: 'BendFile'
  }
}

class Community {

  private api: any
  private api2: any

  constructor () {
    this.api = BendClient
    this.api2 = BendTable
  }

  async init (allOption: boolean = false) {
    let response: any[] = await this.api.fetchCommunityList()
    if (allOption) {
      const allClients = {
        name: 'All clients',
        id: null
      }
      response = [
        allClients,
        ...response
      ]
    }
    redux.dispatch(
      actions.list.update(response)
    )
    return response
  }

  async initBoard () {
    const response = await this.api.fetchCommunityList()
    redux.dispatch(
      actions.boardList.update(response)
    )
  }

  async initEnabled () {

    const response: List = await this.api.fetchEnabledCommunityList()

    redux.dispatch(
      actions.list.update(response)
    )
  }

  public getCollectionEnabledList () {
    return this.api.getCollectionEnabled()
  }

  public getCategoryEnabledList () {
    return this.api.getCategoryEnabledList()
  }

  public getGlobalCategoryEnabledList () {
    return this.api.getGlobalCategoryEnabledList()
  }

  public getTaskList (communityId) {
    return this.api.getTaskList(communityId)
  }

  public upload (file, callback, ext, progressCallback) {
    return this.api.upload(file, callback, ext, progressCallback)
  }

  public getFile (refObj, callback) {
    return this.api.getFile(refObj, callback)
  }

  public async getCommunityPushConfig (communityId) {
    const params = {
      relations: {
        appleCertificate: 'BendFile',
        community: 'community'
      }
    }
    const response = await this.api2.getList(BendTable.Tables.PUSH_CONFIG, { communityId }, params)
    return response[0]
  }

  public async getCommunityAppConfig (communityId, params = defaultParams) {
    const response = await this.api2.getList(BendTable.Tables.CLIENT_APP_CONFIG, { communityId }, params)
    return response[0]
  }

  public saveCommunityPushConfig (data) {
    return this.api2.create(BendTable.Tables.PUSH_CONFIG, data)
  }

  public getAllWhiteListed () {
    return this.api2.get(BendTable.Tables.WHITELABEL)
  }

  public async getCurrentWhitelabel (communityId) {
    const whitelabels = await this.getAllWhiteListed()
    return this.getWhitelabelWithCommunityId(whitelabels, communityId)
  }

  public saveWhitelabel (data) {
    return this.api2.create(BendTable.Tables.WHITELABEL, data)
  }

  public updateWhitelabel (data) {
    return this.api2.update(BendTable.Tables.WHITELABEL, data)
  }

  public saveAwsConfig (data) {
    const saveAwsConfig = "saveAwsConfig"; 
    return this.api2.callEndpoint(saveAwsConfig, data); 
  }

  public saveCommunityAppConfig (data) {
    return this.api2.create(BendTable.Tables.CLIENT_APP_CONFIG, data)
  }

  public fetchWhiteListedEmails (communityId) {
    return this.api.fetchWhiteListedEmails(communityId)
  }

  public addWhiteListedEmails (data) {
    return this.api.addWhiteListedEmails(data)
  }

  public deleteWhiteListedEmail (data) {
    return this.api.upadteWhiteListedEmail(data)
  }

  public async fetchByStatus (status) {
    const response: List = await this.api.getCommunitiesByStatus(status)
    redux.dispatch(
      actions.list.updatePageList(response)
    )
  }

  public get (communityId) {
    const related = {
      relations: {
        logo: 'BendFile'
      }
    }
    let community = this.api2.get(BendTable.Tables.COMMUNITY, communityId, related)
    return community
    // return this.api2.get(BendTable.Tables.COMMUNITY, communityId, related)
  }

  public create (data) {
    return this.api2.create(BendTable.Tables.COMMUNITY, data)
  }

  public update (data) {
    return this.api2.update(BendTable.Tables.COMMUNITY, data)
  }

  public delete (id) {
    return this.api2.delete(BendTable.Tables.COMMUNITY, id)
  }

  private getWhitelabelWithCommunityId (object, id) {
    let parentObject = null
    if (object.hasOwnProperty('community')) {
      parentObject = object
      object = object.community
    }

    if (object.hasOwnProperty('_collection') && object['_collection'] == 'community' &&
      object.hasOwnProperty('_id') && object['_id'] == id)
      return parentObject

    for (let i = 0; i < Object.keys(object).length; i++) {
      if (typeof object[Object.keys(object)[i]] == 'object') {
        let o = this.getWhitelabelWithCommunityId(object[Object.keys(object)[i]], id)
        if (o != null) return o
      }
    }

    return null
  }

}

export default new Community()
