import { combineReducers } from 'redux'
import list, { Teams } from './list'
import settings from './settings'

export interface CombineReducers {
  list: Teams
}

const rootReducer = combineReducers<CombineReducers>({
  list,
  settings
})

export default rootReducer
