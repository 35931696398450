import { UPDATE_LEADERBOARDS_USERS_IDS_LIST } from '../constants/types'
import { List } from '../reducers/usersPoints'

export interface Action {
  type: string,
  usersIds: List
}

export const update = (usersIds: List): Action => {
  return {
    type: UPDATE_LEADERBOARDS_USERS_IDS_LIST,
    usersIds
  }
}
