import BendClient, { BendTable } from '../BendClient'
import * as actions from './actions'
import redux from '../../../src/helpers/redux'
import * as _ from 'lodash'
import commonUtil from '../../helpers/commonUtil'

class Feature {

  private api: any
  private api2: any

  constructor () {
    this.api = BendClient
    this.api2 = BendTable
  }

  public getCommunityList (callback) {
    this.api.getCommunityList().then((response) => {
      callback(response)
    })
  }

  // public getCategoryList (callback) {
  //   this.api.getCategoryList().then((response) => {
  //     callback(response)
  //   })
  // }

  public getCollectionEnabledList (callback) {
    this.api.getCollectionEnabled().then((response) => {
      callback(response)
    })
  }

  public getCategoryGroupList (communityId) {
    return this.api.getCategoryGroupList(communityId)
  }

  public get (id) {
    return this.api2.get(BendTable.Tables.ACTION, id)
  }

  public create (data) {
    return this.api2.create(BendTable.Tables.ACTION, data)
  }

  public update (data) {
    return this.api2.update(BendTable.Tables.ACTION, data)
  }

  public delete (id) {
    return this.api2.delete(BendTable.Tables.ACTION, id)
  }

  public upload (file, callback, ext, progressCallback) {
    return this.api.upload(file, callback, ext, progressCallback)
  }

  public getFile (refObj, callback) {
    return this.api.getFile(refObj, callback)
  }

  public async getList (settings) {
    const actionsList = await this.api2.getList('action', settings)
    redux.dispatch(
      actions.list.update(actionsList)
    )
    redux.dispatch(
      actions.settings.update(actionsList.length)
    )
  }

  public sortBy (key, direction) {
    let list = redux.getState().actions.list
    let sortedList = commonUtil.sortBy(list, key, direction)
    redux.dispatch(
      actions.list.update(sortedList)
    )
  }

  public paginateToPage (page) {
    redux.dispatch(
      actions.settings.pageUpdate(page)
    )
  }

  public reset () {
    redux.dispatch(
      actions.settings.reset()
    )
  }

  public async selectSearchTerm (term) {
    redux.dispatch(
      actions.settings.selectSearchTerm(term)
    )
  }

  public async selectCommunity (id: string, communityList: any[]) {
    const selected = communityList.find(item => item._id === id)
    redux.dispatch(
      actions.settings.selectCommunity(selected)
    )
  }

  public async selectCollection (id: string, collections: any[]) {
    const selected = collections.find(item => item._id === id)
    redux.dispatch(
      actions.settings.selectCollection(selected)
    )
  }

  public async selectCategory (id: string, categoriesList: any[]) {
    const selected = categoriesList.find(item => item._id === id)
    redux.dispatch(
      actions.settings.selectCategory(selected)
    )
  }
}

export default new Feature()
