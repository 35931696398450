import {
  UPDATE_POLL_QUESTIONS_LIST
} from '../constants/types'
import { PollQuestions } from '../reducers/list'

export interface Action {
  type: string,
  pollQuestions: PollQuestions
}

export const update = (pollQuestions: PollQuestions): Action => {
  return {
    type: UPDATE_POLL_QUESTIONS_LIST,
    pollQuestions
  }
}
