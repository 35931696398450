import {
  UPDATE_COLLECTIONS_LIST
} from '../constants/types'
import { Collections } from '../reducers/list'

export interface Action {
  type: string,
  collections: Collections
}

export const update = (collections: Collections): Action => {
  return {
    type: UPDATE_COLLECTIONS_LIST,
    collections
  }
}
