import {
  UPDATE_TASKS_LIST,
  ADD_TASK,
  CANCEL_TASK_EDIT,
  SAVE_EDITED_TASK,
  EDIT_TASK,
  MARK_TASK_COMPLETE,
  MARK_TASK_INCOMPLETE,
  REMOVE_INCOMPLETE_TASK
} from '../constants/types'
import { Action } from '../actions/list'

export interface Item {
  _id: string,
  _acl: {
    creator: string
  },
  _bmd: {
    createdAt: number,
    _rev: number,
    updatedAt: number,
    _gen: string
  },
  completed: boolean,
  title: string,
  community: {
    _type: string,
    _id: string,
    _collection: string
  },
  label: string,
  $$hashKey: string
}

export type List = Item[]

export type stateLists = {
  incomplete: List,
  complete: List
}

const initialState: stateLists = {
  incomplete: [],
  complete: []
}
export default (state: stateLists = initialState, action: Action): stateLists => {
  switch (action.type) {
    case UPDATE_TASKS_LIST:
      return {
        ...state,
        incomplete: action.incomplete,
        complete: action.complete
      }
    case ADD_TASK:
      return {
        ...state,
        incomplete: [
          ...state.incomplete,
          action.newTask
        ]
      }
    case EDIT_TASK:
      return {
        ...state,
        incomplete: (state.incomplete as any).map((item) => {
          if (item._id === action.id) {
            return {
              ...item,
              editing: true
            }
          }

          return item
        })
      }
    case SAVE_EDITED_TASK:
      return {
        ...state,
        incomplete: (state.incomplete as any).map((item, index) => {
          if (item._id === action.id) {
            return {
              ...item,
              title: action.title,
              editing: false
            }
          }

          return item
        })
      }
    case CANCEL_TASK_EDIT:
      return {
        ...state,
        incomplete: (state.incomplete as any).map((item, index) => {
          if (item._id === action.id) {
            return {
              ...item,
              title: action.title,
              editing: false
            }
          }

          return item
        })
      }
    case REMOVE_INCOMPLETE_TASK:
      return {
        ...state,
        incomplete: (state.incomplete as any).filter((item, index) => item._id !== action.id)
      }
    case MARK_TASK_COMPLETE:
      return {
        ...state,
        incomplete: (state.incomplete as any).filter((item, index) => item._id !== action.id),
        complete: [
          ...state.complete,
          ...(state.incomplete as any).filter((item, index) => item._id === action.id)
            .map(item => ({
              ...item,
              completed: true
            }))
        ]
      }
    case MARK_TASK_INCOMPLETE:
      return {
        ...state,
        complete: state.complete.filter((item, index) => index !== action.index),
        incomplete: [
          ...state.incomplete,
          state.complete[action.index]
        ]
      }
    default:
      return state
  }
}
