import * as _ from 'lodash'

const activityTypes = {
    'action': 'Actions',
    'volunteer_opportunity': 'Volunteering',
    'business': 'Places Check-In',
    'event': 'Events',
    'event-registration':'Event Registration',
    'event-checkin':'Event Check-In',
    'poll':'Daily Polls',
    'comment':'Comments',
    'survey':'Survey',
    'post': 'Post',
    'customActivity': 'Custom Activity'
}

class GenericHelper {

    formatActivityType(type) {
      //console.log(type)
      if (activityTypes.hasOwnProperty(type)) {
        //console.log(activityTypes[type])
        return activityTypes[type]
      } else {
        console.log('ERR activity type not defined', type)
        return type
      }

    }
}
export default new GenericHelper()