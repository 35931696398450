import * as _ from 'lodash'
import BendClient, { BendTable } from '../BendClient'
import commonUtil from '../../helpers/commonUtil'
import * as Bend from '../../lib/bend-1.1.8'
import redux from '../../../src/helpers/redux'
import { getActiveUser } from '../../helpers'
import async from 'async'

import * as comments from '../comments'
import * as activities from '../activities'

class Feature {

  private api: any
  private api2: any

  constructor () {
    this.api = BendClient
    this.api2 = BendTable
  }

  public async initPostsAndComments () {
    return this.api2.callEndpoint('moderation_getPostsAndComments')
  }

  public async initConversations () {
    return this.api2.callEndpoint('moderation_getConversationsAndUsers')
  }

  public async deleteCommentOrPost (id, txt) {
    let postId = null
    let commentId = null
    if (txt === 'post') postId = id
    if (txt === 'comment') commentId = id
    let params = {
      postId,
      commentId
    }
    this.api2.callEndpoint('feed_removeItem', params)
    return true
  }
}

export default new Feature()
