'use strict'

import * as pollQuestions from '../../../src/features/pollQuestions'
import * as teams from '../../../src/features/teams'
import * as segments from '../../../src/features/segments'
import commonUtil from '../../../src/helpers/commonUtil';


angular.module('app.controllers')
  .controller('PollQuestionEditController', ['$scope', '$location', '$routeParams', 'BendAuth', '$rootScope', '$modal', '$ngRedux',
    function ($scope, $location, $routeParams, BendAuth, $rootScope, $modal, $ngRedux) {
      // Init.
      $scope.commonUtil = commonUtil
      $scope.user = BendAuth.getActiveUser()
      $scope.isEditMode = false
      if ($routeParams.id != 'new') {
        $scope.pollQuestionId = $routeParams.id
        $scope.isEditMode = true
      }

      const mapStateToThis = (state) => {
        return {
          teams: state.teams.list.teams,
          segments: state.segments.list
        }
      }

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      $scope.communityList = []
      $scope.answerList = []
      $scope.responseList = []
      $scope.userList = []
      $scope.isAnswer = true
      $scope.pollQuestion = {
        answerCount: 0,
        responseCount: 0,
        points: 10
      }
      $scope.questionList = []
      $scope.isShowType = 0

      $scope.formData = {
        communities: [],
        openedDateSelector: false,
        teams: []
      }

      $scope.form = {}

      $scope.teams = []

      var oldAnswersPosList = []

      teams.helpers.getList()
      segments.helpers.getList()

      $scope.getSegmentInfo = (segment) => {
        return segments.helpers.getSegmentInfo(segment)
      }

      // setting up formData for user's community
      if ($scope.user.communityAdmin) {
        $scope.formData.communities.push($scope.user.community)
      }

      // OLD COMMENT: only call if !communityAdmin? (applicable to below)
        // OLD COMMENT: needs minor refactoring to do that (applicable to below)
       // Runs several functions that: 
        // (1) gets answers list -> talks to pollQuestionAnswer (contains the answer choices)
        // (2) gets response lists -> talks to pollQuestionResponse (contains answer, question, and user IDs)
      pollQuestions.helpers.getCommunityList().then(function (rets) {
        $scope.communityList = rets
        if ($scope.isEditMode) {
          async.parallel([
            function (cb) {
              $scope.linkedPushToggle = false
              pollQuestions.helpers.get($scope.pollQuestionId).then(function (ret) {

                $scope.pollQuestion = ret
                $scope.oldPoll = _.clone(ret)

                if ($scope.pollQuestion.communities && $scope.pollQuestion.communities.length > 0) {
                  $scope.formData.communities = _.filter($scope.communityList, function (o) {
                    return $scope.pollQuestion.communities.indexOf(o._id) != -1
                  })
                }
                if ($scope.pollQuestion.survey && $scope.pollQuestion.survey.length > 0) {
                  $scope.formData.survey = $scope.pollQuestion.survey
                }
                if ($scope.pollQuestion.pollDate) {
                  $scope.formData.pollDate = $scope.pollQuestion.pollDate
                }

                if ($scope.pollQuestion.teams) {
                  $scope.formData.teams = _.filter($scope.teams, function (o) {
                    return $scope.pollQuestion.teams.indexOf(o._id) != -1
                  })
                }

                if ($scope.pollQuestion.segments) {
                  $scope.formData.segments = _.filter($scope.segments, function (o) {
                    return $scope.pollQuestion.segments.indexOf(o._id) != -1
                  })
                }


                // update value for the toggle in the ui
                // find push associated with this poll, save it
                // if (ret.push && ret.push._id) {
                //   const settings = {
                //     pollQuestionId: _.get(ret, '_id')
                //   }
                //   pollQuestions.helpers.fetchLinkedPushNotification(settings).then(function (pushArr) {
                //     if (pushArr.length > 0) {
                //       $scope.linkedPushToggle = true
                //     }
                //   }, function (err) { })
                // } else { $scope.linkedPushToggle = false }
                cb(null, null)
              })
            },
            async (cb) => {
              const response = await pollQuestions.helpers.getAnswerList($scope.pollQuestionId)
              applyChangesOnScope($scope, () => {
                $scope.answerList = response
                cb(null, null)
              })
            },
            async (cb) => {
              const response = await pollQuestions.helpers.getResponseList($scope.pollQuestionId)
              applyChangesOnScope($scope, () => {
                if ($scope.pollQuestionId) {
                  $scope.responseList = response
                }
                cb(null, null)
              })
            }
          ], function (err, ret) {
            $scope.enableSortableAnswers()

            const chartData = []
            _.map($scope.answerList, function (o) {
              chartData.push({
                label: o.title,
                data: $scope.getCount(o)
              })
            })

            

            
            $.plot('#response-pie', chartData, {
              series: {
                  pie: {
                    show: true, 

                     
                  }
              },
              legend: {
               show: true,
               position: 'right',
              },
              
            })
            //ends the plot

          })
        }
      })

      $scope.enableSortableAnswers = function () {
        $('#answers-container').sortable({
          opacity: 0.5,
          dropOnEmpty: true,
          start: function (event, ui) {
            ui.item[0].style.backgroundColor = '#eee'
            oldAnswersPosList = []
            $scope.answerList.map(function (o) {
              oldAnswersPosList[o._id] = o.position
            })
          },
          update: function (event, ui) {
            $('.answer-content').each(function (idx) {
              var answerKey = $(this).find('#answerId-input')[0].value
              var answer = _.find($scope.answerList, function (_o) {
                return _o.$$hashKey == answerKey
              })
              answer.position = idx + 1
            })

            applyChangesOnScope($scope, function () {
              $scope.answerList = _.sortBy($scope.answerList, function (o) {
                return o.position
              })
            })
            ui.item[0].style.backgroundColor = ''
          }
        })
        $('#answers-container').sortable('option', 'disabled', false)
      }
      $scope.disableSortableAnswers = function () {
        $('#answers-container').sortable('disable')
      }

      $scope.editPollQuestionAnswer = function (answer) {
        $scope.pollAnswer = answer
        $scope.viewAnswer = true
      }

      $scope.getCount = function (answer) {
        var count = 0
        _.map(answer.counts || [], function (o) {
          count += o
        })

        return count
      }

      $scope.deletePollQuestion = function (id, e) {
        e.preventDefault()
        e.stopPropagation()
        const yeap = confirm('Deleting the question is permanent and can not be undone.')
        if (!yeap) {
          return false
        }
        pollQuestions.helpers.delete(id)
      }

      $scope.deleteAnswer = async (item, $ev) => {
        $ev.stopPropagation()
        $ev.preventDefault()
        $scope.answerList = _.difference($scope.answerList, item)
        $scope.answerList.map(function (o, idx) {
          o.position = idx + 1
        })

        const itemIndex = $scope.answerList.indexOf(item)
        const remainingAnswers = $scope.answerList.filter((item, index) => index !== itemIndex)

        if (!item._id) {
          $scope.answerList = remainingAnswers
          return
        }

        await pollQuestions.helpers.deleteAnswer(item._id)
        applyChangesOnScope($scope, () => {
          $scope.answerList = remainingAnswers
        })
      }

      $rootScope.enableSort = function () {
        $scope.enableSortableAnswers()
      }

      $scope.openDateWindow = function ($event) {
        $event.preventDefault()
        $event.stopPropagation()

        $scope.formData.openedDateSelector = true
      }

      $scope.viewAnswer = false

      var answerObj = {
        count: 0,
        percentage: 0,
        position: $scope.answerList.length + 1,
        question: $scope.pollQuestion
      }

      $scope.openPollQuestionAnswer = () => {
        $scope.pollAnswer = _.clone(answerObj)
        $scope.viewAnswer = true
      }

      $scope.close = function () {
        $scope.viewAnswer = false
      }

      // OLD COMMENT: can't save immediately because if its a new question, the question id won't exist yet
      $scope.saveAnswerDo = function (another) {
        $scope.answerList.push($scope.pollAnswer)
        if (another) {
          $scope.viewAnswer = true
        }
        $scope.viewAnswer = false
        $rootScope.enableSort()
      }

      // OLD COMMENT: form validation using angular ng-class
      $scope.validateForm = () => {
        const required = 'required'
        const question = _.get($scope.pollQuestion, 'question')
        const points = _.get($scope.pollQuestion, 'points')
        const answersExist = _.get($scope, 'answerList').length
        if (question === '' || !question) $scope.questionReq = required
        if (points === '' || !points) $scope.pointsReq = required
        if (answersExist === 0 || !answersExist) $scope.answersReq = required
        if (
          !question ||
          !points ||
          !answersExist === 0 ||
          !answersExist
        ) {
          $scope.errorMsg = true
          return false
        }
        return true
      }


      // Creates new Poll Question or Saves New Poll Question 
        // Functions: 
          // (1) updatePollQuestion
          // (2) createPollQuestion
      $scope.savePollQuestionDo = function () {
        if (!$scope.validateForm()) return
        var pollQData = _.clone($scope.pollQuestion)
        if (pollQData.community && pollQData.community._id != '') { // if community exists
          pollQData.community = commonUtil.makeBendRef(pollQData.community._id, 'community')
        } else {
          delete pollQData.community
        }

        if (typeof $scope.formData.pollDate === 'object') { // if date exists
          if ($scope.formData.pollDate) {
            pollQData.pollDate = commonUtil.formatDateWithFormat($scope.formData.pollDate.getTime() * 1000000, 'YYYY-MM-DD')
          } else { delete pollQData.pollDate }
        }

        if ($scope.formData.teams &&
          $scope.formData.teams.length > 0 &&
          $scope.formData.teams.indexOf({ _id: null, name: 'All Users' }) === -1 // if all users is not selected
        ) {
          pollQData.teams = commonUtil.getIdList($scope.formData.teams)
        } else {
          delete pollQData.teams
        }

        if ($scope.formData.segments && $scope.formData.segments.length) {
          pollQData.segments = commonUtil.getIdList($scope.formData.segments)
        }

        if ($scope.formData.survey) {
          pollQData.survey = $scope.formData.survey
        }

        if ($scope.pollQuestionId) { // if existing, update
          $scope.isSaving = true
          pollQuestions.helpers.updatePollQuestion(pollQData, $scope.answerList, function (pollQRet, savedAnswers) {
            $scope.isSaving = false
            // if ($scope.shouldUpdateAutoPush($scope.oldPoll, pollQRet, 'pollQuestion', $scope.linkedPushToggle)) {
            //   $scope.autoPush(pollQRet, 'pollQuestion', savedAnswers)
            // }
          })
        } else { // create question
          $scope.isSaving = true
          let community
          if ($scope.user.communityAdmin) {
            community = [$scope.user.community]
          } else {
            community = $scope.formData.communities
          }
          pollQuestions.helpers.createPollQuestion(pollQData, community, $scope.answerList, function (pollQRet, savedAnswers) {
            $scope.isSaving = false
            // if ($scope.shouldUpdateAutoPush($scope.oldPoll, pollQRet, 'pollQuestion', $scope.linkedPushToggle)) {
            //   $scope.autoPush(pollQRet, 'pollQuestion', savedAnswers)
            // }
          })
        }

        $location.path('/dailyPolls')
      }
    }])

  // OLD CODE TO RUN MODAL
  // $scope.openPollQuestionAnswer = function (answerObj) {
  //   $modal.open({
  //     templateUrl: 'editAnswer.html',
  //     backdrop: 'static',
  //     controller: 'PollQuestionAnswerController', ['$scope', '$modalInstance', 'answerObj', 'answerList',
  //     // controller: 'PollQuestionAnswerController', ['$scope', '$modalInstance', 'answerObj', 'answerList',
  //       function ($scope, $modalInstance, answerObj, answerList) {
  //         // this is only for editing poll question answers and it doesn't do anything
  //         $scope.canSubmitValidationForm = function () {
  //           return $scope.form.validateForm2.$valid
  //         }
  //       }
  //     ],
  //     resolve: {
  //       answerObj: ['$scope', function ($scope) {
  //         return answerObj
  //       }],
  //       answerList: ['$scope', function ($scope) {
  //         return $scope.answerList
  //       }]
  //     }
  //   })
  // }

  // $scope.toggleAutoPush = () => {
  //   $scope.linkedPushToggle = !$scope.linkedPushToggle
  // }

  // /*
  // * update if any values have changed
  // * delete push if requested
  // */
  // $scope.shouldUpdateAutoPush = (oldData = {}, newData, type, pushToggle) => {
  //   // delete associated push if link toggled off
  //   if (!pushToggle && typeof oldData.push === 'object') {
  //     pollQuestions.helpers.deletePush(oldData.push._id)
  //     newData.push = null
  //     if (type === 'challenge') {
  //       pollQuestions.helpers.updateChallenge(newData)
  //     }
  //     if (type === 'pollQuestion') {
  //       pollQuestions.helpers.updatePollQuestion(newData, $scope.answerList, () => {
  //       })
  //     }
  //     return
  //   }

  //   // generic checks
  //   let oldTeams = 0
  //   let newTeams = 0
  //   if (oldData && oldData.teams) oldTeams = _.get(oldData, 'teams', [])
  //   if (newData && newData.teams) newTeams = _.get(newData, 'teams', [])
  //   if (pushToggle) {
  //     if (
  //       (oldData && !oldData.push) || // if push not already associated
  //       !_.isEqual(oldTeams, newTeams) // teams arrays aren't equal
  //     ) {
  //       return true
  //     }
  //     // type specific check
  //     const oldDetails = _.get(oldData, 'details', '')
  //     const newDetails = _.get(newData, 'details', '')
  //     const oldDate = _.get(oldData, 'pollDate', '')
  //     if (
  //       type === 'challenge' &&
  //       // any of below conditions
  //       (oldData.title !== newData.title ||
  //         oldData.startsAt !== newData.startsAt ||
  //         oldData.endsAt !== newData.endsAt)
  //     ) {
  //       return true
  //     } else if (
  //       type === 'pollQuestion' &&
  //       // any of below conditions
  //       (oldDate !== newData.pollDate ||
  //       oldData.question !== newData.question ||
  //       oldDetails !== newDetails || // not currently using the details in the push
  //       oldData.text !== newData.text)
  //     ) {
  //       return true
  //     }
  //   } else {
  //     return false
  //   }
  // }

/*
* send push notification at noon on day that poll question goes live
*/
  // $scope.autoPush = (data, type, savedAnswers) => {
  //   let push = {}

  //   // default settings, user doesn't choose these
  //   if (type === 'pollQuestion') {
  //     push.title = 'Today\'s Poll'
  //   }
  //   push.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone // i think below will work but we can also use Date().getTimezoneOffset() to calculate time for the push to be sent
  //   push.scheduled = true
  //   push.status = 'pending'
  //   /// build reference to poll question into the push
  //   push[type] = {
  //     _type: 'BendRef',
  //     _id: data._id,
  //     _collection: type
  //   }
  //   /// other default settings
  //   push.userQuery = '{}'
  //   push.params = [{
  //     key: 'deeplink',
  //     value: 'milkcrate://home'
  //   }]
  //   push.group = $scope.user.community._id
  //   /// prep with default audience object
  //   push.audience = {
  //     defaultQuery: {
  //       'community._id': $scope.user.community._id
  //     },
  //     audiences: []
  //   }

  //   // custom settings, user chooses these
  //   if (data.teams) { // checks happen when team id array is built on save
  //     push.audience.audiences = data.teams
  //   }
  //   if (data.pollDate) {
  //     let dateArr = data.pollDate.split('-')
  //     let year = Number(dateArr[0])
  //     let month = Number(dateArr[1]) - 1 // compensate for js months starting at 0
  //     let day = Number(dateArr[2])
  //     let time = new Date(year, month, day, 9) // set time to 9AM on day of poll
  //     push.datetime = time.getTime() * 1000000
  //     // if push.datetime is in the past, send immediately or next day at noon? this is an edge case, most content will be created before it goes live
  //   }
  //   push.text = data.question

  //   // save or update the push
  //   pollQuestions.helpers.updatePush(push).then(function (result) {
  //     let savedData = result
  //     data.push = { // build reference to push
  //       _type: 'BendRef',
  //       _id: savedData._id,
  //       _collection: 'push'
  //     }
  //     // add push link to the poll question
  //     if (type === 'pollQuestion') {
  //       debugger
  //       pollQuestions.helpers.updatePollQuestion(data, savedAnswers, () => {
  //       })
  //     }
  //   })
  // }
