import { Action } from '../actions/list'
import {
  UPDATE_SEGMENT_TYPES_LIST
} from '../constants/types'

export interface Item {
  any
}

export type SegmentTypes = Array<any>

const initialState: SegmentTypes = []

export default (state: SegmentTypes = initialState, action: Action | any): SegmentTypes => {
  switch (action.type) {
    case UPDATE_SEGMENT_TYPES_LIST:
      return action.segmentTypes
    default:
      return state
  }
}
