import * as _ from 'lodash'
import BendClient, { BendTable } from '../BendClient'
import * as actions from './actions'
import redux from '../../../src/helpers/redux'
import commonUtil from '../../../src/helpers/commonUtil'

import { Settings } from '../../types'

class Feature {

  private api: any
  private api2: any

  constructor () {
    this.api = BendClient
    this.api2 = BendTable
  }

  public getCollectionEnabled () {
    return this.api.getCollectionEnabled()
  }

  public getCommunityList () {
    return this.api.getCommunityList()
  }

  // public getCategory () {
  //   return this.api.getCategoryList()
  // }

  public getCategoryGroupList (communityId) {
    return this.api.getCategoryGroupList(communityId)
  }

  public upload (file, callback, ext, progressCallback) {
    return this.api.upload(file, callback, ext, progressCallback)
  }

  public getFile (refObj, callback) {
    return this.api.getFile(refObj, callback)
  }

  public get (id) {
    return this.api2.get(BendTable.Tables.CUSTOM_ACTIVITY, id)
  }

  public create (data) {
    return this.api2.create(BendTable.Tables.CUSTOM_ACTIVITY, data)
  }

  public update (data) {
    return this.api2.update(BendTable.Tables.CUSTOM_ACTIVITY, data)
  }

  public delete (id) {
    return this.api2.delete(BendTable.Tables.CUSTOM_ACTIVITY, id)
  }

  public fetchUsersById (settings: {id: string[] }) {
    return this.api.fetchUsersById(settings)
  }

  public reset () {
    redux.dispatch(
      actions.settings.reset()
    )
  }

  public sortBy (key, direction) {
    let list = redux.getState().customActivities.list
    let sortedList = commonUtil.sortBy(list, key, direction)
    redux.dispatch(
      actions.list.update(sortedList)
    )
  }

  public paginateToPage (page) {
    redux.dispatch(
      actions.settings.pageUpdate(page)
    )
  }

  public async selectSearchTerm (term) {
    redux.dispatch(
      actions.settings.selectSearchTerm(term)
    )
  }

  public async selectCommunity (id: string, communityList: any[]) {
    const selected = communityList.find(item => item._id === id)
    redux.dispatch(
      actions.settings.selectCommunity(selected)
    )
  }

  public async selectCollection (id: string, collections: any[]) {
    const selected = collections.find(item => item._id === id)
    redux.dispatch(
      actions.settings.selectCollection(selected)
    )
  }

  public async selectCategory (category) {
    redux.dispatch(
      actions.settings.selectCategory(category)
    )
  }

  public async getList () {
    const settings = redux.getState().customActivities.settings
    const customActivityList = await this.api2.getList(BendTable.Tables.CUSTOM_ACTIVITY, settings)
    redux.dispatch(
      actions.settings.update(customActivityList.length)
    )
    redux.dispatch(
      actions.list.update(customActivityList)
    )
  }
}

export default new Feature()
