import {
  UPDATE_USERS_SETTINGS,
  UPDATE_USERS_LIST_LENGTH_SETTINGS,
  UPDATE_USERS_PAGE_SETTINGS,
  UPDATE_USERS_KEYWORD_SETTINGS,
  UPDATE_USERS_COMMUNITY_SETTINGS,
  UPDATE_USERS_TEAM_SETTINGS,
  UPDATE_USERS_TEAMS_SETTINGS,
  RESET_USERS_SETTINGS
} from '../constants/types'
import { Settings } from '../reducers/settings'

export interface Action {
  type: string,
  users: Settings,
  count: number,
  page: number,
  keyword: string,
  communityId: any,
  itemsPerPage: number,
  team: any,
  teams: any
}

export const update = (count) => {
  return {
    type: UPDATE_USERS_SETTINGS,
    count
  }
}

export const pageUpdate = (page) => {
  return {
    type: UPDATE_USERS_PAGE_SETTINGS,
    page
  }
}

export const updateItemsPerPage = (itemsPerPage) => {
  return {
    type: UPDATE_USERS_LIST_LENGTH_SETTINGS,
    itemsPerPage
  }
}

export const searchKeywordUpdate = (keyword) => {
  return {
    type: UPDATE_USERS_KEYWORD_SETTINGS,
    keyword
  }
}

export const communityUpdate = (communityId) => {
  return {
    type: UPDATE_USERS_COMMUNITY_SETTINGS,
    communityId
  }
}

export const selectTeam = (team) => {
  return {
    type: UPDATE_USERS_TEAM_SETTINGS,
    team
  }
}

export const teamsUpdate = (teams) => {
  return {
    type: UPDATE_USERS_TEAMS_SETTINGS,
    teams
  }
}

export const reset = () => {
  return {
    type: RESET_USERS_SETTINGS
  }
}
