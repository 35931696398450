import BendClient, { BendTable } from '../BendClient/index'
import * as actions from './actions'
import redux from '../../../src/helpers/redux'
import commonUtil from '../../helpers/commonUtil'
const moment = require('moment-timezone')
import * as Bend from '../../lib/bend-1.1.8'

const TIME_FORMAT = "MMM D, 'YY h:mm a"

class EventsHelper {

  private api: any
  private api2: any

  constructor() {
    this.api = BendClient
    this.api2 = BendTable
  }

  public manageItems() {
    return this.api.manageItems('event')
  }

  public async init() {
    const data = await this.api.getEvents({ filter: { hideDisabled: false } });
    let eventsWithTimeStr = data.events.map(event => {
      event.startsAtLong = event.startsAtUTC || event.startsAt //For the sorting

      if (event.version === 2) {
        const start = moment(event.startsAtUTC / 1000000)
        const end = moment(start).add(event.timeLength || 30, 'minutes')
        if (start.isValid() && end.isValid()) {
          event.timeStr = `${start.format(TIME_FORMAT)} to ${end.format(TIME_FORMAT)}`
        }
      } else {
        // Legacy Recurrent Events, with multiple dates on the same record
        event.timeStr = commonUtil.timesToTimeStr(event)
      }

      if (event.capacity && event.capacity && event.totalRegistrations) {
        event.spotsLeft = event.capacity - event.totalRegistrations
      }

      return event
    })
    //console.log('eventsWithTimeStr', eventsWithTimeStr)
    redux.dispatch(
      actions.list.update(eventsWithTimeStr)
    )
    redux.dispatch(
      actions.settings.update(eventsWithTimeStr.length)
    )
  }

  public async getList() {
    this.init()
  }

  public selectCommunity(id) {
    let community = this.api2.get(BendTable.Tables.COMMUNITY, id)
    redux.dispatch(
      actions.settings.communityUpdate(community)
    )
  }

  public selectCategory(category) {
    redux.dispatch(
      actions.settings.categoryUpdate(category)
    )
  }

  public selectCollection(id) {
    let selected = redux.getState().collections.list.find(item => item._id === id)
    redux.dispatch(
      actions.settings.collectionUpdate(selected)
    )
  }

  public handleSearchKeyword(keyword) {
    redux.dispatch(
      actions.settings.searchKeywordUpdate(keyword)
    )
  }

  public paginateToPage(page) {
    redux.dispatch(
      actions.settings.pageUpdate(page)
    )
  }

  public sortBy(key, direction) {
    let list = redux.getState().events.list
    let sortedList = commonUtil.sortBy(list, key, direction)
    redux.dispatch(
      actions.list.update(sortedList)
    )
  }

  public reloadLists() {
    const settings = redux.getState().events.settings
    if (settings.community && !settings.community._id) {
      this.selectCommunity(null)
    }
    if (settings.category && !settings.category._id) {
      this.selectCategory(null)
    }
    if (settings.collection && !settings.collection._id) {
      this.selectCollection(null)
    }
  }

  public async reset(communityAdminId) {
    redux.dispatch(
      actions.settings.reset()
    )
    this.reloadLists()
    // fix reset for communityAdmins
    if (communityAdminId) {
      this.selectCommunity(communityAdminId)
    }
    await this.init()
  }

  public getCommunityList() {
    return this.api.getCommunityList()
  }

  public getCollectionEnabledList() {
    return this.api.getCollectionEnabled()
  }

  public get(eventId, instanceId = null) {
    if (instanceId != null) {
      return this.api.getEvents({ eventId, instanceId, filter: { hideDisabled: false } });
    } else {
      return this.api2.get(BendTable.Tables.EVENT, eventId, {
        relations: {
          sponsorLogo: 'BendFile',
          coverImage: 'BendFile'
        }
      })
    }
  }

  public create(data) {
    return this.api2.create(BendTable.Tables.EVENT, data)
  }

  public update(data) {
    return this.api2.update(BendTable.Tables.EVENT, data)
  }

  public delete(id) {
    return this.api2.delete(BendTable.Tables.EVENT, id)
  }

  public deleteFollowing(id, fromDate) {
    return this.api.deleteFollowingEvents(id, fromDate)
  }

  public deletePush(id) {
    return this.api.deletePushNotification(id)
  }

  public upload(file, callback, ext, progressCallback) {
    return this.api.upload(file, callback, ext, progressCallback)
  }

  public getFile(refObj, callback) {
    return this.api.getFile(refObj, callback)
  }

  public fetchLinked(settings: { eventId: string }) {
    return this.api.fetchLinkedPushNotification(settings)
  }

  public toggleEnabled = (eventId, enabled) => {
    return this.api.toggleEvent(eventId, enabled)
  }

  public getCancellations = async (event = null, instanceId = null) => {
    if (event == null) return []
    const activity = await this.get(event, instanceId)
    const eventId = activity.parentId || activity._id //In case this is a recurrent one
    return this.api.getEventCancellations(eventId, instanceId)
  }

  public getCheckins = async (event = null, instanceId = null) => {
    if (event == null) return []
    const activity = await this.get(event, instanceId)
    const eventId = activity.parentId || activity._id //In case this is a recurrent one
    return this.api.getEventCheckins(eventId, instanceId)
  }

  public getRegistrations = async (event = null, instanceId = null) => {
    if (event != null) {
      const activity = await this.get(event, instanceId)
      const eventId = activity.parentId || activity._id //In case this is a recurrent one
      const registrations = await Bend.execute('getEventActivityRegistrations', {
        eventId, instanceId
      })
	  
      return registrations;
    } else {
      return []
    }
  }

  public registerCheckIn = async (userId, eventId, instanceId) => {
	if (userId && eventId) {
		const event = await this.get(eventId, instanceId)

		const params = {        
			id: eventId,
			userId: userId,
			type: 'event-checkin',
			table: 'event',
			points: event.checkinPoints || 5,
			parentId: event.parentId || event._id,
			instanceId: instanceId || event.instanceId || null,
			eventType: event.type || null,
			geoverified: false,
		}

		return await Bend.execute('captureActivityV2', params)
	}
  }
  public cancelCheckIn = async (activityId) => {
	return await Bend.execute('removeActivity', {id: activityId})
  }
}

export default new EventsHelper()
