import * as _ from 'lodash'

import {
  UPDATE_CHARTS_SETTINGS,
  UPDATE_CHARTS_PAGE_SETTINGS,
  UPDATE_CHARTS_KEYWORD_SETTINGS,
  UPDATE_CHARTS_COMMUNITY,
  UPDATE_CHARTS_TEAM,
  UPDATE_CHARTS_TEAMS,
  UPDATE_CHARTS_TYPE,
  RESET_CHARTS_SETTINGS
} from '../constants/types'

const dropdownItem = {
  name: 'All Teams',
  _id: null
}

import { Action } from '../actions/settings'

export interface Settings {
  searchTerm: any,
  itemsPerPage: number,
  isLoading: boolean,
  totalItems: number,
  currentPage: number,
  numPages: number,
  community: any,
  selectedType: any,
  team: any,
  teams: any
}

const initialState: Settings = {
  searchTerm: '',
  itemsPerPage: 20,
  isLoading: true,
  totalItems: 0,
  currentPage: 1,
  numPages: 0,
  community: {
    _id: null
  },
  selectedType: {},
  team: {
    _id: null
  },
  teams: []
}

export default (state: Settings = initialState, action: Action): Settings => {
  switch (action.type) {
    case UPDATE_CHARTS_SETTINGS:
      return {
        ...state,
        totalItems: action.count,
        numPages: Math.ceil(action.count / state.itemsPerPage + 1)
      }
    case UPDATE_CHARTS_PAGE_SETTINGS:
      return {
        ...state,
        currentPage: action.page
      }
    case UPDATE_CHARTS_KEYWORD_SETTINGS:
      return {
        ...state,
        searchTerm: action.keyword
      }
    case UPDATE_CHARTS_COMMUNITY:
      return {
        ...state,
        community: action.community
      }
    case UPDATE_CHARTS_TYPE:
      return {
        ...state,
        selectedType: action.selectedType
      }
    case UPDATE_CHARTS_TEAM:
      return {
        ...state,
        team: action.team
      }
    case UPDATE_CHARTS_TEAMS:
      const teamsList = _.without(action.teams)
      const sortedList = _.orderBy(teamsList, (i: any) => i.name.toLowerCase(), ['asc'])
      const teams = [
        dropdownItem,
        ...sortedList
      ]
      return {
        ...state,
        teams
      }
    case RESET_CHARTS_SETTINGS:
      return initialState
    default:
      return state
  }
}
