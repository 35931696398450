import { Statistics } from '../reducers/data'
import { UPDATE_STATISTICS_LIST } from '../constants/types'

export interface Action {
  type: string,
  statistics: Statistics
}

export const update = (statistics: Statistics): Action => {
  return {
    type: UPDATE_STATISTICS_LIST,
    statistics
  }
}
