import {
  UPDATE_PUSH_TEMPLATES_SETTINGS,
  UPDATE_PUSH_TEMPLATES_PAGE_SETTINGS,
  UPDATE_PUSH_TEMPLATES_LOADING_SETTINGS,
  UPDATE_PUSH_TEMPLATES_COMMUNITY_SETTINGS
} from '../constants/types'

export interface Action {
  type: string,
  count: number,
  page: number,
  isLoading: boolean,
  communityId: string
}

export const update = (count) => {
  return {
    type: UPDATE_PUSH_TEMPLATES_SETTINGS,
    count
  }
}

export const pageUpdate = (page) => {
  return {
    type: UPDATE_PUSH_TEMPLATES_PAGE_SETTINGS,
    page
  }
}
export const communityUpdate = (communityId) => {
  return {
    type: UPDATE_PUSH_TEMPLATES_COMMUNITY_SETTINGS,
    communityId
  }
}

// export const updateDeeplinks = (deeplinks) => {
//   return {
//     type: UPDATE_PUSH_TEMPLATES_PAGE_SETTINGS,
//     deeplinks
//   }
// }

export const toggleLoading = (isLoading) => {
  return {
    type: UPDATE_PUSH_TEMPLATES_LOADING_SETTINGS,
    isLoading
  }
}
