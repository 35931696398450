'use strict'

import 'Flot'
import 'Flot/jquery.flot.pie.js'

import * as segmentTypes from '../segmentTypes'
import commonUtil from '../../helpers/commonUtil'

angular.module('app.controllers')
  .controller('SegmentTypeEditController', ['$scope', '$bend', '$location', '$routeParams', 'BendAuth', '$bootbox', '$rootScope', '$modal', '$http', 'pinesNotifications', '$ngRedux',
    async function ($scope, $bend, $location, $routeParams, BendAuth, $bootbox, $rootScope, $modal, $http, pinesNotifications, $ngRedux) {
      // Init.
      $scope.commonUtil = commonUtil
      $scope.user = BendAuth.getActiveUser()

      const mapStateToThis = (state) => {
        return {}
      }

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      if ($routeParams.id !== 'new') {
        segmentTypes.helpers.get($routeParams.id).then(ret => {
          $scope.segmentType = ret
          applyChangesOnScope($scope, () => {})
        })
      } else {
        $scope.segmentType = {
          enabled: true
        }
      }

      $scope.deleteSegmentType = function (id, e) {
        e.preventDefault()
        e.stopPropagation()
        const yeap = confirm('Deleting the question is permanent and can not be undone.')
        if (!yeap) {
          return false
        }
        segmentTypes.helpers.delete(id).then(val => {
          applyChangesOnScope($scope, () => {
            $location.path('/segmentTypes')
          })
        })
      }

      // form validation using angular ng-class
      $scope.validateForm = () => {
        const required = 'required'
        const name = _.get($scope.segmentType, 'name', null)
        if (name === '' || !name) $scope.nameReq = required
        if (
          !name
        ) {
          $scope.errorMsg = true
          return false
        }
        return true
      }

      $scope.saveSegmentTypeDo = function () {
        var segmentTypeData = _.clone($scope.segmentType)
        segmentTypeData.community = commonUtil.makeBendRef($scope.user.community._id, 'community')
        segmentTypes.helpers.update(segmentTypeData)

        $location.path('/segmentTypes')
      }

      $scope.deleteSegmentType = function (id) {
        segmentTypes.helpers.delete(id)
        $location.path('/segmentTypes')
      }
    }])
