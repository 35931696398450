import { UPDATE_LEADERBOARD_LIST } from '../constants/types'
import { List } from '../reducers/list'

export interface Action {
  type: string,
  leaderboards: List
}

export const update = (leaderboards: List): Action => {
  return {
    type: UPDATE_LEADERBOARD_LIST,
    leaderboards
  }
}
