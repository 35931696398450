import {
  UPDATE_MENU_LIST,
  UPDATE_MENU_DEFAULT,
  UPDATE_MENU_MASTER
} from '../constants/types'

import { Item } from '../reducers/list'

export interface Action {
  type: string,
  list: Array<Item>
}

export const updateDefault = (): Action => {
  return {
    type: UPDATE_MENU_DEFAULT,
    list: []
  }
}

export const updateList = (list: Array<Item> = []): Action => {
  return {
    type: UPDATE_MENU_LIST,
    list
  }
}

export const updateMaster = (): Action => {
  return {
    type: UPDATE_MENU_MASTER,
    list: []
  }
}
