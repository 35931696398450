import { combineReducers } from 'redux'
import list, { Activities } from './list'
import settings, { Settings } from './settings'
import calculated from './calculated'

export interface CombineReducers {
  list: Activities,
  settings: Settings
}

const rootReducer = combineReducers<CombineReducers>({
  list,
  settings,
  calculated
})

export default rootReducer
