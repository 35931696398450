'use strict'

import * as studioApps from '../../../src/features/studioApps'
import commonUtil from '../../../src/helpers/commonUtil'

angular.module('app.controllers')
  .controller('StudioAppController', ['$scope', 'BendAuth', '$rootScope', '$ngRedux',
    async function ($scope, BendAuth, $rootScope, $ngRedux) {
      $scope.commonUtil = commonUtil

      $scope.user = BendAuth.getActiveUser()
      if ($scope.user.communityAdmin) {
        window.location.assign('/')
      }

      const mapStateToThis = (state) => {
        return {
          studioApps: state.studioApps.list,
          filter: {
            community: state.teams.settings.community
          },
          sortKey: state.teams.settings.sortKey,
          sortDirection: state.teams.settings.sortDirection,
          fields: {
            exportData: state.studioApps.exportList
          }
        }
      }

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      studioApps.helpers.init()
      studioApps.helpers.export()

      $scope.viewStudioApp = (url) => {
        window.open(url, '_blank')
      }

      $scope.updateExported = async () => {
        studioApps.helpers.updateExported()
      }

      $scope.cleanApps = () => {
        studioApps.helpers.cleanApps().then(() => {
          // location.reload()
        })
      }

      $scope.countFeatures = featureObj => {
        const boolArr = Object.values(featureObj)
        let count = 0
        boolArr.forEach(val => {
          if (val) count++
        })
        return count
      }

      $scope.deleteStudioApp = (studioApp) => {
        studioApps.helpers.delete(studioApp)
      }
    }])
