import * as _ from 'lodash'

import {
  RESET_SURVEYS_SETTINGS,
  UPDATE_SURVEY_RESPONSES,
  UPDATE_SURVEY_USER_GROUP_SETTING,
  UPDATE_SURVEYS_COMMUNITY_SETTINGS,
  UPDATE_SURVEYS_PAGE_SETTINGS,
  UPDATE_SURVEYS_SEGMENTS_SETTINGS,
  UPDATE_SURVEYS_SETTINGS,
  UPDATE_SURVEYS_TEAM_SETTINGS,
  UPDATE_SURVEYS_TEAMS_SETTINGS
} from '../constants/types'

import { Action } from '../actions/settings'

export interface Settings {
  itemsPerPage: number,
  isLoading: boolean,
  totalItems: number,
  currentPage: number,
  numPages: number,
  communityId: any,
  communityTeams: any,
  surveyResponses: any,
  teams: Array<string>,
  version: number,
  surveyId: string,
  segments: Array<string>,
  userGroup: any
}

const initialState: Settings = {
  itemsPerPage: 20,
  isLoading: true,
  totalItems: 0,
  currentPage: 1,
  numPages: 0,
  communityId: '',
  communityTeams: [],
  surveyResponses: {},
  surveyId: '',
  teams: [],
  segments: [],
  userGroup: '',
  version: 2
}

const dropdownItem = {
  name: 'All Teams',
  _id: null
}

export default (state: Settings = initialState, action: Action): Settings => {
  switch (action.type) {
    case UPDATE_SURVEYS_SETTINGS:
      return {
        ...state,
        totalItems: action.count,
        numPages: Math.ceil(action.count / state.itemsPerPage + 1)
      }
    case UPDATE_SURVEYS_PAGE_SETTINGS:
      return {
        ...state,
        currentPage: action.page
      }
    case UPDATE_SURVEY_RESPONSES:
      return {
        ...state,
        surveyResponses: action.surveyResponses
      }
    case UPDATE_SURVEYS_COMMUNITY_SETTINGS:
      return {
        ...state,
        communityId: action.communityId || {}
      }
    case UPDATE_SURVEYS_TEAMS_SETTINGS:
      const teamsList = _.without(action.communityTeams)
      const sortedList = _.orderBy(teamsList, (i: any) => i.name.toLowerCase(), ['asc'])
      const communityTeams = [
        dropdownItem,
        ...sortedList
      ]
      return {
        ...state,
        communityTeams
      }
    case UPDATE_SURVEYS_TEAM_SETTINGS:
      if (action.team && typeof action.team._id !== 'undefined') {
        return {
          ...state,
          teams: [action.team._id]
        }
      } else {
        return {
          ...state,
          teams: []
        }
      }
    case UPDATE_SURVEYS_SEGMENTS_SETTINGS:
      if (action.segment && typeof action.segment._id !== 'undefined') {
        return {
          ...state,
          segments: [action.segment._id]
        }
      } else {
        return {
          ...state,
          segments: []
        }
      }
    case UPDATE_SURVEY_USER_GROUP_SETTING:
      return {
        ...state,
        userGroup: action.userGroup._id || ''
      }
    case RESET_SURVEYS_SETTINGS:
      return {
        itemsPerPage: 20,
        isLoading: true,
        totalItems: 0,
        currentPage: 1,
        numPages: 0,
        communityId: '',
        communityTeams: [],
        surveyResponses: {},
        surveyId: '',
        teams: [],
        segments: [],
        userGroup: '',
        version: 2
      }
    default:
      return state
  }
}
