import {
  UPDATE_SPRINTS_SETTINGS,
  UPDATE_SPRINTS_PAGE_SETTINGS,
  UPDATE_SPRINTS_KEYWORD_SETTINGS,
  UPDATE_SPRINTS_COMMUNITY_SETTINGS,
  RESET_SPRINTS_SETTINGS
} from '../constants/types'
import { Settings } from '../reducers/settings'

export interface Action {
  type: string,
  users: Settings,
  count: number,
  page: number,
  keyword: string,
  community: any
}

export const update = (count) => {
  return {
    type: UPDATE_SPRINTS_SETTINGS,
    count
  }
}

export const pageUpdate = (page) => {
  return {
    type: UPDATE_SPRINTS_PAGE_SETTINGS,
    page
  }
}

export const searchKeywordUpdate = (keyword) => {
  return {
    type: UPDATE_SPRINTS_KEYWORD_SETTINGS,
    keyword
  }
}

export const communityUpdate = (community) => {
  return {
    type: UPDATE_SPRINTS_COMMUNITY_SETTINGS,
    community
  }
}

export const reset = () => {
  return {
    type: RESET_SPRINTS_SETTINGS
  }
}
