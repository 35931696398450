export const UPDATE_PLACES_LIST = 'UPDATE_PLACES_LIST'
export const UPDATE_PLACES_COUNT = 'UPDATE_PLACES_COUNT'
export const UPDATE_PLACES_PAGE_SETTINGS = 'UPDATE_PLACES_PAGE_SETTINGS'
export const UPDATE_PLACES_SEARCH_TERM_SETTINGS = 'UPDATE_PLACES_SEARCH_TERM_SETTINGS'
export const RESET_PLACES_SETTINGS = 'RESET_PLACES_SETTINGS'

export const UPDATE_PLACES_STATES_SETTINGS = 'UPDATE_PLACES_STATES_SETTINGS'
export const UPDATE_PLACES_COMMUNITY_SETTINGS = 'UPDATE_PLACES_COMMUNITY_SETTINGS'
export const UPDATE_PLACES_CATEGORY_SETTINGS = 'UPDATE_PLACES_CATEGORY_SETTINGS'
export const UPDATE_PLACES_COLLECTION_SETTINGS = 'UPDATE_PLACES_COLLECTION_SETTINGS'
export const UPDATE_PLACES_STATE_SETTINGS = 'UPDATE_PLACES_STATE_SETTINGS'
export const UPDATE_PLACES_SORT_DIRECTION_SETTINGS = 'UPDATE_PLACES_SORT_DIRECTION_SETTINGS'
export const UPDATE_PLACES_SORT_KEY_SETTINGS = 'UPDATE_PLACES_SORT_KEY_SETTINGS'
export const UPDATE_PLACES_LOADING_SETTINGS = 'UPDATE_PLACES_LOADING_SETTINGS'
