import BendClient from '../BendClient'
import * as actions from './actions'
import redux from '../../../src/helpers/redux'

class Feature {

  private api: any

  constructor () {
    this.api = BendClient
  }

  public async init () {
    const collections = await this.api.fetchCollectionList()
    redux.dispatch(
      actions.list.update(collections)
    )
  }

  public async initWithAll () {
    const collections = await this.api.fetchEnabledCollectionList()
    const AllCollection = ({
      name: 'All Collections',
      _id: null
    } as any)
    redux.dispatch(
      actions.list.update([AllCollection].concat(collections))
    )
  }

  public create (data) {
    return this.api.createCollection(data)
  }

  public update (data) {
    return this.api.updateCollection(data)
  }

  public delete (id) {
    return this.api.deleteCollection(id)
  }

  public upload (file, callback, ext, progressCallback) {
    return this.api.upload(file, callback, ext, progressCallback)
  }
  public getFile (refObj, callback) {
    return this.api.getFile(refObj, callback)
  }

}

export default new Feature()
