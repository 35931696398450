'use strict'

import commonUtil from '../../../src/helpers/commonUtil'
import * as volunteerings from '../../../src/features/volunteerings'
import * as categories from '../../../src/features/categories'
import * as teams from '../../../src/features/teams'
import * as segments from '../../../src/features/segments'

angular.module('app.controllers')
  .controller('VolunteeringEditController', ['$scope', '$location', '$routeParams', 'BendAuth', '$rootScope', '$modal', '$ngRedux',
    async function ($scope, $location, $routeParams, BendAuth, $rootScope, $modal, $ngRedux) {
      // Init.
      $scope.commonUtil = commonUtil
      $scope.volunteeringId = null
      if ($routeParams.id != 'new') { $scope.volunteeringId = $routeParams.id }
      $scope.isLoading = true
      $scope.moreOptions = false
      $scope.user = await BendAuth.getActiveUser()

      $scope.formData = {
        categories: [],
        collections: [],
        segments: [],
        teams: [],
        intervalminute: 0,
        intervalhour: 0,
        intervalday: 0
      }

      const formDataKeys = Object.keys($scope.formData)

      const mapStateToThis = (state) => {
        // prep formdata to display when loading data
        if (
          // $scope.teams &&
          // $scope.segments &&
          state.segments.list &&
          state.segments.list.length &&
          state.categories.list &&
          state.categories.list.length
        ) {
          formDataKeys.map(key => {
            if (
              !$scope.volunteering ||
              !$scope.volunteering[key] ||
              typeof $scope.formData[key] === 'string' // if its a string
            ) {
              return
            }
            $scope.formData[key] = $scope[key].filter(obj => {
              return $scope.volunteering[key].indexOf(obj._id) !== -1
            })
          })
        }
        return {
          comments: state.comments.list,
          categories: state.categories.list,
          segments: state.segments.list,
          teams: state.teams.list.teams
        }
      }

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      teams.helpers.init()
      segments.helpers.init()
      categories.helpers.getList($scope.user.community._id)

      $scope.isUploading = []
      $scope.volunteering = {
        repeatable: true
      }

      $scope.tagList = []
      // $scope.categoryList = []
      $scope.collectionList = []
      $scope.communityList = []
      $scope.stateList = commonUtil.AllStates
      $scope.state = $scope.stateList[0]
      $scope.fileProgress = []
      $scope.openedDateSelector = [false, false]
      $scope.openedTimeDateSelector = []
      $scope.form = {}

      async.parallel([
        async (callback) => {
          const response = await volunteerings.helpers.getCommunityList()
          $scope.communityList = response
          callback(null, null)
        }, async (callback) => {
          const response = await volunteerings.helpers.getCollectionEnabled()
          $scope.collectionList = response
          callback(null, null)
        }], function (err, rets) {
        if (err) {
          return
        }

        if ($scope.volunteeringId) {
          volunteerings.helpers.get($scope.volunteeringId).then(function (ret) {
            ret.enabled = (typeof ret.enabled !== 'undefined' && ret.enabled === true)
            $scope.volunteering = ret
            $scope.isLoading = false
            if ($scope.volunteering.repeatInterval) {
              $scope.formData.intervalday = parseInt($scope.volunteering.repeatInterval / (24 * 3600))
              $scope.formData.intervalhour = parseInt(($scope.volunteering.repeatInterval - $scope.formData.intervalday * (24 * 3600)) / (3600))
              $scope.formData.intervalminute = parseInt(($scope.volunteering.repeatInterval - (($scope.formData.intervalday * (24 * 3600)) + $scope.formData.intervalhour * 3600)) / 60)
            }

            if ($scope.volunteering.state) {
              $scope.state = $scope.volunteering.state
            }

            if ($scope.volunteering.categories && $scope.volunteering.categories.length > 0) {
              $scope.formData.categories = _.filter($scope.categories, function (o) {
                return $scope.volunteering.categories.indexOf(o._id) != -1
              })
            }

            if ($scope.volunteering.teams && $scope.volunteering.teams.length) {
              $scope.formData.teams = _.filter($scope.teams, function (team) {
                return $scope.volunteering.teams.indexOf(team._id) !== -1
              })
            }

            if ($scope.volunteering.collections && $scope.volunteering.collections.length > 0) {
              $scope.formData.collections = _.filter($scope.collectionList, function (o) {
                return $scope.volunteering.collections.indexOf(o._id) != -1
              })
            }

            if ($scope.volunteering.community) {
              $scope.volunteering.community = $scope.volunteering.community._id
            } else {
              $scope.volunteering.community = ''
            }

            if ($scope.volunteering.startsAt) {
              $scope.volunteering.startsAt = commonUtil.formatDateWithFormat($scope.volunteering.startsAt, 'YYYY-MM-DD h:mm a')
           }
            
            if ($scope.volunteering.endsAt) {
              $scope.volunteering.endsAt = commonUtil.formatDateWithFormat($scope.volunteering.endsAt, 'YYYY-MM-DD h:mm a')
            }
          })
        }
      })

      $scope.getSegmentInfo = (segment) => {
        return segments.helpers.getSegmentInfo(segment)
      }

      $scope.deleteVolunteering = async () => {
        const id = $scope.volunteeringId
        if (!id) { return $location.path('/volunteerings') }
        const yeap = confirm('Deleting the volunteering is permanent and can not be undone.')
        if (!yeap) {
          return false
        }
        await volunteerings.helpers.delete(id)
        applyChangesOnScope($scope, () => {
          $location.path('/volunteerings')
        })
      }

      // form validation using angular ng-class
      $scope.validateForm = () => {
        const required = 'required'

        const name = _.get($scope.volunteering, 'name')
        const description = _.get($scope.volunteering, 'description')
        const points = _.get($scope.volunteering, 'points')
        const categories = _.get($scope.formData, 'categories')


        if (name === '' || !name) $scope.nameReq = required
        else $scope.nameReq = false
        if (description === '' || !description) $scope.descriptionReq = required
        else $scope.descriptionReq = false
        if (categories.length === 0 || !categories) $scope.categoriesReq = required
        else $scope.categoriesReq = false
        if (points === '' || !points) $scope.pointsReq = required
        else $scope.pointsReq = false

        if (!name ||
          !description ||
          !categories ||
          categories === [] ||
          !points
        ) {
          $scope.errorMsg = true
          return false
        }
        return true
      }

      $scope.filterNewline = async () =>{
        var description = _.get($scope.volunteering, 'description')
        var parts = description.toString().split('\n')
        _.set($scope.volunteering, 'description', parts.join(''))
      }

      $scope.saveVolunteeringDo = async function () {
        if (!$scope.validateForm()) return
        $scope.filterNewline()
        var repeatInterval = 0
        if ($scope.formData.intervalday != 0) {
          repeatInterval = $scope.formData.intervalday * (24 * 3600)
        }
        if ($scope.formData.intervalhour != 0) {
          repeatInterval = repeatInterval + $scope.formData.intervalhour * (3600)
        }
        if ($scope.formData.intervalminute != 0) {
          repeatInterval = repeatInterval + $scope.formData.intervalminute * (60)
        }

        if (repeatInterval > 0) {
          $scope.volunteering.repeatInterval = repeatInterval
        }
        //console.log("2", $scope.volunteering.startsAt)

        $scope.volunteering.startsAt = commonUtil.convertStringToDate($scope.volunteering.startsAt, 'YYYY-MM-DD h:mm a').getTime() * 1000000
        $scope.volunteering.endsAt = commonUtil.convertStringToDate($scope.volunteering.endsAt, 'YYYY-MM-DD h:mm a').getTime() * 1000000

        //console.log("3",$scope.volunteering.startsAt)


        formDataKeys.map(key => {
          if ($scope.formData[key] && $scope.formData[key].length > 0) {
            $scope.volunteering[key] = $scope.formData[key].map(obj => obj._id)
          } else {
            delete $scope.volunteering[key]
          }
        })
        //if ($scope.volunteering._geoloc && $scope.volunteering._geoloc[0] && $scope.volunteering._geoloc[1] && $scope.volunteering._geoloc[0] != '' && $scope.volunteering._geoloc[1] != '') { $scope.volunteering._geoloc = [parseFloat($scope.volunteering._geoloc[0]), parseFloat($scope.volunteering._geoloc[1])] } else { delete $scope.volunteering._geoloc }
        var volunteeringData = _.clone($scope.volunteering)
        if (volunteeringData.coverImage) {
          volunteeringData.coverImage = commonUtil.makeBendFile(volunteeringData.coverImage._id)
        }

        if (volunteeringData.community != '') {
          volunteeringData.community = commonUtil.makeBendRef(volunteeringData.community, 'community')
        } else {
          delete volunteeringData.community
        }

        if ($scope.volunteeringId) {
          delete volunteeringData.$$hashKey
          await volunteerings.helpers.update(volunteeringData)
          applyChangesOnScope($scope, () => {
            $location.path('/volunteerings')
          })
        } else {
          await volunteerings.helpers.create(volunteeringData)
          applyChangesOnScope($scope, () => {
            $location.path('/volunteerings')
          })
        }
      }

      $scope.openDateWindow = function ($volunteering, idx) {
        $volunteering.prvolunteeringDefault()
        $volunteering.stopPropagation()

        $scope.openedDateSelector[idx] = true
      }
      $scope.openTimeDateWindow = function ($volunteering, idx) {
        $volunteering.prvolunteeringDefault()
        $volunteering.stopPropagation()

        $scope.openedTimeDateSelector[idx] = true
      }

      $scope.deleteFile = function (tag, $ev) {
        $ev.stopPropagation()
        $ev.prvolunteeringDefault()
        applyChangesOnScope($scope, function () {
          delete $scope.volunteering.coverImage
        })
      }

      $scope.showFileLoading = function (tag, bShow) {
        $scope.isUploading[tag] = bShow
      }

      $scope.selectFileOpen = function (fileId, $ev) {
        if ($ev.target.tagName == 'DIV') {
          setTimeout(function () {
            $('#' + fileId).click()
          }, 0)
        }
      }

      $scope.onFileUpload = function (files, tag) {
        var file = files[0]
        $scope.fileProgress[tag] = 0
        $scope.showFileLoading(tag, true)
        var reader = new FileReader()
        reader.onload = function (e) {
          $scope.uploadingFileUrl = e.target.result
        }
        reader.readAsDataURL(file)

        volunteerings.helpers.upload(file, function (error, uploadedFile) {
          if (error) {
            $scope.showFileLoading(tag, false)
            $scope.uploadingFileUrl = null

            return
          }
          volunteerings.helpers.getFile(uploadedFile, function (o) {
            $scope.volunteering.coverImage = o
            $scope.uploadingFileUrl = null

            $scope.showFileLoading(tag, false)
          })
        }, {
          _workflow: 'coverPhoto'
        }, function (total, prog) {
          applyChangesOnScope($scope, function () {
            $scope.fileProgress[tag] = prog * 100 / total
          })
        })
      }

      $scope.resetGeocode = function () {
        geoAddress = ''
        marker = null
        delete $scope.volunteering._geoloc
        $scope.viewGeocode = false
      }

      var addressList = []
      $scope.openGeocode = function () {
        marker = null // this down't work to reset marker if address is changed
        if ($scope.volunteering.address1 && $scope.volunteering.address1 != '') { addressList.push($scope.volunteering.address1) }
        if ($scope.volunteering.city && $scope.volunteering.city != '') { addressList.push($scope.volunteering.city) }
        if ($scope.volunteering.state && $scope.volunteering.state != '') { addressList.push($scope.volunteering.state) }
        if ($scope.volunteering.postalCode && $scope.volunteering.postalCode != '') { addressList.push($scope.volunteering.postalCode) }
        $scope.viewGeocode = true
        setTimeout(() => $scope.initMap(), 200) // google maps needs this timeout for some reason
      }

      $scope.close = function () {
        $scope.viewGeocode = false
      }

      $scope.confirmAddress = function () {
        if (markerGeocode.indexOf('') !== -1) return
        $scope.volunteering._geoloc = [markerGeocode[1], markerGeocode[0]]
        $scope.viewGeocode = false
      }

      var geoAddress
      var marker = null
      $scope.initMap = function () {
        var map = new google.maps.Map(document.getElementById('geo_map'), {
          zoom: 12,
          center: { lat: 42.3005383, lng: -71.0654838 } // phila! :D
        })

        var geocoder = new google.maps.Geocoder()

        if (addressList.length > 0) {
          geoAddress = addressList.join(', ')
          // document.getElementById('address').value = address
          $scope.geocodeAddress(geocoder, map)
        } else {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
              var pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
              }

              map.setCenter(pos)
            })
          }
        }

        document.getElementById('submit').addEventListener('click', function () {
          $scope.geocodeAddress(geocoder, map)
        })

        map.addListener('click', function (e) {
          $scope.placeMarkerAndPanTo(e.latLng, map)
        })
      }

      var markerGeocode = []
      $scope.placeMarkerAndPanTo = function (latLng, map) {
        if (marker) {
          marker.setPosition(latLng)
        } else {
          marker = new google.maps.Marker({
            map: map,
            position: latLng
          })
        }
        markerGeocode = [latLng.lat(), latLng.lng()]
      }

      $scope.geocodeAddress = function (geocoder, resultsMap) {
        geocoder.geocode({ 'address': geoAddress }, function (results, status) {
          if (status === 'OK') {
            resultsMap.setCenter(results[0].geometry.location)
            $scope.placeMarkerAndPanTo(results[0].geometry.location, resultsMap)
          } else {
            alert('Geocode was not successful for the following reason: ' + status)
          }
        })
      }
      // $rootScope.setLocation = function (lat, lng) {
      //   $scope.volunteering._geoloc = [
      //     Number(lng),
      //     Number(lat)
      //   ]
      // }

      // $scope.geocodeAddress = function () {
      //   var addressList = []
      //   if ($scope.volunteering.address1 && $scope.volunteering.address1 != '') { addressList.push($scope.volunteering.address1) }
      //   if ($scope.volunteering.city && $scope.volunteering.city != '') { addressList.push($scope.volunteering.city) }
      //   if ($scope.volunteering.state && $scope.volunteering.state != '') { addressList.push($scope.volunteering.state) }
      //   if ($scope.volunteering.postalCode && $scope.volunteering.postalCode != '') { addressList.push($scope.volunteering.postalCode) }

      //   $modal.open({
      //     templateUrl: 'views/templates/geocodeAddress.html',
      //     backdrop: 'static',
      //     controller: function ($scope, $modalInstance) {
      //       $scope.cancel = function () {
      //         $modalInstance.dismiss('cancel')
      //       }
      //       $scope.close = function () {
      //         $modalInstance.dismiss('cancel')
      //       }
      //       $scope.confirmAddress = function () {
      //         var lat = $('#geoLat').val()
      //         var lng = $('#geoLng').val()
      //         if (lat == '' || lng == '') return

      //         $rootScope.setLocation(lat, lng)
      //         $modalInstance.dismiss('cancel')
      //       }

      //       var marker = null
      //       $scope.initMap = function () {
      //         var map = new google.maps.Map(document.getElementById('geo_map'), {
      //           zoom: 12,
      //           center: {lat: 42.3005383, lng: -71.0654838}
      //         })

      //         var geocoder = new google.maps.Geocoder()

      //         if (addressList.length > 0) {
      //           var address = addressList.join(', ')
      //           document.getElementById('address').value = address
      //           $scope.geocodeAddress(geocoder, map)
      //         } else {
      //           if (navigator.geolocation) {
      //             navigator.geolocation.getCurrentPosition(function (position) {
      //               var pos = {
      //                 lat: position.coords.latitude,
      //                 lng: position.coords.longitude
      //               }

      //               map.setCenter(pos)
      //             })
      //           }
      //         }

      //         document.getElementById('submit').addvolunteeringListener('click', function () {
      //           $scope.geocodeAddress(geocoder, map)
      //         })

      //         map.addListener('click', function (e) {
      //           $scope.placeMarkerAndPanTo(e.latLng, map)
      //         })
      //       }

      //       setTimeout(function () {
      //         $scope.initMap()
      //       }, 100)

      //       $scope.placeMarkerAndPanTo = function (latLng, map) {
      //         if (marker) {
      //           marker.setPosition(latLng)
      //         } else {
      //           marker = new google.maps.Marker({
      //             map: map,
      //             position: latLng
      //           })
      //         }

      //         $('#geoLat').val(latLng.lat())
      //         $('#geoLng').val(latLng.lng())
      //       }

      //       $scope.geocodeAddress = function (geocoder, resultsMap) {
      //         var address = document.getElementById('address').value
      //         geocoder.geocode({'address': address}, function (results, status) {
      //           if (status === 'OK') {
      //             resultsMap.setCenter(results[0].geometry.location)
      //             $scope.placeMarkerAndPanTo(results[0].geometry.location, resultsMap)
      //           } else {
      //             alert('Geocode was not successful for the following reason: ' + status)
      //           }
      //         })
      //       }
      //     }
      //   })
      // }
      $rootScope.fileUploadFromSearch2 = function (file, tag) {
        file._filename = Date.now() + ''
        var files = []
        files.push(file)
        $scope.onFileUpload(files, tag)
      }

      $scope.searchImages2 = function ($ev, tag) {
        $ev.stopPropagation()
        $ev.prvolunteeringDefault()
        $modal.open({
          templateUrl: 'views/templates/searchImages.html',
          backdrop: 'static',
          controller: function ($scope, $modalInstance) {
            $scope.commonUtil = commonUtil
            $scope.searchResults = 0
            $scope.pages = 1
            $scope.showLoadMore = false
            // var cacheSearchKey = ''

            $scope.cancel = function () {
              $modalInstance.dismiss('cancel')
            }
            $scope.close = function () {
              $modalInstance.dismiss('cancel')
            }

            $scope.searchImages = function (searchVal) {
              // cacheSearchKey = searchVal

              $scope.pages = 1
              if (searchVal) {
                var URL = 'https://pixabay.com/api/?key=2706353-ee3016f8af51ca406a8c8a3db&q=' + encodeURIComponent(searchVal) + '&image_type=photo&page=' + $scope.pages
                $.getJSON(URL, function (data) {
                  if (parseInt(data.totalHits) > 0) {
                    applyChangesOnScope($scope, function () {
                      if (data.totalHits > $scope.pages * 20) { $scope.showLoadMore = true } else { $scope.showLoadMore = false }

                      $scope.searchResults = data.hits
                    })
                  }
                })
              }
            }

            $scope.searchImagesMore = function (tabIdx, searchVal) {
              $scope.pages++
              if (searchVal) {
                var URL = 'https://pixabay.com/api/?key=2706353-ee3016f8af51ca406a8c8a3db&q=' + encodeURIComponent(searchVal) + '&image_type=photo&page=' + $scope.pages
                $.getJSON(URL, function (data) {
                  if (parseInt(data.totalHits) > 0) {
                    applyChangesOnScope($scope, function () {
                      if (data.totalHits > $scope.pages * 20) { $scope.showLoadMore = true } else { $scope.showLoadMore = false }

                      $scope.searchResults = $scope.searchResults.concat(data.hits)
                    })
                  }
                })
              }
            }

            $scope.selectImage = function (searchItem) {
              $scope.isDownloading = true
              var imageUrl = ''
              imageUrl = searchItem.webformatURL

              var xhr = new XMLHttpRequest()
              xhr.open('GET', imageUrl, true)
              xhr.responseType = 'blob'
              xhr.onload = function (e) {
                if (this.status == 200) {
                  var myBlob = this.response
                  $rootScope.fileUploadFromSearch2(myBlob, tag)
                  // myBlob is now the blob that the object URL pointed to.
                  $scope.cancel()
                }
              }
              xhr.send()
            }
          }
        })
      }
    }])
