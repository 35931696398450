'use strict'

import commonUtil from '../../../src/helpers/commonUtil'
import * as categories from '../../../src/features/categories'
import * as users from '../../../src/features/users'
import * as segments from '../../../src/features/segments'
import * as eventTemplates from '../../../src/features/eventTemplates'
import { getActiveUser } from '../../../src/helpers'

angular.module('app.controllers')
  .controller('EventTemplatesEditController', ['$scope', '$location', '$routeParams', '$rootScope', '$ngRedux', '$route', '$modal',
    function ($scope, $location, $routeParams, $rootScope, $ngRedux, $route, $modal) {
      const mapStateToThis = (state) => ({
        comments: state.comments.list,
        segments: state.segments.list,
        categories: state.categories.list
      })

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      const loadData = async ($scope) => {
        await categories.helpers.getList($scope.user.community._id)
        await segments.helpers.init()
      }

      $scope.commonUtil = commonUtil
      $scope.isSaving = false
      $scope.eventId = $routeParams.id != 'new' ? $routeParams.id : null
      $scope.isLoading = true
      $scope.moreOptions = false
      $scope.fileProgress = []
      $scope.isUploading = []

      $scope.stateList = commonUtil.AllStates
      $scope.formData = {
        categories: [],
        collections: [],
        state: '',
        segments: []
      }

      $scope.event = {
        type: 'geo-checkin',
        version: 2
      }
      $scope.template = {}

      $scope.user = getActiveUser()
      $scope.form = {}

      $scope.creator = null

      try {
        loadData($scope)
          .then(() => {
            if ($scope.eventId) {
              eventTemplates.helpers.get($scope.eventId).then((template) => {
                const event = { ...template.event }

                event.enabled = (typeof event.enabled !== 'undefined' && event.enabled === true)

                if (event.categories && event.categories.length > 0) $scope.formData.categories = $scope.categories.filter((o) => event.categories.indexOf(o._id) != -1)

                if (event.segments && event.segments.length > 0) $scope.formData.segments = $scope.segments.filter((o) => event.segments.indexOf(o._id) != -1)

                // convert check-in radius to feet for American users
                if (event.checkinRadius) event.checkinRadius = commonUtil.metersToFeet(event.checkinRadius)

                event.community = event.community ? event.community._id : ''

                $scope.formData.state = event.state
                $scope.event = event
                $scope.template = template
                $scope.updateType()
                $scope.isLoading = false
                $scope.setCreator()
              })
            } else {
              $scope.linkedPushToggle = false
              $scope.add_count = 0
            }
          })
      } catch (error) {
        // console.log(error)
        return
      }

      $scope.updateType = () => {
        const { address1, city, state, isPhoneCheckIn, digital } = $scope.event
        let { type } = $scope.event

        if (!type) {
          if (!address1 && !city && !state) type = 'self-checkin'
          else if (address1) type = 'geo-checkin'
          else if (isPhoneCheckIn) type = 'phone-checkin'
          else if (digital) type = 'url-checkin'
          else type = 'geo-checkin'

          $scope.event.type = type
        }

        $scope.addressExists = $scope.addressRequired = (type == 'geo-checkin' || type == 'self-checkin')
        $scope.digitalReq = (type == 'url-checkin' || type == 'phone-checkin')
      }

      $scope.setCreator = () => {
        if ($scope.template._acl && $scope.template._acl.creator) {
          const { creator: userId } = $scope.template._acl
          users.helpers.get(userId)
            .then((user) => {
              if (user) {
                applyChangesOnScope($scope, () => {
                  $scope.creator = user
                })
              }
            })
        }
      }

      $scope.getSegmentInfo = (segment) => segments.helpers.getSegmentInfo(segment)

      $scope.resetGeocode = () => {
        geoAddress = ''
        marker = null
        delete $scope.event._geoloc
        $scope.viewGeocode = false
        addressList = []
      }

      let addressList = []
      $scope.openGeocode = () => {
        const { address1, city, state, postalCode } = $scope.event
        addressList = []
        marker = null // this down't work to reset marker if address is changed
        if (address1 && address1 != '') addressList.push(address1)
        if (city && city != '') addressList.push(city)
        if (state && state != '') addressList.push(state)
        if (postalCode && postalCode != '') addressList.push(postalCode)
        $scope.viewGeocode = true
        setTimeout(() => $scope.initMap(), 200) // google maps needs this timeout for some reason
      }

      $scope.close = function () {
        $scope.viewGeocode = false
      }

      $scope.confirmAddress = function () {
        if (markerGeocode.indexOf('') !== -1) return
        $scope.event._geoloc = [markerGeocode[1], markerGeocode[0]]
        $scope.viewGeocode = false
      }

      let geoAddress
      let marker = null
      $scope.initMap = function () {
        const map = new google.maps.Map(document.getElementById('geo_map'), {
          zoom: 12,
          center: { lat: 42.3005383, lng: -71.0654838 } // phila! :D
        })

        const geocoder = new google.maps.Geocoder()

        if (addressList.length > 0) {
          geoAddress = addressList.join(', ')
          // document.getElementById('address').value = address
          $scope.geocodeAddress(geocoder, map)
        } else {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
              const pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
              }
              map.setCenter(pos)
            })
          }
        }

        document.getElementById('submit').addEventListener('click', () => {
          $scope.geocodeAddress(geocoder, map)
        })

        map.addListener('click', (e) => {
          $scope.placeMarkerAndPanTo(e.latLng, map)
        })
      }

      let markerGeocode = []
      $scope.placeMarkerAndPanTo = function (latLng, map) {
        if (marker) {
          marker.setPosition(latLng)
        } else {
          marker = new google.maps.Marker({
            map: map,
            position: latLng
          })
        }
        markerGeocode = [latLng.lat(), latLng.lng()]
      }

      $scope.geocodeAddress = (geocoder, resultsMap) => {
        if (geoAddress && geoAddress !== '') {
          geocoder.geocode({ 'address': geoAddress }, (results, status) => {
            if (status === 'OK') {
              resultsMap.setCenter(results[0].geometry.location)
              $scope.placeMarkerAndPanTo(results[0].geometry.location, resultsMap)
            } else {
              alert('Geocode was not successful for the following reason: ' + status)
            }
          })
        }
      }

      $scope.deleteFile = function (tag, $ev) {
        $ev.stopPropagation()
        $ev.preventDefault()
        applyChangesOnScope($scope, () => {
          if (tag === 'coverImage') delete $scope.event.coverImage
          if (tag === 'sponsorLogo') delete $scope.event.sponsorLogo
        })
      }

      $scope.showFileLoading = (tag, bShow) => {
        $scope.isUploading[tag] = bShow
      }

      $scope.selectFileOpen = (fileId, $ev) => {
        if ($ev.target.tagName == 'DIV') setTimeout(() => $('#' + fileId).click(), 0)
      }

      $scope.onFileUpload = (files, tag) => {
        const imgNum = (tag === 'sponsorLogo' && files.length > 1 ? 1 : 0)
        const file = files[imgNum]
        // var file = files[0]
        $scope.fileProgress[tag] = 0
        $scope.showFileLoading(tag, true)
        const reader = new FileReader()
        reader.onload = (e) => {
          if (tag === 'coverImage') $scope.uploadingFileUrl = e.target.result
          if (tag === 'sponsorLogo') $scope.uploadingSponsorUrl = e.target.result
        }
        reader.readAsDataURL(file)

        eventTemplates.helpers.upload(file, (error, uploadedFile) => {
          if (error) {
            $scope.uploadingFileUrl = null
            $scope.showFileLoading(tag, false)
            return
          }
          eventTemplates.helpers.getFile(uploadedFile, (o) => {
            $scope.uploadingFileUrl = null
            if (tag === 'coverImage') $scope.event.coverImage = o
            if (tag === 'sponsorLogo') $scope.event.sponsorLogo = o
            $scope.showFileLoading(tag, false)
          })
        }, {
          _workflow: 'coverPhoto'
        }, (total, prog) => {
          applyChangesOnScope($scope, () => {
            $scope.fileProgress[tag] = prog * 100 / total
          })
        })
      }

      $rootScope.fileUploadFromSearch2 = function (file, tag) {
        file._filename = Date.now() + ''
        const files = []
        files.push(file)
        $scope.onFileUpload(files, tag)
      }

      $scope.searchImages2 = function ($ev, tag) {
        $ev.stopPropagation()
        $ev.preventDefault()
        $modal.open({
          templateUrl: 'views/templates/searchImages.html',
          backdrop: 'static',
          controller: function ($scope, $modalInstance) {
            $scope.commonUtil = commonUtil
            $scope.searchResults = 0
            $scope.pages = 1
            $scope.showLoadMore = false
            // var cacheSearchKey = ''

            $scope.cancel = () => $modalInstance.dismiss('cancel')
            $scope.close = () => $modalInstance.dismiss('cancel')

            $scope.searchImages = (searchVal) => {
              $scope.pages = 1
              if (searchVal) {
                const URL = 'https://pixabay.com/api/?key=2706353-ee3016f8af51ca406a8c8a3db&q=' + encodeURIComponent(searchVal) + '&image_type=photo&page=' + $scope.pages
                $.getJSON(URL, (data) => {
                  if (parseInt(data.totalHits) > 0) {
                    applyChangesOnScope($scope, () => {
                      $scope.showLoadMore = (data.totalHits > $scope.pages * 20)
                      $scope.searchResults = data.hits
                    })
                  }
                })
              }
            }

            $scope.searchImagesMore = function (tabIdx, searchVal) {
              $scope.pages++
              if (searchVal) {
                const URL = 'https://pixabay.com/api/?key=2706353-ee3016f8af51ca406a8c8a3db&q=' + encodeURIComponent(searchVal) + '&image_type=photo&page=' + $scope.pages
                $.getJSON(URL, (data) => {
                  if (parseInt(data.totalHits) > 0) {
                    applyChangesOnScope($scope, () => {
                      $scope.showLoadMore = (data.totalHits > $scope.pages * 20)
                      $scope.searchResults = $scope.searchResults.concat(data.hits)
                    })
                  }
                })
              }
            }

            $scope.selectImage = function (searchItem) {
              $scope.isDownloading = true
              const imageUrl = searchItem.webformatURL

              const xhr = new XMLHttpRequest()
              xhr.open('GET', imageUrl, true)
              xhr.responseType = 'blob'
              xhr.onload = function (e) {
                if (this.status == 200) {
                  const myBlob = this.response
                  $rootScope.fileUploadFromSearch2(myBlob, tag)
                  // myBlob is now the blob that the object URL pointed to.
                  $scope.cancel()
                }
              }
              xhr.send()
            }
          }
        })
      }

      const checkRequiredString = (value) => (value === '' || !value ? 'required' : false)
      const checkRequiredNumber = (value) => (value === 0 || !value ? 'required' : false)
      const checkRequiredArray = (value) => (!value || value.length === 0 ? 'required' : false)

      $scope.validateForm = () => {
        const required = 'required'

        const { name: templateName, description: templateDescription } = $scope.template
        const { name, description, _geoloc, address1, city, postalCode, checkinPoints, digital } = $scope.event
        const { categories, state } = $scope.formData

        $scope.templateNameReq = checkRequiredString(templateName)
        $scope.templateDescriptionReq = checkRequiredString(templateDescription)
        $scope.nameReq = checkRequiredString(name)
        $scope.descriptionReq = checkRequiredString(description)
        $scope.categoriesReq = checkRequiredArray(categories)
        $scope.checkinPointsReq = checkRequiredNumber(checkinPoints)

        // address requirements group, cancels requirement if address doesn't exist
        $scope.digitalReq = ($scope.digitalReq && !digital ? required : false)

        if ($scope.addressRequired) {
          $scope.address1Req = checkRequiredString(address1)
          $scope.cityReq = checkRequiredString(city)
          $scope.stateReq = checkRequiredString(state)
          $scope.postalCodeReq = checkRequiredString(postalCode)
          $scope.geocodeReq = checkRequiredString(_geoloc)
        }

        const { templateNameReq, templateDescriptionReq, nameReq, descriptionReq, categoriesReq, checkinPointsReq, digitalReq, addressRequired, address1Req, cityReq, stateReq, postalCodeReq, geocodeReq } = $scope

        // if all required fields are false, so return true.
        const allOK = (!templateNameReq &&
                    !templateDescriptionReq &&
                    !nameReq &&
                    !descriptionReq &&
                    !categoriesReq &&
                    !checkinPointsReq &&
                    !digitalReq &&
                    (
                      !addressRequired ||
                        (
                          addressRequired &&
                            !address1Req &&
                            !cityReq &&
                            !stateReq &&
                            !postalCodeReq &&
                            !geocodeReq
                        )
                    ))

        $scope.errorMsg = !allOK
        return allOK
      }

      $scope.saveEventDo = async function () {
        $scope.updateType();
        if ($scope.validateForm()) {
          const { formData, event, template, addressRequired } = $scope
          const { community, description, name, checkinRadius, _geoloc, coverImage, sponsorLogo } = event
          const { segments, state, categories } = formData

          const eventData = {
            ...event,
            name: name,
            description: description.replace('/\n/g', ''),
            checkinRadius: !checkinRadius ? 250 : commonUtil.feetToMeters(checkinRadius)
          }

          if (addressRequired) eventData.state = state
          if (community && community != '') eventData.community = commonUtil.makeBendRef(community, 'community')
          if (_geoloc && _geoloc[0] && _geoloc[1] && _geoloc[0] != '' && _geoloc[1] != '') eventData._geoloc = [parseFloat(_geoloc[0]), parseFloat(_geoloc[1])]

          if (segments && segments.length > 0) eventData.segments = commonUtil.getIdList(segments)
          if (categories.length > 0) eventData.categories = commonUtil.getIdList(categories)
          if (coverImage) eventData.coverImage = commonUtil.makeBendFile(coverImage._id)
          if (sponsorLogo) eventData.sponsorLogo = commonUtil.makeBendFile(sponsorLogo._id)

          $scope.isSaving = true
          try {
            if ($scope.eventId) {
              await eventTemplates.helpers.update({ ...template, event: eventData })
              applyChangesOnScope($scope, () => {
                $route.reload()
                $location.path('/eventTemplates')
              })
            } else {
              await eventTemplates.helpers.create({ ...template, event: eventData })
              $route.reload()
              $location.path('/eventTemplates')
            }
          } catch (error) {
            $scope.isSaving = false
          }
        }
      }
    }]
  )
