import { Action } from '../actions/list'
import {
  UPDATE_CATEGORIES_LIST
} from '../constants/types'

export interface Category {
}

export type Categories = Array<any>

const initialState: Categories = []

export default (state: Categories = initialState, action: Action | any): Categories => {
  switch (action.type) {
    case UPDATE_CATEGORIES_LIST:
      return action.categories
    default:
      return state
  }
}
