/* eslint no-useless-escape: 0 */
'use strict'

import * as communities from '../../../src/features/communities'

angular.module('app.controllers')
  .controller('CommunityConfigController', ['$scope', '$location', '$routeParams', 'BendAuth', 'CommonUtil', '$rootScope', '$modal', '$ngRedux',
    function ($scope, $location, $routeParams, BendAuth, CommonUtil, $rootScope, $modal, $ngRedux) {
      $scope.isLoading = true
      $scope.CommonUtil = CommonUtil
      $scope.community = {
        enableNewChat: true,
        profileBioEnabled: true,
        profileFacebookEnabled: true,
        profileTwitterEnabled: true,
        profileSnapchatEnabled: true,
        profileLinkedinEnabled: true,
        profileInstagramEnabled: true,
        profileTelephoneEnabled: true,
        profileSegmentsEnabled: true,
        profileRoleEnabled: true,
        profileIndustryEnabled: true,
        profileOrganizationEnabled: true,
        profileSchoolEnabled: true,

        profileQuickstatsEnabled: true,
        profileQuickstatsPointsEnabled: true,
        profileQuickstatsHoursEnabled: true,
        profileQuickstatsLeaderboardEnabled: true,
        profileQuickstatsConnectionsEnabled: true,

        promptForRace: true,
        promptForGender: true,
        promptForZipCode: true,
        promptForBirthday: true,
        pointsGem: false,
        teamsEnabled: false,
        segmentsEnabled: false
      }

      $scope.communityId = null
      const activeUser = BendAuth.getActiveUser()
      if (activeUser && activeUser.community && activeUser.community._id && (activeUser.communityAdmin || activeUser.isAdmin)) { $scope.communityId = activeUser.community._id }

      $scope.form = {}

      if ($scope.communityId) {
        communities.helpers.get($scope.communityId).then((ret) => {
          $scope.community = Object.assign($scope.community, ret)
          $scope.isLoading = false
        })
      }

      $scope.saveCommunityDo = async () => {
        const data = _.clone($scope.community)
        if (data._id) {
          delete data.$$hashKey
          await communities.helpers.update(data)
        }
        applyChangesOnScope($scope, () => {
          $location.path('/')
        })
      }

      $scope.canSubmitValidationForm = function () {
        return $scope.form.validateForm.$valid
      }
    }])
