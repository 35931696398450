import BendClient, { BendTable } from '../BendClient/index'
import * as actions from './actions'
import redux from '../../helpers/redux'

class Feature {

    private api: any
    private api2: any

    constructor() {
        this.api = BendClient
        this.api2 = BendTable
    }

    public async init() {
        const { list } = redux.getState().schools
        const schoolsList = await this.api.fetchSchools()
        if (list.length === 0) this.toggleLoading(true)

        redux.dispatch(actions.list.update(schoolsList))
        redux.dispatch(actions.settings.update(schoolsList.length))
        this.toggleLoading(false)
    }

    public create(data) {
        this.toggleLoading(true)
        return this.api2.create(BendTable.Tables.SCHOOLS, data)
    }

    public update(data) {
        const newData = { ...data }
        delete newData.$$hashKey

        this.toggleLoading(true)
        return this.api2.update(BendTable.Tables.SCHOOLS, newData)
    }

    public get(id) {
        return this.api2.get(BendTable.Tables.SCHOOLS, id)
    }

    public delete(id) {
        this.toggleLoading(true)
        return this.api2.delete(BendTable.Tables.SCHOOLS, id).then(() => this.init())
    }

    public toggleLoading(bool) {
        redux.dispatch(actions.settings.toggleLoading(bool))
    }

    public isInUse(schoolId) {
        return this.api.isSchoolInUse(schoolId);
    }


}

export default new Feature()
