import {
  UPDATE_GOALS_LIST,
} from '../constants/types'
import { Goals } from '../reducers/list'

export type Update = {
  type: string,
  goals: Goals
}
export type Action = Update

export const update = (goals: Goals): Update => {
  return {
    type: UPDATE_GOALS_LIST,
    goals
  }
}