import {
  UPDATE_GROUPS_SETTINGS,
  UPDATE_GROUPS_PAGE_SETTINGS,
  UPDATE_GROUPS_KEYWORD_SETTINGS,
  UPDATE_GROUPS_COMMUNITY_SETTINGS,
  UPDATE_GROUPS_SORTING_SETTINGS,
  RESET_GROUPS_SETTINGS
} from '../constants/types'

import { Action } from '../actions/settings'

export interface Settings {
  searchTerm: any,
  itemsPerPage: number,
  isLoading: boolean,
  totalItems: number,
  currentPage: number,
  numPages: number,
  community: any,
  sortKey: string,
  sortDirection: Object
}

const initialState: Settings = {
  searchTerm: '',
  itemsPerPage: 20,
  isLoading: true,
  totalItems: 0,
  currentPage: 1,
  numPages: 0,
  community: {
    _id: null
  },
  sortKey: '',
  sortDirection: {
    totalUsers: null,
    activeUsers: null,
    name: null
  }
}

export default (state: Settings = initialState, action: Action): Settings => {
  switch (action.type) {
    case UPDATE_GROUPS_SETTINGS:
      return {
        ...state,
        totalItems: action.count,
        numPages: Math.ceil(action.count / state.itemsPerPage + 1)
      }
    case UPDATE_GROUPS_PAGE_SETTINGS:
      return {
        ...state,
        currentPage: action.page
      }
    case UPDATE_GROUPS_KEYWORD_SETTINGS:
      return {
        ...state,
        searchTerm: action.keyword
      }
    case UPDATE_GROUPS_COMMUNITY_SETTINGS:
      return {
        ...state,
        community: action.community
      }
    case UPDATE_GROUPS_SORTING_SETTINGS:
      return {
        ...state,
        sortKey: action.sortKey,
        sortDirection: action.sortDirection
      }
    case RESET_GROUPS_SETTINGS:
      return initialState
    default:
      return state
  }
}
