import * as _ from 'lodash'
import * as actions from './actions'
import BendClient, { BendTable } from '../BendClient'
import commonUtil from '../../helpers/commonUtil'
import * as Bend from '../../lib/bend-1.1.8'
import redux from '../../../src/helpers/redux'
import { getActiveUser } from '../../helpers'
import async from 'async'

class Feature {

  private api: any
  private api2: any

  constructor () {
    this.api = BendClient
    this.api2 = BendTable
  }

  public async reset () {
    redux.dispatch(
      actions.settings.reset()
    )
  }

  public async export () {
    // const settings = redux.getState().users.settings
    const studioApps = await this.api2.getList(BendTable.Tables.STUDIO_APP, { notYetExported: true })
    let exportData: Array<any> = []
    studioApps.map(app => {
      const {
        contactName,
        contactEmail,
        contactPhone,
        organization,
        features,
        category1,
        category2,
        category3,
        category4,
        url,
        innerWidth
      } = app
      const {
        actions,
        push,
        volunteerops,
        chat,
        checkin,
        polls,
        socialMedia,
        events,
        sponsorships,
        donations
      } = features
      let categoryArr: Array<string> = [];
      [category1, category2, category3, category4].forEach(cat => {
        if (cat !== '(Enter category here)') categoryArr.push(cat)
      })
      let categories: string = categoryArr.join('-')

      // choosing what campaign to put them in for zapier integration
      let campaign
      let featureNum = 0
      Object.keys(features).map(f => {
        if (features[f]) featureNum++
      })
      if (innerWidth && innerWidth > 1080 && featureNum > 0) {
        campaign = 'studio campaign'
      } else if (featureNum === 0) campaign = 'mobile studio'
      else if (featureNum > 0) campaign = 'studio campaign'

      const dateVisited = commonUtil.formatDateWithFormat(app._bmd.createdAt, "MMM D, 'YY, H:mm")

      const row = {
        contactName,
        contactEmail,
        contactPhone,
        organization,
        push: push ? 'yes' : 'no',
        volunteerops: volunteerops ? 'yes' : 'no',
        actions: actions ? 'yes' : 'no',
        chat: chat ? 'yes' : 'no',
        places: checkin ? 'yes' : 'no',
        polls: polls ? 'yes' : 'no',
        socialMedia: socialMedia ? 'yes' : 'no',
        events: events ? 'yes' : 'no',
        sponsorships: sponsorships ? 'yes' : 'no',
        donations: donations ? 'yes' : 'no',
        categories,
        url,
        campaign,
        dateVisited
      }
      if (!app.exported) exportData.push(row)
    })
    redux.dispatch(
      actions.exportList.update(exportData)
    )
  }

  public async updateExported () {
    let studioApps = redux.getState().studioApps.list
    async.mapLimit(studioApps, 150, (app, cb) => {
      app.exported = true
      delete app.$$hashKey
      this.api2.update(BendTable.Tables.STUDIO_APP, app).then(app => {
        cb(null, null)
      })
    })
  }

  public async cleanApps () {
    this.api2.callEndpoint('studio_helper').then(() => {
      return true
    })
  }

  public async init () {
    let settings = redux.getState().studioApps.settings
    // let studioAppsList: any = []
    const studioAppsList = await this.api2.getList(BendTable.Tables.STUDIO_APP, settings)

    redux.dispatch(
      actions.list.update(studioAppsList)
    )
  }

  public async delete (studioApp) {
    studioApp.deleted = true
    delete studioApp.$$hashKey
    this.api2.update(BendTable.Tables.STUDIO_APP, studioApp).then(app => {
      this.init()
    })
  }

  public paginateToPage (page) {
    redux.dispatch(
      actions.settings.pageUpdate(page)
    )
  }
}

export default new Feature()
