import {
  UPDATE_COMMENTS_LIST
} from '../constants/types'
import { Comments } from '../reducers/list'

export interface Action {
  type: string,
  comments: Comments
}

export const update = (comments: Comments): Action => {
  return {
    type: UPDATE_COMMENTS_LIST,
    comments
  }
}
