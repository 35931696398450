import {
  UPDATE_TASKS_LIST,
  ADD_TASK,
  REMOVE_INCOMPLETE_TASK,
  MARK_TASK_COMPLETE,
  MARK_TASK_INCOMPLETE,
  EDIT_TASK,
  CANCEL_TASK_EDIT,
  SAVE_EDITED_TASK
} from '../constants/types'
import { List, Item } from '../reducers/list'

export interface Action {
  type: string,
  index: number,
  title: string,
  id: string,
  editing: boolean,
  newTask: Item,
  incomplete: List,
  complete: List
}

export const update = (incomplete: List, complete: List) => {
  return {
    type: UPDATE_TASKS_LIST,
    incomplete,
    complete
  }
}

export const add = (newTask: Item) => {
  return {
    type: ADD_TASK,
    newTask
  }
}

export const deleteItem = ({ id }) => {
  return {
    type: REMOVE_INCOMPLETE_TASK,
    id
  }
}

export const complete = ({ id }) => {
  return {
    type: MARK_TASK_COMPLETE,
    id
  }
}

export const incomplete = (index) => {
  return {
    type: MARK_TASK_INCOMPLETE,
    index
  }
}

export const edit = ({ id, title }) => {
  return {
    type: SAVE_EDITED_TASK,
    id,
    title
  }
}

export const itemEditing = ({ id }) => {
  return {
    type: EDIT_TASK,
    id
  }
}

export const cancelEdit = ({ id, title }) => {
  return {
    type: CANCEL_TASK_EDIT,
    id,
    title
  }
}

export const saveEdited = ({ id, title }) => {
  return {
    type: SAVE_EDITED_TASK,
    id,
    title
  }
}
