'use strict'

import * as Bend from '../../../src/lib/bend-1.1.8'

angular.module('app.controllers')
  .controller('SigninController', ['$scope', '$location', '$theme', 'BendAuth', '$rootScope', 'rememberMeService',
    function ($scope, $location, $theme, BendAuth, $rootScope, rememberMeService) {
      $scope.hasError = false
      $scope.errorMessage = ''
      $scope.invaildCredential = false

      $theme.set('fullscreen', true)
      $scope.$on('$destroy', function () {
        $theme.set('fullscreen', false)
      })

      // If already signed in - redirect to main page.
      if (BendAuth.isLoggedIn()) {
        $theme.set('fullscreen', false)
        // $rootScope.initMenu();
        BendAuth.redirectToDashboard()
        return
      }

      $scope.user = {
        orgCode: '',
        username: '',
        password: ''
      }

      $scope.errorMsg = ''

      $scope.submitForm = function () {
        BendAuth.logIn($scope.user, function (error) {
          if (error) {
            $scope.hasError = true
            $scope.errorMessage = error.name

            Bend.User.logout()
            return
          }

          $rootScope.$broadcast('login_initialize')
          $rootScope.initMenu()
          var savedURL = rememberMeService(BendAuth.getActiveUser()._id)
          applyChangesOnScope($scope, function () {
            if (savedURL != null && savedURL != '') {
              $location.path(savedURL)
            } else {
              $location.path('/')
            }
          })
          // BendAuth.redirectToDashboard();
        })
      }
    }])
