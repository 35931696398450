import BendClient, { BendTable } from '../BendClient/index'
import * as actions from './actions'
import redux from '../../../src/helpers/redux'
import * as _ from 'lodash'
import commonUtil from '../../helpers/commonUtil'
import { getActiveUser } from '../../helpers'

const forEachAsync = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

class Feature {

  private api: any
  private api2: any

  constructor () {
    this.api = BendClient
    this.api2 = BendTable
  }

  public async init (communityId) {
    console.time("Teams Init");

    const settings = redux.getState().teams.settings
    settings.communityId = communityId
    const teams = await this.api2.getList(BendTable.Tables.TEAM, settings)
    teams.unshift({ name: 'All Users' })

    const sprint = await this.api.getCurrentSprint(communityId)
    await forEachAsync(teams, async team => {

      team.sprintPoints = 0
      team.avgSprintPoints = 0
      const resp = await this.api.getTeamPoints('points', communityId, team._id)
      const userCount = await this.api.getUserCountByTeamId(team._id)
      team.userCount = userCount
      team.totalPoints = resp.reduce((prev, current) => prev + current.result, 0)
      const avg = team.totalPoints && team.userCount ? team.totalPoints / team.userCount : 0
      team.avgTotalPoints = Math.round(avg).toFixed(0)
      if (sprint && sprint._id) {
        const resp = await this.api.getTeamPoints('points', communityId, team._id, sprint)
        team.sprintUserCount = resp.length
        team.sprintPoints = resp.reduce((prev, current) => prev + current.result, 0)
        const avgS = team.sprintPoints && team.sprintUserCount ? team.sprintPoints / team.sprintUserCount : 0
        team.avgSprintPoints = Math.round(avgS).toFixed(0)
      }

    })

    //console.log('Teams', teams)
    console.timeEnd("Teams Init");

    redux.dispatch(
      actions.list.update(teams)
    )
    redux.dispatch(
      actions.settings.update(teams.length)
    )
    return teams
  }

  public async getList (id = getActiveUser().community._id) {
    console.time("Teams getList");
    const teams = await this.api2.getList(BendTable.Tables.TEAM, { communityId: id })
    console.timeEnd("Teams getList");

    redux.dispatch(
      actions.list.update(teams)
    )
    redux.dispatch(
      actions.settings.update(teams.length)
    )
    return teams
  }

  public async sortBy (key, direction) {
    let teams = redux.getState().teams.list.teams
    let sortedList = commonUtil.sortBy(teams, key, direction)
    redux.dispatch(
      actions.list.update(sortedList)
    )
  }

  public updateTeamsMembers () {
    const teamList = redux.getState().teams.list.teams
    const userList = redux.getState().users.list
    let teamsMembers: any = {}
    ;(teamList || []).forEach((hm, team) => {
      (userList || []).forEach((hm, user) => {
        if (userList[user].teams
            && userList[user].teams.indexOf(teamList[team]._id) !== -1
        ) { // if team id exists on user
          if (teamsMembers[teamList[team]._id]) { // if team id key exists
            teamsMembers[teamList[team]._id].push(userList[user]._id) // add user id to array at key of team id
          } else {
            teamsMembers[teamList[team]._id] = [userList[user]._id] // create key of team id with value user id in array
          }
        }
      })
    })
    redux.dispatch(
      actions.list.updateTeamsMembers(teamsMembers)
    )
  }

  public getTeamNames (ids: Array<string> = [], teams: Array<any> = []) {
    const names: Array<string> = []
    teams.map(team => {
      ids.map(id => {
        if (team._id === id) {
          names.push(team.name)
        }
      })
    })
    return names.join(', ')
  }

  public paginateToPage (page) {
    redux.dispatch(
      actions.settings.pageUpdate(page)
    )
  }

  public handleSearchKeyword (keyword) {
    redux.dispatch(
      actions.settings.searchKeywordUpdate(keyword)
    )
  }

  public selectCommunity (id) {
    let selected = redux.getState().communities.list.find(item => item._id === id)
    redux.dispatch(
      actions.settings.communityUpdate(selected)
    )
  }

  public getCommunityList () {
    return this.api.getCommunityList()
  }

  public get (id) {
    return this.api2.get(BendTable.Tables.TEAM, id)
  }

  public create (data) {
    return this.api2.create(BendTable.Tables.TEAM, data)
  }

  public update (data) {
    return this.api2.update(BendTable.Tables.TEAM, data)
  }

  public delete (id) {
    return this.api2.delete(BendTable.Tables.TEAM, id)
  }

}

export default new Feature()
