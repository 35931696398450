import { Action } from '../actions/list'
import {
  UPDATE_MENU_LIST,
  UPDATE_MENU_MASTER,
  UPDATE_MENU_DEFAULT
} from '../constants/types'

export interface Item {

}

export type Menu = Array<Item>

const initialState: Menu = []

export default (state: Menu = initialState, action: Action): Menu => {
  switch (action.type) {
    case UPDATE_MENU_DEFAULT:
      return defaultList
    case UPDATE_MENU_LIST:
      return action.list
    case UPDATE_MENU_MASTER:
      return masterList
    default:
      return state
  }
}

export const defaultList = [
  {
    'url': '#/',
    'iconClasses': 'glyphicon glyphicon-home',
    'type': 'both',
    'label': 'Dashboard'
  },
  {
    'separator': true,
    'iconClasses': '',
    'type': 'both',
    'label': 'User Management'
  },
  {
    'url': '#/moderation',
    'iconClasses': 'fa fa-check-square-o',
    'type': 'both',
    'label': 'Moderation'
  },
  {
    'url': '#/users',
    'iconClasses': 'glyphicon glyphicon-user',
    'type': 'both',
    'label': 'Users'
  },
  {
    'url': '#/groups',
    'iconClasses': 'fa fa-users',
    'type': 'both',
    'label': 'User Groups (BETA)'
  },
  {
    'url': '#/teams',
    'iconClasses': 'fa fa-users',
    'type': 'both',
    'label': 'Teams'
  },
  {
    "children": [
        {
            "url": "#/segmentTypes",
            "label": "Segment Types"
        },
        {
            "url": "#/segments",
            "label": "Segments"
        }
    ],
    "iconClasses": "fa fa-users",
    "type": "both",
    "label": "Segments"
  },
  {
    'url': '#/organizations',
    'iconClasses': 'glyphicon glyphicon-briefcase',
    'type': 'both',
    'label': 'Organizations (BETA)'
  },
  {
    'url': '#/schools',
    'iconClasses': 'fa fa-school',
    'type': 'both',
    'label': 'Schools (BETA)'
  },
  {
    'separator': true,
    'iconClasses': '',
    'type': 'both',
    'label': 'Communication Tools'
  },
  {
    'children': [
      {
        'url': '#/pushes',
        'label': 'Push Notifications'
      },
      {
        'url': '#/templates',
        'label': 'Templates'
      }
    ],
    'iconClasses': 'glyphicon glyphicon-bell',
    'type': 'both',
    'label': 'Push Notifications'
  },
  {
    'url': '#/dailyPolls',
    'iconClasses': 'fa fa-question-circle-o',
    'type': 'both',
    'label': 'Daily Polls'
  },
  {
    'children': [
      {
        'url': '#/surveys',
        'label': 'Surveys (BETA)'
      },
      {
        'url': '#/surveyQuestions',
        'label': 'Survey Questions (BETA)'
      }
    ],
    'iconClasses': 'fa fa-question-circle-o',
    'type': 'both',
    'label': 'Surveys (BETA)'
  },
  {
    'url': '#/goals',
    'iconClasses': 'glyphicon glyphicon-user',
    'type': 'both',
    'label': 'Goals'
  },
  {
    'url': '#/challenges',
    'iconClasses': 'fa fa-cog',
    'type': 'both',
    'label': 'Challenges'
  },
  {
    'separator': true,
    'label': 'Data'
  },
  {
    'url': '#/charts',
    'iconClasses': 'fa fa-bar-chart',
    'type': 'both',
    'label': 'Charts'
  },
  {
    'url': '#/leaderboards',
    'iconClasses': 'fa fa-table',
    'type': 'both',
    'label': 'Leaderboards'
  },
  {
    'url': '#/activities',
    'iconClasses': 'fa fa-eye',
    'type': 'both',
    'label': 'User Activity'
  },
  {
    'separator': true,
    'iconClasses': '',
    'type': 'both',
    'label': 'Create Activities'
  },
  {
    'url': '#/businesses',
    'iconClasses': 'glyphicon glyphicon-th-large',
    'type': 'both',
    'label': 'Places'
  },
  {
    'children': [
      {
        'url': '#/events',
        'label': 'Events'
      },
      {
        'url': '#/eventTemplates',
        'label': 'Templates'
      }
    ],
    'iconClasses': 'glyphicon glyphicon-calendar',
    'type': 'both',
    'label': 'Events (BETA)'
  },
  {
    'url': '#/actions',
    'iconClasses': 'glyphicon glyphicon-tasks',
    'type': 'both',
    'label': 'Actions'
  },
  {
    'url': '#/volunteerings',
    'iconClasses': 'glyphicon glyphicon-heart',
    'type': 'both',
    'label': 'Volunteering'
  },
  {
    'url': '#/customActivities',
    'iconClasses': 'glyphicon glyphicon-tasks',
    'type': 'both',
    'label': 'Custom Activity'
  },
  {
    'separator': true,
    'iconClasses': '',
    'type': 'both',
    'label': 'Configuration'
  },
  {
    'url': '#/sprints',
    'iconClasses': 'fa fa-calendar',
    'type': 'both',
    'label': 'Sprints'
  },
  {
    'url': '#/whitelabel',
    'iconClasses': 'glyphicon glyphicon-list-alt',
    'type': 'both',
    'label': 'App Store (BETA)'
  },
  {
    'separator': true,
    'iconClasses': '',
    'type': 'both',
    'label': 'Legacy'
  },
  {
    'url': '#/legacy/events',
    'iconClasses': 'glyphicon glyphicon-calendar',
    'type': 'both',
    'label': 'Events'
  }]

const masterList = [{
  label: 'User Management',
  iconClasses: '',
  separator: true,
  type: 'both'
}, {
  label: 'Dashboard',
  iconClasses: 'glyphicon glyphicon-home',
  url: '#/',
  type: 'both'
}, {
  label: 'Users',
  iconClasses: 'glyphicon glyphicon-user',
  url: '#/users',
  type: 'both'
}, {
  label: 'User Activity',
  iconClasses: 'fa fa-eye',
  url: '#/activities',
  type: 'both'
}, {
  label: 'Leaderboards',
  iconClasses: 'fa fa-table',
  url: '#/leaderboards',
  type: 'both'
}, {
  label: 'MilkCrate Admin',
  iconClasses: '',
  separator: true
}, {
  label: 'Studio Apps',
  iconClasses: 'glyphicon glyphicon-globe',
  url: '#/studioApps',
  type: 'both'
},{
  label: 'Google Data Studio',
  iconClasses: 'glyphicon glyphicon-globe',
  url: '#/gds',
  type: 'both'
}, {
  label: 'Clients',
  iconClasses: 'glyphicon glyphicon-globe',
  url: '#/communities',
  type: 'both'
}, {
  label: 'Categories',
  iconClasses: 'glyphicon glyphicon-tags',
  url: '#/categories',
  type: 'both'
}, {
  label: 'Collections',
  iconClasses: 'glyphicon glyphicon-link',
  url: '#/collections',
  type: 'both'
}, {
  label: 'Certifications',
  iconClasses: 'glyphicon glyphicon-bookmark',
  url: '#/certifications',
  type: 'both'
}, {
  label: 'Calendars',
  iconClasses: 'fa fa-calendar',
  type: 'both',
  children: [{
    label: 'Challenges',
    url: '#/challengeCalendar'
  }]
}, {
  label: 'Utilities',
  iconClasses: 'fa fa-wrench',
  url: '#/utilities',
  type: 'both'
}]
