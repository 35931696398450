import { UPDATE_NEWEST_USERS_LIST } from '../constants/types'
import { List } from '../reducers/list'

export interface Action {
  type: string,
  newestUsers: List
}

export const update = (newestUsers: List): Action => {
  return {
    type: UPDATE_NEWEST_USERS_LIST,
    newestUsers
  }
}
