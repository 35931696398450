import { Action } from '../actions/list'
import {
  UPDATE_TEAMS_LIST,
  UPDATE_TEAMS_MEMBERS,
  UPDATE_TEAMS_ITEM
} from '../constants/types'

export interface Item {
}
export type Teams = Array<any>

export type List = {
  teams: Teams,
  teamsMembers: any
}

const initialState = {
  teams: [],
  teamsMembers: {}
}

export default (state: List = initialState, action: Action | any): List => {
  // console.log('team reducer', action)
  switch (action.type) {
    case UPDATE_TEAMS_LIST:
      return {
        ...state,
        teams: action.teams
      }
    case UPDATE_TEAMS_ITEM:
      return {
        ...state,
        teams: [
          ...state.teams.map((i) => {
            if (i._id !== action.id) {
              return i
            }
            return {
              ...i,
              userCount: action.userCount
            }
          })
        ]
      }
    case UPDATE_TEAMS_MEMBERS:
      return {
        ...state,
        teamsMembers: action.teamsMembers
      }

    default:
      return state
  }
}
