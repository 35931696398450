/* eslint-disable standard/no-callback-literal */
'use strict'

import * as Bend from '../../../src/lib/bend-1.1.8'
import * as users from '../../../src/features/users'

angular
  .module('app.auth', [])
  .factory('BendAuthBootstrap', ['$bend', function ($bend) {
    return {
      APP_KEY: '589d36e94bad3014f50128ce',
      APP_SECRET: 'deduKe8DAuA1ry2cYYQXSQEFHgZy9qTvrL0D2lsc',
      APP_ADMIN_GROUP: 'admin',
      APP_URL: 'https://api.bend.io/group/', // XXX APP_URL name isn't accurate

      checkAuthToken: function (callback) {
        callback = callback || function () {}

        var expiresIn = 1 * 60 * 60 * 1000 // ~1 hours.
        Bend.appKey = this.APP_KEY
        $bend.LocalStorage.get('tokenLastUsedTime').then(function (timestamp) {
          if (timestamp) {
            var diff = Date.now() - parseInt(timestamp)
            if (diff > expiresIn) {
              $bend.LocalStorage.destroy('activeUser')
            }
          }
          callback(null)
        }, function (error) { callback(error) })
      },
      bootstrapService: function () {
        this.checkAuthToken(function (error) {
          if (error) {
          }
          users.helpers.initBend().then(
            function (activeUser) {
              angular.bootstrap(document.getElementById('app'), ['themesApp'])
              if (activeUser) {
              }
            },
            function (error) {
              angular.bootstrap(document.getElementById('app'), ['themesApp'])
            }
          )
        })
      }
    }
  }])
  .factory('rememberMeService', function () {
    function fetchValue (name) {
      var gCookieVal = document.cookie.split('; ')
      for (var i = 0; i < gCookieVal.length; i++) {
        // a name/value pair (a crumb) is separated by an equal sign
        var gCrumb = gCookieVal[i].split('=')
        if (name === gCrumb[0]) {
          var value = ''
          try {
            value = angular.fromJson(gCrumb[1])
          } catch (e) {
            value = unescape(gCrumb[1])
          }
          return value
        }
      }
      // a cookie with the requested name does not exist
      return null
    }
    return function (name, values) {
      if (arguments.length === 1) return fetchValue(name)
      var cookie = name + '='
      if (typeof values === 'object') {
        var expires = ''
        cookie += (typeof values.value === 'object') ? angular.toJson(values.value) + ';' : values.value + ';'
        if (values.expires) {
          var date = new Date()
          date.setTime(date.getTime() + (values.expires * 24 * 60 * 60 * 1000))
          expires = date.toGMTString()
        }
        cookie += (!values.session) ? 'expires=' + expires + ';' : ''
        cookie += (values.path) ? 'path=' + values.path + ';' : ''
        cookie += (values.secure) ? 'secure;' : ''
      } else {
        cookie += values + ';'
      }
      document.cookie = cookie
    }
  })
  .factory('BendAuth', ['$location', '$bend', 'BendAuthBootstrap', '$rootScope',
    function ($location, $bend, BendAuthBootstrap, $rootScope) {
      var BendAuth = {}

      BendAuth.APP_KEY = BendAuthBootstrap.APP_KEY
      BendAuth.APP_SECRET = BendAuthBootstrap.APP_SECRET
      BendAuth.GROUP_ID = BendAuthBootstrap.APP_ADMIN_GROUP
      BendAuth.URL = BendAuthBootstrap.APP_URL

      BendAuth.bootstrapService = function () {
        users.helpers.initBend().then(function () {
          angular.bootstrap(document.getElementById('app'), ['themesApp'])
        })
      }

      BendAuth.checkAuth = function () {
        if (!this.isLoggedIn()) {
          return this.redirectToLogin()
        } else {
          var user = this.getActiveUser()

          $rootScope.globals.admin = {
            name: (_.isUndefined(user.fullName)) ? user.username : user.fullName,
            id: user._id,
            avatarUrl: '/assets/img/avatarPlaceholder.png'
          }

          if (user.avatar instanceof Object) {
            users.helpers.getAvatar().then(function (res) {
              if (res.length > 0) {
                const file = _.get(res, '[0]._downloadURL')
                $rootScope.globals.admin.avatarUrl = file
              }
            })
          }
        }
      }

      BendAuth.getActiveUser = function () {
        return Bend.getActiveUser()
      }

      BendAuth.init = function () {
        return users.helpers.initBend()
      }

      BendAuth.isLoggedIn = function () {
        return Bend.getActiveUser() != null
      }

      BendAuth.checkEmail = function (username, callback) {
        $bend.User.exists(username).then(function (ret) {
          callback(Boolean(ret))
        }, function () {

        })
      }

      BendAuth.logIn = function (loginData, callback) {
        callback = callback || function () {}
        var credentials = {
          username: loginData.username,
          password: loginData.password
        }

        users.helpers.login(credentials).then(
          function (res) {
            if (res.deleted) {
              callback({
                name: 'User not found.'
              })
              BendAuth.logOut()
              return
            }
            if (res.communityAdmin) {
              callback(null)
            } else {
              BendAuth.isAdmin(res, function (isAdmin) {
                var data = isAdmin ? null : {
                  name: 'Insufficient privileges.'
                }
                callback(data)
                if (!isAdmin) {
                  BendAuth.logOut()
                }
              })
            }
          },
          function () {
            var data = {
              name: 'The username or password is incorrect.'
            }
            callback(data)
          }
        )
      }

      BendAuth.logOut = function () {
        if (this.isLoggedIn()) {
          return Bend.User.logout({
            success: function () {
              localStorage.clear()
              BendAuth.redirectToLogin()
              // $bend.Sync.destruct() // not implemented
            },
            failure: function () {
              return $location.path('/')
            }
          })
        }
        // we want to run this anyway if someone requests a logout
        localStorage.clear()
        location.reload(true)
      }

      BendAuth.redirectToDashboard = function () {
        return $location.path('/')
      }

      BendAuth.redirectToLogin = function () {
        if ($location.$$url !== '/signin') {
          return $location.path('/signin')
        }
      }

      BendAuth.isAdmin = function (user, callback) {
        if (!user || typeof user !== 'object') {
          return false
        }
        var authString = 'Bend ' + user._bmd.authtoken
        $.ajax({
          url: BendAuth.URL + BendAuth.APP_KEY + '/' + BendAuth.GROUP_ID,
          headers: {'Authorization': authString}
        }).then(function (ret) {
          var adminList = ret.users.list
          var adminUser = _.find(adminList, function (o) {
            return o._id == user._id
          })
          callback(Boolean(adminUser))
        }, function () {
          var data = false
          callback(data)
        })
      }

      BendAuth.setAdmin = function (user, isAdmin, callback) {
        callback = callback || function () {}
      }
      return BendAuth
    }
  ])
