'use strict'

import * as users from '../../../src/features/users'
import * as activities from '../../../src/features/activities'
import * as teams from '../../../src/features/teams'
import * as segments from '../../../src/features/segments'
import * as groups from '../../../src/features/groups'
import * as communities from '../../../src/features/communities'

import commonUtil from '../../../src/helpers/commonUtil'
import { getActiveUser } from '../../../src/helpers'
import genericHelper from '../../../src/helpers/GenericHelper'

angular.module('app.controllers')
  .controller('UserEditController', ['$scope', '$location', '$routeParams', 'BendAuth', '$modal', '$ngRedux',
    async function ($scope, $location, $routeParams, BendAuth, $modal, $ngRedux) {
      // Init.
      $scope.genericHelper = genericHelper
      $scope.commonUtil = commonUtil
      if ($routeParams.id !== 'new') $scope.userId = $routeParams.id

      $scope.formData = {
        teams: [],
        userGroup: null,
        segments: []
      }

      $scope.goals = {
        started: 0,
        completed: 0
      }
      const formDataKeys = Object.keys($scope.formData)
      const mapStateToThis = (state) => {
        if (state.teams.list && state.teams.list.length > 0 && $scope.user.teams && $scope.user.teams.length > 0) {
          $scope.formData.teams = _.filter($scope.teams, (o) => $scope.user.teams.indexOf(o._id) != -1)
        }
        if (state.segments.list.segments && state.segments.list.segments.length > 0 && $scope.user.segments && $scope.user.segments.length > 0) {
        $scope.formData.segments = _.filter($scope.segments, (o) => $scope.user.segments.indexOf(o._id) != -1)
        }
        if (state.groups.list.groups && state.groups.list.length > 0 && $scope.user.userGroup && $scope.user.userGroup._id) {
          $scope.formData.userGroup = $scope.groups.groups.filter((group) => group._id === $scope.user.userGroup._id)
        }
        return {
          segments: state.segments.list,
          groups: state.groups.list,
          teams: state.teams.list.teams
        }
      }

      await teams.helpers.getList()

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      $scope.communityList = []
      $scope.communityList2 = []

      $scope.communityList = await users.helpers.getCommunityEnabledList()

      $scope.openedDateSelector = [false]
      $scope.isLoading = true
      $scope.fileProgress = []
      $scope.isUploading = []
      $scope.tagList = []
      $scope.emailError = ''
      $scope.errorMsg = []

      $scope.collectionView = {
        searchTerm: '',
        itemsPerPage: 20,
        isLoading: true,
        totalItems: 0,
        currentPage: 1,
        numPages: 0
      }
      $scope.form = {}
      $scope.teamList = []
      $scope.activeUser = await getActiveUser()
      $scope.communityAdmin = $scope.activeUser.communityAdmin

      if ($scope.userId) {
        $scope.user = await users.helpers.get($scope.userId)
        // communities.helpers.get($scope.user.community._id)
      } else {
        $scope.user = {}
        if (!$scope.user.community) $scope.user.community = $scope.activeUser.community

        $scope.user.gender = $scope.user.gender || 'Prefer not to say'

        if ($scope.user.birthdate) {
          $scope.BirthDate = $scope.user.birthdate // commonUtil.formatDateWithFormat(ret.birthdate,"YYYY-MM-DD");
        }

        $scope.user.email = $scope.user.email || $scope.user.username

        users.helpers.getAdminList(BendAuth, (err, adminList) => {
          const exist = _.find(adminList, (o) => o._id == $scope.userId)
          if (exist) { $scope.user.isAdmin = true } else { $scope.user.isAdmin = false }
        })
      }

      communities.helpers.init(false).then((communities) => {
        communities.forEach((com) => {
          if (com._id === $scope.user.community._id) {
            $scope.user.code = com.code
            $scope.community = com
          }
        })
      })

      teams.helpers.getList().then((teams) => {
        const userTeams = $scope.user.teams
        if (userTeams && userTeams.length) {
          $scope.formData.teams = teams.filter((team) => userTeams.indexOf(team._id) !== -1)
        }
      })

      segments.helpers.init().then((segments) => {
        const userSegments = $scope.user.segments
        if (userSegments) {
          $scope.formData.segments = segments.filter((segment) => userSegments.indexOf(segment._id) !== -1)
        }
      })

      groups.helpers.getList().then((groups) => {
        const userGroup = $scope.user.userGroup

        if (userGroup && userGroup._id) {
          const selectedGroup = groups.filter((group) => group._id == userGroup._id) //This shouold always be an rray of length 1
          if (selectedGroup.length > 0){
            $scope.formData.userGroup = selectedGroup[0]
          }
        }
      })

      // segments.helpers.init().then(segments => {
      //   let userSegments = $scope.user.segments
      //   if (userSegments && userSegments.length) {
      //     $scope.formData.segments = segments.filter(segment => {
      //       return userSegments.indexOf(segment._id) !== -1
      //     })
      //   }
      // })

      $scope.toggleCommunityAdmin = () => {
        $scope.user.communityAdmin = !$scope.user.communityAdmin
      }
      // to make a user a milkcrate admin their ID needs to be put into a special admin user id array
      $scope.toggleMilkCrateAdmin = () => {
        $scope.user.isAdmin = !$scope.user.isAdmin
      }

      $scope.getSegmentName = (segment) => segments.helpers.getSegmentName(segment)

      $scope.saveUserDo = async () => {
        $scope.emailError = ''
        $scope.errorMsg = []

        formDataKeys.map((key) => {
          if ($scope.formData[key] && Array.isArray($scope.formData[key]) && $scope.formData[key].length > 0) {
            $scope.user[key] = $scope.formData[key].map((obj) => obj._id)
          } else {
            delete $scope.user[key]
          }
        })

        const userData = _.clone($scope.user)
        if (!userData.community) {
          $scope.errorMsg.push('Please save user with a community')
          return
        }
        if (userData.community != '') {
          if (!userData.code) {
            userData.code = $scope.community.code
          }
          if (userData.community && !userData.community._id) {
            userData.community = commonUtil.makeBendRef(userData.community, 'community')
          } else if (userData.community && userData.community._id) {
            userData.community = commonUtil.makeBendRef(userData.community._id, 'community')
          }
        } else {
          delete userData.community
        }
        if (typeof $scope.BirthDate === 'object') {
          userData.birthdate = commonUtil.formatDateWithFormat($scope.BirthDate.getTime() * 1000000, 'YYYY-MM-DD')
        }
        if (!userData.gender || userData.gender == '') {
          delete userData.gender
        } else {
          userData.gender = userData.gender.toLowerCase()
        }
        if (userData.avatar) { userData.avatar = commonUtil.makeBendFile(userData.avatar._id) }
        if (userData.coverImage) { userData.coverImage = commonUtil.makeBendFile(userData.coverImage._id) }

        if ($scope.formData.userGroup && $scope.formData.userGroup._id) { 
          userData.userGroup = commonUtil.makeBendRef($scope.formData.userGroup._id, 'groups') 
        }

        if (userData.email != userData.username) {
          userData.username = userData.email
        }

        if (!userData.coachingInfo || userData.coachingInfo == '') {
          delete userData.coachingInfo
        }
        if (!userData.profileBio || userData.profileBio == '') {
          delete userData.profileBio
        }
        if (!userData.profileUrl || userData.profileUrl == '') {
          delete userData.profileUrl
        }
        if (!userData.profileFacebook || userData.profileFacebook == '') {
          delete userData.profileFacebook
        }
        if (!userData.profileTwitter || userData.profileTwitter == '') {
          delete userData.profileTwitter
        }
        if (!userData.profileInstagram || userData.profileInstagram == '') {
          delete userData.profileInstagram
        }

        if (!userData.profileTelephone || userData.profileTelephone == '') {
          delete userData.profileTelephone
        }

        // var teams = []
        // _.map($scope.formData.teams, function (o) {
        //   teams.push(o._id)
        // })

        // userData.teams = teams
        if (userData._id) {
          const { user } = await users.helpers.update(userData)
          $scope.user = user
        } else {
          $scope.viewChangePassword = true
          const { user } = await users.helpers.create(userData)
          user.password = ''
          $scope.user = user
          return
        }

        applyChangesOnScope($scope, () => {
          const error = _.get($scope.user, 'name') === 'RequestError'
          if (error) {
            $scope.emailError = 'required'
            $scope.errorMsg.push('A user already exists for this email address')
          } else {
            $location.path('/users')
            users.helpers.setUserAsAdmin(BendAuth, userData._id, userData.isAdmin, () => {})
          }
        })
      }
      $scope.openDateWindow = function ($event, idx) {
        $event.preventDefault()
        $event.stopPropagation()

        $scope.openedDateSelector[idx] = true
      }

      $scope.deleteUser = async () => {
        const yeap = confirm('Deleting the user is permanent and can not be undone.')
        if (!yeap) {
          return false
        }
        await users.helpers.delete($scope.userId)
        applyChangesOnScope($scope, () => {
          $location.path('/users')
        })
      }

      $scope.deleteFile = function (tag, $ev) {
        $ev.stopPropagation()
        $ev.preventDefault()
        if (tag == 'userAvatar') {
          applyChangesOnScope($scope, () => {
            delete $scope.user.avatar
          })
        }
        if (tag == 'coverImage') {
          applyChangesOnScope($scope, () => {
            delete $scope.user.coverImage
          })
        }
      }

      $scope.showFileLoading = function (tag, bShow) {
        $scope.isUploading[tag] = bShow
      }

      $scope.selectFileOpen = function (fileId, $ev) {
        if ($ev.target.tagName == 'DIV') {
          setTimeout(() => {
            $('#' + fileId).click()
          }, 0)
        }
      }

      $scope.onFileUpload = function (files, tag) {
        const file = files[0]
        $scope.fileProgress[tag] = 0
        $scope.showFileLoading(tag, true)
        if (tag == 'userAvatar') {
          users.helpers.upload(file, (error, uploadedFile) => {
            if (error) {
              $scope.showFileLoading(tag, false)
              return
            }

            users.helpers.getFile(uploadedFile, (o) => {
              $scope.user.avatar = o
              $scope.showFileLoading(tag, false)
            })
          }, {
            _workflow: 'avatar'
          }, (total, prog) => {
            applyChangesOnScope($scope, () => {
              $scope.fileProgress[tag] = prog * 100 / total
            })
          })
        } else if (tag == 'coverImage') {
          users.helpers.upload(file, (error, uploadedFile) => {
            if (error) {
              $scope.showFileLoading(tag, false)
              return
            }
            users.helpers.getFile(uploadedFile, (o) => {
              $scope.user.coverImage = o
              $scope.showFileLoading(tag, false)
            })
          }, {
            _workflow: 'coverPhoto'
          }, (total, prog) => {
            applyChangesOnScope($scope, () => {
              $scope.fileProgress[tag] = prog * 100 / total
            })
          })
        }
      }

      $scope.changePassword = function () {
        $scope.user.password = ''
        $scope.user.passwordConfirm = ''
        $scope.viewChangePassword = true
      }

      $scope.cancelPassword = function () {
        $scope.viewChangePassword = false
      }

      $scope.changePasswordDo = async () => {
        if ($scope.user.community) {
          delete $scope.user.community.$$hashKey
        }
        delete $scope.user.passwordConfirm
        await users.helpers.update($scope.user).then((user) => {
          if (user) $scope.successMsg = 'Password successfully updated'
        })
        applyChangesOnScope($scope, () => {
          $scope.viewChangePassword = false
        })
      }

      $scope.canSubmitValidationForm = function () {
      }

      $scope.getTeamNames = function (teamIds = []) {
        if (!teamIds || teamIds.length == 0) {
          return 'No Team'
        } else if (teamIds.length > 4) {
          return `${teamIds.length} Teams`
        }

        const names = []
        _.map($scope.teams, (o) => {
          if (teamIds.indexOf(o._id) != -1) { names.push(o.name) }
        })

        return names.join(', ')
      }

      $scope.loadActivities = function () {
        $scope.isLoading = true
        const settings = {
          userId: _.get($scope, 'userId'),
          itemsPerPage: _.get($scope, 'collectionView.itemsPerPage'),
          currentPage: _.get($scope, 'collectionView.currentPage')
        }

        users.helpers.fetchActivityTabList(settings).then((rets) => {
          $scope.activityList = rets
          $scope.isLoading = false

          $scope.imageMap = {}
          const imageIds = []
          _.each(rets, (o) => {
            if (o.user.avatar) {
              if (imageIds.indexOf(o.user.avatar._id) == -1) { imageIds.push(o.user.avatar._id) }
            }
          })

          users.helpers.getImages({ id: imageIds }).then((rets) => {
            _.each(rets, (o) => {
              $scope.imageMap[o._id] = o
            })
            _.map($scope.activityList, (o) => {
              if (typeof o.points === 'undefined') {
                o.points = 0
              }
              if (o.user.avatar) {
                o.user.avatar = $scope.imageMap[o.user.avatar._id]
              }
            })
          })
        })

        users.helpers.fetchActivityTabListNavigation(settings).then((count) => {
          const numPages = Math.ceil(count / $scope.collectionView.itemsPerPage)
          applyChangesOnScope($scope, () => {
            $scope.collectionView.totalItems = count
            $scope.collectionView.numPages = numPages
          })
        })
      }

      $scope.loadActivities()
      $scope.loadGoals = function () {
        $scope.isLoading = true; 

        const settings = {
          userId: _.get($scope, 'userId'),
          itemsPerPage: _.get($scope, 'collectionView.itemsPerPage'),
          currentPage: _.get($scope, 'collectionView.currentPage')
        }

        users.helpers.fetchGoalsTabList(settings).then((rets) => {  

          rets.map(data => {

              data.progress = ((data.currentPoints / data.totalPoints) * 100).toFixed(0); 
              

              if (data.progress > 100) {
                data.progress = 100; 
              }

              if (data.currentPoints > data.totalPoints || data.currentPoints === data.totalPoints) {
                $scope.goals.completed++; 
              } else if (data.currentPoints) {
                $scope.goals.started++;
              }

            return data
          })

          $scope.goalList = rets
          $scope.isLoading = false

        })


      }

      $scope.loadGoals(); 

      $scope.onPageChange = function () {
        $scope.loadActivities()
      }

      $scope.deleteActivity = async (id, e) => {
        e.preventDefault()
        e.stopPropagation()
        const yeap = confirm('Deleting the activity is permanent and can not be undone.')
        if (!yeap) {
          return false
        }
        $scope.isLoading = true
        await activities.helpers.delete(id)
        applyChangesOnScope($scope, () => {
          $scope.loadActivities()
        })
      }

      $scope.editActivity = function (activity, $event) {
        if ($event.metaKey || $event.ctrlKey) {
          window.open('#/users/' + $scope.userId + '/activities/' + activity._id, '_blank')
        } else { return $location.path('/users/' + $scope.userId + '/activities/' + activity._id) }
      }

      $scope.createActivity = function ($event) {
        if ($event.metaKey || $event.ctrlKey) {
          window.open('#/users/' + $scope.userId + '/activities/new', '_blank')
        } else { return $location.path('/users/' + $scope.userId + '/activities/new') }
      }
    }])
