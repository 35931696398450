import * as actions from './actions'
import BendClient from '../BendClient'
import commonUtil from '../../helpers/commonUtil'
import redux from '../../../src/helpers/redux'

class Feature {

  private api: any

  constructor () {
    this.api = BendClient
  }

  manageItems () {
    return this.api.manageItems('task')
  }

  async init (communityId) {
    const response = await this.api.fetchTasks(communityId)
    const incomplete = response.filter(item => item.completed !== true)
    const complete = response.filter(item => item.completed === true)
    redux.dispatch(
      actions.list.update(incomplete, complete)
    )
  }

  async deleteItem ({ id }) {
    await this.manageItems().delete(id)
    redux.dispatch(
      actions.list.deleteItem({ id })
    )
  }

  async complete (item) {
    const cloneItem = Object.assign({}, item)
    const id = cloneItem._id
    cloneItem.completed = true
    delete cloneItem.$$hashKey
    delete cloneItem.editing

    await this.manageItems().complete(cloneItem)
    redux.dispatch(
      actions.list.complete({ id })
    )
  }

  async incomplete (item, index) {
    const cloneItem = Object.assign({}, item)
    cloneItem.completed = false
    delete cloneItem.$$hashKey
    delete cloneItem.editing

    await this.manageItems().incomplete(cloneItem)
    redux.dispatch(
      actions.list.incomplete(index)
    )
  }

  cancelEdit (e, { id, title }) {
    if (e.keyCode === 27) {
      redux.dispatch(
        actions.list.cancelEdit({ id, title })
      )
    }
  }

  startAddNew () {
    redux.dispatch(
      actions.form.toggleShowForm()
    )
  }

  async addingNew (communityId, { title, label, color }) {
    const newTask = {
      title: title,
      label: label,
      color: color,
      completed: false,
      community: commonUtil.makeBendRef(communityId, 'community')
    }

    const response = await this.manageItems().add(newTask)
    redux.dispatch(
      actions.list.add(response)
    )
    redux.dispatch(
      actions.form.toggleShowForm()
    )

    return response
  }

  cancelingAdd () {
    redux.dispatch(
      actions.form.toggleShowForm()
    )
  }

  async saving (item) {
    const cloneItem = Object.assign({}, item)
    delete cloneItem.$$hashKey
    delete cloneItem.editing

    await this.manageItems().edit(cloneItem)
    redux.dispatch(
      actions.list.saveEdited({
        id: cloneItem._id,
        title: cloneItem.title
      })
    )
  }

  startEditing ({ id }) {
    redux.dispatch(
      actions.list.itemEditing({ id })
    )
  }
}

export default new Feature()
