'use strict'

import commonUtil from '../../../src/helpers/commonUtil'
import * as communities from '../../../src/features/communities'
import * as categories from '../../../src/features/categories'

angular.module('app.controllers')
  .controller('CategoryController', ['$scope', '$location', '$window', '$ngRedux',
    async function ($scope, $location, $window, $ngRedux) {
      $scope.commonUtil = commonUtil

      const mapStateToThis = (state) => {
        return {
          communities: state.communities.list,
          categories: state.categories.list,
          groupList: state.categories.settings.groups,
          collectionView: {
            itemsPerPage: state.categories.settings.itemsPerPage,
            totalItems: state.categories.settings.totalItems,
            currentPage: state.categories.settings.currentPage,
            numPages: state.categories.settings.numPages
          },
          filter: {
            community: state.categories.settings.community
          }
        }
      }

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      communities.helpers.initEnabled()
      // if (typeof $scope.filter.community === 'object' && !$scope.filter.community._id) {
      //   categories.helpers.selectCommunity(null)
      // }

      $(document).on('click', '.pagination li:not(.disabled) a', (e) => {
        const n = $(e.target).text()
        categories.helpers.paginateToPage(n)
        $scope.loadList()
      })

      setTimeout(() => {
        categories.helpers.getList()
      }, 200)

      $scope.loadList = async (id = null) => {
        await categories.helpers.getList(id)
        $scope.isLoading = false
      }

      $scope.onSearch = async (community) => {
        await $scope.loadList()
      }
      $scope.selectCommunity = async () => {
        $scope.isLoading = true
        const id = $scope.filter.community && $scope.filter.community._id
        categories.helpers.selectCommunity(id)
        await $scope.loadList(id)
      }

      $scope.allGroups = []

      const allCategoriesGroups = await categories.helpers.getAllCategoryGroups()
      $scope.allGroups = allCategoriesGroups

      $scope.getGroupName = function (group) {
        if (!group) return ''
        if (typeof group == 'string') {
          return group
        }

        var exist = _.find($scope.allGroups, function (o) {
          return o._id == group._id
        })

        if (exist) {
          return exist.name
        }

        return ''
      }

      $scope.editCategory = function (id, $event = {}) {
        return $location.path('/categories/' + id)
        // return $location.path(`/categories/${id}`) //
        // return $window.location.assign(`/#/categories/${id}`) // same reload issue
        // return window.location.assign(`/#/categories/${id}`)
        // window.location.hash = `#/categories/${id}`
      }
    }
  ])
