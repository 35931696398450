import { combineReducers } from 'redux'
import list, { Collections } from './list'

export interface CombineReducers {
  list: Collections
}

const rootReducer = combineReducers<CombineReducers>({
  list
})

export default rootReducer
