import { combineReducers } from 'redux'
import list from './list'
import boardList from './boardList'
import pageList from './pageList'

const rootReducer = combineReducers({
  list,
  boardList,
  pageList
})

export default rootReducer
