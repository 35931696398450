'use strict'

import commonUtil from '../../../src/helpers/commonUtil'
import * as customActivities from '../../../src/features/customActivities'
import * as categories from '../../../src/features/categories'
// import * as teams from '../../../src/features/teams'
import * as segments from '../../../src/features/segments'

angular.module('app.controllers')
  .controller('CustomActivityEditController', ['$scope', '$location', '$routeParams', 'BendAuth', '$rootScope', '$modal', '$ngRedux',
    function ($scope, $location, $routeParams, BendAuth, $rootScope, $modal, $ngRedux) {
      // Init.
      $scope.commonUtil = commonUtil
      $scope.customActivityId = null
      if ($routeParams.id != 'new') { $scope.customActivityId = $routeParams.id }
      $scope.isLoading = true
      $scope.moreOptions = false

      $scope.formData = {
        categories: [],
        teams: [],
        segments: []
      }
      const formDataKeys = Object.keys($scope.formData)

      const mapStateToThis = (state) => {
        // prep formdata to display when loading data
        if (
          // $scope.teams &&
          $scope.segments &&
          $scope.categories
        ) {
          formDataKeys.map(key => {
            if (
              !$scope.customActivity ||
              !$scope.customActivity[key]
            ) {
              return
            }
            $scope.formData[key] = $scope[key].filter(obj => {
              return $scope.customActivity[key].indexOf(obj._id) !== -1
            })
          })
        }
        return {
          comments: state.comments.list,
          categories: state.categories.list,
          teams: state.teams.list.teams,
          segments: state.segments.list
        }
      }

      const unsubscribe = $ngRedux.connect(mapStateToThis)($scope)
      $scope.$on('$destroy', unsubscribe)

      // teams.helpers.init()
      segments.helpers.init()

      $scope.isUploading = []
      $scope.customActivity = {
        repeatable: true
      }

      $scope.tagList = []
      $scope.collectionList = []
      $scope.stateList = commonUtil.AllStates

      $scope.state = $scope.stateList[0]
      $scope.fileProgress = []
      $scope.openedDateSelector = [false, false]
      $scope.openedTimeDateSelector = []
      $scope.user = BendAuth.getActiveUser()
      $scope.form = {}

      async.parallel([
        async (callback) => {
          categories.helpers.getList()
          const response = await customActivities.helpers.getCollectionEnabled()
          $scope.collectionList = response
          callback(null, null)
        }], function (err, rets) {
        if (err) {
          return
        }

        if ($scope.customActivityId) {
          customActivities.helpers.get($scope.customActivityId).then(function (ret) {
            ret.enabled = (typeof ret.enabled !== 'undefined' && ret.enabled === true)
            $scope.customActivity = ret
            $scope.isLoading = false

            if ($scope.customActivity.state) {
              $scope.state = $scope.customActivity.state
            }

            // convert check-in radius to feet for American users
            if ($scope.customActivity.checkinRadius) {
              $scope.customActivity.checkinRadius = commonUtil.metersToFeet($scope.customActivity.checkinRadius)
            }

            // if ($scope.customActivity.categories && $scope.customActivity.categories.length > 0) {
            //   $scope.formData.categories = _.filter($scope.categories, function (o) {
            //     return $scope.customActivity.categories.indexOf(o._id) != -1
            //   })
            // }

            // if ($scope.customActivity.teams && $scope.customActivity.teams.length) {
            //   $scope.formData.teams = _.filter($scope.teams, function (team) {
            //     return $scope.customActivity.teams.indexOf(team._id) !== -1
            //   })
            // }

            if ($scope.customActivity.community) {
              $scope.customActivity.community = $scope.customActivity.community._id
            } else {
              $scope.customActivity.community = ''
            }

            if ($scope.customActivity.startsAt) {
            }
            if ($scope.customActivity.endsAt) {
            }
          })
        }
      })

      $scope.getSegmentInfo = (segment) => {
        return segments.helpers.getSegmentInfo(segment)
      }

      $scope.deleteCustomActivity = async () => {
        const id = $scope.customActivityId
        const yeap = confirm('Deleting the customActivity is permanent and can not be undone.')
        if (!yeap) {
          return false
        }
        await customActivities.helpers.delete(id)
        applyChangesOnScope($scope, () => {
          $location.path('/customActivities')
        })
      }

      // form validation using angular ng-class
      $scope.validateForm = () => {
        const required = 'required'

        const name = _.get($scope.customActivity, 'name')
        const unit = _.get($scope.customActivity, 'unit')

        if (name === '' || !name) $scope.nameReq = required
        if (unit === '' || !unit) $scope.customUnitReq = required
        if (!name ||
            !unit
        ) {
          $scope.errorMsg = true
          return false
        }
        return true
      }

      $scope.saveCustomActivityDo = async function () {
        if (!$scope.validateForm()) return

        // on save
        formDataKeys.map(key => {
          if ($scope.formData[key] && $scope.formData[key].length > 0) {
            $scope.customActivity[key] = $scope.formData[key].map(obj => obj._id)
          } else {
            delete $scope.customActivity[key]
          }
        })

        // if ($scope.formData.categories.length > 0) {
        //   $scope.customActivity.categories = commonUtil.getIdList($scope.formData.categories)
        // } else {
        //   delete $scope.customActivity.categories
        // }

        // if ($scope.formData.teams && $scope.formData.teams.length > 0) {
        //   $scope.customActivity.teams = commonUtil.getIdList($scope.formData.teams)
        // } else {
        //   delete $scope.customActivity.teams
        // }

        var customActivityData = _.clone($scope.customActivity)
        if (customActivityData.coverImage) {
          customActivityData.coverImage = commonUtil.makeBendFile(customActivityData.coverImage._id)
        }

        customActivityData.community = commonUtil.makeBendRef($scope.user.community._id, 'community')

        if ($scope.customActivityId) {
          delete customActivityData.$$hashKey
          await customActivities.helpers.update(customActivityData)
          applyChangesOnScope($scope, () => {
            $location.path('/customActivities')
          })
        } else {
          await customActivities.helpers.create(customActivityData)
          applyChangesOnScope($scope, () => {
            $location.path('/customActivities')
          })
        }
      }

      $scope.deleteFile = function (tag, $ev) {
        $ev.stopPropagation()
        $ev.prcustomActivityDefault()
        applyChangesOnScope($scope, function () {
          delete $scope.customActivity.coverImage
        })
      }

      $scope.showFileLoading = function (tag, bShow) {
        $scope.isUploading[tag] = bShow
      }

      $scope.selectFileOpen = function (fileId, $ev) {
        if ($ev.target.tagName == 'DIV') {
          setTimeout(function () {
            $('#' + fileId).click()
          }, 0)
        }
      }

      $scope.onFileUpload = function (files, tag) {
        var file = files[0]
        $scope.fileProgress[tag] = 0
        $scope.showFileLoading(tag, true)
        var reader = new FileReader()
        reader.onload = function (e) {
          $scope.uploadingFileUrl = e.target.result
        }
        reader.readAsDataURL(file)

        customActivities.helpers.upload(file, function (error, uploadedFile) {
          if (error) {
            $scope.showFileLoading(tag, false)
            $scope.uploadingFileUrl = null

            return
          }
          customActivities.helpers.getFile(uploadedFile, function (o) {
            $scope.customActivity.coverImage = o
            $scope.uploadingFileUrl = null

            $scope.showFileLoading(tag, false)
          })
        }, {
          _workflow: 'coverPhoto'
        }, function (total, prog) {
          applyChangesOnScope($scope, function () {
            $scope.fileProgress[tag] = prog * 100 / total
          })
        })
      }

      $rootScope.setLocation = function (lat, lng) {
        $scope.customActivity._geoloc = [
          Number(lng),
          Number(lat)
        ]
      }

      $rootScope.fileUploadFromSearch2 = function (file, tag) {
        file._filename = Date.now() + ''
        var files = []
        files.push(file)
        $scope.onFileUpload(files, tag)
      }

      $scope.searchImages2 = function ($ev, tag) {
        $ev.stopPropagation()
        $ev.prcustomActivityDefault()
        $modal.open({
          templateUrl: 'views/templates/searchImages.html',
          backdrop: 'static',
          controller: function ($scope, $modalInstance) {
            $scope.commonUtil = commonUtil
            $scope.searchResults = 0
            $scope.pages = 1
            $scope.showLoadMore = false
            // var cacheSearchKey = ''

            $scope.cancel = function () {
              $modalInstance.dismiss('cancel')
            }
            $scope.close = function () {
              $modalInstance.dismiss('cancel')
            }

            $scope.searchImages = function (searchVal) {
              // cacheSearchKey = searchVal

              $scope.pages = 1
              if (searchVal) {
                var URL = 'https://pixabay.com/api/?key=2706353-ee3016f8af51ca406a8c8a3db&q=' + encodeURIComponent(searchVal) + '&image_type=photo&page=' + $scope.pages
                $.getJSON(URL, function (data) {
                  if (parseInt(data.totalHits) > 0) {
                    applyChangesOnScope($scope, function () {
                      if (data.totalHits > $scope.pages * 20) { $scope.showLoadMore = true } else { $scope.showLoadMore = false }

                      $scope.searchResults = data.hits
                    })
                  }
                })
              }
            }

            $scope.searchImagesMore = function (tabIdx, searchVal) {
              $scope.pages++
              if (searchVal) {
                var URL = 'https://pixabay.com/api/?key=2706353-ee3016f8af51ca406a8c8a3db&q=' + encodeURIComponent(searchVal) + '&image_type=photo&page=' + $scope.pages
                $.getJSON(URL, function (data) {
                  if (parseInt(data.totalHits) > 0) {
                    applyChangesOnScope($scope, function () {
                      if (data.totalHits > $scope.pages * 20) { $scope.showLoadMore = true } else { $scope.showLoadMore = false }

                      $scope.searchResults = $scope.searchResults.concat(data.hits)
                    })
                  }
                })
              }
            }

            $scope.selectImage = function (searchItem) {
              $scope.isDownloading = true
              var imageUrl = ''
              imageUrl = searchItem.webformatURL

              var xhr = new XMLHttpRequest()
              xhr.open('GET', imageUrl, true)
              xhr.responseType = 'blob'
              xhr.onload = function (e) {
                if (this.status == 200) {
                  var myBlob = this.response
                  $rootScope.fileUploadFromSearch2(myBlob, tag)
                  // myBlob is now the blob that the object URL pointed to.
                  $scope.cancel()
                }
              }
              xhr.send()
            }
          }
        })
      }
    }])
