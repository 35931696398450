import { UPDATE_LEADERBOARD_LIST } from '../constants/types'
import { Action } from '../actions/list'

export interface Item {
  sprintRank: number,
  email: string,
  points: number,
  _id: string,
  enabled: boolean,
  sprintPoints: number,
  username: string,
  birthday: string,
  _acl: {
    creator: string
  },
  _bmd: {
    createdAt: number,
    _rev: number,
    updatedAt: number,
    _gen: number
  },
  previousRank: number,
  gender: string,
  name: string,
  allowNotifications: boolean,
  communityTotals: number,
  defaultAvatar: string,
  previousSprintRank: number,
  code: string,
  shareActivity: boolean,
  community: {
    _type: string,
    _id: string,
    _collection: string
  },
  rank: number,
  avatar: string,
  birthdate: string,
  $$hashKey: string
}

export type List = Array<Item>

const initialState: List = []

export default (state: List = initialState, action: Action): List => {
  switch (action.type) {
    case UPDATE_LEADERBOARD_LIST:
      return action.leaderboards
    default:
      return state
  }
}
