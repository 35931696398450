import * as actions from './actions'
import BendClient, { BendTable } from '../BendClient'
import commonUtil from '../../helpers/commonUtil'
import redux from '../../../src/helpers/redux'

class Feature {

  private api: any
  private api2: any
  private allPlaces: any

  constructor () {
    this.api = BendClient
    this.api2 = BendTable
    this.allPlaces = []
  }

  public async init () {
    await this.loadPlaces()
    // await this.updateStates()
  }

  public async loadPlaces () {
    const settings = redux.getState().places.settings
    const params = {
      relations: {
        avatar: 'BendFile',
        community: 'community'
      }
    }
    const places = await this.api2.getList(BendTable.Tables.PLACE, settings, params)
    redux.dispatch(
      actions.list.update(places)
    )
    redux.dispatch(
      actions.settings.update(places.length)
    )
    this.allPlaces = places
    this.isLoading(false)
  }

  public sortBy (key, direction) {
    let list = redux.getState().places.list
    let sortedList = commonUtil.sortBy(list, key, direction)
    redux.dispatch(
      actions.list.update(sortedList)
    )
  }

  // public async updateStates () {
  //   const stateList = ['All States'].concat(commonUtil.AllStates)
  //   redux.dispatch(
  //     actions.settings.updateStates(stateList)
  //   )
  //   this.isLoading(false)
  // }

  public async selectSearchTerm (term) {
    this.isLoading(true)
    redux.dispatch(
      actions.settings.selectSearchTerm(term)
    )
    await this.loadPlaces()
  }

  public async selectCommunity (id) {
    const selected = redux.getState().communities.list.find(item => item._id === id)
    redux.dispatch(
      actions.settings.selectCommunity(selected)
    )
    await this.loadPlaces()
  }

  public async selectCollection (id) {
    const selected = redux.getState().collections.list.find(item => item._id === id)
    redux.dispatch(
      actions.settings.selectCollection(selected)
    )
    await this.loadPlaces()
  }

  public async selectCategory (selected) {
    redux.dispatch(
      actions.settings.selectCategory(selected)
    )
    await this.loadPlaces()
  }

  public async selectState (AA) {
    redux.dispatch(
      actions.settings.selectState(AA)
    )
    await this.loadPlaces()
  }

  public async reset () {
    redux.dispatch(
      actions.settings.reset()
    )
    await this.loadPlaces()
  }

  public paginateToPage (n) {
    const currentPage = redux.getState().places.settings.currentPage
    if (Number(n)) {
      redux.dispatch(
        actions.settings.pageUpdate(n)
      )
    } else if (n === 'Previous') {
      redux.dispatch(
        actions.settings.pageUpdate(currentPage - 1)
      )
    } else {
      redux.dispatch(
        actions.settings.pageUpdate(currentPage + 1)
      )
    }
  }

  public getCategoryGroupList (communityId) {
    return this.api.getCategoryGroupList(communityId)
  }

  // public getCategoryList () {
  //   return this.api.getCategoryList()
  // }

  public getCommunityList () {
    return this.api.getCommunityList()
  }

  public getCollectionEnabled () {
    return this.api.getCollectionEnabled()
  }

  public get (id) {
    const related = {
      relations: {
        coverImage: 'BendFile'
      }
    }
    return this.api2.get(BendTable.Tables.PLACE, id, related)
  }

  public create (data) {
    return this.api2.create(BendTable.Tables.PLACE, data)
  }
  public update (data) {
    return this.api2.update(BendTable.Tables.PLACE, data)
  }

  public delete (id) {
    return this.api2.delete(BendTable.Tables.PLACE, id)
  }

  private isLoading (bool) {
    redux.dispatch(
      actions.settings.toggleLoading(bool)
    )
  }

}

export default new Feature()
